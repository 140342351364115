import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Button from './Button';

class Action extends Component {
  render() {
    const { action, danger } = this.props;
    return (
      <Button
        multi
        onClick={action.onClick}
        href={action.url ? action.url : '#'}
        buttonType={danger ? 'danger' : 'primary'}
        disabled={action.disabled}
        icon={action.icon}
        label={action.name}
        confirmable={action.confirmable}
        loading={action.loading}
        target={action.target}
      />
    );
  }
}

Action.propTypes = {
  action: PropTypes.object.isRequired,
  danger: PropTypes.bool,
};

export default Action;
