// v2 constants
//export const REM = 'v2Rem';
export const PRIMARY_COLOR = 'primaryColor';
export const PRIMARY_LIGHT_COLOR = 'primaryLightColor';
export const SECONDARY_COLOR = 'secondaryColor';
export const SECONDARY_LIGHT_COLOR = 'secondaryLightColor';
export const LIGHT_COLOR = 'lightColor';
export const DARK_COLOR = 'darkColor';
export const DARK_BLUE_COLOR = 'darkBlueColor';
export const DANGER_LIGHT_COLOR = 'dangerLightColor';
export const DANGER_COLOR = 'dangerColor';
export const DANGER_COLOR_HOVER = 'dangerColorHover';
export const SUCCESS_COLOR = 'successColor';
export const SUCCESS_LIGHT_COLOR = 'successLightColor';
export const INFO_COLOR = 'infoColor';
export const INFO_LIGHT_COLOR = 'infoLightColor';
export const INFO_COLOR_DARK = 'infoColorDark';
export const LIGHT_BORDER_COLOR = 'lightBorderColor';
export const WARNING_COLOR = 'warningColor';
export const WARNING_LIGHT_COLOR = 'warningLightColor';
export const WARNING_COLOR_HOVER = 'warningColorHover';
export const WARNING_COLOR_DARK = 'warningColorDark';
export const HEADER_BACKGROUND_COLOR = 'headerBackgroundColor';
export const LOGIN_BACKGROUND_COLOR = 'loginBackgroundColor';
export const LOGIN_BACKGROUND_COLOR2 = 'loginBackgroundColor2';
export const MODAL_OVERLAY_BACKGROUND_COLOR = 'modalOverlayBackgroundColor';
export const PRIMARY_BACKGROUND_SEMI_OPAQ_COLOR =
  'primaryBackgroundSemiOpaqColor';
export const LOADING_OVERLAY_BACKGROUND_COLOR = 'loadingOverlayBackgroundColor';
export const TRANSPARENT_COLOR = 'transparentColor';
export const DARK_GREY_COLOR = 'darkGreyColor';
export const DARK_BLACK_COLOR = 'darkBlackColor';
export const MID_GREY_COLOR = 'midGreyColor';
export const LIGHT_GREY_COLOR = 'lightGreyColor';
export const LIGHT_BLUE_COLOR = 'lightBlueColor';
export const FORM_FIELDS_BORDER_COLOR = 'formFieldsBorderColor';
export const XLS_COLOR = 'xlsColor';
export const PICKED_BACKGROUND_COLOR = 'pickedBackgroundColor';
export const PICKED_COLOR = 'pickedColor';
export const PICKED_ITEM_COLOR = 'pickedItemColor';
export const HIGHLIGHT_ITEM_COLOR = 'highlightItemColor';
export const PDF_COLOR = 'pdfColor';
export const GENERIC_DOCUMENT_COLOR = 'genericDocumentColor';
export const GENERIC_FOLDER_COLOR = 'genericFolderColor';
export const ACQ_COLOR = 'acqColor';
export const CLI_COLOR = 'cliColor';
export const MATCHED_COLOR = 'matchedColor';
export const PRIMARY_COLOR_HOVER = 'primaryColorHover';
export const LIGHT_BACKGROUND_COLOR = 'lightBackgroundColor';
export const DROP_SHADOW_COLOR = 'dropShadowColor';
export const CLIENT_UI_COLOR = 'clientUIColor';

export const BODY_FONT = 'bodyFont';

export default {
  // Breakpoints
  tablet: '64rem', // stands for 1024px i.e. landscape tablets. Usage: @media (min-width: ${props => props.theme.tablet}) { my rules for tablets landscape and above }
  xl: '75rem', // 1200px
  xxl: '100rem', // 1600px
  [PRIMARY_COLOR]: '#003a78',
  [PRIMARY_COLOR_HOVER]: '#003a78',
  [CLI_COLOR]: '#003A78',
  [PRIMARY_LIGHT_COLOR]: '#DEF',
  [SECONDARY_COLOR]: '#D64D3B',
  [SECONDARY_LIGHT_COLOR]: '#FEE',
  [LIGHT_COLOR]: '#ffffff',
  [LIGHT_BACKGROUND_COLOR]: '#edf9fd',
  [DARK_COLOR]: '#003167',
  [DARK_BLUE_COLOR]: '#061B38',
  [DANGER_COLOR]: 'rgb(255, 0, 0)',
  [DANGER_LIGHT_COLOR]: '#fee',
  [DANGER_COLOR_HOVER]: '#f17979',
  [LIGHT_BORDER_COLOR]: '#eaeaea',
  [SUCCESS_COLOR]: '#199119',
  [SUCCESS_LIGHT_COLOR]: '#EFE',
  [INFO_COLOR]: '#0784CF',
  [INFO_LIGHT_COLOR]: '#def',
  [INFO_COLOR_DARK]: '#004499',
  [WARNING_COLOR]: '#D4AA39',
  [PICKED_ITEM_COLOR]: '#c5e8fd',
  [HIGHLIGHT_ITEM_COLOR]: '#fdc5a3',
  [WARNING_LIGHT_COLOR]: '#FED',
  [WARNING_COLOR_HOVER]: '#b39136',
  [WARNING_COLOR_DARK]: '#edc',
  [HEADER_BACKGROUND_COLOR]: 'rgba(0,0,0,.75)',
  [LOGIN_BACKGROUND_COLOR]: 'rgba(0,31,67,.25)',
  [LOGIN_BACKGROUND_COLOR2]: 'rgba(0,31,67,.9)',
  [PRIMARY_BACKGROUND_SEMI_OPAQ_COLOR]: '0,31,67,',
  [MODAL_OVERLAY_BACKGROUND_COLOR]: 'rgba(0,31,67,.2)',
  [LOADING_OVERLAY_BACKGROUND_COLOR]: 'rgba(0,31,67,.9)',
  [TRANSPARENT_COLOR]: 'transparent',
  [DARK_GREY_COLOR]: '#555',
  [DARK_BLACK_COLOR]: '#434552',
  [MID_GREY_COLOR]: '#808080',
  [LIGHT_GREY_COLOR]: '#f0f0f0',
  [LIGHT_BLUE_COLOR]: '#EDF5FD',
  [FORM_FIELDS_BORDER_COLOR]: '#ccc',
  [XLS_COLOR]: 'green',
  [PICKED_BACKGROUND_COLOR]: '#e0eef9',
  [PICKED_COLOR]: '#407ac4',
  [PDF_COLOR]: '#00f',
  [GENERIC_DOCUMENT_COLOR]: '#00f',
  [GENERIC_FOLDER_COLOR]: '#f00',
  [ACQ_COLOR]: '#ffb43d',
  [CLI_COLOR]: '#004e9e',
  [MATCHED_COLOR]: '#ffdc7a',
  [DROP_SHADOW_COLOR]: 'rgba(0,0,0,.2)',
  [BODY_FONT]: '"Open Sans", sans-serif',
  [CLIENT_UI_COLOR]: '#003a78', // Change this const if "client" needs a customized color
};
