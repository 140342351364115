import styled from 'styled-components';
import { DARK_COLOR } from '../../theme_blue';

export default styled.ul`
  color: ${props => props.theme[DARK_COLOR]};
  display: flex;
  margin: 0 0 0 ${props => (props.isFirstLevel ? '0' : '1rem')};
  padding: 0;
  flex-basis: 100%;
  flex-direction: column;
`;
