import styled from 'styled-components';

export default styled.label`
  padding: 12px;
  font-size: 14px;
  height: 48px;
  line-height: 1.42857143;
  border-radius: 0;
  display: ${props => (props.display ? props.display : 'inline-block')};
  ${props => (props.flexGrow ? 'flex-grow: 1' : null)};
`;
