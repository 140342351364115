import { delay } from 'redux-saga';
import { takeEvery, put, all } from 'redux-saga/effects';
import { ADD_NOTIFICATION_REMINDER } from '../reducers/notifications';

export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';
export const DELETE_NOTIFICATION_DONE = 'DELETE_NOTIFICATION_DONE';
export const MOVE_NOTIFICATION = 'MOVE_NOTIFICATION';
export const MOVE_NOTIFICATION_DONE = 'MOVE_NOTIFICATION_DONE';
export const ADD_PERMANENT_NOTIFICATION = 'ADD_PERMANENT_NOTIFICATION';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';

function* moveNotificationSaga(action) {
  const { notificationId } = action;

  yield delay(500);

  yield put({
    type: MOVE_NOTIFICATION_DONE,
    notificationId,
  });

  yield delay(20000);
  yield put({
    type: DELETE_NOTIFICATION,
    notificationId,
  });
}

function* deleteNotificationSaga(action) {
  const { notificationId } = action;

  yield delay(300);

  yield put({
    type: DELETE_NOTIFICATION_DONE,
    notificationId,
  });
}

export function* saga() {
  yield all([
    takeEvery(MOVE_NOTIFICATION, moveNotificationSaga),
    takeEvery(DELETE_NOTIFICATION, deleteNotificationSaga),
  ]);
}

export const addNotificationAction = (message, notificationType = 'ERROR') => ({
  type: ADD_NOTIFICATION,
  message,
  notificationType,
});

export const addNotificationReminderAction = (
  message,
  notificationType = 'ERROR'
) => ({
  type: ADD_NOTIFICATION_REMINDER,
  message,
  notificationType,
});

export const addPermanentNotification = (
  notificationId,
  message,
  notificationType
) => ({
  type: ADD_PERMANENT_NOTIFICATION,
  notificationId,
  message,
  notificationType,
});

export const deleteNotification = notificationId => ({
  type: DELETE_NOTIFICATION,
  notificationId,
});

export const moveNotificationAction = notificationId => ({
  type: MOVE_NOTIFICATION,
  notificationId,
});
