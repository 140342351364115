import Basil from '../../../../node_modules/basil.js/build/basil';

class TokenRepository {
  constructor(basil, key) {
    this.basil = basil;
    this.key = key;
    this.USER = 'user';
  }

  getToken() {
    return this.basil.get(this.getKeyForType(this.guessType()));
  }

  setToken(value) {
    this.setTokenWithType(this.guessType(), value);
  }

  setTokenWithType(type, value) {
    this.basil.set(this.getKeyForType(type), value);
  }

  guessType() {
    return this.USER;
  }

  getKeyForType(type) {
    return `${this.key}-${type}`;
  }
}

export default new TokenRepository(new Basil({ namespace: 'token' }), 'token');
