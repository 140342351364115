import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import {
  Overlay,
  Tooltip,
  MenuItem,
  SplitButton,
  Button as ReactBootstrapButton,
  Dropdown,
  ButtonGroup,
} from 'react-bootstrap';
import { findDOMNode } from 'react-dom';
import { uniqueId } from 'underscore';
import './Button.css';

class Button extends Component {
  static defaultProps = {
    buttonType: 'primary',
    buttonClassName: '',
    tooltipId: uniqueId('tooltip'),
  };

  constructor(props) {
    super(props);
    this.id = uniqueId('split');
    this.buttonClicked = this.buttonClicked.bind(this);
    this.renderElement = this.renderElement.bind(this);
    this.closeTooltip = this.closeTooltip.bind(this);
    this.state = {
      showCancelableTooltip: false,
    };
  }

  buttonClicked(evt) {
    if (this.props.confirmable && !this.props.showCancelableTooltip) {
      if (this.props.href) {
        evt.preventDefault();
      }

      this.setState({
        showCancelableTooltip: true,
      });

      if (this.timeout) {
        clearInterval(this.timeout);
      }

      this.timeout = setTimeout(() => {
        this.setState({
          showCancelableTooltip: false,
        });
      }, 3000);

      return;
    }

    if (this.props.confirmable && this.props.showCancelableTooltip) {
      this.closeTooltip();
    }

    if (this.props.onClick) {
      if (this.props.href) {
        evt.preventDefault();
      }

      this.props.onClick(evt);
    }
  }

  closeTooltip() {
    if (this.timeout) {
      clearInterval(this.timeout);
    }

    this.setState({ showCancelableTooltip: false });
  }

  renderElement() {
    const {
      disabled,
      href,
      label,
      children,
      subButtons,
      multi,
      block,
      target,
    } = this.props;
    const { showCancelableTooltip } = this.state;
    let icon = 'fa fa-' + this.props.icon;
    let buttonClass = `btn btn-${this.props.buttonType} ${this.props.buttonClassName}`;
    let type = this.props.type ? this.props.type : 'button';
    if (this.props.loading) {
      icon = 'fa fa-cog fa-spin fa-fw';
      buttonClass += ' disabled';
    }

    if (showCancelableTooltip) {
      buttonClass += ' active';
    }

    let iconElement = <i className={icon} />;
    let bsClass = buttonClass;

    if (multi) {
      iconElement = <span className="icon"> {iconElement}</span>;
    }

    if (subButtons) {
      return (
        <SplitButton
          href={href}
          dropup
          pullRight
          title={
            <Fragment>
              {iconElement} {label}
            </Fragment>
          }
          id={this.id}
          disabled={disabled || this.props.loading}
          onClick={this.buttonClicked}
          type={type}
          className={buttonClass}
          ref={button => {
            this.target = button;
          }}
        >
          {subButtons.map(action => (
            <MenuItem
              onClick={action.onClick}
              disabled={action.disabled}
              key={action.label}
            >
              <i className={`fa fa-${action.icon}`} /> {action.label}
            </MenuItem>
          ))}
        </SplitButton>
      );
    }

    if (multi) {
      bsClass += ' btn-block btn-multi';
    }

    return (
      <ReactBootstrapButton
        target={target}
        href={href}
        disabled={disabled || this.props.loading}
        onClick={this.buttonClicked}
        type={type}
        bsClass={bsClass}
        ref={button => {
          this.target = button;
        }}
      >
        {iconElement}
        {label || children}
      </ReactBootstrapButton>
    );
  }

  render() {
    const { confirmable, tooltipId } = this.props;
    const element = this.renderElement();

    if (!confirmable) {
      return element;
    }

    const { showCancelableTooltip } = this.state;

    return (
      <Fragment>
        {element}
        <Overlay
          show={showCancelableTooltip}
          rootClose
          placement="top"
          onHide={this.closeTooltip}
          target={() => findDOMNode(this.target)}
        >
          <Tooltip id={tooltipId}>
            <h4>
              <b>Êtes vous sur ?</b>
            </h4>
            <div className="btn-group btn-group-justified">
              <Button
                buttonClassName="btn-sm areYouSureButton"
                buttonType="danger"
                icon="ban"
                onClick={this.closeTooltip}
              >
                Non
              </Button>
              &nbsp;
              <Button
                buttonClassName="btn-sm areYouSureButton"
                icon="check"
                buttonType="success"
                onClick={evt => {
                  this.closeTooltip();
                  this.props.onClick(evt);
                }}
              >
                Oui
              </Button>
            </div>
          </Tooltip>
        </Overlay>
      </Fragment>
    );
  }
}

Button.propTypes = {
  loading: PropTypes.bool,
  icon: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onClick: PropTypes.func,
  buttonClassName: PropTypes.string,
  buttonType: PropTypes.string,
  type: PropTypes.string,
  href: PropTypes.string,
};

export default Button;
