import { takeEvery, select, put, call } from 'redux-saga/effects';
import getNextPath from './utils/getNextPath';
import { uniqueId, indexBy } from 'underscore';
import { ADD_FILES } from './addFiles';
import { AFTER, INSIDE } from './utils/getNextPath';
import updatePath from './utils/updatePath';
import getPathLevel from './utils/getPathLevel';
import { TYPE_DOCUMENT, TYPE_FOLDER } from '../documents/type.js';

import apiClient from 'js/dataroom/core/apiClient';

const ADD_TEMPLATE = 'ADD_TEMPLATE';

export const addTemplateAction = templateName => ({
  type: ADD_TEMPLATE,
  templateName,
  addFilesId: uniqueId('dataroom_action'),
});

export function* saga() {
  yield takeEvery(ADD_TEMPLATE, addTemplateSaga);
}

function generateNodeFromTemplateNode(node, indexedNodes) {
  const generatedNode = {
    id: node.id,
    name: node.name,
    type: node.type,
  };

  if (generatedNode.type === TYPE_DOCUMENT) {
    return generatedNode;
  }

  generatedNode.children = [];
  let currentPath = `${node.path}.01`;

  while (typeof indexedNodes[currentPath] !== 'undefined') {
    generatedNode.children.push(
      generateNodeFromTemplateNode(indexedNodes[currentPath], indexedNodes)
    );

    currentPath = updatePath(
      getPathLevel(currentPath),
      currentPath,
      pathNumber => pathNumber + 1
    );
  }

  return generatedNode;
}

function generateNodesFromTemplateNodes(templateNodes) {
  const indexedNodes = indexBy(templateNodes, 'path');
  const nodes = [];
  let currentPath = '01';

  while (typeof indexedNodes[currentPath] !== 'undefined') {
    nodes.push(
      generateNodeFromTemplateNode(indexedNodes[currentPath], indexedNodes)
    );

    currentPath = updatePath(1, currentPath, pathNumber => pathNumber + 1);
  }

  return nodes;
}

const getTemplateNodes = templateName =>
  apiClient
    .request(new Request(`/api/templates/${templateName}`))
    .then(response => response.json());

function* addTemplateSaga(action) {
  const templateNodes = yield call(getTemplateNodes, action.templateName);
  const { dataroomNodes, rootNodeId } = yield select(state => ({
    dataroomNodes: state.dataroom.working.nodes,
    rootNodeId: state.dataroom.working.rootNodeId,
  }));
  const rootNode = dataroomNodes[rootNodeId];

  let position = null;
  let destinationNode = null;
  if (rootNode.childIds.length === 0) {
    position = INSIDE;
    destinationNode = rootNode;
  } else {
    position = AFTER;
    destinationNode =
      dataroomNodes[rootNode.childIds[rootNode.childIds.length - 1]];
  }

  yield put({
    type: ADD_FILES,
    nodes: generateNodesFromTemplateNodes(templateNodes),
    destinationId: destinationNode.id,
    destinationPath: getNextPath(destinationNode.path, position),
    position,
    frontActionId: action.addFilesId,
  });
}

export default function(state, action) {
  switch (action.type) {
    case ADD_TEMPLATE: {
      return {
        ...state,
        working: {
          ...state.working,
          addTemplate: {
            adding: true,
            addFilesActionId: action.addFilesId,
          },
        },
      };
    }
    case ADD_FILES: {
      if (action.frontActionId !== state.working.addTemplate.addFilesActionId) {
        return state;
      }

      return {
        ...state,
        working: {
          ...state.working,
          addTemplate: {
            adding: false,
            addFilesActionId: null,
          },
        },
      };
    }
  }

  return state;
}
