import React, { Component } from 'react';
import styled from 'styled-components';

import {
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  LIGHT_COLOR,
  DARK_COLOR,
  DANGER_COLOR,
  DANGER_COLOR_HOVER,
  SUCCESS_COLOR,
  INFO_COLOR,
  WARNING_COLOR,
  HEADER_BACKGROUND_COLOR,
} from './theme_blue';

const Li = styled.li`
  display: inline;
  margin: 0;
  padding: 0;
  &:first-child a {
    margin-left: 0;
  }
`;
const A = styled.a`
  display: inline-block;
  padding: 0.5em 1em;
  border: solid 1px ${props => props.theme.formFieldsBorderColor};
  margin-left: -1px;
  text-decoration: none;
  color: ${props => {
    if (props.disabled) {
      return props.theme.midGreyColor;
    }
    if (props.active) {
      return props.theme.lightColor;
    }
    if (props.paginationColor) {
      return props.theme[props.paginationColor];
    }
    return props.theme.darkColor;
  }};
  background-color: ${props => {
    if (props.active) {
      if (props.paginationColor) {
        return props.theme[props.paginationColor];
      }
      return props.theme.primaryColor;
    }
    return props.theme.lightColor;
  }};
  transition: background-color 0.3s;
  ${props => (props.disabled ? 'cursor: not-allowed;' : null)}
  &:hover {
    color: ${props =>
      props.disabled ? props.theme.darkGreyColor : props.theme.lightColor};
    background-color: ${props =>
      props.disabled ? props.theme.lightGreyColor : props.theme.darkGreyColor};
  }
  &:focus {
    color: ${props =>
      props.disabled ? props.theme.darkGreyColor : props.theme.lightColor};
  }
`;

class PaginationItem extends Component {
  render() {
    return (
      <Li>
        <A
          href={this.props.href}
          active={this.props.active}
          disabled={this.props.disabled}
          onClick={this.props.onClick}
          paginationColor={this.props.paginationColor}
        >
          {this.props.children}
        </A>
      </Li>
    );
  }
}

export default PaginationItem;
