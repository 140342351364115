import React, { Component } from 'react';
import styled from 'styled-components';

import {
  LIGHT_COLOR,
  DARK_COLOR,
  MODAL_OVERLAY_BACKGROUND_COLOR,
  LIGHT_GREY_COLOR,
  PRIMARY_BACKGROUND_SEMI_OPAQ_COLOR,
  SUCCESS_COLOR,
  DANGER_COLOR,
  INFO_COLOR,
  WARNING_COLOR,
} from './theme_blue';

const Modal = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(
    ${props =>
      props.theme[PRIMARY_BACKGROUND_SEMI_OPAQ_COLOR] +
      (props.backgroundOpacity ? props.backgroundOpacity : 0)}
  );
  z-index: ${props =>
    props.zIndex
      ? props.zIndex
      : 101}; /* z-index: 101 is above the responsive menu */
  justify-content: center;
  align-items: center;
  color: ${props => props.theme[DARK_COLOR]};
  font-size: 1rem;
  ${props => (props.youCanClickMeThrough ? 'pointer-events: none;' : null)}
`;
Modal.Popin = styled.div`
  background: ${props => props.theme[LIGHT_COLOR]};
  padding: 0;
  margin: 0;
  display: block;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  min-width: 29rem;
  max-width: 90vw;
  ${props => (props.width ? 'width: ' + props.width + ';' : null)}
  box-shadow: 0 0 1rem ${props => props.theme[MODAL_OVERLAY_BACKGROUND_COLOR]};
  @media (min-width: ${props => props.theme.tablet}) {
    min-width: 30em;
    max-width: 64em;
  }
`;
Modal.Header = styled.header`
  background: ${props => props.theme[DARK_COLOR]};
  color: ${props => props.theme[LIGHT_COLOR]};
  display: flex;
  justify-content: space-between;
  padding: 0.625em 0.5em 0.25em 0.5em;
  min-height: 2.75em;
`;
Modal.Title = styled.div`
  font-weight: bold;
`;
Modal.Close = styled.div`
  flex-shrink: 0;
  flex-grow: 1;
  text-align: right;
`;
Modal.ContentPlaceholder = styled.div`
  padding: 0.5em 0 0.5em 0.5em;
  min-height: ${props => (props.minHeight ? props.minHeight : '8em')};
  display: flex;
  align-items: center;
`;
Modal.Content = styled.div`
  display: ${props => (props.display ? props.display : 'flex')};
  ${props =>
    props.flexDirection ? 'flex-direction: ' + props.flexDirection + ';' : null}
  flex-shrink: 0;
  flex-grow: 1;
  width: 100%;
  justify-content: center;
  border: ${props => (props.border ? 'solid 1px' : null)};
  padding-right: 0.5em;
  ${props => (props.padding ? 'padding: ' + props.padding + ';' : null)}
  align-items: center;
  max-height: 75vh;
  overflow-y: auto;
  color: ${props => {
    if (props.type == 'SUCCESS') {
      return props.theme[SUCCESS_COLOR];
    }
    if (props.type == 'ERROR') {
      return props.theme[DANGER_COLOR];
    }
    if (props.type == 'INFO') {
      return props.theme[INFO_COLOR];
    }
    if (props.type == 'WARNING') {
      return props.theme[WARNING_COLOR];
    }
    return props.theme[DARK_COLOR];
  }};
`;
Modal.Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1em;
  width: 3rem;
  height: 3rem;
  border: ${props => (props.border ? props.border : null)};
  border-radius: 100%;
`;
Modal.Footer = styled.footer`
  display: block;
  border-top: solid 1px ${props => props.theme[LIGHT_GREY_COLOR]};
  padding: 0.25em 0.5em;
`;

export default Modal;
