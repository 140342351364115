import React, { Fragment } from 'react';
import Helmet from 'react-helmet';
import Layout from '../../app/layout/Layout';
import { Menu } from '../../app/components/menu/Menu';
import Content from '../../app/layout/Content';
import Notifications from '../../dataroom/app/containers/Notifications';
import Span from '../../presentational/components/Span';
import {
  BODY_FONT,
  CLIENT_UI_COLOR,
  DANGER_COLOR,
} from '../../presentational/components/theme_blue';
import H2 from '../../presentational/components/H2';
import IconUser from '../../presentational/components/img/icons/IconUser';
import UserForm from '../components/user/UserForm';

export default function UserCreation(props) {
  return (
    <Fragment>
      <Helmet>
        <title>Espace client - Ajout d'un utilisateur</title>
      </Helmet>

      <Layout>
        <Menu users />

        <Content>
          <H2>
            <Span
              color={CLIENT_UI_COLOR}
              fontFamily={BODY_FONT}
              display="flex"
              alignItems="center"
              justifyContent="center"
              minWidth="100%"
            >
              <IconUser marginRight=".5em" marginBottom="-.1em" />
              Ajout d'un utilisateur
            </Span>
          </H2>
          <UserForm
            defaultValues={{
              firstName: null,
              lastName: null,
              email: null,
              role: 'ROLE_USER',
              password: null,
              passwordConfirmation: null,
            }}
          />
        </Content>
      </Layout>

      <Notifications />
    </Fragment>
  );
}
