import React, { Fragment } from 'react';
import theme_blue, {
  BODY_FONT,
  CLIENT_UI_COLOR,
  DARK_GREY_COLOR,
} from '../../presentational/components/theme_blue';
import Helmet from 'react-helmet';
import Layout from '../../app/layout/Layout';
import { Menu } from '../../app/components/menu/Menu';
import Content from '../../app/layout/Content';
import Div from '../../presentational/components/Div';
import Span from '../../presentational/components/Span';
import A from '../../presentational/components/A';
import P from '../../presentational/components/P';
import LoginForm from '../components/login/LoginForm';
import { Link, withRouter } from 'react-router';
import Notifications from '../../dataroom/app/containers/Notifications';

export default function Login(props) {
  return (
    <Fragment>
      <Helmet>
        <title>Espace client - Connexion</title>
      </Helmet>

      <Layout>
        <Menu onlyLogo />

        <Content>
          <P
            marginTop="2em"
            marginBottom="2em"
            textAlign="center"
            fontWeight="bold"
          >
            <Span color={CLIENT_UI_COLOR}>Bienvenue dans votre espace</Span>
          </P>
          <Div maxWidth="25em" margin="0 auto" fontWeight="300">
            <LoginForm onLoginSuccess={props.onLogin} />
            <Div
              fontSize=".75rem"
              marginTop="1rem"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <div></div>
              <div>
                <Span display="inline-block" margin=".125rem 0 .5rem">
                  <A
                    cursor="pointer"
                    as={Link}
                    to="/request-reset-password"
                    color={DARK_GREY_COLOR}
                  >
                    Mot de passe oublié&nbsp;?
                  </A>
                </Span>
              </div>
            </Div>
          </Div>
        </Content>

        <Notifications />
      </Layout>
    </Fragment>
  );
}
