import reduceReducers from 'reduce-reducers';
import dragDropDocument from '../ducks/dragDropDocument';
import pickDocument from '../ducks/pickDocument';
import moveDocument from '../ducks/moveDocument';
import apiClient from '../../core/apiClient';

const PDF_LEAVE = 'MERGED_PDF_LEAVE';
const PDF_OPEN = 'MERGED_PDF_OPEN';
const PDF_PREVIEW = 'MERGED_PDF_PREVIEW';

export const leavePdfMergedAction = () => ({
  type: PDF_LEAVE,
});

export const openPdfMergedAction = (nodeId, selectedNodes) => ({
  type: PDF_OPEN,
  nodeId: nodeId,
  nodes: selectedNodes,
});

export const previewPdfMergedAction = (nodeId, nodes, name) => {
  return (dispatch, getState) => {
    dispatch({
      type: PDF_PREVIEW,
      preview: true,
    });

    apiClient
      .request(
        new Request(`/api/nodes/${nodeId}/previewMergeDocumentPdf`, {
          method: 'PATCH',
          body: JSON.stringify({
            nodeId: nodeId,
            nodeName: name,
            nodeIdsToMerge: nodes.map(node => node.id),
          }),
        })
      )
      .then(response => response.blob())
      .then(data => {
        dispatch({
          type: PDF_PREVIEW,
          preview: false,
        });

        window.open(URL.createObjectURL(data));
      });
  };
};

const formatNodes = nodes =>
  nodes.map(node => {
    return {
      id: node.id,
      picked: false,
      dragging: false,
      multipleDrag: false,
      name: node.name,
      path: node.path,
    };
  });

const initialState = {
  nodes: [],
  pickedNodes: [],
  draggingNodes: [],
  open: false,
  nodeId: null,
  previewing: false,
};

function pdfMerged(state = initialState, action = {}) {
  switch (action.type) {
    case PDF_OPEN: {
      return {
        ...state,
        nodeId: action.nodeId,
        open: true,
        nodes: formatNodes(action.nodes),
      };
    }
    case 'PDF_MERGE_SUCCESS':
    case PDF_LEAVE: {
      return initialState;
    }
    case PDF_PREVIEW: {
      return {
        ...state,
        previewing: action.preview,
      };
    }
  }

  return state;
}

export default reduceReducers(
  pdfMerged,
  dragDropDocument,
  pickDocument,
  moveDocument
);
