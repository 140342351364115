import React, { Fragment, useState } from 'react';
import Content from '../layout/Content';
import P from '../../presentational/components/P';
import Span from '../../presentational/components/Span';
import Layout from '../layout/Layout';
import { Menu } from './menu/Menu';

export default function WaitingToConnect(props) {
  return (
    <Layout>
      <Menu onlyLogo />

      <Content>
        <P
          marginTop="2em"
          marginBottom="2em"
          textAlign="center"
          fontWeight="bold"
        >
          <Span>Chargement en cours...</Span>
        </P>
      </Content>
    </Layout>
  );
}
