import React, { Component } from 'react';
import styled from 'styled-components';

import {
  LIGHT_COLOR,
  DARK_COLOR,
  LOADING_OVERLAY_BACKGROUND_COLOR,
  LIGHT_GREY_COLOR,
} from './theme_blue';

const LoadingFullscreen = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${props => props.theme[LOADING_OVERLAY_BACKGROUND_COLOR]};
  z-index: ${props =>
    props.zIndex
      ? props.zIndex
      : 101}; /* z-index: 101 is above the responsive menu */
  justify-content: center;
  align-items: center;
  color: ${props => props.theme[LIGHT_COLOR]};
  font-size: 1rem;
`;
LoadingFullscreen.Content = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export default LoadingFullscreen;
