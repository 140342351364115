import React from 'react';
import Svg from './Svg';

export default function IconGroup(props) {
  return (
    <Svg
      {...props}
      d="M208.013 117.297l-19.37 15.061-19.359-15.061c-18.765 14.575-31.999 49.684-31.999 90.703H240c0-41.019-13.222-76.128-31.987-90.703zm-121.285 0l-19.371 15.061-19.358-15.061C29.234 131.872 16 166.981 16 208h102.715c0-41.019-13.222-76.128-31.987-90.703zm101.912 30.264l-18.526-14.414c-11.246 13.42-18.703 36.325-20.433 62.853h77.923c-1.729-26.527-9.178-49.433-20.424-62.855l-18.54 14.416zm-121.285 0l-18.526-14.414C37.583 146.567 30.126 169.472 28.396 196h77.923c-1.729-26.527-9.178-49.433-20.424-62.855l-18.54 14.416zm127.289-99.105c16.473 2.823 28.982 17.079 28.982 34.203 0 19.174-15.645 34.723-34.982 34.723s-34.983-15.549-34.983-34.723c0-17.124 12.51-31.38 28.983-34.203v-.511h12v.511zm-121.285 0c16.473 2.823 28.982 17.079 28.982 34.203 0 19.174-15.645 34.723-34.982 34.723s-34.983-15.549-34.983-34.723c0-17.124 12.51-31.38 28.983-34.203v-.511h12v.511zm114.103 11.519c-12.132.609-21.801 10.521-21.801 22.684 0 12.572 10.303 22.723 22.983 22.723 12.679 0 22.982-10.151 22.982-22.723 0-12.556-10.303-22.714-22.982-22.714l-.593.008-.589.022zm-121.285 0c-12.132.609-21.801 10.521-21.801 22.684 0 12.572 10.303 22.723 22.983 22.723 12.679 0 22.982-10.151 22.982-22.723 0-12.556-10.304-22.714-22.982-22.714l-.593.008-.589.022z"
    />
  );
}
