import React, { Component } from 'react';
import {
  Canvas,
  Viewer,
} from '../../../presentational/components/editor/PageViewer';
import CloseButton from '../../../presentational/components/editor/CloseButton';
import { DARK_COLOR } from '../../../presentational/components/theme_blue';

class FullScreenPage extends Component {
  constructor(props) {
    super(props);
    this.closeAction = this.closeAction.bind(this);
    this.canvasRef = React.createRef();
  }

  closeAction() {
    this.props.onClose();
  }

  calculateViewport(page, rotation) {
    const viewport = page.getViewport(1);
    const scale = this.props.width / viewport.width;

    return page.getViewport(scale, rotation);
  }

  renderPage(viewPort) {
    const canvas = this.canvasRef.current;
    canvas.height = viewPort.height;
    canvas.width = viewPort.width;

    this.props.page.pagePdf.render({
      viewport: viewPort,
      canvasContext: canvas.getContext('2d'),
    });
  }

  componentDidMount() {
    let page = this.props.page.pagePdf;
    const calculatedViewport = this.calculateViewport(
      page,
      this.props.page.rotation
    );

    this.renderPage(calculatedViewport);
  }

  componentWillReceiveProps(nextProps) {
    let page = this.props.page.pagePdf;
    let nextPropsPage = nextProps.pages.find(
      p => p.pageNumber === page.pageNumber
    );

    const currentViewport = this.calculateViewport(
      page,
      nextPropsPage.rotation
    );
    this.renderPage(currentViewport);
  }

  render() {
    return (
      <Viewer background={DARK_COLOR} className="pdfFullScreen">
        <CloseButton
          viewer
          background={DARK_COLOR}
          onClick={this.closeAction}
        />
        <Canvas ref={this.canvasRef} className="pdfFullScreen" />
      </Viewer>
    );
  }
}

export default FullScreenPage;
