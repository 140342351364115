import {
  addDraggingObject,
  draggedObjectIsOneOfPickedObject,
  resetDraggingObject,
} from '../../dataroom/utils/dragDropObject';

const BEGIN_DRAG = 'EDITOR_MERGED_BEGIN_DRAG_NODE';
const END_DRAG = 'EDITOR_MERGED_END_DRAG_NODE';

export const beginDragAction = nodeId => ({
  type: BEGIN_DRAG,
  id: nodeId,
});

export const endDragAction = () => ({
  type: END_DRAG,
});

export default function reducer(state, action) {
  switch (action.type) {
    case BEGIN_DRAG: {
      let nodesToDrag = [action.id];

      if (draggedObjectIsOneOfPickedObject(action.id, state.pickedNodes)) {
        nodesToDrag = [...state.pickedNodes];
      }

      const multipleDrag = nodesToDrag.length > 1;

      let nodes = [...state.nodes];

      for (let nodeToDrag of nodesToDrag) {
        nodes = addDraggingObject(nodes, nodeToDrag, multipleDrag, 'id');
      }

      return {
        ...state,
        nodes: nodes,
        draggingNodes: nodesToDrag,
      };
    }
    case END_DRAG: {
      let newState = {
        ...state,
        nodes: [...state.nodes],
      };

      return resetDraggingObject(newState, 'nodes', 'draggingNodes', 'id');
    }
  }

  return state;
}
