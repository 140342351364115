import apiClient from '../../core/apiClient';
import { REMOVE_PAGE, REMOVE_PAGE_NUMBER } from './pickPage';
import { ROTATE_PDF, ROTATE_PDF_PAGE_NUMBER } from './editPage';

const START_PDF_ERRORS_DETECTION = 'START_PDF_ERRORS_DETECTION';
const PDF_ERRORS_DETECTION_STARTED = 'PDF_ERRORS_DETECTION_STARTED';

export const UPDATE_DETECTION_INFO = 'UPDATE_DETECTION_INFO';

const PDF_PAGE_ERROR_FIXED = 'PDF_PAGE_ERROR_FIXED';
const PDF_PAGE_ERROR_SCROLL_DONE = 'PDF_PAGE_ERROR_SCROLL_DONE';

const CHANGE_CURRENT_ERROR = 'CHANGE_CURRENT_ERROR';

export const STATUS_BUILDING = 'STATUS_BUILDING';
export const STATUS_PROCESSING = 'STATUS_PROCESSING';
export const STATUS_FINISHED = 'STATUS_FINISHED';

export const ERROR_TYPE_WHITE_PAGE = 'ERROR_TYPE_WHITE_PAGE';
export const ERROR_TYPE_EAST_ORIENTATION = 'ERROR_TYPE_EAST_ORIENTATION';
export const ERROR_TYPE_SOUTH_ORIENTATION = 'ERROR_TYPE_SOUTH_ORIENTATION';
export const ERROR_TYPE_WEST_ORIENTATION = 'ERROR_TYPE_WEST_ORIENTATION';
export const ERROR_TYPE_UNKNOWN_ORIENTATION = 'ERROR_TYPE_UNKNOWN_ORIENTATION';
export const ERROR_TYPE_UNKNOWN_OPERATION = 'ERROR_TYPE_UNKNOWN_OPERATION';

export const STATUS_SCROLL_NONE = 'STATUS_SCROLL_NONE';
export const STATUS_SCROLL_IMMEDIATLY = 'STATUS_SCROLL_IMMEDIATLY';
export const STATUS_SCROLL_DELAYED = 'STATUS_SCROLL_DELAYED';

function getExpectedRotationFromRotationError(errorType) {
  switch (errorType) {
    case ERROR_TYPE_EAST_ORIENTATION:
      return 270;
    case ERROR_TYPE_SOUTH_ORIENTATION:
      return 180;
    case ERROR_TYPE_WEST_ORIENTATION:
      return 90;
  }

  return 0;
}

function generateResolveActionFromError(error, pages) {
  const page = pages.find(page => page.pageNumber === error.pageNumber);

  if (!page) {
    return;
  }

  if (error.errorType === ERROR_TYPE_WHITE_PAGE) {
    return {
      type: REMOVE_PAGE_NUMBER,
      pageNumber: error.pageNumber,
    };
  }

  let expectedRotation = getExpectedRotationFromRotationError(error.errorType);

  if (expectedRotation === 0) {
    return;
  }

  expectedRotation -= page.rotation;
  expectedRotation = expectedRotation % 360;

  if (expectedRotation === 0) {
    return;
  }

  return {
    type: ROTATE_PDF_PAGE_NUMBER,
    pageNumber: error.pageNumber,
    rotation: expectedRotation,
  };
}

export function fixPdfPageErrorAction(error) {
  return (dispatch, getState) => {
    const pages = getState().pdfEditor.pages;

    const solveAction = generateResolveActionFromError(error, pages);
    if (solveAction) {
      dispatch(solveAction);
    }
  };
}

export function removeErrorAction(error) {
  return {
    type: PDF_PAGE_ERROR_FIXED,
    error,
  };
}

export function scrollDoneStatusAction() {
  return {
    type: PDF_PAGE_ERROR_SCROLL_DONE,
  };
}

export function changeCurrentError(error) {
  return {
    type: CHANGE_CURRENT_ERROR,
    error,
  };
}

export function startPdfErrorsDetectionOnNodeAction(nodeId) {
  return async dispatch => {
    dispatch({ type: START_PDF_ERRORS_DETECTION });

    const data = await apiClient
      .request(
        new Request(`/api/nodes/${nodeId}/start_pdf_errors_detection`, {
          method: 'PATCH',
        })
      )
      .then(response => response.json());

    dispatch({
      type: PDF_ERRORS_DETECTION_STARTED,
      pdfDetectionId: data.pdfDetectionId,
    });
  };
}

export const defaultPdfPageErrorsState = {
  pdfDetectionId: null,
  inProgress: false,
  errors: [],
  currentErrorId: null,
  scrollStatus: STATUS_SCROLL_NONE,
  status: null,
  treatedPages: 0,
};

function isErrorFixed(error, pages) {
  const page = pages.find(page => page.pageNumber === error.pageNumber);
  if (!page) {
    return true;
  }

  if (
    error.errorType === ERROR_TYPE_WHITE_PAGE ||
    error.errorType === ERROR_TYPE_UNKNOWN_ORIENTATION ||
    error.errorType === ERROR_TYPE_UNKNOWN_OPERATION
  ) {
    return false;
  }

  let expectedRotation = getExpectedRotationFromRotationError(error.errorType);

  if (expectedRotation === 0) {
    return true;
  }

  expectedRotation = expectedRotation - page.rotation + 360;
  expectedRotation = expectedRotation % 360;

  return expectedRotation === 0;
}

function getNextErrorFromCurrentErrorId(currentErrorId, errors) {
  if (!currentErrorId) {
    const firstUnfixedError = errors.find(error => !error.fixed);

    return firstUnfixedError
      ? [firstUnfixedError.id, firstUnfixedError.pageNumber]
      : [null, null];
  }

  let currentErrorIndex = errors.findIndex(
    error => error.id === currentErrorId
  );

  let nextErrors = errors.slice(currentErrorIndex);

  let nextUnfixedError = nextErrors.find(
    error => !error.fixed && error.id !== currentErrorId
  );

  if (!nextUnfixedError) {
    nextUnfixedError = errors.find(
      error => !error.fixed && error.id !== currentErrorId
    );
  }

  if (nextUnfixedError) {
    return [nextUnfixedError.id, nextUnfixedError.pageNumber];
  }

  return [null, null];
}

export default function pdfPageErrors(state, action) {
  switch (action.type) {
    case CHANGE_CURRENT_ERROR:
      return {
        ...state,
        pdfPageErrors: {
          ...state.pdfPageErrors,
          currentErrorId: action.error.id,
          currentPageNumber: action.error.pageNumber,
          scrollStatus: STATUS_SCROLL_IMMEDIATLY,
        },
      };
    case UPDATE_DETECTION_INFO:
      let newErrors = [...state.pdfPageErrors.errors];
      for (let pdfPageError of action.pdfErrorsDetection.pdfPageErrors) {
        if (newErrors.findIndex(error => error.id === pdfPageError.id) !== -1) {
          continue;
        }

        if (isErrorFixed(pdfPageError, state.pages)) {
          pdfPageError = { ...pdfPageError, fixed: true };
        }

        newErrors.push(pdfPageError);
      }

      let currentErrorId = state.pdfPageErrors.currentErrorId;
      let currentPageNumber = state.pdfPageErrors.currentPageNumber;
      if (!currentErrorId) {
        const firstUnfixedError = newErrors.find(error => !error.fixed);
        if (firstUnfixedError) {
          currentErrorId = firstUnfixedError.id;
          currentPageNumber = firstUnfixedError.pageNumber;
        }
      }

      return {
        ...state,
        pdfPageErrors: {
          ...state.pdfPageErrors,
          currentErrorId,
          currentPageNumber,
          errors: newErrors,
          status: action.pdfErrorsDetection.status,
          treatedPageNumber: action.pdfErrorsDetection.treatedPageNumber,
        },
      };
    case START_PDF_ERRORS_DETECTION:
      return {
        ...state,
        pdfPageErrors: {
          ...state.pdfPageErrors,
          inProgress: true,
          currentDetectionType: STATUS_BUILDING,
        },
      };
    case PDF_ERRORS_DETECTION_STARTED:
      return {
        ...state,
        pdfPageErrors: {
          ...state.pdfPageErrors,
          pdfDetectionId: action.pdfDetectionId,
          scrollStatus: STATUS_SCROLL_IMMEDIATLY,
        },
      };
    case PDF_PAGE_ERROR_FIXED: {
      const newErrors = state.pdfPageErrors.errors.map(error => {
        if (error.id === action.error.id) {
          return {
            ...error,
            fixed: true,
          };
        }

        return error;
      });

      let currentErrorId = state.pdfPageErrors.currentErrorId;
      let currentPageNumber = state.pdfPageErrors.currentPageNumber;
      if (action.error.id === currentErrorId) {
        [currentErrorId, currentPageNumber] = getNextErrorFromCurrentErrorId(
          currentErrorId,
          newErrors
        );
      }

      return {
        ...state,
        pdfPageErrors: {
          ...state.pdfPageErrors,
          currentErrorId,
          currentPageNumber,
          errors: newErrors,
          scrollStatus: STATUS_SCROLL_IMMEDIATLY,
        },
      };
    }
    case PDF_PAGE_ERROR_SCROLL_DONE: {
      return {
        ...state,
        scrollStatus: STATUS_SCROLL_NONE,
      };
    }
    case REMOVE_PAGE:
    case REMOVE_PAGE_NUMBER:
    case ROTATE_PDF:
    case ROTATE_PDF_PAGE_NUMBER: {
      if (state.pdfPageErrors.errors.length === 0) {
        return state;
      }

      let pagesNumbersToEdit =
        action.type === REMOVE_PAGE || action.type === ROTATE_PDF
          ? state.pickedPageNumbers
          : [action.pageNumber];

      let errorsToFix = [];

      for (let pageNumberToEdit of pagesNumbersToEdit) {
        let errorOnPage = state.pdfPageErrors.errors.find(
          error => error.pageNumber === pageNumberToEdit
        );
        if (!errorOnPage) {
          continue;
        }

        if (action.type === REMOVE_PAGE || action.type === REMOVE_PAGE_NUMBER) {
          errorsToFix.push(errorOnPage.id);
        }

        if (
          errorOnPage.errorType === ERROR_TYPE_WHITE_PAGE ||
          errorOnPage.errorType === ERROR_TYPE_UNKNOWN_ORIENTATION ||
          errorOnPage.errorType === ERROR_TYPE_UNKNOWN_OPERATION
        ) {
          continue;
        }

        let expectedRotation = getExpectedRotationFromRotationError(
          errorOnPage.errorType
        );
        let pageToEdit = state.pages.find(
          page => page.pageNumber === pageNumberToEdit
        );
        if (!pageToEdit) {
          continue;
        }

        expectedRotation -= pageToEdit.rotation;
        expectedRotation -= action.rotation;
        expectedRotation = expectedRotation % 360;

        if (expectedRotation === 0) {
          errorsToFix.push(errorOnPage.id);
        }
      }

      const newErrors = state.pdfPageErrors.errors.map(error => {
        if (errorsToFix.includes(error.id)) {
          return {
            ...error,
            fixed: true,
          };
        }

        return error;
      });

      let currentErrorId = state.pdfPageErrors.currentErrorId;
      let currentPageNumber = state.pdfPageErrors.currentPageNumber;
      const hasFixedCurrentError =
        errorsToFix.findIndex(errorToFix => errorToFix === currentErrorId) !==
        -1;

      if (hasFixedCurrentError) {
        [currentErrorId, currentPageNumber] = getNextErrorFromCurrentErrorId(
          currentErrorId,
          newErrors
        );
      }

      return {
        ...state,
        pdfPageErrors: {
          ...state.pdfPageErrors,
          currentErrorId,
          currentPageNumber,
          errors: newErrors,
          scrollStatus: STATUS_SCROLL_DELAYED,
        },
      };
    }
  }

  return state;
}
