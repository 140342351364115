import React, {
  Fragment,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import useApiClient, { useQuery } from '../../../hooks/apiClient';
import Select, { loadSelectItemsFromApi } from './Select';

export function defaultNormalizer(item) {
  return {
    id: item.id,
    label: `${item.firstName} ${item.lastName.toUpperCase()}`,
  };
}

export default function UserSelect(props) {
  const baseUrlParameters = props.baseUrlParameters || {};
  if (props.excludeIds) {
    baseUrlParameters.searchOn = {
      ...baseUrlParameters.searchOn,
      excludeIds: props.excludeIds,
    };
  }

  if (props.company) {
    baseUrlParameters.searchOn = {
      ...baseUrlParameters.searchOn,
      company: true,
    };
  }

  if (props.excludeAdmin) {
    baseUrlParameters.searchOn = {
      ...baseUrlParameters.searchOn,
      excludeAdmin: true,
    };
  }

  const apiClient = useApiClient();

  return (
    <Select
      isHorizontal
      placeholder={props.placeholder}
      isRequired={props.isRequired}
      labelTextRight={props.labelTextRight}
      label={props.label}
      onChange={props.onChange}
      value={props.value}
      loadSelectItems={function(abortController, inputValue, page) {
        return loadSelectItemsFromApi(
          abortController,
          inputValue,
          page,
          '/api/users',
          apiClient,
          defaultNormalizer,
          baseUrlParameters
        );
      }}
    />
  );
}
