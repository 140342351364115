import React from 'react';
import styled from 'styled-components';
import ErrorMessage from './ErrorMessage';

const Span = styled.span`
  display: block;
  width: 100%;
  flex-basis: 100%;
  color: ${props =>
    props.hasError ? props.theme.dangerColor : props.theme.darkColor};
  font-size: 0.75em;
`;
class Helper extends React.Component {
  render() {
    return (
      <Span hasError={this.props.hasError}>
        {this.props.errorMessage && (
          <ErrorMessage>{this.props.errorMessage}</ErrorMessage>
        )}
        {this.props.children}
      </Span>
    );
  }
}

export default Helper;
