import React, { Component } from 'react';
import ResizeObserverPolyfill from 'resize-observer-polyfill';
import NodeNameButtons, {
  upperCaseSelection,
  addPlaceholder,
} from './NodeNameButtons';
import { throttle } from 'underscore';

class RenameNode extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleAddPlaceholder = this.handleAddPlaceholder.bind(this);
    this.upperCase = this.upperCase.bind(this);
    this.setInputWidth = this.setInputWidth.bind(this);
    this.setInputHeight = throttle(this.setInputHeight, 5);
    this.handleUpperCaseKeyboardShortcut = this.handleUpperCaseKeyboardShortcut.bind(
      this
    );

    this.state = {
      name: props.name,
      nameValid: props.name.trim().length > 0,
      submitting: false,
      height: 30,
    };

    this.nameSubmitted = false;
    this.inputRef = React.createRef();
    this.formRef = React.createRef();

    // TODO: delete this when vince fix the problem with input max size
    if (typeof ResizeObserver !== 'undefined') {
      this.resizeObserver = new ResizeObserver(() => {
        this.setInputWidth();
      });
    } else {
      this.resizeObserver = new ResizeObserverPolyfill(() => {
        this.setInputWidth();
      });
    }
  }

  setInputWidth() {
    if (!this.formRef.current) {
      return;
    }

    const pathWidth = this.props.path
      .split('.')
      .reduce(width => width + 16, 16);

    this.setState({
      inputWidth:
        Math.floor(
          this.formRef.current.closest('.i').getBoundingClientRect().width
        ) -
        295 -
        pathWidth,
    });
  }

  handleSubmit(evt) {
    evt.preventDefault();

    this.setState({
      submitting: true,
    });
    this.nameSubmitted = true;
    this.props.renameNodeAction(this.state.name, this.props.nodeId);
  }

  handleCancel(evt) {
    evt.preventDefault();

    this.props.cancelRenameAction();
  }

  handleAddPlaceholder(evt) {
    evt.preventDefault();
    const result = addPlaceholder(this.state.name, this.inputRef);

    this.setState(
      {
        name: result.name,
      },
      result.afterUpdate
    );
  }

  upperCase(evt) {
    if (evt) {
      evt.preventDefault();
    }

    if (document.activeElement !== this.inputRef.current) {
      return;
    }

    const result = upperCaseSelection(this.state.name, this.inputRef);

    this.setState(
      {
        name: result.name,
      },
      result.afterUpdate
    );
  }

  componentDidMount() {
    this.inputRef.current.focus();

    this.setInputWidth();
    this.resizeObserver.observe(this.formRef.current.closest('.i'));

    document.addEventListener('keydown', this.handleUpperCaseKeyboardShortcut);
  }

  setInputHeight() {
    if (this.state.height === this.inputRef.current.scrollHeight + 2) {
      return;
    }

    this.setState({
      height: this.inputRef.current.scrollHeight + 2,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    this.setInputHeight();
  }

  handleUpperCaseKeyboardShortcut(evt) {
    if (evt.key === 'F3' && evt.shiftKey) {
      evt.preventDefault();
      this.upperCase();
    }
  }

  componentWillUnmount() {
    document.removeEventListener(
      'keydown',
      this.handleUpperCaseKeyboardShortcut
    );

    this.resizeObserver.disconnect();

    if (!this.nameSubmitted) {
      this.props.cancelRenameAction();
    }
  }

  render() {
    return (
      // stop propagation of double click to avoid document detail trigger
      <form
        onDoubleClick={evt => evt.stopPropagation()}
        className="renaming-node-form"
        onSubmit={this.handleSubmit}
        ref={this.formRef}
      >
        <span className="rename-add-node-area rename">
          <span className="input-group-sm">
            <textarea
              className="form-control"
              disabled={this.state.submitting}
              ref={this.inputRef}
              placeholder="Saisissez le nom de la ligne"
              value={this.state.name}
              maxLength={1024}
              onKeyDown={evt => {
                if (evt.key === 'Escape') {
                  this.handleCancel(evt);
                }
              }}
              onKeyPress={evt => {
                if (evt.key === 'Enter') {
                  this.handleSubmit(evt);
                }
              }}
              onChange={evt =>
                this.setState({
                  name: evt.target.value,
                  nameValid: evt.target.value.trim().length > 0,
                })
              }
              style={{
                width: this.state.inputWidth,
                resize: 'none',
                height: `${this.state.height}px`,
                boxSizing: 'border-box',
                minHeight: '30px',
                display: 'block',
                overflow: 'hidden',
              }}
            />

            <NodeNameButtons
              formValid={this.state.nameValid}
              submitting={this.state.submitting}
              upperCase={this.upperCase}
              handleAddPlaceholder={this.handleAddPlaceholder}
              handleCancel={this.handleCancel}
            />
          </span>
        </span>
      </form>
    );
  }
}

export default RenameNode;
