import React, { Fragment } from 'react';
import theme_blue, {
  BODY_FONT,
  CLIENT_UI_COLOR,
  DARK_GREY_COLOR,
} from '../../presentational/components/theme_blue';
import Helmet from 'react-helmet';
import Layout from '../../app/layout/Layout';
import Content from '../../app/layout/Content';
import Span from '../../presentational/components/Span';
import P from '../../presentational/components/P';
import { Menu } from '../components/menu/Menu';

export default function NotFound(props) {
  return (
    <Fragment>
      <Helmet>
        <title>Espace client</title>
      </Helmet>

      <Layout>
        <Menu />

        <Content>
          <P
            marginTop="2em"
            marginBottom="2em"
            textAlign="center"
            fontWeight="bold"
          >
            <Span color={CLIENT_UI_COLOR}>Page non trouvée</Span>
          </P>
        </Content>
      </Layout>
    </Fragment>
  );
}
