function getParentIds(nodeId, nodes) {
  if (nodes[nodeId].path === '0') {
    return [];
  }

  let currentNodeId = nodes[nodeId].parentId;
  const parentIds = [];
  while (currentNodeId) {
    parentIds.push(currentNodeId);
    currentNodeId = nodes[currentNodeId].parentId;
  }

  return parentIds;
}

export default (nodeId, nodes, documentWithFileNumberToAdd = 1) => {
  const nodeIdsToUpdate = getParentIds(nodeId, nodes);
  nodeIdsToUpdate.push(nodeId);
  const newNodes = { ...nodes };

  for (let nodeIdToUpdate of nodeIdsToUpdate) {
    newNodes[nodeIdToUpdate] = {
      ...newNodes[nodeIdToUpdate],
      documentWithFileNumber:
        newNodes[nodeIdToUpdate].documentWithFileNumber +
        documentWithFileNumberToAdd,
    };
  }

  return newNodes;
};

export const addDocumentNumberWithoutFile = (
  nodeId,
  nodes,
  numberToAdd = 1
) => {
  const nodeIdsToUpdate = getParentIds(nodeId, nodes);
  nodeIdsToUpdate.push(nodeId);
  const newNodes = { ...nodes };

  for (let nodeIdToUpdate of nodeIdsToUpdate) {
    newNodes[nodeIdToUpdate] = {
      ...newNodes[nodeIdToUpdate],
      documentWithoutFileNumber:
        newNodes[nodeIdToUpdate].documentWithoutFileNumber + numberToAdd,
    };
  }

  return newNodes;
};
