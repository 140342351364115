import styled from 'styled-components';
import { DANGER_COLOR, PRIMARY_COLOR } from '../theme_blue';

export default styled.span`
  display: block;
  text-align: center;
  padding: 0.25em;
  user-select: none;
  color: ${props => {
    if (props.currentErroredPage) {
      return props.theme[DANGER_COLOR];
    }
    if (props.color) {
      return props.theme[props.color];
    }

    return 'inherit';
  }};
`;
