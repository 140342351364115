import apiClient from '../../core/apiClient';
import queryString from 'qs';
import { delay } from 'redux-saga';
import { call, takeLatest, all, put, select } from 'redux-saga/effects';
import { omit } from 'underscore';

export const FOLD = 'FOLD';
export const UNFOLD = 'UNFOLD';

export const FOLD_ALL = 'FOLD_ALL';
export const UNFOLD_ALL = 'UNFOLD_ALL';

export const FOLD_RECURSIVE = 'FOLD_RECURSIVE';
export const UNFOLD_RECURSIVE = 'UNFOLD_RECURSIVE';

export const SELECT = 'SELECT_NODE';
export const UNSELECT = 'UNSELECT_NODE';

export const OPEN_PDF_EDITOR = 'OPEN_PDF_EDITOR';
export const CLOSE_PDF_EDITOR = 'CLOSE_PDF_EDITOR';

export const LOAD_DATAROOM = 'LOAD_DATAROOM';

export const FETCH_DATAROOM = 'FETCH_DATAROOM';

export const START_SEARCH = 'START_SEARCH';
export const UPDATE_SEARCH = 'UPDATE_SEARCH';

export const ADD_NODES = 'ADD_NODE';

export const FETCH_DATAROOM_NODES = 'FETCH_DATAROOM_NODES';
export const LOAD_DATAROOM_NODES = 'LOAD_DATAROOM_NODES';

export const TOGGLE_IS_LARGE_DATAROOM = 'TOGGLE_IS_LARGE_DATAROOM';

export const REFRESH_NODES = 'REFRESH_NODES';

export const RESET_DATAROOM = 'RESET_DATAROOM';
export const START_DATAROOM = 'START_DATAROOM';

export function unSelectNode(nodeId) {
  return {
    type: UNSELECT,
    nodeId: nodeId,
  };
}

export function resetDataroom() {
  return {
    type: RESET_DATAROOM,
  };
}

export function startDataroom() {
  return {
    type: START_DATAROOM,
  };
}

export function selectNode(nodeId) {
  return {
    type: SELECT,
    nodeId: nodeId,
  };
}

export function foldRecursive(nodeId) {
  return {
    type: FOLD_RECURSIVE,
    nodeId,
  };
}

export function unFoldRecursive(nodeId) {
  return {
    type: UNFOLD_RECURSIVE,
    nodeId,
  };
}

export function openPdfEditor(nodeId) {
  return {
    type: OPEN_PDF_EDITOR,
    nodeId: nodeId,
  };
}

export function closePdfEditor() {
  return {
    type: CLOSE_PDF_EDITOR,
  };
}

export function foldAll() {
  return {
    type: FOLD_ALL,
  };
}

export function unFoldAll() {
  return {
    type: UNFOLD_ALL,
  };
}

export function fold(node) {
  return {
    type: FOLD,
    nodeId: node.id,
  };
}

export function unFold(node) {
  return {
    type: UNFOLD,
    nodeId: node.id,
  };
}

export function updateSearch(search) {
  return {
    type: UPDATE_SEARCH,
    search: search,
  };
}

export function toggleIsLargeDataroomAction(isLargeDataroom) {
  return {
    type: TOGGLE_IS_LARGE_DATAROOM,
    isLargeDataroom,
  };
}

export function addNodes(nodes, destinationId, position, withUpload = false) {
  return {
    type: ADD_NODES,
    nodes,
    destinationId,
    position,
    withUpload,
  };
}

function* triggerSearchSaga(action) {
  let debounceTime = 300;
  if (
    !action.search.beginDate &&
    !action.search.endDate &&
    (!action.search.text || action.search.text.length < 3)
  ) {
    debounceTime = 600;
  }

  yield call(delay, debounceTime);

  yield put({
    type: START_SEARCH,
    ...omit(action, 'type'),
  });
}

function* searchSaga() {
  yield takeLatest(UPDATE_SEARCH, triggerSearchSaga);
}

export function* rootSaga() {
  yield all([searchSaga()]);
}

export function loadDataroomNodes(dataroomId) {
  return dispatch => {
    dispatch({
      type: FETCH_DATAROOM_NODES,
    });

    apiClient
      .request(
        new Request(
          `/api/nodes?${queryString.stringify({
            items: '',
            searchOn: { dataroom: dataroomId },
          })}`
        )
      )
      .then(response => response.json())
      .then(nodes => {
        dispatch({
          type: LOAD_DATAROOM_NODES,
          nodes: nodes.nodes,
        });
      });
  };
}

export function loadDataroom(id, anonymous) {
  return (dispatch, getState) => {
    dispatch({
      type: FETCH_DATAROOM,
      anonymous: anonymous,
      dataroomId: id,
    });

    let query = {
      resources: ['DATAROOM_NODES', 'DATAROOM_LOCK'],
      rights: ['DATAROOM_LOCK', 'DATAROOM_UNLOCK', 'DATAROOM_EDITION_MODE'],
    };

    return apiClient
      .request(
        new Request(`/api/datarooms/${id}?${queryString.stringify(query)}`)
      )
      .then(response => {
        if (response.ok) {
          return response.json().then(dataroom => {
            dispatch({
              type: LOAD_DATAROOM,
              dataroom: dataroom,
            });
          });
        }
      });
  };
}
