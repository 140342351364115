import updatePath from './updatePath';
import getPathLevel from './getPathLevel';
import mapDescendant from './mapDescendant';
import childNode from './childNode';

export default (nodes, nodeId) => {
  let pathLevel = getPathLevel(nodes[nodeId].path);
  const parentId = nodes[nodeId].parentId;
  const parentNode = nodes[nodes[nodeId].parentId];

  const newNodes = mapDescendant(nodes, nodeId, node => {
    return {
      ...node,
      path: updatePath(pathLevel, node.path, position => position - 1),
    };
  });

  newNodes[parentId] = {
    ...parentNode,
    childIds: parentNode.childIds.filter(id => id !== nodeId),
  };

  const idsToDelete = [];
  for (const childNodeItem of childNode(nodes, nodeId)) {
    idsToDelete.push(childNodeItem.id);
  }
  idsToDelete.forEach(id => {
    delete newNodes[id];
  });
  delete newNodes[nodeId];

  return newNodes;
};
