import createUndoableActionReducer from '../reducers/createUndoableActionReducer';
import { takeEvery, call } from 'redux-saga/effects';
import undoSaga from './dataroomActions/undoSaga';

import apiClient from 'js/dataroom/core/apiClient';
import { uniqueId } from 'underscore';

export const PDF_EDIT = 'PDF_EDIT';

export function editPdfAction(nodeId, pages) {
  return {
    type: PDF_EDIT,
    nodeId,
    pages,
    frontActionId: uniqueId('dataroom_action'),
  };
}

export const validatePdfEditorApi = action =>
  apiClient.request(
    new Request(`/api/nodes/${action.nodeId}/editDocumentPdf`, {
      method: 'PATCH',
      body: JSON.stringify(action.pages),
    })
  );

export function* saga(action) {
  return yield call(validatePdfEditorApi, action);
}

export function* undoActionSaga() {
  yield takeEvery('UNDO_PDF_EDIT', undoSaga);
}

export const editPdf = (state, action) => {
  const node = state.working.nodes[action.nodeId];

  return {
    ...state,
    working: {
      ...state.working,
      savedNodes: {
        ...state.working.savedNodes,
        [action.frontActionId]: {
          [node.id]: { ...node },
        },
      },
      nodes: {
        ...state.working.nodes,
        [action.nodeId]: {
          ...state.working.nodes[action.nodeId],
          file: {
            ...state.working.nodes[action.nodeId].file,
            editedAt: new Date(),
          },
        },
      },
    },
  };
};

export const undoEditPdf = (state, action) => {
  const oldNode = state.working.savedNodes[action.frontActionId][action.nodeId];

  return {
    ...state,
    working: {
      ...state.working,
      nodes: {
        ...state.working.nodes,
        [action.nodeId]: {
          ...state.working.nodes[action.nodeId],
          file: oldNode.file,
        },
      },
    },
  };
};

export default createUndoableActionReducer(PDF_EDIT, editPdf, undoEditPdf);
