import React from 'react';
import ClientUI from '../../../presentational/components/ClientUI';
import Div from '../../../presentational/components/Div';
import { Link } from 'react-router';
import A from '../../../presentational/components/A';
import { useAuthDataContext } from '../../hooks/authDataProvider';

const Logo = require('../../../../img/acme-logo.svg');

export function Menu(props) {
  const user = useAuthDataContext()?.user;

  return (
    <ClientUI.Header id="nav_bar" isOrangeSkin={props.isOrangeSkin}>
      <Div display="flex" justifyContent="between" alignItems="center">
        <A to="/" as={Link}>
          <ClientUI.ClientLogo
            src={Logo}
            alt="ACME"
            isOrangeSkin={props.isOrangeSkin}
          />
        </A>
        {!props.onlyLogo && (
          <ClientUI.MainNav>
            <ClientUI.MainNavLink
              as={Link}
              to={'/'}
              isOrangeSkin={props.isOrangeSkin}
              active={props.dataroom}
            >
              Datarooms
            </ClientUI.MainNavLink>
            <ClientUI.MainNavLink
              as={Link}
              to={'/users'}
              isOrangeSkin={props.isOrangeSkin}
              active={props.users}
            >
              Utilisateurs
            </ClientUI.MainNavLink>
            <ClientUI.MainNavLink
              as={Link}
              to="/me"
              isOrangeSkin={props.isOrangeSkin}
              active={props.me}
            >
              Mon compte ({user.firstName})
            </ClientUI.MainNavLink>
          </ClientUI.MainNav>
        )}
      </Div>
    </ClientUI.Header>
  );
}
