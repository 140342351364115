import accents from './accentCorrespondance.js';
import { uniq } from 'underscore';

export default function textMatch(subjectText, searchText) {
  const subject = subjectText.toLowerCase();
  const normalizedSearchText = searchText.trim().toLowerCase();
  const asciiFoldedSubject = asciiFoldWord(subject);
  let asciiFoldedSearchWords = asciiFoldWord(normalizedSearchText).split(' ');

  return oneOfSubjectMatchSearch(
    [asciiFoldedSubject],
    uniq(asciiFoldedSearchWords)
  );
}

function oneOfSubjectMatchSearch(subjects, searchWords) {
  for (var i = 0; i < subjects.length; i++) {
    if (subjectMatchAllOfWords(subjects[i], searchWords)) {
      return true;
    }
  }

  return false;
}

function subjectMatchAllOfWords(subject, searchWords) {
  for (var i = 0; i < searchWords.length; i++) {
    if (subject.indexOf(searchWords[i]) == -1) {
      return false;
    }
  }

  return true;
}

function asciiFoldWord(word) {
  let i = word.length - 1;
  let wordChars = word.split('');

  for (; i >= 0; i--) {
    let ch = wordChars[i];
    if (accents.hasOwnProperty(ch)) {
      wordChars[i] = accents[ch];
    }
  }

  return wordChars.join('');
}
