import React from 'react';
import Svg from './Svg';

export default function IconErrorsScan(props) {
  return (
    <Svg
      {...props}
      d="M159.569 168.055c-15.228 13.266-35.128 21.303-56.89 21.303-47.839 0-86.679-38.84-86.679-86.679S54.84 16 102.679 16s86.679 38.84 86.679 86.679c0 21.762-8.037 41.662-21.303 56.89L240 231.515 231.515 240l-71.946-71.945zM102.679 28c41.216 0 74.679 33.463 74.679 74.679s-33.463 74.679-74.679 74.679S28 143.895 28 102.679 61.463 28 102.679 28zm10.289 100h-20v20h20v-20zm0-78h-20v60.133h20V50z"
    />
  );
}
