import React, { Component, Fragment } from 'react';
import UpperCaseNodeNameButton from './UpperCaseNodeNameButton';
import AddPlaceholderButton from './AddPlaceholderButton';
export { upperCaseSelection } from './UpperCaseNodeNameButton';
export { addPlaceholder } from './AddPlaceholderButton';

class NodeNameButtons extends Component {
  render() {
    return (
      <Fragment>
        <span className="input-group-btn">
          <button
            tabIndex="-1"
            type="submit"
            className="btn btn-primary"
            title="Valider"
            disabled={!this.props.formValid || this.props.submitting}
          >
            {' '}
            <span className="glyphicon glyphicon-ok" />{' '}
            <span className="sr-only">Valider</span>{' '}
          </button>
        </span>
        <UpperCaseNodeNameButton
          upperCase={this.props.upperCase}
          submitting={this.props.submitting}
        />
        <AddPlaceholderButton
          handleAddPlaceholder={this.props.handleAddPlaceholder}
          submitting={this.props.submitting}
        />
        <span className="input-group-btn">
          <button
            tabIndex="-1"
            type="button"
            className="btn btn-dark"
            title="Annuler"
            onClick={this.props.handleCancel}
            disabled={this.props.submitting}
          >
            {' '}
            <span className="glyphicon glyphicon-remove" />{' '}
            <span className="sr-only">Annuler</span>{' '}
          </button>
        </span>
      </Fragment>
    );
  }
}

export default NodeNameButtons;
