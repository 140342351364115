import React from 'react';
import styled from 'styled-components';
import CloseButton from './CloseButton';
import { PRIMARY_COLOR, LIGHT_COLOR } from '../theme_blue';

export const Viewer = styled.div`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-color: ${props =>
    props.background
      ? props.theme[props.background]
      : props.theme[PRIMARY_COLOR]};
  color: ${props => props.theme[LIGHT_COLOR]};
  padding: 0.5em;
`;

export const Canvas = styled.canvas`
  display: block;
  max-width: 100%;
  height: 100%;
  margin: auto;
  ${props =>
    props.src
      ? 'background-repeat: no-repeat;' +
        'background-size: contain;' +
        'background-position: 50% 50%;' +
        'background-image: url(' +
        props.src +
        ');'
      : null};
`;

export default function PageViewer(props) {
  return (
    <Viewer>
      <CloseButton />
      <Canvas src={props.src} />
    </Viewer>
  );
}
