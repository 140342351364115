import React from 'react';
import styled from 'styled-components';
import FieldGroup from './FieldGroup';
import Label from './Label';
import FieldAndHelper from './FieldAndHelper';
import Helper from './Helper';
import ErrorMessage from './ErrorMessage';
import Li from '../Li';

const TextareaField = styled.textarea`
  resize: vertical;
  width: ${props => (props.displayBlock ? '100%' : 'auto')};
  display: ${props => (props.displayBlock ? 'block' : 'inline-block')};
  text-align: ${props => (props.textAlign ? props.textAlign : 'left')};
  border-style: solid;
  border-width: 1px;
  border-color: ${props => {
    if (props.hasError) {
      return props.theme.dangerColor;
    }
    return props.theme.formFieldsBorderColor;
  }};
  border-radius: 0;
  padding: 0.45em 0.5em;
  font-family: ${props => props.theme.bodyFont};
  font-size: clamp(16px, 1em, 2rem);
  background-color: ${props =>
    props.hasError ? props.theme.dangerLightColor : props.theme.lightColor};
  color: ${props =>
    props.hasError ? props.theme.dangerColor : props.theme.darkColor};
  ${props =>
    props.disabled
      ? 'cursor: not-allowed; background-color: ' + props.theme.lightGreyColor
      : null};
  @media (min-width: ${props => props.theme.tablet}) {
    font-size: 1em;
  }
`;

class Textarea extends React.Component {
  render() {
    return (
      <FieldGroup
        isHorizontal={this.props.isHorizontal}
        size={this.props.size}
        marginBottom0={this.props.marginBottom0}
      >
        <Label
          field_id={this.props.name}
          hasError={this.props.errors}
          isHorizontal={this.props.isHorizontal}
          labelTextRight={this.props.labelTextRight}
          label={this.props.label}
          isRequired={this.props.isRequired}
          isLabelBold={this.props.isLabelBold}
          labelWidth={this.props.labelWidth}
          labelColor={this.props.labelColor}
        />
        <FieldAndHelper isHorizontal={this.props.isHorizontal}>
          <TextareaField
            id={this.props.name}
            onBlur={this.props.onBlur}
            textAlign={this.props.textAlign}
            required={this.props.isRequired}
            displayBlock={this.props.displayBlock}
            placeholder={this.props.placeholder}
            onChange={this.props.onChange}
            disabled={this.props.isDisabled}
            hasError={this.props.errors}
            rows={this.props.linesNumber}
            value={this.props.value}
            name={this.props.name}
            ref={this.props.forwardedRef}
          />
          {this.props.errors && (
            <ErrorMessage displayBlock={this.props.displayBlock}>
              {Object.entries(this.props.errors).map(([type, message]) => (
                <Li key={type}>{message}</Li>
              ))}
            </ErrorMessage>
          )}
          <Helper hasError={this.props.errors}>{this.props.formHelper}</Helper>
        </FieldAndHelper>
      </FieldGroup>
    );
  }
}

export default React.forwardRef((props, ref) => (
  <Textarea forwardedRef={ref} {...props} />
));
