import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router';
import { LIGHT_COLOR } from './theme_blue';

const Button = styled.button`
  font-family: inherit;
  ${props => {
    if (props.displayBlock || props.displayBlockForMobile) {
      return (
        'display: flex; flex-basis: 100%; flex-grow: 1; width: calc(100% - ' +
        (props.marginLeft ? props.marginLeft : 0) +
        'em - ' +
        (props.marginRight ? props.marginRight : 0) +
        'em);'
      );
    }
    return 'display: inline-flex; flex-basis: auto; flex-grow: 0; width: auto;';
  }}
  ${props => (props.flexGrow ? 'flex-grow: 1;' : null)}
  min-height: ${props =>
    props.height100Percent
      ? '100%'
      : 'auto'}; /* Useful combined with grid cols verticaly stretched */
  flex-shrink: 0;
  flex-wrap: ${props => (props.noWrap ? 'nowrap' : 'wrap')};
  align-items: center;
  padding: ${props => {
    if (props.padding) {
      return props.padding;
    }
    if (props.size == 'tiny') {
      return '.125em .5em';
    }
    if (props.size == 'large') {
      return '.875em 1em';
    }
    return '.5em .75em';
  }};
  ${props =>
    props.marginTop ? 'margin-top: ' + props.marginTop + 'em;' : null}
  ${props => (props.float ? 'float: ' + props.float + ';' : null)}
  ${props =>
    props.marginBottom ? 'margin-bottom: ' + props.marginBottom + 'em;' : null}
  ${props =>
    props.marginLeft ? 'margin-left: ' + props.marginLeft + 'em;' : null}
  ${props =>
    props.marginRight ? 'margin-right: ' + props.marginRight + 'em;' : null}
  background-color: ${props =>
    props.backgroundColor
      ? props.theme[props.backgroundColor]
      : props.theme.primaryColor};
  color: ${props =>
    props.color ? props.theme[props.color] : props.theme.lightColor};
  font-size: ${props => {
    if (props.size == 'tiny') {
      return '.75em';
    }
    if (props.size == 'large') {
      return '1.25em';
    }
    return '1em';
  }};
  transition: background-color 0.3s ease;
  text-decoration: none;
  justify-content: ${props =>
    props.justifyContent ? props.justifyContent : 'center'};
  ${props =>
    props.disabled ? 'cursor: not-allowed; opacity: .5;' : 'cursor: pointer;'}
  border: ${props => (props.dottedBorder ? 'dotted' : 'solid')} ${props =>
  props.noBorder ? '0' : '1'}px ${props =>
  props.borderColor ? props.theme[props.borderColor] : props.theme.lightColor};
  ${props => (props.height ? 'height: ' + props.height + ';' : null)}
  @media (min-width: ${props => props.theme.tablet}) {
    ${props => {
      if (props.displayBlockForMobile) {
        return 'display: inline-flex; flex-basis: auto; flex-grow: 0; width: auto;';
      }
    }}
  }
  &:hover,
  &:focus {
    background-color: ${props => {
      if (props.backgroundHoverColor) {
        return props.theme[props.backgroundHoverColor];
      }
      if (props.backgroundColor) {
        return props.theme[props.backgroundColor];
      }
      return props.theme.lightColor;
    }};
    color: ${props => {
      if (props.hoverColor) {
        return props.theme[props.hoverColor];
      }
      if (props.backgroundColor) {
        return props.theme[props.backgroundColor];
      }
      return props.theme.primaryColor;
    }};
    border-color: ${props => {
      if (props.borderHoverColor) {
        return props.theme[props.borderHoverColor];
      }
      if (props.borderColor) {
        return props.theme[props.borderColor];
      }
      return props.theme.primaryColor;
    }};
  }
`;

const ButtonLink = Button.withComponent('a');
const ButtonSpan = Button.withComponent('span');

class ButtonComponent extends React.Component {
  render() {
    let Component = Button;
    let type = this.props.type;
    if (this.props.as == 'span') {
      Component = ButtonSpan;
      type = undefined;
    }
    if (this.props.as == 'a') {
      Component = ButtonLink;
      type = undefined;
    }
    const buttonBaseProps = {};
    if (this.props.link) {
      buttonBaseProps.href = this.props.link;
    }
    if (this.props.href) {
      buttonBaseProps.href = this.props.href;
    }
    if (this.props.onClick) {
      buttonBaseProps.onClick = this.props.onClick;
    }
    if (this.props.action) {
      buttonBaseProps.onClick = this.props.action;
    }
    if (this.props.targetBlank) {
      buttonBaseProps.target = this.props.targetBlank;
    }
    if (this.props.asLink) {
      buttonBaseProps.as = Link;
    }
    if (this.props.to) {
      buttonBaseProps.to = this.props.to;
    }
    return (
      <Component
        {...buttonBaseProps}
        type={type}
        onMouseEnter={this.props.onMouseEnter}
        form={this.props.form}
        backgroundColor={this.props.backgroundColor}
        color={this.props.color}
        backgroundHoverColor={this.props.backgroundHoverColor}
        disabled={this.props.disabled}
        size={this.props.size}
        marginTop={this.props.marginTop}
        marginBottom={this.props.marginBottom}
        displayBlock={this.props.displayBlock}
        displayBlockForMobile={this.props.displayBlockForMobile}
        height100Percent={this.props.height100Percent}
        isActive={this.props.isActive}
        targetBlank={this.props.targetBlank}
        noWrap={this.props.noWrap}
        justifyContent={this.props.justifyContent}
        borderColor={this.props.borderColor}
        marginLeft={this.props.marginLeft}
        marginRight={this.props.marginRight}
        dottedBorder={this.props.dottedBorder}
        hoverColor={this.props.hoverColor}
        borderHoverColor={this.props.borderHoverColor}
        flexGrow={this.props.flexGrow}
        title={this.props.title}
        margin={this.props.margin}
        noBorder={this.props.noBorder}
        padding={this.props.padding}
        float={this.props.float}
        height={this.props.height}
        className={this.props.className}
      >
        {this.props.children}
      </Component>
    );
  }
}

ButtonComponent.defaultProps = {
  type: 'button',
};

export default ButtonComponent;
