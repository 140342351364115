(function (t) {
// en
t.add("export", "Export", "messages", "en");
t.add("close_page", "Close this page", "messages", "en");
t.add("searchAction", "Search", "messages", "en");
t.add("validate_action", "Validate", "messages", "en");
t.add("save", "Save", "messages", "en");
t.add("download", "Download", "messages", "en");
t.add("home", "Home", "messages", "en");
t.add("home.dashboard", "Dashboard", "messages", "en");
t.add("selectFilter", "Select a filter", "messages", "en");
t.add("searchResult", "Search results", "messages", "en");
t.add("foundResult", "{0} No result|{1} %count% result found|]1,Inf[ %count% result found", "messages", "en");
t.add("assistant", "Wizard", "messages", "en");
t.add("choose_element", "Choose an item", "messages", "en");
})(Translator);
