import React from 'react';
import Svg from './Svg';

export default function IconSave(props) {
  return (
    <Svg
      {...props}
      d="M32 32h192v192H70.571L32 186.286V32zm40 12H44v137.236L75.463 212H212V44h-28v84H72V44zm12 0v72h88V44H84z"
    />
  );
}
