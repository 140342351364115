import { createSelector } from 'reselect';
import { once } from 'underscore';

function formatNodeName(nodeName) {
  let nodeNameItems = nodeName.split(' ').filter(item => item.length > 25);

  if (nodeNameItems.length > 0) {
    return nodeName.substr(0, 25) + '...';
  }

  return nodeName;
}

export const makeShortenedNodeSelector = () => {
  return createSelector([node => node.name], nodeName => {
    return formatNodeName(nodeName);
  });
};

export const makeShortenedNodeForDocumentDetailSelector = once(
  makeShortenedNodeSelector
);
