import React, { Component } from 'react';
import pdfObject from 'pdfobject';

class PDF extends Component {
  render() {
    if (pdfObject.supportsPDFs) {
      return (
        <embed
          src={this.props.fileUrl}
          width="100%"
          height={this.props.height}
          style={this.props.style}
          type="application/pdf"
        />
      );
    }

    return (
      <iframe
        src={`/pdfjs/web/viewer.html?file=${encodeURIComponent(
          this.props.fileUrl
        )}`}
        width="100%"
        height={this.props.height}
        style={this.props.style}
      />
    );
  }
}

export default PDF;
