import createUndoableActionReducer from '../reducers/createUndoableActionReducer';
import { takeEvery, call } from 'redux-saga/effects';
import undoSaga from './dataroomActions/undoSaga';

import apiClient from 'js/dataroom/core/apiClient';
import { uniqueId } from 'underscore';
import { removeAndSaveNodes, undoRemoveNode } from './utils/removeNodes';

export const PDF_MERGE = 'PDF_MERGE';

export function mergeDocumentAction(nodeId, nodes, name) {
  return {
    type: PDF_MERGE,
    nodeId,
    nodes,
    name,
    frontActionId: uniqueId('dataroom_action'),
  };
}

export const mergeDocumentApi = action =>
  apiClient.request(
    new Request(`/api/nodes/${action.nodeId}/mergeDocumentPdf`, {
      method: 'PATCH',
      body: JSON.stringify({
        nodeId: action.nodeId,
        nodeName: action.name,
        nodeIdsToMerge: action.nodes.map(node => node.id),
      }),
    })
  );

export function* saga(action) {
  return yield call(mergeDocumentApi, action);
}

export function* undoActionSaga() {
  yield takeEvery('UNDO_PDF_MERGE', undoSaga);
}

const getFilteredNodeIds = (nodes, nodeId) => {
  return nodes.reduce(function(filtered, node) {
    if (node.id !== nodeId) {
      filtered.push(node.id);
    }
    return filtered;
  }, []);
};

export const mergedDocument = (state, action) => {
  let node = state.working.nodes[action.nodeId];

  let newState = {
    ...state,
    working: {
      ...state.working,
      savedNodes: {
        ...state.working.savedNodes,
        [action.frontActionId]: {
          [action.nodeId]: { ...node },
        },
      },
      nodes: {
        ...state.working.nodes,
        [action.nodeId]: {
          ...state.working.nodes[action.nodeId],
          name: action.name,
        },
      },
    },
  };

  let filteredNodeIds = getFilteredNodeIds(action.nodes, action.nodeId);

  return removeAndSaveNodes(
    newState,
    filteredNodeIds,
    action.frontActionId,
    false
  );
};

export const undoMergedDocument = (state, action) => {
  let newState = { ...state };

  let filteredNodeIds = getFilteredNodeIds(action.nodes, action.nodeId);

  filteredNodeIds.reverse().forEach(nodeId => {
    newState = undoRemoveNode(newState, nodeId, action.frontActionId, false);
  });

  return {
    ...newState,
    working: {
      ...newState.working,
      nodes: {
        ...newState.working.nodes,
        [action.nodeId]: {
          ...newState.working.nodes[action.nodeId],
          name:
            newState.working.savedNodes[action.frontActionId][action.nodeId]
              .name,
        },
      },
    },
  };
};

export default createUndoableActionReducer(
  PDF_MERGE,
  mergedDocument,
  undoMergedDocument
);
