import React, { Component } from 'react';
import { Field } from 'redux-form';
import Input from './Input';
import moment from 'moment';
import 'translations/validators/fr';
import 'translations/validators/en';

export const REQUIRED_ERROR = Translator.trans(
  'This value should not be blank.',
  {},
  'validators'
);

class FieldInput extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { field } = this.props;

    return <Field component={Input} name={field} {...this.props} />;
  }
}

export default FieldInput;
