import { call, put, takeEvery } from 'redux-saga/effects';
import { TYPE_DOCUMENT, TYPE_FOLDER } from '../documents/type';
import getNextPath, { AFTER, INSIDE } from './utils/getNextPath';
import uuidv4 from 'uuid/v4';
import { addFilesAction, isFileIgnored } from './addFiles';
import { flatten, difference } from 'underscore';
import { addNotificationAction } from '../../app/actions/notifications';
import {
  IMPORT_TYPE_CDROM_BRUT,
  IMPORT_TYPE_CLASSIC,
  IMPORT_TYPE_NOTARY_CHAMBER,
} from '../constants';
import {
  transformArchiveNodeToFolderAction,
  getStructDocumentNode,
} from './transformArchiveNodeToFolder';
import getPathLevel from './utils/getPathLevel';
import updatePath from './utils/updatePath';
import getParentPath from './utils/getParentPath';

export const CONFIRM_IMPORT_FILES = '@import-files/confirm_import_files';
export const IMPORT_FILES = '@import-files/import_files';
export const IMPORT_FILES_STARTED = '@import-files/import_files_started';
const CANCEL_IMPORT_FILES = '@import-files/cancel_import_files';
export const IMPORT_ERROR = '@import-files/import_error';

export const FILE_ORIGIN_ARCHIVE = 'FILE_ORIGIN_ARCHIVE';
export const FILE_ORIGIN_FILES_LIST = 'FILE_ORIGIN_FILES_LIST';

export const BADLY_FORMATED_IMPORT = 'BADLY_FORMATED_IMPORT';

export const IGNORED_FILES_FROM_CSV = 'IGNORED_FILES_FROM_CSV';
export const IGNORED_FILES_FROM_ARCHIVE = 'IGNORED_FILES_FROM_ARCHIVE';

const ignoredFiles = ['title.txt', 'LANCER_MOI_POUR_GRAVER.bat'];
const ignoredFolders = ['jaquette'];

export const pathRegex = /^[0-9]+(\.[0-9]+)*[^a-zA-Z]?/;

const importIsWkCDROMBrut = files => {
  for (let file of files) {
    if (file.type === TYPE_FOLDER && ignoredFolders.indexOf(file.name) !== -1) {
      continue;
    }

    if (
      file.type === TYPE_DOCUMENT &&
      (ignoredFiles.indexOf(file.name) !== -1 || isFileIgnored(file.name))
    ) {
      continue;
    }

    if (!file.name.match(pathRegex)) {
      return false;
    }

    if (file.type === TYPE_FOLDER) {
      if (!importIsWkCDROMBrut(file.children)) {
        return false;
      }
    }
  }

  return true;
};

export const getImportType = files => {
  const indexStructNotary = files.findIndex(
    file => file.type === TYPE_DOCUMENT && file.name.endsWith('.struct.csv')
  );

  if (indexStructNotary !== -1) {
    return IMPORT_TYPE_NOTARY_CHAMBER;
  }

  if (files.length !== 1) {
    return IMPORT_TYPE_CLASSIC;
  }

  if (files[0].type === TYPE_DOCUMENT) {
    return IMPORT_TYPE_CLASSIC;
  }

  const indexStruct = files[0].children.findIndex(
    file => file.type === TYPE_DOCUMENT && file.name.endsWith('.struct.csv')
  );

  if (indexStruct !== -1) {
    return IMPORT_TYPE_NOTARY_CHAMBER;
  }

  if (files[0].children.length > 0 && importIsWkCDROMBrut(files[0].children)) {
    return IMPORT_TYPE_CDROM_BRUT;
  }

  return IMPORT_TYPE_CLASSIC;
};

export const importFilesStartedAction = () => ({
  type: IMPORT_FILES_STARTED,
});

const importErrorAction = () => ({ type: IMPORT_ERROR });

const badlyFormatedImportAction = (errors, actionToDispatch) => ({
  type: BADLY_FORMATED_IMPORT,
  errors,
  actionToDispatch,
});

export const confirmImportFilesAction = (
  nodes,
  destinationNode,
  position,
  importType
) => ({
  type: CONFIRM_IMPORT_FILES,
  action: {
    nodes,
    destinationNode,
    position,
    importType,
    fileOrigin: FILE_ORIGIN_FILES_LIST,
  },
});

export const importFilesAction = action => ({
  type: IMPORT_FILES,
  action,
});

export const cancelImportFilesAction = () => ({
  type: CANCEL_IMPORT_FILES,
});

const getCsvToJSON = () => {
  return import(/* webpackChunkName: "csvtojson" */ 'csvtojson').then(
    imported => {
      return imported.default;
    }
  );
};

const getCsvContent = nodes => {
  const nodeImported = getStructDocumentNode(nodes);

  const reader = new FileReader();

  return new Promise((success, reject) => {
    reader.addEventListener('load', () => {
      success(reader.result);
    });

    reader.readAsText(nodeImported.file, 'windows-1252');
  });
};

const hasImportContainsParentFolder = nodes => {
  if (nodes[0].children) {
    let nodeWithParent = nodes[0].children.find(child =>
      child.name.endsWith('.struct.csv')
    );

    return !!nodeWithParent;
  }

  return false;
};

const normalizePath = path =>
  path
    .split('.')
    .map(pathPart => (parseInt(pathPart, 10) < 10 ? `0${pathPart}` : pathPart))
    .join('.');

const findNodeInChildren = (nodes, name) =>
  nodes.find(node => node.name === name);

// since the filenames are encoded in cp865 and csv files is encoded in windows 1252,
// some characters cant be displayed in the filename, to have the complete find the char present here:
// https://en.wikipedia.org/wiki/Code_page_437
// https://en.wikipedia.org/wiki/Code_page_865
// and which are not here https://en.wikipedia.org/wiki/Windows-1252
//
const probalematicCharacters = ['’', '€', '®', 'È', 'Ï', '¦'];

const replaceableChars = [
  {
    source: /–/g,
    destination: '__',
  },
];

const findOccurencesOfproblematicCharacters = name => {
  let foundOccurences = [];

  for (let i = 0; i < name.length; i++) {
    let probalematicCharactersIndex = probalematicCharacters.indexOf(name[i]);
    if (probalematicCharactersIndex !== -1) {
      foundOccurences.push({
        index: i,
        char: probalematicCharacters[probalematicCharactersIndex],
      });
    }
  }

  return foundOccurences;
};

function replaceAt(string, index, replace) {
  return string.substring(0, index) + replace + string.substring(index + 1);
}

const findDocumentNodeWithProblematicChar = (
  nodes,
  name,
  occurencesOfproblematicCharacters
) => {
  return nodes.find(node => {
    const nodeNameLength = node.name.length;
    let transformedNodeName = node.name;

    for (let occurenceOfproblematicCharacter of occurencesOfproblematicCharacters) {
      if (nodeNameLength < occurenceOfproblematicCharacter.index) {
        return;
      }

      transformedNodeName = replaceAt(
        transformedNodeName,
        occurenceOfproblematicCharacter.index,
        occurenceOfproblematicCharacter.char
      );
    }

    return transformedNodeName === name;
  });
};

const findDocumentNodeInChildren = (nodes, name) => {
  let foundNode = findNodeInChildren(nodes, name);
  if (foundNode) {
    return foundNode;
  }

  let occurencesOfproblematicCharacters = findOccurencesOfproblematicCharacters(
    name
  );

  foundNode = findDocumentNodeWithProblematicChar(
    nodes,
    name,
    occurencesOfproblematicCharacters
  );

  if (foundNode) {
    return foundNode;
  }

  for (let replaceableChar of replaceableChars) {
    foundNode = findDocumentNodeWithProblematicChar(
      nodes,
      name.replace(replaceableChar.source, replaceableChar.destination),
      occurencesOfproblematicCharacters
    );
    if (foundNode) {
      return foundNode;
    }
  }
};

function extractMissingNodesChildErrors(currentPath, indexedCSVNodes) {
  let childPath = getNextPath(currentPath, INSIDE);
  let errors = [];
  while (indexedCSVNodes[childPath]) {
    errors.push({
      currentPath: childPath,
      csvNode: indexedCSVNodes[childPath],
      errrorType: IGNORED_FILES_FROM_CSV,
    });

    if (indexedCSVNodes[getNextPath(childPath, INSIDE)]) {
      errors = [
        ...errors,
        ...extractMissingNodesChildErrors(childPath, indexedCSVNodes),
      ];
    }

    childPath = getNextPath(childPath, AFTER);
  }

  return errors;
}

export const transformNodesForParent = (
  parentNode,
  parentPath,
  indexedCSVNodes
) => {
  let currentPath = getNextPath(parentPath, INSIDE);

  const nodes = [];
  let errors = [];

  // node has no corresponding parent folder in the filesystem
  if (!parentNode) {
    return {
      nodes,
      errors: extractMissingNodesChildErrors(
        getParentPath(currentPath),
        indexedCSVNodes
      ),
    };
  }

  // a node is on the file system and has no usage in the csv file
  if (!indexedCSVNodes[currentPath]) {
    console.warn('no usage found for', currentPath);
  }

  while (indexedCSVNodes[currentPath]) {
    let currentCsvNode = indexedCSVNodes[currentPath];

    let transformedNode = {
      name: currentCsvNode.name,
      id: uuidv4(),
      type: currentCsvNode.type,
    };

    if (currentCsvNode.type === TYPE_FOLDER) {
      const result = transformNodesForParent(
        findNodeInChildren(parentNode.children, currentCsvNode.csvPath),
        currentPath,
        indexedCSVNodes
      );
      errors = [...errors, ...result.errors];

      transformedNode.children = result.nodes;
    }

    if (currentCsvNode.type === TYPE_DOCUMENT) {
      const documentNode = findDocumentNodeInChildren(
        parentNode.children,
        currentCsvNode.fileName
      );

      if (!currentCsvNode.fileName) {
        transformedNode.file = null;
      } else {
        if (!documentNode) {
          errors.push({
            csvNode: currentCsvNode,
            currentPath,
            errorType: IGNORED_FILES_FROM_CSV,
          });
          console.warn(`error for ${currentCsvNode.name}`);
        } else {
          transformedNode.file = documentNode.file;
          transformedNode.mappingId = documentNode.id;

          if (documentNode.archivePath) {
            transformedNode.archivePath = documentNode.archivePath;
          }
        }
      }
    }

    currentPath = getNextPath(currentPath, AFTER);
    nodes.push(transformedNode);
  }

  return { nodes, errors };
};

const addDocumentAtPath = (node, indexedCSVNodes, path) => {
  const pathLevel = getPathLevel(path);
  if (typeof indexedCSVNodes[path] === 'undefined') {
    indexedCSVNodes[path] = node;
    return;
  }

  let lastDescendantPath = getNextPath(path, AFTER);
  while (typeof indexedCSVNodes[lastDescendantPath] !== 'undefined') {
    lastDescendantPath = updatePath(
      pathLevel,
      lastDescendantPath,
      position => position + 1
    );
  }

  let previousPath = lastDescendantPath;
  while (lastDescendantPath !== path) {
    previousPath = updatePath(
      pathLevel,
      previousPath,
      position => position - 1
    );
    indexedCSVNodes[lastDescendantPath] = indexedCSVNodes[previousPath];

    lastDescendantPath = previousPath;
  }

  indexedCSVNodes[path] = node;
};

const transformNodesForNotaryChamberImport = (nodes, csvContent, csvToJSON) => {
  return new Promise((resolve, reject) => {
    return csvToJSON({
      delimiter: ';',
    })
      .fromString(csvContent)
      .then(csvRows => {
        try {
          const indexedCSVNodes = {};

          let lastFolderPath = null;
          let lastDocumentPath = null;
          let rowNumber = 0;

          for (let csvRow of csvRows) {
            rowNumber += 1;
            if (csvRow.PrefixeSD === '' && csvRow.DocNom === '') {
              console.warn(`empty line detected at line ${rowNumber}`);
              continue;
            }

            // Dossier
            if (csvRow.PrefixeSD) {
              let folderPath = normalizePath(csvRow.PrefixeSD);

              addDocumentAtPath(
                {
                  type: TYPE_FOLDER,
                  name: csvRow.SousDossier,
                  csvPath: csvRow.PrefixeSD,
                },
                indexedCSVNodes,
                folderPath
              );
              lastFolderPath = folderPath;
              lastDocumentPath = null;
              continue;
            }
            //Check if document has valid file doc join
            if (
              csvRow.DocNom &&
              (csvRow.DocFichierJoint === 'N/A' ||
                csvRow.DocFichierJoint === 'nonapplicable')
            ) {
              console.warn(
                `document line without 'DocFichierJoint' detected at line ${rowNumber} : force continue`
              );

              // We need to continue to create an empty node !csvRow.DocFichierJoint
              // 2195-insérer-les-documents-ayant-le-pdf-associé-vide-en-tant-que-document-sans-document-pour-lexport-chambre-des-notaires

              continue;
            }

            let nextPath = null;
            if (lastDocumentPath) {
              nextPath = getNextPath(lastDocumentPath, AFTER);
            } else {
              if (lastFolderPath === null) {
                lastFolderPath = '0';
              }

              nextPath = getNextPath(lastFolderPath, INSIDE);
            }

            const extension = csvRow.DocFichierJoint.substring(
              csvRow.DocFichierJoint.lastIndexOf('.') + 1
            ).toLowerCase();

            indexedCSVNodes[nextPath] = {
              type: TYPE_DOCUMENT,
              name: `${csvRow.DocNom}.${extension}`,
              fileName: csvRow.DocFichierJoint,
            };
            lastFolderPath = null;
            lastDocumentPath = nextPath;
          }

          let hasParentFolder = hasImportContainsParentFolder(nodes);

          let parentNode = nodes[0];
          if (!hasParentFolder) {
            parentNode = {
              children: nodes,
              id: uuidv4(),
              type: TYPE_FOLDER,
              name: '_fictive_',
            };
          }

          let transformedParentNode = {
            ...parentNode,
          };

          const documentIdsFromNodes = getFlattenDocumentIdsFromNodes(
            parentNode,
            'id'
          );

          const result = transformNodesForParent(
            parentNode,
            '0',
            indexedCSVNodes
          );

          transformedParentNode.children = result.nodes;

          const documentIdsFromCsvNodes = getFlattenDocumentIdsFromNodes(
            transformedParentNode,
            'mappingId'
          );

          const missingDocumentIds = difference(
            documentIdsFromNodes,
            documentIdsFromCsvNodes
          );

          const missingDocumentNodesErrors = exportMissingDocumentNodesFromIds(
            parentNode,
            missingDocumentIds,
            '0'
          );

          if (hasParentFolder) {
            resolve({
              nodes: [transformedParentNode],
              errors: [...result.errors, ...missingDocumentNodesErrors],
            });
          } else {
            resolve({
              nodes: transformedParentNode.children,
              errors: [...result.errors, ...missingDocumentNodesErrors],
            });
          }
        } catch (error) {
          reject(error);
        }
      });
  });
};

const getFlattenDocumentIdsFromNodes = (parentNode, key) => {
  let ids = [];

  if (parentNode.type === TYPE_FOLDER) {
    return flatten(
      parentNode.children.map(node => getFlattenDocumentIdsFromNodes(node, key))
    );
  }

  return [...ids, parentNode[key]];
};

const exportMissingDocumentNodesFromIds = (
  parentNode,
  missingDocumentNodeIds,
  parentPath
) => {
  let errors = [];

  if (parentNode.type === TYPE_FOLDER) {
    const errors = flatten(
      parentNode.children.map(node =>
        exportMissingDocumentNodesFromIds(
          node,
          missingDocumentNodeIds,
          parentNode.name
        )
      )
    );

    return errors.filter(error => error.missingNode);
  }

  // Nous devons ignorer les fichiers qui sont à la racine de l'archive car il n'appartiennent pas à l'export chambre des notaires
  // Permet d'exclure des fichiers de type: sommaire.pdf, OfficeNotarial.exe...
  // Cette distinction est réalisée par parentPath.match(/^[0-9]{1,}(\.[0-9]{1,})*/) !== null

  return [
    ...errors,
    {
      csvNode: parentNode,
      currentPath: parentPath,
      missingNode:
        missingDocumentNodeIds.includes(parentNode.id) &&
        parentPath.match(/^[0-9]{1,}(\.[0-9]{1,})*/) !== null,
      errorType: IGNORED_FILES_FROM_ARCHIVE,
    },
  ];
};

const transformName = nodeName => {
  return nodeName.replace(pathRegex, '');
};

const removeIgnoredNodeForCDROMBrut = children => {
  return children.filter(child => {
    if (
      child.type === TYPE_FOLDER &&
      ignoredFolders.indexOf(child.name) !== -1
    ) {
      return false;
    }

    if (
      child.type === TYPE_DOCUMENT &&
      ignoredFiles.indexOf(child.name) !== -1
    ) {
      return false;
    }

    return true;
  });
};

const nodedNameOrderNumber = name => {
  const matched = name.match(pathRegex);
  if (!matched) {
    return 0;
  }
  const matchedPath = matched[0];

  return parseInt(
    matchedPath.substr(0, matchedPath.length - 1).replace(/\./g, ''),
    10
  );
};

const orderChildrenByPath = children => {
  children.sort((child1, child2) => {
    return (
      nodedNameOrderNumber(child1.name) - nodedNameOrderNumber(child2.name)
    );
  });
};

const transformChildrenForCDROMBrutImport = children => {
  let transformedChildren = removeIgnoredNodeForCDROMBrut(children);
  orderChildrenByPath(transformedChildren);

  for (let childNode of transformedChildren) {
    childNode.name = transformName(childNode.name);

    if (childNode.type === TYPE_FOLDER) {
      childNode.children = transformChildrenForCDROMBrutImport(
        childNode.children
      );
    }
  }

  return transformedChildren;
};

const transformNodesForCDROMBrutImport = nodes => {
  const transformedRootNode = {
    ...nodes[0],
  };

  transformedRootNode.children = transformChildrenForCDROMBrutImport(
    transformedRootNode.children
  );

  return [transformedRootNode];
};

const normalizeNodeNamesForImport = nodes => {
  let transformedNodes = [];

  for (let node of nodes) {
    let transformedNode = { ...node };

    transformedNode.name = transformedNode.name.replace('/', '-');

    if (node.type === TYPE_FOLDER) {
      transformedNode.children = normalizeNodeNamesForImport(
        transformedNode.children
      );
    }

    transformedNodes.push(transformedNode);
  }

  return transformedNodes;
};

export function* transformNodesForImport(nodes, importType) {
  let importedNodes = null;
  let errors = [];

  if (importType === IMPORT_TYPE_NOTARY_CHAMBER) {
    const csvToJSON = yield call(getCsvToJSON);
    const csvContent = yield call(getCsvContent, nodes);

    const result = yield call(
      transformNodesForNotaryChamberImport,
      nodes,
      csvContent,
      csvToJSON
    );

    errors = result.errors;
    importedNodes = result.nodes;
  }

  if (importType === IMPORT_TYPE_CDROM_BRUT) {
    importedNodes = yield call(transformNodesForCDROMBrutImport, nodes);
  }
  if (!importedNodes) {
    throw new Error();
  }

  return {
    // there is often "/" chars in import files and ths users dont want to edit all the nodes before having the possibility to publish
    importedNodes: normalizeNodeNamesForImport(importedNodes),
    errors,
  };
}

function* importFilesSaga(action) {
  const {
    nodes,
    destinationNode,
    position,
    importType,
    fileOrigin,
    nodeId,
  } = action.action;

  let importedNodes = null;
  let errors = [];
  try {
    const result = yield call(transformNodesForImport, nodes, importType);

    importedNodes = result.importedNodes;
    errors = result.errors;
  } catch (error) {
    console.error(error);
    yield put(importErrorAction());
    return;
  }

  let actionToDispatch = null;

  if (!importedNodes) {
    yield put(importErrorAction());
    return;
  } else if (fileOrigin === FILE_ORIGIN_ARCHIVE) {
    actionToDispatch = transformArchiveNodeToFolderAction(
      importedNodes,
      nodeId,
      importType,
      action.action.frontActionId
    );
  } else {
    actionToDispatch = addFilesAction(
      importedNodes,
      destinationNode,
      position,
      importType,
      fileOrigin
    );
  }

  if (errors.length > 0) {
    yield put(badlyFormatedImportAction(errors, actionToDispatch));
    return;
  }

  yield put(actionToDispatch);
  yield put(importFilesStartedAction());
}

export function* saga() {
  yield takeEvery(IMPORT_FILES, importFilesSaga);
}

export default function(state, action) {
  switch (action.type) {
    case CONFIRM_IMPORT_FILES: {
      return {
        ...state,
        working: {
          ...state.working,
          confirmImportFilesModal: {
            ...state.working.confirmImportFilesModal,
            action: action.action,
            open: true,
          },
        },
      };
    }

    case IMPORT_FILES: {
      return {
        ...state,
        working: {
          ...state.working,
          confirmImportFilesModal: {
            ...state.working.confirmImportFilesModal,
            importing: true,
          },
        },
      };
    }

    case CANCEL_IMPORT_FILES:
    case IMPORT_ERROR:
    case IMPORT_FILES_STARTED: {
      return {
        ...state,
        working: {
          ...state.working,
          confirmImportFilesModal: {
            nodes: null,
            destinationNode: null,
            position: null,
            importType: null,
            open: false,
            importing: false,
            actionToDispatch: null,
            errors: [],
          },
        },
      };
    }
    case BADLY_FORMATED_IMPORT: {
      return {
        ...state,
        working: {
          ...state.working,
          confirmImportFilesModal: {
            ...state.working.confirmImportFilesModal,
            errors: action.errors,
            actionToDispatch: action.actionToDispatch,
          },
        },
      };
    }
  }

  return state;
}
