import React, { Component } from 'react';
import styled from 'styled-components';

export const Span = styled.span`
  display: inline-block;
  margin-right: 10px;
  margin-top: 4px;
  color: ${props => (props.color ? props.theme[props.color] : 'inherit')};
  font-weight: bold;
`;

export default function ItemNumber(props) {
  return <Span color={props.color}>{props.children}</Span>;
}
