import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { addFilesAction } from '../ducks/addFiles';
import {
  cancelExtractZipAction,
  extractZipAction,
} from '../ducks/extractZipFile';
import PleaseWait from '../components/PleaseWait';

class ConfirmExtractZipModal extends Component {
  render() {
    const { open, zipFile, destinationNode, position, extracting } = this.props;

    return (
      <Fragment>
        <PleaseWait text="Extraction en cours..." waiting={extracting} />
        <Modal
          show={open}
          backdrop="static"
          onHide={this.props.cancelExtractZipAction}
        >
          <Modal.Header>
            <Modal.Title>Extraction d'un ZIP</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <h4>
              le document que vous avez ajouté est une archive ZIP.
              <br />
              <br />
              Voulez vous extraire l'archive ou garder le document tel quel ?
            </h4>
          </Modal.Body>

          <Modal.Footer>
            <button
              onClick={this.props.cancelExtractZipAction}
              type="button"
              className="btn btn-warning md-close"
              disabled={extracting}
            >
              Annuler l'ajout
            </button>

            <button
              onClick={() => {
                this.props.addFilesAction([zipFile], destinationNode, position);
                this.props.cancelExtractZipAction();
              }}
              type="button"
              data-dismiss="modal"
              className="btn btn-default md-close"
              disabled={extracting}
            >
              Garder le document
            </button>

            <button
              onClick={() =>
                this.props.extractZipAction(zipFile, destinationNode, position)
              }
              type="button"
              className="btn btn-primary md-close"
              disabled={extracting}
            >
              Extraire l'archive
            </button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return state.dataroom.working.confirmExtractZipModal;
}

export default connect(mapStateToProps, {
  addFilesAction,
  cancelExtractZipAction,
  extractZipAction,
})(ConfirmExtractZipModal);
