import React, { Component } from 'react';
import { Router as ReactRouter, applyRouterMiddleware } from 'react-router';
import { useScroll } from 'react-router-scroll';
import { connect } from 'react-redux';

class Router extends Component {
  constructor(props) {
    super(props);
    this.lastWindowY = window.scrollY;
    this.savedWindowY = window.scrollY;
  }

  componentDidMount() {
    window.addEventListener('scroll', evt => {
      this.lastWindowY = window.scrollY;
    });
  }

  render() {
    return (
      <ReactRouter
        history={this.props.history}
        render={applyRouterMiddleware(
          useScroll({
            shouldUpdateScroll: (previousRouteProps, nextRouteProps) => {
              let needUseScroll = nextRouteProps.routes.some(e => e.useScroll);

              if (!needUseScroll) {
                return false;
              }

              if (!previousRouteProps) {
                return true;
              }

              if (this.props.isLargeDataroom) {
                return false;
              }

              if (
                !previousRouteProps.params.documentId &&
                nextRouteProps.params.documentId
              ) {
                this.savedWindowY = this.lastWindowY;
                return false;
              }

              if (
                previousRouteProps.params.documentId &&
                !nextRouteProps.params.documentId
              ) {
                return [0, this.savedWindowY];
              }

              return true;
            },
            scrollKey: 'appScrollKey',
          })
        )}
      >
        {this.props.children}
      </ReactRouter>
    );
  }
}

export default connect(state => ({
  isLargeDataroom: state.dataroom ? state.dataroom.isLargeDataroom : null,
}))(Router);
