import React, { Component, Fragment } from 'react';
import { uniqueId } from 'underscore';
import 'translations/discussion/fr';
import 'translations/discussion/en';

class FileInput extends Component {
  constructor(props) {
    super(props);
    this.onFileChange = this.onFileChange.bind(this);
    this.resetFile = this.resetFile.bind(this);
    this.state = {
      fileName: null,
      inputKey: uniqueId(),
    };
  }

  onFileChange(evt) {
    this.setState({ fileName: evt.target.files[0].name });

    if (this.props.onChange) {
      this.props.onChange(evt.target.files[0]);
    }
  }

  resetFile() {
    this.setState({ fileName: null, inputKey: uniqueId() });

    if (this.props.onChange) {
      this.props.onChange(null);
    }
  }

  render() {
    const {
      error,
      comment,
      allowedExtensions,
      canRemoveFile,
      name,
    } = this.props;
    const { fileName } = this.state;

    return (
      <div className="row">
        <div className="col-sm-6">
          <p>
            <label
              htmlFor={name ? name : 'input-file-n1'}
              className="input-file-label btn btn-block btn-default"
            >
              <input
                name={name ? name : 'file'}
                type="file"
                id={name ? name : 'input-file-n1'}
                accept={allowedExtensions
                  .map(extension => `.${extension}`)
                  .join(', ')}
                onChange={this.onFileChange}
                key={this.state.inputKey}
              />
              <i className="fa fa-file-pdf-o" />{' '}
              {Translator.trans('add_file_to_comment', {}, 'discussion')}
            </label>
          </p>
          {comment && <p className="note"> {comment} </p>}
        </div>
        {!fileName && (
          <div className="col-sm-6">
            <span className="file-name">
              {' '}
              {Translator.trans('no_selected_file', {}, 'discussion')}{' '}
            </span>
          </div>
        )}
        {fileName && (
          <Fragment>
            <div className="col-sm-6">
              <a className="file-name"> {fileName} </a>
              {canRemoveFile && (
                <button
                  onClick={this.resetFile}
                  className="btn btn-block btn-danger"
                >
                  <i className="fa fa-remove" /> Enlever le fichier
                </button>
              )}

              <br />

              {error && (
                <ul
                  style={{ marginBottom: '20px' }}
                  className="parsley-errors-list filled"
                >
                  <li className="parsley-required">{error}</li>
                </ul>
              )}
            </div>
          </Fragment>
        )}
      </div>
    );
  }
}

FileInput.defaultProps = {
  allowedExtensions: [],
};

export default FileInput;
