(function (t) {
// fr
t.add("action_connexion_stats", "Voir les connexions", "stats\u002Dconnexion", "fr");
t.add("TYPE_ALL", "Toutes", "stats\u002Dconnexion", "fr");
t.add("TYPE_SUCCESS", "R\u00e9ussies", "stats\u002Dconnexion", "fr");
t.add("TYPE_FAILURE", "Echou\u00e9es", "stats\u002Dconnexion", "fr");
t.add("GROUP_CONNEXION_TYPE_ALL", "Tous", "stats\u002Dconnexion", "fr");
t.add("client", "Client", "stats\u002Dconnexion", "fr");
t.add("buyer", "Acqu\u00e9reur", "stats\u002Dconnexion", "fr");
t.add("connexionType", "Type de connexion", "stats\u002Dconnexion", "fr");
t.add("groupConnexionType", "Connexion en tant que", "stats\u002Dconnexion", "fr");
t.add("failed_connection", "Connexion \u00e9chou\u00e9e", "stats\u002Dconnexion", "fr");
t.add("successfull_connection", "Connexion r\u00e9ussie", "stats\u002Dconnexion", "fr");
t.add("as_client", "En tant que client", "stats\u002Dconnexion", "fr");
t.add("as_buyer", "En tant qu'acqu\u00e9reur", "stats\u002Dconnexion", "fr");
t.add("connection_search", "Recherche des connexions", "stats\u002Dconnexion", "fr");
t.add("stats.list_connexion", "Liste des connexions", "stats\u002Dconnexion", "fr");
})(Translator);
