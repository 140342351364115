import React from 'react';
import Svg from './Svg';

export default function IconLockUnlocked(props) {
  return (
    <Svg
      {...props}
      d="M52 128h118V86c0-23.18-18.82-42-42-42S86 62.82 86 86H74c0-29.803 24.197-54 54-54s54 24.197 54 54v42h22v96H52v-96z"
    />
  );
}
