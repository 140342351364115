import { ThemeProvider } from 'styled-components';

import React, { Component, Fragment } from 'react';
import { Alert } from 'react-bootstrap';
import { connect } from 'react-redux';
import ModalConfirm from '../../pdfEditor/components/ModalConfirm';
import LoadingIndicator from '../../../presentational/components/editor/LoadingIndicator';
import {
  loadPdfExplodeAction,
  leavePdfExplodeAction,
  loadPagesAction,
} from '../reducers/pdfExplode';
import { createNodeAction, removeNodeAction } from '../ducks/manageNode';
import theme, {
  DANGER_COLOR,
  DANGER_COLOR_HOVER,
  DARK_COLOR,
  LIGHT_BACKGROUND_COLOR,
  PRIMARY_COLOR,
  PRIMARY_COLOR_HOVER,
} from '../../../presentational/components/theme_blue';
import Page from './Page';
import { resetPickPdfAction } from '../ducks/pickPage';
import { resetPickNodeAction } from '../ducks/pickNode';
import Level from '../../../presentational/components/editor/treeview/Level';
import Document from './Document';
import { explodePdfAction } from '../../dataroom/ducks/pdfExplode';
import ExplodePdfForm from './ExplodePdfForm';
import Div from '../../../presentational/components/Div';
import { LIGHT_COLOR } from '../../../presentational/components/theme_blue';
import DarkOverlay from '../../../presentational/components/editor/DarkOverlay';
import CommandButtonsContainer from '../../../presentational/components/editor/CommandButtonsContainer';
import LightBox from '../../../presentational/components/editor/LightBox';
import ScrollablePanel from '../../../presentational/components/editor/ScrollablePanel';
import Title from '../../../presentational/components/editor/Title';
import Pages from '../../../presentational/components/editor/Pages';
import FusionNameContainer from '../../../presentational/components/editor/FusionNameContainer';
import CloseButton from '../../../presentational/components/editor/CloseButton';
import Button from '../../../presentational/components/Button';
import LoadingScreen from '../../pdfEditor/components/LoadingScreen';

class PdfExplode extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.closeEditor = this.closeEditor.bind(this);
    this.openConfirmModal = this.openConfirmModal.bind(this);
    this.addNode = this.addNode.bind(this);
    this.removeNode = this.removeNode.bind(this);
    this.handleObserver = this.handleObserver.bind(this);
    this.listenClick = this.listenClick.bind(this);
    this.state = {
      openModal: false,
      submitting: false,
      name: '',
      prevY: 0,
    };
  }

  componentDidMount() {
    document.querySelector('body').style.overflow = 'hidden';
    document.addEventListener('click', this.listenClick);

    this.props.loadPdfExplodeAction(this.props.nodeId);

    var options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    };

    this.observer = new IntersectionObserver(
      this.handleObserver.bind(this),
      options
    );

    this.observer.observe(this.loadingRef);
  }

  handleObserver(entities, observer) {
    const y = entities[0].boundingClientRect.y;
    if (this.state.prevY > y) {
      this.props.loadPagesAction(this.props.pagesToLoad.length);
    }

    this.setState({ prevY: y });
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.listenClick);

    if (this.props.isLargeDataroom || this.props.document === null) {
      document.querySelector('body').style.overflow = '';
    }
  }

  submit() {
    this.setState({
      submitting: true,
    });

    this.props.explodePdfAction(
      this.props.nodeId,
      this.props.to,
      this.props.newNodes
    );
  }

  closeEditor() {
    this.props.leavePdfExplodeAction();
  }

  addNode(name) {
    this.props.createNodeAction(name);
  }

  removeNode(index) {
    this.props.removeNodeAction(index);
  }

  openConfirmModal() {
    this.setState({ openModal: true });
  }

  listenClick(e) {
    if (e.target.parentNode.classList.contains('text-node')) {
      return;
    }

    if (e.target.classList.contains('text-node')) {
      return;
    }

    if (e.target.classList.contains('pdf')) {
      return;
    }

    this.props.resetPickPdfAction();
  }

  render() {
    const { loaded, lock, pages, pickedNodeIds, pagesToLoad } = this.props;

    return (
      <ThemeProvider theme={theme}>
        <DarkOverlay background={DARK_COLOR} color={LIGHT_COLOR}>
          {lock.lockedByMe && (
            <CommandButtonsContainer background={DARK_COLOR}>
              <Div margin=".5em">
                <div className="menu">
                  <Button
                    title="valider, enregistrer et quitter"
                    onClick={this.submit}
                    className="menu-btn"
                    disabled={
                      this.state.submitting || !this.props.newNodes.length > 0
                    }
                    marginBottom={0.5}
                  >
                    <i className="fa fa-fw fa-save" />
                  </Button>
                </div>
              </Div>
              {pickedNodeIds.length > 0 && (
                <Div margin=".5em">
                  <div>
                    <Button
                      title="Supprimer la sélection"
                      backgroundColor={DANGER_COLOR}
                      onClick={() => this.removeNode(null)}
                      className="menu-btn"
                      marginBottom={0.5}
                      color={LIGHT_COLOR}
                      borderColor={DANGER_COLOR}
                      backgroundHoverColor={DANGER_COLOR_HOVER}
                      borderHoverColor={DANGER_COLOR_HOVER}
                      hoverColor={LIGHT_COLOR}
                    >
                      <i className="fa fa-fw fa-trash" />
                    </Button>
                  </div>
                </Div>
              )}
            </CommandButtonsContainer>
          )}
          <LightBox background={LIGHT_BACKGROUND_COLOR} color={DARK_COLOR}>
            <CloseButton
              onClick={this.openConfirmModal}
              background={DARK_COLOR}
            />
            <ScrollablePanel>
              <Title color={LIGHT_COLOR} background={DARK_COLOR}>
                Découpage de {this.props.name}
              </Title>
              <Pages>
                <FusionNameContainer className="text-node">
                  <ExplodePdfForm
                    addNode={this.addNode}
                    pickedPageNumbers={this.props.pickedPageNumbers}
                  />
                </FusionNameContainer>
                <FusionNameContainer>
                  {this.props.newNodes.length === 0 && (
                    <div>Aucune nouvelle ligne ajoutée.</div>
                  )}
                  <Level isFirstLevel>
                    {this.props.newNodes.map((newNode, index) => (
                      <div key={index} style={{ display: '-webkit-box' }}>
                        <Document index={index} newNode={newNode} />
                        {this.props.pickedNodeIds.length === 0 && (
                          <Button
                            title="Supprimer la sélection"
                            backgroundColor={DANGER_COLOR}
                            marginLeft="1em"
                            marginTop="0.25em"
                            noMargin
                            onClick={() => {
                              this.removeNode(index);
                            }}
                            className="menu-btn"
                            color={LIGHT_COLOR}
                            borderColor={DANGER_COLOR}
                            backgroundHoverColor={DANGER_COLOR_HOVER}
                            borderHoverColor={DANGER_COLOR_HOVER}
                            hoverColor={LIGHT_COLOR}
                          >
                            <i className="fa fa-fw fa-trash" />
                          </Button>
                        )}
                      </div>
                    ))}
                  </Level>
                </FusionNameContainer>
                {lock.lockedByMe && (
                  <Fragment>
                    {pagesToLoad.map(page => (
                      <Page key={page.pageNumber} page={page} />
                    ))}

                    <div
                      ref={loadingRef => (this.loadingRef = loadingRef)}
                      style={{ background: 'transparent' }}
                    />
                  </Fragment>
                )}
                {!lock.lockedByMe && (
                  <Alert bsStyle="warning">
                    <strong>
                      {' '}
                      <i
                        className="fa fa-exclamation-circle"
                        aria-hidden="true"
                      />{' '}
                      Vous devez verrouiller la dataroom pour découper le
                      fichier.
                    </strong>
                  </Alert>
                )}
              </Pages>
            </ScrollablePanel>
          </LightBox>
          {this.state.openModal && (
            <ModalConfirm
              title="Fermer l’éditeur ?"
              cancelLabel="Annuler"
              leaveLabel="Quitter l’éditeur"
              saveLabel="Sauvegarder et quitter"
              cancel={() => this.setState({ openModal: false })}
              leave={this.closeEditor}
              save={this.submit}
            >
              Vous n’avez pas enregistré vos modifications.
              <br />
              Êtes-vous sûr(e) de vouloir quitter l’éditeur&nbsp;?
            </ModalConfirm>
          )}
          {(this.state.submitting || !loaded) && (
            <LoadingScreen
              theme={this.props.theme}
              zIndex={1000}
              text="Veuillez patienter"
            />
          )}
        </DarkOverlay>
      </ThemeProvider>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    loaded: state.pdfExplode.loaded,
    pickedNodeIds: state.pdfExplode.pickedNodeIds,
    pickedPageNumbers: state.pdfExplode.pickedPageNumbers,
    to: state.pdfExplode.to,
    pages: state.pdfExplode.pages,
    pagesToLoad: state.pdfExplode.loadedPages,
    newNodes: state.pdfExplode.newNodes,
    nodeId: state.pdfExplode.nodeId,
    name: state.dataroom.working.nodes[props.nodeId].name,
    lock: state.dataroom.working.lock,
    isLargeDataroom: state.dataroom.isLargeDataroom,
    document: state.document.document,
  };
}

export default connect(mapStateToProps, {
  loadPdfExplodeAction,
  leavePdfExplodeAction,
  resetPickPdfAction,
  createNodeAction,
  removeNodeAction,
  resetPickNodeAction,
  explodePdfAction,
  loadPagesAction,
})(PdfExplode);
