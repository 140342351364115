(function (t) {
// fr
t.add("name", "nom", "edm\u002Ddataroom", "fr");
t.add("lastPublished", "Date de derni\u00e8re publication pour la", "edm\u002Ddataroom", "fr");
t.add("remainingDocumentsToIntegrate", "Nombre de documents restant \u00e0 int\u00e9grer dans la ", "edm\u002Ddataroom", "fr");
t.add("state", "\u00c9tat", "edm\u002Ddataroom", "fr");
t.add("export_dashboard", "Exporter<br \/> le tableau de bord", "edm\u002Ddataroom", "fr");
t.add("export_dashboard_no_new_line", "Exporter le tableau de bord", "edm\u002Ddataroom", "fr");
t.add("export_summary", "Exporter<br \/> le sommaire", "edm\u002Ddataroom", "fr");
t.add("export_summary_no_new_line", "Exporter le sommaire", "edm\u002Ddataroom", "fr");
t.add("questions_response_assistant", "Assistant de r\u00e9ponse aux questions", "edm\u002Ddataroom", "fr");
t.add("buyerDataRoom", "Dataroom acqu\u00e9reur", "edm\u002Ddataroom", "fr");
t.add("clientDataRoom", "Dataroom client", "edm\u002Ddataroom", "fr");
t.add("no_dataroom", "Il n'y a actuellement pas de dataroom", "edm\u002Ddataroom", "fr");
t.add("asset.new_documents", "Nouveaux documents", "edm\u002Ddataroom", "fr");
t.add("document.see_document", "Voir le document", "edm\u002Ddataroom", "fr");
t.add("edm.search.form_title", "Rechercher des %begin_html_tag% Documents %end_html_tag% dans la Dataroom", "edm\u002Ddataroom", "fr");
t.add("wk_document", " Document \u00e0 fournir par WK ", "edm\u002Ddataroom", "fr");
t.add("client_document", " Document \u00e0 fournir par le client ", "edm\u002Ddataroom", "fr");
t.add("merge_document_mail_explication", "Vous pouvez saisir un email complet ou rechercher directement des noms de personnes pr\u00e9sentes dans le carnet global", "edm\u002Ddataroom", "fr");
t.add("send_selection", "Envoyer la s\u00e9lection", "edm\u002Ddataroom", "fr");
t.add("selection_sent", "Le mail a \u00e9t\u00e9 envoy\u00e9", "edm\u002Ddataroom", "fr");
t.add("selection_error", "Une erreur s'est produite lors de l'envoi", "edm\u002Ddataroom", "fr");
t.add("error.select_one_at_least_one_mail", "Veuillez s\u00e9lectionner au moins un destinataire", "edm\u002Ddataroom", "fr");
t.add("selected_documents", "Veuillez s\u00e9lectionner au moins un destinataire", "edm\u002Ddataroom", "fr");
t.add("selected_documents_number", "%count% document |]1,Inf[ %count% documents", "edm\u002Ddataroom", "fr");
t.add("you_selected", "Vous avez s\u00e9lectionn\u00e9", "edm\u002Ddataroom", "fr");
t.add("total_size_of", "pour une taille totale de", "edm\u002Ddataroom", "fr");
t.add("select", "S\u00e9lectionner", "edm\u002Ddataroom", "fr");
t.add("deselect", "D\u00e9s\u00e9lectionner", "edm\u002Ddataroom", "fr");
t.add("fold", "Replier", "edm\u002Ddataroom", "fr");
t.add("unfold", "D\u00e9plier", "edm\u002Ddataroom", "fr");
t.add("print_selection", "Imprimer la s\u00e9lection", "edm\u002Ddataroom", "fr");
t.add("print_selection_without_cover_page", "Imprimer la s\u00e9lection sans page de garde", "edm\u002Ddataroom", "fr");
t.add("receive", "Recevoir", "edm\u002Ddataroom", "fr");
t.add("the_selection", "la s\u00e9lection", "edm\u002Ddataroom", "fr");
t.add("is_favorite_question", "Favoris ?", "edm\u002Ddataroom", "fr");
t.add("documents_printing", "Impression de documents", "edm\u002Ddataroom", "fr");
t.add("document", "Document", "edm\u002Ddataroom", "fr");
t.add("last_updated_at", "Derni\u00e8re mise \u00e0 jour", "edm\u002Ddataroom", "fr");
t.add("mega_bytes", "Mo", "edm\u002Ddataroom", "fr");
t.add("initialize_buyer_dataroom", "Initialisation Dataroom Acqu\u00e9reur", "edm\u002Ddataroom", "fr");
t.add("STEP_DATAROOM_TYPE", "Choix du type de dataroom", "edm\u002Ddataroom", "fr");
t.add("STEP_DATAROOM_CHOICE", "Choix de la dataroom", "edm\u002Ddataroom", "fr");
t.add("STEP_NODES_CHOICE", "Choix des lignes", "edm\u002Ddataroom", "fr");
t.add("dataroom_type", "Type de dataroom", "edm\u002Ddataroom", "fr");
t.add("reinitialize_client_dataroom", "Reg\u00e9n\u00e9rer la dataroom client", "edm\u002Ddataroom", "fr");
t.add("CHOOSE_DATAROOM", "Choix dataroom", "edm\u002Ddataroom", "fr");
t.add("VALIDATE_CHOICE", "Validation", "edm\u002Ddataroom", "fr");
t.add("working", "Travail", "edm\u002Ddataroom", "fr");
t.add("published", "Publi\u00e9e", "edm\u002Ddataroom", "fr");
t.add("NAME_TOO_LONG", "Nom trop long", "edm\u002Ddataroom", "fr");
t.add("NAME_WITH_ILLEGAL_CHAR", "Caract\u00e8res interdits", "edm\u002Ddataroom", "fr");
t.add("MISSING_FILE", "fichier absent", "edm\u002Ddataroom", "fr");
t.add("FOLDER_HAS_FOLDER_AND_DOCUMENTS_AS_FIRST_CHILDREN", "Pr\u00e9sence de dossier et document", "edm\u002Ddataroom", "fr");
t.add("EMPTY_FOLDER", "Dossier vide", "edm\u002Ddataroom", "fr");
t.add("export-error", "Une erreur est survenue lors de l'export", "edm\u002Ddataroom", "fr");
t.add("warning-documents-type-print", "Vous avez s\u00e9lectionn\u00e9 %documentsNumber% document(s) qui ne peuvent-\u00eatre pr\u00e9-visualis\u00e9s, ils ne seront pas pr\u00e9sents dans l'impression", "edm\u002Ddataroom", "fr");
t.add("reinitialize", "R\u00c9INITIALISER", "edm\u002Ddataroom", "fr");
t.add("see-full-screen", "Voir en plein \u00e9cran", "edm\u002Ddataroom", "fr");
t.add("dataroom", "Dataroom", "edm\u002Ddataroom", "fr");
t.add("edm.search.from", "D\u00e9but", "edm\u002Ddataroom", "fr");
t.add("edm.search.to", "Fin", "edm\u002Ddataroom", "fr");
t.add("edm.search.keywords", "Mots-cl\u00e9s", "edm\u002Ddataroom", "fr");
t.add("receive_selection_without_cover_page", "Recevoir la s\u00e9lection sans page de garde", "edm\u002Ddataroom", "fr");
t.add("error.print-pdf", "Une erreur s'est produite lors de la g\u00e9n\u00e9ration du PDF", "edm\u002Ddataroom", "fr");
t.add("number_of_comments", "Nombre de commentaires", "edm\u002Ddataroom", "fr");
t.add("number_of_questions", "Nombre de questions", "edm\u002Ddataroom", "fr");
t.add("number_of_documents_with_file", "Nombre de documents ayant des documents associ\u00e9s", "edm\u002Ddataroom", "fr");
t.add("number_of_documents_without_file", "Nombre de documents n'ayant pas de documents associ\u00e9s", "edm\u002Ddataroom", "fr");
t.add("initialize_buyer_dataroom_message", "Publiez la dataroom client avant d'initialiser la dataroom acquereur", "edm\u002Ddataroom", "fr");
t.add("send_export_dataroom", "Export d'une dataroom par mail", "edm\u002Ddataroom", "fr");
t.add("create_buyer_dataroom", "Cr\u00e9ation d'une Dataroom Acqu\u00e9reur", "edm\u002Ddataroom", "fr");
t.add("STEP_DATAROOM_NAME", "Choix du nom", "edm\u002Ddataroom", "fr");
t.add("dataroom_name", "Nom", "edm\u002Ddataroom", "fr");
t.add("create_from_dataroom", "Cr\u00e9er \u00e0 partir de la dataroom", "edm\u002Ddataroom", "fr");
t.add("rename_buyer_dataroom", "Renommer la dataroom", "edm\u002Ddataroom", "fr");
t.add("buyer_dataroom_empty_name", "Une dataroom doit avoir un nom valide", "edm\u002Ddataroom", "fr");
t.add("remove_buyer_dataroom", "Supprimer la dataroom", "edm\u002Ddataroom", "fr");
t.add("open-pdf-editor", "Editer le document", "edm\u002Ddataroom", "fr");
t.add("internal_file", "Fiche interne", "edm\u002Ddataroom", "fr");
t.add("cridon_folder", "Dossier CRIDON", "edm\u002Ddataroom", "fr");
t.add("consultation_cridon", "Consultation CRIDON", "edm\u002Ddataroom", "fr");
t.add("lawyer_consultation", "Consultation avocat", "edm\u002Ddataroom", "fr");
t.add("training_support", "Support de formation", "edm\u002Ddataroom", "fr");
t.add("regulation", "R\u00e8glement", "edm\u002Ddataroom", "fr");
t.add("jurisprudence", "Jurisprudence", "edm\u002Ddataroom", "fr");
t.add("administrative_doctrine", "Doctrine administrative", "edm\u002Ddataroom", "fr");
t.add("doctrine", "Doctrine", "edm\u002Ddataroom", "fr");
t.add("metadata_assistant", "Assistant de metadatas", "edm\u002Ddataroom", "fr");
t.add("metadata_assistant.no_data", "Aucune m\u00e9tadata en attente de modification !", "edm\u002Ddataroom", "fr");
t.add("asset.internal_new_documents", "Nouveaux documents interne", "edm\u002Ddataroom", "fr");
t.add("last_inserted_files", "Liste des nouveaux documents", "edm\u002Ddataroom", "fr");
t.add("preview_in_progress", "G\u00e9n\u00e9ration de la pr\u00e9visualisation en cours", "edm\u002Ddataroom", "fr");
t.add("preview_not_finished", "La g\u00e9n\u00e9ration de la pr\u00e9visualisation prend du temps, revenez plus tard", "edm\u002Ddataroom", "fr");
t.add("preview_error", "La pr\u00e9visualisation n'est pas disponible pour ce document", "edm\u002Ddataroom", "fr");
t.add("menu", "Menu", "edm\u002Ddataroom", "fr");
t.add("menu_open_panel", "Agrandir le panneau d\u2019actions", "edm\u002Ddataroom", "fr");
t.add("menu_close_panel", "R\u00e9duire le panneau d\u2019actions", "edm\u002Ddataroom", "fr");
t.add("view_document", "Visualiser le document", "edm\u002Ddataroom", "fr");
t.add("search_document", "Rechercher des documents dans la dataroom ?", "edm\u002Ddataroom", "fr");
t.add("loading", "Chargement en cours...", "edm\u002Ddataroom", "fr");
t.add("print.no_pdf", "Votre s\u00e9lection ne contient aucun document.", "edm\u002Ddataroom", "fr");
t.add("no_metadata", "Aucune m\u00e9tadata !", "edm\u002Ddataroom", "fr");
t.add("title_search_metadatas", "Recherche dans la documentation", "edm\u002Ddataroom", "fr");
t.add("DUPLICATED_FILE", "Fichier d\u00e9j\u00e0 pr\u00e9sent", "edm\u002Ddataroom", "fr");
})(Translator);
