import React, {
  Fragment,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import Helmet from 'react-helmet';
import Layout from '../../app/layout/Layout';
import { Menu } from '../../app/components/menu/Menu';
import Content from '../../app/layout/Content';
import Notifications from '../../dataroom/app/containers/Notifications';
import Span from '../../presentational/components/Span';
import {
  BODY_FONT,
  CLIENT_UI_COLOR,
  DANGER_COLOR,
} from '../../presentational/components/theme_blue';
import IconDataroom from '../../presentational/components/img/icons/IconDataroom';
import H2 from '../../presentational/components/H2';
import DataRoomForm from '../components/dataroom/DataRoomForm';
import queryString from 'qs';
import { useQuery } from '../../app/hooks/apiClient';

export default function DataRoomEdition(props) {
  const url = useMemo(() => {
    return `/api/datarooms/${
      props.routeParams.dataroomId
    }?${queryString.stringify({
      resources: ['DATAROOM_COMPANY', 'DATAROOM_OWNER', 'DATAROOM_USERS'],
    })}`;
  }, [props.routeParams.dataroomId]);

  const [loaded, , data] = useQuery(url);

  return (
    <Fragment>
      <Helmet>
        <title>Espace client - Edition de dataroom</title>
      </Helmet>

      <Layout>
        <Menu dataroom />

        <Content>
          <H2>
            <Span
              color={CLIENT_UI_COLOR}
              fontFamily={BODY_FONT}
              display="flex"
              alignItems="center"
              justifyContent="center"
              minWidth="100%"
            >
              <IconDataroom marginRight=".5em" marginBottom="-.1em" />
              Edition d'une dataroom
            </Span>
          </H2>
          {loaded && (
            <DataRoomForm
              dataroom={data}
              defaultValues={{
                name: data.name,
                isManipulable: data.manipulable,
                comment: data.comment,
                users: data.users.map(dataroomRight => {
                  return {
                    readable: dataroomRight.readable,
                    writable: dataroomRight.writable,
                    user: dataroomRight.user,
                  };
                }),
              }}
            />
          )}
        </Content>
      </Layout>

      <Notifications />
    </Fragment>
  );
}
