import styled from 'styled-components';

export default styled.div`
  overflow: auto;
  flex-grow: 1;
  position: relative;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;
