import { createSelector } from 'reselect';
import workingNodesSelector from './workingNodesSelector';

export default createSelector(
  [state => state.dataroom.working.pickedNodeIds, workingNodesSelector],
  (nodeIds, nodes) => {
    return nodeIds.map(
      selectedNodeIdInContextMenu => nodes[selectedNodeIdInContextMenu]
    );
  }
);
