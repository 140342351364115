(function (t) {
// fr
t.add("mail_list", "Liste des notifications", "stats\u002Dmail", "fr");
t.add("mail_contact_search", "Recherche des notifications envoy\u00e9es aux contacts", "stats\u002Dmail", "fr");
t.add("BY_WALLET", "Par portefeuille", "stats\u002Dmail", "fr");
t.add("BY_ASSET", "Par actif", "stats\u002Dmail", "fr");
t.add("BY_GROUP", "Par groupe", "stats\u002Dmail", "fr");
t.add("BY_CONTACT", "Par contact", "stats\u002Dmail", "fr");
t.add("waiting", "En attente d'envoi", "stats\u002Dmail", "fr");
t.add("clicked", "Cliqu\u00e9", "stats\u002Dmail", "fr");
t.add("opened", "Ouvert", "stats\u002Dmail", "fr");
t.add("sent", "D\u00e9livr\u00e9", "stats\u002Dmail", "fr");
t.add("marked_as_spam", "Spam", "stats\u002Dmail", "fr");
t.add("bounced", "Erreur", "stats\u002Dmail", "fr");
t.add("blocked", "Bloqu\u00e9", "stats\u002Dmail", "fr");
t.add("all", "Tous", "stats\u002Dmail", "fr");
t.add("DATE_ASC", " \u2191 Date \u2014 des + anciens aux + r\u00e9cents ", "stats\u002Dmail", "fr");
t.add("DATE_DESC", " \u2193 Date \u2014 des + r\u00e9cents aux + anciens ", "stats\u002Dmail", "fr");
t.add("action_mail_stats", "Voir les notifications envoy\u00e9es", "stats\u002Dmail", "fr");
t.add("search_mail", "Recherche des notifications", "stats\u002Dmail", "fr");
t.add("created_at", "Envoy\u00e9", "stats\u002Dmail", "fr");
t.add("sent_to", "Envoy\u00e9 \u00e0", "stats\u002Dmail", "fr");
t.add("not_sent", "Erreur \u00e0 l'envoi", "stats\u002Dmail", "fr");
t.add("comment.created", "Commentaire cr\u00e9\u00e9", "stats\u002Dmail", "fr");
t.add("question.asked", "Question pos\u00e9e", "stats\u002Dmail", "fr");
t.add("question.answered", "Question r\u00e9pondue", "stats\u002Dmail", "fr");
t.add("question.validated_and_answered", "Question r\u00e9pondue et valid\u00e9e", "stats\u002Dmail", "fr");
t.add("edm.dataroom_open", "Dataroom ouverte", "stats\u002Dmail", "fr");
t.add("edm.share_merged_documents", "Envoi de documents", "stats\u002Dmail", "fr");
t.add("FORGOTTEN_PASSWORD", "Mot de passe oubli\u00e9", "stats\u002Dmail", "fr");
t.add("ACCOUNT_ACCESS", "Envoi des acc\u00e8s", "stats\u002Dmail", "fr");
t.add("data_room.published", "Dataroom publi\u00e9e", "stats\u002Dmail", "fr");
t.add("edm.share_exported_dataroom", "Export de dataroom", "stats\u002Dmail", "fr");
t.add("state", "Etat", "stats\u002Dmail", "fr");
t.add("sender", "Exp\u00e9diteur", "stats\u002Dmail", "fr");
t.add("notificationType", "Type de notification", "stats\u002Dmail", "fr");
t.add("link", "lien", "stats\u002Dmail", "fr");
})(Translator);
