import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import './FolderNode.css';
import { ContextMenuTrigger } from 'react-contextmenu';
import { makeShortenedNodeSelector } from '../selectors/shortenedNodeNameSelector';
import RenameNode from '../containers/RenameNode';
import StickyNode from './StickyNode';
import AddNodes from '../containers/AddNodes';
import ValidationErrors from './ValidationErrors';
import { AFTER, BEFORE, INSIDE } from '../ducks/utils/getNextPath';

class FolderNode extends Component {
  constructor(props) {
    super(props);
    this.shouldComponentUpdate = this.shouldComponentUpdate.bind(this);
    this.shortenedNode = makeShortenedNodeSelector();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextProps !== this.props || nextState !== this.state;
  }

  render() {
    const { folder, TreeNodeComponent } = this.props;

    let action = null;
    let listClass = '';

    let folderName = this.shortenedNode(folder);
    if (folder.matched) {
      folderName = <em>{folderName}</em>;
    }

    folderName = (
      <span
        style={
          folder.menuOpen ? { backgroundColor: 'rgba(46, 117, 191, 0.3)' } : {}
        }
        className="node-name"
        title={folder.name}
      >
        {folderName}
      </span>
    );

    if (folder.rename) {
      folderName = (
        <RenameNode path={folder.path} nodeId={folder.id} name={folder.name} />
      );
    }

    if (folder.folded) {
      action = this.props.unFoldAction;
      listClass = 'hidden';
    } else {
      action = this.props.foldAction;
    }

    let nodeComponent = (
      <ContextMenuTrigger
        holdToDisplay={-1}
        collect={() => ({ node: folder })}
        id="NODE_MENU"
        renderTag="div"
        attributes={{
          style: {
            paddingBottom: '3px',
            paddingTop: '3px',
          },
        }}
      >
        {this.props.checkBoxElement}
        <button onClick={action} className="o">
          <i />
        </button>
        {folder.path !== '0' && <u>{folder.path} </u>}
        {folderName}
        &nbsp;
        <a
          title={Translator.trans(
            'number_of_documents_with_file',
            {},
            'edm-dataroom'
          )}
          className="documentWithFileNumber"
        >
          {folder.documentWithFileNumber} <i className="fa fa-files-o" />
        </a>{' '}
        <a
          title={Translator.trans(
            'number_of_documents_without_file',
            {},
            'edm-dataroom'
          )}
          className="document-number-without-file"
        >
          {folder.documentWithoutFileNumber} <i className="fa fa-files-o" />
        </a>
        {folder.validationErrors && (
          <Fragment>
            &nbsp;
            <ValidationErrors
              tooltipId={folder.id}
              validationErrors={folder.validationErrors}
            />{' '}
          </Fragment>
        )}
      </ContextMenuTrigger>
    );

    if (folder.rename) {
      nodeComponent = <StickyNode>{nodeComponent}</StickyNode>;
    }

    return (
      <Fragment>
        {folder.addNodes === BEFORE && (
          <StickyNode>
            <AddNodes />
          </StickyNode>
        )}
        {nodeComponent}
        {!folder.folded && (
          <ol className={listClass}>
            {folder.addNodes === INSIDE && (
              <li className="i f">
                <StickyNode>
                  <AddNodes />
                </StickyNode>
              </li>
            )}

            {folder.childIds.map(nodeId => (
              <TreeNodeComponent key={nodeId} nodeId={nodeId} />
            ))}
          </ol>
        )}

        {folder.addNodes === AFTER && (
          <StickyNode>
            <AddNodes />
          </StickyNode>
        )}
      </Fragment>
    );
  }
}

FolderNode.propTypes = {
  folder: PropTypes.object.isRequired,
  unFoldAction: PropTypes.func.isRequired,
  foldAction: PropTypes.func.isRequired,
};

export default FolderNode;
