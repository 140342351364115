import createUndoableActionReducer from '../reducers/createUndoableActionReducer';
import { takeEvery, put, select, call } from 'redux-saga/effects';
import undoSaga from './dataroomActions/undoSaga';
import { PASTE_NODE, TYPE_CUT } from './nodesClipBoard';
import getNextPath, { AFTER, INSIDE, BEFORE } from './utils/getNextPath';
import getParentIdForInsertion from './utils/getParentIdForInsertion';
import getIndexForInsert from './utils/getIndexForInsert';
import updatePathForDescendant from './utils/updatePathForDescendant';
import childNode from './utils/childNode';
import updatePath from './utils/updatePath';
import getPathLevel from './utils/getPathLevel';
import getPosition from './utils/getPosition';
import { uniqueId } from 'underscore';
import addDocumentNumber, {
  addDocumentNumberWithoutFile,
} from './utils/addDocumentNumber';
import { TYPE_DOCUMENT, TYPE_FOLDER } from '../documents/type';

import apiClient from 'js/dataroom/core/apiClient';

export const MOVE_NODES = 'MOVE_NODES';

export const moveNodesAction = (sourceNodes, destinationNode, position) => {
  const pathLevel = getPathLevel(sourceNodes[0].path);

  return {
    type: MOVE_NODES,
    sourceNodeIds: sourceNodes
      .sort(
        (node1, node2) =>
          getPosition(pathLevel, node1.path) -
          getPosition(pathLevel, node2.path)
      )
      .map(node => node.id),
    destinationId: destinationNode.id,
    destinationPath: getNextPath(destinationNode.path, position),
    position,
    frontActionId: uniqueId('dataroom_action'),
  };
};

function* applyMoveNodeSaga(action) {
  const { sourceNodes, destinationNode } = yield select(state => ({
    destinationNode: state.dataroom.working.nodes[action.destinationId],
    sourceNodes: action.clipBoard.nodeIds.map(
      nodeId => state.dataroom.working.nodes[nodeId]
    ),
  }));

  yield put(moveNodesAction(sourceNodes, destinationNode, action.position));
}

export function* pasteNodeSaga() {
  yield takeEvery(
    action => action.type === PASTE_NODE && action.clipBoard.type === TYPE_CUT,
    applyMoveNodeSaga
  );
}

export function* undoActionSaga() {
  yield takeEvery('UNDO_MOVE_NODES', undoSaga);
}

const moveNodeApi = (action, dataroomId) =>
  apiClient.request(
    new Request(`/api/datarooms/${dataroomId}/nodes/move`, {
      method: 'POST',
      body: JSON.stringify({
        nodeIds: action.sourceNodeIds,
        to: action.destinationPath,
      }),
    })
  );

export function* saga(action) {
  const selectedState = yield select(state => state.dataroom.dataroom.id);

  return yield call(moveNodeApi, action, selectedState);
}

const movementWillRenumberFutureAscendant = (sourcePath, destinationPath) => {
  const destinationPathLevel = getPathLevel(destinationPath);
  const sourcePathLevel = getPathLevel(sourcePath);
  const sourcePositionLevel = getPosition(sourcePathLevel, sourcePath);

  const iDirectAscendant =
    sourcePathLevel === 1 ||
    getPosition(sourcePathLevel - 1, destinationPath) ===
      getPosition(sourcePathLevel - 1, sourcePath);

  return (
    iDirectAscendant &&
    destinationPathLevel >= sourcePathLevel &&
    sourcePositionLevel < getPosition(sourcePathLevel, destinationPath)
  );
};

export function moveNodes(state, action) {
  let newState = {
    ...state,
    working: {
      ...state.working,
      savedNodes: {
        ...state.working.savedNodes,
        [action.frontActionId]: {},
      },
    },
  };

  let sourceNodeIds = action.sourceNodeIds;

  if (action.position === AFTER || action.position === INSIDE) {
    sourceNodeIds = [...sourceNodeIds].reverse();
  }

  sourceNodeIds.forEach(sourceNodeId => {
    newState = moveNode(newState, {
      destinationId: action.destinationId,
      position: action.position,
      sourceId: sourceNodeId,
      frontActionId: action.frontActionId,
      destinationPath: getNextPath(
        newState.working.nodes[action.destinationId].path,
        action.position
      ),
      sourcePath: newState.working.nodes[sourceNodeId].path,
    });
  });

  return newState;
}

function moveNode(state, action) {
  const { sourceId, destinationId, position, destinationPath } = action;
  const { nodes } = state.working;
  const sourcePath = nodes[sourceId].path;
  const sourceNode = nodes[sourceId];
  const parentNode = nodes[sourceNode.parentId];
  const savedNodes = {};
  const futureParentNode =
    nodes[getParentIdForInsertion(nodes, destinationId, position)];
  const parentNodeChildIds = [...parentNode.childIds];
  const futureParentNodeChildIds =
    parentNode.id === futureParentNode.id
      ? parentNodeChildIds
      : [...futureParentNode.childIds];

  parentNodeChildIds.splice(parentNodeChildIds.indexOf(sourceNode.id), 1);

  futureParentNodeChildIds.splice(
    getIndexForInsert(futureParentNodeChildIds, destinationId, position),
    0,
    sourceId
  );

  let newNodes = updatePathForDescendant(nodes, sourceId, AFTER, -1);
  newNodes = updatePathForDescendant(newNodes, destinationId, position, 1);

  let updatedDestinationPath = destinationPath;
  const sourcePathLevel = getPathLevel(sourcePath);
  const sourcePositionLevel = getPosition(sourcePathLevel, sourcePath);

  savedNodes.parent = parentNode;
  savedNodes.source = sourceNode;
  if (sourcePositionLevel !== 1) {
    savedNodes.previousSibling =
      nodes[parentNode.childIds[sourcePositionLevel - 2]];
  }

  if (movementWillRenumberFutureAscendant(sourcePath, destinationPath)) {
    updatedDestinationPath = updatePath(
      sourcePathLevel,
      updatedDestinationPath,
      position => position - 1
    );
  }

  for (const childNodeItem of childNode(nodes, sourceId)) {
    newNodes[childNodeItem.id] = {
      ...childNodeItem,
      path: childNodeItem.path.replace(sourceNode.path, updatedDestinationPath),
    };
  }

  if (sourceNode.type === TYPE_DOCUMENT && sourceNode.file) {
    newNodes = addDocumentNumber(sourceNode.parentId, newNodes, -1);
    newNodes = addDocumentNumber(futureParentNode.id, newNodes, 1);
  }
  if (sourceNode.type === TYPE_DOCUMENT && !sourceNode.file) {
    newNodes = addDocumentNumberWithoutFile(sourceNode.parentId, newNodes, -1);
    newNodes = addDocumentNumberWithoutFile(futureParentNode.id, newNodes, 1);
  }

  if (sourceNode.type === TYPE_FOLDER) {
    newNodes = addDocumentNumber(
      sourceNode.parentId,
      newNodes,
      -sourceNode.documentWithFileNumber
    );
    newNodes = addDocumentNumber(
      futureParentNode.id,
      newNodes,
      sourceNode.documentWithFileNumber
    );
    newNodes = addDocumentNumberWithoutFile(
      sourceNode.parentId,
      newNodes,
      -sourceNode.documentWithoutFileNumber
    );
    newNodes = addDocumentNumberWithoutFile(
      futureParentNode.id,
      newNodes,
      sourceNode.documentWithoutFileNumber
    );
  }

  return {
    ...state,
    working: {
      ...state.working,
      savedNodes: {
        ...state.working.savedNodes,
        [action.frontActionId]: {
          ...state.working.savedNodes[action.frontActionId],
          [action.sourceId]: savedNodes,
        },
      },
      nodes: {
        ...newNodes,
        [sourceNode.parentId]: {
          ...newNodes[sourceNode.parentId],
          childIds: parentNodeChildIds,
        },
        [futureParentNode.id]: {
          ...newNodes[futureParentNode.id],
          childIds: futureParentNodeChildIds,
        },
        [sourceId]: {
          ...newNodes[sourceId],
          path: updatedDestinationPath,
          parentId: futureParentNode.id,
        },
      },
    },
  };
}

const getReversedAction = (nodes, savedNodes, sourceId) => {
  const sourcePath = savedNodes.source.path;

  const sourcePathLevel = getPathLevel(sourcePath);
  const sourcePosition = getPosition(sourcePathLevel, sourcePath);

  if (sourcePosition === 1) {
    return moveNodesAction(
      [nodes[sourceId]],
      nodes[savedNodes.parent.id],
      INSIDE
    );
  }

  return moveNodesAction(
    [nodes[sourceId]],
    nodes[savedNodes.previousSibling.id],
    AFTER
  );
};

function undoMoveNodes(state, action) {
  let newState = { ...state };

  let sourceNodeIds = action.sourceNodeIds;

  if (action.position === BEFORE) {
    sourceNodeIds = [...sourceNodeIds].reverse();
  }

  sourceNodeIds.forEach(sourceNodeId => {
    const reversedAction = getReversedAction(
      newState.working.nodes,
      state.working.savedNodes[action.frontActionId][sourceNodeId],
      sourceNodeId
    );

    newState = moveNodes(newState, reversedAction);

    delete newState.working.savedNodes[action.frontActionId];
  });

  return newState;
}

export default createUndoableActionReducer(
  MOVE_NODES,
  moveNodes,
  undoMoveNodes
);
