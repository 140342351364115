import { call, put, takeEvery } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import apiClient from 'js/dataroom/core/apiClient';
import queryString from 'qs';

export const FETCH_DOCUMENT = 'FETCH_DOCUMENT';
export const LOAD_DOCUMENT = 'LOAD_DOCUMENT';
export const RESET_DOCUMENT = 'RESET_DOCUMENT';

export const CHECK_PREVIEW_STATUS = 'CHECK_PREVIEW_STATUS';
export const PREVIEW_FINISHED = 'DOCUMENT_PREVIEW_FINISHED';
export const PREVIEW_NOT_FINISHED = 'DOCUMENT_PREVIEW_NOT_FINISHED';
export const DISPLAY_CHECKING_PREVIEW = 'DISPLAY_CHECKING_PREVIEW';

export const previableExtensions = [
  'docx',
  'doc',
  'xlsx',
  'xls',
  'pptx',
  'ppt',
  'csv',
  'msg',
  'dwg',
  'zip',
  'tiff',
  'tif',
  'xlsm',
  'rar',
];

export function loadDocument(document, anonymous) {
  return {
    type: LOAD_DOCUMENT,
    document: document,
    anonymous,
  };
}

export function checkDocumentPreviewStatus(documentId) {
  return {
    type: CHECK_PREVIEW_STATUS,
    documentId,
  };
}

export function fetchDocument(dataroomId, documentId, anonymous) {
  return (dispatch, getState) => {
    dispatch({ type: FETCH_DOCUMENT, anonymous });

    let query = {
      resources: [],
    };
    if (!getState().dataroom.dataroom) {
      query.resources.push('NODE_DATAROOM');
    }

    apiClient
      .request(
        new Request(`/api/nodes/${documentId}?${queryString.stringify(query)}`)
      )
      .then(response => {
        if (response.ok) {
          return response.json().then(document => {
            dispatch({
              type: LOAD_DOCUMENT,
              document: document,
              anonymous,
            });
          });
        }
      });
  };
}

export function resetDocumentPage() {
  return {
    type: RESET_DOCUMENT,
  };
}

export function* saga() {
  yield takeEvery(CHECK_PREVIEW_STATUS, checkDocumentPreviewStatusSaga);
}

const getPreviewStatus = documentId =>
  apiClient
    .request(new Request(`/api/nodes/${documentId}`))
    .then(response => response.json())
    .then(document => {
      return document.file.previewStatus;
    });

function* checkDocumentPreviewStatusSaga(action) {
  const TRY_NUMBER = 13;

  let previewStatus = '';
  for (let i = 1; i <= TRY_NUMBER; i++) {
    yield delay(fibonacci(i) * 350);

    previewStatus = yield call(getPreviewStatus, action.documentId);

    if (previewStatus !== 'in_progress') {
      yield put({
        type: PREVIEW_FINISHED,
        previewStatus,
        documentId: action.documentId,
      });
      break;
    }
    if (i === 1) {
      yield put({
        type: DISPLAY_CHECKING_PREVIEW,
      });
    }
  }
  if (previewStatus === 'in_progress') {
    yield put({
      type: PREVIEW_NOT_FINISHED,
    });
  }
}

function fibonacci(n) {
  if (n < 2) {
    return n;
  }
  return fibonacci(n - 2) + fibonacci(n - 1);
}
