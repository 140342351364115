import React, { Fragment } from 'react';
import styled from 'styled-components';

const Div = styled.div`
  display: block;
  flex-basis: ${props => (props.displayBlock ? '100%' : 'auto')};
  cursor: default;
`;
const Span = styled.span`
  display: ${props => (props.displayBlock ? 'block' : 'inline-block')};
  color: ${props => props.theme.dangerColor};
  padding: 0.25em 0.5em;
  font-size: 0.75em;
`;
class ErrorMessage extends React.Component {
  render() {
    return (
      <Div displayBlock={this.props.displayBlock}>
        <Span displayBlock={this.props.displayBlock}>
          {this.props.children}
        </Span>
      </Div>
    );
  }
}

export default ErrorMessage;
