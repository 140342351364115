(function (t) {
// fr
t.add("asset.unread_comments", "Commentaires non lus", "discussion", "fr");
t.add("asset.all_comments", "Tous les commentaires", "discussion", "fr");
t.add("asset.export_all_comments", "Exporter tous les commentaires", "discussion", "fr");
t.add("asset.unread_questions", "Questions non lues", "discussion", "fr");
t.add("asset.unresponded_questions", "Questions sans r\u00e9ponse", "discussion", "fr");
t.add("asset.all_questions", "Toutes les questions", "discussion", "fr");
t.add("asset.export_all_questions", "Exporter toutes les questions", "discussion", "fr");
t.add("asset.unvalidated_answerds", "R\u00e9ponses non valid\u00e9es", "discussion", "fr");
t.add("questions", "Questions", "discussion", "fr");
t.add("comments", "Commentaires", "discussion", "fr");
t.add("comment.list", "Liste des commentaires", "discussion", "fr");
t.add("ORDER_BY_CREATED_AT_DESC", "\u2193 Date \u2014 des + r\u00e9centes aux + anciennes ", "discussion", "fr");
t.add("ORDER_BY_CREATED_AT_ASC", " \u2191 Date \u2014 des + anciennes aux + r\u00e9centes ", "discussion", "fr");
t.add("ORDER_BY_TREE_LEVEL_ASC", " \u2193 Nveau arborescent \u2014 descendant ", "discussion", "fr");
t.add("ORDER_BY_TREE_LEVEL_DESC", " \u2191 Nveau arborescent \u2014 ascendant ", "discussion", "fr");
t.add("ALL", "Tous", "discussion", "fr");
t.add("STATE_READ", " Lus ", "discussion", "fr");
t.add("STATE_UNREAD", " Non lus ", "discussion", "fr");
t.add("State", " Etat ", "discussion", "fr");
t.add("Order", "Ordre", "discussion", "fr");
t.add("From", "De", "discussion", "fr");
t.add("To", "A", "discussion", "fr");
t.add("select_asset_by_name", "S\u00e9lectionnez un actif par son nom", "discussion", "fr");
t.add("select_folder_from_asset", "S\u00e9lectionnez un dossier de l\u2019arborescence de l'actif ci-dessus", "discussion", "fr");
t.add("select_wallet_by_name", " S\u00e9lectionnez un portefeuille par son nom ", "discussion", "fr");
t.add("by_asset", "Par actif", "discussion", "fr");
t.add("by_wallet", "Par portefeuille", "discussion", "fr");
t.add("comment_attachment", "Pi\u00e8ce jointe \u00e0 ce commentaire", "discussion", "fr");
t.add("add_comment", "Ajouter un commentaire", "discussion", "fr");
t.add("select_buyer_group_by_name", "S\u00e9lectionnez un groupe par son nom ", "discussion", "fr");
t.add("by_buyer_group", "Par groupe", "discussion", "fr");
t.add("NO_ANSWERS", "Question sans r\u00e9ponse", "discussion", "fr");
t.add("NO_ANSWERS_VALIDATE", "R\u00e9ponses non valid\u00e9es", "discussion", "fr");
t.add("ANSWERS_VALIDATE", "R\u00e9ponses valid\u00e9es", "discussion", "fr");
t.add("NO_ANSWERS_OR_NO_ANSWERS_VALIDATE", "Question sans r\u00e9ponse ou non valid\u00e9es", "discussion", "fr");
t.add("ORDER_BY_QUESTION_ADDED_AT_DESC", " \u2193 Date \u2014 des + r\u00e9centes aux + anciennes ", "discussion", "fr");
t.add("ORDER_BY_QUESTION_ADDED_AT_ASC", " \u2191 Date \u2014 des + anciennes aux + r\u00e9centes ", "discussion", "fr");
t.add("question.list", "Liste des questions", "discussion", "fr");
t.add("is_answer_validated", "R\u00e9ponse valid\u00e9e ?", "discussion", "fr");
t.add("your_answer", "Votre r\u00e9ponse", "discussion", "fr");
t.add("edit_answer", "Modifier la r\u00e9ponse", "discussion", "fr");
t.add("save_and_validate_answer", "Enregistrer et valider%br_tag%la r\u00e9ponse ", "discussion", "fr");
t.add("save_answer", "Enregistrer%br_tag%la r\u00e9ponse", "discussion", "fr");
t.add("answer_of", "R\u00e9ponse de", "discussion", "fr");
t.add("see_buyer_group_page", "Voir la fiche descriptive du group acqu\u00e9reur", "discussion", "fr");
t.add("see_asset_page", "Voir la fiche descriptive de l'actif", "discussion", "fr");
t.add("go_to_hierarchy", "Aller \u00e0 ce niveau d'arborescence", "discussion", "fr");
t.add("see_document", "Afficher le document", "discussion", "fr");
t.add("no_answer_yet", "Personne n'a encore r\u00e9pondu \u00e0 cette question", "discussion", "fr");
t.add("assistant_qr", "Assistant Questions\/R\u00e9ponses", "discussion", "fr");
t.add("question_numbers", "Question %current% sur %total%", "discussion", "fr");
t.add("question_answered", "Question R\u00e9pondue", "discussion", "fr");
t.add("marked_as_read_question", "Marqu\u00e9 %br_html% comme lu?", "discussion", "fr");
t.add("asset.unvalidated_questions", "R\u00e9ponse non valid\u00e9e", "discussion", "fr");
t.add("last_comments", "Derniers commentaires", "discussion", "fr");
t.add("last_questions", "Derni\u00e8res questions\/r\u00e9ponses", "discussion", "fr");
t.add("message", "Message", "discussion", "fr");
t.add("comment_file_format_infos", "Le fichier doit \u00eatre au format PDF et ne doit pas exc\u00e9der 80Mo.", "discussion", "fr");
t.add("add_file_to_comment", "Ajouter un fichier", "discussion", "fr");
t.add("error.empty_comment", "Le commentaire ne doit pas \u00eatre vide", "discussion", "fr");
t.add("error.comment_file_not_pdf", "Seuls les fichiers pdf sont autoris\u00e9s.\n                 Cliquez \u00e0 nouveau sur \"Ajouter un fichier\"", "discussion", "fr");
t.add("error.max_size_reached", "Le fichier fais plus de 80 Mo.\n                Cliquez \u00e0 nouveau sur \"Ajouter un fichier\"", "discussion", "fr");
t.add("see_all_asset_comments", "Voir tous les commentaires de l'actif", "discussion", "fr");
t.add("error.empty_question", "La question ne doit pas \u00eatre vide", "discussion", "fr");
t.add("question_of", "Question num\u00e9ro %prefix%%number% de", "discussion", "fr");
t.add("no_answer", "Personne n'a encore r\u00e9pondu \u00e0 cette question", "discussion", "fr");
t.add("validate_and_save", "Enregistrer et valider", "discussion", "fr");
t.add("no_waiting_questions_for_search", "Il n'y a pas de r\u00e9ponses en attente pour votre recherche", "discussion", "fr");
t.add("no_selected_file", "Aucun fichier s\u00e9lectionn\u00e9", "discussion", "fr");
t.add("comment_number", "Commentaire num\u00e9ro %number%", "discussion", "fr");
t.add("show_comment_for_my_collaborators_group", "Afficher seulement les commentaires de mes groupes", "discussion", "fr");
t.add("show_questions_for_my_collaborators_group", "Afficher seulement les questions de mes groupes", "discussion", "fr");
t.add("STATE_ARCHIVED", "Archiv\u00e9", "discussion", "fr");
t.add("Number", "N\u00b0", "discussion", "fr");
t.add("filter_comment_number", "N\u00b0 du commentaire", "discussion", "fr");
t.add("filter_question_number", "N\u00b0 de la question", "discussion", "fr");
t.add("reset_filter", "R\u00e9initialiser les filtres", "discussion", "fr");
t.add("ask", "Demander", "discussion", "fr");
t.add("foundNodes", "{0} Pas de r\u00e9sultat|{1} %count% lignes contiennent des r\u00e9sultats|]1,Inf[ %count% lignes contiennent des r\u00e9sultats", "discussion", "fr");
t.add("selectBuyerGroup", "Veuillez s\u00e9lectionner un groupe", "discussion", "fr");
t.add("show_all_questions_from_search_context", "Afficher toutes les questions de chaque ligne", "discussion", "fr");
t.add("group_questions_by_node", "Regrouper les questions par ligne", "discussion", "fr");
t.add("foundQuestions", "{0} Pas de r\u00e9sultat|{1} %count% question a \u00e9t\u00e9 trouv\u00e9e|]1,Inf[ %count% questions ont \u00e9t\u00e9 trouv\u00e9es", "discussion", "fr");
t.add("question.total", "questions au total", "discussion", "fr");
t.add("list_comments", "Liste des commentaires", "discussion", "fr");
t.add("read", "Lu", "discussion", "fr");
t.add("unread", "Non lu", "discussion", "fr");
t.add("select_contact_by_name", "S\u00e9lectionnez un contact par son nom", "discussion", "fr");
t.add("list_questions", "Liste des questions", "discussion", "fr");
t.add("validate_answer", "Enregistrer la r\u00e9ponse", "discussion", "fr");
t.add("validate_and_save_answer", "Enregistrer et valider la r\u00e9ponse", "discussion", "fr");
t.add("AnswerNumberMismatchException", "Une r\u00e9ponse a \u00e9t\u00e9 ajout\u00e9e pendant la r\u00e9daction de la votre.", "discussion", "fr");
t.add("AnswerCanNotBeAddedToAQuestionWithAnAnswerNotValidateException", "Une r\u00e9ponse a \u00e9t\u00e9 ajout\u00e9e pendant la r\u00e9daction de la votre.", "discussion", "fr");
t.add("OldAnswerTextMismatchException", "Cette r\u00e9ponse vient d'\u00eatre \u00e9dit\u00e9e.", "discussion", "fr");
t.add("CantEditValidAnswerException", "Cette r\u00e9ponse a d\u00e9ja \u00e9t\u00e9 valid\u00e9e.", "discussion", "fr");
t.add("EmptyAnswerMessageException", "Il est impossible d'ajouter une r\u00e9ponse vide.", "discussion", "fr");
t.add("cannot_send_question", "Vous ne pouvez pas poser de questions", "discussion", "fr");
t.add("question_placeholder", "Votre question", "discussion", "fr");
t.add("question_title", "Poser une question", "discussion", "fr");
t.add("QuestionNumberOnNodeMismatchException", "Une question a \u00e9t\u00e9 ajout\u00e9e pendant la r\u00e9daction de la votre. La liste a \u00e9t\u00e9 recharg\u00e9e.", "discussion", "fr");
t.add("EmptyQuestionMessageException", "Votre r\u00e9ponse ne doit pas \u00eatre vide !", "discussion", "fr");
t.add("cannot_send_comment", "Vous ne pouvez pas poser de commentaires", "discussion", "fr");
t.add("CommentNumberOnNodeMismatchException", "Un commentaire a \u00e9t\u00e9 ajout\u00e9e pendant la r\u00e9daction du votre. La liste a \u00e9t\u00e9 recharg\u00e9e.", "discussion", "fr");
t.add("answer_validated", "R\u00e9ponse valid\u00e9e", "discussion", "fr");
t.add("answer_waiting_validated", "R\u00e9ponse en attente de validation", "discussion", "fr");
t.add("QUESTION_WITH_ANSWERS", "Question avec r\u00e9ponses", "discussion", "fr");
})(Translator);
