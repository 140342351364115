import React, { Fragment } from 'react';
import theme_blue, {
  CLIENT_UI_COLOR,
} from '../../presentational/components/theme_blue';
import Helmet from 'react-helmet';
import Layout from '../../app/layout/Layout';
import { Menu } from '../../app/components/menu/Menu';
import Content from '../../app/layout/Content';
import Div from '../../presentational/components/Div';
import Span from '../../presentational/components/Span';
import P from '../../presentational/components/P';
import RequestResetPasswordForm from '../components/login/RequestResetPasswordForm';
import Notifications from '../../dataroom/app/containers/Notifications';

export default function RequestResetPassword(props) {
  return (
    <Fragment>
      <Helmet>
        <title>Espace client - Récupération de mot de passe</title>
      </Helmet>

      <Layout>
        <Menu onlyLogo />

        <Content>
          <P
            marginTop="2em"
            marginBottom="2em"
            textAlign="center"
            fontWeight="bold"
          >
            <Span color={CLIENT_UI_COLOR}>Récupération de mot de passe</Span>
          </P>
          <Div maxWidth="25em" margin="0 auto" fontWeight="300">
            <RequestResetPasswordForm />
          </Div>
        </Content>

        <Notifications />
      </Layout>
    </Fragment>
  );
}
