import styled from 'styled-components';
const Ul = styled.ul`
  ${props => (props.display ? 'display: ' + props.display + ';' : null)}
  ${props => (props.width ? 'width: ' + props.width + ';' : null)}
  ${props => (props.padding ? 'padding: ' + props.padding + ';' : null)}
  ${props => (props.margin ? 'margin: ' + props.margin + ';' : null)}
  ${props => (props.fontSize ? 'font-size : ' + props.fontSize + ';' : null)}
  ${props =>
    props.listStyleType
      ? 'list-style-type : ' + props.listStyleType + ';'
      : null}
  @media (min-width: ${props => props.theme.tablet}) {
    ${props => (props.twoCols ? 'columns: 2; column-gap: 2em;' : null)}
  }
`;
export default Ul;
