import React, { Component } from 'react';
import copy from 'copy-to-clipboard';
import { Overlay, Tooltip } from 'react-bootstrap';
import ReactDOM from 'react-dom';

class AnonymousButton extends Component {
  constructor(props) {
    super(props);
    this.copyAnonymousLink = this.copyAnonymousLink.bind(this);
    this.state = {
      showLinkCopiedTooltip: false,
    };
  }

  copyAnonymousLink() {
    const anonymousLink = `${window.location.href}/fullscreen`;
    copy(anonymousLink);
    this.setState({ showLinkCopiedTooltip: true });

    setTimeout(() => {
      this.setState({ showLinkCopiedTooltip: false });
    }, 1000);
  }

  render() {
    const anonymousLink = `${window.location.href}/fullscreen`;

    return (
      <div
        className="btn-group btn-group-justified"
        role="group"
        aria-label="Default button group"
        style={{ marginTop: '4px' }}
      >
        {' '}
        <a
          target="_blank"
          href={anonymousLink}
          style={{ width: '75%' }}
          className="btn btn-primary"
        >
          {' '}
          <i className="fa fa-eye-slash" /> Voir la version anonyme
        </a>{' '}
        <a
          onClick={this.copyAnonymousLink}
          style={{ width: '25%' }}
          className="btn btn-warning"
        >
          {' '}
          <i className="fa fa-clipboard" />{' '}
          <span className="sr-only"> Copier </span>
        </a>
        {this.state.showLinkCopiedTooltip && (
          <div>
            <Tooltip placement="right" className="in" id="tooltip-right">
              Lien copié
            </Tooltip>
          </div>
        )}{' '}
      </div>
    );
  }
}

export default AnonymousButton;
