import PropTypes from 'prop-types';
import React, { Component } from 'react';
import 'translations/edm-dataroom/fr';
import 'translations/edm-dataroom/en';
import { makeShortenedNodeForDocumentDetailSelector } from '../../dataroom/selectors/shortenedNodeNameSelector';

class Title extends Component {
  constructor(props) {
    super(props);
    this.handleDocumentClose = this.handleDocumentClose.bind(this);
    this.shortenedNodeName = makeShortenedNodeForDocumentDetailSelector();
  }

  render() {
    const { document } = this.props;
    let size = 0;
    if (document.file) {
      size = document.file.size / 1024 / 1024;
    }

    let documentName = this.shortenedNodeName(document);

    return (
      <span>
        <div className="pull-right">
          <a
            href="#"
            className="close-page--button"
            onClick={this.props.closeDocumentAction}
          >
            {' '}
            <span className="s7-close" aria-hidden="true" />{' '}
            <span className="sr-only"> {Translator.trans('close_page')} </span>{' '}
          </a>
        </div>
        <h1 className="doc-title">
          {document.path} {documentName}{' '}
        </h1>
        {document.file && (
          <div className="row other-infos">
            <div className="col-sm-12">
              {' '}
              {size.toFixed(2)}{' '}
              {Translator.trans('mega_bytes', {}, 'edm-dataroom')} -{' '}
              {Translator.trans('last_updated_at', {}, 'edm-dataroom')}
              &nbsp;: {this.renderDate(document.file.editedAt)}
            </div>
          </div>
        )}
      </span>
    );
  }

  handleDocumentClose(evt) {
    evt.preventDefault();
    this.props.closeDocumentAction();
  }

  renderDate(date) {
    if (!date) {
      return null;
    }

    return date.toLocaleString(document.documentElement.lang);

    let month = date.getMonth() + 1;
    if (month < 10) {
      month = '0' + month;
    }

    return date.getDate() + '/' + month + '/' + date.getFullYear();
  }
}

Title.propTypes = {
  document: PropTypes.object.isRequired,
  closeDocumentAction: PropTypes.func.isRequired,
};

export default Title;
