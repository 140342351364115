import {
  FOLD,
  FOLD_ALL,
  UNFOLD_RECURSIVE,
  FOLD_RECURSIVE,
} from '../../actions/dataroom.js';
import DataroomStateRepository from '../../repository/DataroomStateRepository';
import childNode from '../../ducks/utils/childNode';
import { TYPE_DOCUMENT } from '../../documents/type';

function getParentIds(nodes, nodeId, parentIds = []) {
  const parentNode = nodes[nodeId];
  parentIds.push(parentNode.id);

  if (parentNode.parentId) {
    return getParentIds(nodes, parentNode.parentId, parentIds);
  }

  return parentIds;
}

export function changeNodesFoldState(nodes, nodeIds, isFold) {
  let newNodes = {};

  nodeIds.forEach(nodeId => {
    if (
      nodes[nodeId].type === TYPE_DOCUMENT ||
      nodes[nodeId].folded === isFold
    ) {
      return;
    }

    newNodes[nodeId] = {
      ...nodes[nodeId],
      folded: isFold,
    };
  });

  return {
    ...nodes,
    ...newNodes,
  };
}

function saveFoldState(dataroomId, nodeIds, isFold) {
  let dataroomStateRepository = new DataroomStateRepository(dataroomId);

  dataroomStateRepository.setNodesFoldStatus(nodeIds, isFold);
}

export function getIdsChangeAllChildrenNodesFoldState(
  nodes,
  nodeId,
  includeParent = true
) {
  const nodeIds = [];

  if (includeParent) {
    nodeIds.push(nodeId);
  }

  for (const node of childNode(nodes, nodeId)) {
    nodeIds.push(node.id);
  }

  return nodeIds;
}

export function changeAllChildrenNodesFoldState(action, state) {
  const nodeIds = getIdsChangeAllChildrenNodesFoldState(
    state.working.nodes,
    action.nodeId
  );

  saveFoldState(state.dataroom.id, nodeIds, action.type === FOLD_RECURSIVE);

  return {
    ...state,
    working: {
      ...state.working,
      nodes: changeNodesFoldState(
        state.working.nodes,
        nodeIds,
        action.type === FOLD_RECURSIVE
      ),
    },
  };
}

// change fold status of node and parent node
export function unfoldParentNodes(nodes, dataroomId, nodeId) {
  const parentIds = getParentIds(nodes, nodeId);

  saveFoldState(dataroomId, parentIds, false);

  return changeNodesFoldState(nodes, parentIds, false);
}

// change fold status for all nodes
export function changeAllNodesFoldState(action, state) {
  let nodeIds = getIdsChangeAllChildrenNodesFoldState(
    state.working.nodes,
    state.working.rootNodeId,
    false
  );

  saveFoldState(state.dataroom.id, nodeIds, action.type === FOLD_ALL);

  return {
    ...state,
    working: {
      ...state.working,
      nodes: changeNodesFoldState(
        state.working.nodes,
        nodeIds,
        action.type === FOLD_ALL
      ),
    },
  };
}

// change fold status for one node
export function changeNodeFoldState(action, state) {
  saveFoldState(state.dataroom.id, [action.nodeId], action.type === FOLD);

  return {
    ...state,
    working: {
      ...state.working,
      nodes: changeNodesFoldState(
        state.working.nodes,
        [action.nodeId],
        action.type === FOLD
      ),
    },
  };
}
