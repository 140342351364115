import 'font-awesome/css/font-awesome.css';
import 'lib/stroke-7/style.css';
import 'nanoscroller/bin/css/nanoscroller.css';
import 'select2/dist/css/select2.css';
import 'css/wk.less';
import 'css/report-sentry-error.css';
import 'css/custom.css';
import 'css/buyerGroupDetails.css';

if (module.hot) {
  module.hot.accept();
}
