import { useEffect, useMemo, useReducer, useRef } from 'react';

export const UPDATE_PAGE = 'UPDATE_PAGE';
export const UPDATE_SEARCH = 'UPDATE_SEARCH';
export const UPDATE_ORDER = 'UPDATE_ORDER';
export const DELETE_ORDER = 'DELETE_ORDER';

function searchReducer(state, action) {
  let newFilterValue = action.value;
  if (action.filterType) {
    newFilterValue = {
      value: action.value,
      filterType: action.filterType,
    };
  }

  switch (action.type) {
    case UPDATE_SEARCH:
      return {
        ...state,
        page: 1,
        filters: {
          ...state.filters,
          [action.field]: newFilterValue,
        },
      };
    case UPDATE_ORDER: {
      return {
        ...state,
        order: {
          field: action.field,
          direction: action.direction,
        },
      };
    }
    case DELETE_ORDER: {
      return {
        ...state,
        order: {},
      };
    }
    case UPDATE_PAGE: {
      return {
        ...state,
        page: action.page,
      };
    }
  }

  return state;
}

export function useSearchReducer(initialSearch) {
  const [search, dispatch] = useReducer(searchReducer, initialSearch);
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    window.scrollTo({
      top: '0',
      behavior: 'instant',
    });
  }, [search.page, isFirstRender]);

  return [search, dispatch];
}

export const baseSearch = {
  filters: {},
  order: {},
  page: 1,
  items: 20,
};
