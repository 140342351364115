import React from 'react';
import Svg from './Svg';

export default function IconChevronTopHeavy(props) {
  return (
    <Svg
      {...props}
      d="M128 92.039l-97.902 108.78L12.8 183.181l115.2-128 115.2 128-17.298 17.638L128 92.039z"
    />
  );
}
