import clipBoardRepository from '../repository/ClipBoardRepository';
import { eventChannel } from 'redux-saga';
import { take, call, put } from 'redux-saga/effects';

export const CHANGE_NODE_CLIPBOARD = 'CHANGE_NODE_CLIPBOARD';

export const PASTE_NODE = 'PASTE_NODE';

export const TYPE_COPY = 'TYPE_COPY';
export const TYPE_CUT = 'TYPE_CUT';

function createClipBoardChannel() {
  return eventChannel(emit => {
    clipBoardRepository.subscribe(newClipboardState => emit(newClipboardState));

    return () => {};
  });
}

function changeClipboardAction(type, nodeIds, dataroomId) {
  return {
    type: CHANGE_NODE_CLIPBOARD,
    clipboardType: type,
    nodeIds,
    dataroomId,
  };
}

export function* watchOnNodeClipBoard() {
  const clipBoardChannel = yield call(createClipBoardChannel);

  while (true) {
    const newClipboardState = yield take(clipBoardChannel);
    yield put(
      changeClipboardAction(
        newClipboardState.type,
        newClipboardState.nodeIds,
        newClipboardState.dataroomId
      )
    );
  }
}

export function changeClipboard(type, nodeIds, dataroomId) {
  clipBoardRepository.setClipBoard(type, nodeIds, dataroomId);

  return changeClipboardAction(type, nodeIds, dataroomId);
}

export function pasteNode(destinationId, position, clipBoard, withFile) {
  return {
    type: PASTE_NODE,
    destinationId,
    position,
    clipBoard,
    withFile,
  };
}

export default function(state, action) {
  switch (action.type) {
    case CHANGE_NODE_CLIPBOARD:
      return {
        ...state,
        working: {
          ...state.working,
          nodeClipBoard: {
            nodeIds: action.nodeIds,
            type: action.clipboardType,
            dataroomId: action.dataroomId,
          },
        },
      };
  }

  return state;
}
