import PropTypes from 'prop-types';
import React, { Component } from 'react';

class Loading extends Component {
  render() {
    let className = 'loading-full-screen';

    if (this.props.isLoading) {
      className += ' visible';
    }

    return (
      <div className={className}>
        <div className="box">
          <div className="progress">
            <div
              style={{ width: '100%' }}
              className="progress-bar progress-bar-danger progress-bar-striped active"
            />
          </div>
        </div>
      </div>
    );
  }
}

Loading.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

export default Loading;
