import { LOAD_DATAROOM, REFRESH_NODES } from '../actions/dataroom';
import io from 'socket.io-client';
import { eventChannel } from 'redux-saga';
import {
  take,
  put,
  call,
  select,
  all,
  spawn,
  fork,
  cancelled,
} from 'redux-saga/effects';

import tokenRepository from '../../core/tokenRepository';
import uuidv4 from 'uuid/v4';
import apiClient from 'js/dataroom/core/apiClient';
import queryString from 'qs';
import { addNotificationAction } from '../../app/actions/notifications';

export const LOCKED = 'DATAROOM_LOCKED';
export const UNLOCKED = 'DATAROOM_UNLOCKED';

export const LOCK = 'DATAROOM_LOCK';
export const LOCK_SUCCESS = 'DATAROOM_LOCK_SUCCESS';
export const LOCK_ERROR = 'DATAROOM_LOCK_ERROR';
export const UNLOCK = 'DATAROOM_UNLOCK';
export const UNLOCK_SUCCESS = 'DATAROOM_UNLOCK_SUCCESS';
export const UNLOCK_ERROR = 'DATAROOM_UNLOCK_ERROR';

export const CANT_LOCK_NOT_MANIPULABLE_DATAROOM =
  'CANT_LOCK_NOT_MANIPULABLE_DATAROOM';

export const lockDataroom = user => ({ type: LOCK, user: user });
export const unLockDataroom = () => ({ type: UNLOCK });

function crateSocketChannel(dataroomId, socket) {
  return eventChannel(emit => {
    socket.emit('join-dataroom-room', dataroomId);

    socket.on('locked', lockBy => {
      emit({
        type: LOCKED,
        lockBy,
      });
    });

    socket.on('unlocked', () => {
      emit({
        type: UNLOCKED,
      });
    });

    return () => socket.close();
  });
}

function* getDataroomIdFromAction(action) {
  if (action.type === LOAD_DATAROOM) {
    return action.dataroom.id;
  }

  return yield select(state => state.dataroom.dataroom.id);
}

// todo refactor because fake user
const lockDataroomApi = dataroomId =>
  apiClient
    .request(
      new Request(`/api/datarooms/${dataroomId}/lock`, {
        method: 'POST',
      })
    )
    .then(response => {
      if (!response.ok) {
        throw new Error();
      }
    });

// todo refactor because fake user
const unlockDataroomApi = dataroomId =>
  apiClient
    .request(
      new Request(`/api/datarooms/${dataroomId}/lock`, {
        method: 'DELETE',
      })
    )
    .then(response => {
      if (!response.ok) {
        throw new Error();
      }
    });

function* lockSaga(dataroomId, socket) {
  while (true) {
    const action = yield take(LOCK);

    const manipulable = yield select(
      state => state.dataroom.dataroom.manipulable
    );

    if (!manipulable) {
      yield put({
        type: CANT_LOCK_NOT_MANIPULABLE_DATAROOM,
      });
      continue;
    }

    try {
      yield call(lockDataroomApi, dataroomId);
    } catch (e) {
      yield put({ type: LOCK_ERROR });
      continue;
    }

    yield put({ type: LOCK_SUCCESS, lockBy: action.user });

    socket.emit('locked', dataroomId, action.user);
  }
}

const getNodes = dataroomId =>
  apiClient
    .request(
      new Request(
        `/api/nodes?${queryString.stringify({
          items: '',
          searchOn: { dataroom: dataroomId },
        })}`
      )
    )
    .then(response => response.json())
    .then(nodesResult => nodesResult.nodes);

function* unlockSaga(dataroomId, socket) {
  while (true) {
    yield take(UNLOCK);

    const [preparingUpload, uploads, dataroomActions] = yield select(state => [
      state.dataroom.working.preparingUpload,
      state.dataroom.working.uploads,
      state.dataroomActions,
    ]);

    if (
      (preparingUpload && preparingUpload === true) ||
      (uploads.length !== 0 && uploads[0].uploadingDocuments !== 0) ||
      dataroomActions.currentAction !== null ||
      dataroomActions.pendingActions.length !== 0 ||
      dataroomActions.preparingActions.length !== 0
    ) {
      yield put(
        addNotificationAction(
          'Impossible de dévérouiller la Dataroom durant un import ou une action'
        )
      );
      yield put({ type: UNLOCK_ERROR });
      continue;
    } else {
      try {
        yield call(unlockDataroomApi, dataroomId);
      } catch (e) {
        yield put({ type: UNLOCK_ERROR });
        continue;
      }
    }

    const [lockedByMe, workingNodesLoaded] = yield select(state => [
      state.dataroom.working.lock.lockedByMe,
      state.dataroom.working.loaded,
    ]);

    if (!lockedByMe && workingNodesLoaded) {
      const nodes = yield call(getNodes, dataroomId);
      yield put({
        type: REFRESH_NODES,
        nodes,
      });
    }

    yield put({ type: UNLOCK_SUCCESS });
    socket.emit('unlocked', dataroomId);
  }
}

function* unlockedSaga(dataroomId) {
  while (true) {
    yield take(UNLOCKED);
    const [workingNodesLoaded] = yield select(state => [
      state.dataroom.working.loaded,
    ]);

    if (workingNodesLoaded) {
      const nodes = yield call(getNodes, dataroomId);
      yield put({
        type: REFRESH_NODES,
        nodes,
      });
    }
  }
}

export function* saga() {
  const action = yield take(action => action.type === LOAD_DATAROOM);

  const socket = io('/datarooms', {
    path: '/socket.io',
    query: {
      clientId: uuidv4(),
    },
  });
  const dataroomId = yield call(getDataroomIdFromAction, action);

  yield fork(lockSaga, dataroomId, socket);
  yield fork(unlockSaga, dataroomId, socket);
  yield fork(unlockedSaga, dataroomId);

  try {
    const socketChannel = yield call(crateSocketChannel, dataroomId, socket);

    while (true) {
      const action = yield take(socketChannel);
      yield put(action);
    }
  } finally {
    if (yield cancelled()) {
      socket.close();
    }
  }
}

export default function(state, action) {
  switch (action.type) {
    case LOCK:
      return {
        ...state,
        working: {
          ...state.working,
          lock: {
            ...state.working.lock,
            locking: true,
          },
        },
      };
    case LOCK_SUCCESS:
      return {
        ...state,
        working: {
          ...state.working,
          lock: {
            ...state.working.lock,
            lockBy: action.lockBy,
            locking: false,
            lockedByMe: true,
          },
        },
      };
    case LOCK_ERROR:
    case CANT_LOCK_NOT_MANIPULABLE_DATAROOM:
      return {
        ...state,
        working: {
          ...state.working,
          lock: {
            ...state.working.lock,
            locking: false,
          },
        },
      };
    case UNLOCK:
      return {
        ...state,
        working: {
          ...state.working,
          lock: {
            ...state.working.lock,
            unlocking: true,
          },
        },
      };
    case UNLOCK_SUCCESS:
    case UNLOCKED:
      return {
        ...state,
        working: {
          ...state.working,
          lock: {
            ...state.working.lock,
            unlocking: false,
            lockedByMe: false,
            lockBy: null,
          },
        },
      };
    case UNLOCK_ERROR:
      return {
        ...state,
        working: {
          ...state.working,
          lock: {
            ...state.working.lock,
            unlocking: false,
          },
        },
      };

    case LOCKED:
      return {
        ...state,
        working: {
          ...state.working,
          lock: {
            ...state.working.lock,
            lockBy: action.lockBy,
            locking: false,
            lockedByMe: false,
          },
        },
      };
  }

  return state;
}
