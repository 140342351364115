import {
  addPickedObject,
  removePickedObject,
  resetPickedObject,
} from '../../dataroom/utils/pickObject';

const PICK_PDF = 'EDITOR_PICK_PDF';
const ADD_PDF_BETWEEN_PICKED = 'EDITOR_ADD_PDF_BETWEEN_PICKED';
const ADD_PDF_TO_PICKED = 'EDITOR_ADD_PDF_TO_PICKED';
const REMOVE_PDF_TO_PICKED = 'EDITOR_REMOVE_PDF_TO_PICKED';
const RESET_PICK_PDF = 'EDITOR_RESET_PICK_PDF';
export const REMOVE_PAGE = 'EDITOR_REMOVE_PAGE';
const PDF_REVERT_LAST_CHANGE = 'EDITOR_PDF_REVERT_LAST_CHANGE';

export const REMOVE_PAGE_NUMBER = 'EDITOR_REMOVE_PAGE_NUMBER';

export const pickPdfAction = pdfNumber => ({
  type: PICK_PDF,
  pageNumber: pdfNumber,
});

export const removePageAction = () => ({
  type: REMOVE_PAGE,
});

export const revertLastAction = () => ({
  type: PDF_REVERT_LAST_CHANGE,
});

export const addPdfBetweenPickedAction = pdfNumber => ({
  type: ADD_PDF_BETWEEN_PICKED,
  pageNumber: pdfNumber,
});

export const addPdfToPickedAction = pdfNumber => ({
  type: ADD_PDF_TO_PICKED,
  pageNumber: pdfNumber,
});

export const removePdfToPickedAction = pdfNumber => ({
  type: REMOVE_PDF_TO_PICKED,
  pageNumber: pdfNumber,
});

export const resetPickPdfAction = () => ({
  type: RESET_PICK_PDF,
});

const revertPage = state => {
  let pages = state.pages.map((page, index) => {
    return {
      ...state.pages[index],
      picked: false,
      dragging: false,
      multipleDrag: false,
    };
  });

  return {
    ...state,
    pages: pages,
  };
};

export default function reducer(state, action) {
  switch (action.type) {
    case PDF_REVERT_LAST_CHANGE: {
      if (state.prevPages.length === 0) {
        return {
          ...state,
        };
      }

      let newState = {
        ...state,
        prevPages: state.prevPages.slice(0, state.prevPages.length - 1),
        pages: state.prevPages[state.prevPages.length - 1],
        pickedPageNumbers: [],
      };

      return revertPage(newState);
    }
    case REMOVE_PAGE:
    case REMOVE_PAGE_NUMBER: {
      const prevPages = state.pages;

      let newState = {
        ...state,
        pages: [...state.pages],
      };
      let pageNumberToRemove = [];
      if (action.type === REMOVE_PAGE) {
        pageNumberToRemove = state.pickedPageNumbers;
        newState = resetPickedObject(
          newState,
          'pages',
          'pickedPageNumbers',
          'pageNumber'
        );
      } else {
        pageNumberToRemove = [action.pageNumber];
      }

      return {
        ...newState,
        pages: newState.pages.filter(
          page => !pageNumberToRemove.includes(page.pageNumber)
        ),
        prevPages: [...state.prevPages, prevPages],
      };
    }
    case ADD_PDF_BETWEEN_PICKED: {
      const pickedPageIndex = state.pages.findIndex(
        page => page.pageNumber === action.pageNumber
      );

      const selectedPageNumber =
        state.pickedPageNumbers[state.pickedPageNumbers.length - 1];
      const selectedPageIndex = state.pages.findIndex(
        page => page.pageNumber === selectedPageNumber
      );

      const begin =
        pickedPageIndex < selectedPageIndex
          ? pickedPageIndex
          : selectedPageIndex;
      const end =
        pickedPageIndex > selectedPageIndex
          ? pickedPageIndex
          : selectedPageIndex;

      const pagesToPick = state.pages.slice(begin, end + 1);

      let newState = { ...state };

      for (let pageToPick of pagesToPick) {
        newState = addPickedObject(
          newState,
          { pageNumber: pageToPick.pageNumber },
          'pages',
          'pageNumber',
          'pickedPageNumbers'
        );
      }

      return newState;
    }
    case RESET_PICK_PDF:
    case PICK_PDF: {
      if (!state.loaded) {
        return {
          ...state,
        };
      }

      let newState = {
        ...state,
        pages: [...state.pages],
      };
      newState = resetPickedObject(
        newState,
        'pages',
        'pickedPageNumbers',
        'pageNumber'
      );

      if (action.type === RESET_PICK_PDF) {
        return newState;
      }

      return addPickedObject(
        newState,
        action,
        'pages',
        'pageNumber',
        'pickedPageNumbers'
      );
    }
    case ADD_PDF_TO_PICKED: {
      return addPickedObject(
        state,
        action,
        'pages',
        'pageNumber',
        'pickedPageNumbers'
      );
    }
    case REMOVE_PDF_TO_PICKED: {
      return {
        ...state,
        pickedPageNumbers: state.pickedPageNumbers.filter(
          pageNumber => pageNumber !== action.pageNumber
        ),
        pages: removePickedObject(state, action, 'pages', 'pageNumber'),
      };
    }
  }

  return state;
}
