import React, { Component } from 'react';
import FormControl from 'react-bootstrap/lib/FormControl';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import { mapObject, values, memoize } from 'underscore';
import 'translations/messages/fr';
import 'translations/messages/en';
import actions from 'redux-form/lib/actions';
import './Input.css';

export const TYPE_INT = 'int';
export const TYPE_TEXT = 'text';
export const TYPE_CHECKBOX = 'checkbox';
export const TYPE_CHOICE = 'choice';
export const TYPE_DATE = 'date';
export const TYPE_TEXTAREA = 'textarea';
export const TYPE_TAGS = 'tags';
export const TYPE_RADIO = 'radio';
export const TYPE_GEOSUGGEST = 'geosuggest';
export const TYPE_MAIL = 'mail';
export const TYPE_REACT_SELECT = 'react-select';

class Input extends Component {
  constructor(props) {
    super(props);
    this.getFieldComponent = this.getFieldComponent.bind(this);
    this.onBlurWithWarning = this.onBlurWithWarning.bind(this);
    this.applyWarnings = this.applyWarnings.bind(this);
    this.state = {
      loadingAsyncWarning: false,
    };
  }

  getFieldComponent() {
    const {
      input,
      fieldType,
      label,
      inputProps,
      choices,
      asyncWarning,
      meta,
      placeholder,
      id,
      inputAddon,
      readOnly,
    } = this.props;
    let formControlChildren = null;

    const props = {
      id: input.name,
      name: input.name,
      placeholder: placeholder ? placeholder : label,
      type: fieldType === TYPE_INT ? 'number' : 'text',
      value: input.value || '',
      onBlur: input.onBlur,
      onChange: input.onChange,
      onFocus: input.onFocus,
      readOnly,
    };

    if (fieldType === TYPE_MAIL) {
      props.type = 'mail';
    }

    if (props.type === 'text') {
      props.maxLength = '255';
    }

    if (asyncWarning) {
      props.onBlur = this.onBlurWithWarning;
    }

    if (fieldType === TYPE_CHOICE) {
      props.componentClass = 'select';
      formControlChildren = values(
        mapObject(choices, (label, value) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))
      );
    }

    const inputComponent = (
      <FormControl
        inputRef={ref => {
          this._input = ref;
        }}
        {...props}
      >
        {formControlChildren}
      </FormControl>
    );

    if (inputAddon) {
      return (
        <div className="input-group">
          {inputComponent}
          <div className="input-group-addon">{inputAddon}</div>
        </div>
      );
    }

    return inputComponent;
  }

  applyWarnings() {
    this.props
      .asyncWarning(this.props.input.value)
      .then(() => {
        if (this.props.meta.warning && this.props.meta.warning.length > 0) {
          this.props.meta.dispatch(
            actions.updateSyncWarnings(this.props.meta.form, {
              [this.props.input.name]: [],
            })
          );
        }
      })
      .catch(error => {
        this.props.meta.dispatch(
          actions.updateSyncWarnings(this.props.meta.form, {
            [this.props.input.name]: error.errors,
          })
        );
      })
      .then(() => {
        this.setState({
          loadingAsyncWarning: false,
        });
      });
  }

  onBlurWithWarning(evt) {
    if (this.props.input.onBlur) {
      this.props.input.onBlur(evt);
    }

    this.setState({
      loadingAsyncWarning: true,
    });

    if (this.applyWarningsTimer) {
      clearTimeout(this.applyWarningsTimer);
    }

    this.applyWarningsTimer = setTimeout(this.applyWarnings, 200);
  }

  render() {
    const {
      label,
      meta,
      input,
      required,
      multiline,
      size,
      fieldType,
    } = this.props;
    const { loadingAsyncWarning } = this.state;

    let divComponentClass = '';
    if (!multiline) {
      divComponentClass = `${divComponentClass} col-sm-${size ? size : 6}`;
    }
    if (loadingAsyncWarning) {
      divComponentClass = `${divComponentClass} input-loading`;
    }
    if (meta.autofilled) {
      divComponentClass = `${divComponentClass} input-autofilled`;
    }

    return (
      <div className={`${label ? 'form-group' : 'row'}`}>
        {label && (
          <ControlLabel
            className={multiline ? '' : 'col-sm-3'}
            htmlFor={input.name}
          >
            {label} {required ? '*' : ''}
            {fieldType === TYPE_TAGS && (
              <span>
                <br />
                <i style={{ fontSize: 'small', color: 'red' }}>
                  {Translator.trans('separateReference')}
                </i>
              </span>
            )}
            <br />
          </ControlLabel>
        )}
        <div className={divComponentClass}>{this.getFieldComponent()}</div>
      </div>
    );
  }
}

export default Input;
