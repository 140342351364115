import { createSelector } from 'reselect';
import getNextPath, { INSIDE, AFTER, BEFORE } from '../ducks/utils/getNextPath';
import getParentPathUtil from '../ducks/utils/getParentPath';

export const getPathAfter = createSelector([node => node.path], nodePath =>
  getNextPath(nodePath, AFTER)
);

export const getPathInside = createSelector([node => node.path], nodePath =>
  getNextPath(nodePath, INSIDE)
);

export const getPathBefore = createSelector([node => node.path], nodePath =>
  getNextPath(nodePath, BEFORE)
);

export const getParentPath = createSelector([node => node.path], nodePath =>
  getParentPathUtil(nodePath)
);
