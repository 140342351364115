import React, { Fragment, useState } from 'react';
import { partial } from 'underscore';
import PaginationItem from '../../presentational/components/PaginationItem';
import Pagination from '../../presentational/components/Pagination';

const Page = props => (
  <PaginationItem
    href="#"
    data-page={props.page}
    active={props.page === props.currentPage ? 'active' : ''}
    onClick={props.changePage}
  >
    {props.page}
  </PaginationItem>
);

const displayPage = (page, currentPage, changePage) => (
  <Page
    key={page}
    page={page}
    currentPage={currentPage}
    changePage={partial(changePage, page)}
  />
);

const displayPagesNumber = (pages, currentPage, changePage) => {
  let elements = [];

  if (pages < 8) {
    for (let i = 0; i < pages; i++) {
      elements.push(displayPage(i + 1, currentPage, changePage));
    }

    return elements;
  }

  let previousPage = currentPage - 1;
  let nextPage = currentPage + 1;

  let hasPrevious = currentPage !== 1;
  let hasNext = currentPage !== pages;

  elements.push(displayPage(1, currentPage, changePage));

  if (previousPage > 2) {
    elements.push(
      <PaginationItem
        key="prev-dot"
        href="#"
        disabled
        onClick={evt => evt.preventDefault()}
      >
        ...
      </PaginationItem>
    );
  }

  if (hasPrevious && previousPage !== 1) {
    elements.push(displayPage(previousPage, currentPage, changePage));
  }

  if (currentPage !== 1 && currentPage !== pages) {
    elements.push(displayPage(currentPage, currentPage, changePage));
  }

  if (hasNext && nextPage !== pages) {
    elements.push(displayPage(nextPage, currentPage, changePage));
  }

  if (nextPage < pages) {
    elements.push(
      <PaginationItem
        key="last-dot"
        href="#"
        disabled
        onClick={evt => evt.preventDefault()}
      >
        ...
      </PaginationItem>
    );
  }

  elements.push(displayPage(pages, currentPage, changePage));

  return elements;
};

export function Paginator(props) {
  const { totalItems, itemsPerPage, currentPage } = props;
  let pages = 1;
  let hasPrevious = false;
  let hasNext = false;

  if (totalItems > 0) {
    pages = Math.ceil(totalItems / itemsPerPage);
    hasPrevious = currentPage !== 1;
    hasNext = currentPage !== pages;
  }

  const changePage = (page, evt) => {
    evt.preventDefault();
    props.changePage(page);
  };

  return (
    <Pagination textAlign="right">
      <PaginationItem
        href="#"
        disabled={!hasPrevious ? 'disabled' : ''}
        onClick={
          hasPrevious
            ? partial(changePage, currentPage - 1)
            : evt => evt.preventDefault()
        }
      >
        «
      </PaginationItem>

      {displayPagesNumber(pages, currentPage, changePage)}

      <PaginationItem
        href="#"
        disabled={!hasNext ? 'disabled' : ''}
        onClick={
          hasNext
            ? partial(changePage, currentPage + 1)
            : evt => evt.preventDefault()
        }
      >
        »
      </PaginationItem>
    </Pagination>
  );
}
