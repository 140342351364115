import styled from 'styled-components';
import { LIGHT_BORDER_COLOR } from '../../theme_blue';

export default styled.input`
  border-width: 2px;
  padding: 10px 12px;
  font-size: 14px;
  height: 44px;
  background-color: #fff;
  border-color: ${props => props.theme[LIGHT_BORDER_COLOR]};
  border-style: solid;
  line-height: 1.42857143;
  border-radius: 0;
  display: ${props => (props.display ? props.display : 'inline-block')};
  ${props => (props.flexGrow ? 'flex-grow: 1' : null)};
  ${props => (props.width ? 'width: 50%' : null)};
`;
