import React, { Fragment, useState } from 'react';
import theme_blue, {
  BODY_FONT,
  CLIENT_UI_COLOR,
  DARK_GREY_COLOR,
  LIGHT_COLOR,
  LIGHT_GREY_COLOR,
  MID_GREY_COLOR,
} from '../../../presentational/components/theme_blue';
import Button from '../../../presentational/components/Button';
import P from '../../../presentational/components/P';
import Form from '../../../presentational/components/form/Form';
import Alert from '../../../presentational/components/Alert';
import IconSpam from '../../../presentational/components/img/icons/IconSpam';
import { useForm } from 'react-hook-form';
import Input from '../../../presentational/components/form/Input';

export default function LoginForm(props) {
  const [hasLoginError, setHasLoginError] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm({
    criteriaMode: 'all',
    defaultValues: {
      user: null,
      password: null,
    },
  });

  const onSubmit = async data => {
    const response = await fetch('/api/login', {
      method: 'POST',
      body: JSON.stringify({
        email: data.user?.trim(),
        password: data.password?.trim(),
      }),
    });

    if (response.ok) {
      setHasLoginError(false);
      const user = await response.json();

      props.onLoginSuccess(user);
      return;
    }

    setHasLoginError(true);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Input
        {...register('user', { required: 'Le mail est obligatoire' })}
        type="text"
        label="Email"
        isRequired
        displayBlock
        isHorizontal
        isLabelBold
        errors={errors.user?.types}
      />
      <Input
        {...register('password', {
          required: 'Le mot de passe est obligatoire',
        })}
        type="password"
        label="Mot de passe"
        isRequired
        displayBlock
        isLabelBold
        isHorizontal
        errors={errors.password?.types}
      />
      <Button
        type="submit"
        displayBlock
        disabled={isSubmitting}
        backgroundColor={CLIENT_UI_COLOR}
        borderColor={CLIENT_UI_COLOR}
        backgroundHoverColor={LIGHT_COLOR}
      >
        Connexion
      </Button>
      {hasLoginError && (
        <Alert alertType="danger" role="alert">
          <Alert.ContentWithOrWithoutIcon>
            <P marginTop="1rem" marginBottom="0">
              <IconSpam marginRight="1em" width="1.5em" marginTop="-.125em" />
            </P>
            <Alert.ContentText>
              <P marginTop="1rem" marginBottom="1rem">
                Identifiant ou mot de passe incorrect.
              </P>
            </Alert.ContentText>
          </Alert.ContentWithOrWithoutIcon>
        </Alert>
      )}
    </Form>
  );
}
