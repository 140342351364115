import { DragSource, DropTarget } from 'react-dnd';
import React, { Component, Fragment } from 'react';
import { findDOMNode } from 'react-dom';
import PdfCanvas from './PdfCanvas';
import { movePageAction, rotatePdfAction } from '../ducks/editPage';
import {
  addPdfBetweenPickedAction,
  addPdfToPickedAction,
  pickPdfAction,
  removePageAction,
  removePdfToPickedAction,
} from '../ducks/pickPage';
import { beginDragAction, endDragAction } from '../ducks/dragDropPage';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import {
  DANGER_COLOR,
  DANGER_COLOR_HOVER,
  DARK_COLOR,
  PRIMARY_COLOR,
  PRIMARY_COLOR_HOVER,
} from '../../../presentational/components/theme_blue';
import { throttle } from 'underscore';
import DropArea from '../../../presentational/components/editor/DropArea';
import PagePlaceholder from '../../../presentational/components/editor/PagePlaceholder';
import CommandButtonsContainer from '../../../presentational/components/editor/CommandButtonsContainer';
import {
  LIGHT_COLOR,
  SECONDARY_COLOR,
  SECONDARY_LIGHT_COLOR,
} from '../../../presentational/components/theme_blue';
import Button from '../../../presentational/components/Button';
import PageNumber from '../../../presentational/components/editor/PageNumber';
import Div from '../../../presentational/components/Div';
import IconReload from '../../../presentational/components/img/icons/IconReload';
import IconTrash from '../../../presentational/components/img/icons/IconTrash';
import { FILE } from '../../lib/react-dnd-html5-backend/src/NativeTypes';
import {
  AUTHORIZED_MIME_TYPE,
  maxUploadFileAction,
  processFilesAction,
} from '../reducers/pdfEditor';

const TYPE_PAGE = 'page';
let image = null;

const generateMultipleDragAndDropImageCache = () => {
  image = new Image();
  image.src =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAhCAYAAAAh3nDkAAAABHNCSVQICAgIfAhkiAAAABl0RVh0U29mdHdhcmUAZ25vbWUtc2NyZWVuc2hvdO8Dvz4AAAKrSURBVFiF5ddZSFRRHMfx71yu3aFxVLAHHc0gMBCkBQKJisoWQttU0DIVgxZpIQyTlhEhXCJIrB5CC9tteSg0KYModQwfSkTRzDQl0dTRMZcZuejo7SGyhsl8mMXA3+P/nMOH83D+f45KURSFOYowV7ANPjU1xfsPdbS0tLkXt1qt5FzM55w+i3sPHrsXL7pdjKG6xm2oDb4vLpoz6SfdjosAWq0n69aG/XVDdm4eZrPFYWjrlo2Eb1pvjwPM9OIqKt85DAPodH52uABQ39BISelLAHp6jTx9Voaxf8Ap6L8iAFQZaii8eReAzs4urhfcoqvrGwAazUKnQFpPT7uaat52OBGgo+MrV64V0trWTmCAPyeOHSI0NMTluEpRFCUtPZMv7R2sWrkcQ3UNPt5ePHlUhEqlwjT4HVmWHYYW+foiSQvscVmWMRoHCAzUEZ94BJNpkOclxaglie2RsUxOTjqMx0TvJOVwsk1NAFCr1Xh5aTmfkY3JNMieXRGoJQnAKTDAxMSEXU0E6O7uITVNz8jIKAeS44nfG+MUcLaI8LOFDg+PcPnSBaYUhf1JKejPniIkZBlJCXGMms0OQ2vCVtvjsizT2tYOQGqafnphfGIcgMSEWIfhmSJKksTxowdRsO01ATp/l6G/MqcdTgTIv1pAX5/RZiE3OwOArJw8LBYXjtTGpmaGhoYJDl5KbW09Wu3vIVBZ5bqRKgJE7Y5Ao9FQV9eAIAhk6k87BZwtAkBkxDZEUeRF+Wu0Wk/MlrHpDW4ZqUZjP+Wv3lBaVo7FMsbD+4X4+Hg7BZ4pAkDTx08MmAaJjtrBkqBArFYrlj9u76qIAAU37tDc/Hm6uGJ5KDqdn8txlaIoitls4W2FgZ5eI0GLA9gcvgEPD3H2087AXa7MkP/jozjv8B/+qwKOzHZgowAAAABJRU5ErkJggg==\n';
};

const getMultipleDragAndDropImage = () => {
  if (!image) {
    generateMultipleDragAndDropImageCache();
  }

  return image;
};

const pageSource = {
  beginDrag(props, monitor, component) {
    if (!props.page.dragging) {
      props.beginDragAction(props.page.pageNumber);

      if (component.props.page.multipleDrag) {
        component.props.connectDragPreview(getMultipleDragAndDropImage());
      } else {
        component.props.connectDragPreview();
      }
    }

    return {
      pageNumber: props.page.pageNumber,
    };
  },

  endDrag(props, monitor, component) {
    if (props.page.dragging) {
      props.endDragAction();
    }
  },
};

const AFTER = 'AFTER';
const BEFORE = 'BEFORE';

const getPositionType = (component, monitor) => {
  const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();
  const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
  const clientOffset = monitor.getClientOffset();

  if (clientOffset) {
    const hoverClientX = clientOffset.x - hoverBoundingRect.left;

    return hoverClientX > hoverMiddleX ? AFTER : BEFORE;
  }

  return AFTER;
};

const canDropPage = props =>
  !props.draggingPageNumbers.includes(props.page.pageNumber);

const pageTarget = {
  hover(props, monitor, component) {
    if (!component) {
      return null;
    }

    const dragPageNumber = monitor.getItem().pageNumber;
    const hoverPageNumber = props.page.pageNumber;

    if (dragPageNumber === hoverPageNumber) {
      return;
    }

    if (!props.canDeleteOrDragPage) {
      return;
    }

    const positionType = getPositionType(component, monitor);

    if (component.props.isOver && canDropPage(props)) {
      component.setState({ hoverPosition: positionType });
      return;
    }

    if (!component.props.isOver && component.state.hoverPosition) {
      component.setState({ hoverPosition: null });
    }
  },

  drop(props, monitor, component) {
    const source = monitor.getItem();

    if (monitor.didDrop()) {
      return;
    }

    if (!canDropPage(props) || !props.canDeleteOrDragPage) {
      props.endDragAction();
      return;
    }

    if (monitor.getItemType() === TYPE_PAGE) {
      props.movePageAction(
        source.pageNumber,
        props.page.pageNumber,
        component.state.hoverPosition
      );
    }

    if (monitor.getItemType() === FILE) {
      let filteredFiles = source.files
        .filter(file => AUTHORIZED_MIME_TYPE.includes(file.getAsFile().type))
        .map(file => file.getAsFile());

      if (filteredFiles.length > 15) {
        props.maxUploadFileAction();
        return;
      }

      props.processFilesAction(
        filteredFiles,
        Object.keys(props.files).length,
        props.page.pageNumber,
        component.state.hoverPosition
      );
    }

    props.endDragAction();
  },
};

@DragSource(TYPE_PAGE, pageSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
}))
@DropTarget([TYPE_PAGE, FILE], pageTarget, (connect, monitor, component) => {
  const isOver = monitor.isOver({ shallow: true });

  return {
    connectDropTarget: connect.dropTarget(),
    isOver,
  };
})
class Page extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.removePage = this.removePage.bind(this);
    this.counterClockwiseRotation = throttle(
      this.counterClockwiseRotation.bind(this),
      500
    );
    this.clockwiseRotation = throttle(this.clockwiseRotation.bind(this), 500);
    this.state = {
      hoverPosition: null,
    };
  }

  counterClockwiseRotation(evt) {
    this.props.rotatePdfAction(270);
  }

  clockwiseRotation(evt) {
    this.props.rotatePdfAction(90);
  }

  removePage(evt) {
    this.props.removePageAction();
  }

  handleClick(e) {
    e.stopPropagation();

    if (e.shiftKey) {
      if (this.props.page.picked) {
        return;
      }

      this.props.addPdfBetweenPickedAction(this.props.page.pageNumber);
      return;
    }

    if (e.ctrlKey) {
      if (!this.props.page.picked) {
        this.props.addPdfToPickedAction(this.props.page.pageNumber);
        return;
      }

      this.props.removePdfToPickedAction(this.props.page.pageNumber);
      return;
    }

    this.props.pickPdfAction(this.props.page.pageNumber);
  }

  render() {
    const { connectDragSource, connectDropTarget, isOver } = this.props;

    return connectDragSource(
      connectDropTarget(
        <div
          style={{
            display: 'inline-flex',
            opacity: this.props.page.dragging ? '0.5' : '1',
          }}
        >
          {isOver && BEFORE === this.state.hoverPosition && (
            <DropArea isDropAreaVisible={true} />
          )}
          <PagePlaceholder
            picked={this.props.page.picked}
            currentErroredPage={
              this.props.page.pageNumber ===
              this.props.pdfErrorCurrentPageNumber
            }
            draggin={this.props.page.dragging}
            id={`pdf-editor-page-${this.props.page.pageNumber}`}
          >
            {this.props.page.picked &&
              !this.props.page.dragging &&
              this.props.pickedPageNumbers.length === 1 && (
                <CommandButtonsContainer
                  forOnePage
                  className="menu"
                  background={DARK_COLOR}
                >
                  <Div margin=".25em 0">
                    <div className="menu">
                      <Button
                        title="rotation anti-horaire -90°"
                        onClick={this.counterClockwiseRotation}
                        className="menu-btn"
                        marginBottom={0.5}
                        marginTop={0.25}
                      >
                        <IconReload mirror />
                      </Button>
                    </div>
                    <Button
                      title="rotation horaire +90°"
                      onClick={this.clockwiseRotation}
                      className="menu-btn"
                      marginBottom={0.5}
                    >
                      <IconReload />
                    </Button>
                  </Div>
                  <Div margin="1em 0 .25em">
                    {this.props.canDeleteOrDragPage && (
                      <Button
                        onClick={this.removePage}
                        title="Supprimer la sélection"
                        backgroundColor={DANGER_COLOR}
                        marginTop="1em"
                        className="menu-btn"
                        marginBottom={0.25}
                        color={LIGHT_COLOR}
                        borderColor={DANGER_COLOR}
                        backgroundHoverColor={DANGER_COLOR_HOVER}
                        borderHoverColor={DANGER_COLOR_HOVER}
                        hoverColor={LIGHT_COLOR}
                      >
                        <IconTrash />
                      </Button>
                    )}
                  </Div>
                </CommandButtonsContainer>
              )}
            <PdfCanvas
              {...this.props}
              width={200}
              handleClick={this.handleClick}
              handleDoubleClick={() => {
                this.props.onFullScreen(this.props.page);
              }}
              file={this.props.files[this.props.page.fileId]}
            />
            <PageNumber
              color={this.props.page.fileId ? SECONDARY_COLOR : PRIMARY_COLOR}
              currentErroredPage={
                this.props.page.pageNumber ===
                this.props.pdfErrorCurrentPageNumber
              }
            >
              {this.props.page.pageNumber}
            </PageNumber>
          </PagePlaceholder>
          {isOver && AFTER === this.state.hoverPosition && (
            <DropArea isDropAreaVisible={true} />
          )}
        </div>
      ),
      {
        dropEffect: 'move',
      }
    );
  }
}

const makeSelector = () => {
  return createSelector(
    [
      state => state.pdfEditor.pages,
      state => state.pdfEditor.pickedPageNumbers,
      state => state.pdfEditor.draggingPageNumbers,
      state => state.pdfEditor.loaded,
      state => state.pdfEditor.files,
      state => state.pdfEditor.pdfPageErrors.currentPageNumber,
    ],
    (
      pages,
      pickedPageNumbers,
      draggingPageNumbers,
      loaded,
      files,
      pdfErrorCurrentPageNumber
    ) => ({
      pages,
      pickedPageNumbers,
      draggingPageNumbers,
      loaded,
      files,
      pdfErrorCurrentPageNumber,
    })
  );
};

function mapStateToProps(state, ownProps) {
  return makeSelector(state, ownProps);
}
export default connect(mapStateToProps, {
  pickPdfAction,
  addPdfBetweenPickedAction,
  addPdfToPickedAction,
  removePdfToPickedAction,
  movePageAction,
  removePageAction,
  rotatePdfAction,
  beginDragAction,
  endDragAction,
  processFilesAction,
  maxUploadFileAction,
})(Page);
