import React from 'react';
import Svg from './Svg';

export default function IconComment(props) {
  return (
    <Svg
      {...props}
      d="M16 48h224v160.507h-62.764V240l-33.699-31.934H16V48zm212 148.507V60H28v136.066h120.319l16.917 16.031v-15.59H228zM56 162.954h109.161v-12H56v12zM56 134h144.161v-12H56v12zm0-28.954h144.161v-12H56v12z"
    />
  );
}
