import moment from 'moment';
import '../lib/datetimepicker/bootstrap-datetimepicker';
import 'bootstrap-datetime-picker/js/locales/bootstrap-datetimepicker.fr.js';

const formatDate = (date, withTime = false) => {
  if (
    withTime &&
    date.match(/[0-9]{2}\/[0-9]{2}\/[0-9]{4} [0-9]{2}:[0-9]{2}/)
  ) {
    return moment(date, 'DD/MM/YYYY HH:mm').toDate();
  }

  if (date.match(/[0-9]{2}\/[0-9]{2}\/[0-9]{4}/)) {
    return moment(date, 'DD/MM/YYYY').toDate();
  }

  if (date.match(/[0-9]{8}/)) {
    return moment(date, 'DDMMYYYY').toDate();
  }

  if (date.match(/[0-9]{6}/)) {
    return moment(date, 'DDMMYY').toDate();
  }

  return null;
};

export default ($element, withTime = false) => {
  $element.each((index, elem) => {
    let $elem = $(elem);

    $elem.change(e => {
      let date = formatDate(e.currentTarget.value, withTime);

      $elem.trigger({
        type: 'dateChanged',
        date: date,
      });

      if (date) {
        date = moment(date).format(
          withTime ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY'
        );
      }

      $elem.val(date);
      $elem.datetimepicker('update');
    });

    $elem.datetimepicker({
      viewMode: 'years',
      autoclose: true,
      format: withTime ? 'dd/mm/yyyy hh:ii' : 'dd/mm/yyyy',
      componentIcon: '.s7-date',
      forceParse: false,
      navIcons: {
        rightIcon: 's7-angle-right',
        leftIcon: 's7-angle-left',
      },
      language: 'fr',
    });
  });
};
