import { UPDATE_SEARCH, START_SEARCH } from '../actions/dataroom.js';
import { keys } from 'underscore';
import matcher from '../search/textSearchMatcher.js';

export default function(state = initialState, action = {}) {
  switch (action.type) {
    case START_SEARCH: {
      return {
        ...state,
        working: {
          ...state.working,
          nodes: updateNodesSearch(state.working.nodes, action.search),
        },
      };
    }
    case UPDATE_SEARCH:
      return {
        ...state,
        search: action.search,
      };
  }

  return state;
}

function resetNodeSearch(nodes) {
  const nodeIds = keys(nodes);
  const newNodes = {};

  nodeIds.forEach(nodeId => {
    newNodes[nodeId] = {
      ...nodes[nodeId],
      hidden: false,
      matched: false,
    };
  });

  return newNodes;
}

function updateNodesSearch(nodes, search) {
  let newNodes = {};
  const nodeIds = keys(nodes);
  let matchedNodeIds = new Set();
  let visibleNodeIds = new Set();
  let hiddenNodeIds = new Set();

  if (searchIsEmpty(search)) {
    return resetNodeSearch(nodes);
  } else {
    nodeIds.forEach(nodeId => {
      if (nodeMatchSearch(nodes[nodeId], search)) {
        matchedNodeIds.add(nodeId);
        hiddenNodeIds.delete(nodeId);
        setParentToVisible(nodes, nodes[nodeId], visibleNodeIds, hiddenNodeIds);
      } else {
        hiddenNodeIds.add(nodeId);
      }
    });
  }

  nodeIds.forEach(nodeId => {
    newNodes[nodeId] = Object.assign({}, nodes[nodeId], {
      hidden: false,
      matched: false,
    });
    let matched = false;
    if (matchedNodeIds.has(nodeId)) {
      newNodes[nodeId] = Object.assign({}, newNodes[nodeId], { matched: true });
      matched = true;
    }
    if (visibleNodeIds.has(nodeId)) {
      newNodes[nodeId] = Object.assign({}, newNodes[nodeId], { folded: false });
    } else if (
      !nodes[nodeId].parentId ||
      (!hiddenNodeIds.has(nodes[nodeId].parentId) && !matched)
    ) {
      newNodes[nodeId] = Object.assign({}, newNodes[nodeId], {
        hidden: true,
        matched: false,
      });
    }
  });

  return newNodes;
}

function searchIsEmpty(search) {
  return (
    !search.beginDate && !search.endDate && search.text.trim().length === 0
  );
}

function setParentToVisible(nodes, node, visibleNodeIds, hiddenNodeIds) {
  if (!node.parentId) {
    return;
  }

  hiddenNodeIds.delete(node.parentId);
  visibleNodeIds.add(node.parentId);
  setParentToVisible(
    nodes,
    nodes[node.parentId],
    visibleNodeIds,
    hiddenNodeIds
  );
}

function nodeMatchSearch(node, search) {
  let beginDateMatch = true,
    endDateMatch = true,
    textMatch = true;

  if (search.beginDate) {
    beginDateMatch = node.file && search.beginDate < node.file.editedAt;
  }

  if (search.endDate) {
    const date = new Date(search.endDate);
    date.setHours(23, 59, 59);

    endDateMatch = node.file && date > node.file.editedAt;
  }

  if (search.text) {
    textMatch = matcher(node.name, search.text);
  }

  return beginDateMatch && endDateMatch && textMatch;
}
