import reduceReducers from 'reduce-reducers';
import {
  CLOSE_PDF_EDITOR,
  FETCH_DATAROOM,
  FOLD,
  FOLD_ALL,
  FOLD_RECURSIVE,
  LOAD_DATAROOM,
  OPEN_PDF_EDITOR,
  REFRESH_NODES,
  RESET_DATAROOM,
  SELECT,
  TOGGLE_IS_LARGE_DATAROOM,
  UNFOLD,
  UNFOLD_ALL,
  UNFOLD_RECURSIVE,
  UNSELECT,
} from '../actions/dataroom';
import { loadDataroom, normalizeNodes } from './utils/dataroomLoad';
import addFiles from '../ducks/addFiles';
import childNode from '../ducks/utils/childNode';
import validate from '../ducks/validate';
import uploader from '../ducks/uploader';
import exportDataroom from '../ducks/exportDataroom';
import pdfExplode from '../ducks/pdfExplode';
import addTemplate from '../ducks/addTemplate';
import removeEmptyFolderNodes from '../ducks/removeEmptyFolderNodes';
import pdfEditor from '../ducks/pdfEditor';
import attachFileToDocument from '../ducks/attachFileToDocument';
import pdfMerged from '../ducks/pdfMerged';
import searchReducer from './search.js';
import transformArchiveNodeToFolder from '../ducks/transformArchiveNodeToFolder';
import pickNode from '../ducks/pickNode';
import addNodes from '../ducks/addNodes';
import copyNode from '../ducks/copyNodes';
import importFiles from '../ducks/importFiles';
import nodesClipBoard from '../ducks/nodesClipBoard';
import nodeMenu from '../ducks/nodeMenu';
import removeDocumentNodeWithoutFile from '../ducks/removeDocumentNodeWithoutFile';
import {
  changeAllChildrenNodesFoldState,
  changeAllNodesFoldState,
  changeNodeFoldState,
} from './utils/nodeFold';
import removeNode from '../ducks/removeNodes';
import { countSelectedNodes, updateSelectedStatus } from './utils/nodeSelected';
import lock from '../ducks/lock';
import extractZipFile from '../ducks/extractZipFile';
import renameNode from '../ducks/renameNode';
import slowActions from '../ducks/slowActions';
import dragDrop from '../ducks/dragDrop';
import moveNode from '../ducks/moveNodes';
import changeNodeType from '../ducks/changeNodeType';
import detachFileToDocument from '../ducks/detachFileToDocument';

const initialState = {
  isLargeDataroom: false,
  working: {
    slowActionInProgress: false,
    corrupted: false,
    nodes: [],
    lastDocuments: [],
    rootNodeId: null,
    loaded: false,
    loading: false,
    exporting: false,
    modalContactShare: {
      open: false,
      sending: false,
      sent: false,
    },
    nodeClipBoard: {
      type: null,
      nodeIds: null,
      dataroomId: null,
    },
    savedNodes: {},
    uploads: [],
    lock: {
      lockedByMe: false,
      lockBy: null,
      locking: false,
      unlocking: false,
    },
    confirmAttachFileModal: {
      open: false,
      nodeId: null,
      file: null,
    },
    confirmAddFilesModal: {
      open: false,
      addFilesAction: null,
      notAllowedDocuments: [],
    },
    addNodesModal: {
      open: false,
      position: null,
      nodeId: null,
    },
    addNodes: {
      show: false,
      position: null,
      nodeId: null,
    },
    renameNode: {
      nodeNameEditable: false,
      nodeId: null,
    },
    attachFileModal: {
      open: false,
      nodeId: null,
    },
    editMetadataModal: {
      nodeId: null,
      open: false,
    },
    confirmImportFilesModal: {
      open: false,
      importing: false,
      action: null,
      actionToDispatch: null,
      errors: [],
    },
    confirmExtractZipModal: {
      open: false,
      zipFile: null,
      position: null,
      destinationNode: null,
      extracting: false,
    },
    confirmRemoveNumberingModal: {
      open: false,
      nodes: [],
      nodeIdsRenumbered: [],
    },
    validation: {
      validating: false,
      nodesWithErrorsIds: [],
      nodesWithWarningIds: [],
      firstNodeWithErrorId: null,
      firstNodeWithWarningId: null,
    },
    // add pickedNodeIds to published or put then in root state of dataroom reducer ?
    pickedNodeIds: [],
    draggingNodeIds: [],
    nodeIdDragInitiator: null,
    addTemplate: {
      adding: false,
      addFilesActionId: null,
    },
  },
  published: {
    nodes: [],
    lastDocuments: [],
    rootNodeId: null,
    loaded: false,
    loading: false,
    exporting: false,
    lastDiscussedDocuments: [],
    anonymous: false,
    modalContactShare: {
      open: false,
      sending: false,
      sent: false,
    },
    modalNonDisclosureAgreement: {
      open: false,
      submitting: false,
    },
  },
  search: {
    beginDate: null,
    endDate: null,
    text: '',
  },
  favorite: null,
  selectedNodesNumber: 0,
  dataroom: null,
  loading: false,
  loaded: false,
  currentDataroomType: null,
  groupType: null,
  assetId: null,
  dataroomId: null,
  publishing: false,
  viewed: false,
  pdfEditor: {
    nodeId: null,
    open: false,
  },
  selectedNodeIdsInContextMenu: [],
};

function dataroom(state = initialState, action = {}) {
  switch (action.type) {
    case RESET_DATAROOM: {
      return initialState;
    }
    case TOGGLE_IS_LARGE_DATAROOM: {
      return {
        ...state,
        isLargeDataroom: action.isLargeDataroom,
      };
    }
    case FETCH_DATAROOM: {
      return {
        ...state,
        dataroomId: action.dataroomId,
        loading: true,
        anonymous: action.anonymous,
      };
    }
    case LOAD_DATAROOM:
      return loadDataroom(action, state);
    case REFRESH_NODES:
      return {
        ...state,
        working: {
          ...state.working,
          nodes: normalizeNodes(state.dataroom.id, action.nodes).nodes,
        },
      };
    case OPEN_PDF_EDITOR: {
      return {
        ...state,
        pdfEditor: {
          open: true,
          nodeId: action.nodeId,
        },
      };
    }
    case 'PDF_EDIT_SUCCESS':
    case CLOSE_PDF_EDITOR: {
      return {
        ...state,
        pdfEditor: {
          open: false,
        },
      };
    }
    case UNFOLD_ALL:
    case FOLD_ALL:
      return changeAllNodesFoldState(action, state);
    case FOLD_RECURSIVE:
    case UNFOLD_RECURSIVE:
      return changeAllChildrenNodesFoldState(action, state);
    case UNFOLD:
    case FOLD:
      return changeNodeFoldState(action, state);
    case SELECT:
    case UNSELECT:
      const newSelectedNodes = updateSelectedStatus(
        state.working.nodes,
        action.type === SELECT,
        action.nodeId
      );

      return {
        ...state,
        working: {
          ...state.working,
          nodes: newSelectedNodes,
        },
        selectedNodesNumber: countSelectedNodes(newSelectedNodes),
      };
  }

  return state;
}

export default reduceReducers(
  dataroom,
  searchReducer,
  lock,
  pickNode,
  nodeMenu,
  dragDrop,
  moveNode,
  pdfMerged,
  nodesClipBoard,
  renameNode,
  uploader,
  copyNode,
  transformArchiveNodeToFolder,
  slowActions,
  addFiles,
  validate,
  pdfEditor,
  exportDataroom,
  attachFileToDocument,
  addTemplate,
  pdfExplode,
  changeNodeType,
  detachFileToDocument,
  importFiles,
  extractZipFile,
  addNodes,
  removeNode,
  removeEmptyFolderNodes,
  removeDocumentNodeWithoutFile
);
