(function (t) {
// en
t.add("asset.all_comments", "All comments", "discussion", "en");
t.add("asset.all_questions", "All questions", "discussion", "en");
t.add("last_comments", "Latest comments", "discussion", "en");
t.add("last_questions", "Latest questions\/answers", "discussion", "en");
t.add("add_comment", "Add a comment", "discussion", "en");
t.add("message", "Message", "discussion", "en");
t.add("Le fichier doit \u00eatre au format PDF et ne doit pas exc\u00e9der 80Mo.", "comment_file_format_infos", "discussion", "en");
t.add("error.empty_comment", "The comment box cannot be empty", "discussion", "en");
t.add("error.comment_file_not_pdf", "Only pdf files are authorized. Click again on \"Add file\"", "discussion", "en");
t.add("error.max_size_reached", "The file is over 80Mb. Click again on \"Add file\"", "discussion", "en");
t.add("see_all_asset_comments", "See all the comments on the asset", "discussion", "en");
t.add("comment_number", "Comment no. %number%", "discussion", "en");
t.add("comments", "Comments", "discussion", "en");
t.add("questions", "Questions", "discussion", "en");
t.add("error.empty_question", "The question box cannot be empty", "discussion", "en");
t.add("ask", "Ask", "discussion", "en");
t.add("question_of", "Question number %number% from", "discussion", "en");
t.add("no_answer", "Nobody has yet answered this question", "discussion", "en");
t.add("validate_and_save", "Save and validate", "discussion", "en");
t.add("answer_of", "Answer from ", "discussion", "en");
t.add("asset.unread_comments", "Comments not read", "discussion", "en");
t.add("asset.export_all_comments", "Export all comments", "discussion", "en");
t.add("asset.unresponded_questions", "Questions without answers", "discussion", "en");
t.add("asset.unvalidated_questions", "Answers not validated", "discussion", "en");
t.add("asset.export_all_questions", "Export all questions", "discussion", "en");
t.add("comment.list", "Comment list", "discussion", "en");
t.add("by_asset", "Per asset", "discussion", "en");
t.add("by_buyer_group", "Per group", "discussion", "en");
t.add("select_asset_by_name", "Select an asset by its name", "discussion", "en");
t.add("select_folder_from_asset", "Select a folder from the asset tree above", "discussion", "en");
t.add("select_buyer_group_by_name", "Select a group by its name", "discussion", "en");
t.add("From", "From", "discussion", "en");
t.add("To", "To", "discussion", "en");
t.add("State", "Status", "discussion", "en");
t.add("ALL", "All", "discussion", "en");
t.add("STATE_READ", "Read", "discussion", "en");
t.add("STATE_UNREAD", "Not read", "discussion", "en");
t.add("Number", "No.", "discussion", "en");
t.add("filter_comment_number", "Comment no.", "discussion", "en");
t.add("Order", "Order", "discussion", "en");
t.add("ORDER_BY_CREATED_AT_DESC", "\u2193 Date \u2014 from newest to oldest", "discussion", "en");
t.add("ORDER_BY_CREATED_AT_ASC", "\u2191 Date \u2014 from oldest to newest", "discussion", "en");
t.add("ORDER_BY_TREE_LEVEL_ASC", "\u2193 Tree level \u2014 downwards", "discussion", "en");
t.add("ORDER_BY_TREE_LEVEL_DESC", "\u2191 Tree level \u2014 upwards", "discussion", "en");
t.add("reset_filter", "Reset filters", "discussion", "en");
t.add("marked_as_read_question", "Marked %br_html% as read?", "discussion", "en");
t.add("question.list", "Questions list", "discussion", "en");
t.add("NO_ANSWERS", "Question without answer", "discussion", "en");
t.add("NO_ANSWERS_VALIDATE", "Answers to validate", "discussion", "en");
t.add("ANSWERS_VALIDATE", "Answers validated", "discussion", "en");
t.add("NO_ANSWERS_OR_NO_ANSWERS_VALIDATE", "Question without answer or to be validated", "discussion", "en");
t.add("filter_question_number", "Question no.", "discussion", "en");
t.add("ORDER_BY_QUESTION_ADDED_AT_DESC", "\u2193 Date \u2014 from newest to oldest", "discussion", "en");
t.add("ORDER_BY_QUESTION_ADDED_AT_ASC", "\u2191 Date \u2014 from oldest to newest", "discussion", "en");
t.add("go_to_hierarchy", "Go to that tree level", "discussion", "en");
t.add("your_answer", "Your answer", "discussion", "en");
t.add("save_answer", "Save%br_tag%the answer", "discussion", "en");
t.add("save_and_validate_answer", "Save and validate%br_tag%the answer", "discussion", "en");
t.add("assistant_qr", "Questions\/Answers wizard", "discussion", "en");
t.add("question_numbers", "Question %current% out of %total%", "discussion", "en");
t.add("no_waiting_questions_for_search", "There are no pending answers to your search", "discussion", "en");
t.add("QUESTION_WITH_ANSWERS", "Question with answers", "discussion", "en");
})(Translator);
