import React, { Component } from 'react';
import styled from 'styled-components';
import { SUCCESS_COLOR } from '../../theme_blue';

export default styled.span`
  display: block;
  position: relative;
  margin-right: .5rem;
  font-style: normal;
  font-weight: normal;
  &:before {
    display: inline-block;
    content: "\\F15B";
    font-family: "FontAwesome";
    font-size: 18px;
    text-rendering: auto;
    margin-right: 5px;
    color: ${props => (props.color ? props.theme[props.color] : 'inherit')};
  }
  &:after {
  ${props => {
    if (props.isValid) {
      let string = '';
      string += 'display: flex;';
      string += 'content: "\\F00C";';
      string += 'font-family: "FontAwesome";';
      string += 'font-size: 10px;';
      string += 'position: absolute;';
      string += 'bottom: 0;';
      string += 'right: -3px;';
      string += 'color: ' + props.theme[SUCCESS_COLOR] + ';';
      string += 'width: 13px;';
      string += 'height: 13px;';
      string += 'line-height: 1;';
      string += 'background: white;';
      string += 'justify-content: center;';
      string += 'align-items: center;';
      string += 'border-radius: 100%;';
      string += 'border: solid 1px ' + props.theme[SUCCESS_COLOR] + ';';
      return string;
    }
  }}
`;
