import createUndoableActionReducer from '../reducers/createUndoableActionReducer';
import { uniqueId } from 'underscore';
import getPathLevel from './utils/getPathLevel';
import undoSaga from './dataroomActions/undoSaga';
import { takeEvery, call, select } from 'redux-saga/effects';
import getPosition from './utils/getPosition';
import { removeAndSaveNodes, undoRemoveNode } from './utils/removeNodes';

import apiClient from 'js/dataroom/core/apiClient';
import queryString from 'qs';

export const REMOVE_NODES = 'REMOVE_NODES';

export const removeNodesAction = (nodes, deleteOnlyHead) => {
  const pathLevel = getPathLevel(nodes[0].path);

  return {
    type: REMOVE_NODES,
    nodeIds: nodes
      .sort(
        (node1, node2) =>
          getPosition(pathLevel, node1.path) -
          getPosition(pathLevel, node2.path)
      )
      .map(node => node.id),
    deleteOnlyHead,
    frontActionId: uniqueId('dataroom_action'),
  };
};

const removeNodeApi = (action, { dataroomId }) => {
  const queryStringObject = {};
  if (!action.deleteOnlyHead) {
    queryStringObject.includeChild = '';
  }
  return apiClient.request(
    new Request(
      `/api/datarooms/${dataroomId}/nodes?${queryString.stringify(
        queryStringObject
      )}`,
      {
        method: 'DELETE',
        body: JSON.stringify({
          nodeIds: action.nodeIds,
        }),
      }
    )
  );
};

export function* saga(action) {
  const selectedState = yield select(state => ({
    dataroomId: state.dataroom.dataroom.id,
  }));

  return yield call(removeNodeApi, action, selectedState);
}

export function* undoActionSaga() {
  yield takeEvery('UNDO_REMOVE_NODES', undoSaga);
}

function removeNodes(state, action) {
  let newState = {
    ...state,
    working: {
      ...state.working,
      savedNodes: {
        ...state.working.savedNodes,
        [action.frontActionId]: {},
      },
    },
  };

  return removeAndSaveNodes(
    newState,
    action.nodeIds,
    action.frontActionId,
    action.deleteOnlyHead
  );
}

function undoRemoveNodes(state, action) {
  let newState = { ...state };

  [...action.nodeIds].reverse().forEach(nodeId => {
    newState = undoRemoveNode(
      newState,
      nodeId,
      action.frontActionId,
      action.deleteOnlyHead
    );
  });

  return newState;
}

export default createUndoableActionReducer(
  REMOVE_NODES,
  removeNodes,
  undoRemoveNodes
);
