import { take } from 'redux-saga/effects';

export default function*(action) {
  return yield take(
    receivedAction =>
      (receivedAction.type === `${action.type}_SUCCESS` ||
        receivedAction.type === `${action.type}_ERROR` ||
        receivedAction.type === `${action.type}_PREPARE_ERROR`) &&
      receivedAction.action.frontActionId === action.frontActionId
  );
}
