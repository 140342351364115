import React, { Fragment } from 'react';
import 'translations/messages/fr';
import 'translations/asset-asset/fr';
import {
  DARK_COLOR,
  LIGHT_COLOR,
  LIGHT_GREY_COLOR,
} from '../../presentational/components/theme_blue';
import Modal from '../../presentational/components/Modal';
import IconClose from '../../presentational/components/img/icons/IconClose';
import P from '../../presentational/components/P';
import Button from '../../presentational/components/Button';
import A from '../../presentational/components/A';

export function ConfirmationModal(props) {
  const { open, sending, title, form, disabled } = props;

  return (
    <Fragment>
      {open && (
        <Modal zIndex={1000}>
          <Modal.Popin width={props.width}>
            <Modal.Header>
              <Modal.Title>{title}</Modal.Title>
              <Modal.Close>
                <A
                  cursor="pointer"
                  color={LIGHT_COLOR}
                  hoverColor={LIGHT_GREY_COLOR}
                  onClick={props.onClose}
                >
                  <IconClose width="1.5rem" alt="fermer" />
                </A>
              </Modal.Close>
            </Modal.Header>
            <Modal.ContentPlaceholder>
              <Modal.Content display="inline-block">
                {props.children}
              </Modal.Content>
            </Modal.ContentPlaceholder>
            <Modal.Footer>
              <P
                marginTop="0"
                marginBottom="0"
                textAlign="right"
                fontSize=".75em"
              >
                <Button
                  color={DARK_COLOR}
                  borderColor={DARK_COLOR}
                  backgroundColor={LIGHT_COLOR}
                  backgroundHoverColor={DARK_COLOR}
                  onClick={props.onClose}
                >
                  Annuler
                </Button>
                <Button
                  type="submit"
                  form={form}
                  disabled={sending || disabled}
                  onClick={props.onValidate}
                  marginLeft={0.5}
                >
                  Valider
                </Button>
              </P>
            </Modal.Footer>
          </Modal.Popin>
        </Modal>
      )}
    </Fragment>
  );
}
