import React from 'react';
import Svg from './Svg';

export default function IconCheckmark(props) {
  return (
    <Svg
      {...props}
      d="M86.512,178.071l-53.954,-53.954l-18.394,18.393l72.348,72.348l155.324,-155.323l-18.394,-18.393l-136.93,136.929Z"
    />
  );
}
