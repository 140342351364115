import { difference } from 'underscore';
import { AFTER } from '../../dataroom/ducks/utils/getNextPath';

export const ROTATE_PDF = 'EDITOR_ROTATE_PDF';
const MOVE_PAGE = 'EDITOR_MOVE_PAGE';
export const ROTATE_PDF_PAGE_NUMBER = 'EDITOR_ROTATE_PDF_PAGE_NUMBER';

export const movePageAction = (
  pdfNumberSource,
  pdfNumberDestination,
  position
) => ({
  type: MOVE_PAGE,
  numberSource: pdfNumberSource,
  numberDestination: pdfNumberDestination,
  position: position,
});

export const rotatePdfAction = rotation => ({
  type: ROTATE_PDF,
  rotation: rotation,
});

function updatePageRotation(state, currentPage, action) {
  const pageIndex = state.pages.findIndex(
    page => page.pageNumber === currentPage.pageNumber
  );

  const rotation = state.pages[pageIndex].rotation + action.rotation;

  let newPage = {
    ...state.pages[pageIndex],
    rotation: ((rotation / 90) % 4) * 90,
  };

  const pages = state.pages.map((page, index) => {
    if (pageIndex !== index) {
      return page;
    }

    return newPage;
  });

  return {
    ...state,
    pages: pages,
  };
}

export default function reducer(state, action) {
  switch (action.type) {
    case ROTATE_PDF:
    case ROTATE_PDF_PAGE_NUMBER: {
      let pagesToRotate = [];
      if (action.type === ROTATE_PDF) {
        pagesToRotate = state.pages.filter(page => page.picked);
      } else {
        pagesToRotate = [
          state.pages.find(page => page.pageNumber === action.pageNumber),
        ];
      }

      let newState = {
        ...state,
        prevPages: [...state.prevPages, state.pages],
      };

      for (let pickedPage of pagesToRotate) {
        newState = updatePageRotation(newState, pickedPage, action);
      }

      return newState;
    }
    case MOVE_PAGE: {
      const prevPages = state.pages;
      const draggingPage = state.pages.filter(page => page.dragging);
      const reversedPages = draggingPage.reverse();

      let pages = difference(state.pages, draggingPage);

      let destinationPageIndex = pages.findIndex(
        page => page.pageNumber === action.numberDestination
      );

      if (action.position === AFTER) {
        destinationPageIndex += 1;
      }

      for (let reversedPage of reversedPages) {
        pages.splice(destinationPageIndex, 0, reversedPage);
      }

      return {
        ...state,
        pages: pages,
        prevPages: [...state.prevPages, prevPages],
      };
    }
  }

  return state;
}
