import styled from 'styled-components';

const H3 = styled.h3`
  font-size: 1rem;
  display: flex;
  align-items: flex-start;
  ${props => (props.marginTop ? 'margin-top: ' + props.marginTop + ';' : null)}
  ${props => (props.width ? 'width: ' + props.width + ';' : 'width: 100%;')}
  ${props =>
    props.marginBottom ? 'margin-bottom: ' + props.marginBottom + ';' : null}
  ${props => (props.color ? 'color: ' + props.theme[props.color] + ';' : null)}
`;

export default H3;
