import React, {
  Fragment,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import UserSelect from '../../../app/components/form/select/UserSelect';
import Span from '../../../presentational/components/Span';
import {
  BODY_FONT,
  CLIENT_UI_COLOR,
  DANGER_COLOR,
  DARK_GREY_COLOR,
  LIGHT_COLOR,
  LIGHT_GREY_COLOR,
  MID_GREY_COLOR,
} from '../../../presentational/components/theme_blue';
import Div from '../../../presentational/components/Div';
import GridCol from '../../../presentational/components/GridCol';
import Button from '../../../presentational/components/Button';
import GridRow from '../../../presentational/components/GridRow';
import Hr from '../../../presentational/components/Hr';
import H3 from '../../../presentational/components/H3';
import Li from '../../../presentational/components/Li';
import A from '../../../presentational/components/A';
import IconClose from '../../../presentational/components/img/icons/IconClose';
import IconUser from '../../../presentational/components/img/icons/IconUser';
import Ul from '../../../presentational/components/Ul';
import IconCheckmark from '../../../presentational/components/img/icons/IconCheckmark';
import { useFieldArray, useForm } from 'react-hook-form';
import Form from '../../../presentational/components/form/Form';
import useNotification from '../../../app/hooks/notification';
import useApiClient from '../../../app/hooks/apiClient';
import { TYPE_ERROR } from '../../../dataroom/app/reducers/notifications';
import { browserHistory, Link } from 'react-router';
import UniqueCheckbox from '../../../presentational/components/form/UniqueCheckbox';
import Textarea from '../../../presentational/components/form/Textarea';
import Input from '../../../presentational/components/form/Input';
import MultipleCheckbox from '../../../presentational/components/form/MultipleCheckbox';
import IconCloseSimple from '../../../presentational/components/img/icons/IconCloseSimple';

export default function DataRoomForm(props) {
  const apiClient = useApiClient();
  const [currentUser, setCurrentUser] = useState(null);
  const { onSendNotificationReminder } = useNotification();

  const {
    register,
    handleSubmit,
    control,
    getValues,
    formState: { isSubmitting, errors },
  } = useForm({
    criteriaMode: 'all',
    defaultValues: props.defaultValues,
  });

  const { fields, remove, append } = useFieldArray({
    control: control,
    name: 'users',
  });

  const onSubmit = async data => {
    let url = `/api/datarooms`;
    let method = `POST`;
    let message = 'La dataroom a été ajoutée';

    if (props.dataroom) {
      url = `/api/datarooms/${props.dataroom.id}`;
      method = `PUT`;
      message = 'La dataroom a été éditée';
    }

    let normalizedData = {
      ...data,
      users: data.users.map(right => {
        return {
          user: right.user.id,
          readable: true,
          writable: right.writable,
        };
      }),
    };

    const response = await apiClient(url, {
      method,
      body: JSON.stringify(normalizedData),
    });

    if (response.ok) {
      onSendNotificationReminder(message);
      browserHistory.push('/');

      return;
    }

    onSendNotificationReminder('La création a échoué', TYPE_ERROR);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Div maxWidth="50rem" margin="0 auto" fontWeight="300">
        <Input
          {...register('name', {
            required: 'Le nom est obligatoire',
          })}
          displayBlock
          isHorizontal
          label="Nom"
          isLabelBold
          type="text"
          isRequired
          labelTextRight
          errors={errors.name?.types}
          labelColor={DARK_GREY_COLOR}
        />
        <UniqueCheckbox
          {...register('isManipulable')}
          type="checkbox"
          isHorizontal
          label="Manipulable"
          isLabelBold
          labelTextRight
          labelColor={DARK_GREY_COLOR}
        />
        <Textarea
          {...register('comment')}
          displayBlock
          isHorizontal
          label="Commentaire"
          isLabelBold
          labelTextRight
          errors={errors.comment?.types}
          labelColor={DARK_GREY_COLOR}
        />
        <Hr />
        <UserSelect
          labelTextRight
          label="Assigner un utilisateur"
          placeholder="Utilisateur..."
          onChange={setCurrentUser}
          value={currentUser}
          company
          excludeAdmin
          excludeIds={getValues('users')?.map(right => right.user.id)}
        />
        <GridRow noGutters>
          <GridCol noGutters hiddenForXs md={40 / 100} />
          <GridCol noGutters textAlign="right">
            <Button
              backgroundColor={CLIENT_UI_COLOR}
              borderColor={CLIENT_UI_COLOR}
              backgroundHoverColor={LIGHT_COLOR}
              size="tiny"
              disabled={!currentUser}
              onClick={() => {
                setCurrentUser(null);
                append({
                  user: currentUser.item,
                  readable: true,
                  writable: false,
                });
              }}
            >
              + Ajouter
            </Button>
          </GridCol>
        </GridRow>
        <H3>
          <Span color={CLIENT_UI_COLOR}>Utilisateur(s) assigné(s) :</Span>
        </H3>
        <Ul listStyleType="none" display="block" margin="0" padding="0">
          {fields.map((item, index) => (
            <Li key={item.id} strippedColor={LIGHT_GREY_COLOR} padding=".5rem">
              <GridRow>
                <GridCol xs={12 / 12}>
                  <Div display="flex">
                    <A
                      color={DANGER_COLOR}
                      display="inline-block"
                      margin="0 .5em 0 0"
                      onClick={() => remove(index)}
                    >
                      <IconClose alt="supprimer" />
                    </A>
                    <A href="#" color={CLIENT_UI_COLOR} display="inline-flex">
                      <IconUser marginRight=".5em" marginTop=".125em" />
                      <strong>
                        {item.user.firstName} {item.user.lastName.toUpperCase()}
                      </strong>
                    </A>
                  </Div>
                </GridCol>
                <GridCol xs={12 / 12}>
                  <Span color={MID_GREY_COLOR}>
                    Rôle : {Translator.trans(item.user.roles[0], {}, 'user')}
                  </Span>
                </GridCol>
                <GridCol xs={12 / 12}>
                  <MultipleCheckbox
                    {...register(`users.${index}.readable`)}
                    type="checkbox"
                    disabled
                    isHorizontal
                    label="Lecture"
                    labelTextRight
                    defaultChecked={item.readable}
                    labelColor={DARK_GREY_COLOR}
                  />
                  <MultipleCheckbox
                    {...register(`users.${index}.writable`)}
                    type="checkbox"
                    isHorizontal
                    label="Ecriture"
                    labelTextRight
                    defaultChecked={item.writable}
                    labelColor={DARK_GREY_COLOR}
                  />
                </GridCol>
              </GridRow>
            </Li>
          ))}
        </Ul>
        <Hr />
        <GridRow>
          <GridCol textAlign="right">
            <A as={Link} to={'/'}>
              <Button
                type="cancel"
                backgroundColor={DARK_GREY_COLOR}
                borderColor={DARK_GREY_COLOR}
                backgroundHoverColor={LIGHT_COLOR}
              >
                <IconCloseSimple marginRight=".5em" />
                Annuler
              </Button>
            </A>
          </GridCol>
          <GridCol textAlign="right">
            <Button
              type="submit"
              disabled={isSubmitting}
              backgroundColor={CLIENT_UI_COLOR}
              borderColor={CLIENT_UI_COLOR}
              backgroundHoverColor={LIGHT_COLOR}
            >
              <IconCheckmark marginRight=".5em" />
              Enregistrer la dataroom
            </Button>
          </GridCol>
        </GridRow>
      </Div>
    </Form>
  );
}
