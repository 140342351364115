import styled from 'styled-components';
import { PRIMARY_COLOR } from '../../theme_blue';

export default styled.button`
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  margin-left: 15px;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 8px 12px;
  font-size: 13px;
  line-height: 1.42857143;
  height: 44px;
  color: #fff;
  background-color: ${props => props.theme[PRIMARY_COLOR]};
  border-color: ${props => props.theme[PRIMARY_COLOR]};
  &[disabled] {
    opacity: 0.5;
  }
`;
