import React from 'react';
import styled from 'styled-components';
import Label, { Asterisk } from './Label';
import FieldGroup from './FieldGroup';
import FieldAndHelper from './FieldAndHelper';

const CheckboxLabel = styled.label`
  display: ${props => (props.displayBlock ? 'flex' : 'inline-flex')};
  font-weight: ${props => (props.isLabelBold ? 'bold' : 'inherit')};
  break-inside: avoid;
  border-style: solid;
  border-width: 1px;
  border-color: transparent;
  padding: 0.25em 0.5em;
  font-family: ${props => props.theme.bodyFont};
  font-size: 1em;
  background-color: ${props => {
    if (props.isDisabled) {
      return props.theme.lightGreyColor;
    }
    if (props.hasError) {
      return props.theme.dangerLightColor;
    }
    return 'transparent';
  }};
  color: ${props => {
    if (props.hasError) {
      return props.theme.dangerColor;
    }
    if (props.labelColor) {
      return props.theme[props.labelColor];
    }
    return props.theme.darkColor;
  }};
  flex: 1; /* makes every [label + checkbox] the same width when isHorizontal is used */
  flex-shrink: 0;
  cursor: ${props => (props.isDisabled ? 'not-allowed' : 'pointer')};
  ${props => (props.flexWrap ? 'flex-wrap: ' + props.flexWrap + ';' : null)}
  ${props =>
    props.justifyContent
      ? 'justify-content: ' + props.justifyContent + ';'
      : null}
  ${props =>
    props.alignItems ? 'align-items: ' + props.alignItems + ';' : null}
`;
const CheckboxContainer = styled.span`
  display: inline-flex;
  height: 100%;
  margin-right: ${props =>
    props.inputCheckboxMarginRight ? props.inputCheckboxMarginRight : '0.75em'};
`;
const Input = styled.input`
  &[type='checkbox'] {
    margin: auto;
  }
  padding: 0;
`;

class UniqueCheckbox extends React.Component {
  render() {
    return (
      <FieldGroup
        isHorizontal={this.props.isHorizontal}
        size={this.props.size}
        marginBottom0={this.props.marginBottom0}
      >
        <Label
          field_id={this.props.name}
          hasError={this.props.errors}
          isHorizontal={this.props.isHorizontal}
          labelTextRight={this.props.labelTextRight}
          label={this.props.label}
          isRequired={this.props.isRequired}
          isLabelBold={this.props.isLabelBold}
          labelWidth={this.props.labelWidth}
          labelColor={this.props.labelColor}
        />
        <FieldAndHelper isHorizontal={this.props.isHorizontal}>
          <CheckboxContainer
            inputCheckboxMarginRight={this.props.inputCheckboxMarginRight}
          >
            <Input
              type={this.props.type}
              id={this.props.name}
              name={this.props.name}
              disabled={this.props.isDisabled}
              ref={this.props.forwardedRef}
              onChange={this.props.onChange}
              {...this.props}
            />
          </CheckboxContainer>
        </FieldAndHelper>
      </FieldGroup>
    );
  }
}

export default React.forwardRef((props, ref) => (
  <UniqueCheckbox forwardedRef={ref} {...props} />
));
