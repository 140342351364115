import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import React, { Component, Fragment } from 'react';
import {
  foldAll,
  unFoldAll,
  selectNode,
  unSelectNode,
} from '../actions/dataroom.js';
import { addTemplateAction } from '../ducks/addTemplate';
import { validateAction } from '../ducks/validate';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { undo } from '../ducks/dataroomActions';
import 'translations/edm-dataroom/fr';
import 'translations/edm-dataroom/en';
import { EDITION_MODE_ALLOWED, EDITION_MODE_FORBIDDEN } from '../constants';
import ComponentActionBar from '../components/ActionBar';
import editionModeSelectorFromDataRoom from '../selectors/editionModeSelectorFromDataRoom';
import AddTemplate from '../components/AddTemplate';

class ActionBar extends Component {
  constructor(props) {
    super(props);
    this.hideSendMailModal = this.hideSendMailModal.bind(this);
    this.showAddTemplate = this.showAddTemplate.bind(this);
    this.hideAddTemplate = this.hideAddTemplate.bind(this);
    this.state = {
      showAddTemplateModal: false,
    };
    this.element = document.getElementById('after-wrapper-end');
  }

  hideSendMailModal() {
    // todo
  }

  showAddTemplate() {
    this.setState({ showAddTemplateModal: true });
  }

  hideAddTemplate() {
    this.setState({ showAddTemplateModal: false });
  }

  render() {
    const selectAll = () => {
      this.props.selectNode(this.props.rootNodeId);
    };

    const unSelectAll = () => {
      this.props.unSelectNode(this.props.rootNodeId);
    };

    const actions = [];
    const hiddenActions = [];

    if (this.props.lockedByMe) {
      actions.push({
        disabled: !this.props.canUndo,
        onClick: this.props.undo,
        buttonType: 'danger',
        icon: 'undo',
        confirmable: true,
        label: (
          <Fragment>
            {' '}
            Annuler{' '}
            <span className="hidden-xs hidden-sm">la dernière opération</span>
          </Fragment>
        ),
      });
    }

    if (this.props.editionMode !== EDITION_MODE_FORBIDDEN) {
      actions.push({
        disabled: this.props.validating,
        onClick: this.props.validateAction,
        buttonType: 'success',
        icon: 'save',
        label: (
          <Fragment>
            {' '}
            Analyser <span className="hidden-xs hidden-sm">la DR</span>
          </Fragment>
        ),
      });
    }

    actions.push({
      onClick: selectAll,
      buttonType: 'default',
      icon: 'check-square',
      label: Translator.trans('select', {}, 'edm-dataroom'),
    });

    actions.push({
      onClick: unSelectAll,
      buttonType: 'default',
      icon: 'square',
      label: Translator.trans('deselect', {}, 'edm-dataroom'),
    });

    actions.push({
      onClick: this.props.foldAll,
      buttonType: 'default',
      disabled: !this.props.loaded,
      icon: 'folder',
      label: Translator.trans('fold', {}, 'edm-dataroom'),
    });

    actions.push({
      onClick: this.props.unFoldAll,
      disabled: !this.props.loaded,
      buttonType: 'default',
      icon: 'folder-open-o',
      label: Translator.trans('unfold', {}, 'edm-dataroom'),
    });

    if (this.props.lockedByMe) {
      hiddenActions.push({
        label: "Ajouter un modèle d'arborescence à la fin",
        icon: 'level-up',
        onClick: this.showAddTemplate,
        disabled: this.props.editionMode === EDITION_MODE_FORBIDDEN,
      });
    }

    // todo

    return ReactDOM.createPortal(
      <Fragment>
        <ComponentActionBar hiddenActions={hiddenActions} actions={actions} />
        {this.props.lockedByMe && (
          <AddTemplate
            onHide={this.hideAddTemplate}
            show={this.state.showAddTemplateModal}
            onTemplateChosen={templateName => {
              this.props.addTemplateAction(templateName);
            }}
            addingTemplate={this.props.addingTemplate}
          />
        )}
      </Fragment>,
      this.element
    );
  }
}

function mapStateToProps(state, ownProps) {
  const props = {
    selectedNodesNumber: state.dataroom.selectedNodesNumber,
    selectedDocuments: [],
    rootNodeId: null,
    dataroomId: state.dataroom.dataroomId,
    lockedByMe: state.dataroom.working.lock.lockedByMe,
    validating: state.dataroom.working.validation.validating,
    canUndo:
      state.dataroom.working.lock.lockedByMe &&
      !state.dataroomActions.undoing &&
      state.dataroomActions.preparingActions.length === 0 &&
      (state.dataroomActions.successfulActions.length !== 0 ||
        !!state.dataroomActions.currentAction ||
        state.dataroomActions.pendingActions.length !== 0),
    isAdmin: ownProps.admin,
    loaded: state.dataroom.loaded,
    addingTemplate: state.dataroom.working.addTemplate.adding,
    editionMode: EDITION_MODE_FORBIDDEN,
  };

  if (state.dataroom.loaded) {
    //todo
    props.editionMode = editionModeSelectorFromDataRoom(state);
    props.rootNodeId = state.dataroom.working.rootNodeId;
  }

  return props;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      foldAll,
      unFoldAll,
      selectNode,
      addTemplateAction,
      validateAction,
      unSelectNode,
      undo,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ActionBar);
