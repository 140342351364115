import { channel } from 'redux-saga';
import { put, call, take, fork } from 'redux-saga/effects';
import Raven from 'raven-js';

export function* takeActionsSaga(actionsSaga) {
  const chan = yield call(channel);
  yield fork(executeActionsSaga, chan, actionsSaga);

  while (true) {
    let action = yield take(
      action => typeof actionsSaga[action.type] !== 'undefined'
    );
    yield put(chan, action);
  }
}

function* executeActionsSaga(chan, actionsSaga) {
  while (true) {
    const action = yield take(chan);

    yield put({
      action: action,
      type: `${action.type}_BEGIN`,
    });

    let data = {};
    try {
      const response = yield call(actionsSaga[action.type], action);

      const contentType = response.headers
        ? response.headers.get('content-type')
        : null;
      if (contentType && contentType.indexOf('application/json') !== -1) {
        data = yield call(() => response.json());
      }

      if (!response.ok) {
        const error = new Error();
        error.data = data;

        throw error;
      }
    } catch (error) {
      console.error(error);
      Raven.captureException(error);

      // TODO: all pending action should be canceled as there could be precedence, for example:
      //   - create folder node
      //   - create node in the folder
      yield put({
        ...data,
        type: `${action.type}_ERROR`,
        action,
      });
      continue;
    }

    yield put({
      ...data,
      type: `${action.type}_SUCCESS`,
      action,
    });
  }
}
