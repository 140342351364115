import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  cancelAttachFileAction,
  confirmAttachFileAction,
  attachFileToDocumentAction,
} from '../ducks/attachFileToDocument';
import { Modal } from 'react-bootstrap';

class ConfirmAttachFileModal extends Component {
  constructor(props) {
    super(props);
    this.addFiles = this.addFiles.bind(this);
  }

  addFiles() {
    this.props.attachFileToDocumentAction(this.props.nodeId, this.props.file);
    this.props.confirmAttachFileAction();
  }

  render() {
    const { open } = this.props;

    return (
      <Modal
        backdrop="static"
        show={open}
        onHide={this.props.cancelAttachFileAction}
      >
        <Modal.Header>
          <Modal.Title>Confirmation de remplacement de document</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <h3>
            Êtes vous sûr de vouloir remplacer le document attaché a la ligne ?
          </h3>
        </Modal.Body>

        <Modal.Footer>
          <button
            onClick={this.props.cancelAttachFileAction}
            type="button"
            data-dismiss="modal"
            className="btn btn-default md-close"
          >
            Annuler
          </button>
          <button
            onClick={this.addFiles}
            type="button"
            data-dismiss="modal"
            className="btn btn-primary md-close"
          >
            Valider
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return state.dataroom.working.confirmAttachFileModal;
}

export default connect(mapStateToProps, {
  cancelAttachFileAction,
  confirmAttachFileAction,
  attachFileToDocumentAction,
})(ConfirmAttachFileModal);
