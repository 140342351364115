import React, { Component } from 'react';

class TreeNodeCheckbox extends Component {
  render() {
    const checked = {
      checked: this.props.isSelected ? 'checked' : '',
    };

    return (
      <input {...checked} onChange={this.props.onChange} type="checkbox" />
    );
  }
}

export default TreeNodeCheckbox;
