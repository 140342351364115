import React, { Component } from 'react';
import { connect } from 'react-redux';
import AddNodes from '../components/AddNodes';
import { cancelAddNodesAction, addNodesAction } from '../ducks/addNodes';

export default connect(
  state => {
    if (!state.dataroom.working.addNodes.show) {
      return {};
    }

    return {
      node:
        state.dataroom.working.nodes[state.dataroom.working.addNodes.nodeId],
      position: state.dataroom.working.addNodes.position,
      groupType: state.dataroom.groupType,
    };
  },
  {
    cancelAddNodesAction,
    addNodesAction,
  }
)(AddNodes);
