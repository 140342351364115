import React, { Component, Fragment } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import 'translations/edm-dataroom/fr';
import 'translations/edm-dataroom/en';
import { Provider, useStore } from 'react-redux';
import { DUPLICATED_FILE } from '../ducks/validate';
import DuplicatedFileWarning from './DuplicatedFileWarning';

function Overlay(props) {
  const { validationErrors, validationWarnings } = props;

  let validationErrorsLength = 0;
  if (validationErrors) {
    validationErrorsLength = validationErrors.length;
  }

  let validationWarningsLength = 0;
  if (validationWarnings) {
    validationWarningsLength = validationWarnings.length;
  }

  return (
    <Fragment>
      {validationErrors &&
        validationErrors.map((validationError, index) => (
          <Fragment key={`err-${index}`}>
            - <b>{Translator.trans(validationError, {}, 'edm-dataroom')}</b>{' '}
            {index !== validationErrorsLength - 1 ? <br /> : null}
          </Fragment>
        ))}
      {validationWarnings &&
        validationWarnings.map((validationWarning, index) => (
          <Fragment key={`wng-${index}`}>
            {validationWarning.name === DUPLICATED_FILE && (
              <DuplicatedFileWarning
                warning={validationWarning}
                index={index}
                validationWarningsLength={validationWarningsLength}
                nodes={[]}
              />
            )}
            {validationWarning.name !== DUPLICATED_FILE && (
              <Fragment>
                -{' '}
                <b>
                  {Translator.trans(validationWarning.name, {}, 'edm-dataroom')}{' '}
                </b>{' '}
                {index !== validationWarningsLength - 1 ? <br /> : null}
              </Fragment>
            )}
          </Fragment>
        ))}
    </Fragment>
  );
}

function ValidationErrors(props) {
  const { tooltipId } = props;
  const store = useStore();

  return (
    <OverlayTrigger
      placement="right"
      overlay={
        <Tooltip id={tooltipId}>
          <Provider store={store}>
            <Overlay {...props} />
          </Provider>
        </Tooltip>
      }
    >
      <i className="fa fa-exclamation-triangle" aria-hidden="true" />
    </OverlayTrigger>
  );
}

export default ValidationErrors;
