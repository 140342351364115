import styled from 'styled-components';
import {
  PICKED_COLOR,
  PICKED_BACKGROUND_COLOR,
  PRIMARY_COLOR,
  DROP_SHADOW_COLOR,
  FORM_FIELDS_BORDER_COLOR,
  LIGHT_COLOR,
  DANGER_COLOR,
  SECONDARY_COLOR,
  WARNING_COLOR,
} from '../theme_blue';

export default styled.div`
  border-style: solid;
  border-width: ${props => {
    if (props.currentErroredPage) {
      return '2px';
    }
    return '1px';
  }};
  border-color: ${props => {
    if (props.draggin) {
      return props.theme[PRIMARY_COLOR];
    }
    if (props.picked) {
      return props.theme[PICKED_COLOR];
    }
    if (props.currentErroredPage) {
      return props.theme[DANGER_COLOR];
    }
    return props.theme[FORM_FIELDS_BORDER_COLOR];
  }};
  background-color: ${props => {
    if (props.draggin || props.picked) {
      return props.theme[PICKED_BACKGROUND_COLOR];
    }
    if (props.error) {
      return props.theme[WARNING_COLOR];
    }
    return props.theme[LIGHT_COLOR];
  }};
  display: block;
  text-align: center;
  margin: 1em 1em 1em;
  padding: 0.5em 0.5em 0;
  cursor: ${props => {
    if (props.draggin) {
      return 'grabbing';
    }
    if (props.picked) {
      return 'move';
    }
    return 'pointer';
  }};
  position: relative;
  align-self: flex-end;
  ${props =>
    props.draggin
      ? 'opacity: .75; box-shadow: 0 0 1.25rem ' +
        props.theme[DROP_SHADOW_COLOR] +
        ';'
      : null}
  ${props =>
    props.currentErroredPage
      ? 'color: ' + props.theme[DANGER_COLOR] + ';'
      : null}
`;
