import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TreeNode from './TreeNode';
import { loadDataroom, loadDataroomNodes } from '../actions/dataroom.js';
import Lock from '../components/Lock';
import { resetPickedNodesAction } from '../ducks/pickNode';
import { lockDataroom, unLockDataroom } from '../ducks/lock';
import canEditDataroom from '../selectors/canEditDataroom';
import editionModeSelectorFromDataRoom from '../selectors/editionModeSelectorFromDataRoom';
import { EDITION_MODE_FORBIDDEN } from '../constants';
import ScrollingDrag from '../../app/components/React-dnd/ScrollingDrag';
import { AuthDataContext } from '../../../app/hooks/authDataProvider';
import { intersection } from 'underscore';

const queryString = require('qs');

class RootNode extends Component {
  static contextType = AuthDataContext;

  constructor(props) {
    super(props);
    this.shouldComponentUpdate = this.shouldComponentUpdate.bind(this);
    this.loadIfNeeded = this.loadIfNeeded.bind(this);
    this.listenClick = this.listenClick.bind(this);
    const show = queryString.parse(window.location.search.substring(1)).show;
    if (show) {
      this.hasScrolled = false;
      this.show = show;
      this.componentDidUpdate = this.scrollToElement.bind(this);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextProps !== this.props || nextState !== this.state;
  }

  loadIfNeeded(props) {
    const {
      id,
      anonymous,
      hasToLoad,
      dataroomLoaded,
      dataroomLoading,
      nodesLoaded,
      nodesLoading,
    } = props;

    if (!hasToLoad) {
      return;
    }

    if (!dataroomLoaded && !dataroomLoading) {
      return this.props.loadDataroom(id, anonymous);
    }

    if (dataroomLoading) {
      return;
    }

    if (!nodesLoaded && !nodesLoading) {
      return this.props.loadDataroomNodes(id);
    }
  }

  listenClick(e) {
    if (e.target.classList.contains('react-contextmenu-item')) {
      return;
    }

    if (!this.props.isLargeDataroom && this.props.document !== null) {
      return;
    }

    if (!e.target.parentNode) {
      return;
    }

    if (!e.target.parentNode.classList) {
      return;
    }

    if (e.target.parentNode.classList.contains('react-contextmenu-item')) {
      return;
    }

    if (e.target.parentNode.classList.contains('close-page--button')) {
      return;
    }

    if (
      this.props.forwardedRef.current &&
      !this.props.forwardedRef.current.contains(e.target)
    ) {
      this.props.resetPickedNodesAction();
    }
  }

  componentDidMount() {
    this.loadIfNeeded(this.props);
    document.addEventListener('click', this.listenClick);
  }

  componentWillUnMount() {
    document.removeEventListener('click', this.listenClick);
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.validating &&
      !nextProps.validating &&
      (nextProps.firstNodeWithErrorId || nextProps.firstNodeWithWarningId)
    ) {
      const element = document.getElementById(nextProps.firstNodeWithErrorId);
      if (element) {
        element.scrollIntoView();
        return;
      }

      const elementWarning = document.getElementById(
        nextProps.firstNodeWithWarningId
      );
      if (elementWarning) {
        elementWarning.scrollIntoView();
      }
    }

    this.loadIfNeeded(nextProps);
  }

  scrollToElement() {
    if (this.hasScrolled || !this.props.nodesLoaded) {
      return;
    }
    const element = document.getElementById(this.show);

    if (!element) {
      return;
    }
    element.scrollIntoView();
    this.hasScrolled = true;
  }

  render() {
    const {
      rootNodeId,
      dataroomName,
      dataroomLoaded,
      nodesLoaded,
      nodesLoading,
      dataroomLoading,
      dataroomType,
      currentDataroomType,
      canEditDataroom,
      forwardedRef,
      corrupted,
      locking,
      unlocking,
      lockedByMe,
      lockBy,
      lockDataroom,
      unLockDataroom,
      editionMode,
    } = this.props;

    let treeView = null;

    if (
      !dataroomLoading &&
      dataroomLoaded &&
      !nodesLoading &&
      nodesLoaded &&
      dataroomType === currentDataroomType
    ) {
      treeView = (
        <ol>
          <TreeNode anonymous={this.props.anonymous} nodeId={rootNodeId} />
        </ol>
      );
    } else {
      treeView = <ol />;
    }

    return (
      <ScrollingDrag>
        <section>
          <section className="section-treeview">
            <div
              ref={forwardedRef}
              className="panel panel-default dataroom-treeview-panel"
            >
              <div className="panel-body">
                <div
                  className={`dataroom-treeview ${
                    canEditDataroom ? 'locked' : ''
                  }`}
                >
                  <ol>
                    <li className="i f open">
                      {!this.props.anonymous && this.props.canWrite && (
                        <Lock
                          disabled={corrupted || locking || unlocking}
                          canUnlock={this.props.canUnlock || lockedByMe}
                          lock={() => lockDataroom(this.context.user)}
                          unlock={unLockDataroom}
                          lockBy={lockBy}
                          lockedByMe={lockedByMe}
                        />
                      )}
                      <strong> {dataroomName} </strong>
                      {treeView}
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </section>
        </section>
      </ScrollingDrag>
    );
  }
}

function mapStateToProps(state, props) {
  const newProps = {
    ...props,
    dataroomLoaded: state.dataroom.loaded,
    dataroomLoading: state.dataroom.loading,
    nodesLoaded: state.dataroom.working.loaded,
    nodesLoading: state.dataroom.working.loading,
    lockBy: state.dataroom.working.lock.lockBy,
    lockedByMe: state.dataroom.working.lock.lockedByMe,
    locking: state.dataroom.working.lock.locking,
    unlocking: state.dataroom.working.lock.unlocking,
    rootNodeId: null,
    validating: state.dataroom.working.validation.validating,
    firstNodeWithErrorId:
      state.dataroom.working.validation.firstNodeWithErrorId,
    firstNodeWithWarningId:
      state.dataroom.working.validation.firstNodeWithWarningId,
    corrupted: state.dataroom.working.corrupted,
    isLargeDataroom: state.dataroom.isLargeDataroom,
    document: state.document.document,
    editionMode: EDITION_MODE_FORBIDDEN,
    canUnlock: false,
    canWrite: false,
  };

  if (state.dataroom.working.loaded) {
    newProps.rootNodeId = state.dataroom.working.rootNodeId;
    newProps.canUnlock = state.dataroom.dataroom.rights.canUnlock;
    newProps.canWrite = state.dataroom.dataroom.rights.canWrite;
    newProps.canEditDataroom = canEditDataroom(state);
  }

  if (state.dataroom.loaded) {
    newProps.dataroomName = state.dataroom.dataroom.name;
    newProps.canUnlock = state.dataroom.dataroom.rights.canUnlock;
    newProps.canWrite = state.dataroom.dataroom.rights.canWrite;
    newProps.editionMode = editionModeSelectorFromDataRoom(state);
  }

  return newProps;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loadDataroom,
      loadDataroomNodes,
      lockDataroom,
      unLockDataroom,
      resetPickedNodesAction,
    },
    dispatch
  );
}

RootNode = connect(mapStateToProps, mapDispatchToProps, null)(RootNode);

export default RootNode;
