import React, { Component } from 'react';
import ReactDOM from 'react-dom';

class PleaseWait extends Component {
  constructor(props) {
    super(props);
    this.element = document.getElementById('please-wait-wrapper');
    this.state = {
      waiting: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.waiting && nextProps.waiting) {
      this.timeout = setTimeout(() => {
        this.setState({
          waiting: true,
        });
      }, 250);
    }

    if (this.props.waiting && !nextProps.waiting) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }

      this.setState({
        waiting: false,
      });
    }
  }

  render() {
    const { waiting } = this.state;

    if (!waiting) {
      return null;
    }

    return ReactDOM.createPortal(
      <div id="please-wait">
        <div className="lbl">{this.props.text}</div>
        <i className="fa fa-spinner fa-pulse fa-fw" />
      </div>,
      this.element
    );
  }
}

PleaseWait.defaultProps = {
  text: 'Traitement en cours…',
};

export default PleaseWait;
