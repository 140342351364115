import DataroomStateRepository from '../../repository/DataroomStateRepository';
import { has, indexBy, omit } from 'underscore';
import clipBoardRepository from '../../repository/ClipBoardRepository';
import findLastDocuments from '../../documents/findLastDocuments';
import { TYPE_DOCUMENT, TYPE_FOLDER } from '../../documents/type';
import childNode from '../../ducks/utils/childNode';
import getParentPath from '../../ducks/utils/getParentPath';
import getPathLevel from '../../ducks/utils/getPathLevel';
import getPosition from '../../ducks/utils/getPosition';

import queryString from 'qs';
import { unfoldParentNodes } from './nodeFold';

export function loadDataroom(action, state) {
  const dataroomNodesTypeState = loadNodesState(
    action.dataroom.nodes,
    action.dataroom.id
  );

  const queryParams = queryString.parse(window.location.search.substring(1));

  if (typeof queryParams.show != 'undefined') {
    dataroomNodesTypeState.nodes = unfoldParentNodes(
      dataroomNodesTypeState.nodes,
      action.dataroom.id,
      queryParams.show
    );
  }

  delete action.dataroom.nodes;

  let newState = {
    ...state,
    dataroom: omit(
      action.dataroom,
      'asset',
      'clientGroupDetailView',
      'buyerGroupDetailView',
      'lock'
    ),
    loading: false,
    loaded: true,
    working: {
      ...state.working,
      ...dataroomNodesTypeState,
      lock: {
        ...state.working.lock,
        lockBy: action.dataroom.lock,
      },
      rights: action.dataroom.rights,
    },
  };

  return newState;
}

export function loadDataroomNodes(action, state) {
  return {
    ...state,
    working: {
      ...state.working,
      ...loadNodesState(action.nodes, state.dataroom.id),
    },
  };
}

function loadNodesState(nodesList, dataroomId) {
  let { nodes, rootNodeId, dataroomCorrupted } = normalizeNodes(
    dataroomId,
    nodesList
  );

  const nodesState = {
    loading: false,
    loaded: true,
    nodes,
    rootNodeId,
    lastDocuments: findLastDocuments(nodes),
    corrupted: dataroomCorrupted,
  };

  nodesState.nodeClipBoard = clipBoardRepository.getClipBoard();

  return nodesState;
}

export function addDocumentNumberForNodes(nodes, nodeId) {
  const node = nodes[nodeId];
  if (node.type === TYPE_DOCUMENT) {
    return;
  }

  node.documentWithFileNumber = node.childIds.reduce((acc, childId) => {
    if (nodes[childId].type === TYPE_DOCUMENT) {
      return nodes[childId].file ? acc + 1 : acc;
    }

    return addDocumentNumberForNodes(nodes, childId) + acc;
  }, 0);

  return node.documentWithFileNumber;
}

export function addDocumentWithoutFileNumberForNodes(nodes, nodeId) {
  const node = nodes[nodeId];
  if (node.type === TYPE_DOCUMENT) {
    return;
  }

  node.documentWithoutFileNumber = node.childIds.reduce((acc, childId) => {
    if (nodes[childId].type === TYPE_DOCUMENT) {
      return nodes[childId].file ? acc : acc + 1;
    }

    return addDocumentWithoutFileNumberForNodes(nodes, childId) + acc;
  }, 0);

  return node.documentWithoutFileNumber;
}

const findParentNodeForNonConnectedNode = (path, nodes) => {
  const parentPath = getParentPath(path);

  const nodesWithParentPathOfTypeFolder = nodes.find(
    node => node.path === parentPath && node.type === TYPE_FOLDER
  );

  if (nodesWithParentPathOfTypeFolder) {
    return nodesWithParentPathOfTypeFolder;
  }

  return findParentNodeForNonConnectedNode(getParentPath(path), nodes);
};

const findChildInsertionPositionForPath = (childIds, path, nodesById) => {
  const pathLevelNodeToInsert = getPathLevel(path);
  const positionNodeToInsert = getPosition(pathLevelNodeToInsert, path);

  let index = 0;
  for (let childId of childIds) {
    const childPathLevel = getPathLevel(nodesById[childId].path);

    if (childPathLevel !== pathLevelNodeToInsert) {
      index += 1;
      continue;
    }

    if (
      positionNodeToInsert <
      getPosition(childPathLevel, nodesById[childId].path)
    ) {
      return index;
    }

    index += 1;
  }

  // insert last
  return index;
};

const fixNonConnectedNodes = (nodes, connectedNodes) => {
  const nodesById = indexBy(nodes, 'id');

  const nonConnectedNodes = nodes.filter(
    node =>
      connectedNodes.findIndex(
        connectedNode => connectedNode.id === node.id
      ) === -1 && node.path !== '0'
  );

  nonConnectedNodes.forEach(node => {
    if (node.type === TYPE_FOLDER) {
      node.childIds = [];
    }

    const parentNode = findParentNodeForNonConnectedNode(node.path, nodes);

    if (!parentNode.childIds) {
      parentNode.childIds = [];
    }

    const childIndex = findChildInsertionPositionForPath(
      parentNode.childIds,
      node.path,
      nodesById
    );

    parentNode.childIds.splice(childIndex, 0, node.id);

    node.parentId = parentNode.id;
    node.validationErrors = ['ERREUR DE NUMÉROTATION'];
  });
};

export const normalizeNodes = (dataroomId, nodes) => {
  const nodesState = new DataroomStateRepository(dataroomId).getNodesState();
  let dataroomCorrupted = false;

  const nodesByPath = indexBy(nodes, 'path');

  const normalizedNodes = nodes.map(node =>
    normalizeNode(
      node,
      nodesByPath,
      has(nodesState, node.id) ? nodesState[node.id] : null
    )
  );

  let indexedNodes = indexBy(normalizedNodes, 'id');

  const connectedNodes = Array.from(
    childNode(indexedNodes, nodesByPath['0'].id)
  );
  // the + 1 is for the root node
  if (Object.values(normalizedNodes).length !== connectedNodes.length + 1) {
    dataroomCorrupted = true;
    fixNonConnectedNodes(normalizedNodes, connectedNodes);
  }

  addDocumentNumberForNodes(indexedNodes, nodesByPath['0'].id);
  addDocumentWithoutFileNumberForNodes(indexedNodes, nodesByPath['0'].id);

  return {
    nodes: indexedNodes,
    rootNodeId: nodesByPath['0'].id,
    dataroomCorrupted,
  };
};

export const normalizeNode = (
  node,
  nodesByPath,
  nodeFoldState,
  rootNode = '0'
) => {
  let nodeState = {
    folded: false,
    selected: false,
    visible: true,
    matched: false,
    childIds: [],
  };

  if (node.path !== rootNode) {
    nodeState = {
      ...nodeState,
      parentId: getParentNodeId(node, nodesByPath),
    };
  }

  if (node.type === TYPE_FOLDER) {
    nodeState = {
      ...nodeState,
      folded: node.path !== '0',
      childIds: getChildNodeIds(node, nodesByPath),
    };
  }

  if (nodeFoldState) {
    nodeState = {
      ...nodeState,
      ...nodeFoldState,
    };
  }

  const normalizedNode = {
    ...node,
    ...nodeState,
  };

  if (node.file) {
    normalizedNode.file.editedAt = new Date(node.file.editedAt * 1000);
  }

  return normalizedNode;
};

const getChildNodeIds = (node, nodesByPath) => {
  const basePath = node.path;
  let currentSubPath = 1;
  const childIds = [];

  while (true) {
    let childPath = null;
    if (node.path === '0') {
      childPath = formatNumber(currentSubPath);
    } else {
      childPath = `${basePath}.${formatNumber(currentSubPath)}`;
    }

    if (!has(nodesByPath, childPath)) {
      break;
    }

    childIds.push(nodesByPath[childPath].id);
    currentSubPath++;
  }

  return childIds;
};

const getParentNodeId = (node, nodesByPath) => {
  const parentPath = getParentPath(node.path);
  if (nodesByPath[parentPath]) {
    return nodesByPath[parentPath].id;
  }

  return null;
};

const formatNumber = number => {
  const formatedNumber = number.toString();

  if (number > 9) {
    return formatedNumber;
  }

  return `0${formatedNumber}`;
};
