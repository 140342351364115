import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Alert, ProgressBar } from 'react-bootstrap';
import { allowLeave, blockLeave } from 'js/dataroom/core/urlLeaveBlocker';
import {
  getUploadedDocumentsSelector,
  getUploadedSizeSelector,
  getUploadedTotalSizeSelector,
  getUploadingDocumentsSelector,
} from '../selectors/uploaderSelector';

class UploadingInfo extends PureComponent {
  componentWillReceiveProps(nextProps) {
    const { uploads } = this.props;

    if (uploads.length === 0 && nextProps.uploads.length !== 0) {
      blockLeave();
      return;
    }

    if (uploads.length !== 0 && nextProps.uploads.length === 0) {
      allowLeave();
      return;
    }
  }

  render() {
    const { uploads } = this.props;

    if (uploads.length === 0) {
      return null;
    }

    const label =
      this.props.uploadedDocuments + '/' + this.props.uploadingDocuments;

    return (
      <Alert bsStyle="warning">
        <p>
          {' '}
          <i className="fa-spin fa fa-spinner" aria-hidden="true" /> UPLOAD DE
          DOCUMENTS EN COURS
        </p>
        <ProgressBar
          active
          now={this.props.uploadedSize}
          max={this.props.uploadedTotalSize}
          label={label}
        />
      </Alert>
    );
  }
}

function mapStateToProps(state) {
  return {
    uploads: state.dataroom.working.uploads,
    uploadedDocuments: getUploadedDocumentsSelector(state),
    uploadingDocuments: getUploadingDocumentsSelector(state),
    uploadedSize: getUploadedSizeSelector(state),
    uploadedTotalSize: getUploadedTotalSizeSelector(state),
  };
}

export default connect(mapStateToProps, {})(UploadingInfo);
