import PropTypes from 'prop-types';
import React, { Component } from 'react';
import FileTab from '../containers/FileTab.js';
import 'translations/edm-dataroom/fr';
import 'translations/edm-dataroom/en';
import 'translations/discussion/fr';
import 'translations/discussion/en';
import { connect } from 'react-redux';
import editionModeSelectorFromDataRoom from '../../dataroom/selectors/editionModeSelectorFromDataRoom';
import canEditDataroom from '../../dataroom/selectors/canEditDataroom';
import editionModeSelectorFromDocument from '../../dataroom/selectors/editionModeSelectorFromDocument';

export const FILE_TAB = 'FILE_TAB';
export const COMMENTS_TAB = 'COMMENTS_TAB';
export const QUESTIONS_TAB = 'QUESTIONS_TAB';

class DocumentTabs extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      selected,
      fileTabActivated,
      hasFileTab,
      isExtraLargeScreen,
    } = this.props;

    let fileTabClass = '';
    if (!fileTabActivated) {
      fileTabClass += ' disabled';
    }
    if (selected == FILE_TAB) {
      fileTabClass += ' active';
    }

    return (
      <div className="tab-container vertically-expanded">
        <ul className="nav nav-tabs">
          {hasFileTab && (
            <li className={fileTabClass}>
              <a data-toggle="tab" aria-expanded="true">
                {' '}
                <i className="fa fa-file" />
                {Translator.trans('document', {}, 'edm-dataroom')}
              </a>
            </li>
          )}
        </ul>
        <div className="tab-content">
          {hasFileTab && fileTabActivated && (
            <FileTab
              dataroomType={this.props.dataroomType}
              admin={this.props.admin}
            />
          )}
        </div>
      </div>
    );
  }
}

DocumentTabs.propTypes = {
  selected: PropTypes.string.isRequired,
  fileTabActivated: PropTypes.bool.isRequired,
  hasFileTab: PropTypes.bool.isRequired,
  admin: PropTypes.bool.isRequired,
};

export default connect()(DocumentTabs);
