import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import getSelectedNodes from '../selectors/getSelectedNodes';
import { addNotificationAction } from '../../app/actions/notifications';
import {
  exportDataroomAction,
  shareExportDataroomAction,
  openShareDataroomModalAction,
  closeShareDataroomModalAction,
} from '../ducks/exportDataroom';
import Button from '../../app/components/Button';
import {
  EXPORT_TYPE_NOTARY_CHAMBER,
  EXPORT_TYPE_FOLDER,
  EXPORT_TYPE_INTRALINK,
} from '../constants';
import { Dropdown, MenuItem, ButtonGroup } from 'react-bootstrap';
import Action from '../../app/components/Action';
import getTotalFileSizeForSelectedNodes from '../selectors/getTotalFileSizeForSelectedNodes';
import PleaseWait from '../components/PleaseWait';

class ExportButton extends Component {
  constructor(props) {
    super(props);
    this.getButtonComponent = this.getButtonComponent.bind(this);
  }

  getButtonComponent() {
    const {
      selectedNodes,
      dataroomId,
      exporting,
      estimatedFileSize,
    } = this.props;

    return (
      <ButtonGroup vertical block>
        <Dropdown
          disabled={exporting || selectedNodes.length === 0}
          bsStyle="primary"
          pullRight
        >
          <Dropdown.Toggle bsSize="small" bsStyle="primary">
            <i className="pull-left fa fa-2x fa-cloud-download" />
            {Translator.trans('export')} (~{estimatedFileSize} Mo)
          </Dropdown.Toggle>
          <Dropdown.Menu className="btn-block">
            <MenuItem
              onClick={() =>
                this.props.exportDataroomAction(
                  EXPORT_TYPE_FOLDER,
                  selectedNodes,
                  dataroomId,
                  this.props.admin
                )
              }
              disabled={exporting || selectedNodes.length === 0}
              eventKey="3"
            >
              Export BRUT
            </MenuItem>
            <MenuItem
              onClick={() =>
                this.props.exportDataroomAction(
                  EXPORT_TYPE_NOTARY_CHAMBER,
                  selectedNodes,
                  dataroomId,
                  this.props.admin
                )
              }
              disabled={exporting || selectedNodes.length === 0}
              eventKey="3"
            >
              Export chambre des notaires
            </MenuItem>
            <MenuItem
              onClick={() =>
                this.props.exportDataroomAction(
                  EXPORT_TYPE_INTRALINK,
                  selectedNodes,
                  dataroomId,
                  this.props.admin
                )
              }
              disabled={exporting || selectedNodes.length === 0}
              eventKey="3"
            >
              Export INTRALINK
            </MenuItem>
          </Dropdown.Menu>
        </Dropdown>
      </ButtonGroup>
    );
  }

  render() {
    const { exporting } = this.props;

    return (
      <Fragment>
        <PleaseWait waiting={exporting} text={'Export en cours…'} />
        {this.getButtonComponent()}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  if (!state.dataroom.loaded) {
    return {
      selectedNodes: [],
      estimatedFileSize: 0,
    };
  }

  let estimatedFileSize = getTotalFileSizeForSelectedNodes(state);

  let exporting = state.dataroom.working.exporting;

  return {
    exporting,
    selectedNodes: getSelectedNodes(state),
    estimatedFileSize,
  };
}

export default connect(mapStateToProps, {
  exportDataroomAction,
  addNotificationAction,
  openShareDataroomModalAction,
  closeShareDataroomModalAction,
  shareExportDataroomAction,
})(ExportButton);
