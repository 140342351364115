import React, { Fragment, useMemo } from 'react';
import Helmet from 'react-helmet';
import Layout from '../../app/layout/Layout';
import { Menu } from '../../app/components/menu/Menu';
import Content from '../../app/layout/Content';
import Notifications from '../../dataroom/app/containers/Notifications';
import Span from '../../presentational/components/Span';
import {
  BODY_FONT,
  CLIENT_UI_COLOR,
  DANGER_COLOR,
} from '../../presentational/components/theme_blue';
import H2 from '../../presentational/components/H2';
import IconUser from '../../presentational/components/img/icons/IconUser';
import UserForm from '../components/user/UserForm';
import queryString from 'qs';
import { useQuery } from '../../app/hooks/apiClient';

export default function UserEdition(props) {
  const url = useMemo(() => {
    return `/api/user/${props.routeParams.userId}?${queryString.stringify({
      resources: ['USER_DATAROOMS'],
    })}`;
  }, [props.routeParams.userId]);

  const [loaded, , data] = useQuery(url);

  return (
    <Fragment>
      <Helmet>
        <title>Espace client - Edition d'un utilisateur</title>
      </Helmet>

      <Layout>
        <Menu users />

        <Content>
          <H2>
            <Span
              color={CLIENT_UI_COLOR}
              fontFamily={BODY_FONT}
              display="flex"
              alignItems="center"
              justifyContent="center"
              minWidth="100%"
            >
              <IconUser marginRight=".5em" marginBottom="-.1em" />
              Edition d'un utilisateur
            </Span>
          </H2>
          {loaded && (
            <UserForm
              user={data}
              noPassword
              defaultValues={{
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
                role: data.roles[0],
              }}
            />
          )}
        </Content>
      </Layout>

      <Notifications />
    </Fragment>
  );
}
