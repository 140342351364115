import React, { Component, Fragment } from 'react';
import {
  ContextMenu as ReactContextContextMenu,
  MenuItem,
  SubMenu,
} from 'react-contextmenu';
import '../components/ContextMenu.css';
import { connect } from 'react-redux';
import { canAddForSelectedNodeInContextMenu as canAdd } from '../selectors/canAdd';
import { EDITION_MODE_ALLOWED, EDITION_MODE_FORBIDDEN } from '../constants';
import { removeEmptyDocumentNodesAction } from '../ducks/removeDocumentNodeWithoutFile';
import { openPdfMergedAction } from '../../pdfMerged/reducers/pdfMerged';
import { showAddNodesFormAction } from '../ducks/addNodes';
import { prepareTransformArchiveNodeToFolderAction } from '../ducks/transformArchiveNodeToFolder';
import { openModalAction as openAttachFileModal } from '../ducks/attachFileToDocument';
import { nest } from 'recompose';
import { canDeleteEmptyFolderNodeFromParentFolderForSelectedNodeInContextMenu as canDeleteEmptyFolderNodes } from '../selectors/canDeleteEmptyFolderNodeFromParentFolder';
import { removeEmptyFolderNodesAction } from '../ducks/removeEmptyFolderNodes';
import { removeNodesAction } from '../ducks/removeNodes';
import { openPdfEditor } from '../actions/dataroom';
import { nodeMenuClosed, nodeMenuOpened } from '../ducks/nodeMenu';
import { addNotificationAction } from '../../app/actions/notifications';
import { createSelector } from 'reselect';
import nodesSelector from '../selectors/workingNodesSelector';
import {
  getPathAfter,
  getPathBefore,
  getPathInside,
  getParentPath,
} from '../selectors/getNextPathForNode';
import {
  changeClipboard,
  TYPE_COPY,
  TYPE_CUT,
  pasteNode,
} from '../ducks/nodesClipBoard.js';
import canEditDataroom from '../selectors/canEditDataroom';
import selectedNodesInContextMenuSelector from '../selectors/selectedNodesInContextMenu';

const clipBoardSelector = state => state.dataroom.working.nodeClipBoard;
import { lockDataroom, unLockDataroom } from '../ducks/lock';
import ErrorBoundary from '../components/ErrorBoundary';
import canMergedDocumentNodes from '../selectors/canMergedDocumentNodes';
import { store } from '../../app/createStore';
import { moveNodesAction } from '../ducks/moveNodes';
import { openPdfExplodeAction } from '../../pdfExplode/reducers/pdfExplode';
import { canRenameFromContextMenu } from '../selectors/canRenameNode';
import { nodeNameEditableAction } from '../ducks/renameNode';
import { changeNodeTypeAction } from '../ducks/changeNodeType';
import { TYPE_DOCUMENT, TYPE_FOLDER } from '../documents/type';
import { canChangeTypeForSelectedNodesInContextMenu as canChangeType } from '../selectors/canChangeTypeSelector';
import { detachFileToDocumentAction } from '../ducks/detachFileToDocument';
import { AFTER, BEFORE, INSIDE } from '../ducks/utils/getNextPath';
import editionModeSelectorFromDataRoom from '../selectors/editionModeSelectorFromDataRoom';
import originalSelectedNodeIdInContextMenuSelector from '../selectors/originalSelectedNodeIdInContextMenuSelector';
import { AuthDataContext } from '../../../app/hooks/authDataProvider';

const createCanPasteState = (before, after, inside = false) => ({
  before,
  after,
  inside,
});

const oneOfNodesIsParentOf = (sourceNodes, destinationNode) => {
  for (let sourceNode of sourceNodes) {
    if (destinationNode.path.startsWith(`${sourceNode.path}.`)) {
      return true;
    }
  }

  return false;
};

const currentDataroomIdSelector = state => state.dataroom.dataroom.id;

const canPaste = createSelector(
  [
    nodesSelector,
    clipBoardSelector,
    originalSelectedNodeIdInContextMenuSelector,
    currentDataroomIdSelector,
  ],
  (nodes, clipboard, originalSelectedNodeIdInContextMenu, dataroomId) => {
    if (!originalSelectedNodeIdInContextMenu) {
      return createCanPasteState(false, false);
    }

    if (!nodes[originalSelectedNodeIdInContextMenu]) {
      return createCanPasteState(false, false);
    }

    if (!clipboard.nodeIds || clipboard.nodeIds.length === 0) {
      return createCanPasteState(false, false);
    }

    if (dataroomId !== clipboard.dataroomId && clipboard.type === TYPE_CUT) {
      return createCanPasteState(false, false);
    }

    const destinationNode = nodes[originalSelectedNodeIdInContextMenu];

    // don't allow paste if one of node of clipboard does not exist
    if (dataroomId === clipboard.dataroomId) {
      for (let nodeId of clipboard.nodeIds) {
        if (typeof nodes[nodeId] === 'undefined') {
          return createCanPasteState(false, false, false);
        }
      }
    }

    if (destinationNode.path === '0') {
      return createCanPasteState(false, false, true);
    }

    if (clipboard.type === TYPE_COPY) {
      return createCanPasteState(
        true,
        true,
        destinationNode.type === TYPE_FOLDER
      );
    }

    if (clipboard.nodeIds.includes(destinationNode.id)) {
      return createCanPasteState(false, false, false);
    }

    const copiedNodes = clipboard.nodeIds.map(nodeId => nodes[nodeId]);

    const oneOfNodesIsParent = oneOfNodesIsParentOf(
      copiedNodes,
      nodes[destinationNode.parentId]
    );

    const canPastBefore = !oneOfNodesIsParent;
    const canPastAfter = !oneOfNodesIsParent;
    let canPastInside = false;
    if (destinationNode.type === TYPE_FOLDER) {
      canPastInside = !oneOfNodesIsParentOf(copiedNodes, destinationNode);
    }

    return createCanPasteState(canPastBefore, canPastAfter, canPastInside);
  }
);

const getPastePosition = props => {
  const { withFile, node, canPaste, pasteAction, canAdd } = props;

  return (
    <Fragment>
      {
        <MenuItem
          disabled={!canPaste.before || !canAdd.before}
          onClick={pasteAction}
          data={{ position: 'BEFORE', withFile }}
        >
          <i className="fa fa-arrow-up" aria-hidden="true" /> Coller au dessus{' '}
          <i>({props.nextPathBefore})</i>
        </MenuItem>
      }
      {node.type === TYPE_FOLDER && (
        <MenuItem
          disabled={!canPaste.inside || !canAdd.inside}
          onClick={pasteAction}
          data={{ position: 'INSIDE', withFile }}
        >
          <i className="fa fa-arrow-right" aria-hidden="true" /> Coller à
          l'intérieur <i>({props.nextPathInside})</i>
        </MenuItem>
      )}
      {
        <MenuItem
          disabled={!canPaste.after || !canAdd.after}
          onClick={pasteAction}
          data={{ position: 'AFTER', withFile }}
        >
          <i className="fa fa-arrow-down" aria-hidden="true" /> Coller au
          dessous <i>({props.nextPathAfter})</i>
        </MenuItem>
      }
    </Fragment>
  );
};

const Paste = props => {
  const {
    pasteAction,
    node,
    clipboardType,
    canPaste,
    canAdd,
    nextPathBefore,
    nextPathInside,
    nextPathAfter,
  } = props;

  let pasteElement = null;

  if (clipboardType === TYPE_COPY) {
    pasteElement = (
      <Fragment>
        <SubMenu
          disabled={!canPaste.before && !canPaste.inside && !canPaste.after}
          hoverDelay={100}
          title={
            <span>
              <i className="fa fa-file-pdf-o" aria-hidden="true" /> Avec les
              fichiers{' '}
            </span>
          }
        >
          {getPastePosition({
            node,
            pasteAction,
            canPaste,
            canAdd,
            withFile: true,
            nextPathBefore,
            nextPathInside,
            nextPathAfter,
          })}
        </SubMenu>
        <SubMenu
          disabled={!canPaste.before && !canPaste.inside && !canPaste.after}
          hoverDelay={100}
          title={
            <span>
              <i className="fa fa-file-o" aria-hidden="true" /> Sans les
              fichiers{' '}
            </span>
          }
        >
          {getPastePosition({
            node,
            pasteAction,
            canPaste,
            canAdd,
            nextPathBefore,
            nextPathInside,
            nextPathAfter,
          })}
        </SubMenu>
      </Fragment>
    );
  } else {
    pasteElement = getPastePosition({
      node,
      pasteAction,
      canPaste,
      canAdd,
      nextPathBefore,
      nextPathInside,
      nextPathAfter,
    });
  }

  return (
    <SubMenu
      disabled={!canPaste.before && !canPaste.inside && !canPaste.after}
      hoverDelay={100}
      title={
        <span>
          <i className="fa fa-clipboard" aria-hidden="true" /> Coller{' '}
        </span>
      }
    >
      {pasteElement}
    </SubMenu>
  );
};

export class ContextMenu extends Component {
  static contextType = AuthDataContext;

  constructor(props) {
    super(props);
    this.copyNode = this.copyNode.bind(this);
    this.cutNode = this.cutNode.bind(this);
    this.pasteNode = this.pasteNode.bind(this);
    this.onShow = this.onShow.bind(this);
    this.onHide = this.onHide.bind(this);
    this.removeNode = this.removeNode.bind(this);
    this.moveSelectedNodeBeforeParent = this.moveSelectedNodeBeforeParent.bind(
      this
    );
    this.changeNodeType = this.changeNodeType.bind(this);
    this.mergedDocuments = this.mergedDocuments.bind(this);
    this.prepareEdition = this.prepareEdition.bind(this);
    this.removeEmptyFolderNodes = this.removeEmptyFolderNodes.bind(this);
    this.removeEmptyDocumentsNodes = this.removeEmptyDocumentsNodes.bind(this);
    this.prepareExplode = this.prepareExplode.bind(this);
    this.lock = this.lock.bind(this);
  }

  removeEmptyDocumentsNodes(evt, data) {
    this.props.removeEmptyDocumentNodesAction(data.node.id);
  }

  removeEmptyFolderNodes(evt, data) {
    this.props.removeEmptyFolderNodesAction(data.node.id);
  }

  moveSelectedNodeBeforeParent() {
    this.props.moveNodesAction(
      [this.props.trigger.node],
      this.props.selectedNodeParent,
      BEFORE
    );
  }

  copyNode() {
    this.props.changeClipboard(
      TYPE_COPY,
      this.props.selectedNodesInContextMenu.map(node => node.id),
      this.props.dataroomId
    );
  }

  lock() {
    this.props.lockDataroom(this.context.user);
  }

  cutNode() {
    this.props.changeClipboard(
      TYPE_CUT,
      this.props.selectedNodesInContextMenu.map(node => node.id),
      this.props.dataroomId
    );
  }

  pasteNode(evt, data) {
    const { pasteNode, trigger, clipboard } = this.props;

    pasteNode(trigger.node.id, data.position, clipboard, data.withFile);
  }

  onShow(evt) {
    return this.props.nodeMenuOpened(evt.detail.data.node.id);
  }

  onHide() {
    this.props.nodeMenuClosed();
  }

  removeNode(evt, data) {
    this.props.removeNodesAction(
      this.props.selectedNodesInContextMenu,
      data.deleteOnlyHead
    );
  }

  prepareExplode(evt, data) {
    this.props.openPdfExplodeAction(
      data.node,
      this.props.nodes[data.node.parentId]
    );
  }

  prepareEdition(evt, data) {
    this.props.openPdfEditor(data.node.id);
  }

  mergedDocuments(evt, data) {
    this.props.openPdfMergedAction(
      data.node.id,
      this.props.selectedNodesInContextMenu
    );
  }

  changeNodeType() {
    this.props.changeNodeTypeAction(
      this.props.selectedNodesInContextMenu.map(node => node.id),
      this.props.selectedNodesInContextMenu[0].type === TYPE_DOCUMENT
        ? TYPE_FOLDER
        : TYPE_DOCUMENT,
      this.props.dataroomId
    );
  }

  render() {
    let node = null;
    if (this.props.trigger) {
      node = this.props.trigger.node;
    }

    if (
      !node ||
      this.props.corrupted ||
      this.props.nodeNameEditable ||
      this.props.showAddNodesForm
    ) {
      return (
        <ReactContextContextMenu
          style={{
            display: 'none',
          }}
          onShow={this.onShow}
          onHide={this.onHide}
          id="NODE_MENU"
        >
          <p>empty</p>
        </ReactContextContextMenu>
      );
    }
    const {
      canPaste,
      editionMode,
      canAdd,
      clipboard,
      canEditDataroom,
      selectedNodesInContextMenu,
      canMoveBeforeParent,
      lockBy,
      locking,
      unlocking,
      corrupted,
    } = this.props;
    const canEditNode = editionMode === EDITION_MODE_ALLOWED;

    // if dataroom is not locked, only display copy
    if (!canEditDataroom) {
      return (
        <ReactContextContextMenu
          onShow={this.onShow}
          onHide={this.onHide}
          id="NODE_MENU"
        >
          <MenuItem onClick={this.copyNode}>
            <i className="fa fa-clone" aria-hidden="true" /> Copier{' '}
            {selectedNodesInContextMenu.length > 1 && 'les lignes'}
          </MenuItem>
          <MenuItem
            onClick={this.lock}
            disabled={
              corrupted ||
              locking ||
              unlocking ||
              lockBy ||
              editionMode === EDITION_MODE_FORBIDDEN
            }
          >
            <i className="fa fa-lock" aria-hidden="true" /> Verrouiller la
            Dataroom
          </MenuItem>
        </ReactContextContextMenu>
      );
    }

    if (selectedNodesInContextMenu.length > 1) {
      return (
        <ReactContextContextMenu
          onShow={this.onShow}
          onHide={this.onHide}
          id="NODE_MENU"
        >
          <MenuItem onClick={this.copyNode}>
            <i className="fa fa-clone" aria-hidden="true" /> Copier les lignes
          </MenuItem>
          {canEditNode && (
            <Fragment>
              <MenuItem onClick={this.cutNode}>
                <i className="fa fa-scissors" aria-hidden="true" /> Couper les
                lignes
              </MenuItem>
              <MenuItem
                disabled={!this.props.canChangeType}
                onClick={this.changeNodeType}
              >
                <i className="fa fa-arrows-h" aria-hidden="true" /> Changer le
                type
              </MenuItem>
              {this.props.canMergeDocumentNodes && (
                <MenuItem onClick={this.mergedDocuments}>
                  <i className="fa fa-compress" aria-hidden="true" /> Fusionner
                  les documents
                </MenuItem>
              )}
              <MenuItem
                onClick={this.removeNode}
                data={{ deleteOnlyHead: false }}
              >
                <i className="fa fa-trash" aria-hidden="true" /> Supprimer les
                lignes
              </MenuItem>
            </Fragment>
          )}
        </ReactContextContextMenu>
      );
    }

    return (
      <ReactContextContextMenu
        onShow={this.onShow}
        onHide={this.onHide}
        id="NODE_MENU"
      >
        <SubMenu
          hoverDelay={100}
          title={
            <span>
              <i className="fa fa-plus" aria-hidden="true" /> Ajouter des lignes
              &nbsp;
            </span>
          }
        >
          <MenuItem
            onClick={() => this.props.showAddNodesFormAction(BEFORE, node.id)}
            disabled={!canAdd.before}
          >
            <i className="fa fa-arrow-up" aria-hidden="true" /> Ajouter au
            dessus <i>({this.props.nextPathBefore})</i>
          </MenuItem>
          {node.type === TYPE_FOLDER && (
            <MenuItem
              onClick={() => this.props.showAddNodesFormAction(INSIDE, node.id)}
              disabled={!canAdd.inside}
            >
              <i className="fa fa-arrow-right" aria-hidden="true" /> Ajouter à
              l'intérieur <i>({this.props.nextPathInside})</i>
            </MenuItem>
          )}
          <MenuItem
            onClick={() => this.props.showAddNodesFormAction(AFTER, node.id)}
            disabled={!canAdd.after}
          >
            <i className="fa fa-arrow-down" aria-hidden="true" /> Ajouter au
            dessous <i>({this.props.nextPathAfter})</i>
          </MenuItem>
        </SubMenu>
        {canEditNode && node.type === TYPE_DOCUMENT && (
          <SubMenu
            hoverDelay={100}
            title={
              <span>
                <i className="fa fa-file" aria-hidden="true" /> Fichier
              </span>
            }
          >
            <MenuItem
              disabled={node.uploading}
              onClick={() => this.props.openAttachFileModal(node.id)}
            >
              <i className="fa fa-file-pdf-o" aria-hidden="true" /> Associer un
              fichier
            </MenuItem>
            <MenuItem
              disabled={!node.file}
              onClick={() => this.props.detachFileToDocumentAction(node.id)}
            >
              <i className="fa fa-file-o" aria-hidden="true" /> Retirer le
              fichier
            </MenuItem>
          </SubMenu>
        )}
        <SubMenu
          hoverDelay={100}
          title={
            <span>
              <i className="fa fa-hand-grab-o" aria-hidden="true" />{' '}
              Manipulation
            </span>
          }
        >
          <MenuItem
            onClick={this.moveSelectedNodeBeforeParent}
            disabled={!canMoveBeforeParent}
          >
            <i className="fa fa-angle-double-up" aria-hidden="true" /> Remonter
            d'un niveau <i>({this.props.selectedNodeParentPath})</i>
          </MenuItem>
          <MenuItem onClick={this.copyNode}>
            <i className="fa fa-clone" aria-hidden="true" /> Copier
          </MenuItem>
          <MenuItem
            disabled={node.path === '0' || !canEditNode || !canAdd.after}
            onClick={this.cutNode}
          >
            <i className="fa fa-scissors" aria-hidden="true" /> Couper
          </MenuItem>
          <Paste
            canAdd={canAdd}
            pasteAction={this.pasteNode}
            clipboardType={clipboard.type}
            canPaste={canPaste}
            node={node}
            nextPathBefore={this.props.nextPathBefore}
            nextPathInside={this.props.nextPathInside}
            nextPathAfter={this.props.nextPathAfter}
          />
        </SubMenu>
        {canEditNode && node.type === TYPE_DOCUMENT && (
          <MenuItem onClick={this.removeNode} data={{ deleteOnlyHead: false }}>
            <i className="fa fa-trash" aria-hidden="true" /> Supprimer
          </MenuItem>
        )}
        {node.type === TYPE_FOLDER && (
          <SubMenu
            hoverDelay={100}
            title={
              <span>
                <i className="fa fa-trash" aria-hidden="true" /> Supprimer
              </span>
            }
          >
            <MenuItem
              onClick={this.removeNode}
              disabled={!canEditNode || !canAdd.after || node.path === '0'}
              data={{ deleteOnlyHead: false }}
            >
              <i className="fa fa-trash" aria-hidden="true" /> Supprimer
            </MenuItem>
            <MenuItem
              onClick={this.removeEmptyDocumentsNodes}
              disabled={node.documentWithoutFileNumber === 0}
            >
              <i className="fa fa-trash" aria-hidden="true" /> Supprimer les
              documents vides
            </MenuItem>
            <MenuItem
              onClick={this.removeEmptyFolderNodes}
              disabled={!this.props.canDeleteEmptyFolderNodes}
            >
              <i className="fa fa-trash" aria-hidden="true" /> Supprimer les
              dossiers vides
            </MenuItem>
            <MenuItem
              disabled={!canEditNode || !canAdd.after || node.path === '0'}
              onClick={this.removeNode}
              data={{ deleteOnlyHead: true }}
            >
              <i className="fa fa-eraser" aria-hidden="true" /> Supprimer tête
              de rubrique
            </MenuItem>
          </SubMenu>
        )}
        {canEditDataroom && (
          <Fragment>
            <MenuItem
              disabled={!this.props.canRename}
              onClick={() => this.props.nodeNameEditableAction(node.id)}
            >
              <i className="fa fa-pencil" aria-hidden="true" /> Renommer
            </MenuItem>
            <MenuItem
              disabled={!this.props.canChangeType}
              onClick={this.changeNodeType}
            >
              <i className="fa fa-arrows-h" aria-hidden="true" /> Changer le
              type
            </MenuItem>
          </Fragment>
        )}
        {canEditNode &&
          node.file &&
          (node.file.extension === 'zip' || node.file.extension === 'rar') && (
            <MenuItem
              onClick={() =>
                this.props.prepareTransformArchiveNodeToFolderAction(node.id)
              }
            >
              <i className="fa fa-file-archive-o" aria-hidden="true" /> Extraire
              l'archive
            </MenuItem>
          )}
        {!node.uploading &&
          canEditNode &&
          node.file &&
          node.file.extension === 'pdf' && (
            <Fragment>
              <MenuItem onClick={this.prepareEdition}>
                <i className="fa fa-file-pdf-o" aria-hidden="true" /> Editer le
                document
              </MenuItem>
              <MenuItem onClick={this.prepareExplode}>
                <i className="fa fa-cut" aria-hidden="true" /> Découper le
                document
              </MenuItem>
            </Fragment>
          )}
      </ReactContextContextMenu>
    );
  }
}

const selectedNodeParent = createSelector(
  [nodesSelector, selectedNodesInContextMenuSelector],
  (nodes, selectedNodesInContextMenu) => {
    if (selectedNodesInContextMenu.length === 0) {
      return null;
    }

    if (selectedNodesInContextMenu[0].parentId) {
      return nodes[selectedNodesInContextMenu[0].parentId];
    }
  }
);

const canMoveBeforeParent = createSelector(
  [
    nodesSelector,
    selectedNodesInContextMenuSelector,
    editionModeSelectorFromDataRoom,
  ],
  (nodes, selectedNodesInContextMenu, editionMode) => {
    if (selectedNodesInContextMenu.length === 0) {
      return false;
    }

    if (editionMode === EDITION_MODE_FORBIDDEN) {
      return false;
    }

    if (
      selectedNodesInContextMenu[0].path === '0' ||
      nodes[selectedNodesInContextMenu[0].parentId].path === '0'
    ) {
      return false;
    }

    return true;
  }
);

export const mapStateToProps = (state, props) => {
  const newProps = {
    clipboard: state.dataroom.working.nodeClipBoard,
    dataroomId: state.dataroom.dataroom.id,
    editionMode: editionModeSelectorFromDataRoom(state), // todo refactor
    canEditDataroom: canEditDataroom(state),
    selectedNodesInContextMenu: selectedNodesInContextMenuSelector(state),
    corrupted: state.dataroom.working.corrupted,
    nodeNameEditable: state.dataroom.working.renameNode.nodeNameEditable,
    canMergeDocumentNodes: canMergedDocumentNodes(state),
    showAddNodesForm: state.dataroom.working.addNodes.show,
    locking: state.dataroom.working.lock.locking,
    unlocking: state.dataroom.working.lock.unlocking,
    lockBy: state.dataroom.working.lock.lockBy,
  };

  if (props.trigger && props.trigger.node) {
    newProps.nextPathBefore = getPathBefore(props.trigger.node);
    newProps.nextPathInside = getPathInside(props.trigger.node);
    newProps.nextPathAfter = getPathAfter(props.trigger.node);
    newProps.selectedNodeParentPath = getParentPath(props.trigger.node);
    newProps.canMoveBeforeParent = canMoveBeforeParent(state, props);
    newProps.selectedNodeParent = selectedNodeParent(state, props);
    newProps.nodes = state.dataroom.working.nodes;
    newProps.canAdd = canAdd(state, props);
    newProps.canPaste = canPaste(state, props);
    newProps.canRename = canRenameFromContextMenu(state, props);
    newProps.canChangeType = canChangeType(state, props);

    if (props.trigger.node.type === TYPE_FOLDER) {
      newProps.canDeleteEmptyFolderNodes = canDeleteEmptyFolderNodes(
        state,
        props
      );
    }
  }

  return newProps;
};

const ConnectedContextMenu = connect(mapStateToProps, {
  lockDataroom,
  unLockDataroom,
  openPdfMergedAction,
  nodeMenuOpened,
  nodeMenuClosed,
  nodeNameEditableAction,
  changeNodeTypeAction,
  detachFileToDocumentAction,
  showAddNodesFormAction,
  removeNodesAction,
  openPdfEditor,
  openPdfExplodeAction,
  openAttachFileModal,
  removeEmptyFolderNodesAction,
  removeEmptyDocumentNodesAction,
  moveNodesAction,
  prepareTransformArchiveNodeToFolderAction,
  changeClipboard,
  pasteNode,
})(ContextMenu);
const ConnectedErrorBoundary = connect(null, {
  onError: () => {
    return addNotificationAction(
      "Une errreur s'est produite lors de l'ouverture du menu contextuel"
    );
  },
})(ErrorBoundary);

export default nest(ConnectedErrorBoundary, ConnectedContextMenu);
