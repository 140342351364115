import React from 'react';
import Svg from './Svg';

export default function IconTrash(props) {
  return (
    <Svg
      {...props}
      d="M162.636 36.7H221.5v30h-10.864v172.599l-165.272.001V66.7H34.5v-30h58.864v-20h69.272l-.001 20zm38.304 30H55.392v162.903h145.549L200.94 66.7zm-29.456 151.744V77.556h-9.696v140.888h9.696zm-39.024 0V77.556h-9.696v140.888h9.696zm-38.608 0V77.556h-9.696v140.888h9.696zM44.501 46.7h167v10h-167v-10zm108.135-20v10h-49.27l-.001-10h49.271z"
    />
  );
}
