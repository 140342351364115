import React from 'react';
import styled from 'styled-components';
import FieldGroup from './FieldGroup';
import Label from './Label';
import FieldAndHelper from './FieldAndHelper';
import Helper from './Helper';
import ErrorMessage from './ErrorMessage';
import IconChevronBottom from '../img/icons/IconChevronBottom';
import { PRIMARY_COLOR, DANGER_COLOR } from '../theme_blue';

const SelectContainer = styled.span`
  display: inline-block;
  position: relative;
  background-color: ${props => props.theme.lightColor};
  width: ${props => (props.displayBlock ? '100%' : 'auto')};
`;

const CaretContainer = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2em;
  height: 100%;
  border-color: transparent;
  pointer-events: none;
  z-index: 0;
`;

const SelectField = styled.select`
  width: ${props => (props.displayBlock ? '100%' : 'auto')};
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
  display: ${props => (props.displayBlock ? 'block' : 'inline-block')};
  text-align: ${props => (props.textAlign ? props.textAlign : 'left')};
  height: ${props => (props.height ? props.height : '41px')};
  border-style: solid;
  border-width: 1px;
  border-color: ${props => {
    if (props.hasError) {
      return props.theme.dangerColor;
    }
    return props.theme.formFieldsBorderColor;
  }};
  padding: 0.45em 2em 0.45em 0.5em;
  font-family: ${props => props.theme.bodyFont};
  font-size: 1em;
  font-size: clamp(16px, 1em, 2rem);
  background-color: ${props =>
    props.hasError ? props.theme.dangerLightColor : props.theme.lightColor};
  color: ${props =>
    props.hasError ? props.theme.dangerColor : props.theme.darkColor};
  ${props =>
    props.disabled
      ? 'cursor: not-allowed; background-color: ' + props.theme.lightGreyColor
      : null};
  &::-ms-expand {
    display: none;
  }
  @media (min-width: ${props => props.theme.tablet}) {
    font-size: 1em;
  }
`;

class Select extends React.Component {
  render() {
    return (
      <FieldGroup
        isHorizontal={this.props.isHorizontal}
        size={this.props.size}
        marginBottom0={this.props.marginBottom0}
      >
        <Label
          field_id={this.props.field_id}
          hasError={this.props.hasError}
          isHorizontal={this.props.isHorizontal}
          labelTextRight={this.props.labelTextRight}
          label={this.props.label}
          isRequired={this.props.isRequired}
          isLabelBold={this.props.isLabelBold}
          labelWidth={this.props.labelWidth}
          labelColor={this.props.labelColor}
        />
        <FieldAndHelper isHorizontal={this.props.isHorizontal}>
          <SelectContainer
            displayBlock={this.props.displayBlock}
            fieldWidth={this.props.fieldWidth}
          >
            <SelectField
              required={this.props.isRequired}
              displayBlock={this.props.displayBlock}
              id={this.props.field_id}
              placeholder={this.props.placeholder}
              ref={this.props.forwardedRef}
              onChange={this.props.onChange}
              disabled={this.props.isDisabled}
              hasError={this.props.hasError}
              defaultValue={this.props.defaultValue}
              value={this.props.value}
              name={this.props.name}
              height={this.props.height}
            >
              {this.props.children}
            </SelectField>
            <CaretContainer>
              {!this.props.hasError && (
                <IconChevronBottom
                  iconWidth="1em"
                  color={PRIMARY_COLOR}
                  iconAlt="Sélectionner une option"
                />
              )}
              {this.props.hasError && (
                <IconChevronBottom
                  iconWidth="1em"
                  color={DANGER_COLOR}
                  iconAlt="Sélectionner une option"
                />
              )}
            </CaretContainer>
          </SelectContainer>
          {this.props.hasError && this.props.errorMessage && (
            <ErrorMessage displayBlock={this.props.displayBlock}>
              {this.props.errorMessage}
            </ErrorMessage>
          )}
          <Helper hasError={this.props.hasError}>
            {this.props.formHelper}
          </Helper>
        </FieldAndHelper>
      </FieldGroup>
    );
  }
}

export default React.forwardRef((props, ref) => (
  <Select forwardedRef={ref} {...props} />
));
