import { createSelector } from 'reselect';
import nodesSelector from './workingNodesSelector';
import originalSelectedNodeIdInContextMenuSelector from './originalSelectedNodeIdInContextMenuSelector';
import { TYPE_FOLDER } from '../documents/type';

function oneEmptyFolderExistInChildNodes(childNodeIds, nodes) {
  for (let i = 0; i < childNodeIds.length; i++) {
    const node = nodes[childNodeIds[i]];
    if (node.type === TYPE_FOLDER) {
      if (
        node.childIds.length === 0 ||
        oneEmptyFolderExistInChildNodes(node.childIds, nodes)
      ) {
        return true;
      }
    }
  }
}

const canDeleteEmptyFolderNodeFromParentFolder = (nodes, nodeId) => {
  if (!nodes[nodeId]) {
    return false;
  }

  return oneEmptyFolderExistInChildNodes(nodes[nodeId].childIds, nodes);
};

export const canDeleteEmptyFolderNodeFromParentFolderForSelectedNodeInContextMenu = createSelector(
  [nodesSelector, originalSelectedNodeIdInContextMenuSelector],
  canDeleteEmptyFolderNodeFromParentFolder
);
