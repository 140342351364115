import { INSIDE } from './getNextPath';

/**
 * get parent id for given position
 * @param nodes
 * @param nodeId
 * @param position
 */
export default (nodes, nodeId, position) =>
  position === INSIDE ? nodeId : nodes[nodeId].parentId;
