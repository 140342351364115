import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  cancelImportFilesAction,
  FILE_ORIGIN_ARCHIVE,
  IGNORED_FILES_FROM_ARCHIVE,
  IGNORED_FILES_FROM_CSV,
  importFilesAction,
  importFilesStartedAction,
} from '../ducks/importFiles';
import { addFilesAction } from '../ducks/addFiles';
import { transformArchiveNodeToFolderAction } from '../ducks/transformArchiveNodeToFolder';
import { Modal } from 'react-bootstrap';
import {
  IMPORT_TYPE_NOTARY_CHAMBER,
  IMPORT_TYPE_CDROM_BRUT,
  IMPORT_TYPE_CLASSIC,
} from '../constants';
import PleaseWait from '../components/PleaseWait';

const getImportTextFromConstant = importType => {
  switch (importType) {
    case IMPORT_TYPE_NOTARY_CHAMBER:
      return 'Chambre des notaires';
    case IMPORT_TYPE_CDROM_BRUT:
      return 'CDROM Brut';
  }
};

function ConfirmImportWithErrors(props) {
  const { errors, actionToDispatch, dispatch } = props;

  const missingNodesFromCsv = errors.filter(
    error => error.errorType === IGNORED_FILES_FROM_CSV
  );
  const missingNodesFromArchive = errors.filter(
    error => error.errorType === IGNORED_FILES_FROM_ARCHIVE
  );

  return (
    <>
      <Modal.Body>
        <h4>
          Certaines lignes de l'import contiennent des erreurs
          <br />
          <br />
          Souhaitez vous quand même lancer l'import en ignorant ces lignes ?
          {missingNodesFromCsv.length > 0 && (
            <div>
              La liste des lignes du CSV en erreur:
              <ul>
                {missingNodesFromCsv.map(error => (
                  <li key={error.currentPath}>
                    <b>{error.currentPath}</b> {error.csvNode.name}
                  </li>
                ))}
              </ul>
            </div>
          )}
          {missingNodesFromArchive.length > 0 && (
            <div>
              Documents non présents dans le CSV mais présent dans
              l'arborescence:
              <ul>
                {missingNodesFromArchive.map(error => (
                  <li key={error.currentPath}>
                    <b>{error.currentPath}</b> {error.csvNode.name}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </h4>
      </Modal.Body>

      <Modal.Footer>
        <button
          type="button"
          className="btn btn-warning md-close"
          onClick={() => props.cancelImportFilesAction()}
        >
          Annuler l'import
        </button>

        <button
          type="button"
          className="btn btn-primary md-close"
          onClick={() => {
            dispatch(actionToDispatch);
            props.importFilesStartedAction();
          }}
        >
          Confirmer l'import
        </button>
      </Modal.Footer>
    </>
  );
}

function ConfirmImport(props) {
  const {
    nodes,
    destinationNode,
    position,
    importing,
    fileOrigin,
    nodeId,
  } = props.action;

  const { importText } = props;

  return (
    <>
      <Modal.Body>
        <h4>
          La structure du dossier que vous avez ajouté correspond à un import de
          type <i>{importText}</i>.
          <br />
          <br />
          Voulez vous lancer l'import ou garder la structure actuelle ?
        </h4>
      </Modal.Body>

      <Modal.Footer>
        <button
          disabled={importing}
          onClick={props.cancelImportFilesAction}
          type="button"
          className="btn btn-warning md-close"
        >
          Annuler l'ajout
        </button>

        <button
          onClick={() => {
            if (fileOrigin === FILE_ORIGIN_ARCHIVE) {
              props.transformArchiveNodeToFolderAction(
                nodes,
                nodeId,
                IMPORT_TYPE_CLASSIC,
                props.action.frontActionId
              );
            } else {
              props.addFilesAction(nodes, destinationNode, position);
            }
            props.cancelImportFilesAction();
          }}
          disabled={importing}
          type="button"
          data-dismiss="modal"
          className="btn btn-default md-close"
        >
          Garder la structure
        </button>

        <button
          onClick={() => props.importFilesAction(props.action)}
          type="button"
          className="btn btn-primary md-close"
          disabled={importing}
        >
          Lancer l'import <i>{importText}</i>
        </button>
      </Modal.Footer>
    </>
  );
}

class ConfirmImportFilesModal extends Component {
  render() {
    if (!this.props.open) {
      return null;
    }

    const { importType, importing } = this.props.action;

    const { actionToDispatch, errors, dispatch } = this.props;

    const importText = getImportTextFromConstant(importType);

    return (
      <Fragment>
        <PleaseWait text="Import en cours..." waiting={importing} />
        <Modal
          show={this.props.open}
          backdrop="static"
          onHide={this.props.cancelImportFilesAction}
        >
          <Modal.Header>
            <Modal.Title>
              Confirmation d'import de type <i>{importText}</i>
            </Modal.Title>
          </Modal.Header>

          {errors.length > 0 && (
            <ConfirmImportWithErrors
              importType={importType}
              errors={errors}
              actionToDispatch={actionToDispatch}
              cancelImportFilesAction={this.props.cancelImportFilesAction}
              dispatch={dispatch}
              importFilesStartedAction={this.props.importFilesStartedAction}
            />
          )}
          {errors.length === 0 && (
            <ConfirmImport
              cancelImportFilesAction={this.props.cancelImportFilesAction}
              transformArchiveNodeToFolderAction={
                this.props.transformArchiveNodeToFolderAction
              }
              addFilesAction={this.props.addFilesAction}
              importFilesAction={this.props.importFilesAction}
              action={this.props.action}
              importText={importText}
            />
          )}
        </Modal>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return state.dataroom.working.confirmImportFilesModal;
}

export default connect(mapStateToProps, dispatch => {
  return {
    dispatch,
    ...bindActionCreators(
      {
        importFilesAction,
        cancelImportFilesAction,
        addFilesAction,
        importFilesStartedAction,
        transformArchiveNodeToFolderAction: transformArchiveNodeToFolderAction,
      },
      dispatch
    ),
  };
})(ConfirmImportFilesModal);
