import React, { Component, Fragment } from 'react';
import Label from '../../../presentational/components/editor/forms/Label';
import InputText from '../../../presentational/components/editor/forms/InputText';
import Button from '../../../presentational/components/editor/forms/Button';

class ExplodePdfForm extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.state = {
      name: '',
    };
  }

  submit(evt) {
    evt.preventDefault();
    this.props.addNode(this.state.name);

    this.setState({ name: '' });
  }

  render() {
    const { submitting, pickedPageNumbers } = this.props;

    return (
      <form
        autoComplete="off"
        className="text-node col-lg-12"
        onSubmit={this.submit}
      >
        <Label
          htmlFor="file_new_name"
          display="inline-flex"
          className="col-lg-1"
        >
          Nom de la ligne :
        </Label>{' '}
        <InputText
          className="col-lg-10"
          type="text"
          name="name"
          id="new_name"
          display="inline-flex"
          flexGrow
          value={this.state.name}
          onChange={event => this.setState({ name: event.target.value })}
          disabled={!this.props.pickedPageNumbers.length > 0}
        />
        <Button
          type="submit"
          disabled={
            !this.props.pickedPageNumbers.length > 0 ||
            this.state.name.length === 0
          }
        >
          Valider
        </Button>
      </form>
    );
  }
}

export default ExplodePdfForm;
