import React from 'react';
import styled from 'styled-components';
import FieldGroup from './FieldGroup';
import Label from './Label';
import FieldAndHelper from './FieldAndHelper';
import InputField from './InputField';
import Helper from './Helper';
import ErrorMessage from './ErrorMessage';
import Li from '../Li';

class Input extends React.Component {
  render() {
    return (
      <FieldGroup
        isHorizontal={this.props.isHorizontal}
        size={this.props.size}
        marginBottom0={this.props.marginBottom0}
      >
        {!this.props.noLabel && (
          <Label
            field_id={this.props.name}
            hasError={this.props.errors}
            isHorizontal={this.props.isHorizontal}
            labelTextRight={this.props.labelTextRight}
            label={this.props.label}
            isRequired={this.props.isRequired}
            isLabelBold={this.props.isLabelBold}
            isLabelHidden={this.props.isLabelHidden}
            labelPaddingBottom={this.props.labelPaddingBottom}
            labelWidth={this.props.labelWidth}
            labelColor={this.props.labelColor}
          />
        )}
        <FieldAndHelper isHorizontal={this.props.isHorizontal}>
          <InputField
            readOnly={this.props.readOnly}
            height={this.props.height}
            autoComplete={this.props.autoComplete}
            onBlur={this.props.onBlur}
            type={this.props.type}
            textAlign={this.props.textAlign}
            isRequired={this.props.isRequired}
            displayBlock={this.props.displayBlock}
            field_id={this.props.name}
            value={this.props.value}
            placeholder={this.props.placeholder}
            onChange={this.props.onChange}
            name={this.props.name}
            onClick={this.props.onClick}
            onFocus={this.props.onFocus}
            onKeyDown={this.props.onKeyDown}
            isDisabled={this.props.isDisabled}
            hasError={this.props.errors}
            maxLength={this.props.maxLength}
            pattern={this.props.pattern}
            noBorder={this.props.noBorder}
            forwardedRef={this.props.forwardedRef}
          />
          <Helper hasError={this.props.errors}>{this.props.formHelper}</Helper>
          {this.props.errors && (
            <ErrorMessage displayBlock={this.props.displayBlock}>
              {Object.entries(this.props.errors).map(([type, message]) => (
                <Li key={type}>{message}</Li>
              ))}
            </ErrorMessage>
          )}
        </FieldAndHelper>
      </FieldGroup>
    );
  }
}

export default React.forwardRef((props, ref) => (
  <Input forwardedRef={ref} {...props} />
));
