import { INSIDE, AFTER } from './getNextPath';

/**
 * get index of insertion in childIds array
 * @param nodes
 * @param parentId
 * @param nodeId
 * @param position
 * @returns {number}
 */
export default (childIds, nodeId, position) => {
  if (position === INSIDE) {
    return 0;
  }

  let index = childIds.indexOf(nodeId);
  if (position === AFTER) {
    index += 1;
  }

  return index;
};
