import React from 'react';
import styled from 'styled-components';

const Div = styled.div`
  position: relative; /* useful for select fields and some absolute positioned elements */
  ${props => {
    if (props.size == 'tiny') {
      return 'font-size: .75rem;';
    }
    if (props.size == 'big') {
      return 'font-size: 1.25rem;';
    }
    if (props.size == 'normal') {
      return 'font-size: 1rem;';
    }
    return 'font-size: 1rem';
  }};
  line-height: normal;
  margin-bottom: ${props => (props.marginBottom0 ? '0' : '1rem')};
  ${props => {
    if (props.isInline) {
      return 'display: inline-flex;';
    }
  }}
  @media (min-width: ${props => props.theme.tablet}) {
    ${props => {
      if (props.isHorizontal && !props.isInline) {
        return 'display: flex;';
      }
    }}
  }
`;
class FieldGroup extends React.Component {
  render() {
    return (
      <Div
        isHorizontal={this.props.isHorizontal}
        size={this.props.size}
        marginBottom0={this.props.marginBottom0}
        id={this.props.field_id}
        isInline={this.props.isInline}
      >
        {this.props.children}
      </Div>
    );
  }
}

export default FieldGroup;
