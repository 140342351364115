import getNextPath, { getNextPathForPath } from './getNextPath';
import getParentIdForInsertion from './getParentIdForInsertion';
import createNode from './createNode';
import updatePathForDescendant from './updatePathForDescendant';
import getIndexForInsert from './getIndexForInsert';
import { TYPE_FOLDER } from '../../documents/type';

export default (nodeId, position, nodes, nodesToAdd) => {
  const nextPath = getNextPath(nodes[nodeId].path, position);
  const parentId = getParentIdForInsertion(nodes, nodeId, position);

  const newChildIds = nodes[parentId].childIds.slice();
  let index = getIndexForInsert(nodes[parentId].childIds, nodeId, position);
  let currentNextPath = nextPath;

  const newNodes = updatePathForDescendant(
    nodes,
    nodeId,
    position,
    nodesToAdd.length
  );

  nodesToAdd.forEach(node => {
    newChildIds.splice(index, 0, node.id);

    newNodes[node.id] = {
      ...createNode(),
      ...node,
      parentId,
      path: currentNextPath,
    };

    if (node.type === TYPE_FOLDER) {
      newNodes[node.id].childIds = [];
      newNodes[node.id].folded = true;
      newNodes[node.id].documentWithFileNumber = 0;
      newNodes[node.id].documentWithoutFileNumber =
        newNodes[node.id].documentWithoutFileNumber || 0;
    }

    index += 1;
    currentNextPath = getNextPathForPath(currentNextPath);
  });

  newNodes[parentId] = {
    ...newNodes[parentId],
    childIds: newChildIds,
  };

  return newNodes;
};
