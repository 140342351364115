import { call, put, race, take, takeEvery } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { blockLeave, allowLeave } from 'js/dataroom/core/urlLeaveBlocker';

const SLOW_ACTION_IN_PROGRESS = 'SLOW_ACTION_IN_PROGRESS';
const SLOW_ACTION_DONE = 'SLOW_ACTION_DONE';

const dataroomActions = [
  'CHANGE_NODES_TYPE',
  'ADD_NODES',
  'COPY_NODES',
  'MOVE_NODES',
  'REMOVE_NODES',
  'RENAME_NODE',
  'ATTACH_FILE_TO_DOCUMENT',
  'DETACH_FILE_TO_DOCUMENT',
  'ADD_FILES',
  'TRANSFORM_ARCHIVE_NODE_TO_FOLDER',
  'REMOVE_EMPTY_DOCUMENT_NODES',
  'REMOVE_EMPTY_FOLDER_NODES',
  'PDF_EDIT',
  'PDF_MERGE',
  'PDF_EXPLODE',
];

function* slowActionsSaga(action) {
  const frontActionId = action.type.startsWith('UNDO_')
    ? action.action.frontActionId
    : action.frontActionId;

  const actionIsDone = receivedAction =>
    receivedAction.action &&
    receivedAction.action.frontActionId === frontActionId &&
    (receivedAction.type.endsWith('SUCCESS') ||
      receivedAction.type.endsWith('ERROR'));

  const { inProgress, done } = yield race({
    done: take(actionIsDone),
    inProgress: call(delay, 1000),
  });

  if (inProgress) {
    yield put({
      type: SLOW_ACTION_IN_PROGRESS,
    });

    blockLeave();

    yield take(actionIsDone);

    allowLeave();

    yield put({
      type: SLOW_ACTION_DONE,
    });
  }
}

export function* saga() {
  yield takeEvery(action => {
    if (dataroomActions.indexOf(action.type) !== -1) {
      return true;
    }

    if (
      dataroomActions.find(
        dataroomAction => action.type === `UNDO_${dataroomAction}`
      )
    ) {
      return true;
    }

    return false;
  }, slowActionsSaga);
}

export default function(state, action) {
  switch (action.type) {
    case SLOW_ACTION_IN_PROGRESS:
    case SLOW_ACTION_DONE: {
      return {
        ...state,
        working: {
          ...state.working,
          slowActionInProgress: action.type === SLOW_ACTION_IN_PROGRESS,
        },
      };
    }
  }
  return state;
}
