import React, { Fragment } from 'react';
import 'translations/messages/fr';
import 'translations/asset-asset/fr';
import P from '../../../presentational/components/P';
import { CLOSE_MODAL, MODE_DELETE_DATAROOM } from '../../pages/DataRoom';
import { ConfirmationModal } from '../../../app/components/ConfirmationModal';
import Ul from '../../../presentational/components/Ul';
import Li from '../../../presentational/components/Li';
import {
  ADD_NOTIFICATION_REMINDER,
  TYPE_ERROR,
  TYPE_SUCCESS,
} from '../../../dataroom/app/reducers/notifications';
import useApiClient from '../../../app/hooks/apiClient';
import useNotification from '../../../app/hooks/notification';

export function DeleteDataRoomModal(props) {
  const { onSendNotificationReminder } = useNotification();
  const apiClient = useApiClient();

  const { open, sending, mode, data } = props.modal;

  return (
    <Fragment>
      {mode === MODE_DELETE_DATAROOM && (
        <ConfirmationModal
          title="Suppression de la dataroom"
          onClose={() => props.onClose()}
          onValidate={async () => {
            const response = await apiClient(`/api/datarooms/${data.id}`, {
              method: 'DELETE',
            });

            props.onClose();

            if (response.ok) {
              props.reload();
              onSendNotificationReminder('La dataroom a été supprimée');
              return;
            }

            onSendNotificationReminder(
              'Suppression impossible, la dataroom est manipulable',
              TYPE_ERROR
            );
          }}
          sending={sending}
          open={open}
        >
          <P>
            Vous êtes sur le point de supprimer la dataroom. L'action est
            irréversible.
          </P>
          <Ul>
            <Li key={1}>{data.name}</Li>
          </Ul>
        </ConfirmationModal>
      )}
    </Fragment>
  );
}
