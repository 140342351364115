import styled from 'styled-components';

const GridRow = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: ${props => {
    if (props.justify === 'center') {
      return 'center';
    }
    if (props.justify === 'end') {
      return 'flex-end';
    }
    if (props.justify) {
      return 'space-' + props.justify;
    }
    return 'flex-start';
  }};
  align-items: ${props => {
    if (props.verticalAlign === 'end') {
      return 'flex-end';
    }
    return props.verticalAlign;
  }};
  width: ${props => (props.noGutters ? '100%' : 'calc(100% + 1rem)')};
  margin-left: ${props => {
    if (props.noGutters) {
      return '0';
    }
    return '-.5rem';
  }};
  margin-right: ${props => {
    if (props.noGutters) {
      return '0';
    }
    return '-.5rem';
  }};
  ${props => (props.marginTop ? 'margin-top: ' + props.marginTop + ';' : null)}
  ${props => (props.height ? 'height: ' + props.height + ';' : null)}
  ${props =>
    props.marginBottom ? 'margin-bottom: ' + props.marginBottom + ';' : null}
  ${props => (props.fontSize ? 'font-size: ' + props.fontSize + ';' : null)}
  ${props =>
    props.debug
      ? '& > div { background: rgba(255,0,0,.125); border: dotted 1px #888; }'
      : null}
  ${props =>
    props.debug
      ? '& > div:nth-child(even) { background: rgba(0,255,0,.125); }'
      : null}
`;

export default GridRow;
