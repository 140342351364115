import { DragSource, DropTarget } from 'react-dnd';
import React, { Component, Fragment } from 'react';
import { findDOMNode } from 'react-dom';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { endDragAction, beginDragAction } from '../ducks/dragDropDocument';
import {
  pickDocumentAction,
  addDocumentToPickedAction,
  resetPickDocumentAction,
  removeDocumentToPickedAction,
} from '../ducks/pickDocument';
import { moveDocumentAction } from '../ducks/moveDocument';
import Item from '../../../presentational/components/editor/treeview/Item';
import IconDocument from '../../../presentational/components/editor/treeview/IconDocument';
import ItemLabel from '../../../presentational/components/editor/treeview/ItemLabel';
import ItemNumber from '../../../presentational/components/editor/treeview/ItemNumber';
import { PDF_COLOR } from '../../../presentational/components/theme_blue';

const TYPE_DOCUMENT = 'document';
let image = null;

const generateMultipleDragAndDropImageCache = () => {
  image = new Image();
  image.src =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAhCAYAAAAh3nDkAAAABHNCSVQICAgIfAhkiAAAABl0RVh0U29mdHdhcmUAZ25vbWUtc2NyZWVuc2hvdO8Dvz4AAAKrSURBVFiF5ddZSFRRHMfx71yu3aFxVLAHHc0gMBCkBQKJisoWQttU0DIVgxZpIQyTlhEhXCJIrB5CC9tteSg0KYModQwfSkTRzDQl0dTRMZcZuejo7SGyhsl8mMXA3+P/nMOH83D+f45KURSFOYowV7ANPjU1xfsPdbS0tLkXt1qt5FzM55w+i3sPHrsXL7pdjKG6xm2oDb4vLpoz6SfdjosAWq0n69aG/XVDdm4eZrPFYWjrlo2Eb1pvjwPM9OIqKt85DAPodH52uABQ39BISelLAHp6jTx9Voaxf8Ap6L8iAFQZaii8eReAzs4urhfcoqvrGwAazUKnQFpPT7uaat52OBGgo+MrV64V0trWTmCAPyeOHSI0NMTluEpRFCUtPZMv7R2sWrkcQ3UNPt5ePHlUhEqlwjT4HVmWHYYW+foiSQvscVmWMRoHCAzUEZ94BJNpkOclxaglie2RsUxOTjqMx0TvJOVwsk1NAFCr1Xh5aTmfkY3JNMieXRGoJQnAKTDAxMSEXU0E6O7uITVNz8jIKAeS44nfG+MUcLaI8LOFDg+PcPnSBaYUhf1JKejPniIkZBlJCXGMms0OQ2vCVtvjsizT2tYOQGqafnphfGIcgMSEWIfhmSJKksTxowdRsO01ATp/l6G/MqcdTgTIv1pAX5/RZiE3OwOArJw8LBYXjtTGpmaGhoYJDl5KbW09Wu3vIVBZ5bqRKgJE7Y5Ao9FQV9eAIAhk6k87BZwtAkBkxDZEUeRF+Wu0Wk/MlrHpDW4ZqUZjP+Wv3lBaVo7FMsbD+4X4+Hg7BZ4pAkDTx08MmAaJjtrBkqBArFYrlj9u76qIAAU37tDc/Hm6uGJ5KDqdn8txlaIoitls4W2FgZ5eI0GLA9gcvgEPD3H2087AXa7MkP/jozjv8B/+qwKOzHZgowAAAABJRU5ErkJggg==\n';
};

const getMultipleDragAndDropImage = () => {
  if (!image) {
    generateMultipleDragAndDropImageCache();
  }

  return image;
};

const pageSource = {
  beginDrag(props, monitor, component) {
    if (!props.node.dragging) {
      props.beginDragAction(props.node.id);

      if (component.props.node.multipleDrag) {
        component.props.connectDragPreview(getMultipleDragAndDropImage());
      } else {
        component.props.connectDragPreview();
      }
    }

    return {
      id: props.node.id,
    };
  },

  endDrag(props, monitor, component) {
    if (props.node.dragging) {
      props.endDragAction();
    }
  },
};

const AFTER = 'AFTER';
const BEFORE = 'BEFORE';

const getPositionType = (component, monitor) => {
  const domNode = findDOMNode(component);
  const hoverBoundingRect = domNode.getBoundingClientRect();
  const clientOffset = monitor.getClientOffset();
  const hoverClientY = clientOffset.y - hoverBoundingRect.top;
  const hoverMiddleY = 33 / 2;

  return hoverClientY > hoverMiddleY ? AFTER : BEFORE;
};

const canDropPageDocument = props =>
  !props.draggingNodes.includes(props.node.id);

const pageTarget = {
  hover(props, monitor, component) {
    if (!component) {
      return null;
    }

    const dragNodeId = monitor.getItem().id;
    const hoverNodeId = props.node.id;

    if (dragNodeId === hoverNodeId) {
      return;
    }

    const positionType = getPositionType(component, monitor);

    if (component.props.isOver && canDropPageDocument(props)) {
      component.setState({ hoverPosition: positionType });
      return;
    }

    if (!component.props.isOver && component.state.hoverPosition) {
      component.setState({ hoverPosition: null });
    }
  },

  drop(props, monitor, component) {
    const sourceNode = monitor.getItem();

    if (monitor.didDrop()) {
      return;
    }

    if (!canDropPageDocument(props)) {
      props.endDragAction();
      return;
    }

    props.moveDocumentAction(
      sourceNode.id,
      props.node.id,
      component.state.hoverPosition
    );

    props.endDragAction();
  },
};

@DragSource(TYPE_DOCUMENT, pageSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
}))
@DropTarget(TYPE_DOCUMENT, pageTarget, (connect, monitor, component) => {
  const isOver = monitor.isOver({ shallow: true });

  return {
    connectDropTarget: connect.dropTarget(),
    isOver,
  };
})
class Document extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      hoverPosition: null,
    };
  }

  handleClick(e) {
    e.stopPropagation();

    if (e.ctrlKey) {
      if (!this.props.node.picked) {
        this.props.addDocumentToPickedAction(this.props.node.id);
        return;
      }

      this.props.removeDocumentToPickedAction(this.props.node.id);
      return;
    }

    this.props.pickDocumentAction(this.props.node.id);
  }

  render() {
    const { connectDragSource, connectDropTarget, isOver, node } = this.props;

    return connectDragSource(
      connectDropTarget(
        <div
          onClick={this.handleClick}
          className="document"
          style={{
            opacity: node.dragging ? '0.5' : '1',
          }}
        >
          {isOver && BEFORE === this.state.hoverPosition && (
            <Item placeholder="true" />
          )}
          <Item picked={node.picked} dragging={node.dragging}>
            <IconDocument color={PDF_COLOR} isValid />
            <ItemNumber color={PDF_COLOR}>{node.path}</ItemNumber>
            <ItemLabel color={PDF_COLOR}>{node.name}</ItemLabel>
          </Item>
          {isOver && AFTER === this.state.hoverPosition && (
            <Item placeholder="true" />
          )}
        </div>
      ),
      {
        dropEffect: 'move',
      }
    );
  }
}

const makeSelector = () => {
  return createSelector(
    [
      state => state.pdfMerged.nodes,
      state => state.pdfMerged.pickedNodes,
      state => state.pdfMerged.draggingNodes,
    ],
    (nodes, pickedNodes, draggingNodes) => ({
      nodes,
      pickedNodes,
      draggingNodes,
    })
  );
};

function mapStateToProps(state, ownProps) {
  return makeSelector(state, ownProps);
}
export default connect(mapStateToProps, {
  beginDragAction,
  endDragAction,
  pickDocumentAction,
  addDocumentToPickedAction,
  resetPickDocumentAction,
  removeDocumentToPickedAction,
  moveDocumentAction,
})(Document);
