import PropTypes from 'prop-types';
import React, { Component } from 'react';
import applyDateTimePicker from '../../core/applyDateTimePicker';
import moment from 'moment';

class DateTimePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: props.value,
    };
  }

  componentDidMount() {
    if (this.props.readOnly) {
      return;
    }
    const $selector = $(this.input);
    applyDateTimePicker($selector, this.props.pickTime);

    $selector.on('dateChanged', evt => {
      this.props.onChange(evt.date);
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.value && !nextProps.value) {
      this.input.value = '';
    }
  }

  render() {
    return (
      <div className="input-group md-jump">
        <input
          readOnly={this.props.readOnly}
          type="text"
          id={this.props.id}
          className="form-control datetimepicker"
          data-date-format={
            this.props.pickTime ? 'dd/mm/yyyy hh:ii' : 'dd/mm/yyyy'
          }
          data-min-view={this.props.pickTime ? undefined : '2'}
          placeholder={this.props.placeholder}
          defaultValue={this.props.value || ''}
          ref={input => {
            this.input = input;
          }}
        />
        <span className="input-group-addon" onClick={() => this.input.focus()}>
          <span className="glyphicon glyphicon-calendar" />
        </span>
      </div>
    );
  }
}

DateTimePicker.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  pickTime: PropTypes.bool,
};

export default DateTimePicker;
