import React, { Component, Fragment } from 'react';
import Page from './Page';
import { connect } from 'react-redux';
import { rotatePdfAction } from '../ducks/editPage';
import {
  resetPickPdfAction,
  removePageAction,
  revertLastAction,
} from '../ducks/pickPage';
import {
  loadPdfEditorAction,
  leavePdfEditorAction,
} from '../reducers/pdfEditor';
import { editPdfAction } from '../../dataroom/ducks/pdfEditor';
import { throttle } from 'underscore';
import { ThemeProvider } from 'styled-components';
import {
  DANGER_COLOR,
  DANGER_COLOR_HOVER,
  DARK_COLOR,
  LIGHT_BACKGROUND_COLOR,
  PRIMARY_COLOR,
  PRIMARY_COLOR_HOVER,
  LIGHT_COLOR,
} from '../../../presentational/components/theme_blue';
import { Alert } from 'react-bootstrap';
import { difference } from 'underscore';
import DarkOverlay from '../../../presentational/components/editor/DarkOverlay';
import CommandButtonsContainer from '../../../presentational/components/editor/CommandButtonsContainer';
import LightBox from '../../../presentational/components/editor/LightBox';
import CloseButton from '../../../presentational/components/editor/CloseButton';
import ScrollablePanel from '../../../presentational/components/editor/ScrollablePanel';
import Title from '../../../presentational/components/editor/Title';
import Pages from '../../../presentational/components/editor/Pages';
import FullScreenPage from './FullScreenPage';
import Button from '../../../presentational/components/Button';
import Div from '../../../presentational/components/Div';
import ModalConfirm from './ModalConfirm';
import LoadingScreen from './LoadingScreen';
import IconSave from '../../../presentational/components/img/icons/IconSave';
import IconReload from '../../../presentational/components/img/icons/IconReload';
import IconChevronLeftHeavy from '../../../presentational/components/img/icons/IconChevronLeftHeavy';
import IconSpam from '../../../presentational/components/img/icons/IconSpam';
import IconTrash from '../../../presentational/components/img/icons/IconTrash';
import PdfErrors from './PdfErrors';
import IconErrorsScan from '../../../presentational/components/img/icons/IconErrorsScan';

const isDetectRotationDisabled = (status, prevPage) => {
  return status !== null || prevPage.length > 0;
};

const getPdfErrorTitle = (status, prevPage) => {
  if (isDetectRotationDisabled(status, prevPage)) {
    return "Enregistrez le document puis revenez sur l'éditeur pour l'analyser";
  }

  return 'Détection pages blanches et mauvaise orientation';
};

const normalizePages = (pages, files) => {
  const formatedPages = pages.map(page => {
    return {
      pageNumber: page.fileId ? page.pageNumber.split('-')[1] : page.pageNumber,
      rotation: page.rotation,
      fileUploadId: files[page.fileId] ? files[page.fileId].id : null,
      fileName: files[page.fileId] ? files[page.fileId].name : null,
    };
  });

  return {
    newPages: formatedPages,
  };
};

const arePageIdentical = (firstPage, secondPage) =>
  difference(firstPage, secondPage).length === 0;

class PdfEditor extends Component {
  constructor(props) {
    super(props);
    this.listenClick = this.listenClick.bind(this);
    this.removePage = this.removePage.bind(this);
    this.counterClockwiseRotation = throttle(
      this.counterClockwiseRotation.bind(this),
      500
    );
    this.clockwiseRotation = throttle(this.clockwiseRotation.bind(this), 500);
    this.submit = this.submit.bind(this);
    this.closeFullscreen = this.closeFullscreen.bind(this);
    this.revertLastChange = this.revertLastChange.bind(this);
    this.closeEditor = this.closeEditor.bind(this);
    this.openConfirmModal = this.openConfirmModal.bind(this);
    this.listenKey = this.listenKey.bind(this);
    this.state = {
      showFullScreenPage: null,
      openModal: false,
      submitting: false,
    };
  }

  componentDidMount() {
    document.addEventListener('click', this.listenClick);
    document.addEventListener('keydown', this.listenKey);

    this.props.loadPdfEditorAction(this.props.prepareEditingUrl);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.listenClick);
    document.removeEventListener('keydown', this.listenKey);
  }

  listenClick(e) {
    if (!!e.target || !!e.target.parentNode) {
      return;
    }

    if (!e.target.parentNode.classList) {
      return;
    }

    if (e.target.parentNode.classList.contains('menu-btn')) {
      return;
    }

    if (e.target.classList.contains('menu')) {
      return;
    }

    if (e.target.classList.contains('menu-btn')) {
      return;
    }

    if (e.target.classList.contains('pdf')) {
      return;
    }

    if (e.target.classList.contains('pdfFullScreen')) {
      return;
    }

    this.props.resetPickPdfAction();
  }

  listenKey(e) {
    if (e.ctrlKey && e.keyCode === 90 && this.props.prevPages.length !== 0) {
      this.props.revertLastAction();
    }

    if (e.key === 'Escape') {
      if (this.state.showFullScreenPage) {
        this.closeFullscreen();
      } else {
        this.openConfirmModal();
      }
    }
  }

  removePage() {
    this.props.removePageAction();
    this.setState({
      showFullScreenPage: null,
    });
  }

  async submit() {
    this.setState({
      submitting: true,
    });

    this.props.onSubmit(normalizePages(this.props.pages, this.props.files));
  }

  counterClockwiseRotation() {
    this.props.rotatePdfAction(270);
  }

  closeEditor() {
    this.props.leavePdfEditorAction();

    this.props.onLeave();
  }

  clockwiseRotation() {
    this.props.rotatePdfAction(90);
  }

  revertLastChange() {
    this.props.revertLastAction();
  }

  closeFullscreen() {
    this.setState({
      showFullScreenPage: null,
    });
  }

  openConfirmModal() {
    if (
      !this.props.eligibilityInformation.eligibility ||
      this.props.prevPages.length === 0 ||
      arePageIdentical(this.props.prevPages[0], this.props.pages)
    ) {
      this.closeEditor();
      return;
    }

    this.setState({ openModal: true });
  }

  render() {
    const {
      loaded,
      pages,
      eligibilityInformation,
      pickedPageNumbers,
      canEdit,
      addingFile,
      cantEditMessage,
      canDeleteOrDragPage,
      pdfPageErrorsStatus,
      prevPages,
    } = this.props;

    if (!loaded) {
      return (
        <LoadingScreen
          theme={this.props.theme}
          zIndex={1000}
          text="Chargement en cours"
        />
      );
    }

    return (
      <DarkOverlay
        style={{ cursor: addingFile ? 'wait' : 'auto' }}
        background={DARK_COLOR}
        color={LIGHT_COLOR}
      >
        {addingFile && (
          <LoadingScreen
            theme={this.props.theme}
            zIndex={1000}
            text="Ajout et conversion des fichiers en cours"
          />
        )}
        {eligibilityInformation.eligibility && canEdit && (
          <CommandButtonsContainer className="menu" background={DARK_COLOR}>
            <Div margin=".5em">
              {pages.length > 0 && (
                <div>
                  <Button
                    title="valider, enregistrer et quitter"
                    onClick={this.submit}
                    className="menu-btn"
                    disabled={this.state.submitting}
                    marginBottom={0.5}
                  >
                    <IconSave />
                  </Button>
                </div>
              )}
              <div>
                <Button
                  title="Annuler la dernière opération"
                  onClick={this.revertLastChange}
                  className="menu-btn"
                  disabled={this.state.submitting}
                  marginBottom={0.5}
                >
                  <IconChevronLeftHeavy />
                </Button>
              </div>
              <div>
                <Button
                  title={getPdfErrorTitle(pdfPageErrorsStatus, prevPages)}
                  onClick={() => {
                    this.props.onDetectErrors();
                  }}
                  className="menu-btn"
                  disabled={isDetectRotationDisabled(
                    pdfPageErrorsStatus,
                    prevPages
                  )}
                >
                  <IconErrorsScan />
                </Button>
              </div>
            </Div>
            {pickedPageNumbers.length > 0 && (
              <Fragment>
                <Div margin=".5em">
                  <div>
                    <Button
                      title="rotation anti-horaire -90°"
                      onClick={this.counterClockwiseRotation}
                      className="menu-btn"
                      disabled={this.state.submitting}
                      marginBottom={0.5}
                    >
                      <IconReload mirror />
                    </Button>
                  </div>
                  <div>
                    <Button
                      title="rotation horaire +90°"
                      onClick={this.clockwiseRotation}
                      className="menu-btn"
                      disabled={this.state.submitting}
                      marginBottom={0.5}
                    >
                      <IconReload />
                    </Button>
                  </div>
                </Div>
                <Div margin=".5em">
                  <div>
                    {canDeleteOrDragPage && (
                      <Button
                        title="Supprimer la sélection"
                        backgroundColor={DANGER_COLOR}
                        marginTop="1em"
                        onClick={this.removePage}
                        className="menu-btn"
                        disabled={this.state.submitting}
                        marginBottom={0.5}
                        color={LIGHT_COLOR}
                        borderColor={DANGER_COLOR}
                        backgroundHoverColor={DANGER_COLOR_HOVER}
                        borderHoverColor={DANGER_COLOR_HOVER}
                        hoverColor={LIGHT_COLOR}
                      >
                        <IconTrash />
                      </Button>
                    )}
                  </div>
                </Div>
              </Fragment>
            )}
          </CommandButtonsContainer>
        )}
        <LightBox background={LIGHT_BACKGROUND_COLOR} color={DARK_COLOR}>
          <CloseButton
            background={DARK_COLOR}
            onClick={this.openConfirmModal}
          />
          <ScrollablePanel>
            <Title color={LIGHT_COLOR} background={DARK_COLOR}>
              Edition de {this.props.name}
            </Title>
            <Pages>
              {canEdit &&
                eligibilityInformation.eligibility &&
                pages.map(page => (
                  <Page
                    canDeleteOrDragPage={canDeleteOrDragPage}
                    rotate={page.rotation}
                    key={page.pageNumber}
                    page={page}
                    onClick={this.handleClick}
                    onFullScreen={page => {
                      this.setState({
                        showFullScreenPage: page,
                      });
                    }}
                  />
                ))}
              {!eligibilityInformation.eligibility && (
                <Alert bsStyle="danger">
                  <strong>
                    {' '}
                    <IconSpam /> Le document n'est pas éligible à l'édition.
                    Veuillez utiliser un logiciel externe (Adobe) pour le
                    manipuler. Le document doit respecter les règles suivantes :
                    <br />
                    <ul>
                      <li>
                        Ne pas excéder 500 pages (
                        {eligibilityInformation.numberOfPage} actuellement)
                      </li>
                      <li>
                        Ne pas excéder 150 Mo ({eligibilityInformation.filesize}{' '}
                        Mo actuellement)
                      </li>
                    </ul>
                  </strong>
                </Alert>
              )}
              {eligibilityInformation.eligibility && !pages.length && (
                <Alert bsStyle="danger">
                  <strong>
                    {' '}
                    <IconSpam /> Impossible de sauvegarder un pdf ne contenant
                    aucune page.
                  </strong>
                </Alert>
              )}
              {!canEdit && (
                <Alert bsStyle="warning">
                  <strong>
                    {' '}
                    <IconSpam /> {cantEditMessage}
                  </strong>
                </Alert>
              )}
            </Pages>
            {this.props.pdfDetectionId && <PdfErrors />}
          </ScrollablePanel>
          {this.state.showFullScreenPage && (
            <FullScreenPage
              page={this.state.showFullScreenPage}
              width={700}
              onClose={this.closeFullscreen}
              pages={this.props.pages}
            />
          )}
        </LightBox>
        {this.state.openModal && (
          <ModalConfirm
            title="Fermer l’éditeur ?"
            cancelLabel="Annuler"
            leaveLabel="Quitter l’éditeur"
            saveLabel="Sauvegarder et quitter"
            cancel={() => this.setState({ openModal: false })}
            leave={this.closeEditor}
            save={this.submit}
          >
            Vous n’avez pas enregistré vos modifications.
            <br />
            Êtes-vous sûr(e) de vouloir quitter l’éditeur&nbsp;?
          </ModalConfirm>
        )}
        {this.state.submitting && (
          <LoadingScreen zIndex={1000} title="Sauvegarde en cours" />
        )}
      </DarkOverlay>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    ...props,
    pdfDetectionId: state.pdfEditor.pdfPageErrors.pdfDetectionId,
    loaded: state.pdfEditor.loaded,
    pdf: state.pdfEditor.pdf,
    pages: state.pdfEditor.pages,
    files: state.pdfEditor.files,
    addingFile: state.pdfEditor.addingFile,
    pickedPageNumbers: state.pdfEditor.pickedPageNumbers,
    prevPages: state.pdfEditor.prevPages,
    pdfPageErrorsStatus: state.pdfEditor.pdfPageErrors.status,
    eligibilityInformation: state.pdfEditor.eligibilityInformation,
  };
}

export default connect(mapStateToProps, {
  loadPdfEditorAction,
  resetPickPdfAction,
  removePageAction,
  rotatePdfAction,
  revertLastAction,
  leavePdfEditorAction,
  editPdfAction,
})(PdfEditor);
