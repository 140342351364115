import styled from 'styled-components';

const Alert = styled.div`
  display: flex;
  align-items: flex-start;
  color: ${props => {
    if (props.alertType == 'secondary') {
      return props.theme.secondaryColor;
    }
    if (props.alertType == 'success') {
      return props.theme.successColor;
    }
    if (props.alertType == 'danger') {
      return props.theme.dangerColor;
    }
    if (props.alertType == 'warning') {
      return props.theme.warningColor;
    }
    if (props.alertType == 'info') {
      return props.theme.infoColor;
    }
    return props.theme.primaryColor;
  }};
  background-color: ${props => {
    if (props.alertType == 'secondary') {
      return props.theme.secondaryLightColor;
    }
    if (props.alertType == 'success') {
      return props.theme.successLightColor;
    }
    if (props.alertType == 'danger') {
      return props.theme.dangerLightColor;
    }
    if (props.alertType == 'warning') {
      return props.theme.warningLightColor;
    }
    if (props.alertType == 'info') {
      return props.theme.infoLightColor;
    }
    return props.theme.primaryLightColor;
  }};
  border-color: ${props => {
    if (props.alertType == 'secondary') {
      return props.theme.secondaryColor;
    }
    if (props.alertType == 'success') {
      return props.theme.successColor;
    }
    if (props.alertType == 'danger') {
      return props.theme.dangerColor;
    }
    if (props.alertType == 'warning') {
      return props.theme.warningColor;
    }
    if (props.alertType == 'info') {
      return props.theme.infoColor;
    }
    return props.theme.primaryColor;
  }};
  border-width: 1px;
  border-style: solid;
  margin: ${props => (props.margin ? props.margin : '1em 0')};
  padding: 0 1em;
  font-size: ${props => (props.fontSize ? props.fontSize : '1rem')};
  ${props => (props.textAlign ? 'text-align: ' + props.textAlign + ';' : null)}
`;

Alert.ContentWithOrWithoutIcon = styled.div`
  display: flex;
  width: auto;
  flex-shrink: 1;
  flex-grow: 1;
`;

Alert.ContentText = styled.div`
  display: flex;
  flex-grow: 1;
  white-space: pre-wrap;
  flex-wrap: wrap;
`;

Alert.ClosePlaceholder = styled.div`
  width: auto;
  flex-shrink: 0;
  flex-grow: 0;
  padding: 0.5em 0 0 1em;
  margin-right: -0.5em;
`;

export default Alert;
