import React from 'react';
import styled from 'styled-components';

const FieldAndHelper = styled.div`
  display: block;
  @media (min-width: ${props => props.theme.tablet}) {
    ${props => {
      if (props.isHorizontal) {
        return 'flex-grow: 1;';
      }
    }}
  }
`;

export default FieldAndHelper;
