import styled from 'styled-components';
import {
  DANGER_COLOR,
  INFO_COLOR,
  SUCCESS_COLOR,
  WARNING_COLOR,
} from './theme_blue';

const Div = styled.div`
  ${props => (props.display ? 'display: ' + props.display + ';' : null)}
  ${props => (props.margin ? 'margin: ' + props.margin + ';' : null)}
  ${props => (props.padding ? 'padding: ' + props.padding + ';' : null)}
  ${props => (props.zIndex ? 'z-index: ' + props.zIndex + ';' : null)}
  ${props =>
    props.alignItems ? 'align-items: ' + props.alignItems + ';' : null}
  ${props =>
    props.verticalAlign ? 'align-items: ' + props.verticalAlign + ';' : null}
  ${props => (props.flexDirectionColumn ? 'flex-direction: column;' : null)};
  ${props =>
    props.justifyContent
      ? 'justify-content: ' + props.justifyContent + ';'
      : null}
  width: ${props => (props.width ? props.width : '100%')};
  ${props => (props.maxWidth ? 'max-width: ' + props.maxWidth + ';' : null)}
  ${props => (props.minWidth ? 'min-width: ' + props.minWidth + ';' : null)}
  flex-shrink: ${props => (props.flexShrink ? 'shrink' : 0)};
  ${props => (props.flexGrow ? 'flex-grow: 1;' : null)}
  ${props =>
    props.border
      ? 'border: solid 1px ' + props.theme[props.border] + ';'
      : null}
  ${props => (props.padding ? 'padding: ' + props.padding + ';' : null)}
  ${props => (props.float ? 'float: ' + props.float + ';' : null)}
  ${props => (props.marginTop ? 'margin-top: ' + props.marginTop + ';' : null)}
  ${props =>
    props.marginBottom ? 'margin-bottom: ' + props.marginBottom + ';' : null}
  ${props =>
    props.marginLeft ? 'margin-left: ' + props.marginLeft + ';' : null}
  ${props =>
    props.marginRight ? 'margin-right: ' + props.marginRight + ';' : null}
  color: ${props => {
    if (props.type == 'SUCCESS') {
      return props.theme[SUCCESS_COLOR];
    }
    if (props.type == 'ERROR') {
      return props.theme[DANGER_COLOR];
    }
    if (props.type == 'INFO') {
      return props.theme[INFO_COLOR];
    }
    if (props.type == 'WARNING') {
      return props.theme[WARNING_COLOR];
    }
    return props.theme[props.color];
  }};
  ${props =>
    props.backgroundColor
      ? 'background-color: ' + props.theme[props.backgroundColor] + ';'
      : null}
  ${props => (props.fontSize ? 'font-size : ' + props.fontSize + ';' : null)}
  ${props => (props.flexWrap ? 'flex-wrap: wrap;' : null)}
  ${props => (props.fold ? 'display: none;' : null)};
  ${props => (props.height ? 'height: ' + props.height + ';' : null)};
  ${props => (props.textAlign ? 'text-align: ' + props.textAlign + ';' : null)}
  ${props =>
    props.lineHeight ? 'line-height: ' + props.lineHeight + ';' : null}
  transition: display .3s;
  ${props =>
    props.fontWeight ? 'font-weight: ' + props.fontWeight + ';' : null}
  @media (min-width: ${props => props.theme.tablet}) {
    ${props => (props.hiddenForMd ? 'display: none;' : null)}
    ${props =>
      props.paddingForMd ? 'padding: ' + props.paddingForMd + ';' : null}
    ${props =>
      props.positionForMd ? 'position: ' + props.positionForMd + ';' : null}

  }
`;

export default Div;
