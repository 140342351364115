import styled from 'styled-components';
import { BODY_FONT, PRIMARY_COLOR, LIGHT_COLOR } from '../theme_blue';

const Title = styled.div`
  background-color: ${props =>
    props.background
      ? props.theme[props.background]
      : props.theme[PRIMARY_COLOR]};
  color: ${props =>
    props.color ? props.theme[props.color] : props.theme[LIGHT_COLOR]};
  font-size: 1.5em;
  margin: 0;
  padding: 0.25em 2.5em 0.5em 0;
  font-family: ${props => props.theme[BODY_FONT]};
  font-weight: 300;
  line-height: 1.1;
  align-self: flex-start;
  width: 100%;
`;

Title.Span = styled.span`
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default Title;
