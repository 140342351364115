const BEGIN_DRAG = 'BEGIN_DRAG_NODE';
const END_DRAG = 'END_DRAG_NODE';

export const beginDragAction = nodeId => ({
  type: BEGIN_DRAG,
  nodeId,
});

export const endDragAction = () => ({
  type: END_DRAG,
});

const draggedNodeIsOneOfPickedNodeOrChildrenOfOnePickedNode = (
  nodeId,
  pickedNodeIds,
  nodes
) =>
  pickedNodeIds.findIndex(
    pickedNodeId =>
      nodeId === pickedNodeId ||
      nodes[nodeId].path.startsWith(`${nodes[pickedNodeId].path}.`)
  ) !== -1;

export default function reducer(state, action) {
  switch (action.type) {
    case BEGIN_DRAG: {
      let nodeIdsToDrag = [action.nodeId];

      if (
        draggedNodeIsOneOfPickedNodeOrChildrenOfOnePickedNode(
          action.nodeId,
          state.working.pickedNodeIds,
          state.working.nodes
        )
      ) {
        nodeIdsToDrag = [...state.working.pickedNodeIds];
      }

      const multipleDrag = nodeIdsToDrag.length > 1;

      const newNodes = { ...state.working.nodes };

      nodeIdsToDrag.forEach(nodeId => {
        newNodes[nodeId] = {
          ...newNodes[nodeId],
          dragging: true,
          multipleDrag,
        };
      });

      newNodes[action.nodeId] = {
        ...newNodes[action.nodeId],
        dragging: true,
        multipleDrag,
      };

      return {
        ...state,
        working: {
          ...state.working,
          nodes: newNodes,
          draggingNodeIds: nodeIdsToDrag,
          nodeIdDragInitiator: action.nodeId,
        },
      };
    }
    case END_DRAG: {
      const newNodes = { ...state.working.nodes };
      state.working.draggingNodeIds.forEach(nodeId => {
        newNodes[nodeId] = {
          ...newNodes[nodeId],
          dragging: false,
          multipleDrag: false,
        };
      });

      newNodes[state.working.nodeIdDragInitiator] = {
        ...newNodes[state.working.nodeIdDragInitiator],
        dragging: false,
        multipleDrag: false,
      };

      return {
        ...state,
        working: {
          ...state.working,
          nodes: newNodes,
          draggingNodeIds: [],
          nodeIdDragInitiator: null,
        },
      };
    }
  }

  return state;
}
