import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  display: ${props => (props.hiddenForXs ? 'none' : 'inline-block')};
  ${props => (props.cursor ? 'cursor: ' + props.cursor + ';' : null)};
  ${props =>
    props.verticalAlignMiddle
      ? 'vertical-align: middle; align-self: center;'
      : null}
  ${props =>
    props.marginRight ? 'margin-right: ' + props.marginRight + ';' : null}
    ${props => (props.mirror ? 'transform: scaleX(-1);' : null)}
  ${props =>
    props.marginLeft ? 'margin-left: ' + props.marginLeft + ';' : null}
  width: ${props => (props.width ? props.width : '1em')};
  min-width: ${props => (props.width ? props.width : '1em')};
  height: ${props => (props.width ? props.width : '1em')};
  min-height: ${props => (props.width ? props.width : '1em')};
  max-width: 100%;
  ${props => (props.marginTop ? 'margin-top: ' + props.marginTop + ';' : null)}
  ${props =>
    props.marginBottom ? 'margin-bottom: ' + props.marginBottom + ';' : null}
  flex-shrink: 0;
  fill-rule: evenodd;
  clip-rule: evenodd;
  stroke-linejoin: round;
  stroke-miterlimit: 2;
  line-height: 1;
  @media (min-width: ${props => props.theme.tablet}) {
    display: ${props => (props.hiddenForMd ? 'none' : 'inline-block')};
  }
`;
const Path = styled.path`
  fill: ${props => {
    if (props.freeColor) {
      return props.freeColor;
    }
    if (props.color) {
      return props.theme[props.color];
    }
    return 'currentColor';
  }};
`;
const Antislash = styled.path`
  fill: ${props => {
    if (props.freeColor) {
      return props.freeColor;
    }
    if (props.color) {
      return props.theme[props.color];
    }
    return 'currentColor';
  }};
`;
class SvgIcon extends React.Component {
  render() {
    return (
      <Svg
        viewBox="0 0 256 256"
        xmlns="http://www.w3.org/2000/svg"
        width={this.props.width}
        marginRight={this.props.marginRight}
        marginLeft={this.props.marginLeft}
        alt={this.props.alt}
        cursor={this.props.cursor}
        onClick={this.props.onClick}
        marginTop={this.props.marginTop}
        marginBottom={this.props.marginBottom}
        verticalAlignMiddle={this.props.verticalAlignMiddle}
        borderColor={this.props.borderColor}
        hiddenForXs={this.props.hiddenForXs}
        hiddenForMd={this.props.hiddenForMd}
        mirror={this.props.mirror}
      >
        {this.props.withAntislash && (
          <clipPath id="mask">
            <path d="M256,0l-239.642,0l105.491,256l134.151,0l0,-256Z" />
          </clipPath>
        )}
        {this.props.alt && <title>{this.props.alt}</title>}
        {this.props.withAntislash && (
          <g clipPath="url(#mask)">
            <Path
              freeColor={this.props.freeColor}
              color={this.props.color}
              d={this.props.d}
            />
            {this.props.d2 && (
              <Path
                freeColor={this.props.freeColor}
                color={this.props.color}
                d={this.props.d2}
              />
            )}
            {this.props.d3 && (
              <Path
                freeColor={this.props.freeColor}
                color={this.props.color}
                d={this.props.d3}
              />
            )}
          </g>
        )}
        {!this.props.withAntislash && (
          <g>
            <Path
              freeColor={this.props.freeColor}
              color={this.props.color}
              d={this.props.d}
            />
            {this.props.d2 && (
              <Path
                freeColor={this.props.freeColor}
                color={this.props.color}
                d={this.props.d2}
              />
            )}
            {this.props.d3 && (
              <Path
                freeColor={this.props.freeColor}
                color={this.props.color}
                d={this.props.d3}
              />
            )}
          </g>
        )}
        {this.props.withAntislash && (
          <Antislash
            freeColor={this.props.freeColor}
            color={this.props.color}
            d="M118.326,235.408l-90.882,-219.408l-11.086,4.592l90.882,219.408l11.086,-4.592Z"
          />
        )}
      </Svg>
    );
  }
}

export default SvgIcon;
