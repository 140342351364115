(function (t) {
// en
t.add("select", "Select", "edm\u002Ddataroom", "en");
t.add("deselect", "Deselect", "edm\u002Ddataroom", "en");
t.add("fold", "Fold", "edm\u002Ddataroom", "en");
t.add("unfold", "Unfold", "edm\u002Ddataroom", "en");
t.add("print_selection", "Print selection", "edm\u002Ddataroom", "en");
t.add("receive", "Receive", "edm\u002Ddataroom", "en");
t.add("the_selection", "the selection", "edm\u002Ddataroom", "en");
t.add("asset.new_documents", "New documents", "edm\u002Ddataroom", "en");
t.add("export_summary_no_new_line", "Export index", "edm\u002Ddataroom", "en");
t.add("export_dashboard_no_new_line", "Export the dashboard", "edm\u002Ddataroom", "en");
t.add("export-error", "An error occured during export", "edm\u002Ddataroom", "en");
t.add("is_favorite_question", "Bookmarks ?", "edm\u002Ddataroom", "en");
t.add("warning-documents-type-print", "Beware: your selection contains %documentsNumber% document(s) which are not pdf type files, they will not be in printed if they cannot be  previewed", "edm\u002Ddataroom", "en");
t.add("documents_printing", "Print documents", "edm\u002Ddataroom", "en");
t.add("wk_document", "Documents to be provided by the office", "edm\u002Ddataroom", "en");
t.add("client_document", "Documents to be provided by the client", "edm\u002Ddataroom", "en");
t.add("edm.search.form_title", "Search for %begin_html_tag% Documents %end_html_tag% in the database", "edm\u002Ddataroom", "en");
t.add("reinitialize", "RESET", "edm\u002Ddataroom", "en");
t.add("send_selection", "Send selection", "edm\u002Ddataroom", "en");
t.add("selection_sent", "E-mail sent", "edm\u002Ddataroom", "en");
t.add("you_selected", "You have selected", "edm\u002Ddataroom", "en");
t.add("selected_documents_number", "%count% documents", "edm\u002Ddataroom", "en");
t.add("total_size_of", "for a total size of", "edm\u002Ddataroom", "en");
t.add("selection_error", "An error occured while sending", "edm\u002Ddataroom", "en");
t.add("document", "Document", "edm\u002Ddataroom", "en");
t.add("mega_bytes", "Mb", "edm\u002Ddataroom", "en");
t.add("last_updated_at", "Latest update", "edm\u002Ddataroom", "en");
t.add("see-full-screen", "See full screen", "edm\u002Ddataroom", "en");
t.add("dataroom", "Dataroom", "edm\u002Ddataroom", "en");
t.add("document.see_document", "See document", "edm\u002Ddataroom", "en");
})(Translator);
