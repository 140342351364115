import React, { Fragment, useState } from 'react';
import Span from '../../../presentational/components/Span';
import {
  BODY_FONT,
  CLIENT_UI_COLOR,
  DANGER_COLOR,
  DARK_GREY_COLOR,
  LIGHT_COLOR,
  LIGHT_GREY_COLOR,
  MID_GREY_COLOR,
} from '../../../presentational/components/theme_blue';
import Div from '../../../presentational/components/Div';
import GridCol from '../../../presentational/components/GridCol';
import Button from '../../../presentational/components/Button';
import 'translations/messages/fr';
import 'translations/messages/en';
import GridRow from '../../../presentational/components/GridRow';
import Hr from '../../../presentational/components/Hr';
import H3 from '../../../presentational/components/H3';
import IconCheckmark from '../../../presentational/components/img/icons/IconCheckmark';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import Form from '../../../presentational/components/form/Form';
import useNotification from '../../../app/hooks/notification';
import useApiClient from '../../../app/hooks/apiClient';
import { TYPE_ERROR } from '../../../dataroom/app/reducers/notifications';
import Input from '../../../presentational/components/form/Input';
import { browserHistory, Link } from 'react-router';
import IconCloseSimple from '../../../presentational/components/img/icons/IconCloseSimple';
import A from '../../../presentational/components/A';
import Alert from '../../../presentational/components/Alert';
import P from '../../../presentational/components/P';
import IconSpam from '../../../presentational/components/img/icons/IconSpam';

export default function UserPasswordForm(props) {
  const apiClient = useApiClient();
  const [hasError, setHasError] = useState(false);
  const { onSendNotificationReminder } = useNotification();

  const {
    register,
    handleSubmit,
    control,
    setError,
    formState: { isSubmitting, errors },
  } = useForm({
    criteriaMode: 'all',
    defaultValues: props.defaultValues,
  });

  const passwordValue = useWatch({
    control,
    name: 'password',
  });

  const onSubmit = async data => {
    let url = `/api/user/${props.id}/update-password`;
    let method = `PATCH`;
    let message = 'Mot de passe modifié';

    if (props.resetToken) {
      url = `/api/user/reset-password`;
      method = `POST`;

      data = {
        ...data,
        token: props.resetToken,
      };
    }

    const response = await apiClient(url, {
      method,
      body: JSON.stringify(data),
    });

    if (response.ok) {
      onSendNotificationReminder(message);
      browserHistory.push('/');

      return;
    }

    if (
      response.status === 400 &&
      response.headers.get('Content-Type') === 'application/json'
    ) {
      const formErrors = await response.json();
      formErrors.forEach(error => {
        setError(error.propertyPath, {
          type: 'manual',
          types: {
            manual: error.message,
          },
        });
      });
      return;
    }

    if (
      response.status === 400 &&
      response.headers.get('Content-Type') === 'application/json+error'
    ) {
      const error = await response.json();
      setHasError(error.message);
      return;
    }

    onSendNotificationReminder('La modification a échoué', TYPE_ERROR);
  };

  return (
    <Div maxWidth="50rem" margin="0 auto" fontWeight="300">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Input
          {...register('password', {
            required: 'Le mot de passe est obligatoire',
            pattern: {
              value: /(?=.*\d)(?=.*[A-Za-z])(?=.*[!#$*]).{8,}/,
              message: 'Le mot de passe est non valide',
            },
          })}
          displayBlock
          isHorizontal
          label="Mot de passe"
          isLabelBold
          type="password"
          isRequired
          labelTextRight
          errors={errors.password?.types}
          formHelper="Plus de 8 caractères dont 1 chiffre, et 1 caractère spécial parmi: !#$*"
          labelColor={DARK_GREY_COLOR}
        />
        <Input
          {...register('passwordConfirmation', {
            validate: value =>
              passwordValue === value || "Le mot de passe n'est pas identique",
          })}
          displayBlock
          isHorizontal
          label="Confirmation"
          isLabelBold
          type="password"
          isRequired
          labelTextRight
          errors={errors.passwordConfirmation?.types}
          labelColor={DARK_GREY_COLOR}
        />
        <Hr />
        <GridRow>
          <GridCol textAlign="right">
            <A as={Link} to={'/'}>
              <Button
                type="cancel"
                backgroundColor={DARK_GREY_COLOR}
                borderColor={DARK_GREY_COLOR}
                backgroundHoverColor={LIGHT_COLOR}
              >
                <IconCloseSimple marginRight=".5em" />
                Annuler
              </Button>
            </A>
          </GridCol>
          <GridCol textAlign="right">
            <Button
              type="submit"
              disabled={isSubmitting}
              backgroundColor={CLIENT_UI_COLOR}
              borderColor={CLIENT_UI_COLOR}
              backgroundHoverColor={LIGHT_COLOR}
            >
              <IconCheckmark marginRight=".5em" />
              Valider
            </Button>
          </GridCol>
        </GridRow>
        {hasError && (
          <Alert alertType="danger" role="alert">
            <Alert.ContentWithOrWithoutIcon>
              <P marginTop="1rem" marginBottom="0">
                <IconSpam marginRight="1em" width="1.5em" marginTop="-.125em" />
              </P>
              <Alert.ContentText>
                <P marginTop="1rem" marginBottom="1rem">
                  {Translator.trans(hasError, {}, 'messages')}
                </P>
              </Alert.ContentText>
            </Alert.ContentWithOrWithoutIcon>
          </Alert>
        )}
      </Form>
    </Div>
  );
}
