import React, { Fragment } from 'react';
import ClientUI from '../../presentational/components/ClientUI';
import { ThemeProvider } from 'styled-components';
import theme_blue from '../../presentational/components/theme_blue';

export default function Layout(props) {
  return (
    <ThemeProvider theme={theme_blue}>
      <ClientUI isOrangeSkin={props.isOrangeSkin} className={props.className}>
        {props.children}
      </ClientUI>
    </ThemeProvider>
  );
}
