import React, { Fragment } from 'react';
import ClientUI from '../../presentational/components/ClientUI';
import { ThemeProvider } from 'styled-components';
import theme_blue, {
  BODY_FONT,
  CLIENT_UI_COLOR,
  DANGER_COLOR,
  DARK_GREY_COLOR,
  LIGHT_COLOR,
  LIGHT_GREY_COLOR,
  MID_GREY_COLOR,
} from '../../presentational/components/theme_blue';
import Helmet from 'react-helmet';
import Layout from '../../app/layout/Layout';
import { Menu } from '../../app/components/menu/Menu';
import Content from '../../app/layout/Content';
import Div from '../../presentational/components/Div';
import H2 from '../../presentational/components/H2';
import Span from '../../presentational/components/Span';
import GridRow from '../../presentational/components/GridRow';
import GridCol from '../../presentational/components/GridCol';
import IconUser from '../../presentational/components/img/icons/IconUser';
import Button from '../../presentational/components/Button';
import { useAuthDataContext } from '../../app/hooks/authDataProvider';
import IconLockUnlocked from '../../presentational/components/img/icons/IconLockUnlocked';
import { Link } from 'react-router';
import A from '../../presentational/components/A';

export default function MyAccount(props) {
  const { user, onLogout } = useAuthDataContext();

  return (
    <Fragment>
      <Helmet>
        <title>Espace client - Mon compte</title>
      </Helmet>

      <Layout>
        <Menu me />

        <Content>
          <H2>
            <Span
              color={CLIENT_UI_COLOR}
              fontFamily={BODY_FONT}
              display="flex"
              alignItems="center"
              justifyContent="center"
              minWidth="100%"
            >
              <IconUser marginRight=".5em" />
              Mon compte ({user.company.name})
            </Span>
          </H2>
          <Div maxWidth="24rem" margin="0 auto" fontWeight="300">
            <GridRow>
              <GridCol textAlign="center" xs={12 / 12} md={6 / 12}>
                <A as={Link} to={`/profile`}>
                  <Button
                    displayBlock
                    marginBottom={1}
                    backgroundColor={LIGHT_COLOR}
                    color={CLIENT_UI_COLOR}
                    borderColor={CLIENT_UI_COLOR}
                    backgroundHoverColor={CLIENT_UI_COLOR}
                  >
                    <IconUser marginRight=".5em" />
                    Mon profil
                  </Button>
                </A>
              </GridCol>
              <GridCol textAlign="center" xs={12 / 12} md={6 / 12}>
                <Button
                  displayBlock
                  marginBottom={1}
                  backgroundColor={LIGHT_COLOR}
                  color={DANGER_COLOR}
                  borderColor={DANGER_COLOR}
                  backgroundHoverColor={DANGER_COLOR}
                  onClick={async () => {
                    await fetch('/api/logout');

                    onLogout();
                  }}
                >
                  <IconLockUnlocked marginRight=".5em" />
                  Déconnexion
                </Button>
              </GridCol>
            </GridRow>
          </Div>
        </Content>
      </Layout>
    </Fragment>
  );
}
