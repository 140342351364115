import { createSelector } from 'reselect';
import nodesSelector from './workingNodesSelector';
import selectedNodesInContextMenu from './selectedNodesInContextMenu';
import pickedNodesSelector from './pickedNodesSelector';

const canRenameNode = (nodes, nodeIds) => {
  if (nodeIds.length !== 1) {
    return false;
  }

  return nodes[nodeIds[0].id];
};

export const canRenameFromQuickMenu = createSelector(
  [nodesSelector, pickedNodesSelector],
  canRenameNode
);

export const canRenameFromContextMenu = createSelector(
  [nodesSelector, selectedNodesInContextMenu],
  canRenameNode
);
