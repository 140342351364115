(function (t) {
// fr
t.add("asset.added", "Votre actif a \u00e9t\u00e9 cr\u00e9\u00e9", "messages", "fr");
t.add("wallet.added", "Votre Portefeuille a \u00e9t\u00e9 cr\u00e9\u00e9", "messages", "fr");
t.add("home.dashboard", "Tableau de bord", "messages", "fr");
t.add("home", "Accueil", "messages", "fr");
t.add("actions", "Actions", "messages", "fr");
t.add("collaborators", "Collaborateurs", "messages", "fr");
t.add("add", "Ajouter", "messages", "fr");
t.add("foundResult", "{0} Pas de r\u00e9sultat|{1} %count% r\u00e9sultat trouv\u00e9|]1,Inf[ %count% r\u00e9sultats trouv\u00e9s", "messages", "fr");
t.add("delete", "Supprimer", "messages", "fr");
t.add("searchResult", "R\u00e9sultats de la recherche", "messages", "fr");
t.add("searchOn", "Recherche des", "messages", "fr");
t.add("details", "D\u00e9tails", "messages", "fr");
t.add("searchAction", "Rechercher", "messages", "fr");
t.add("selectFilter", "S\u00e9lectionner un filtre", "messages", "fr");
t.add("yes", "Oui", "messages", "fr");
t.add("no", "Non", "messages", "fr");
t.add("language", "Langue", "messages", "fr");
t.add("FRENCH", "Fran\u00e7ais", "messages", "fr");
t.add("ENGLISH", "Anglais", "messages", "fr");
t.add("contact.added", "Le contact a \u00e9t\u00e9 cr\u00e9e", "messages", "fr");
t.add("open", "Ouvrir", "messages", "fr");
t.add("opened", "Ouvert", "messages", "fr");
t.add("close", "Fermer", "messages", "fr");
t.add("closed", "Ferm\u00e9", "messages", "fr");
t.add("next_step", "Etape suivante", "messages", "fr");
t.add("group.added", "Le groupe a \u00e9t\u00e9 cr\u00e9e", "messages", "fr");
t.add("group.edited", "Le groupe a \u00e9t\u00e9 \u00e9dit\u00e9", "messages", "fr");
t.add("group.deleted", "Le groupe a \u00e9t\u00e9 supprim\u00e9", "messages", "fr");
t.add("group.detached", "Le groupe a \u00e9t\u00e9 d\u00e9tach\u00e9", "messages", "fr");
t.add("validate_action", "Valider", "messages", "fr");
t.add("wallet.edited", "Portefeuille \u00e9dit\u00e9", "messages", "fr");
t.add("group.user_removed", "Utilisateur retir\u00e9 du groupe", "messages", "fr");
t.add("group.user_added", "Utilisateur ajout\u00e9 au groupe", "messages", "fr");
t.add("group.asset_added", "Actif ajout\u00e9 au groupe", "messages", "fr");
t.add("group.asset_removed", "Actif retir\u00e9 du groupe", "messages", "fr");
t.add("asset.group_added", "Groupe ajout\u00e9 avec succ\u00e8s", "messages", "fr");
t.add("activate", "Activ\u00e9", "messages", "fr");
t.add("deactivate", "D\u00e9sactiv\u00e9", "messages", "fr");
t.add("cancel", "Annuler", "messages", "fr");
t.add("save", "Enregistrer", "messages", "fr");
t.add("third.edited", "Tiers \u00e9dit\u00e9", "messages", "fr");
t.add("detach", "Le d\u00e9tacher", "messages", "fr");
t.add("delete_2", "Le supprimer", "messages", "fr");
t.add("wallet.client_dataroom_open", "Les datarooms clients des actifs s\u00e9lectionn\u00e9s ont \u00e9t\u00e9 ouvertes", "messages", "fr");
t.add("wallet.client_dataroom_close", "Les datarooms clients des actifs s\u00e9lectionn\u00e9s ont \u00e9t\u00e9 ferm\u00e9es", "messages", "fr");
t.add("wallet.buyer_dataroom_open", "Les datarooms acqu\u00e9reurs des actifs s\u00e9lectionn\u00e9s ont \u00e9t\u00e9 ouvertes", "messages", "fr");
t.add("wallet.buyer_dataroom_close", "Les datarooms acqu\u00e9reurs des actifs s\u00e9lectionn\u00e9s ont \u00e9t\u00e9 ferm\u00e9es", "messages", "fr");
t.add("third.added", "Tiers Cr\u00e9\u00e9", "messages", "fr");
t.add("third.add_contact", "Contact ajout\u00e9 au tiers", "messages", "fr");
t.add("or", "ou", "messages", "fr");
t.add("wallet.asset_imported", "Actif import\u00e9 avec succ\u00e8s", "messages", "fr");
t.add("wallet.collaborator_added", "Collaborateur ajout\u00e9 avec succ\u00e8s", "messages", "fr");
t.add("wallet.collaborator_removed", "Collaborateur retir\u00e9 avec succ\u00e8s", "messages", "fr");
t.add("list_messages", "Liste", "messages", "fr");
t.add("export", "Exporter", "messages", "fr");
t.add("choose_element", "Choisissez un \u00e9l\u00e9ment", "messages", "fr");
t.add("password.updated", "Mot de passe mis \u00e0 jour", "messages", "fr");
t.add("information", "Informations", "messages", "fr");
t.add("edit", "Editer", "messages", "fr");
t.add("assistant", "Assistant", "messages", "fr");
t.add("previous", "Pr\u00e9c\u00e9dent", "messages", "fr");
t.add("next", "Suivant", "messages", "fr");
t.add("asset.edited", "Votre actif a \u00e9t\u00e9 \u00e9dit\u00e9", "messages", "fr");
t.add("separateReference", "S\u00e9parez les r\u00e9f\u00e9rences par un -", "messages", "fr");
t.add("contact.edited", "Votre contact a \u00e9t\u00e9 \u00e9dit\u00e9", "messages", "fr");
t.add("close_page", "Fermer cette page", "messages", "fr");
t.add("assets_archived", "Les actifs s\u00e9lectionn\u00e9s ont \u00e9t\u00e9 archiv\u00e9s", "messages", "fr");
t.add("assets_deleted", "Les actifs s\u00e9lectionn\u00e9s ont \u00e9t\u00e9 supprim\u00e9s", "messages", "fr");
t.add("group.archived", "groupe archiv\u00e9", "messages", "fr");
t.add("CANT_MOVE_NON_DELETABLE_ASSET", "Il n'est pas possible de deplacer un actif non supprimable", "messages", "fr");
t.add("referent-updated", "Le r\u00e9frent a \u00e9t\u00e9 mis \u00e0 jour", "messages", "fr");
t.add("download", "T\u00e9l\u00e9charger", "messages", "fr");
t.add("notification_preferences_updated", "Pr\u00e9f\u00e9rences de notification mise \u00e0 jour", "messages", "fr");
t.add("connected_as", "Vous \u00eates connect\u00e9 en tant que ", "messages", "fr");
t.add("non_manageable_element", "\n                    Ce %element% est associ\u00e9 \u00e0 au moins un portefeuille dans lequel vous n\u2018\u00eates pas membre du groupe. Pour des raisons de s\u00e9curit\u00e9, vous devez demander \u00e0 un associ\u00e9 d\u2018effectuer les actions\n                ", "messages", "fr");
t.add("contact.add_third", "\n                    Tiers ajout\u00e9 au contact\n                ", "messages", "fr");
t.add("wallet.asset_exported", "Actifs export\u00e9s avec succ\u00e8s", "messages", "fr");
t.add("wallet.no_asset_selected_for_import", "Vous devez s\u00e9lectionner un actif \u00e0 importer", "messages", "fr");
t.add("wallet.client_group_assets_open", "Les acc\u00e8s aux dataroom clients des actifs s\u00e9lectionn\u00e9s ont \u00e9t\u00e9 ouverts", "messages", "fr");
t.add("wallet.client_group_assets_close", "Les acc\u00e8s aux dataroom clients des actifs s\u00e9lectionn\u00e9s ont \u00e9t\u00e9 ferm\u00e9s", "messages", "fr");
t.add("wallet.buyer_group_assets_open", "Les acc\u00e8s aux dataroom acqu\u00e9reurs des actifs s\u00e9lectionn\u00e9s ont \u00e9t\u00e9 ouverts", "messages", "fr");
t.add("wallet.buyer_group_assets_close", "Les acc\u00e8s aux dataroom acqu\u00e9reurs des actifs s\u00e9lectionn\u00e9s ont \u00e9t\u00e9 ferm\u00e9s", "messages", "fr");
t.add("group.asset_has_initialized_buyer_dataroom", "L'actif a au moins une dataroom acqu\u00e9reur initialis\u00e9e", "messages", "fr");
t.add("group.group_asset_open", "la liaison avec le groupe est ouverte", "messages", "fr");
t.add("group.dataroom_is_manipulable", "la dataroom est manipulable", "messages", "fr");
t.add("group.one_asset_has_initialized_buyer_dataroom", "un des actifs a au moins une dataroom acqu\u00e9reur initialis\u00e9e", "messages", "fr");
t.add("group.one_asset_has_group_asset_open", "un des actifs a sa liaison avec le groupe d'ouverte", "messages", "fr");
t.add("group.one_asset_has_dataroom_is_manipulable", "un des actifs a sa dataroom de manipulable", "messages", "fr");
t.add("wallet.asset_not_deletable", "L'actif s\u00e9lectionn\u00e9 ne peut \u00eatre d\u00e9plac\u00e9 car il n'est pas supprimable", "messages", "fr");
t.add("contact.add_third_error", "Vous ne pouvez pas ajouter un autre Tiers \u00e0 ce contact", "messages", "fr");
t.add("third.add_contact_error", "Ce contact est d\u00e9j\u00e0 li\u00e9 \u00e0 un autre Tiers", "messages", "fr");
t.add("export_wk1", "Exporter pour WK1", "messages", "fr");
t.add("click_here", "cliquez ici", "messages", "fr");
t.add("clicker_here", "cliquer ici", "messages", "fr");
t.add("group.buyer_group_asset_buyer_dataroom_open", "Une des liaisons avec le groupe est ouverte", "messages", "fr");
t.add("group.buyer_group_asset_buyer_dataroom_manipulable", "Une des datarooms du groupe est manipulable", "messages", "fr");
t.add("analytics_code.edited", "Le code analytique a \u00e9t\u00e9 \u00e9dit\u00e9", "messages", "fr");
t.add("dataroom_viewed", "A vu la dataroom", "messages", "fr");
t.add("question_answered", "A r\u00e9pondu \u00e0 une question", "messages", "fr");
t.add("question_asked", "A pos\u00e9 une question", "messages", "fr");
t.add("comment_asked", "A pos\u00e9 un commentaire", "messages", "fr");
t.add("no_interactions", "Aucune interactions depuis la connexion", "messages", "fr");
t.add("asset.multiple.added", "Les actifs ont \u00e9t\u00e9 ajout\u00e9s", "messages", "fr");
t.add("asset.multiple.no_asset_providen", "Aucun actif trouv\u00e9", "messages", "fr");
t.add("asset.multiple.empty_asset_name", "Nom de l'actif non valide", "messages", "fr");
t.add("asset.multiple.missing_values", "Donn\u00e9es manquantes dans l'import", "messages", "fr");
t.add("asset.multiple.template_not_found", "Type de dataroom non trouv\u00e9e", "messages", "fr");
t.add("import", "Importer", "messages", "fr");
t.add("home.wallet_favorite", "Mes portefeuilles favoris", "messages", "fr");
t.add("sort", "Trier", "messages", "fr");
t.add("sort_asc", "Tri alphab\u00e9tique", "messages", "fr");
t.add("sort_desc", "Tri alphab\u00e9tique invers\u00e9", "messages", "fr");
t.add("fold_unfold", "Plier \/ d\u00e9plier", "messages", "fr");
t.add("show_hide.options", "Afficher\/Masquer les options", "messages", "fr");
t.add("UserAlreadyExistException", "L'utilisateur existe d\u00e9j\u00e0", "messages", "fr");
t.add("UserNotFoundException", "L'utilisateur n'existe pas", "messages", "fr");
t.add("ResetTokenAlreadyRequestedException", "Un email de r\u00e9cup\u00e9ration a d\u00e9j\u00e0 \u00e9t\u00e9 envoy\u00e9", "messages", "fr");
t.add("InvalidResetTokenException", "Token invalide", "messages", "fr");
})(Translator);
