import PropTypes from 'prop-types';
import React, { Component } from 'react';
import DateTimePicker from './DateTimePicker.js';
import PanelHeader from '../../app/components/PanelHeader';
import 'translations/edm-dataroom/fr';
import 'translations/edm-dataroom/en';
import 'translations/messages/fr';
import 'translations/messages/en';

class Search extends Component {
  constructor(props) {
    super(props);
    this.beginDateChanged = this.beginDateChanged.bind(this);
    this.endDateChanged = this.endDateChanged.bind(this);
    this.textChanged = this.textChanged.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
  }

  beginDateChanged(date) {
    this.props.searchUpdated(
      Object.assign({}, this.props.search, { beginDate: date })
    );
  }

  endDateChanged(date) {
    this.props.searchUpdated(
      Object.assign({}, this.props.search, { endDate: date })
    );
  }

  textChanged(evt) {
    this.props.searchUpdated({
      ...this.props.search,
      text: evt.currentTarget.value,
    });
  }

  clearSearch() {
    this.props.searchUpdated({
      ...this.props.search,
      text: '',
      beginDate: null,
      endDate: null,
    });
  }

  render() {
    const { search } = this.props;

    return (
      <section>
        <div className="panel panel-primary search-block">
          <PanelHeader icon="search">
            <span
              dangerouslySetInnerHTML={{
                __html: Translator.trans(
                  'edm.search.form_title',
                  {
                    begin_html_tag: '<span class="shadowed-dark">',
                    end_html_tag: '</span>',
                  },
                  'edm-dataroom'
                ),
              }}
            />
          </PanelHeader>
          <div className="panel-body">
            <form
              autoComplete="off"
              action="#"
              className="form-horizontal search-form"
            >
              <div className="form-group">
                <label className="control-label sr-only">
                  {Translator.trans('searchAction')}
                </label>
                <div className="col-md-6 col-lg-3">
                  <DateTimePicker
                    value={search.beginDate}
                    id="begin-date"
                    placeholder={Translator.trans(
                      'edm.search.from',
                      {},
                      'edm-dataroom'
                    )}
                    onChange={this.beginDateChanged}
                  />
                </div>
                <div className="col-md-6 col-lg-3 md-jump">
                  <DateTimePicker
                    value={search.endDate}
                    id="end-date"
                    placeholder={Translator.trans(
                      'edm.search.to',
                      {},
                      'edm-dataroom'
                    )}
                    onChange={this.endDateChanged}
                  />
                </div>
                <div className="col-md-6 col-lg-4 md-jump">
                  <div className="vertical-for-xs">
                    <input
                      onChange={this.textChanged}
                      value={search.text}
                      id="text-search"
                      type="text"
                      className="form-control"
                      placeholder={Translator.trans(
                        'edm.search.keywords',
                        {},
                        'edm-dataroom'
                      )}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-lg-2">
                  <div className="input-group-btn vertical-for-xs">
                    <button
                      onClick={this.clearSearch}
                      tabIndex="-1"
                      type="button"
                      className="btn btn-dark btn-block"
                    >
                      <span className="glyphicon glyphicon-remove" />{' '}
                      {Translator.trans('reinitialize', {}, 'edm-dataroom')}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    );
  }
}

Search.propTypes = {
  searchUpdated: PropTypes.func.isRequired,
  search: PropTypes.object.isRequired,
};

export default Search;
