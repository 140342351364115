import React, { Component } from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import Button from '../../app/components/Button';
import FieldInput from '../../app/components/FieldInput';
import { TYPE_CHOICE } from '../../app/components/Input';
import { reduxForm } from 'redux-form';
import { once } from 'underscore';

const dataRoomModelChoices = once(() => ({
  '': '',
  DATAROOM_MODEL_CORPORATE: Translator.trans(
    'DATAROOM_MODEL_CORPORATE',
    {},
    'asset-asset'
  ),
  DATAROOM_MODEL_FUNDING_NOTARY_CREDITOR: Translator.trans(
    'DATAROOM_MODEL_FUNDING_NOTARY_CREDITOR',
    {},
    'asset-asset'
  ),
  DATAROOM_MODEL_GENERAL: Translator.trans(
    'DATAROOM_MODEL_GENERAL',
    {},
    'asset-asset'
  ),
  DATAROOM_MODEL_HOTEL_BUSINESS: Translator.trans(
    'DATAROOM_MODEL_HOTEL_BUSINESS',
    {},
    'asset-asset'
  ),
  DATAROOM_MODEL_HOTEL_WALL: Translator.trans(
    'DATAROOM_MODEL_HOTEL_WALL',
    {},
    'asset-asset'
  ),
  DATAROOM_MODEL_PARIS_MIXED: Translator.trans(
    'DATAROOM_MODEL_PARIS_MIXED',
    {},
    'asset-asset'
  ),
  DATAROOM_MODEL_FUNDING_NOTARY_BORROWER: Translator.trans(
    'DATAROOM_MODEL_FUNDING_NOTARY_BORROWER',
    {},
    'asset-asset'
  ),
  DATAROOM_MODEL_SUCCESSION: Translator.trans(
    'DATAROOM_MODEL_SUCCESSION',
    {},
    'asset-asset'
  ),
  DATAROOM_MODEL_EMPTY: Translator.trans(
    'DATAROOM_MODEL_EMPTY',
    {},
    'asset-asset'
  ),
}));

const getFilteredDataRoomModelChoices = once(() => {
  const filteredDataRoomModelChoices = { ...dataRoomModelChoices() };

  delete filteredDataRoomModelChoices['DATAROOM_MODEL_EMPTY'];

  return filteredDataRoomModelChoices;
});

class AddTemplate extends Component {
  constructor(props) {
    super(props);
    this.hide = this.hide.bind(this);
    this.submit = this.submit.bind(this);
  }

  hide() {
    if (this.props.addingTemplate) {
      return;
    }

    this.props.onHide();
  }

  componentDidUpdate(nextProps) {
    if (!nextProps.addingTemplate && this.props.addingTemplate) {
      this.props.onHide();
      this.props.reset();
    }
  }

  submit(data) {
    this.props.onTemplateChosen(data.dataroomTemplate);
  }

  render() {
    const {
      handleSubmit,
      submitting,
      dirty,
      valid,
      addingTemplate,
    } = this.props;

    return (
      <Modal enforceFocus={false} onHide={this.hide} show={this.props.show}>
        <Modal.Header closeButton>
          <Modal.Title>Ajouter un modèle d'arborescence à la fin</Modal.Title>
          <Modal.Body>
            <form
              autoComplete="off"
              onSubmit={handleSubmit(this.submit)}
              className="form-horizontal group-border-dashed"
            >
              <FieldInput
                required
                name="dataroomTemplate"
                fieldType={TYPE_CHOICE}
                choices={getFilteredDataRoomModelChoices()}
                label={Translator.trans('dataroomModel', {}, 'asset-asset')}
              />

              <Button
                type="submit"
                disabled={!valid || !dirty}
                loading={submitting || addingTemplate}
              >
                Envoyer
              </Button>
            </form>
          </Modal.Body>
        </Modal.Header>
      </Modal>
    );
  }
}

AddTemplate = reduxForm({
  form: 'add-template',
})(AddTemplate);

export default AddTemplate;
