import React from 'react';

const LoadingIndicator = props => {
  return (
    <div id="please-wait">
      <div className="lbl">
        {props.title}
        <span>&hellip;</span>
      </div>
    </div>
  );
};

LoadingIndicator.defaultProps = {
  title: 'Traitement en cours',
};

export default LoadingIndicator;
