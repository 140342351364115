import styled from 'styled-components';

const A = styled.a`
  ${props => (props.display ? 'display: ' + props.display + ';' : null)}
  ${props =>
    props.alignItems ? 'align-items: ' + props.alignItems + ';' : null}
  ${props => (props.$noDecoration ? 'text-decoration: none;' : null)}
  color: ${props => {
    if (props.colorInherit) {
      return 'inherit';
    }
    if (props.color) {
      return props.theme[props.color];
    }
    return props.theme.secondaryColor;
  }};
  ${props => (props.margin ? 'margin: ' + props.margin + ';' : null)}
  ${props => (props.cursor ? 'cursor: ' + props.cursor + ';' : null)}
  ${props => (props.float ? 'float: ' + props.float + ';' : null)}
  ${props =>
    props.$wordBreak ? 'word-break: ' + props.$wordBreak + ';' : null}
  ${props => (props.width ? 'width: ' + props.width + ';' : null)}
  ${props => (props.flexWrap ? 'flex-wrap: ' + props.flexWrap + ';' : null)}
  &:hover {
    ${props => {
      if (props.$underlineOnHover) {
        return 'text-decoration: underline;';
      }
      if (!props.$noDecoration) {
        return 'text-decoration: none;';
      }
    }}
    ${props =>
      props.$hoverColor
        ? 'color: ' + props.theme[props.$hoverColor] + ';'
        : null}
  }
`;

export default A;
