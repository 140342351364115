import { createSelector } from 'reselect';
import selectedNodesInContextMenu from './selectedNodesInContextMenu';
import { uniq } from 'underscore';

const canMergedDocumentNodes = nodes => {
  let documentsType = nodes.map(node =>
    node.file ? node.file.extension : null
  );
  let uniqDocumentsType = uniq(documentsType);

  if (uniqDocumentsType.length > 1) {
    return false;
  }

  return uniqDocumentsType[0] === 'pdf';
};

export default createSelector([selectedNodesInContextMenu], nodes => {
  if (nodes.length > 1) {
    return canMergedDocumentNodes(nodes);
  }

  return false;
});
