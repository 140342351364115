import createUndoableActionReducer from '../reducers/createUndoableActionReducer';
import { uniqueId } from 'underscore';
import undoSaga from './dataroomActions/undoSaga';
import { takeEvery, call } from 'redux-saga/effects';
import addDocumentNumber, {
  addDocumentNumberWithoutFile,
} from './utils/addDocumentNumber';

import apiClient from 'js/dataroom/core/apiClient';

export const DETACH_FILE_TO_DOCUMENT = 'DETACH_FILE_TO_DOCUMENT';

export const detachFileToDocumentAction = nodeId => ({
  type: DETACH_FILE_TO_DOCUMENT,
  nodeId,
  frontActionId: uniqueId('dataroom_action'),
});

const detachFileApi = action =>
  apiClient.request(
    new Request(`/api/nodes/${action.nodeId}/file`, {
      method: 'DELETE',
    })
  );

export function* saga(action) {
  return yield call(detachFileApi, action);
}

export function* undoActionSaga() {
  yield takeEvery('UNDO_DETACH_FILE_TO_DOCUMENT', undoSaga);
}

function detachFileToDocument(state, action) {
  const node = state.working.nodes[action.nodeId];

  let newNodes = addDocumentNumber(node.parentId, state.working.nodes, -1);
  newNodes = addDocumentNumberWithoutFile(node.parentId, newNodes);

  let newNode = {
    ...node,
    file: null,
  };

  return {
    ...state,
    working: {
      ...state.working,
      savedNodes: {
        ...state.working.savedNodes,
        [action.frontActionId]: {
          [node.id]: { ...node },
        },
      },
      nodes: {
        ...newNodes,
        [node.id]: newNode,
      },
    },
  };
}

function undoDetachFileToDocument(state, action) {
  const node = state.working.nodes[action.nodeId];
  const oldNode = state.working.savedNodes[action.frontActionId][action.nodeId];
  let newNodes = addDocumentNumber(node.parentId, state.working.nodes, 1);
  newNodes = addDocumentNumberWithoutFile(node.parentId, newNodes, -1);

  return {
    ...state,
    working: {
      ...state.working,
      nodes: {
        ...newNodes,
        [node.id]: {
          ...node,
          file: oldNode.file,
        },
      },
    },
  };
}

export default createUndoableActionReducer(
  DETACH_FILE_TO_DOCUMENT,
  detachFileToDocument,
  undoDetachFileToDocument
);
