import React, { Fragment } from 'react';
import ClientUI from '../../presentational/components/ClientUI';

export default function Content(props) {
  return (
    <ClientUI.Main isOrangeSkin={props.isOrangeSkin}>
      {props.children}
    </ClientUI.Main>
  );
}
