import React from 'react';
import Svg from './Svg';

export default function IconEyeClosed(props) {
  return (
    <Svg
      {...props}
      d="M51.161 42.167l-8.994 8.994 38.908 38.713 69.41 69.409 11.926 12.122 42.428 42.428 8.994-8.994-38.908-38.908c26.493-13.124 44.26-32.603 45.36-33.825L224 128l-3.715-4.106c-1.686-1.882-41.694-45.947-92.285-45.947-12.244 0-23.804 2.688-34.411 6.648L51.161 42.167zM128 90.46c13.466 0 26.2 3.788 37.54 8.994 4.032 6.746 6.256 14.371 6.256 22.289 0 11.365-4.399 21.752-11.535 29.524l-17.793-17.792c2.616-3.202 4.302-7.259 4.302-11.732 0-10.362-8.407-18.769-18.77-18.769-4.473 0-8.53 1.686-11.731 4.301L103.56 94.566c7.772-2.395 15.91-4.106 24.44-4.106zm-58.265 5.67c-20.065 12.343-33.091 26.713-34.02 27.764L32 128l3.715 4.106c1.613 1.808 38.664 42.354 86.419 45.556 1.931.195 3.886.391 5.866.391 1.98 0 3.935-.196 5.866-.391 5.156-.342 10.191-1.027 15.055-2.151l-11.145-11.144c-3.177.733-6.403 1.173-9.776 1.173-24.147 0-43.796-19.65-43.796-43.797 0-3.323.44-6.574 1.173-9.776L69.735 96.13zm3.52 12.709c-1.002 4.228-1.565 8.53-1.565 12.904 0 10.876 3.055 20.897 8.408 29.524-14.322-8.212-25.271-17.841-30.892-23.267 4.668-4.521 13.075-12.024 24.049-19.161zm109.49 0c10.974 7.137 19.357 14.64 24.049 19.161-5.621 5.426-16.741 15.251-31.087 23.462 5.377-8.627 8.603-18.843 8.603-29.719 0-4.374-.563-8.7-1.565-12.904z"
    />
  );
}
