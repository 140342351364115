export default (actionName, doAction, undoAction) => (state, action) => {
  switch (action.type) {
    case actionName:
      return doAction(state, action);
    case `${actionName}_ERROR`:
      return undoAction(state, action.action);
    case `UNDO_${actionName}`:
      return undoAction(state, action.action);
    case `UNDO_${actionName}_ERROR`:
      return doAction(state, action.action);
  }

  return state;
};
