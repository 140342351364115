import {
  put,
  call,
  select,
  takeEvery,
  all,
  race,
  take,
} from 'redux-saga/effects';
import { delay } from 'redux-saga';
import reduceReducers from 'reduce-reducers';
import waitActionEnd from './dataroomActions/waitActionEnd';
import { UNLOCKED, UNLOCK_SUCCESS } from './lock';

// actions
export const UNDO = 'UNDO_DATAROOM_ACTION';
export const CANCEL_PREPARE_ACTION = 'CANCEL_PREPARE_ACTION';

export const undo = () => ({
  type: UNDO,
});

export const cancelPrepareAction = action => ({
  type: CANCEL_PREPARE_ACTION,
  action,
});

// saga
function* undoSaga() {
  const { pendingActions, currentAction, successfulActions } = yield select(
    state => state.dataroomActions
  );

  // if there is pending actions, undo the last one
  if (pendingActions.length > 0) {
    const actionToUndo = pendingActions[pendingActions.length - 1];

    yield put({
      type: `UNDO_${actionToUndo.type}`,
      undoType: 'pending',
      action: actionToUndo,
    });
    return;
  }

  if (currentAction) {
    // if there is a pending action, wait its end before undoing it
    const action = yield call(waitActionEnd, currentAction);

    if (action.type === `${currentAction.type}_ERROR`) {
      return;
    }

    yield put({
      type: `UNDO_${currentAction.type}`,
      undoType: 'successful',
      action: currentAction,
    });
    return;
  }

  // no successful action: there is nothing to do
  if (successfulActions.length === 0) {
    return;
  }

  const actionToUndo = successfulActions[successfulActions.length - 1];
  yield put({
    type: `UNDO_${actionToUndo.type}`,
    undoType: 'successful',
    action: actionToUndo,
  });
}

export function* saga() {
  yield takeEvery(UNDO, undoSaga);
}

// reducer
const initialState = {
  undoing: false,
  preparingActions: [],
  pendingActions: [],
  currentAction: null,
  successfulActions: [],
  successfulActionsNumber: 0,
};

function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case CANCEL_PREPARE_ACTION: {
      return {
        ...state,
        preparingActions: deleteAction(
          state.preparingActions,
          action.action.frontActionId
        ),
      };
    }
    case UNDO:
      return {
        ...state,
        undoing: true,
      };
    case UNLOCKED:
    case UNLOCK_SUCCESS:
      return {
        ...state,
        ...initialState,
        successfulActionsNumber: state.successfulActionsNumber,
      };
  }

  return state;
}

const deleteAction = (actionList, actionId) => {
  const index = actionList.findIndex(
    actionItem => actionItem.frontActionId === actionId
  );

  if (index === -1) {
    return [...actionList];
  }

  return [...actionList.slice(0, index), ...actionList.slice(index + 1)];
};

export const handleAction = actionName => {
  return (state, action) => {
    switch (action.type) {
      case actionName:
        return {
          ...state,
          pendingActions: [...state.pendingActions, action],
        };
      case `${actionName}_PREPARE`: {
        return {
          ...state,
          preparingActions: [...state.preparingActions, action.action],
        };
      }
      case `${actionName}_PREPARE_ERROR`: {
        return {
          ...state,
          preparingActions: deleteAction(
            state.preparingActions,
            action.action.frontActionId
          ),
          currentAction: null,
        };
      }
      case `${actionName}_BEGIN`: {
        return {
          ...state,
          preparingActions: deleteAction(
            state.preparingActions,
            action.action.frontActionId
          ),
          pendingActions: deleteAction(
            state.pendingActions,
            action.action.frontActionId
          ),
          currentAction: action.action,
        };
      }
      case `${actionName}_ERROR`: {
        return {
          ...state,
          currentAction: null,
        };
      }
      case `${actionName}_SUCCESS`: {
        return {
          ...state,
          currentAction: null,
          successfulActions: [...state.successfulActions, action.action],
          successfulActionsNumber: state.successfulActionsNumber + 1,
        };
      }
      case `UNDO_${actionName}`: {
        if (action.undoType === 'successful') {
          return state;
        }

        return {
          ...state,
          undoing: false,
          pendingActions: deleteAction(
            state.pendingActions,
            action.action.frontActionId
          ),
        };
      }
      case `UNDO_${actionName}_SUCCESS`:
        return {
          ...state,
          undoing: false,
          successfulActions: deleteAction(
            state.successfulActions,
            action.action.frontActionId
          ),
        };
      case `UNDO_${actionName}_ERROR`: {
        return {
          ...state,
          undoing: false,
        };
      }
    }

    return state;
  };
};

export default reduceReducers(
  reducer,
  handleAction('CHANGE_NODES_TYPE'),
  handleAction('ADD_NODES'),
  handleAction('COPY_NODES'),
  handleAction('MOVE_NODES'),
  handleAction('REMOVE_NODES'),
  handleAction('RENAME_NODE'),
  handleAction('ATTACH_FILE_TO_DOCUMENT'),
  handleAction('DETACH_FILE_TO_DOCUMENT'),
  handleAction('REMOVE_EMPTY_DOCUMENT_NODES'),
  handleAction('REMOVE_EMPTY_FOLDER_NODES'),
  handleAction('ADD_FILES'),
  handleAction('TRANSFORM_ARCHIVE_NODE_TO_FOLDER'),
  handleAction('PDF_EDIT'),
  handleAction('PDF_MERGE'),
  handleAction('PDF_EXPLODE')
);
