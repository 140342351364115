import styled from 'styled-components';
import { LIGHT_COLOR, PRIMARY_COLOR } from '../theme_blue';

export default styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: ${props =>
    props.background
      ? props.theme[props.background]
      : props.theme[PRIMARY_COLOR]};
  color: ${props =>
    props.color ? props.theme[props.color] : props.theme[LIGHT_COLOR]};
  z-index: 500;
  overflow: hidden;
  padding: 0.5em 0.5em 0 0;
  display: flex;
  align-items: stretch;
`;
