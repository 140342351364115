(function (t) {
// fr
t.add("profile", "Mon profil", "user", "fr");
t.add("user_documentation", "Documentation", "user", "fr");
t.add("etude_contacts", "Contacts \u00e9tude", "user", "fr");
t.add("logout", "D\u00e9connexion", "user", "fr");
t.add("settings", "R\u00e9glages", "user", "fr");
t.add("user.client", "Client", "user", "fr");
t.add("french", "Fran\u00e7ais", "user", "fr");
t.add("english", "Anglais", "user", "fr");
t.add("user_statistics_connections", "Stats. connexions", "user", "fr");
t.add("user_statistics_mails", "Stats. notifications", "user", "fr");
t.add("user_statistics_documents", "Stats. documents", "user", "fr");
t.add("user_statistics", "Statistiques", "user", "fr");
t.add("ROLE_SUPER_ADMIN", "Super Administrateur", "user", "fr");
t.add("ROLE_COMPANY_ADMIN", "Administrateur", "user", "fr");
t.add("ROLE_USER", "Utilisateur", "user", "fr");
})(Translator);
