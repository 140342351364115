import { difference } from 'underscore';
import { AFTER } from '../../dataroom/ducks/utils/getNextPath';

const MOVE_DOCUMENT = 'EDITOR_MERGED_MOVE_DOCUMENT';

export const moveDocumentAction = (
  nodeIdSource,
  nodeIdDestination,
  position
) => ({
  type: MOVE_DOCUMENT,
  nodeIdSource: nodeIdSource,
  nodeIdDestination: nodeIdDestination,
  position: position,
});

export default function reducer(state, action) {
  switch (action.type) {
    case MOVE_DOCUMENT: {
      const draggingDocument = state.nodes.filter(node => node.dragging);
      const reversedDocuments = draggingDocument.reverse();

      let nodes = difference(state.nodes, draggingDocument);

      let destinationDocumentIndex = nodes.findIndex(
        node => node.id === action.nodeIdDestination
      );

      if (action.position === AFTER) {
        destinationDocumentIndex += 1;
      }

      for (let reversedDocument of reversedDocuments) {
        nodes.splice(destinationDocumentIndex, 0, reversedDocument);
      }

      return {
        ...state,
        nodes: nodes,
      };
    }
  }

  return state;
}
