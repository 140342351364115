class ApiClient {
  request(request, options = {}) {
    request.headers.set(
      'cache-control',
      'no-cache, max-age=300, must-revalidate, no-store'
    );
    request.headers.set('pragma', 'no-cache');
    request.headers.set('credentials', 'same-origin');

    if (request.method !== 'POST' && request.method !== 'GET') {
      request.headers.set('X-HTTP-METHOD-OVERRIDE', request.method);
      request = new Request(request, { method: 'POST' });
    }

    return fetch(request, options).then(response => {
      if (response.status == 401) {
        window.location.reload();
      }
      return response;
    });
  }
}

export default new ApiClient();
