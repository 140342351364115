import { createSelector } from 'reselect';
import {
  STATUS_DELETING,
  STATUS_MOVING_TO_REMINDERS,
  STATUS_NOTIFICATION,
  STATUS_NOTIFICATION_REMINDER,
} from '../reducers/notifications';

export const notificationFullscreenStatus = [
  STATUS_NOTIFICATION,
  STATUS_MOVING_TO_REMINDERS,
];
export const notificationReminderStatus = [
  STATUS_NOTIFICATION_REMINDER,
  STATUS_DELETING,
];

export const selectNotifications = createSelector(
  state => state.notifications.notifications,
  notifications =>
    notifications.filter(
      notification =>
        notificationFullscreenStatus.indexOf(notification.status) !== -1
    )
);

export const selectNotificationsReminder = createSelector(
  state => state.notifications.notifications,
  notifications =>
    notifications.filter(
      notification =>
        notificationReminderStatus.indexOf(notification.status) !== -1
    )
);
