import createUndoableActionReducer from '../reducers/createUndoableActionReducer';
import { TYPE_FOLDER, TYPE_DOCUMENT } from '../documents/type';
import { takeEvery, call } from 'redux-saga/effects';
import apiClient from 'js/dataroom/core/apiClient';
import undoSaga from './dataroomActions/undoSaga';

import { uniqueId } from 'underscore';
import addDocumentNumberWithFile, {
  addDocumentNumberWithoutFile,
} from './utils/addDocumentNumber';

export const CHANGE_NODES_TYPE = 'CHANGE_NODES_TYPE';

//actions
export const changeNodeTypeAction = (nodeIds, nodeType, dataroomId) => ({
  type: CHANGE_NODES_TYPE,
  nodeIds,
  nodeType,
  dataroomId,
  frontActionId: uniqueId('dataroom_action'),
});

// saga
const changeNodesTypeApi = action => {
  return apiClient.request(
    new Request(`/api/nodes/change_type`, {
      method: 'PATCH',
      body: JSON.stringify({
        nodeIds: action.nodeIds,
        type: action.nodeType,
        dataroomId: action.dataroomId,
      }),
    })
  );
};

export function* saga(action) {
  return yield call(changeNodesTypeApi, action);
}

export function* undoActionSaga() {
  yield takeEvery('UNDO_CHANGE_NODES_TYPE', undoSaga);
}

export function changeNodesType(nodes, nodeIds) {
  let newNodes = { ...nodes };
  nodeIds.forEach(nodeId => {
    const node = nodes[nodeId];

    let newNode = {
      ...node,
      type: node.type === TYPE_FOLDER ? TYPE_DOCUMENT : TYPE_FOLDER,
    };

    // switch document to folder
    if (node.type === TYPE_DOCUMENT) {
      newNode.childIds = [];
      newNode.documentWithFileNumber = 0;
      newNode.documentWithoutFileNumber = 0;
    }

    if (node.type === TYPE_FOLDER) {
      newNodes = addDocumentNumberWithoutFile(
        newNodes[node.parentId].id,
        newNodes,
        1
      );
    } else if (node.type === TYPE_DOCUMENT && !node.file) {
      newNodes = addDocumentNumberWithoutFile(
        newNodes[node.parentId].id,
        newNodes,
        -1
      );
    } else {
      newNodes = addDocumentNumberWithFile(
        newNodes[node.parentId].id,
        newNodes,
        -1
      );
    }

    if (newNode.file) {
      delete newNode.file;
    }

    newNodes[node.id] = newNode;
  });
  return newNodes;
}

//reducers
function changeNodesTypeReducer(state, action) {
  let previousNodeType = state.working.nodes[action.nodeIds[0]].type;
  for (let $i = 0; $i < action.nodeIds.length; $i++) {
    const node = state.working.nodes[action.nodeIds[$i]];
    if (node.path === '0') {
      return state;
    }
    if (node.type !== previousNodeType) {
      return state;
    }
    if (
      (node.type === TYPE_FOLDER && node.childIds.length !== 0) ||
      (node.type === TYPE_DOCUMENT && node.file)
    ) {
      return state;
    }
  }

  return {
    ...state,
    working: {
      ...state.working,
      nodes: changeNodesType(state.working.nodes, action.nodeIds),
    },
  };
}

const undoChangeNodesType = (state, action) =>
  changeNodesTypeReducer(state, {
    ...action,
    nodeType: action.nodeType === TYPE_DOCUMENT ? TYPE_FOLDER : TYPE_DOCUMENT,
  });

export default createUndoableActionReducer(
  CHANGE_NODES_TYPE,
  changeNodesTypeReducer,
  undoChangeNodesType
);
