import React, {
  createContext,
  useState,
  useEffect,
  useMemo,
  useContext,
} from 'react';
import queryString from 'qs';
import Login from '../../user/pages/Login';
import WaitingToConnect from '../components/WaitingToConnect';
import { browserHistory, useRouterHistory } from 'react-router';
import { intersection } from 'underscore';
import { adminRoles, superAdminRoles } from '../../dataroom/dataroom/constants';

export const AuthDataContext = createContext(null);

const initialAuthData = {
  user: null,
  disconnected: false,
};

const AuthDataProvider = props => {
  const [authData, setAuthData] = useState(initialAuthData);

  const isUnloggedPage =
    props.location.pathname === '/request-reset-password' ||
    props.location.pathname === '/reset-password';

  useEffect(() => {
    if (authData.user || authData.disconnected) {
      return;
    }

    async function fetchUser() {
      const response = await fetch(
        `/api/user/me?${queryString.stringify({
          resources: ['USER_COMPANY'],
        })}`
      );

      if (!response.ok) {
        setAuthData(state => ({
          ...state,
          user: null,
          disconnected: true,
        }));
        return;
      }

      const user = await response.json();

      setAuthData(state => ({
        ...state,
        disconnected: false,
        user,
      }));
    }

    fetchUser();
  }, [authData]);

  const onLogout = () => {
    setAuthData(initialAuthData);

    browserHistory.push('/');
  };

  const onLogin = newAuthData => setAuthData(newAuthData);

  if (isUnloggedPage) {
    return (
      <AuthDataContext.Provider value={{ ...authData }}>
        {props.children}
      </AuthDataContext.Provider>
    );
  }

  if (authData.disconnected) {
    return <Login onLogin={user => onLogin(user)} {...props} />;
  }

  if (!authData.user) {
    return <WaitingToConnect />;
  }

  const isAdmin = intersection(authData.user.roles, adminRoles).length !== 0;

  const isSuperAdmin =
    intersection(authData.user.roles, superAdminRoles).length !== 0;

  return (
    <AuthDataContext.Provider
      value={{ ...authData, onLogin, onLogout, isAdmin, isSuperAdmin }}
    >
      {props.children}
    </AuthDataContext.Provider>
  );
};

export const useAuthDataContext = () => useContext(AuthDataContext);

export default AuthDataProvider;
