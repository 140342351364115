import reduceReducers from 'reduce-reducers';
import dragDrop from '../ducks/dragDropPage';
import pickPage from '../ducks/pickPage';
import editPage from '../ducks/editPage';
import pdfjs from 'pdfjs-dist';
import apiClient from '../../core/apiClient';
import { AFTER } from '../../dataroom/ducks/utils/getNextPath';
import uploader from '../../core/uploader';
import pdfPageErrors, {
  defaultPdfPageErrorsState,
} from '../ducks/pdfPageErrors';
import { ADD_NOTIFICATION } from '../../app/actions/notifications';
import { ADD_NOTIFICATION_REMINDER } from '../../app/reducers/notifications';

pdfjs.GlobalWorkerOptions.workerSrc = '/pdfjs/build/pdf.worker.js';

const PDF_LOADED = 'EDITOR_PDF_LOADED';
const PDF_ADDED = 'EDITOR_PDF_ADDED';
const PDF_SUBMITTED = 'EDITOR_PDF_SUBMITTED';
const PDF_INELIGIBLE_DOCUMENT = 'EDITOR_INELIGIBLE_DOCUMENT';
const PDF_LEAVE = 'EDITOR_PDF_LEAVE';
const PDF_ADDING_FILES = 'EDITOR_PDF_ADDING_FILES';
const PDF_FILES_ADDED = 'EDITOR_PDF_FILES_ADDED';

export const CONVERTED_MIME_TYPE = [
  'image/jpeg',
  'application/msword',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'image/tiff',
  'image/png',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];
export const AUTHORIZED_MIME_TYPE = ['application/pdf', ...CONVERTED_MIME_TYPE];

class IneligibleDocumentCompressionError {}

export const leavePdfEditorAction = () => ({
  type: PDF_LEAVE,
});

export const maxUploadFileAction = () => ({
  type: ADD_NOTIFICATION,
  message: 'Vous avez atteint le nombre maximum de fichiers droppable (15).',
  statusType: 'ERROR',
  iconType: 'IconSpam',
});

function getPdfPageData(pdf, pageNumber) {
  return pdf.getPage(pageNumber).then(page => {
    return page;
  });
}

function processFile(file, prefix, pageNumber, hoverPosition, dispatch) {
  return new Promise(async (success, reject) => {
    const uploadedFile = await uploader.uploadDocumentByChunk(
      file,
      prefix,
      0,
      `/api/upload/chunk/${prefix}`
    );

    dispatch({
      type: PDF_SUBMITTED,
      file: uploadedFile,
    });

    if (CONVERTED_MIME_TYPE.includes(file.type)) {
      file = await convertFile(
        uploadedFile.fileName,
        uploadedFile.fileUploadId
      );
      if (!file) {
        return reject();
      }
    }

    try {
      let fileData = await loadFile(file, pageNumber, hoverPosition, prefix);
      dispatch({
        type: PDF_ADDED,
        ...fileData,
      });
    } catch (err) {
      return reject();
    }

    return success();
  });
}

export const processFilesAction = (
  files,
  prefix,
  pageNumber,
  hoverPosition
) => {
  return dispatch => {
    dispatch({
      type: PDF_ADDING_FILES,
    });

    let promises = [];

    files.map((file, index) => {
      promises.push(
        processFile(
          file,
          prefix + index + 1,
          pageNumber,
          hoverPosition,
          dispatch
        )
      );
    });

    return Promise.all(promises)
      .finally(() => {
        dispatch({
          type: PDF_FILES_ADDED,
        });
      })
      .catch(error => {
        dispatch({
          type: ADD_NOTIFICATION_REMINDER,
          message:
            'Certains fichiers ne peuvent pas être traités et ont été ignorés.',
          statusType: 'ERROR',
          iconType: 'IconSpam',
        });
      });
  };
};

const loadDocument = (buffer, prefix = null) => {
  return pdfjs.getDocument({ data: buffer }).then(pdf => {
    let promises = [];
    for (let i = 1; i <= pdf.numPages; i++) {
      promises.push(getPdfPageData(pdf, i));
    }

    return Promise.all(promises).then(pages => {
      let formatedPages = pages.map(page => {
        return {
          pagePdf: page,
          pageNumber: prefix ? prefix + '-' + page.pageNumber : page.pageNumber,
          picked: false,
          rotation: 0,
          dragging: false,
          multipleDrag: false,
          fileId: null,
        };
      });

      return {
        formatedPages: formatedPages,
      };
    });
  });
};

const readFile = file => {
  let fileReader = new FileReader();

  return new Promise((success, reject) => {
    fileReader.onload = () => {
      success(fileReader.result);
    };

    fileReader.onerror = () => {
      reject();
    };

    fileReader.readAsArrayBuffer(file);
  });
};

export const loadFile = (file, numberDestination, position, prefix) => {
  return readFile(file)
    .then(buffer => loadDocument(buffer, prefix))
    .then(document => {
      return {
        pages: document.formatedPages,
        numberDestination: numberDestination,
        position: position,
        file: file,
        prefix: prefix,
      };
    })
    .catch(e => {
      let error = new Error();
      error.message = 'Cannot load file from PDFJS';

      throw error;
    });
};

export const loadPdfEditorAction = url => {
  return dispatch => {
    apiClient
      .request(
        new Request(url, {
          method: 'POST',
        })
      )
      .then(response => {
        if (response.headers.get('content-type') === 'application/json+error') {
          response.json().then(data => {
            dispatch({
              type: PDF_INELIGIBLE_DOCUMENT,
              eligibility: data.additionalData,
            });
          });

          throw new IneligibleDocumentCompressionError();
        }

        return response.arrayBuffer();
      })
      .then(buffer => loadDocument(buffer))
      .then(document => {
        dispatch({
          type: PDF_LOADED,
          pages: document.formatedPages,
        });
      })
      .catch(err => {
        console.warn('ineligible pdf');
      });
  };
};

export const convertFile = async (fileName, fileUploadId) => {
  return apiClient
    .request(
      new Request(`/api/document/convertFile`, {
        method: 'POST',
        body: JSON.stringify({
          fileName: fileName,
          fileUploadId: fileUploadId,
        }),
      })
    )
    .then(response => {
      if (response.ok) {
        return response.blob();
      }
    });
};

const initialState = {
  files: [],
  prevPages: [],
  pages: [],
  addingFile: false,
  pickedPageNumbers: [],
  loaded: false,
  dropping: false,
  draggingPageNumbers: [],
  eligibilityInformation: {
    eligibility: true,
    filesize: null,
    numberOfPage: null,
  },
  pdfPageErrors: defaultPdfPageErrorsState,
  totalPage: 0,
};

function pdfEditor(state = initialState, action = {}) {
  switch (action.type) {
    case PDF_LOADED: {
      return {
        ...state,
        loaded: true,
        pages: action.pages,
        prevPages: [],
        totalPage: action.pages.length,
      };
    }
    case PDF_ADDED: {
      const prevPages = state.pages;
      const pages = state.pages;

      let destinationPageIndex = pages.findIndex(
        page => page.pageNumber === action.numberDestination
      );

      if (action.position === AFTER) {
        destinationPageIndex += 1;
      }

      for (let page of action.pages.reverse()) {
        pages.splice(destinationPageIndex, 0, {
          ...page,
          fileId: action.prefix,
        });
      }

      return {
        ...state,
        loaded: true,
        dropping: false,
        pages: pages,
        prevPages: [...state.prevPages, prevPages],
      };
    }
    case PDF_SUBMITTED: {
      let files = {
        ...state.files,
        [action.file.id]: {
          ...state.files[action.file.id],
          id: action.file.fileUploadId,
          name: action.file.fileName,
          size: action.file.fileSize,
        },
      };

      return {
        ...state,
        files: files,
      };
    }
    case PDF_INELIGIBLE_DOCUMENT: {
      return {
        ...state,
        eligibilityInformation: action.eligibility,
        loaded: true,
      };
    }
    case PDF_FILES_ADDED: {
      return {
        ...state,
        addingFile: false,
      };
    }
    case PDF_ADDING_FILES: {
      return {
        ...state,
        addingFile: true,
      };
    }
    case 'PDF_EDIT_SUCCESS':
    case PDF_LEAVE: {
      return initialState;
    }
  }

  return state;
}

export default reduceReducers(
  pdfPageErrors,
  pdfEditor,
  dragDrop,
  pickPage,
  editPage
);
