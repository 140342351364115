export const NODE_MENU_OPENED = 'NODE_MENU_OPENED';
export const NODE_MENU_CLOSED = 'NODE_MENU_CLOSED';

export function nodeMenuOpened(nodeId) {
  return {
    type: NODE_MENU_OPENED,
    nodeId,
  };
}

export function nodeMenuClosed() {
  return {
    type: NODE_MENU_CLOSED,
  };
}

export default function(state, action) {
  switch (action.type) {
    case NODE_MENU_OPENED: {
      let newNodes = { ...state.working.nodes };
      let selectedNodeIdsInContextMenu = [];
      const index = state.working.pickedNodeIds.findIndex(
        nodeId => nodeId === action.nodeId
      );

      if (index === -1) {
        selectedNodeIdsInContextMenu = [action.nodeId];
      } else {
        selectedNodeIdsInContextMenu = [...state.working.pickedNodeIds];
      }

      selectedNodeIdsInContextMenu.forEach(nodeId => {
        newNodes[nodeId] = {
          ...newNodes[nodeId],
          menuOpen: true,
        };
      });

      return {
        ...state,
        working: {
          ...state.working,
          nodes: newNodes,
        },
        selectedNodeIdsInContextMenu: selectedNodeIdsInContextMenu,
      };
    }
    case NODE_MENU_CLOSED: {
      let newNodes = { ...state.working.nodes };

      state.selectedNodeIdsInContextMenu.forEach(nodeId => {
        if (typeof newNodes[nodeId] === 'undefined') {
          return;
        }
        newNodes[nodeId] = {
          ...newNodes[nodeId],
          menuOpen: false,
        };
      });

      return {
        ...state,
        working: {
          ...state.working,
          nodes: newNodes,
        },
        selectedNodeIdsInContextMenu: [],
      };
    }
  }

  return state;
}
