import React, {
  Fragment,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import Helmet from 'react-helmet';
import Layout from '../../app/layout/Layout';
import { Menu } from '../../app/components/menu/Menu';
import Content from '../../app/layout/Content';
import Notifications from '../../dataroom/app/containers/Notifications';
import Span from '../../presentational/components/Span';
import {
  BODY_FONT,
  CLIENT_UI_COLOR,
  DANGER_COLOR,
} from '../../presentational/components/theme_blue';
import IconDataroom from '../../presentational/components/img/icons/IconDataroom';
import H2 from '../../presentational/components/H2';
import DataRoomForm from '../components/dataroom/DataRoomForm';

export default function DataRoomCreation(props) {
  return (
    <Fragment>
      <Helmet>
        <title>Espace client - Ajout de dataroom</title>
      </Helmet>

      <Layout>
        <Menu dataroom />

        <Content>
          <H2>
            <Span
              color={CLIENT_UI_COLOR}
              fontFamily={BODY_FONT}
              display="flex"
              alignItems="center"
              justifyContent="center"
              minWidth="100%"
            >
              <IconDataroom marginRight=".5em" marginBottom="-.1em" />
              Ajout d'une dataroom
            </Span>
          </H2>
          <DataRoomForm
            defaultValues={{
              name: null,
              isManipulable: true,
              comment: null,
            }}
          />
        </Content>
      </Layout>

      <Notifications />
    </Fragment>
  );
}
