import React from 'react';
import Svg from './Svg';

export default function IconUser(props) {
  return (
    <Svg
      {...props}
      d="M155.485 111.615l-27.417 21.319-27.401-21.319C74.106 132.245 55.374 181.939 55.374 240h145.387c0-58.061-18.715-107.755-45.276-128.385zm-27.42 36.522l-26.818-20.866C82.303 147.216 69.629 184.576 67.648 228h120.839c-1.98-43.428-14.641-80.785-33.584-100.731l-26.838 20.868zm6.004-131.815c23.15 2.923 41.023 22.542 41.023 46.265 0 25.769-21.035 46.657-47.023 46.657-25.987 0-47.023-20.888-47.023-46.657 0-23.723 17.874-43.342 41.023-46.265v-.377h12v.377zm-7.801 11.668c-18.495.929-33.222 16.055-33.222 34.597 0 19.167 15.694 34.657 35.023 34.657 19.33 0 35.023-15.49 35.023-34.657 0-19.141-15.693-34.642-35.023-34.642l-.903.012-.898.033z"
    />
  );
}
