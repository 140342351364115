import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const LoadingIcon = styled.div`
  display: inline-block;
  ${props =>
    props.verticalAlign ? 'vertical-align: ' + props.verticalAlign + ';' : null}
  &:after {
    content: '';
    display: block;
    width: 0.8em;
    height: 0.8em;
    margin: 0.1em;
    border-radius: 50%;
    border-width: 0.1em;
    border-style: solid;
    border-right-color: transparent;
    border-left-color: transparent;
    border-top-color: ${props =>
      props.color ? props.theme[props.color] : 'currentColor'};
    border-bottom-color: ${props =>
      props.color ? props.theme[props.color] : 'currentColor'};
    animation: ${rotate} 1.2s linear infinite;
  }
`;

export default LoadingIcon;
