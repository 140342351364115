import childNode from './childNode';
import { TYPE_FOLDER } from '../../documents/type';

export default function*(nodes, startId, includeStart = false) {
  const parentId = nodes[startId].parentId;
  const parentNode = nodes[parentId];

  let startIndex = parentNode.childIds.indexOf(startId);
  if (!includeStart) {
    startIndex += 1;
  }

  if (startIndex === -1) {
    throw new Error(
      `unknow child with id ${startId} for node with id ${parentNode.id}`
    );
  }

  if (startIndex >= parentNode.childIds.length) {
    return;
  }

  for (const childId of parentNode.childIds.slice(startIndex)) {
    yield nodes[childId];
    if (nodes[childId].type === TYPE_FOLDER) {
      yield* childNode(nodes, childId);
    }
  }
}
