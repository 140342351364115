import createUndoableActionReducer from '../reducers/createUndoableActionReducer';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import undoSaga from './dataroomActions/undoSaga';
import { uniqueId } from 'underscore';
import { TYPE_DOCUMENT, TYPE_FOLDER } from '../documents/type';
import getNextPath, { INSIDE } from './utils/getNextPath';
import addNodes from './utils/addNodes';
import removeNode from './utils/removeNode';
import { unFold } from '../actions/dataroom';
import uuidv4 from 'uuid/v4';
import apiClient from 'js/dataroom/core/apiClient';
import { changeNodeFoldState } from '../reducers/utils/nodeFold';
import { addDocumentNumberWithoutFile } from './utils/addDocumentNumber';

// action
export const ADD_NODES = 'ADD_NODES';
export const REMOVE_JUST_ADDED_ANIMATION = 'REMOVE_JUST_ADDED_ANIMATION';

const SHOW_ADD_NODES_FORM = 'SHOW_ADD_NODES_FORM';
const CANCEL_ADD_NODES = 'CANCEL_ADD_NODES';

export const addNodesAction = (nodes, nodeType, position, node) => {
  return {
    type: ADD_NODES,
    nodes: nodes.map(nodeName => {
      return {
        name: nodeName,
        type: nodeType,
        id: uuidv4(),
        justAdded: true,
      };
    }),
    position,
    nodeId: node.id,
    futurePath: getNextPath(node.path, position),
    frontActionId: uniqueId('dataroom_action'),
    nodeType,
  };
};

export const showAddNodesFormAction = (position, nodeId) => ({
  type: SHOW_ADD_NODES_FORM,
  position,
  nodeId,
});

export const cancelAddNodesAction = () => ({
  type: CANCEL_ADD_NODES,
});

// saga
const addNodesApi = (action, { dataroomId }) => {
  return apiClient.request(
    new Request(`/api/datarooms/${dataroomId}/nodes`, {
      method: 'POST',
      body: JSON.stringify({
        to: action.futurePath,
        nodes: action.nodes,
      }),
    })
  );
};

function* removeJustAddedAttribute(action) {
  yield delay(3000);

  yield put({
    type: REMOVE_JUST_ADDED_ANIMATION,
    nodeIds: action.nodes.map(node => node.id),
  });
}

export function* removeJustAddedAttributeSaga() {
  yield takeEvery(ADD_NODES, removeJustAddedAttribute);
}

export function* saga(action) {
  const selectedState = yield select(state => ({
    dataroomId: state.dataroom.dataroom.id,
  }));

  return yield call(addNodesApi, action, selectedState);
}

export function* undoActionSaga() {
  yield takeEvery('UNDO_ADD_NODES', undoSaga);
}

// reducer
const addNodesReducer = (state, action) => {
  let newNodes = addNodes(
    action.nodeId,
    action.position,
    state.working.nodes,
    action.nodes
  );

  newNodes[action.nodeId] = {
    ...newNodes[action.nodeId],
    addNodes: null,
  };

  if (action.nodeType === TYPE_DOCUMENT) {
    newNodes = addDocumentNumberWithoutFile(
      action.position === INSIDE
        ? action.nodeId
        : newNodes[action.nodeId].parentId,
      newNodes,
      action.nodes.length
    );
  }

  return {
    ...state,
    working: {
      ...state.working,
      nodes: newNodes,
      addNodes: {
        show: false,
        position: action.position,
        nodeId: action.nodeId,
      },
    },
  };
};

const removeNodes = (nodes, nodeIdsToRemove) => {
  let newState = { ...nodes };

  nodeIdsToRemove.forEach(nodeId => (newState = removeNode(newState, nodeId)));

  return newState;
};

const undoAddNodesReducer = (state, action) => {
  let newNodes = removeNodes(
    state.working.nodes,
    action.nodes.map(nodeItem => nodeItem.id)
  );

  if (action.nodeType === TYPE_DOCUMENT) {
    newNodes = addDocumentNumberWithoutFile(
      action.position === INSIDE
        ? action.nodeId
        : newNodes[action.nodeId].parentId,
      newNodes,
      -action.nodes.length
    );
  }

  return {
    ...state,
    working: {
      ...state.working,
      nodes: newNodes,
    },
  };
};

const baseReducer = createUndoableActionReducer(
  ADD_NODES,
  addNodesReducer,
  undoAddNodesReducer
);

export default function(state, action) {
  switch (action.type) {
    case REMOVE_JUST_ADDED_ANIMATION: {
      const newState = { ...state };

      for (let nodeId of action.nodeIds) {
        if (!state.working.nodes[nodeId]) {
          continue;
        }

        newState.working = {
          ...newState.working,
          nodes: {
            ...newState.working.nodes,
            [nodeId]: {
              ...newState.working.nodes[nodeId],
              justAdded: false,
            },
          },
        };
      }

      return newState;
    }

    case SHOW_ADD_NODES_FORM: {
      let newState = { ...state };

      if (
        newState.working.nodes[action.nodeId].type === TYPE_FOLDER &&
        action.position === INSIDE
      ) {
        newState = changeNodeFoldState(
          unFold(newState.working.nodes[action.nodeId]),
          newState
        );
      }

      const newNode = {
        ...newState.working.nodes[action.nodeId],
        addNodes: action.position,
      };

      return {
        ...newState,
        working: {
          ...newState.working,
          nodes: {
            ...newState.working.nodes,
            [action.nodeId]: newNode,
          },
          addNodes: {
            show: true,
            position: action.position,
            nodeId: action.nodeId,
          },
        },
      };
    }
    case CANCEL_ADD_NODES: {
      return {
        ...state,
        working: {
          ...state.working,
          nodes: {
            ...state.working.nodes,
            [state.working.addNodes.nodeId]: {
              ...state.working.nodes[state.working.addNodes.nodeId],
              addNodes: null,
            },
          },
          addNodes: {
            show: false,
            position: null,
            nodeId: null,
          },
        },
      };
    }
  }

  return baseReducer(state, action);
}
