import React from 'react';
import Svg from './Svg';

export default function IconReload(props) {
  return (
    <Svg
      {...props}
      d="M131.949,52.701l-26.532,-25.795l8.365,-8.604l42.142,40.973l-42.371,41.216l-8.368,-8.602l27.889,-27.128c-28.332,-1.581 -54.977,17.164 -62.712,46.033c-8.845,33.011 10.341,66.921 42.884,75.641c32.542,8.72 66.114,-11.054 74.959,-44.066l11.591,3.106c-10.572,39.454 -50.762,62.973 -89.656,52.551c-38.894,-10.421 -61.941,-50.884 -51.369,-90.338c9.078,-33.88 39.996,-56.008 73.178,-54.987Z"
    />
  );
}
