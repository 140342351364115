import getPathLevel from './getPathLevel';
import mapDescendant from './mapDescendant';
import updatePath from './updatePath';
import { INSIDE, BEFORE } from './getNextPath';

/**
 * update the path for all the descendant of the node
 *
 * @param nodes
 * @param nodeId
 * @param position
 * @param nodesToAddNumber
 * @returns {{}}
 */
export default (nodes, nodeId, position, nodesToAddNumber) => {
  let pathLevel = getPathLevel(nodes[nodeId].path);

  if (position !== INSIDE) {
    return mapDescendant(
      nodes,
      nodeId,
      node => {
        return {
          ...node,
          path: updatePath(
            pathLevel,
            node.path,
            position => position + nodesToAddNumber
          ),
        };
      },
      position === BEFORE
    );
  }

  if (nodes[nodeId].childIds.length === 0) {
    return { ...nodes };
  }

  return mapDescendant(
    nodes,
    nodes[nodeId].childIds[0],
    node => {
      return {
        ...node,
        path: updatePath(
          pathLevel + 1,
          node.path,
          position => position + nodesToAddNumber
        ),
      };
    },
    true
  );
};
