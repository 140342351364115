import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { Modal, Alert, ListGroup, ListGroupItem } from 'react-bootstrap';
import {
  hideModalAction,
  addFilesWithAllowedExtensions,
} from '../ducks/addFiles';
import { cancelPrepareAction } from '../ducks/dataroomActions';
import { bindActionCreators } from 'redux';
import allowedFileExtension from '../documents/allowedFileExtension';
import { TYPE_FOLDER } from '../documents/type';
import copy from 'copy-to-clipboard';
import { Tooltip } from 'react-bootstrap';

class ConfirmAddFilesModal extends PureComponent {
  constructor(props) {
    super(props);
    this.addFiles = this.addFiles.bind(this);
    this.copyList = this.copyList.bind(this);
    this.cancelAddFiles = this.cancelAddFiles.bind(this);
    this.state = {
      showListCopiedTooltip: false,
    };
  }

  copyList() {
    const { notAllowedDocuments } = this.props;

    const textList = notAllowedDocuments.map(document => document.path);

    copy(textList.join('\n'));

    this.setState({
      showListCopiedTooltip: true,
    });

    setTimeout(() => {
      this.setState({ showListCopiedTooltip: false });
    }, 1000);
  }

  addFiles() {
    this.props.addFilesWithAllowedExtensions(this.props.addFilesAction);
    this.props.hideModalAction();
  }

  cancelAddFiles() {
    this.props.cancelPrepareAction(this.props.addFilesAction);
    this.props.hideModalAction();
  }

  render() {
    const { open, notAllowedDocuments, addFilesAction } = this.props;

    if (!open) {
      return null;
    }

    return (
      <Modal backdrop="static" show={open} onHide={this.cancelAddFiles}>
        <Modal.Header>
          <Modal.Title>Fichiers Invalide</Modal.Title>
        </Modal.Header>

        <Modal.Body className="form">
          <div className="form-group">
            <Fragment>
              Certains fichiers ont des extensions non autorisées,{' '}
              <b>ils ne seront donc pas uploadés</b>
            </Fragment>

            <ListGroup>
              {notAllowedDocuments.map(notAllowedDocument => (
                <ListGroupItem key={notAllowedDocument.id}>
                  {notAllowedDocument.name}
                </ListGroupItem>
              ))}
            </ListGroup>

            <Alert bsStyle="info">
              Extensions autorisées: {allowedFileExtension.join(', ')}
            </Alert>

            <button
              onClick={this.copyList}
              type="button"
              data-dismiss="modal"
              className="btn btn-warning"
            >
              <i className="fa fa-clipboard" /> Copier la liste des fichiers
              invalides
            </button>

            {this.state.showListCopiedTooltip && (
              <div>
                <Tooltip placement="bottom" className="in" id="tooltip-bottom">
                  Liste copiée
                </Tooltip>
              </div>
            )}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button
            onClick={this.cancelAddFiles}
            type="button"
            data-dismiss="modal"
            className="btn btn-default md-close"
          >
            Annuler
          </button>
          <button
            onClick={this.addFiles}
            type="button"
            data-dismiss="modal"
            className="btn btn-primary md-close"
          >
            Valider
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return state.dataroom.working.confirmAddFilesModal;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      hideModalAction,
      addFilesWithAllowedExtensions,
      cancelPrepareAction,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmAddFilesModal);
