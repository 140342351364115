import React, { Component } from 'react';

export const addPlaceholder = (name, inputRef) => {
  if (document.activeElement !== inputRef.current) {
    return {
      name: `${name} [____] `,
    };
  }

  const { selectionEnd } = inputRef.current;
  const placeholderSize = 8;

  return {
    name: `${name.substr(0, selectionEnd)} [____] ${name.substr(selectionEnd)}`,
    afterUpdate: () => {
      inputRef.current.setSelectionRange(
        selectionEnd + placeholderSize,
        selectionEnd + placeholderSize
      );
    },
  };
};

class AddPlaceholderButton extends Component {
  render() {
    return (
      <span className="input-group-btn">
        <button
          tabIndex="-1"
          type="button"
          className="btn btn-default"
          title="Ajouter un espace à remplir"
          onMouseDown={this.props.handleAddPlaceholder}
          disabled={this.props.submitting}
        >
          {' '}
          <span className="sr-only">Ajouter un espace à remplir</span>{' '}
          [&nbsp;&nbsp;&nbsp;]{' '}
        </button>
      </span>
    );
  }
}

export default AddPlaceholderButton;
