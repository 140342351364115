import React, { Component } from 'react';

class Lock extends Component {
  constructor(props) {
    super(props);
    this.lockClicked = this.lockClicked.bind(this);
  }

  lockClicked() {
    const { lockBy, lockedByMe, disabled, canUnlock } = this.props;

    if (disabled) {
      return;
    }

    if (!lockBy) {
      return this.props.lock();
    }

    if (!canUnlock) {
      return;
    }

    this.props.unlock();
  }

  render() {
    const { lockBy, lockedByMe, disabled } = this.props;

    let className = '';
    let title = '';

    if (lockBy) {
      title = `${lockBy.lastName} ${lockBy.firstName}`;
      className = 'l';
    }

    if (lockedByMe) {
      className = 'w';
    }

    return (
      <button
        title={title}
        style={{ opacity: disabled ? 0.4 : 1 }}
        disabled={disabled}
        onClick={this.lockClicked}
        className="o"
      >
        <i className={className} />
      </button>
    );
  }
}

export default Lock;
