import React, { Component } from 'react';
import LoadingFullscreen from '../../../presentational/components/LoadingFullscreen';
import Div from '../../../presentational/components/Div';
import LoadingIcon from '../../../presentational/components/LoadingIcon';

class LoadingScreen extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <LoadingFullscreen zIndex={1000} theme={this.props.theme}>
        <LoadingFullscreen.Content>
          <Div>
            {this.props.text} <LoadingIcon verticalAlign="middle" />
          </Div>
        </LoadingFullscreen.Content>
      </LoadingFullscreen>
    );
  }
}

export default LoadingScreen;
