import React, { Fragment, useEffect, useMemo, useReducer } from 'react';
import theme_blue, {
  BODY_FONT,
  CLIENT_UI_COLOR,
  DARK_GREY_COLOR,
  LIGHT_BLUE_COLOR,
  LIGHT_COLOR,
  LIGHT_GREY_COLOR,
  MID_GREY_COLOR,
  SECONDARY_COLOR,
  TRANSPARENT_COLOR,
} from '../../../presentational/components/theme_blue';
import Div from '../../../presentational/components/Div';
import 'translations/user/fr';
import 'translations/user/en';
import Span from '../../../presentational/components/Span';
import IconDataroom from '../../../presentational/components/img/icons/IconDataroom';
import GridRow from '../../../presentational/components/GridRow';
import GridCol from '../../../presentational/components/GridCol';
import Hr from '../../../presentational/components/Hr';
import Ul from '../../../presentational/components/Ul';
import Li from '../../../presentational/components/Li';
import A from '../../../presentational/components/A';
import IconEdit from '../../../presentational/components/img/icons/IconEdit';
import IconCalendar from '../../../presentational/components/img/icons/IconCalendar';
import IconUser from '../../../presentational/components/img/icons/IconUser';
import { Link } from 'react-router';
import { useAuthDataContext } from '../../../app/hooks/authDataProvider';
import { Date } from '../../../app/components/Date';
import { Paginator } from '../../../app/components/Paginator';
import IconTrash from '../../../presentational/components/img/icons/IconTrash';
import Button from '../../../presentational/components/Button';
import IconGroup from '../../../presentational/components/img/icons/IconGroup';
import IconLockLocked from '../../../presentational/components/img/icons/IconLockLocked';

export default function UserSearchResult(props) {
  const { user, isAdmin } = useAuthDataContext();
  const { currentPage, users, total, items } = props;

  return (
    <Fragment>
      {total === 0 && (
        <Span>{Translator.transChoice('foundResult', total)}</Span>
      )}
      <Div fontWeight="300">
        <Ul listStyleType="none" display="block" margin="0" padding="0">
          {users.map(userList => (
            <Li
              key={userList.id}
              strippedColor={LIGHT_GREY_COLOR}
              padding=".5rem"
            >
              <Div display="flex" alignItems="start">
                <GridRow>
                  <GridCol xs={12 / 12} md={4 / 12}>
                    <A color={CLIENT_UI_COLOR} display="inline-flex">
                      <IconUser marginRight=".5em" marginTop=".125em" />
                      <strong>
                        {userList.firstName} {userList.lastName.toUpperCase()}
                      </strong>
                    </A>
                  </GridCol>
                  <GridCol xs={12 / 12} md={2 / 12}>
                    <Span
                      color={MID_GREY_COLOR}
                      display="inline-block"
                      margin="0 0 0 1.6em"
                    >
                      <IconCalendar marginRight=".5em" marginBottom="-.125em" />
                      Créée le <Date date={userList.createdAt} format="L" />
                    </Span>
                  </GridCol>
                  <GridCol xs={12 / 12} md={2 / 12}>
                    <Span
                      color={MID_GREY_COLOR}
                      display="inline-block"
                      margin="0 0 0 1.6em"
                    >
                      <IconCalendar marginRight=".5em" marginBottom="-.125em" />
                      MàJ le <Date date={userList.updatedAt} format="L" />
                    </Span>
                  </GridCol>
                  <GridCol xs={12 / 12} md={3 / 12}>
                    <Span
                      color={MID_GREY_COLOR}
                      display="flex"
                      margin="0 0 0 1.6em"
                      flexWrap
                    >
                      <Span display="block">
                        <IconUser marginRight=".5em" marginTop=".125em" />
                        <Span display="inline-block" margin="0 .5em 0 0">
                          Rôle :
                        </Span>
                      </Span>
                      <A display="inline-block" color={MID_GREY_COLOR}>
                        {Translator.trans(userList.roles[0], {}, 'user')}{' '}
                      </A>
                    </Span>
                  </GridCol>
                  <GridCol xs={12 / 12} md={1 / 12}>
                    {(isAdmin || userList.id === user.id) && (
                      <A
                        as={Link}
                        to={`/users/${userList.id}/edition`}
                        title="Éditer cet utilisateur"
                        $hoverColor="primaryColor"
                      >
                        <Span margin="0 1em 0 0">
                          <IconEdit width="1.25em" />
                        </Span>
                      </A>
                    )}
                    {(isAdmin || userList.id === user.id) && (
                      <A
                        as={Link}
                        to={`/users/${userList.id}/edition/password`}
                        title="Modifier le mot de passe"
                        $hoverColor="primaryColor"
                      >
                        <Span margin="0 1em 0 0">
                          <IconLockLocked width="1.25em" />
                        </Span>
                      </A>
                    )}
                    {isAdmin && userList.id !== user.id && (
                      <Button
                        backgroundColor={TRANSPARENT_COLOR}
                        borderColor={TRANSPARENT_COLOR}
                        color={SECONDARY_COLOR}
                        hoverColor={CLIENT_UI_COLOR}
                        noBorder
                        padding="0"
                      >
                        <Span
                          margin="0 1em 0 0"
                          onClick={() => props.onDelete(userList)}
                        >
                          <IconTrash width="1.25em" />
                        </Span>
                      </Button>
                    )}
                  </GridCol>
                </GridRow>
              </Div>
            </Li>
          ))}
        </Ul>
      </Div>
      <Hr />
      <Div fontSize=".75rem" margin="2rem 0">
        {total > 0 && (
          <Paginator
            totalItems={total}
            itemsPerPage={items}
            currentPage={currentPage}
            changePage={props.changePage}
          />
        )}
      </Div>
    </Fragment>
  );
}
