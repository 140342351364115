import childNode from '../../ducks/utils/childNode';

export function updateSelectedStatus(nodes, isSelected, nodeId) {
  let newNodes = { ...nodes };

  if (nodes[nodeId].selected !== isSelected) {
    newNodes[nodeId] = {
      ...newNodes[nodeId],
      selected: isSelected,
    };
  }

  for (const childNodeItem of childNode(nodes, nodeId)) {
    if (childNodeItem.selected !== isSelected) {
      newNodes[childNodeItem.id] = {
        ...childNodeItem,
        selected: isSelected,
      };
    }
  }

  return newNodes;
}

export function countSelectedNodes(nodes) {
  let count = 0;

  Object.entries(nodes).forEach(function(node) {
    if (node[1].selected) {
      count++;
    }
  });

  return count;
}
