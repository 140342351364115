/**
 Permet de gérer le pick d'un objet en ayant le format suivant:
 {
    ...,
    objects: [
        {
          ...,
          picked: boolean,
          dragging: boolean,
          multipleDrag: boolean
        }
    ],
    pickedObjects: [string, ...],
    draggingObjects: [string, ...]
 }
 */

/**
 * Permet de reset tous les objects picked
 * @param objectKey => valeur de la clef ('objects' dans l'exemple)
 * @param pickedObjectKey => valeur de la clef ('pickedObjects' dans l'exemple)
 * @param identifierKey => la clef de l'identifiant unique (ex: id)
 */
export const resetPickedObject = (
  state,
  objectKey,
  pickedObjectKey,
  identifierKey
) => {
  state[pickedObjectKey].forEach(id => {
    const nodeIndex = state[objectKey].findIndex(
      node => node[identifierKey] === id
    );

    state[objectKey][nodeIndex] = {
      ...state[objectKey][nodeIndex],
      picked: false,
    };
  });

  state[pickedObjectKey] = [];

  return state;
};

/**
 * Permet d'ajouter un object en tant que picked
 * @param objectIdentifierToAdd => valeur de la clef ('objects' dans l'exemple)
 * @param key => la clef de l'identifiant unique (ex: id)
 * @param pickedObjectIdentifier => valeur de la clef ('pickedObjects' dans l'exemple)
 */
export const addPickedObject = (
  state,
  action,
  objectIdentifierToAdd,
  key,
  pickedObjectIdentifier
) => {
  const nodeIndex = state[objectIdentifierToAdd].findIndex(
    node => node[key] === action[key]
  );

  let newNode = {
    ...state[objectIdentifierToAdd][nodeIndex],
    picked: true,
  };

  let pickedNodes = [...state[pickedObjectIdentifier]];

  const nodes = state[objectIdentifierToAdd].map((node, index) => {
    if (nodeIndex !== index || node.picked) {
      return node;
    }

    pickedNodes = [...state[pickedObjectIdentifier], action[key]];

    return newNode;
  });

  let newState = {
    ...state,
  };

  newState[objectIdentifierToAdd] = nodes;
  newState[pickedObjectIdentifier] = pickedNodes;

  return newState;
};

/**
 * Permet de supprimer un object picked
 * @param objectIdentifierToRemove => valeur de la clef ('objects' dans l'exemple)
 * @param key => la clef de l'identifiant unique (ex: id)
 */
export const removePickedObject = (
  state,
  action,
  objectIdentifierToRemove,
  key
) => {
  const nodeIndex = state[objectIdentifierToRemove].findIndex(
    node => node[key] === action[key]
  );

  let newNode = {
    ...state[objectIdentifierToRemove][nodeIndex],
    picked: false,
  };

  return state[objectIdentifierToRemove].map((node, index) => {
    if (nodeIndex !== index) {
      return node;
    }

    return newNode;
  });
};
