import { TYPE_DOCUMENT } from './type';

function findLastDocuments(nodes) {
  let lastDocuments = [];
  Object.entries(nodes).forEach(function(node) {
    lastDocuments = refreshLastDocuments(lastDocuments, node[1]).sort(function(
      a,
      b
    ) {
      return b.file.editedAt - a.file.editedAt;
    });
  });

  return lastDocuments;
}

function refreshLastDocuments(lastDocuments, document) {
  if (document.type !== TYPE_DOCUMENT || !document.file) {
    return lastDocuments;
  }

  if (lastDocuments.length < 2) {
    lastDocuments.push(document);
    return lastDocuments;
  }

  for (let i = 0; i < lastDocuments.length; i++) {
    let lastDocument = lastDocuments[i];
    if (document.file.editedAt > lastDocument.file.editedAt) {
      return updateLastDocuments(i, document, lastDocuments);
    }
  }

  return lastDocuments;
}

function updateLastDocuments(index, newDocument, lastDocuments) {
  if (index == 1 || lastDocuments.length == index) {
    lastDocuments[index] = newDocument;
    return lastDocuments;
  }
  lastDocuments[1] = lastDocuments[0];
  lastDocuments[0] = newDocument;

  return lastDocuments;
}

export default findLastDocuments;
