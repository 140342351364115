import { createSelector } from 'reselect';

const getUploadedDocuments = uploads =>
  uploads.reduce(
    (accumulator, upload) => accumulator + upload.uploadedDocuments,
    0
  );

export const getUploadedDocumentsSelector = createSelector(
  state => state.dataroom.working.uploads,
  uploads => {
    return getUploadedDocuments(uploads);
  }
);

const getUploadingDocuments = uploads =>
  uploads.reduce(
    (accumulator, upload) => accumulator + upload.uploadingDocuments,
    0
  );

export const getUploadingDocumentsSelector = createSelector(
  state => state.dataroom.working.uploads,
  uploads => {
    return getUploadingDocuments(uploads);
  }
);

const getUploadedSizeDocuments = uploads =>
  uploads.reduce((accumulator, upload) => accumulator + upload.uploadedSize, 0);

export const getUploadedSizeSelector = createSelector(
  state => state.dataroom.working.uploads,
  uploads => {
    return getUploadedSizeDocuments(uploads);
  }
);

const getUploadedTotalSizeDocuments = uploads =>
  uploads.reduce((accumulator, upload) => accumulator + upload.totalSize, 0);

export const getUploadedTotalSizeSelector = createSelector(
  state => state.dataroom.working.uploads,
  uploads => {
    return getUploadedTotalSizeDocuments(uploads);
  }
);
