import React from 'react';
import Svg from './Svg';

export default function IconEdit(props) {
  return (
    <Svg
      {...props}
      d="M177.805 27.596L48.059 157.343l-13.705 57.728 57.545-13.888L221.646 71.437l-43.841-43.841zm0 16.971l26.87 26.87-117.79 117.79-36.261 9.481 9.414-36.374L177.805 44.567z"
    />
  );
}
