import createUndoableActionReducer from '../reducers/createUndoableActionReducer';
import { takeEvery, call, put } from 'redux-saga/effects';
import apiClient from '../../core/apiClient';
import {
  generateNodeFromIndexedNodes,
  insertNodeAtPath,
} from './extractZipFile';
import { TYPE_DOCUMENT, TYPE_FOLDER } from '../documents/type';
import {
  CONFIRM_IMPORT_FILES,
  FILE_ORIGIN_ARCHIVE,
  getImportType,
  IMPORT_FILES,
} from './importFiles';
import { IMPORT_TYPE_CLASSIC, IMPORT_TYPE_NOTARY_CHAMBER } from '../constants';
import {
  addChildToFolder,
  CANT_CREATE_ALL_IGNORED_FILES,
  filterNotAllowedNodes,
  getDocumentNodes,
  getDocuments,
  getDocumentsWithNotAllowedExtension,
  normalizeAddedNode,
  OPEN_CONFIRM_MODAL,
} from './addFiles';
import addNodes from './utils/addNodes';
import { changeNodesType } from './changeNodeType';
import { INSIDE } from './utils/getNextPath';
import undoSaga from './dataroomActions/undoSaga';
import addDocumentNumber, {
  addDocumentNumberWithoutFile,
} from './utils/addDocumentNumber';
import { uniqueId } from 'underscore';
import removeNode from './utils/removeNode';

export const TRANSFORM_ARCHIVE_NODE_TO_FOLDER =
  'TRANSFORM_ARCHIVE_NODE_TO_FOLDER';

export const prepareTransformArchiveNodeToFolderAction = nodeId => ({
  type: `${TRANSFORM_ARCHIVE_NODE_TO_FOLDER}_PREPARE`,
  action: {
    frontActionId: uniqueId('dataroom_action'),
    nodeId,
  },
});

const getListArchive = nodeId =>
  apiClient
    .request(new Request(`/api/nodes/${nodeId}/list_archive`))
    .then(response => response.json());

export const transformArchiveNodeToFolderAction = (
  nodes,
  nodeId,
  importType = IMPORT_TYPE_CLASSIC,
  frontActionId = uniqueId('dataroom_action')
) => {
  const filteredNodes = filterNotAllowedNodes(nodes);

  if (filteredNodes.length === 0) {
    return {
      type: CANT_CREATE_ALL_IGNORED_FILES,
    };
  }

  const notAllowedDocuments = getDocumentsWithNotAllowedExtension(
    filteredNodes
  );

  const action = {
    type: TRANSFORM_ARCHIVE_NODE_TO_FOLDER,
    nodeId,
    nodes,
    importType,
    frontActionId,
  };

  if (notAllowedDocuments.length === 0) {
    return action;
  }

  return {
    type: OPEN_CONFIRM_MODAL,
    addFilesAction: action,
    notAllowedDocuments,
    FILE_ORIGIN_ARCHIVE: FILE_ORIGIN_ARCHIVE,
  };
};

const transformArchiveToFolderApi = action =>
  apiClient.request(
    new Request(
      `/api/nodes/${action.nodeId}/transform_archive_node_to_folder`,
      {
        method: 'POST',
        body: JSON.stringify({
          importType: action.importType,
          nodes: action.nodes.map(normalizeNodeForApi),
        }),
      }
    )
  );

export function* saga(action) {
  return yield call(transformArchiveToFolderApi, action);
}

export function* undoActionSaga() {
  yield takeEvery(`UNDO_${TRANSFORM_ARCHIVE_NODE_TO_FOLDER}`, undoSaga);
}

export const normalizeNodeForApi = node => {
  let newNode = { ...node };

  if (node.type === TYPE_DOCUMENT) {
    const lastIndex = newNode.name.lastIndexOf('.');

    if (lastIndex !== -1) {
      newNode.name = node.name.substr(0, newNode.name.lastIndexOf('.'));
    }

    return newNode;
  }

  newNode.children = newNode.children.map(normalizeNodeForApi);
  return newNode;
};

const getCsvContent = (nodeId, csvPath) =>
  apiClient
    .request(
      new Request(`/api/nodes/${nodeId}/get_archive_file_for_path/${csvPath}`)
    )
    .then(response => response.blob());

function* prepareTransformArchiveNodeToFolder(action) {
  const { nodeId, frontActionId } = action.action;

  const archiveList = yield call(getListArchive, nodeId);

  const indexedNodes = {};

  for (let archiveListItem of archiveList.entries) {
    const splittedPath = archiveListItem.name.split('/');
    const itemIsDir = !archiveListItem.isFile;

    if (itemIsDir) {
      splittedPath.splice(-1, 1);
    }

    const node = {
      type: itemIsDir ? TYPE_FOLDER : TYPE_DOCUMENT,
      name: splittedPath[splittedPath.length - 1],
      archivePath: archiveListItem['name'],
    };

    if (itemIsDir) {
      node.children = {};
    } else if (archiveListItem.file) {
      node.file = {
        ...archiveListItem.file,
        editedAt: new Date(),
      };
    }

    splittedPath.splice(-1, 1);

    insertNodeAtPath(node, splittedPath, indexedNodes);
  }

  let nodeList = generateNodeFromIndexedNodes(indexedNodes);

  const importType = getImportType(nodeList);

  if (importType === IMPORT_TYPE_NOTARY_CHAMBER) {
    const structDocumentNode = getStructDocumentNode(nodeList);

    const csvContent = yield call(
      getCsvContent,
      nodeId,
      structDocumentNode.archivePath
    );

    // Cant strigify File object see https://stackoverflow.com/a/29281243
    let file = new File([csvContent], structDocumentNode.name, {
      type: 'text/csv',
    });

    file.toJSON = function() {
      return {
        size: file.size,
        mimeType: file.type,
        extension: 'csv',
        fileName: file.name,
        editedAt: file.lastModifiedDate,
      };
    };

    structDocumentNode.file = file;
  }

  if (importType !== IMPORT_TYPE_CLASSIC) {
    yield put({
      type: CONFIRM_IMPORT_FILES,
      action: {
        type: IMPORT_FILES,
        fileOrigin: FILE_ORIGIN_ARCHIVE,
        nodes: nodeList,
        importType,
        nodeId,
        frontActionId,
      },
    });
    return;
  }

  yield put(
    transformArchiveNodeToFolderAction(
      nodeList,
      nodeId,
      importType,
      frontActionId
    )
  );
}

export const getStructDocumentNode = nodeList => {
  if (nodeList[0].children) {
    let structDocumentNode = nodeList[0].children.find(child =>
      child.name.endsWith('.struct.csv')
    );

    if (structDocumentNode) {
      return structDocumentNode;
    }
  }

  return nodeList.find(child => child.name.endsWith('.struct.csv'));
};

export function* prepareTransformArchiveNodeToFolderSaga() {
  yield takeEvery(
    `${TRANSFORM_ARCHIVE_NODE_TO_FOLDER}_PREPARE`,
    prepareTransformArchiveNodeToFolder
  );
}

function transformArchiveToFolder(state, action) {
  const normalizedNodes = action.nodes.map(normalizeAddedNode);
  let newNodes = changeNodesType(state.working.nodes, [action.nodeId]);

  newNodes = addNodes(
    action.nodeId,
    INSIDE,
    newNodes,
    normalizedNodes,
    state.groupType
  );

  action.nodes.forEach(node => {
    if (node.type === TYPE_FOLDER) {
      newNodes = addChildToFolder(
        node.id,
        node.children,
        newNodes,
        state.groupType
      );
    }
  });

  newNodes = addDocumentNumberWithoutFile(
    action.nodeId,
    newNodes,
    getDocumentNodes(action.nodes).length
  );

  return {
    ...state,
    working: {
      ...state.working,
      savedNodes: {
        ...state.working.savedNodes,
        [action.frontActionId]: {
          [action.nodeId]: { ...state.working.nodes[action.nodeId] },
        },
      },
      nodes: newNodes,
    },
  };
}

function undoTransformArchiveToFolder(state, action) {
  let newNodes = { ...state.working.nodes };

  for (let document of getDocuments(action.nodes)) {
    const node = newNodes[document.id];

    if (!node.file) {
      newNodes = addDocumentNumberWithoutFile(node.id, newNodes, -1);
    }

    if (node.file) {
      newNodes = addDocumentNumber(node.id, newNodes, -1);
    }
  }

  action.nodes.forEach(node => {
    newNodes = removeNode(newNodes, node.id);
  });

  newNodes[action.nodeId] =
    state.working.savedNodes[action.frontActionId][action.nodeId];
  newNodes = addDocumentNumber(
    state.working.nodes[action.nodeId].parentId,
    newNodes,
    1
  );

  return {
    ...state,
    working: {
      ...state.working,
      nodes: newNodes,
    },
  };
}

const baseReducer = createUndoableActionReducer(
  TRANSFORM_ARCHIVE_NODE_TO_FOLDER,
  transformArchiveToFolder,
  undoTransformArchiveToFolder
);

const setFileForExtractedDocuments = (extractedNodes, dataroomNodes) => {
  let newNodes = { ...dataroomNodes };

  for (let extractedNode of extractedNodes) {
    if (extractedNode.type === TYPE_FOLDER) {
      newNodes = setFileForExtractedDocuments(extractedNode.children, newNodes);
      continue;
    }

    if (!dataroomNodes[extractedNode.id].uploadingFile) {
      continue;
    }

    newNodes[extractedNode.id] = {
      ...newNodes[extractedNode.id],
      file: newNodes[extractedNode.id].uploadingFile,
      uploading: false,
      uploadingFile: null,
    };

    newNodes = addDocumentNumber(extractedNode.id, newNodes);

    newNodes = addDocumentNumberWithoutFile(extractedNode.id, newNodes, -1);
  }

  return newNodes;
};

export default (state, action) => {
  if (action.type === `${TRANSFORM_ARCHIVE_NODE_TO_FOLDER}_SUCCESS`) {
    return {
      ...state,
      working: {
        ...state.working,
        nodes: setFileForExtractedDocuments(
          action.action.nodes,
          state.working.nodes
        ),
      },
    };
  }

  return baseReducer(state, action);
};
