import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import theme, {
  DANGER_COLOR,
  DARK_COLOR,
  LIGHT_BACKGROUND_COLOR,
  PRIMARY_COLOR,
  PRIMARY_COLOR_HOVER,
} from '../../../presentational/components/theme_blue';
import { leavePdfMergedAction } from '../reducers/pdfMerged';
import { mergeDocumentAction } from '../../dataroom/ducks/pdfMerged';
import { resetPickDocumentAction } from '../ducks/pickDocument';
import ModalConfirm from '../../pdfEditor/components/ModalConfirm';
import LoadingScreen from '../../pdfEditor/components/LoadingScreen';
import Document from './Document';
import LoadingIndicator from '../../../presentational/components/editor/LoadingIndicator';
import { resetPickedNodesAction } from '../../dataroom/ducks/pickNode';
import { previewPdfMergedAction } from '../reducers/pdfMerged';
import Level from '../../../presentational/components/editor/treeview/Level';
import Label from '../../../presentational/components/editor/forms/Label';
import InputText from '../../../presentational/components/editor/forms/InputText';
import { LIGHT_COLOR } from '../../../presentational/components/theme_blue';
import DarkOverlay from '../../../presentational/components/editor/DarkOverlay';
import Div from '../../../presentational/components/Div';
import CommandButtonsContainer from '../../../presentational/components/editor/CommandButtonsContainer';
import Button from '../../../presentational/components/Button';
import LightBox from '../../../presentational/components/editor/LightBox';
import CloseButton from '../../../presentational/components/editor/CloseButton';
import ScrollablePanel from '../../../presentational/components/editor/ScrollablePanel';
import Title from '../../../presentational/components/editor/Title';
import Pages from '../../../presentational/components/editor/Pages';
import FusionNameContainer from '../../../presentational/components/editor/FusionNameContainer';

class PdfMerged extends Component {
  constructor(props) {
    super(props);
    this.listenClick = this.listenClick.bind(this);
    this.submit = this.submit.bind(this);
    this.preview = this.preview.bind(this);
    this.close = this.close.bind(this);
    this.listenKey = this.listenKey.bind(this);
    this.state = {
      openModal: false,
      submitting: false,
      name: this.props.name,
    };
  }

  componentDidMount() {
    document.addEventListener('click', this.listenClick);
    document.addEventListener('keydown', this.listenKey);

    document.querySelector('body').style.overflow = 'hidden';
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.listenClick);
    document.removeEventListener('keydown', this.listenKey);

    if (this.props.isLargeDataroom || this.props.document === null) {
      document.querySelector('body').style.overflow = '';
    }
  }

  close() {
    this.props.leavePdfMergedAction();
  }

  listenClick(e) {
    if (!e.target.parentNode) {
      return;
    }

    if (!e.target.parentNode.classList) {
      return;
    }

    if (e.target.parentNode.classList.contains('menu-btn')) {
      return;
    }

    if (e.target.classList.contains('document')) {
      return;
    }

    if (e.target.parentNode.classList.contains('document')) {
      return;
    }

    if (e.target.parentNode.parentNode.classList.contains('document')) {
      return;
    }

    this.props.resetPickDocumentAction();
  }

  listenKey(e) {
    if (e.key === 'Escape') {
      this.setState({ openModal: true });
    }
  }

  submit() {
    this.setState({
      submitting: true,
    });

    this.props.resetPickedNodesAction();

    this.props.mergeDocumentAction(
      this.props.nodeId,
      this.props.nodes,
      this.state.name
    );
  }

  preview() {
    this.props.previewPdfMergedAction(
      this.props.nodeId,
      this.props.nodes,
      this.state.name
    );
  }

  render() {
    const { lock, nodes } = this.props;

    return (
      <ThemeProvider theme={theme}>
        <DarkOverlay background={DARK_COLOR} color={LIGHT_COLOR}>
          {lock.lockedByMe && (
            <CommandButtonsContainer background={DARK_COLOR}>
              <Div margin=".5em">
                <div className="menu">
                  <Button
                    title="valider, enregistrer et quitter"
                    onClick={this.submit}
                    className="menu-btn"
                    disabled={this.state.submitting}
                    marginBottom={0.5}
                  >
                    <i className="fa fa-fw fa-save" />
                  </Button>
                </div>
                <Button
                  title="prévisualiser"
                  className="menu-btn"
                  onClick={this.preview}
                >
                  <i className="fa fa-fw fa-eye" />
                </Button>
              </Div>
            </CommandButtonsContainer>
          )}
          <LightBox background={LIGHT_BACKGROUND_COLOR} color={DARK_COLOR}>
            <CloseButton
              onClick={() => this.setState({ openModal: true })}
              background={DARK_COLOR}
            />
            <ScrollablePanel>
              <Title color={LIGHT_COLOR} background={DARK_COLOR}>
                Fusion de documents
              </Title>
              <Pages>
                <FusionNameContainer>
                  <Label htmlFor="file_new_name" display="inline-flex">
                    Nom du document après la fusion :
                  </Label>{' '}
                  <InputText
                    type="text"
                    id="file_new_name"
                    display="inline-flex"
                    flexGrow
                    defaultValue={this.state.name}
                    width
                    onChange={event =>
                      this.setState({ name: event.target.value })
                    }
                  />
                </FusionNameContainer>
                <Level isFirstLevel>
                  {lock.lockedByMe &&
                    nodes.map(node => <Document node={node} key={node.id} />)}
                </Level>
              </Pages>
            </ScrollablePanel>
          </LightBox>
          {this.state.openModal && (
            <ModalConfirm
              title="Fermer l’éditeur ?"
              cancelLabel="Annuler"
              leaveLabel="Quitter l’éditeur"
              saveLabel="Sauvegarder et quitter"
              cancel={() => this.setState({ openModal: false })}
              leave={this.close}
              save={this.submit}
            >
              Vous n’avez pas enregistré vos modifications.
              <br />
              Êtes-vous sûr(e) de vouloir quitter l’éditeur de fusion de
              documents&nbsp;?
            </ModalConfirm>
          )}
          {(this.state.submitting || this.props.previewing) && (
            <LoadingScreen
              theme={this.props.theme}
              zIndex={1000}
              text="Génération en cours"
            />
          )}
        </DarkOverlay>
      </ThemeProvider>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    nodes: state.pdfMerged.nodes,
    previewing: state.pdfMerged.previewing,
    pickedNodes: state.pdfMerged.pickedNodes,
    nodeId: state.pdfMerged.nodeId,
    lock: state.dataroom.working.lock,
    isLargeDataroom: state.dataroom.isLargeDataroom,
    document: state.document.document,
    name: state.dataroom.working.nodes[state.pdfMerged.nodeId].name,
  };
}

export default connect(mapStateToProps, {
  leavePdfMergedAction,
  resetPickDocumentAction,
  mergeDocumentAction,
  resetPickedNodesAction,
  previewPdfMergedAction,
})(PdfMerged);
