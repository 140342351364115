import { TYPE_DOCUMENT } from '../../dataroom/documents/type';
import uuidv4 from 'uuid/v4';

const CREATE_NODE = 'EXPLODE_CREATE_NODE';
const REMOVE_NODE = 'EXPLODE_REMOVE_NODE';

export const createNodeAction = nodeName => ({
  type: CREATE_NODE,
  node: {
    name: nodeName,
    type: TYPE_DOCUMENT,
    id: uuidv4(),
  },
});

export const removeNodeAction = index => ({
  type: REMOVE_NODE,
  index: index,
});

export default function reducer(state, action) {
  switch (action.type) {
    case REMOVE_NODE: {
      if (action.index !== null) {
        return {
          ...state,
          newNodes: state.newNodes.filter(
            (newNode, index) => index !== action.index
          ),
          pickedNodeIds: [],
        };
      }

      return {
        ...state,
        newNodes: state.newNodes.filter(
          newNode => !state.pickedNodeIds.includes(newNode.nodeId)
        ),
        pickedNodeIds: [],
      };
    }
    case CREATE_NODE: {
      if (!action.node.name || state.pickedPageNumbers.length === 0) {
        return state;
      }

      return {
        ...state,
        newNodes: [
          ...state.newNodes,
          {
            pageNumbers: state.pickedPageNumbers.sort((a, b) => a - b),
            nodeName: action.node.name,
            nodeId: action.node.id,
            nodeType: action.node.type,
            picked: false,
          },
        ],
      };
    }
  }

  return state;
}
