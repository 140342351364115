const allowedFileExtension = [
  'pdf',
  'doc',
  'docx',
  'xls',
  'xlsx',
  'ppt',
  'pptx',
  'dwg',
  'png',
  'jpeg',
  'tiff',
  'tif',
  'dwf',
  'dwfx',
  'msg',
  'jpg',
  'csv',
  'zip',
  'xlsm',
  'rar',
  'mp4',
];

export default allowedFileExtension;

export function isFileAllowed(fileName) {
  const extension = fileName
    .substring(fileName.lastIndexOf('.') + 1)
    .toLowerCase();

  return allowedFileExtension.indexOf(extension) !== -1;
}
