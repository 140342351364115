import { takeLatest, select, call, put } from 'redux-saga/effects';
import { unfoldParentNodes } from '../reducers/utils/nodeFold';
import getPosition from './utils/getPosition';
import getPathLevel from './utils/getPathLevel';
import { TYPE_DOCUMENT, TYPE_FOLDER } from '../documents/type';
import { addNotificationAction } from '../../app/actions/notifications';

import apiClient from 'js/dataroom/core/apiClient';
import { TYPE_SUCCESS, TYPE_WARNING } from '../../app/reducers/notifications';

const VALIDATE = 'VALIDATE_DATAROOM';
export const VALIDATE_SUCCESS = 'VALIDATE_DATAROOM_SUCCESS';
export const VALIDATE_ERROR = 'VALIDATE_DATAROOM_ERROR';

const FOLDER_HAS_FOLDER_AND_DOCUMENTS_AS_FIRST_CHILDREN =
  'FOLDER_HAS_FOLDER_AND_DOCUMENTS_AS_FIRST_CHILDREN';
const NAME_TOO_LONG = 'NAME_TOO_LONG';
const NAME_WITH_ILLEGAL_CHAR = 'NAME_WITH_ILLEGAL_CHAR';
export const DUPLICATED_FILE = 'DUPLICATED_FILE';
const MISSING_FILE = 'MISSING_FILE';
const EMPTY_FOLDER = 'EMPTY_FOLDER';

export const validateAction = () => ({ type: VALIDATE });

const callApi = dataroomId =>
  apiClient
    .request(new Request(`/api/datarooms/${dataroomId}/validate`))
    .then(response => {
      if (!response.ok) {
        throw new Error();
      }
      return response.json();
    });

function* validate() {
  const dataroomId = yield select(state => state.dataroom.dataroom.id);
  let validationErrors = null;

  try {
    validationErrors = yield call(callApi, dataroomId);
  } catch (e) {
    yield put(addNotificationAction('La validation de la dataroom a échoué'));
    yield put({ type: VALIDATE_ERROR });
    return;
  }
  if (
    validationErrors.violations.length === 0 &&
    validationErrors.warning.length === 0
  ) {
    yield put(addNotificationAction('La dataroom est valide', TYPE_SUCCESS));
  }

  if (
    validationErrors.violations.length === 0 &&
    validationErrors.warning.length > 0
  ) {
    yield put(
      addNotificationAction(
        `La dataroom est valide mais contient des alertes (${validationErrors.warning.length} alertes)`,
        TYPE_WARNING
      )
    );
  }

  if (
    validationErrors.violations.length > 0 &&
    validationErrors.warning.length > 0
  ) {
    yield put(
      addNotificationAction(
        `La dataroom n'est pas valide (${validationErrors.violations.length} erreurs) et contient des alertes (${validationErrors.warning.length} alertes)`,
        TYPE_WARNING
      )
    );
  }

  if (
    validationErrors.violations.length > 0 &&
    validationErrors.warning.length === 0
  ) {
    yield put(
      addNotificationAction(
        `La dataroom n'est pas valide (${validationErrors.violations.length} erreurs)`,
        TYPE_WARNING
      )
    );
  }

  yield put({ type: VALIDATE_SUCCESS, validationErrors });
}

export function* saga() {
  yield takeLatest(VALIDATE, validate);
}

function isFirstPath(path1, path2) {
  const pathLevel1 = getPathLevel(path1);
  const pathLevel2 = getPathLevel(path2);

  for (let i = 1; i <= pathLevel1 && i <= pathLevel2; i++) {
    const path1Position = getPosition(i, path1);
    const path2Position = getPosition(i, path2);

    if (path1Position !== path2Position) {
      return path1Position < path2Position;
    }
  }

  return pathLevel1 < pathLevel2;
}

const removeNodeFromErrorList = (validation, nodeIdToRemove) => {
  let newNodesWithErrorsIds = [...validation.nodesWithErrorsIds];
  newNodesWithErrorsIds.splice(
    newNodesWithErrorsIds.indexOf(nodeIdToRemove),
    1
  );

  return {
    ...validation,
    nodesWithErrorsIds: newNodesWithErrorsIds,
  };
};

const removeNodeFromWarningList = (validation, nodeIdToRemove) => {
  let newNodesWithWarningIds = [...validation.nodesWithWarningIds];
  newNodesWithWarningIds.splice(
    newNodesWithWarningIds.indexOf(nodeIdToRemove),
    1
  );

  return {
    ...validation,
    nodesWithWarningIds: newNodesWithWarningIds,
  };
};

// node validation

// contains / or \
const nodeHasNameWithIllegalChars = node => /[\/\\]/.test(node.name);
const nodeHasNameTooLong = node => node.name.length > 250;
const nodeHasMissingFile = node => !!node.file;

const folderHasFolderAndDocumentsAsFirstChildren = (node, nodes) => {
  if (node.type === TYPE_DOCUMENT) {
    return false;
  }

  let hasDocument = false;
  let hasFolder = false;

  for (let childId of node.childIds) {
    if (!hasDocument) {
      hasDocument = nodes[childId].type === TYPE_DOCUMENT;
    }
    if (!hasFolder) {
      hasFolder = nodes[childId].type === TYPE_FOLDER;
    }

    if (hasDocument && hasFolder) {
      return true;
    }
  }

  return false;
};

const getParentNodeFromAction = (state, action) => {
  if (action.type === 'MOVE_NODES_SUCCESS') {
    const parentId =
      state.working.savedNodes[action.action.frontActionId][
        action.action.sourceNodeIds[0]
      ].parent.id;

    return state.working.nodes[parentId];
  }

  let sourceNode = null;

  if (action.type === 'REMOVE_NODES_SUCCESS') {
    sourceNode =
      state.working.savedNodes[action.action.frontActionId][
        action.action.nodeIds[0]
      ];
  } else {
    sourceNode = state.working.nodes[action.action.nodeId];
  }

  return state.working.nodes[sourceNode.parentId];
};

const getNewParentNodeFromAction = (state, action) => {
  if (
    action.type === 'MOVE_NODES_SUCCESS' ||
    action.type === 'COPY_NODE_SUCCESS' ||
    action.type === 'ADD_FILES_SUCCESS'
  ) {
    return state.working.nodes[action.action.destinationId];
  }

  if (action.type === 'ADD_NODES_SUCCESS') {
    return state.working.nodes[action.action.nodeId];
  }
};

const correctErrorFolderHasFolderAndDocumentsAsFirstChildren = (
  state,
  action
) => {
  const node = getParentNodeFromAction(state, action);

  if (!node.validationErrors) {
    return state;
  }

  const folderHasFolderAndDocumentsAsFirstChildrenIndex = node.validationErrors.indexOf(
    FOLDER_HAS_FOLDER_AND_DOCUMENTS_AS_FIRST_CHILDREN
  );
  if (
    folderHasFolderAndDocumentsAsFirstChildrenIndex === -1 ||
    folderHasFolderAndDocumentsAsFirstChildren(node, state.working.nodes)
  ) {
    return state;
  }

  let newValidationErrors = [...node.validationErrors];
  newValidationErrors.splice(
    folderHasFolderAndDocumentsAsFirstChildrenIndex,
    1
  );

  const newState = {
    ...state,
    working: {
      ...state.working,
      nodes: {
        ...state.working.nodes,
        [node.id]: {
          ...node,
          validationErrors: newValidationErrors,
        },
      },
    },
  };

  if (newValidationErrors.length === 0) {
    newState.working.nodes[node.id].validationErrors = null;
    newState.working.validation = removeNodeFromErrorList(
      newState.working.validation,
      node.id
    );
  }

  return newState;
};

const correctWarningDuplicatedFiles = (state, nodeIds, frontActionId) => {
  nodeIds.forEach(nodeId => {
    state = correctWarningDuplicatedFile(
      state,
      state.working.savedNodes[frontActionId][nodeId]
    );
  });

  return state;
};

const correctWarningDuplicatedFile = (state, node) => {
  if (!node) {
    return state;
  }

  if (!node.validationWarnings) {
    return state;
  }

  const nodeHasDuplicatedFileIndex = node.validationWarnings.findIndex(
    warning => warning.name === DUPLICATED_FILE
  );

  if (nodeHasDuplicatedFileIndex === -1) {
    return state;
  }

  // Extract relatedIds to update
  const relatedIds =
    node.validationWarnings[nodeHasDuplicatedFileIndex].relatedIds;

  let newValidationWarnings = [...node.validationWarnings];
  newValidationWarnings.splice(nodeHasDuplicatedFileIndex, 1);

  const newState = {
    ...state,
    working: {
      ...state.working,
      nodes: {
        ...state.working.nodes,
        [node.id]: {
          ...node,
          validationWarnings: newValidationWarnings,
        },
      },
    },
  };

  if (newValidationWarnings.length === 0) {
    newState.working.nodes[node.id].validationWarnings = null;
    newState.working.validation = removeNodeFromWarningList(
      newState.working.validation,
      node.id
    );
  }

  // Remove current path from relatedNodes
  relatedIds.forEach(relatedId => {
    const relatedNode = state.working.nodes[relatedId];
    if (!relatedNode) {
      return;
    }

    const validationWarnings = relatedNode.validationWarnings.map(
      validationWarning => {
        if (validationWarning.name === DUPLICATED_FILE) {
          return {
            name: DUPLICATED_FILE,
            relatedIds: validationWarning.relatedIds.filter(
              id => id !== node.id
            ),
          };
        }

        return validationWarning;
      }
    );

    newState.working.nodes[relatedNode.id] = {
      ...relatedNode,
      validationWarnings: validationWarnings,
    };
  });

  // Remove warning from the only relatedNode
  if (relatedIds.length === 1) {
    return correctWarningDuplicatedFile(
      newState,
      newState.working.nodes[relatedIds[0]]
    );
  }

  return newState;
};

const correctErrorMissingFile = (state, nodeId) => {
  const node = state.working.nodes[nodeId];
  if (!node.validationErrors) {
    return state;
  }

  const nodeHasMissingFileIndex = node.validationErrors.indexOf(MISSING_FILE);

  if (nodeHasMissingFileIndex === -1) {
    return state;
  }

  let newValidationErrors = [...node.validationErrors];
  newValidationErrors.splice(nodeHasMissingFileIndex, 1);

  const newState = {
    ...state,
    working: {
      ...state.working,
      nodes: {
        ...state.working.nodes,
        [node.id]: {
          ...node,
          validationErrors: newValidationErrors,
        },
      },
    },
  };

  if (newValidationErrors.length === 0) {
    newState.working.nodes[node.id].validationErrors = null;
    newState.working.validation = removeNodeFromErrorList(
      newState.working.validation,
      node.id
    );
  }

  return newState;
};

const getFirstNode = (
  firstNodeWithPath,
  firstNodeWithId,
  validation,
  state
) => {
  if (!firstNodeWithPath) {
    firstNodeWithId = validation.id;
    firstNodeWithPath = state.working.nodes[validation.id].path;
  } else if (
    isFirstPath(state.working.nodes[validation.id].path, firstNodeWithPath)
  ) {
    firstNodeWithId = validation.id;
    firstNodeWithPath = state.working.nodes[validation.id].path;
  }

  return [firstNodeWithPath, firstNodeWithId];
};

const correctErrorEmptyFolder = (state, action) => {
  if (action.action.position !== 'INSIDE') {
    return state;
  }

  const node = getNewParentNodeFromAction(state, action);

  if (!node.validationErrors) {
    return state;
  }

  const nodeHasEmptyFolder = node.validationErrors.indexOf(EMPTY_FOLDER);

  if (nodeHasEmptyFolder === -1) {
    return state;
  }

  let newValidationErrors = [...node.validationErrors];
  newValidationErrors.splice(nodeHasEmptyFolder, 1);

  const newState = {
    ...state,
    working: {
      ...state.working,
      nodes: {
        ...state.working.nodes,
        [node.id]: {
          ...node,
          validationErrors: newValidationErrors,
        },
      },
    },
  };

  if (newValidationErrors.length === 0) {
    newState.working.nodes[node.id].validationErrors = null;
    newState.working.validation = removeNodeFromErrorList(
      newState.working.validation,
      node.id
    );

    return newState;
  }
};

export default function reducer(state, action) {
  switch (action.type) {
    case 'ATTACH_FILE_TO_DOCUMENT_SUCCESS': {
      const newState = correctWarningDuplicatedFile(
        state,
        state.working.nodes[action.action.nodeId]
      );

      return correctErrorMissingFile(newState, action.action.nodeId);
    }
    case 'DETACH_FILE_TO_DOCUMENT_SUCCESS':
    case 'PDF_EDIT_SUCCESS':
    case 'PDF_EXPLODE_SUCCESS':
    case 'PDF_MERGE_SUCCESS':
      return correctWarningDuplicatedFile(
        state,
        state.working.nodes[action.action.nodeId]
      );
    case 'CHANGE_NODE_TYPE_SUCCESS':
      let newState = correctErrorFolderHasFolderAndDocumentsAsFirstChildren(
        state,
        action
      );
      return correctErrorMissingFile(newState, action.action.nodeId);
    case 'MOVE_NODES_SUCCESS':
      newState = correctErrorFolderHasFolderAndDocumentsAsFirstChildren(
        state,
        action
      );
      return correctErrorEmptyFolder(newState, action);
    case 'REMOVE_NODES_SUCCESS':
      newState = correctErrorFolderHasFolderAndDocumentsAsFirstChildren(
        state,
        action
      );

      return correctWarningDuplicatedFiles(
        newState,
        action.action.nodeIds,
        action.action.frontActionId
      );
    case 'ADD_NODES_SUCCESS':
      return correctErrorEmptyFolder(state, action);
    case 'ADD_FILES_SUCCESS':
      return correctErrorEmptyFolder(state, action);
    case 'COPY_NODE_SUCCESS':
      return correctErrorEmptyFolder(state, action);
    case 'RENAME_NODE_SUCCESS': {
      const node = state.working.nodes[action.action.nodeId];

      if (!node.validationErrors) {
        return state;
      }

      const nameTooLongIndex = node.validationErrors.indexOf(NAME_TOO_LONG);
      const nameWithIllegalCharIndex = node.validationErrors.indexOf(
        NAME_WITH_ILLEGAL_CHAR
      );

      if (
        (nameTooLongIndex === -1 || nodeHasNameTooLong(node)) &&
        (nameWithIllegalCharIndex === -1 || nodeHasNameWithIllegalChars(node))
      ) {
        return state;
      }

      let newValidationErrors = [...node.validationErrors];

      if (nameTooLongIndex !== -1 && !nodeHasNameTooLong(node)) {
        newValidationErrors.splice(
          newValidationErrors.indexOf(NAME_TOO_LONG),
          1
        );
      }

      if (
        nameWithIllegalCharIndex !== -1 &&
        !nodeHasNameWithIllegalChars(node)
      ) {
        newValidationErrors.splice(
          newValidationErrors.indexOf(NAME_WITH_ILLEGAL_CHAR),
          1
        );
      }

      const newState = {
        ...state,
        working: {
          ...state.working,
          nodes: {
            ...state.working.nodes,
            [node.id]: {
              ...node,
              validationErrors: newValidationErrors,
            },
          },
        },
      };

      if (newValidationErrors.length === 0) {
        newState.working.nodes[node.id].validationErrors = null;
        newState.working.validation = removeNodeFromErrorList(
          newState.working.validation,
          node.id
        );
      }

      return newState;
    }

    case VALIDATE:
      return {
        ...state,
        working: {
          ...state.working,
          validation: {
            ...state.working.validation,
            validating: true,
          },
        },
      };
    case VALIDATE_SUCCESS: {
      const { validationErrors } = action;

      const newState = {
        ...state,
        working: {
          ...state.working,
          validation: {
            ...state.working.validation,
            validating: false,
          },
        },
      };

      // TODO: test reducer and saga
      let newNodes = {};
      let firstNodeWithErrorId = null;
      let firstNodeWithErrorPath = null;

      // remove old errors
      state.working.validation.nodesWithErrorsIds.forEach(nodeWithErrorsId => {
        newNodes[nodeWithErrorsId] = {
          ...state.working.nodes[nodeWithErrorsId],
          validationErrors: null,
        };
      });

      // add new errors
      newState.working.validation.nodesWithErrorsIds = validationErrors.violations.map(
        validationError => {
          [firstNodeWithErrorPath, firstNodeWithErrorId] = getFirstNode(
            firstNodeWithErrorPath,
            firstNodeWithErrorId,
            validationError,
            state
          );

          newNodes[validationError.id] = {
            ...state.working.nodes[validationError.id],
            validationErrors: validationError.reasons,
          };

          return validationError.id;
        }
      );

      newNodes = {
        ...state.working.nodes,
        ...newNodes,
      };

      let firstNodeWithWarningId = null;
      let firstNodeWithWarningPath = null;

      // remove old warning
      state.working.validation.nodesWithWarningIds.forEach(nodeWithErrorsId => {
        newNodes[nodeWithErrorsId] = {
          ...newNodes[nodeWithErrorsId],
          validationWarnings: null,
        };
      });

      // add new warning
      newState.working.validation.nodesWithWarningIds = validationErrors.warning.map(
        validationWarning => {
          [firstNodeWithWarningPath, firstNodeWithWarningId] = getFirstNode(
            firstNodeWithWarningPath,
            firstNodeWithWarningId,
            validationWarning,
            state
          );

          newNodes[validationWarning.id] = {
            ...newNodes[validationWarning.id],
            validationWarnings: validationWarning.reasons,
          };

          return validationWarning.id;
        }
      );

      // unfold parent nodes
      [...validationErrors.warning, ...validationErrors.violations].forEach(
        validationError => {
          if (
            validationError.reasons.indexOf(
              FOLDER_HAS_FOLDER_AND_DOCUMENTS_AS_FIRST_CHILDREN
            ) !== -1 ||
            newNodes[validationError.id].path === '0'
          ) {
            newNodes = unfoldParentNodes(
              newNodes,
              state.dataroom.id,
              validationError.id,
              state.currentDataroomType
            );
          } else {
            newNodes = unfoldParentNodes(
              newNodes,
              state.dataroom.id,
              newNodes[validationError.id].parentId,
              state.currentDataroomType
            );
          }
        }
      );

      newState.working.nodes = newNodes;
      newState.working.validation.firstNodeWithErrorId = firstNodeWithErrorId;
      newState.working.validation.firstNodeWithWarningId = firstNodeWithWarningId;

      return newState;
    }
    case VALIDATE_ERROR:
      return {
        ...state,
        working: {
          ...state.working,
          validation: {
            ...state.working.validation,
            validating: false,
          },
        },
      };

    default:
      return state;
  }
}
