import React, { Fragment, useEffect, useMemo, useReducer } from 'react';
import theme_blue, {
  BODY_FONT,
  CLIENT_UI_COLOR,
  DARK_GREY_COLOR,
  LIGHT_COLOR,
} from '../../presentational/components/theme_blue';
import Helmet from 'react-helmet';
import Layout from '../../app/layout/Layout';
import { Menu } from '../../app/components/menu/Menu';
import Content from '../../app/layout/Content';
import Div from '../../presentational/components/Div';
import queryString from 'qs';
import H2 from '../../presentational/components/H2';
import Span from '../../presentational/components/Span';
import IconDataroom from '../../presentational/components/img/icons/IconDataroom';
import GridRow from '../../presentational/components/GridRow';
import GridCol from '../../presentational/components/GridCol';
import IconSearch from '../../presentational/components/img/icons/IconSearch';
import Hr from '../../presentational/components/Hr';
import Button from '../../presentational/components/Button';
import {
  UPDATE_PAGE,
  UPDATE_SEARCH,
  useSearchReducer,
} from '../../app/hooks/search';
import useApiClient, { useQuery } from '../../app/hooks/apiClient';
import DataRoomSearchResult from '../components/dataroom/DataRoomSearchResult';
import { DeleteDataRoomModal } from '../components/dataroom/DeleteDataRoomModal';
import Notifications from '../../dataroom/app/containers/Notifications';
import { Link } from 'react-router';
import A from '../../presentational/components/A';
import Input from '../../presentational/components/form/Input';

const ITEMS_PER_PAGE = 20;

export const MODE_DELETE_DATAROOM = 'MODE_DELETE_DATAROOM';

export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const MODAL_SEND = 'MODAL_SEND';
export const MODAL_SENT = 'MODAL_SENT';

const initialState = {
  modal: {
    mode: null,
    open: false,
    data: null,
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case OPEN_MODAL:
      return {
        ...state,
        modal: {
          ...state.modal,
          open: true,
          mode: action.mode,
          data: action.data,
        },
      };
    case CLOSE_MODAL:
      return {
        ...state,
        modal: {
          ...state.modal,
          open: false,
          mode: null,
          data: null,
        },
      };
    case MODAL_SEND:
      return {
        ...state,
        modal: {
          ...state.modal,
          sending: true,
        },
      };
    case MODAL_SENT:
      return {
        ...state,
        modal: {
          ...state.modal,
          sending: false,
        },
      };
  }

  return state;
};

export default function DataRoom(props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const [search, dispatchSearch] = useSearchReducer({
    filters: {
      text: '',
    },
    order: {
      field: 'createdAt',
      direction: 'desc',
    },
    direction: 'asc',
    page: 1,
    items: ITEMS_PER_PAGE,
  });

  const listUrl = useMemo(() => {
    return `/api/datarooms/?${queryString.stringify({
      resources: ['DATAROOM_OWNER', 'DATAROOM_COMPANY'],
      page: search.page,
      items: search.items,
      order: search.order.field,
      direction: search.order.direction,
      query: search.filters.text,
    })}`;
  }, [search.page, search.order, search.filters]);

  const [loaded, , data, , reloadData] = useQuery(listUrl);

  return (
    <Fragment>
      <Helmet>
        <title>Espace client - Accueil</title>
      </Helmet>

      <Layout>
        <Menu dataroom />

        <Content>
          <H2>
            <Span
              color={CLIENT_UI_COLOR}
              fontFamily={BODY_FONT}
              display="flex"
              alignItems="center"
              justifyContent="center"
              minWidth="100%"
            >
              <IconDataroom marginRight=".5em" marginBottom="-.1em" />
              Datarooms
            </Span>
          </H2>
          <Div maxWidth="50rem" margin="0 auto" fontWeight="300">
            <GridRow noGutters verticalAlign="end">
              <GridCol xs={8 / 12} md={8 / 12} noGutters>
                <Input
                  type="text"
                  name="text"
                  onChange={evt => {
                    dispatchSearch({
                      type: UPDATE_SEARCH,
                      field: 'text',
                      value: evt.target.value,
                    });
                  }}
                  label="Chercher une dataroom"
                  isHorizontal
                  displayBlock
                  labelColor={DARK_GREY_COLOR}
                  marginBottom0
                />
              </GridCol>
              <GridCol xs={4 / 12} md={1 / 12} noGutters>
                <Span display="block" height="2.45rem">
                  <Button
                    backgroundColor={CLIENT_UI_COLOR}
                    borderColor={CLIENT_UI_COLOR}
                    backgroundHoverColor={LIGHT_COLOR}
                    hoverColor={CLIENT_UI_COLOR}
                    height100Percent
                    displayBlockForMobile
                  >
                    <IconSearch alt="Rechercher" />
                  </Button>
                </Span>
              </GridCol>
              <GridCol xs={12 / 12} md={3 / 12} textAlign="right" noGutters>
                <Div hiddenForMd height="1rem">
                  {/* a vertical spacer for smaller screens */}
                </Div>
                <A as={Link} to={'/datarooms/creation'}>
                  <Button
                    backgroundColor={CLIENT_UI_COLOR}
                    borderColor={CLIENT_UI_COLOR}
                    backgroundHoverColor={LIGHT_COLOR}
                    hoverColor={CLIENT_UI_COLOR}
                    height100Percent
                    displayBlock
                  >
                    + Créer une dataroom
                  </Button>
                </A>
              </GridCol>
            </GridRow>
          </Div>
          <Hr />
          {loaded && (
            <DataRoomSearchResult
              currentPage={search.page}
              total={data.resultNumber}
              items={ITEMS_PER_PAGE}
              datarooms={data.dataRoomItemViews}
              changePage={page =>
                dispatchSearch({ type: UPDATE_PAGE, page: page })
              }
              onDelete={dataroom =>
                dispatch({
                  type: OPEN_MODAL,
                  data: dataroom,
                  mode: MODE_DELETE_DATAROOM,
                })
              }
            />
          )}
          <DeleteDataRoomModal
            modal={state.modal}
            onClose={() => dispatch({ type: CLOSE_MODAL })}
            reload={() => reloadData()}
          />
        </Content>
      </Layout>

      <Notifications />
    </Fragment>
  );
}
