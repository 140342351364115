import getParentPath from './utils/getParentPath';
import getPosition from './utils/getPosition';
import { REMOVE_NODES } from './removeNodes';
import {
  getEmptyFolderChildNodes,
  REMOVE_EMPTY_FOLDER_NODES,
} from './removeEmptyFolderNodes';
import {
  REMOVE_EMPTY_DOCUMENT_NODES,
  getEmptyDocumentsChildNodes,
} from './removeDocumentNodeWithoutFile';
import getPathLevel from './utils/getPathLevel';

const PICK_NODE = 'PICK_NODE';

const ADD_NODE_TO_PICKED = 'ADD_NODE_TO_PICKED';
const REMOVE_NODE_FROM_PICKED = 'REMOVE_NODE_FROM_PICKED';

const ADD_NODES_BETWEEN_PICKED = 'ADD_NODES_BETWEEN_PICKED';

const RESET_PICKED_NODES = 'RESET_PICKED_NODES';

export const pickNodeAction = nodeId => ({ type: PICK_NODE, nodeId });

export const addNodeToPickedAction = nodeId => ({
  type: ADD_NODE_TO_PICKED,
  nodeId,
});
export const removeNodeToPickedAction = nodeId => ({
  type: REMOVE_NODE_FROM_PICKED,
  nodeId,
});

export const addNodesBetweenPickedAction = nodeId => ({
  type: ADD_NODES_BETWEEN_PICKED,
  nodeId,
});

export const resetPickedNodesAction = () => ({
  type: RESET_PICKED_NODES,
});

function addNodePick(state, nodeId) {
  if (
    state.working.pickedNodeIds.findIndex(
      newPickedNodeId => newPickedNodeId === nodeId
    ) !== -1
  ) {
    return {
      ...state,
    };
  }

  return {
    ...state,
    working: {
      ...state.working,
      pickedNodeIds: [...state.working.pickedNodeIds, nodeId],
      nodes: {
        ...state.working.nodes,
        [nodeId]: {
          ...state.working.nodes[nodeId],
          picked: true,
        },
      },
    },
  };
}

const resetPickedNodes = state => {
  let newState = {
    ...state,
    working: {
      ...state.working,
    },
  };

  state.working.pickedNodeIds.forEach(nodeId => {
    newState.working.nodes[nodeId] = {
      ...newState.working.nodes[nodeId],
      picked: false,
    };
  });

  newState.working.pickedNodeIds = [];

  return newState;
};

const nodeIsParentOfOneNode = (node, nodes) => {
  if (nodes.length > 0 && node.path === '0') {
    return true;
  }

  for (let nodeToCheck of nodes) {
    if (nodeToCheck.path.startsWith(`${node.path}.`)) {
      return true;
    }
  }

  return false;
};

const pickedNodesChildrenOfOneDeletedNode = (state, deletedNodeIds) => {
  const nodes = state.working.nodes;

  const pickedNodes = state.working.pickedNodeIds.map(nodeId => nodes[nodeId]);

  for (let deletedNodeId of deletedNodeIds) {
    if (nodeIsParentOfOneNode(nodes[deletedNodeId], pickedNodes)) {
      return true;
    }
  }

  return false;
};

const actionDeletePickedNodes = (nodeIds, pickedNodeIds) => {
  return (
    nodeIds.findIndex(
      nodeId =>
        pickedNodeIds.findIndex(pickedNodeId => pickedNodeId === nodeId) !== -1
    ) !== -1
  );
};

export default function reducer(state, action) {
  switch (action.type) {
    case ADD_NODES_BETWEEN_PICKED: {
      const pickedNode = state.working.nodes[action.nodeId];
      const pickedNodeParentPath = getParentPath(pickedNode.path);

      if (state.working.pickedNodeIds.length === 0) {
        return addNodePick(state, action.nodeId);
      }

      for (let pickedNodeId of state.working.pickedNodeIds) {
        if (
          getParentPath(state.working.nodes[pickedNodeId].path) !==
          pickedNodeParentPath
        ) {
          return state;
        }
      }

      const pickedNodePosition = getPosition(
        getPathLevel(pickedNode.path),
        pickedNode.path
      );
      const selectedNode =
        state.working.nodes[
          state.working.pickedNodeIds[state.working.pickedNodeIds.length - 1]
        ];

      const selectedNodePosition = getPosition(
        getPathLevel(selectedNode.path),
        selectedNode.path
      );
      const childIds = state.working.nodes[pickedNode.parentId].childIds;

      const begin =
        pickedNodePosition < selectedNodePosition
          ? pickedNodePosition
          : selectedNodePosition;
      const end =
        pickedNodePosition > selectedNodePosition
          ? pickedNodePosition
          : selectedNodePosition;

      const nodeIdsToPick = childIds.slice(begin - 1, end);

      let newState = { ...state };

      for (let nodeIdToPick of nodeIdsToPick) {
        newState = addNodePick(newState, nodeIdToPick);
      }

      return newState;
    }

    case ADD_NODE_TO_PICKED: {
      if (
        state.working.pickedNodeIds.length > 0 &&
        state.working.nodes[action.nodeId].path === '0'
      ) {
        return state;
      }

      const pickedNodeParentPath = getParentPath(
        state.working.nodes[action.nodeId].path
      );

      for (let pickedNodeId of state.working.pickedNodeIds) {
        if (state.working.nodes[pickedNodeId].path === '0') {
          return state;
        }
        if (
          getParentPath(state.working.nodes[pickedNodeId].path) !==
          pickedNodeParentPath
        ) {
          return state;
        }
      }

      return addNodePick(state, action.nodeId);
    }
    case REMOVE_NODE_FROM_PICKED: {
      return {
        ...state,
        working: {
          ...state.working,
          pickedNodeIds: state.working.pickedNodeIds.filter(
            nodeId => nodeId !== action.nodeId
          ),
          nodes: {
            ...state.working.nodes,
            [action.nodeId]: {
              ...state.working.nodes[action.nodeId],
              picked: false,
            },
          },
        },
      };
    }
    case REMOVE_EMPTY_DOCUMENT_NODES: {
      if (state.working.pickedNodeIds.length === 0) {
        return state;
      }
      const nodeIds = getEmptyDocumentsChildNodes(
        state.working.nodes[action.parentNodeId].childIds,
        state.working.nodes
      );
      if (actionDeletePickedNodes(nodeIds, state.working.pickedNodeIds)) {
        return resetPickedNodes(state);
      }
      return state;
    }
    case REMOVE_EMPTY_FOLDER_NODES: {
      if (state.working.pickedNodeIds.length === 0) {
        return state;
      }
      const nodeIds = getEmptyFolderChildNodes(
        state.working.nodes[action.parentNodeId].id,
        state.working.nodes[action.parentNodeId].childIds,
        state.working.nodes
      );
      if (actionDeletePickedNodes(nodeIds, state.working.pickedNodeIds)) {
        return resetPickedNodes(state);
      }
      return state;
    }
    case PICK_NODE:
    case RESET_PICKED_NODES: {
      const newState = resetPickedNodes(state);

      if (action.type === RESET_PICKED_NODES) {
        return newState;
      }

      return addNodePick(newState, action.nodeId);
    }
    case REMOVE_NODES: {
      if (state.working.pickedNodeIds.length === 0) {
        return state;
      }

      if (
        actionDeletePickedNodes(action.nodeIds, state.working.pickedNodeIds)
      ) {
        return resetPickedNodes(state);
      }

      if (
        !action.deleteOnlyHead &&
        pickedNodesChildrenOfOneDeletedNode(state, action.nodeIds)
      ) {
        return resetPickedNodes(state);
      }

      return state;
    }
  }

  return state;
}
