import { TYPE_DOCUMENT, TYPE_FOLDER } from '../../documents/type';

/**
 * return a generator which permit to loop on all the childs of the given nodeId
 * @param nodes
 * @param nodeId
 * @yields node
 */
export default function* childNode(nodes, nodeId) {
  if (nodes[nodeId].type === TYPE_DOCUMENT) {
    return;
  }

  for (const childId of nodes[nodeId].childIds) {
    yield nodes[childId];

    yield* childNode(nodes, childId);
  }
}
