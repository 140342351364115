import React, { Component, Fragment } from 'react';
import PdfCanvas from '../../pdfEditor/components/PdfCanvas';
import { connect } from 'react-redux';
import {
  addPdfBetweenPickedAction,
  addPdfToPickedAction,
  pickPdfAction,
  removePdfToPickedAction,
} from '../ducks/pickPage';
import PagePlaceholder from '../../../presentational/components/editor/PagePlaceholder';
import PageNumber from '../../../presentational/components/editor/PageNumber';

class Page extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    e.stopPropagation();

    if (e.shiftKey) {
      if (this.props.page.picked) {
        return;
      }

      this.props.addPdfBetweenPickedAction(this.props.page.pageNumber);
      return;
    }

    if (e.ctrlKey) {
      if (!this.props.page.picked) {
        this.props.addPdfToPickedAction(this.props.page.pageNumber);
        return;
      }

      this.props.removePdfToPickedAction(this.props.page.pageNumber);
      return;
    }

    this.props.pickPdfAction(this.props.page.pageNumber);
  }

  render() {
    return (
      <PagePlaceholder picked={this.props.currentPage.picked}>
        <PdfCanvas {...this.props} width={200} handleClick={this.handleClick} />
        <PageNumber>{this.props.page.pageNumber}</PageNumber>
      </PagePlaceholder>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    currentPage: state.pdfExplode.pages.find(
      page => page.pageNumber === props.page.pageNumber
    ),
  };
}
export default connect(mapStateToProps, {
  pickPdfAction,
  addPdfBetweenPickedAction,
  addPdfToPickedAction,
  removePdfToPickedAction,
})(Page);
