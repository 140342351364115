import React, { Component, Fragment } from 'react';
import Button from '../../app/components/Button';
import { DropdownButton, MenuItem } from 'react-bootstrap';

function transformActionToButton(action) {
  const buttonsProps = {
    disabled: action.disabled,
    onClick: action.onClick,
    type: 'button',
    buttonType: action.buttonType,
    icon: action.icon,
    label: action.label,
    confirmable: action.confirmable,
  };

  if (action.subActions) {
    buttonsProps.subButtons = action.subActions.map(transformActionToButton);
  }
  return buttonsProps;
}

const Action = props => {
  return (
    <Fragment>
      <Button {...transformActionToButton(props.action)} />
      &nbsp;{' '}
    </Fragment>
  );
};

class ActionBar extends Component {
  render() {
    const { actions, hiddenActions } = this.props;

    return (
      <div className="commands-toolbar text-right">
        <div className="placeholder">
          {actions.map((action, index) => (
            <Action key={index} action={action} />
          ))}

          {hiddenActions && hiddenActions.length > 0 && (
            <DropdownButton
              id="other-actions"
              noCaret
              pullRight
              dropup
              title={<i className="fa fa-ellipsis-v" aria-hidden="true" />}
            >
              {hiddenActions.map((action, index) => (
                <MenuItem
                  onClick={action.onClick}
                  disabled={action.disabled}
                  key={index}
                >
                  <i className={`fa fa-${action.icon}`} /> {action.label}
                </MenuItem>
              ))}
            </DropdownButton>
          )}
        </div>
      </div>
    );
  }
}

export default ActionBar;
