/**
 * get path level of path (1.2.3 -> 3)
 * @param path
 * @returns int
 */
export default path => {
  if (path === '0') {
    return 0;
  }

  const pathLevel = path.match(/\./g);

  if (!pathLevel) {
    return 1;
  }

  return pathLevel.length + 1;
};
