import {
  addPickedObject,
  removePickedObject,
  resetPickedObject,
} from '../../dataroom/utils/pickObject';

const PICK_NODE = 'EXPLODE_PICK_NODE';
const ADD_NODE_TO_PICKED = 'EXPLODE_ADD_NODE_TO_PICKED';
const REMOVE_NODE_TO_PICKED = 'EXPLODE_REMOVE_NODE_TO_PICKED';
const RESET_PICK_NODE = 'EXPLODE_RESET_PICK_NODE';

export const pickNodeAction = id => ({
  type: PICK_NODE,
  nodeId: id,
});

export const addNodeToPickedAction = id => ({
  type: ADD_NODE_TO_PICKED,
  nodeId: id,
});

export const removeNodeToPickedAction = id => ({
  type: REMOVE_NODE_TO_PICKED,
  nodeId: id,
});

export const resetPickNodeAction = () => ({
  type: RESET_PICK_NODE,
});

export default function reducer(state, action) {
  switch (action.type) {
    case RESET_PICK_NODE:
    case PICK_NODE: {
      if (!state.loaded) {
        return {
          ...state,
        };
      }

      let newState = {
        ...state,
        newNodes: [...state.newNodes],
      };

      newState = resetPickedObject(
        newState,
        'newNodes',
        'pickedNodeIds',
        'nodeId'
      );

      if (action.nodeType === RESET_PICK_NODE) {
        return newState;
      }

      return addPickedObject(
        newState,
        action,
        'newNodes',
        'nodeId',
        'pickedNodeIds'
      );
    }
    case ADD_NODE_TO_PICKED: {
      return addPickedObject(
        state,
        action,
        'newNodes',
        'nodeId',
        'pickedNodeIds'
      );
    }
    case REMOVE_NODE_TO_PICKED: {
      return {
        ...state,
        pickedNodeIds: state.pickedNodeIds.filter(
          nodeId => nodeId !== action.nodeId
        ),
        newNodes: removePickedObject(state, action, 'newNodes', 'nodeId'),
      };
    }
  }

  return state;
}
