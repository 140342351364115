import { TYPE_DOCUMENT, TYPE_FOLDER } from '../../documents/type';

export default node => {
  let className = 'i';

  if (node.type === TYPE_DOCUMENT) {
    className += ' d';
  } else {
    className += ' f';
  }

  if (node.file && !node.validationErrors) {
    className += ' p';
  }

  if (node.hidden) {
    className += ' hidden';
  }

  if (node.type === TYPE_FOLDER && !node.folded) {
    className += ' open';
  }

  if (!node.validationErrors && node.validationWarnings) {
    className += ' w';
  }

  if (node.validationErrors) {
    className += ' e';
  }

  if (node.justAdded) {
    className += ' justAdded';
  }

  return className;
};
