import { takeEvery, call, put } from 'redux-saga/effects';
import uuidv4 from 'uuid/v4';
import { TYPE_DOCUMENT, TYPE_FOLDER } from '../documents/type';
import { addFilesAction } from './addFiles';
import { confirmImportFilesAction, getImportType } from './importFiles';
import { IMPORT_TYPE_CLASSIC } from '../constants';

export const CONFIRM_EXTRACT_ZIP = '@extract-zip-files/confirm_extract_zip';
const CANCEL_EXTRACT_ZIP = '@extract-zip-files/cancel_extract_zip';
const EXTRACT_ZIP = '@extract-zip-files/extract_zip';
export const EXTRACT_ZIP_ENDED = '@extract-zip-files/extract_zip_ended';

export const confirmExtractZipAction = (
  zipFile,
  destinationNode,
  position
) => ({
  type: CONFIRM_EXTRACT_ZIP,
  zipFile,
  destinationNode,
  position,
});

export const cancelExtractZipAction = () => ({
  type: CANCEL_EXTRACT_ZIP,
});

export const extractZipAction = (zipFile, destinationNode, position) => ({
  type: EXTRACT_ZIP,
  zipFile,
  destinationNode,
  position,
});

const getFileContent = file => {
  return new Promise((success, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      success(reader.result);
    };

    reader.readAsArrayBuffer(file);
  });
};

const loadZip = (fileContent, JSZip, iconv) => {
  const zip = new JSZip();

  return zip.loadAsync(fileContent, {
    createFolders: true,
    decodeFileName: function(bytes) {
      return iconv.decode(bytes, 'cp863');
    },
  });
};

const getBlobForZipFile = zipFile => {
  return zipFile.async('blob');
};

export const insertNodeAtPath = (node, splittedPath, indexedNodes) => {
  let current = indexedNodes;

  for (let splittedPathItem of splittedPath) {
    if (typeof current[splittedPathItem] === 'undefined') {
      current[splittedPathItem] = {
        type: TYPE_FOLDER,
        name: splittedPathItem,
        children: {},
      };
    }
    current = current[splittedPathItem].children;
  }

  current[node.name] = node;
};

export const generateNodeFromIndexedNodes = indexedNodes => {
  const nodes = [];

  const indexedNodesEntries = Object.entries(indexedNodes);

  for (let indexedNodeEntry of indexedNodesEntries) {
    const node = {
      id: uuidv4(),
      type: indexedNodeEntry[1]['type'],
      name: indexedNodeEntry[1]['name'],
    };

    if (indexedNodeEntry[1].archivePath) {
      node.archivePath = indexedNodeEntry[1].archivePath;
    }

    if (node.type === TYPE_DOCUMENT && indexedNodeEntry[1].file) {
      node.file = indexedNodeEntry[1].file;
    } else if (node.type === TYPE_FOLDER) {
      node.children = generateNodeFromIndexedNodes(
        indexedNodeEntry[1].children
      );
    }

    nodes.push(node);
  }

  return nodes;
};

const extractZipEndedAction = () => ({
  type: EXTRACT_ZIP_ENDED,
});

const getJSZip = () => {
  return import(/* webpackChunkName: "jszip" */ 'jszip/dist/jszip').then(
    imported => {
      return imported.default;
    }
  );
};

const getIconvLite = () => {
  return import(/* webpackChunkName: "iconv-lite" */ 'iconv-lite').then(
    imported => {
      return imported.default;
    }
  );
};

function* extractZipSaga(action) {
  const fileContent = yield call(getFileContent, action.zipFile.file);

  const JSZip = yield call(getJSZip);
  const iconv = yield call(getIconvLite);

  const extractedZip = yield call(loadZip, fileContent, JSZip, iconv);
  const extractedZipEntries = Object.entries(extractedZip.files);

  const indexedNodes = {};

  for (let extractedZipEntry of extractedZipEntries) {
    const splittedPath = extractedZipEntry[1].name.split('/');
    // remove last "/" for folder and remove name for files
    if (extractedZipEntry[1].dir) {
      splittedPath.splice(-1, 1);
    }

    const node = {
      type: extractedZipEntry[1].dir ? TYPE_FOLDER : TYPE_DOCUMENT,
      name: splittedPath[splittedPath.length - 1],
    };

    if (!extractedZipEntry[1].dir) {
      node.file = yield call(getBlobForZipFile, extractedZipEntry[1]);
      node.file.name = splittedPath[splittedPath.length - 1];
    } else {
      node.children = {};
    }

    splittedPath.splice(-1, 1);

    insertNodeAtPath(node, splittedPath, indexedNodes);
  }

  const nodeList = generateNodeFromIndexedNodes(indexedNodes);

  yield put(extractZipEndedAction());

  const importType = getImportType(nodeList);

  if (importType === IMPORT_TYPE_CLASSIC) {
    yield put(
      addFilesAction(nodeList, action.destinationNode, action.position)
    );
    return;
  }

  yield put(
    confirmImportFilesAction(
      nodeList,
      action.destinationNode,
      action.position,
      importType
    )
  );
}

export function* saga() {
  yield takeEvery(EXTRACT_ZIP, extractZipSaga);
}

export default function(state, action) {
  switch (action.type) {
    case CONFIRM_EXTRACT_ZIP:
      return {
        ...state,
        working: {
          ...state.working,
          confirmExtractZipModal: {
            ...state.working.confirmExtractZipModal,
            open: true,
            zipFile: action.zipFile,
            position: action.position,
            destinationNode: action.destinationNode,
          },
        },
      };

    case EXTRACT_ZIP: {
      return {
        ...state,
        working: {
          ...state.working,
          confirmExtractZipModal: {
            ...state.working.confirmExtractZipModal,
            extracting: true,
          },
        },
      };
    }

    case EXTRACT_ZIP_ENDED:
    case CANCEL_EXTRACT_ZIP: {
      return {
        ...state,
        working: {
          ...state.working,
          confirmExtractZipModal: {
            open: false,
            zipFile: null,
            position: null,
            destinationNode: null,
            extracting: false,
          },
        },
      };
    }
  }

  return state;
}
