import React from 'react';
import Svg from './Svg';

export default function IconDataroom(props) {
  return (
    <Svg
      {...props}
      d="M16 208V63.02h8.527V16H231.48v32H240v159.853H41.442L16 208zM28.211 75.231V195.6l199.578.041V60.211h-52.531l-22.371 15.02H28.211zM219.269 48V28.212H36.739V63.02h112.429L171.539 48h47.73z"
    />
  );
}
