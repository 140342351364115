import descendantNodes from './descendantNodes';
import childNode from './childNode';

/**
 * map all descendant of given nodeId
 * 1
 * 2
 *   2.1
 *   2.2
 *     2.2.1
 * if nodeId of 2.1 is given, function will map on 2.2 and 2.2.1
 *
 * @param nodes
 * @param startId
 * @param modifier
 * @param includeStart
 * @returns {*}
 */
export default function mapDescendant(
  nodes,
  startId,
  modifier,
  includeStart = false
) {
  const iterator = descendantNodes(nodes, startId, includeStart);

  let newNodes = {};
  for (const node of iterator) {
    newNodes[node.id] = modifier(nodes[node.id]);
  }

  return {
    ...nodes,
    ...newNodes,
  };
}
