import { createSelector } from 'reselect';

export default createSelector(
  [
    state => state.dataroom.selectedNodeIdsInContextMenu,
    state => state.dataroom.working.nodes,
  ],
  (selectedNodeIdsInContextMenu, nodes) => {
    return selectedNodeIdsInContextMenu.map(
      selectedNodeIdInContextMenu => nodes[selectedNodeIdInContextMenu]
    );
  }
);
