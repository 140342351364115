import { uniqueId } from 'underscore';
import { takeEvery, call, select } from 'redux-saga/effects';
import createUndoableActionReducer from '../reducers/createUndoableActionReducer';
import { removeAndSaveNodes, undoRemoveNode } from './utils/removeNodes';
import { TYPE_DOCUMENT } from '../documents/type';
import undoSaga from './dataroomActions/undoSaga';
import apiClient from '../../core/apiClient';
export const REMOVE_EMPTY_DOCUMENT_NODES = 'REMOVE_EMPTY_DOCUMENT_NODES';

export const removeEmptyDocumentNodesAction = parentNodeId => {
  return {
    type: REMOVE_EMPTY_DOCUMENT_NODES,
    parentNodeId: parentNodeId,
    frontActionId: uniqueId('dataroom_action'),
  };
};

export const getEmptyDocumentsChildNodes = (childNodeIds, nodes) => {
  let emptyChildNodes = [];
  childNodeIds.forEach(nodeId => {
    const node = nodes[nodeId];
    if (node.type === TYPE_DOCUMENT) {
      if (!node.file) {
        emptyChildNodes.push(node.id);
      }
      return;
    }
    emptyChildNodes = emptyChildNodes.concat(
      getEmptyDocumentsChildNodes(node.childIds, nodes)
    );
  });
  return emptyChildNodes;
};

const removeEmptyDocumentNodeApi = (action, { dataroomId }) => {
  return apiClient.request(
    new Request(`/api/datarooms/${dataroomId}/nodes/empty_document_child`, {
      method: 'DELETE',
      body: JSON.stringify({
        parentNodeId: action.parentNodeId,
      }),
    })
  );
};

export function* saga(action) {
  const selectedState = yield select(state => ({
    dataroomId: state.dataroom.dataroom.id,
  }));

  return yield call(removeEmptyDocumentNodeApi, action, selectedState);
}

export function* undoActionSaga() {
  yield takeEvery('UNDO_REMOVE_EMPTY_DOCUMENT_NODES', undoSaga);
}

function removeEmptyDocumentNodes(state, action) {
  let newState = {
    ...state,
    working: {
      ...state.working,
      savedNodes: {
        ...state.working.savedNodes,
        [action.frontActionId]: {},
      },
    },
  };

  const nodeIds = getEmptyDocumentsChildNodes(
    state.working.nodes[action.parentNodeId].childIds,
    state.working.nodes
  );
  newState = removeAndSaveNodes(newState, nodeIds, action.frontActionId, false);
  newState = {
    ...newState,
    working: {
      ...newState.working,
      savedNodes: {
        ...newState.working.savedNodes,
        [action.frontActionId]: {
          ...newState.working.savedNodes[action.frontActionId],
          nodeIds: nodeIds,
        },
      },
    },
  };
  return newState;
}

function undoRemoveEmptyDocumentNodes(state, action) {
  let newState = { ...state };
  [...state.working.savedNodes[action.frontActionId].nodeIds]
    .reverse()
    .forEach(nodeId => {
      newState = undoRemoveNode(newState, nodeId, action.frontActionId, false);
    });

  return newState;
}

export default createUndoableActionReducer(
  REMOVE_EMPTY_DOCUMENT_NODES,
  removeEmptyDocumentNodes,
  undoRemoveEmptyDocumentNodes
);
