import React, { Fragment } from 'react';
import theme_blue, {
  BODY_FONT,
  CLIENT_UI_COLOR,
  DARK_GREY_COLOR,
} from '../../presentational/components/theme_blue';
import Helmet from 'react-helmet';
import Layout from '../../app/layout/Layout';
import 'translations/user/fr';
import 'translations/user/en';
import { Menu } from '../../app/components/menu/Menu';
import Content from '../../app/layout/Content';
import Div from '../../presentational/components/Div';
import H2 from '../../presentational/components/H2';
import Span from '../../presentational/components/Span';
import { useAuthDataContext } from '../../app/hooks/authDataProvider';
import IconGroup from '../../presentational/components/img/icons/IconGroup';
import Input from '../../presentational/components/form/Input';

export default function MyProfile(props) {
  const { user, onLogout } = useAuthDataContext();

  return (
    <Fragment>
      <Helmet>
        <title>Espace client - Mon profil</title>
      </Helmet>

      <Layout>
        <Menu me />

        <Content>
          <H2>
            <Span
              color={CLIENT_UI_COLOR}
              fontFamily={BODY_FONT}
              display="flex"
              alignItems="center"
              justifyContent="center"
              minWidth="100%"
            >
              <IconGroup marginRight=".5em" />
              Mon profil
            </Span>
          </H2>
          <Div maxWidth="50rem" margin="0 auto" fontWeight="300">
            <Input
              displayBlock
              isHorizontal
              label="Nom"
              labelColor={DARK_GREY_COLOR}
              isLabelBold
              value={user.lastName}
              isDisabled={true}
            />
            <Input
              displayBlock
              isHorizontal
              label="Prénom"
              labelColor={DARK_GREY_COLOR}
              isLabelBold
              value={user.firstName}
              isDisabled={true}
            />
            <Input
              displayBlock
              isHorizontal
              label="Email"
              labelColor={DARK_GREY_COLOR}
              isLabelBold
              value={user.email}
              isDisabled={true}
            />
            <Input
              displayBlock
              isHorizontal
              label="Rôle"
              labelColor={DARK_GREY_COLOR}
              isLabelBold
              value={Translator.trans(user.roles[0], {}, 'user')}
              isDisabled={true}
            />
          </Div>
        </Content>
      </Layout>
    </Fragment>
  );
}
