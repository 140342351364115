import React from 'react';
import Svg from './Svg';

export default function IconClose(props) {
  return (
    <Svg
      {...props}
      d="M56.242,56.242c39.605,-39.604 103.911,-39.604 143.516,0c39.604,39.605 39.604,103.911 -0,143.516c-39.605,39.604 -103.911,39.604 -143.516,-0c-39.604,-39.605 -39.604,-103.911 0,-143.516Zm8.969,8.969c-34.653,34.655 -34.653,90.923 0,125.578c34.655,34.653 90.923,34.653 125.578,-0c34.653,-34.655 34.653,-90.923 -0,-125.578c-34.655,-34.653 -90.923,-34.653 -125.578,0Zm62.789,53.82l44.849,-44.849l8.969,8.969l-44.849,44.849l44.849,44.849l-8.969,8.969l-44.849,-44.849l-44.849,44.849l-8.969,-8.969l44.849,-44.849l-44.849,-44.849l8.969,-8.969l44.849,44.849Z"
    />
  );
}
