import React, { Component } from 'react';
import { AFTER, BEFORE, INSIDE } from '../ducks/utils/getNextPath';
import { TYPE_DOCUMENT, TYPE_FOLDER } from '../documents/type';
import NodeNameButtons, {
  addPlaceholder,
  upperCaseSelection,
} from './NodeNameButtons';

const positionTypeText = positionType => {
  switch (positionType) {
    case BEFORE:
      return 'avant';
    case INSIDE:
      return "à l'intérieur de";
    case AFTER:
      return 'après';
  }
};

const titlePlaceholder = (positionType, path, nodeName) => {
  return (
    <p>
      Ajouter une ou plusieurs lignes{' '}
      <b>{positionTypeText(positionType).toUpperCase()}</b> <u>{path}</u>{' '}
      <i>{nodeName}</i>
    </p>
  );
};

class AddNodes extends Component {
  constructor(props) {
    super(props);

    this.changeNodesText = this.changeNodesText.bind(this);
    this.changeNodeType = this.changeNodeType.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleAddPlaceholder = this.handleAddPlaceholder.bind(this);
    this.handleUpperCaseKeyboardShortcut = this.handleUpperCaseKeyboardShortcut.bind(
      this
    );
    this.upperCase = this.upperCase.bind(this);
    this.state = {
      nodes: '',
      nodeType: TYPE_DOCUMENT,
      nodesValid: false,
      submitting: false,
    };
    this.nodesAdded = false;
    this.textAreaRef = React.createRef();
  }

  handleSubmit(evt) {
    evt.preventDefault();

    this.setState({
      submitting: true,
    });
    this.nodesAdded = true;
    this.props.addNodesAction(
      this.state.nodes
        .trim()
        .split('\n')
        .filter(item => item.length > 0),
      this.state.nodeType,
      this.props.position,
      this.props.node
    );
  }

  handleCancel(evt) {
    evt.preventDefault();

    this.props.cancelAddNodesAction();
  }

  handleAddPlaceholder(evt) {
    evt.preventDefault();
    const result = addPlaceholder(this.state.nodes, this.textAreaRef);

    this.setState(
      {
        nodes: result.name,
      },
      result.afterUpdate
    );
  }

  upperCase(evt) {
    if (evt) {
      evt.preventDefault();
    }

    if (document.activeElement !== this.textAreaRef.current) {
      return;
    }

    const result = upperCaseSelection(this.state.nodes, this.textAreaRef);
    if (!result) {
      return;
    }

    this.setState(
      {
        nodes: result.name,
      },
      result.afterUpdate
    );
  }

  changeNodesText(evt) {
    evt.preventDefault();

    const values = evt.target.value.trim().split('\n');

    for (let value of values) {
      if (value.length > 512) {
        return;
      }
    }

    this.setState({
      nodes: evt.target.value,
      nodesValid: evt.target.value.trim().length > 0,
    });
  }

  changeNodeType(evt) {
    this.setState({
      nodeType: evt.target.value,
    });
  }

  handleUpperCaseKeyboardShortcut(evt) {
    if (evt.key === 'F3' && evt.shiftKey) {
      evt.preventDefault();
      this.upperCase();
    }
  }

  componentDidMount() {
    const textAreaElement = this.textAreaRef.current;
    textAreaElement.focus();
    // textAreaElement.scrollIntoView();
    document.addEventListener('keydown', this.handleUpperCaseKeyboardShortcut);
  }

  componentWillUnmount() {
    document.removeEventListener(
      'keydown',
      this.handleUpperCaseKeyboardShortcut
    );

    if (!this.nodesAdded) {
      this.props.cancelAddNodesAction();
    }
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <span
          style={{ backgroundColor: '#f3a174' }}
          className="rename-add-node-area add"
        >
          {titlePlaceholder(
            this.props.position,
            this.props.node.path,
            this.props.node.name
          )}
          <span className="input-group input-group-sm">
            <textarea
              className="form-control"
              value={this.state.nodes}
              onChange={this.changeNodesText}
              placeholder="Un par ligne"
              ref={this.textAreaRef}
            />
            <span className="spacer"> </span>
            <NodeNameButtons
              formValid={this.state.nodesValid}
              submitting={this.state.submitting}
              upperCase={this.upperCase}
              handleAddPlaceholder={this.handleAddPlaceholder}
              handleCancel={this.handleCancel}
            />
          </span>
          <span className="input-group">
            <label htmlFor="node-type---file" className="radio-inline">
              <input
                type="radio"
                name="node-type"
                value={TYPE_DOCUMENT}
                id="node-type---file"
                onChange={this.changeNodeType}
                checked={this.state.nodeType === TYPE_DOCUMENT}
              />
              <i className="fa fa-file" /> Fichier{' '}
            </label>
            <label htmlFor="node-type---folder" className="radio-inline">
              <input
                type="radio"
                name="node-type"
                value={TYPE_FOLDER}
                onChange={this.changeNodeType}
                id="node-type---folder"
                checked={this.state.nodeType === TYPE_FOLDER}
              />
              <i className="fa fa-folder" /> Dossier{' '}
            </label>
          </span>
        </span>
      </form>
    );
  }
}

export default AddNodes;
