import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import 'translations/edm-dataroom/fr';
import 'translations/edm-dataroom/en';
import { ContextMenuTrigger } from 'react-contextmenu';
import { makeShortenedNodeSelector } from '../selectors/shortenedNodeNameSelector';
import RenameNode from '../containers/RenameNode';
import AddNodes from '../containers/AddNodes';
import StickyNode from './StickyNode';
import ValidationErrors from './ValidationErrors';
import { getUploadingPercentSelector } from '../selectors/calculateUploadingPercentSelector';
import { AFTER, BEFORE } from '../ducks/utils/getNextPath';

const getIconForExtension = extension => {
  if ('pdf' === extension) {
    return 'fa fa-file-pdf-o';
  }

  if ('mp4' === extension) {
    return 'fa fa-file-video-o';
  }

  if (['png', 'jpeg', 'tiff', 'jpg', 'tif'].indexOf(extension) !== -1) {
    return 'fa fa-file-image-o';
  }

  if (['doc', 'docx'].indexOf(extension) !== -1) {
    return 'fa fa-file-word-o';
  }

  if (['xls', 'xlsx', 'csv', 'xlsm'].indexOf(extension) !== -1) {
    return 'fa fa-file-excel-o';
  }

  if (['ppt', 'pptx'].indexOf(extension) !== -1) {
    return 'fa fa-file-powerpoint-o';
  }

  if (['dwg', 'dwf', 'dwfx'].indexOf(extension) !== -1) {
    return 'fa fa-picture-o';
  }

  if ('msg' === extension) {
    return 'fa fa-envelope';
  }

  if (['rar', 'zip'].indexOf(extension) !== -1) {
    return 'fa fa-file-archive-o';
  }

  return 'fa fa-question';
};

class DocumentNode extends Component {
  constructor(props) {
    super(props);
    this.shouldComponentUpdate = this.shouldComponentUpdate.bind(this);
    this.handleGoToDocumentAction = this.handleGoToDocumentAction.bind(this);
    this.renderDocumentLink = this.renderDocumentLink.bind(this);
    this.shortenedNode = makeShortenedNodeSelector();
    this.calculateProgress = getUploadingPercentSelector();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextProps !== this.props || nextState !== this.state;
  }

  render() {
    const document = this.props.document;

    let documentName = this.shortenedNode(document);

    if (document.matched) {
      documentName = <em>{documentName}</em>;
    }

    documentName = (
      <span
        className="node-name"
        style={
          document.menuOpen
            ? { backgroundColor: 'rgba(46, 117, 191, 0.3)' }
            : {}
        }
        title={document.name}
      >
        {documentName}
      </span>
    );

    if (document.rename) {
      documentName = (
        <RenameNode
          path={document.path}
          nodeId={document.id}
          name={document.name}
        />
      );
    }

    let component = (
      <ContextMenuTrigger
        holdToDisplay={-1}
        collect={() => ({ node: document })}
        id="NODE_MENU"
        renderTag="div"
        attributes={{
          style: {
            paddingBottom: '3px',
            paddingTop: '3px',
          },
        }}
      >
        {document.addNodes === BEFORE && (
          <StickyNode>
            <AddNodes />
          </StickyNode>
        )}
        {this.props.checkBoxElement}
        <button className="o">
          <i />
        </button>
        {this.renderDocumentLink()}
        <u>{document.path}</u> {documentName}
        &nbsp;
        {(document.validationErrors || document.validationWarnings) && (
          <Fragment>
            <ValidationErrors
              tooltipId={document.id}
              validationErrors={document.validationErrors}
              validationWarnings={document.validationWarnings}
            />{' '}
          </Fragment>
        )}
        {document.file && (
          <Fragment>
            <i className="na">
              <i className={getIconForExtension(document.file.extension)} />{' '}
              {document.file.extension}
            </i>{' '}
          </Fragment>
        )}
        {document.addNodes === AFTER && (
          <StickyNode>
            <AddNodes />
          </StickyNode>
        )}
      </ContextMenuTrigger>
    );

    if (document.rename) {
      component = <StickyNode>{component}</StickyNode>;
    }

    return component;
  }

  handleGoToDocumentAction(evt) {
    evt.preventDefault();
    const { document } = this.props;
    if (!document.file) {
      return;
    }
    this.props.goToDocumentAction(document);
  }

  renderDocumentLink() {
    const { document, disableDetail } = this.props;

    if (disableDetail) {
      return null;
    }

    let linkClass = 'df';
    if (!document.file) {
      linkClass += ' disabled';
    }

    let uploadingProgressValue = null;
    if (document.uploading) {
      if (document.uploadingProgress.uploadedSize === 0) {
        linkClass += ' loading';
      } else {
        uploadingProgressValue = this.calculateProgress(document);
      }
    }

    return (
      <a
        title={uploadingProgressValue && uploadingProgressValue.percent + '%'}
        href="#"
        onClick={
          document.uploading
            ? evt => evt.preventDefault()
            : this.handleGoToDocumentAction
        }
        className={linkClass}
      >
        <i>
          {uploadingProgressValue && (
            <svg viewBox="0 0 30 28" className="load-progress">
              <rect
                x="0"
                y="0"
                width="30"
                height={uploadingProgressValue.height}
              />
            </svg>
          )}
        </i>
      </a>
    );
  }
}

DocumentNode.propTypes = {
  document: PropTypes.object.isRequired,
  goToDocumentAction: PropTypes.func.isRequired,
};

export default DocumentNode;
