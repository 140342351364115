import React from 'react';
import styled from 'styled-components';
import { DANGER_COLOR, DARK_COLOR } from '../theme_blue';

const Label = styled.label`
  display: block;
  line-height: normal;
  font-weight: ${props => (props.isLabelBold ? 'bold' : 'inherit')};
  padding-bottom: ${props =>
    props.labelPaddingBottom ? props.labelPaddingBottom : 0};
  ${props =>
    props.isLabelHidden
      ? 'position: absolute; width: 1px; height: 1px; padding: 0 !important; overflow: hidden; clip: rect(0, 0, 0, 0); white-space: nowrap; border: 0'
      : null};
  ${props => {
    if (props.isInline) {
      return 'flex-shrink: auto; flex-wrap: wrap; padding-right: .75rem; margin-top: .5em;';
    }
  }}
  font-family: ${props => props.theme.bodyFont};
  @media (min-width: ${props => props.theme.tablet}) {
    ${props => {
      if (!props.isInline && props.isHorizontal) {
        return 'flex-grow: 0; flex-shrink: 0; padding-right: .75rem; margin-top: .5em;';
      }
    }}
    ${props => {
      if (props.isHorizontal && props.labelTextRight) {
        return 'text-align: right;';
      }
    }}
    ${props =>
      props.labelWidth && props.isHorizontal
        ? 'flex-basis: ' + props.labelWidth + ';'
        : 'flex-basis: 40%;'}
  }
  font-size: ${props => (props.fontSize ? props.fontSize : '1em')};
  color: ${props => {
    if (props.hasError) {
      return props.theme.dangerColor;
    }
    if (props.labelColor) {
      return props.theme[props.labelColor];
    }
    return props.theme.darkColor;
  }};
`;
export const Asterisk = styled.span`
  display: inline-block;
  margin-left: 0.5em;
  color: ${props => props.theme.dangerColor};
  font-weight: bold;
`;
class LabelComponent extends React.Component {
  render() {
    return (
      <Label
        htmlFor={this.props.field_id}
        hasError={this.props.hasError}
        isHorizontal={this.props.isHorizontal}
        isInline={this.props.isInline}
        labelTextRight={this.props.labelTextRight}
        isLabelBold={this.props.isLabelBold}
        isLabelHidden={this.props.isLabelHidden}
        labelPaddingBottom0={this.props.labelPaddingBottom0}
        {...this.props.labelProps}
        labelWidth={this.props.labelWidth}
        labelColor={this.props.labelColor}
        fontSize={this.props.fontSize}
        lineHeight={this.props.lineHeight}
        paddingLeft={this.props.paddingLeft}
        textAlign={this.props.textAlign}
      >
        {!this.props.isForInputFile && this.props.label}
        {this.props.isRequired && <Asterisk>*</Asterisk>}
        {this.props.isForInputFile && this.props.children}
        {this.props.isForRecommend && this.props.children}
      </Label>
    );
  }
}

export default LabelComponent;
