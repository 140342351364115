import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import {
  hideModalAction,
  attachFileToDocumentAction,
} from '../ducks/attachFileToDocument';
import { bindActionCreators } from 'redux';
import FileInput from '../../app/components/FileInput';
import allowedFileExtension, {
  isFileAllowed,
} from '../documents/allowedFileExtension';

class AttachFileModal extends Component {
  constructor(props) {
    super(props);
    this.attachFile = this.attachFile.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.state = {
      error: null,
    };
  }

  hideModal() {
    this.props.hideModalAction();
    this.setState({
      error: null,
    });
  }

  attachFile(file) {
    if (!isFileAllowed(file.name)) {
      this.setState({
        error: `Ce type de fichier n'est pas autorisé. Les types de fichiers autorisés sont: ${allowedFileExtension.join(
          ', '
        )}`,
      });
      return;
    }

    this.props.attachFileToDocumentAction(this.props.nodeId, file);
    this.setState({
      error: null,
    });
  }

  render() {
    const { open } = this.props;
    const { error } = this.state;

    return (
      <Modal show={open} onHide={this.hideModal}>
        <Modal.Body className="form">
          <div className="form-group">
            <FileInput
              allowedExtensions={allowedFileExtension}
              error={error}
              onChange={this.attachFile}
            />
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return state.dataroom.working.attachFileModal;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      hideModalAction,
      attachFileToDocumentAction,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AttachFileModal);
