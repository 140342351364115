import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import dataroom from '../../dataroom/reducers/dataroom.js';
import document from '../../document/reducers/document.js';
import pdfEditor from '../../pdfEditor/reducers/pdfEditor';
import notifications from './notifications';
import pdfMerged from '../../pdfMerged/reducers/pdfMerged';
import dataroomActions from '../../dataroom/ducks/dataroomActions';
import pdfExplode from '../../pdfExplode/reducers/pdfExplode';
import actions from './actions.js';
import { reducer as formReducer } from 'redux-form';

export default combineReducers({
  routing: routerReducer,
  dataroom,
  document,
  dataroomActions,
  notifications,
  pdfMerged,
  pdfEditor,
  pdfExplode,
  actions,
  form: formReducer,
});
