import { createStore, applyMiddleware } from 'redux';
import logger from 'redux-logger';
import createRavenMiddleware from 'raven-for-redux';
import createSagaMiddleware from 'redux-saga';
import {
  call,
  takeLatest,
  all,
  put,
  select,
  fork,
  take,
  cancel,
  cancelled,
} from 'redux-saga/effects';
import rootReducer from './reducers';
import Raven from 'raven-js';
import { pick, isObject } from 'underscore';
import { saga as documentSaga } from '../document/actions/document';
import { saga as lockSaga } from '../dataroom/ducks/lock';
import { saga as validateSaga } from '../dataroom/ducks/validate';
import { saga as exportDataroomSaga } from '../dataroom/ducks/exportDataroom';
import { saga as addTemplateSaga } from '../dataroom/ducks/addTemplate';
import { saga as notificationSaga } from './actions/notifications';
import {
  saga as mergeDocumentSaga,
  undoActionSaga as undoMergeDocumentSaga,
  PDF_MERGE,
} from '../dataroom/ducks/pdfMerged';
import { saga as importFilesSaga } from '../dataroom/ducks/importFiles';
import {
  saga as explodePdfSaga,
  undoActionSaga as undoExplodePdfSaga,
  PDF_EXPLODE,
} from '../dataroom/ducks/pdfExplode';
import { saga as extractZipFileSaga } from '../dataroom/ducks/extractZipFile';
import { saga as dataroomActionsSaga } from '../dataroom/ducks/dataroomActions';
import { saga as slowActionsSaga } from '../dataroom/ducks/slowActions';
import {
  saga as editPdfSaga,
  undoActionSaga as undoEditPdfSaga,
  PDF_EDIT,
} from '../dataroom/ducks/pdfEditor';
import {
  saga as transformArchiveNodeToFolderSaga,
  undoActionSaga as undoTransformArchiveNodeToFolderSaga,
  prepareTransformArchiveNodeToFolderSaga,
  TRANSFORM_ARCHIVE_NODE_TO_FOLDER,
} from '../dataroom/ducks/transformArchiveNodeToFolder';
import { watchOnNodeClipBoard } from '../dataroom/ducks/nodesClipBoard';
import {
  saga as removeEmptyFolderNodesSaga,
  undoActionSaga as undoRemoveEmptyFolderNodes,
  REMOVE_EMPTY_FOLDER_NODES,
} from '../dataroom/ducks/removeEmptyFolderNodes';
import {
  saga as copyNodeSaga,
  undoActionSaga as undoCopyNodeSaga,
  pasteNodeSaga as pasteCopyNodeSaga,
  COPY_NODES,
} from '../dataroom/ducks/copyNodes';
import {
  saga as addNodesSaga,
  undoActionSaga as undoAddNodesSaga,
  removeJustAddedAttributeSaga,
  ADD_NODES,
} from '../dataroom/ducks/addNodes';
import {
  saga as removeEmptyDocumentNodesSaga,
  undoActionSaga as undoRemoveEmptyDocumentNodes,
  REMOVE_EMPTY_DOCUMENT_NODES,
} from '../dataroom/ducks/removeDocumentNodeWithoutFile';
import {
  saga as attachFileToDocumentSaga,
  undoActionSaga as undoAttachFileToDocumentSaga,
  ATTACH_FILE_TO_DOCUMENT,
} from '../dataroom/ducks/attachFileToDocument';
import {
  saga as addFilesSaga,
  undoActionSaga as undoAddFilesSaga,
  uploadActionSaga,
  ADD_FILES,
} from '../dataroom/ducks/addFiles';
import {
  saga as moveNodeSaga,
  undoActionSaga as undoMoveNodeSaga,
  pasteNodeSaga as pasteMoveNodeSaga,
  MOVE_NODES,
} from '../dataroom/ducks/moveNodes';
import {
  saga as renameNodeSaga,
  undoActionSaga as undoRenameNodeSagaSaga,
  RENAME_NODE,
} from '../dataroom/ducks/renameNode';
import {
  saga as changeNodeTypeSaga,
  undoActionSaga as undoChangeNodeTypeSaga,
  CHANGE_NODES_TYPE,
} from '../dataroom/ducks/changeNodeType';
import {
  saga as removeNodeSaga,
  undoActionSaga as undoRemoveNodeSaga,
  REMOVE_NODES,
} from '../dataroom/ducks/removeNodes';
import {
  saga as detachFileToDocumentSaga,
  undoActionSaga as undoDetachFileToDocumentSaga,
  DETACH_FILE_TO_DOCUMENT,
} from '../dataroom/ducks/detachFileToDocument';

import thunk from 'redux-thunk';
import {
  RESET_DATAROOM,
  rootSaga,
  START_DATAROOM,
} from '../dataroom/actions/dataroom';
import { takeActionsSaga } from '../dataroom/ducks/dataroomActions/doSaga';

const sagaMiddleware = createSagaMiddleware({
  onError: error => {
    Raven.captureException(error);
  },
});

function* dataroomSaga() {
  let tasks = [];
  try {
    tasks.push(yield fork(rootSaga));
    tasks.push(yield fork(documentSaga));
    tasks.push(yield fork(dataroomActionsSaga));
    tasks.push(yield fork(uploadActionSaga));
    tasks.push(yield fork(slowActionsSaga));
    tasks.push(yield fork(undoAddFilesSaga));
    tasks.push(yield fork(undoAddNodesSaga));
    tasks.push(yield fork(lockSaga));
    tasks.push(yield fork(addTemplateSaga));
    tasks.push(yield fork(undoMoveNodeSaga));
    tasks.push(yield fork(undoRenameNodeSagaSaga));
    tasks.push(yield fork(undoRemoveNodeSaga));
    tasks.push(yield fork(undoCopyNodeSaga));
    tasks.push(yield fork(pasteCopyNodeSaga));
    tasks.push(yield fork(undoChangeNodeTypeSaga));
    tasks.push(yield fork(undoDetachFileToDocumentSaga));
    tasks.push(yield fork(removeJustAddedAttributeSaga));
    tasks.push(yield fork(prepareTransformArchiveNodeToFolderSaga));
    tasks.push(yield fork(undoTransformArchiveNodeToFolderSaga));
    tasks.push(yield fork(exportDataroomSaga));
    tasks.push(yield fork(pasteMoveNodeSaga));
    tasks.push(yield fork(watchOnNodeClipBoard));
    tasks.push(yield fork(undoRemoveEmptyFolderNodes));
    tasks.push(yield fork(validateSaga));
    tasks.push(yield fork(undoMergeDocumentSaga));
    tasks.push(yield fork(undoExplodePdfSaga));
    tasks.push(yield fork(undoEditPdfSaga));
    tasks.push(yield fork(extractZipFileSaga));
    tasks.push(yield fork(importFilesSaga));
    tasks.push(yield fork(undoAttachFileToDocumentSaga));
    tasks.push(yield fork(undoRemoveEmptyDocumentNodes));
    tasks.push(
      yield fork(takeActionsSaga, {
        [CHANGE_NODES_TYPE]: changeNodeTypeSaga,
        [RENAME_NODE]: renameNodeSaga,
        [ADD_NODES]: addNodesSaga,
        [COPY_NODES]: copyNodeSaga,
        [MOVE_NODES]: moveNodeSaga,
        [REMOVE_NODES]: removeNodeSaga,
        [ATTACH_FILE_TO_DOCUMENT]: attachFileToDocumentSaga,
        [DETACH_FILE_TO_DOCUMENT]: detachFileToDocumentSaga,
        [ADD_FILES]: addFilesSaga,
        [TRANSFORM_ARCHIVE_NODE_TO_FOLDER]: transformArchiveNodeToFolderSaga,
        [REMOVE_EMPTY_DOCUMENT_NODES]: removeEmptyDocumentNodesSaga,
        [REMOVE_EMPTY_FOLDER_NODES]: removeEmptyFolderNodesSaga,
        [PDF_EDIT]: editPdfSaga,
        [PDF_MERGE]: mergeDocumentSaga,
        [PDF_EXPLODE]: explodePdfSaga,
      })
    );
  } finally {
    if (yield cancelled()) {
      tasks.forEach(task => {
        task.cancel();
      });
    }
  }
}

export default function configure(initialState) {
  const isDev = process.env.NODE_ENV === 'development';

  const create =
    window.__REDUX_DEVTOOLS_EXTENSION__ && isDev
      ? window.__REDUX_DEVTOOLS_EXTENSION__()(createStore)
      : createStore;

  const middlewares = [thunk, sagaMiddleware];

  if (isDev) {
    middlewares.push(logger);
  }

  middlewares.unshift(
    createRavenMiddleware(Raven, {
      breadcrumbDataFromAction: action => {
        return pick(action, (value, key) => key !== 'type' && !isObject(value));
      },
    })
  );

  const createStoreWithMiddleware = applyMiddleware.apply(
    this,
    middlewares
  )(create);

  const store = createStoreWithMiddleware(rootReducer, initialState);

  sagaMiddleware.run(function*() {
    yield fork(notificationSaga);

    while (yield take(START_DATAROOM)) {
      const tasks = yield fork(dataroomSaga);

      yield take(RESET_DATAROOM);

      try {
        yield cancel(tasks);
      } catch (e) {
        console.log({ e });
      }
    }
  });

  if (module.hot) {
    module.hot.accept('./reducers', () => {
      store.replaceReducer(nextReducer);
    });
  }

  return store;
}
