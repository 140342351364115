import React from 'react';
import Svg from './Svg';

export default function IconChevronBottomHeavy(props) {
  return (
    <Svg
      {...props}
      d="M128 163.961l97.902-108.78L243.2 72.819l-115.2 128-115.2-128 17.298-17.638L128 163.961z"
    />
  );
}
