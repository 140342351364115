import { call, cancel, fork, put, take } from 'redux-saga/effects';
import { MAX_BUFFER } from './addFiles';
import uploader from '../../core/uploader.js';

export const FILE_UPLOAD_PROGRESS = 'FILE_UPLOAD_PROGRESS';
export const FILES_UPLOAD_PROGRESS = 'FILES_UPLOAD_PROGRESS';

function* filesUploadedProgressSaga(frontActionId, uploadedSize, nodeId) {
  yield put({
    type: FILES_UPLOAD_PROGRESS,
    frontActionId,
    uploadedSize,
    nodeId,
  });
}

export function* uploadDocument(file, nodeId, offset, url, chan) {
  let uploadedStatus = null;
  let isUploadFinished = false;
  let bytesUploaded = 0;

  while (!isUploadFinished) {
    uploadedStatus = yield call(uploader.uploadChunk, url, file, offset);

    if (uploadedStatus) {
      bytesUploaded = uploadedStatus.offset - offset;
      offset = uploadedStatus.offset;
      isUploadFinished = uploadedStatus.isFinished;

      yield put(chan, {
        type: FILE_UPLOAD_PROGRESS,
        nodeId: nodeId,
        uploadedSize: bytesUploaded,
      });
    }
  }

  return uploadedStatus.lastResponse;
}

export function* handleFileUploadedProgess(
  fileUploadProgressChan,
  frontActionId,
  numberOfChunk
) {
  let finishedProgressFiles = [];
  let finishedProgressFilesByTaskId = {};
  let progressFileTask;
  let currentChunkUploaded = 0;

  while (true) {
    const action = yield take(fileUploadProgressChan);

    if (progressFileTask && progressFileTask.isRunning()) {
      yield cancel(progressFileTask);
      if (progressFileTask.isCancelled()) {
        finishedProgressFiles =
          finishedProgressFilesByTaskId[progressFileTask.id];
      }
    }

    finishedProgressFiles.push(action);

    progressFileTask = yield fork(
      filesUploadedProgressSaga,
      frontActionId,
      action.uploadedSize,
      action.nodeId
    );

    finishedProgressFilesByTaskId[progressFileTask.id] = finishedProgressFiles;
    if (finishedProgressFiles.length >= MAX_BUFFER) {
      progressFileTask = null;
    }

    finishedProgressFiles = [];
    currentChunkUploaded += 1;

    if (numberOfChunk === currentChunkUploaded) {
      break;
    }
  }
}

function updateUploads(uploads, action) {
  const uploadIndex = uploads.findIndex(
    upload => upload.frontActionId === action.frontActionId
  );

  let newUpload = {
    ...uploads[uploadIndex],
    uploadedSize: uploads[uploadIndex].uploadedSize + action.uploadedSize,
  };

  return uploads.map((upload, index) => {
    if (uploadIndex !== index) {
      return upload;
    }

    return newUpload;
  });
}

export default function reducer(state, action) {
  switch (action.type) {
    case FILES_UPLOAD_PROGRESS: {
      const node = state.working.nodes[action.nodeId];

      let newNodes = {
        ...state.working.nodes,
      };

      if (node) {
        newNodes[action.nodeId] = {
          ...node,
          uploadingProgress: {
            ...node.uploadingProgress,
            uploadedSize:
              action.uploadedSize + node.uploadingProgress.uploadedSize,
          },
        };
      }

      return {
        ...state,
        working: {
          ...state.working,
          uploads: updateUploads(state.working.uploads, action),
          nodes: newNodes,
        },
      };
    }
  }
  return state;
}
