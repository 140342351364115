import React, { Component } from 'react';
import 'translations/stats-mail/fr';
import 'translations/stats-connexion/fr';
import { connect } from 'react-redux';
import { editPdfAction } from '../../ducks/pdfEditor';
import PdfEditor from '../../../pdfEditor/components/PdfEditor';
import theme from '../../../../presentational/components/theme_blue';
import { closePdfEditor } from '../../actions/dataroom';
import editionModeSelector from '../../selectors/editionModeSelectorFromDataRoom';
import { ThemeProvider } from 'styled-components';
import { startPdfErrorsDetectionOnNodeAction } from '../../../pdfEditor/ducks/pdfPageErrors';

class DataroomPdfEditor extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    document.querySelector('body').style.overflow = 'hidden';
  }

  componentWillUnmount() {
    if (this.props.isLargeDataroom || this.props.document === null) {
      document.querySelector('body').style.overflow = '';
    }
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <PdfEditor
          theme={theme}
          name={this.props.dataroom.nodes[this.props.nodeId].name}
          canEdit={this.props.lock.lockedByMe}
          cantEditMessage={
            'Vous devez verrouiller la dataroom pour manipuler le fichier.'
          }
          canDeleteOrDragPage
          prepareEditingUrl={`/api/nodes/${this.props.nodeId}/prepareEditing`}
          onLeave={this.props.closePdfEditor}
          onSubmit={pages => this.props.editPdfAction(this.props.nodeId, pages)}
          onDetectErrors={() =>
            this.props.startPdfErrorsDetectionOnNodeAction(this.props.nodeId)
          }
        />
      </ThemeProvider>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    ...props,
    lock: state.dataroom.working.lock,
    isLargeDataroom: state.dataroom.isLargeDataroom,
    document: state.document.document,
    editionMode: editionModeSelector(state),
  };
}

export default connect(mapStateToProps, {
  editPdfAction,
  closePdfEditor,
  startPdfErrorsDetectionOnNodeAction,
})(DataroomPdfEditor);
