import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Notification from '../components/Notification/Notification';
import { bindActionCreators } from 'redux';
import {
  moveNotificationAction,
  addPermanentNotification,
  deleteNotification,
} from '../actions/notifications';
import ReactDOM from 'react-dom';
import {
  selectNotifications,
  selectNotificationsReminder,
} from '../selectors/notifications';
import getVisibleNodesNumber from '../../dataroom/selectors/getVisibleNodesNumber';
import { VISIBLE_NODE_NUMBER_WARNING } from '../../dataroom/constants';
import {
  STATUS_NOTIFICATION,
  TYPE_ERROR,
  TYPE_WARNING,
  TYPE_IN_PROGRESS,
} from '../reducers/notifications';
import './Notifications.css';

const FullScreenNotification = props => {
  const { notifications, moveNotificationAction, moveNotifications } = props;

  if (notifications.length === 0) {
    return null;
  }

  return (
    <Fragment>
      <header>
        <h3> Notifications </h3>
        <span
          onClick={evt => {
            evt.preventDefault();
            moveNotifications();
          }}
          className="close-full-screen-notifications"
        >
          <i className="fa fa-times" />
        </span>
      </header>
      <section>
        {notifications.map(notification => (
          <Notification
            key={notification.id}
            notification={notification}
            moveNotificationAction={() => {
              moveNotificationAction(notification.id);
            }}
          />
        ))}
      </section>
    </Fragment>
  );
};

const ReminderNotification = props => {
  const {
    notifications,
    moveNotificationAction,
    deleteNotificationAction,
  } = props;

  if (notifications.length === 0) {
    return null;
  }

  return (
    <section>
      {notifications.map(notification => (
        <Notification
          key={notification.id}
          notification={notification}
          moveNotificationAction={() => {
            moveNotificationAction(notification.id);
          }}
          deleteNotificationAction={() => {
            deleteNotificationAction(notification.id);
          }}
        />
      ))}
    </section>
  );
};

const WARNING_VISIBLE_NODES_NUMBER_NOTIFICATION_ID = 'visibles-nodes-warning';
const ERROR_CORRUPTED_DATAROOM = 'error-corrupted-dataroom';
const WARNING_SLOW_ACTION = 'warning-slow-action';

class Notifications extends Component {
  constructor(props) {
    super(props);
    this.moveNotifications = this.moveNotifications.bind(this);

    this.fullScreenElement = document.getElementById('wk-notification-wrapper');
    this.reminderElement = document.getElementById(
      'wk-notification-reminder-wrapper'
    );
  }

  shouldComponentUpdate(nextProps) {
    return nextProps !== this.props;
  }

  componentDidMount() {
    if (this.props.visibleNodesNumber > VISIBLE_NODE_NUMBER_WARNING) {
      this.props.addPermanentNotification(
        WARNING_VISIBLE_NODES_NUMBER_NOTIFICATION_ID,
        `Plus de ${VISIBLE_NODE_NUMBER_WARNING} lignes sont affichées. 
        il est conseillé de fermer certains dossiers pour garder un affichage fluide de l'arborescence`,
        TYPE_WARNING
      );
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.visibleNodesNumber < VISIBLE_NODE_NUMBER_WARNING &&
      nextProps.visibleNodesNumber > VISIBLE_NODE_NUMBER_WARNING
    ) {
      return this.props.addPermanentNotification(
        WARNING_VISIBLE_NODES_NUMBER_NOTIFICATION_ID,
        `Plus de ${VISIBLE_NODE_NUMBER_WARNING} lignes sont affichées. 
        il est conseillé de fermer certains dossiers pour garder un affichage fluide de l'arborescence`,
        TYPE_WARNING
      );
    }

    if (
      this.props.visibleNodesNumber > VISIBLE_NODE_NUMBER_WARNING &&
      nextProps.visibleNodesNumber < VISIBLE_NODE_NUMBER_WARNING
    ) {
      return this.props.deleteNotification(
        WARNING_VISIBLE_NODES_NUMBER_NOTIFICATION_ID
      );
    }

    // todo pas de check sur le user
    if (!this.props.corrupted && nextProps.corrupted) {
      return this.props.addPermanentNotification(
        ERROR_CORRUPTED_DATAROOM,
        `La dataroom connait un problème de cohérence, l'équipe technique est informée et revient vers vous au plus vite`,
        TYPE_ERROR
      );
    }

    if (this.props.slowActionInProgress && !nextProps.slowActionInProgress) {
      return this.props.deleteNotification(WARNING_SLOW_ACTION);
    }

    if (!this.props.slowActionInProgress && nextProps.slowActionInProgress) {
      return this.props.addPermanentNotification(
        WARNING_SLOW_ACTION,
        `Une action sur la dataroom est en cours et prend un peu plus de temps que prévu, 
        ne quittez pas la page tant que ce message est affiché`,
        TYPE_IN_PROGRESS
      );
    }

    if (this.props.corrupted && !nextProps.corrupted) {
      return this.props.deleteNotification(ERROR_CORRUPTED_DATAROOM);
    }
  }

  moveNotifications() {
    this.props.notifications
      .filter(notification => notification.status === STATUS_NOTIFICATION)
      .forEach(notification =>
        this.props.moveNotificationAction(notification.id)
      );
  }

  render() {
    const {
      notifications,
      moveNotificationAction,
      deleteNotification,
      notificationsReminder,
    } = this.props;

    return (
      <Fragment>
        {ReactDOM.createPortal(
          <FullScreenNotification
            moveNotificationAction={moveNotificationAction}
            moveNotifications={this.moveNotifications}
            notifications={notifications}
          />,
          this.fullScreenElement
        )}

        {ReactDOM.createPortal(
          <ReminderNotification
            notifications={notificationsReminder}
            deleteNotificationAction={deleteNotification}
          />,
          this.reminderElement
        )}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const workingDataroom = state.dataroom.working;
  const newProps = {
    notifications: selectNotifications(state),
    notificationsReminder: selectNotificationsReminder(state),
    visibleNodesNumber: 0,
    corrupted: false,
    slowActionInProgress: state.dataroom.working.slowActionInProgress,
    user: state.user,
  };

  if (workingDataroom && workingDataroom.loaded) {
    newProps.visibleNodesNumber = getVisibleNodesNumber(state);
    newProps.corrupted = workingDataroom.corrupted;
  }

  return newProps;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      moveNotificationAction,
      addPermanentNotification,
      deleteNotification,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
