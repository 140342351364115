import styled from 'styled-components';

const Span = styled.span`
  display: ${props => {
    if (props.hiddenForXs) {
      return 'none';
    }
    if (props.display) {
      return props.display;
    }
    return 'inline';
  }};
  flex-wrap: ${props => (props.flexWrap ? 'wrap' : 'nowrap')};
  flex-shrink: ${props => (props.flexShrink ? 1 : 0)};
  ${props =>
    props.alignItems ? 'align-items: ' + props.alignItems + ';' : null}
  ${props => (props.color ? 'color: ' + props.theme[props.color] + ';' : null)}
  ${props => (props.padding ? 'padding: ' + props.padding + ';' : null)}
  ${props =>
    props.paddingLeft ? 'padding-left: ' + props.paddingLeft + ';' : null}
  ${props =>
    props.backgroundColor
      ? 'background-color: ' + props.theme[props.backgroundColor] + ';'
      : null}
  ${props => (props.margin ? 'margin: ' + props.margin + ';' : null)}
  ${props => (props.fontSize ? 'font-size: ' + props.fontSize + ';' : null)}
  ${props =>
    props.lineHeight ? 'line-height: ' + props.lineHeight + ';' : null}
  ${props => (props.height ? 'height: ' + props.height + ';' : null)}
  ${props =>
    props.marginBottom ? 'margin-bottom: ' + props.marginBottom + ';' : null}
  ${props => (props.minWidth ? 'min-width: ' + props.minWidth + ';' : null)}
  ${props => (props.maxWidth ? 'max-width: ' + props.maxWidth + ';' : null)}
  ${props => (props.textAlign ? 'text-align: ' + props.textAlign + ';' : null)}
  ${props => (props.fontStyle ? 'font-style: ' + props.fontStyle + ';' : null)}
  ${props => (props.noWrap ? 'white-space: nowrap;' : null)}
  ${props =>
    props.fontWeight ? 'font-weight: ' + props.fontWeight + ';' : null}
  ${props =>
    props.screenReaderOnly
      ? 'position: absolute; width: 1px; height: 1px; padding: 0; margin: -1px; overflow: hidden; clip: rect(0,0,0,0); white-space: nowrap; border: 0;'
      : null}
  ${props =>
    props.textEllipsis
      ? 'text-overflow: ellipsis; overflow: hidden; white-space: nowrap;'
      : null}
  ${props =>
    props.verticalAlign ? 'vertical-align: ' + props.verticalAlign + ';' : null}
  ${props =>
    props.wordBreak
      ? 'word-break: ' +
        props.wordBreak +
        '; word-wrap: ' +
        props.wordBreak +
        ';'
      : null}
  ${props => (props.minHeight ? 'min-height: ' + props.minHeight + ';' : null)}
  ${props =>
    props.fontFamily
      ? 'font-family: ' + props.theme[props.fontFamily] + ';'
      : null}
  ${props =>
    props.justifyContent
      ? 'justify-content: ' + props.justifyContent + ';'
      : null}
  @media (min-width: ${props => props.theme.tablet}) {
    display: ${props => {
      if (props.hiddenForMd) {
        return 'none';
      }
      if (props.display) {
        return props.display;
      }
      return 'inline';
    }};
    ${props =>
      props.minWidthForMd ? 'min-width: ' + props.minWidthForMd + ';' : null}
    ${props =>
      props.fontSizeForMd ? 'font-size: ' + props.fontSizeForMd + ';' : null}
  }
`;

export default Span;
