import { createSelector } from 'reselect';
import { AFTER, BEFORE, INSIDE } from '../ducks/utils/getNextPath';
import { TYPE_FOLDER } from '../documents/type';
import nodesSelector from './workingNodesSelector';
import originalSelectedNodeIdInContextMenuSelector from './originalSelectedNodeIdInContextMenuSelector';
import editionModeSelectorFromDataRoom from './editionModeSelectorFromDataRoom';

const pickedNodeIdsSelector = state => state.dataroom.working.pickedNodeIds;

const canAdd = (nodes, nodeId, editionMode) => ({
  after: nodes[nodeId] && nodes[nodeId].path !== '0',
  before: nodes[nodeId] && nodes[nodeId].path !== '0',
  inside: nodes[nodeId] && nodes[nodeId].type === TYPE_FOLDER,
});

export const canAddForSelectedNodeInContextMenu = createSelector(
  [
    nodesSelector,
    originalSelectedNodeIdInContextMenuSelector,
    editionModeSelectorFromDataRoom,
  ],
  canAdd
);

export const canAddForPickedNode = createSelector(
  [nodesSelector, pickedNodeIdsSelector, editionModeSelectorFromDataRoom],
  (nodes, pickedNodeIds, editionMode) => {
    if (pickedNodeIds.length !== 1) {
      return {
        after: false,
        before: false,
        inside: false,
      };
    }

    return canAdd(nodes, pickedNodeIds[0], editionMode);
  }
);
