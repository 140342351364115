import React from 'react';
import Svg from './Svg';

export default function IconAttachment(props) {
  return (
    <Svg
      {...props}
      d="M128 119.031l44.849-44.849 8.969 8.969L136.969 128l44.849 44.849-8.969 8.969L128 136.969l-44.849 44.849-8.969-8.969L119.031 128 74.182 83.151l8.969-8.969L128 119.031z"
    />
  );
}
