import React, { Component } from 'react';
import Modal from '../../../presentational/components/Modal';
import A from '../../../presentational/components/A';
import {
  DARK_COLOR,
  LIGHT_COLOR,
  LIGHT_GREY_COLOR,
} from '../../../presentational/components/theme_blue';
import IconClose from '../../../presentational/components/img/icons/IconClose';
import P from '../../../presentational/components/P';
import Button from '../../../presentational/components/Button';

class ModalConfirm extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Modal zIndex={1000}>
        <Modal.Popin>
          <Modal.Header>
            <Modal.Title>{this.props.title}</Modal.Title>
            <Modal.Close>
              <A
                cursor="pointer"
                color={LIGHT_COLOR}
                hoverColor={LIGHT_GREY_COLOR}
                onClick={this.props.cancel}
              >
                <IconClose width="1.5rem" alt="fermer" />
              </A>
            </Modal.Close>
          </Modal.Header>
          <Modal.ContentPlaceholder>
            <Modal.Content display="inline-block">
              {this.props.children}
            </Modal.Content>
          </Modal.ContentPlaceholder>
          <Modal.Footer>
            <P
              marginTop="0"
              marginBottom="0"
              textAlign="right"
              fontSize=".75em"
            >
              <Button
                color={DARK_COLOR}
                borderColor={DARK_COLOR}
                backgroundColor={LIGHT_COLOR}
                backgroundHoverColor={DARK_COLOR}
                onClick={this.props.cancel}
              >
                {this.props.cancelLabel}
              </Button>
              <Button
                color={DARK_COLOR}
                borderColor={DARK_COLOR}
                backgroundColor={LIGHT_COLOR}
                backgroundHoverColor={DARK_COLOR}
                onClick={this.props.leave}
                marginLeft={0.5}
              >
                {this.props.leaveLabel}
              </Button>
              <Button type="submit" onClick={this.props.save} marginLeft={0.5}>
                {this.props.saveLabel}
              </Button>
            </P>
          </Modal.Footer>
        </Modal.Popin>
      </Modal>
    );
  }
}

export default ModalConfirm;
