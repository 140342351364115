import React, { Component } from 'react';
import ActionsPanel from '../../app/containers/ActionsPanel.js';
import 'translations/edm-dataroom/fr';
import 'translations/edm-dataroom/en';
import { connect } from 'react-redux';
import AnonymousButton from '../components/AnonymousButton';
import ExportButton from './ExportButton';

class DataroomActions extends Component {
  constructor(props) {
    super(props);
  }

  generateEndpointForExport() {
    return `/api/datarooms/${this.props.dataroomId}/export/summary`;
  }

  generateUrlsForDocument(documents) {
    let urls = [];
    documents.forEach(function(document) {
      urls.push({
        name: `${document.path} ${document.name}`,
        url: window.location.pathname + '/' + 'documents' + '/' + document.id,
      });
    });

    return urls;
  }

  render() {
    const actions = [
      {
        name: Translator.trans(
          'export_summary_no_new_line',
          {},
          'edm-dataroom'
        ),
        target: '_blank',
        icon: 'file-excel-o',
        url: this.generateEndpointForExport(
          'summary',
          this.props.dataroomType,
          this.props.groupType
        ),
      },
    ];

    const links = [
      {
        icon: 'file',
        title: Translator.trans('asset.new_documents', {}, 'edm-dataroom'),
        urls: this.generateUrlsForDocument(this.props.lastDocuments),
      },
    ];

    return (
      <ActionsPanel
        actionsList={actions}
        links={links}
        headerElementsList={[]}
        footerActionsList={[]}
      >
        {this.props.exportDataroom && (
          <ExportButton
            dataroomId={this.props.dataroomId}
            admin={this.props.admin}
          />
        )}
        <AnonymousButton />
      </ActionsPanel>
    );
  }
}

export function mapStateToProps(state, ownProps) {
  let props = {
    ...ownProps,
    lastDocuments: state.dataroom.working.lastDocuments,
    exportDataroom: false,
  };

  if (state.dataroom.loaded) {
    props.exportDataroom = true;
  }

  return props;
}

export default connect(mapStateToProps, {})(DataroomActions);
