import { createSelector } from 'reselect';
import getSelectedNodes from './getSelectedNodes';

const convertBytesToMegabytes = value => value / 1000000;

const getTotalFileSizeForSelectedNodes = nodes => {
  let sizes = nodes.reduce(
    (accumulator, currentValue) =>
      accumulator + (currentValue.file ? currentValue.file.size : 0),
    0
  );

  return convertBytesToMegabytes(sizes).toFixed(2);
};

export default createSelector([getSelectedNodes], nodes => {
  return getTotalFileSizeForSelectedNodes(nodes);
});
