import { useDispatch, useSelector } from 'react-redux';
import React, { Component, Fragment, useEffect, useMemo, useRef } from 'react';
import apiClient from '../../core/apiClient';
import {
  changeCurrentError,
  ERROR_TYPE_EAST_ORIENTATION,
  ERROR_TYPE_SOUTH_ORIENTATION,
  ERROR_TYPE_UNKNOWN_OPERATION,
  ERROR_TYPE_UNKNOWN_ORIENTATION,
  ERROR_TYPE_WEST_ORIENTATION,
  ERROR_TYPE_WHITE_PAGE,
  fixPdfPageErrorAction,
  removeErrorAction,
  STATUS_FINISHED,
  STATUS_SCROLL_DELAYED,
  STATUS_SCROLL_IMMEDIATLY,
  STATUS_SCROLL_NONE,
  UPDATE_DETECTION_INFO,
  scrollDoneStatusAction,
} from '../ducks/pdfPageErrors';
import ErrorsScanPanel from '../../../presentational/components/editor/ErrorsScanPanel';
import Div from '../../../presentational/components/Div';
import Span from '../../../presentational/components/Span';
import IconCheckmark from '../../../presentational/components/img/icons/IconCheckmark';
import {
  SUCCESS_COLOR,
  WARNING_COLOR,
} from '../../../presentational/components/theme_blue';
import LoadingIcon from '../../../presentational/components/LoadingIcon';
import { LIGHT_COLOR } from '../../../presentational/components/theme_blue';
import IconEyeClosed from '../../../presentational/components/img/icons/IconEyeClosed';
import IconChevronLeftHeavy from '../../../presentational/components/img/icons/IconChevronLeftHeavy';
import IconChevronRightHeavy from '../../../presentational/components/img/icons/IconChevronRightHeavy';
import Button from '../../../presentational/components/Button';
import queryString from 'qs';

const scrollToPage = pageNumber => {
  const pageDomElement = document.getElementById(
    `pdf-editor-page-${pageNumber}`
  );

  if (pageDomElement) {
    pageDomElement.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }
};

const getTypeError = type => {
  switch (type) {
    case ERROR_TYPE_WHITE_PAGE:
      return 'Page blanche';
    case ERROR_TYPE_EAST_ORIENTATION:
      return 'Mauvaise orientation (90° -> 270°)';
    case ERROR_TYPE_SOUTH_ORIENTATION:
      return 'Mauvaise orientation (180° -> 180°)';
    case ERROR_TYPE_WEST_ORIENTATION:
      return 'Mauvaise orientation (270° -> 90°)';
    default:
      return 'Orientation inconnue';
  }
};

function CurrentError() {
  const currentErrorId = useSelector(
    state => state.pdfEditor.pdfPageErrors.currentErrorId
  );
  const errors = useSelector(state => state.pdfEditor.pdfPageErrors.errors);
  const scrollStatus = useSelector(
    state => state.pdfEditor.pdfPageErrors.scrollStatus
  );

  const unfixedErrors = useMemo(() => {
    return errors.filter(error => !error.fixed);
  }, [errors]);

  const currentErrorIndex = useMemo(() => {
    return unfixedErrors.findIndex(error => error.id === currentErrorId);
  }, [currentErrorId, unfixedErrors]);

  const dispatch = useDispatch();

  useEffect(() => {
    let timerId;

    if (currentErrorIndex === -1) {
      return;
    }

    const currentError = unfixedErrors[currentErrorIndex];

    if (!currentError) {
      return;
    }

    if (scrollStatus === STATUS_SCROLL_NONE) {
      return;
    }

    if (scrollStatus === STATUS_SCROLL_IMMEDIATLY) {
      scrollToPage(currentError.pageNumber);
      dispatch(scrollDoneStatusAction(STATUS_SCROLL_NONE));
    }

    if (scrollStatus === STATUS_SCROLL_DELAYED) {
      timerId = setTimeout(() => {
        scrollToPage(currentError.pageNumber);
        dispatch(scrollDoneStatusAction(STATUS_SCROLL_NONE));
      }, 1000);
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [currentErrorIndex, unfixedErrors, scrollStatus]);

  const currentError = unfixedErrors[currentErrorIndex];

  return (
    <Fragment>
      {unfixedErrors.length > 0 && (
        <Fragment>
          <Div width="auto" textAlign="center">
            Erreur actuelle :{' '}
            <Span color={WARNING_COLOR}>{currentErrorIndex + 1}</Span> /{' '}
            {unfixedErrors.length}
            <div>
              Erreur de type :{' '}
              <Span color={WARNING_COLOR} fontWeight="bold">
                {getTypeError(currentError.errorType)}
              </Span>
            </div>
          </Div>
          <Div width="auto">
            <Button
              marginLeft={0.5}
              marginRight={0.5}
              borderColor={LIGHT_COLOR}
              onClick={() => {
                dispatch(removeErrorAction(currentError));
              }}
            >
              <IconEyeClosed width="1.5em" marginRight=".5em" />
              Ignorer
            </Button>
            {currentError.errorType !== ERROR_TYPE_UNKNOWN_ORIENTATION &&
              currentError.errorType !== ERROR_TYPE_UNKNOWN_OPERATION && (
                <Button
                  marginLeft={0.5}
                  marginRight={0.5}
                  backgroundColor={LIGHT_COLOR}
                  borderColor={LIGHT_COLOR}
                  color={SUCCESS_COLOR}
                  backgroundHoverColor={SUCCESS_COLOR}
                  onClick={() => {
                    dispatch(fixPdfPageErrorAction(currentError));
                  }}
                >
                  <IconCheckmark width="1.5em" marginRight=".5em" />
                  Régler
                </Button>
              )}
          </Div>
          <Div width="auto" display="flex">
            <Button
              marginLeft={0.5}
              marginRight={0.5}
              onClick={() => {
                dispatch(
                  changeCurrentError(unfixedErrors[currentErrorIndex - 1])
                );
              }}
              disabled={currentErrorIndex === 0}
            >
              <IconChevronLeftHeavy width="1.5em" marginRight=".5em" />
              Précédente
            </Button>
            <Button
              marginLeft={0.5}
              onClick={() => {
                dispatch(
                  changeCurrentError(unfixedErrors[currentErrorIndex + 1])
                );
              }}
              disabled={currentErrorIndex + 1 === unfixedErrors.length}
            >
              Suivante
              <IconChevronRightHeavy width="1.5em" marginLeft=".5em" />
            </Button>
          </Div>
        </Fragment>
      )}
    </Fragment>
  );
}

export default function PdfErrors(props) {
  const pdfPageErrors = useSelector(state => state.pdfEditor.pdfPageErrors);
  const totalPage = useSelector(state => state.pdfEditor.totalPage);
  const dispatch = useDispatch();

  // regular polling of pdfDetection status/tratedPage + get new page errors
  useEffect(() => {
    let timerId;

    if (pdfPageErrors.status === STATUS_FINISHED) {
      return;
    }

    async function getPdfDetectionInfos() {
      const response = await apiClient.request(
        new Request(
          `/api/pdf_errors_detection/${
            pdfPageErrors.pdfDetectionId
          }?${queryString.stringify({
            resources: ['PDF_ERRORS_DETECTION_PDF_PAGE_ERRORS'],
            resourcesParams: {
              'PDF_ERRORS_DETECTION_PDF_PAGE_ERRORS.ignoredIds': pdfPageErrors.errors
                .map(error => error.id)
                .join(','),
            },
          })}`
        )
      );

      if (!response.ok) {
        timerId = setTimeout(getPdfDetectionInfos, 1000);
        return;
      }

      const pdfErrorsDetection = await response.json();

      dispatch({
        type: UPDATE_DETECTION_INFO,
        pdfErrorsDetection,
      });

      if (pdfErrorsDetection.status === STATUS_FINISHED) {
        return;
      }

      timerId = setTimeout(getPdfDetectionInfos, 1000);
    }

    timerId = setTimeout(getPdfDetectionInfos, 1000);

    return () => {
      clearTimeout(timerId);
    };
  }, [
    pdfPageErrors.errors,
    pdfPageErrors.pdfDetectionId,
    pdfPageErrors.status,
    dispatch,
  ]);

  if (
    pdfPageErrors.status === STATUS_FINISHED &&
    pdfPageErrors.currentErrorId === null
  ) {
    return null;
  }

  return (
    <ErrorsScanPanel>
      <ErrorsScanPanel.Inner>
        <Div width="auto" display="flex" alignItems="center">
          <Span
            hiddenForXs
            display="inline-block"
            margin="0 1em -5px 2em"
            minWidth="1.5rem"
            textAlign="center"
          >
            {pdfPageErrors.status === STATUS_FINISHED && (
              <IconCheckmark color={SUCCESS_COLOR} width="2em" />
            )}
            {pdfPageErrors.status !== STATUS_FINISHED && <LoadingIcon />}
          </Span>
          <Div width="13em" textAlign="center">
            {pdfPageErrors.status === STATUS_FINISHED && (
              <>
                Analyse terminée
                <div>{totalPage} pages analysées</div>
              </>
            )}
            {pdfPageErrors.status !== STATUS_FINISHED && (
              <>
                Analyse en cours…
                <div>
                  Page{' '}
                  <Span color={WARNING_COLOR}>
                    {pdfPageErrors.treatedPageNumber}
                  </Span>{' '}
                  sur {totalPage}
                </div>
              </>
            )}
          </Div>
        </Div>
        <CurrentError />
      </ErrorsScanPanel.Inner>
    </ErrorsScanPanel>
  );
}
