import styled from 'styled-components';

const Hr = styled.hr`
  height: 1px;
  background: ${props =>
    props.color ? props.theme[props.color] : props.theme.lightGreyColor};
  border: none;
  outline: none;
  margin: 1em 0;
`;

export default Hr;
