import styled from 'styled-components';

import {
  BODY_FONT,
  DARK_GREY_COLOR,
  LIGHT_COLOR,
  LIGHT_GREY_COLOR,
  CLIENT_UI_COLOR,
} from './theme_blue';

const orangeRatio = 1.23077; // ≈ 16/13 (16px = 1rem for the blue skin, while 13px = 1rem for the orange skin)

const ClientUI = styled.div`
  font-size: 1rem;
  font-weight: 400;
  font-family: ${props => props.theme[BODY_FONT]};
  color: ${props => props.theme[DARK_GREY_COLOR]};
  background-color: ${props => props.theme[LIGHT_COLOR]};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

ClientUI.Header = styled.header`
  display: flex;
  padding: 0
    ${props =>
      props.isOrangeSkin ? orangeRatio * 1.5625 + 'rem' : '1.5625rem'};
  align-items: center;
  border-bottom: solid 1px ${props => props.theme[LIGHT_GREY_COLOR]};
  font-size: ${props =>
    props.isOrangeSkin ? orangeRatio * 0.75 + 'rem' : '.75rem'};
  height: ${props =>
    props.isOrangeSkin ? orangeRatio * 4.5625 + 'rem' : '4.5625rem'};
  overflow: hidden;
  justify-content: ${props => (props.isUserLogged ? 'flex-start' : 'center')};
  font-weight: 300;
  @media (min-width: ${props => props.theme.tablet}) {
    font-size: ${props => (props.isOrangeSkin ? orangeRatio + 'rem' : '1rem')};
    padding: 0
      ${props =>
        props.isOrangeSkin ? orangeRatio * 2.1875 + 'rem' : '2.1875rem'};
  }
`;
ClientUI.ClientLogo = styled.img`
  display: inline-block;
  max-width: ${props =>
    props.isOrangeSkin ? orangeRatio * 5 + 'rem' : '5rem'};
  max-height: 100%;
  width: auto;
  height: auto;
  margin-right: ${props => (props.isOrangeSkin ? orangeRatio + 'rem' : '1rem')};
  margin-top: ${props =>
    props.isOrangeSkin ? orangeRatio * 0.5 + 'rem' : '.5rem'};
  margin-bottom: ${props =>
    props.isOrangeSkin ? orangeRatio * 0.5 + 'rem' : '.5rem'};
  @media (min-width: ${props => props.theme.tablet}) {
    max-width: ${props =>
      props.isOrangeSkin ? orangeRatio * 10 + 'rem' : '10rem'};
  }
`;
ClientUI.MainNav = styled.nav`
  display: flex;
  margin-left: auto;
  flex-wrap: wrap;
  justify-content: space-around;
  @media (min-width: ${props => props.theme.tablet}) {
    justify-content: flex-end;
  }
`;
ClientUI.MainNavLink = styled.a`
  display: inline-block;
  margin: 0
    ${props => (props.isOrangeSkin ? orangeRatio * 0.5 + 'rem' : '.5rem')};
  padding: ${props =>
      props.isOrangeSkin ? orangeRatio * 0.5 + 'rem' : '.5rem'}
    0;
  color: ${props => props.theme[CLIENT_UI_COLOR]};
  text-decoration: none;
  position: relative;
  &:after {
    display: block;
    position: absolute;
    bottom: 0;
    left: ${props => (props.active ? '0' : '50%')};
    width: ${props => (props.active ? '100%' : '0')};
    height: 1px;
    content: '';
    background-color: ${props => props.theme[CLIENT_UI_COLOR]};
    transition: 0.3s;
  }
  &:hover {
    color: ${props => props.theme[CLIENT_UI_COLOR]};
    filter: brightness(0.5);
    &:after {
      width: 100%;
      left: 0;
    }
  }
`;
ClientUI.Main = styled.main`
  ${props =>
    props.verticalCentered ? 'margin-top: auto; margin-bottom: auto;' : null}
  padding: ${props => (props.isOrangeSkin ? '0' : '1.5625rem')};
  font-size: 1rem;
  @media (min-width: ${props => props.theme.tablet}) {
    padding: ${props => (props.isOrangeSkin ? '0' : '2.1875rem')};
  }
`;

export default ClientUI;
