import styled from 'styled-components';
import { BODY_FONT } from './theme_blue';

const GridCol = styled.div`
  font-family: ${props => props.theme[BODY_FONT]};
  box-sizing: border-box;
  ${props => (props.textAlign ? 'text-align: ' + props.textAlign + ';' : null)}
  ${props => {
    if (!props.xs) {
      return 'max-width: 100%; flex-basis: 0; flex-grow: 1;';
    }
    return (
      'max-width: ' + props.xs * 100 + '%; flex: 0 0 ' + props.xs * 100 + '%;'
    );
  }}
  padding-left: ${props => (props.noGutters ? '0' : '.5rem')};
  padding-right: ${props => (props.noGutters ? '0' : '.5rem')};
  ${props =>
    props.marginBottom ? 'margin-bottom: ' + props.marginBottom + ';' : null}
    
  ${props => (props.margin ? 'margin: ' + props.margin + ';' : null)}
  ${props => (props.hiddenForXs ? 'display: none;' : null)}
  ${props => {
    if (props.textAlign == 'right') {
      return 'justify-content: flex-end;';
    }
    if (props.textAlign == 'center') {
      return 'justify-content: center;';
    }
  }}
  ${props => (props.fontSize ? 'font-size: ' + props.fontSize + ';' : null)}
  @media (min-width: ${props => props.theme.tablet}){
    ${props => (props.hiddenForXs ? 'display: block;' : null)}
    ${props => (props.mdFirst ? 'order: -1;' : null)}
    ${props => {
      if (!props.md) {
        return 'max-width: 100%; flex-basis: 0; flex-grow: 1;';
      }
      return (
        'min-width: ' +
        props.md * 100 +
        '%; flex: 0 0 ' +
        props.md * 100 +
        '%; max-width: ' +
        props.md * 100 +
        '%;'
      );
    }}
  }
`;

export default GridCol;
