import { takeEvery, call, put } from 'redux-saga/effects';
export const EXPORT_DATAROOM = 'EXPORT_DATAROOM';
export const SHARE_EXPORT_DATAROOM = 'SHARE_EXPORT_DATAROOM';
export const SHARE_EXPORTED_DATAROOM = 'SHARE_EXPORTED_DATAROOM';
export const CHECK_EXPORT_STATUS = 'CHECK_EXPORT_STATUS';
export const EXPORT_DATAROOM_SUCCESS = 'EXPORT_DATAROOM_SUCCESS';
export const EXPORT_DATAROOM_ERROR = 'EXPORT_DATAROOM_ERROR';
export const OPEN_EXPORT_DATAROOM_MODAL = 'OPEN_EXPORT_DATAROOM_MODAL';
export const CLOSE_EXPORT_DATAROOM_MODAL = 'CLOSE_EXPORT_DATAROOM_MODAL';
import { EXPORT_TYPE_NOTARY_CHAMBER } from '../constants';

import apiClient from 'js/dataroom/core/apiClient';
import { delay } from 'redux-saga';
import { VALIDATE_ERROR, VALIDATE_SUCCESS } from './validate';
import { addNotificationAction } from '../../app/actions/notifications';
import { TYPE_WARNING, TYPE_SUCCESS } from '../../app/reducers/notifications';

export const downloadFile = url => {
  window.location = url;
};

const exportDataroomSuccessAction = () => ({
  type: EXPORT_DATAROOM_SUCCESS,
});

const exportDataroomErrorAction = () => ({
  type: EXPORT_DATAROOM_ERROR,
});

export const exportDataroomAction = (
  exportType,
  selectedNodes,
  dataroomId,
  isAdmin
) => ({
  type: EXPORT_DATAROOM,
  exportType,
  selectedNodes,
  dataroomId,
  isAdmin,
});

export const shareExportDataroomAction = (
  selectedNodes,
  dataroomId,
  exportType,
  selectedMails
) => ({
  type: SHARE_EXPORT_DATAROOM,
  selectedNodes,
  dataroomId,
  exportType,
  selectedMails,
});

export const openShareDataroomModalAction = () => ({
  type: OPEN_EXPORT_DATAROOM_MODAL,
});

export const shareExportedDataroomAction = () => ({
  type: SHARE_EXPORTED_DATAROOM,
});

export const closeShareDataroomModalAction = () => ({
  type: CLOSE_EXPORT_DATAROOM_MODAL,
});

const checkExportStatusAction = (exportId, dataroomId, isAdmin) => ({
  type: CHECK_EXPORT_STATUS,
  exportId,
  dataroomId,
  isAdmin,
});

function* checkExportStatusSaga(action) {
  let iteration = 0;

  while (true) {
    let waitingTime = iteration * 150 + 150;
    if (waitingTime > 3000) {
      waitingTime = 3000;
    }

    yield delay(waitingTime);

    iteration += 1;

    const status = yield call(
      checkExportStatus,
      action.exportId,
      action.dataroomId
    );
    if (status === 'pending') {
      continue;
    }

    if (status === 'error') {
      yield put(
        addNotificationAction(
          Translator.trans('export-error', {}, 'edm-dataroom')
        )
      );
      yield put(exportDataroomErrorAction());
      break;
    }

    if (status === 'success') {
      downloadFile(
        `/api/datarooms/${action.dataroomId}/export/${action.exportId}`,
        'export.zip'
      );
      yield put(exportDataroomSuccessAction(action.dataroomType));
      break;
    }
  }
}

const checkExportStatus = (exportId, dataroomId) =>
  apiClient
    .request(
      new Request(`/api/datarooms/${dataroomId}/export/${exportId}/status`)
    )
    .then(response => response.json())
    .then(data => data.status);

const exportDataroom = (dataroomId, selectedNodes, exportType) =>
  apiClient
    .request(
      new Request(`/api/datarooms/${dataroomId}/export`, {
        method: 'POST',
        body: JSON.stringify({
          nodeIds: selectedNodes.map(node => node.id),
          exportType: exportType,
        }),
      })
    )
    .then(response => response.json())
    .then(data => data.exportId);

const shareExportDataroom = (
  dataroomId,
  selectedNodes,
  exportType,
  selectedMails
) =>
  apiClient
    .request(
      new Request(`/api/datarooms/${dataroomId}/share`, {
        method: 'POST',
        body: JSON.stringify({
          nodeIds: selectedNodes.map(node => node.id),
          exportType: exportType,
          mails: selectedMails,
        }),
      })
    )
    .then(response => response.json())
    .then(data => data.exportId);

const validateDataroomForExport = dataroomId =>
  apiClient
    .request(new Request(`/api/datarooms/${dataroomId}/validate`))
    .then(response => {
      if (!response.ok) {
        throw new Error();
      }
      return response.json();
    });

const validateSelectedNodes = (validationErrors, selectedNodes) => {
  // We ignore warning when validate
  let selectedNodesErrors = {
    violations: [],
    warning: [],
  };

  selectedNodes.map(selectedNode => {
    validationErrors.violations.map(validationError => {
      if (selectedNode.id === validationError.id) {
        validationError.reasons = validationError.reasons.filter(
          error =>
            error === 'NAME_TOO_LONG' || error === 'NAME_WITH_ILLEGAL_CHAR'
        );
        if (validationError.reasons.length !== 0) {
          selectedNodesErrors.violations.push(validationError);
        }
      }
    });
  });
  return selectedNodesErrors;
};

function* exportDataroomSaga(action) {
  if (action.exportType !== EXPORT_TYPE_NOTARY_CHAMBER) {
    let validationErrors = null;

    try {
      validationErrors = yield call(
        validateDataroomForExport,
        action.dataroomId
      );
    } catch (e) {
      yield put(
        addNotificationAction(
          "La validation de la dataroom pour l'export a échoué, export impossible"
        )
      );
      yield put({ type: VALIDATE_ERROR });
      return;
    }

    const selectedNodesValidationErrors = yield call(
      validateSelectedNodes,
      validationErrors,
      action.selectedNodes
    );

    if (selectedNodesValidationErrors.violations.length !== 0) {
      yield put({
        type: VALIDATE_SUCCESS,
        validationErrors: selectedNodesValidationErrors,
      });
      yield put(
        addNotificationAction(
          `La dataroom n\'est  pas valide pour l\'export (${selectedNodesValidationErrors.violations.length} erreurs)`,
          TYPE_WARNING
        )
      );
      yield put(exportDataroomErrorAction(action.dataroomType));
      return;
    }
  }
  const exportId = yield call(
    exportDataroom,
    action.dataroomId,
    action.selectedNodes,
    action.exportType
  );
  yield put(
    checkExportStatusAction(exportId, action.dataroomId, action.isAdmin)
  );
}

function* shareExportDataroomSaga(action) {
  if (action.exportType !== EXPORT_TYPE_NOTARY_CHAMBER) {
    let validationErrors = null;

    try {
      validationErrors = yield call(
        validateDataroomForExport,
        action.dataroomId
      );
    } catch (e) {
      yield put(
        addNotificationAction(
          "La validation de la dataroom pour l'export a échoué, export impossible"
        )
      );
      yield put({ type: VALIDATE_ERROR });
      return;
    }

    const selectedNodesValidationErrors = yield call(
      validateSelectedNodes,
      validationErrors,
      action.selectedNodes
    );
    if (selectedNodesValidationErrors.length !== 0) {
      yield put({
        type: VALIDATE_SUCCESS,
        validationErrors: selectedNodesValidationErrors,
      });
      yield put(
        addNotificationAction(
          `La dataroom n\'est  pas valide pour l\'export (${selectedNodesValidationErrors.length} erreurs)`,
          TYPE_WARNING
        )
      );
      yield put(exportDataroomErrorAction());
      return;
    }
  }

  yield call(
    shareExportDataroom,
    action.dataroomId,
    action.selectedNodes,
    action.exportType,
    action.selectedMails
  );

  yield put(shareExportedDataroomAction());
}

export function* saga() {
  yield takeEvery(EXPORT_DATAROOM, exportDataroomSaga);
  yield takeEvery(SHARE_EXPORT_DATAROOM, shareExportDataroomSaga);
  yield takeEvery(CHECK_EXPORT_STATUS, checkExportStatusSaga);
}

export default function(state, action) {
  switch (action.type) {
    case CLOSE_EXPORT_DATAROOM_MODAL:
      return {
        ...state,
        working: {
          ...state.working,
          modalContactShare: {
            open: false,
            sending: false,
            sent: false,
          },
        },
      };
    case OPEN_EXPORT_DATAROOM_MODAL:
      return {
        ...state,
        working: {
          ...state.working,
          modalContactShare: {
            open: true,
            sending: false,
            sent: false,
          },
        },
      };
    case SHARE_EXPORT_DATAROOM:
      return {
        ...state,
        working: {
          ...state.working,
          modalContactShare: {
            open: true,
            sending: true,
            sent: false,
          },
        },
      };
    case SHARE_EXPORTED_DATAROOM:
      return {
        ...state,
        working: {
          ...state.working,
          modalContactShare: {
            open: true,
            sending: false,
            sent: true,
          },
        },
      };
    case EXPORT_DATAROOM: {
      return {
        ...state,
        working: {
          ...state.working,
          exporting: true,
          validation: {
            ...state.working.validation,
            validating: action.exportType !== EXPORT_TYPE_NOTARY_CHAMBER,
          },
        },
      };
    }
    case EXPORT_DATAROOM_ERROR: {
      return {
        ...state,
        working: {
          ...state.working,
          exporting: false,
          validation: {
            ...state.working.validation,
            validating: false,
          },
        },
      };
    }
    case EXPORT_DATAROOM_SUCCESS: {
      return {
        ...state,
        working: {
          ...state.working,
          exporting: false,
          validation: {
            ...state.working.validation,
            validating: false,
          },
        },
      };
    }
  }
  return state;
}
