import styled from 'styled-components';
import { PRIMARY_COLOR, PRIMARY_LIGHT_COLOR } from '../theme_blue';

export default styled.div`
  background-color: ${props =>
    props.background
      ? props.theme[props.background]
      : props.theme[PRIMARY_LIGHT_COLOR]};
  color: ${props =>
    props.color ? props.theme[props.color] : props.theme[PRIMARY_COLOR]};
  height: calc(100% - 0.5em);
  display: flex;
  align-items: stretch;
  margin: 0 0 0.5em;
  flex-grow: 1;
`;
