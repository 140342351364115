import styled from 'styled-components';
import {
  HIGHLIGHT_ITEM_COLOR,
  INFO_COLOR,
  INFO_COLOR_DARK,
  PICKED_COLOR,
  PICKED_ITEM_COLOR,
  WARNING_COLOR,
  WARNING_COLOR_DARK,
} from '../../theme_blue';

export default styled.div`
  display: flex;
  margin-top: 0.25em;
  padding: 2px 5px;
  flex-grow: 1;
  flex-shrink: 0;
  align-items: flex-start;
  ${props =>
    props.picked
      ? 'background-color: ' + props.theme[PICKED_ITEM_COLOR] + ';'
      : null}
  cursor: ${props => {
    if (props.dragging) {
      return 'grabbing';
    }
    return 'grab';
  }};
    ${props => (props.picked ? 'cursor: grab;' : null)}
  ${props => (props.dragging ? 'opacity: .7;' : null)}
  ${props =>
    props.placeholder
      ? 'background-color: ' + props.theme[PICKED_ITEM_COLOR] + ';'
      : null}
  ${props => (props.placeholder ? 'min-height: 1.5em;' : null)}
  border: solid 1px ${props => {
    if (props.placeholder) {
      return props.theme[PICKED_ITEM_COLOR];
    }
    if (props.picked) {
      return props.theme[INFO_COLOR_DARK];
    }
    return 'transparent';
  }};
`;
