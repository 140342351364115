import { createSelector } from 'reselect';
import { TYPE_DOCUMENT } from '../documents/type';

const getVisibleNodesNumber = (nodeId, nodes) => {
  const node = nodes[nodeId];
  let visibleNodesNumber = 1;

  if (node.type === TYPE_DOCUMENT || node.folded) {
    return visibleNodesNumber;
  }

  return node.childIds.reduce(
    (acc, nodeId) => acc + getVisibleNodesNumber(nodeId, nodes),
    visibleNodesNumber
  );
};

export default createSelector(
  [
    state => state.dataroom.working.nodes,
    state => state.dataroom.working.rootNodeId,
  ],
  (nodes, rootNodeId) => {
    return getVisibleNodesNumber(rootNodeId, nodes);
  }
);
