import PropTypes from 'prop-types';
import React, { Component } from 'react';
import * as documentActions from '../actions/document.js';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Title from '../components/Title.js';
import { browserHistory } from 'react-router';
import DocumentTabs from '../components/DocumentTabs.js';
import { TYPE_DOCUMENT } from '../../dataroom/documents/type.js';
import { omit } from 'underscore';
import Loading from '../../app/components/Loading';
import './App.css';
import { CSSTransition } from 'react-transition-group';

import queryString from 'qs';

class App extends Component {
  constructor(props) {
    super(props);
    this.goToDataroom = this.goToDataroom.bind(this);
    this.componentWillUnmount = this.componentWillUnmount.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
    this.goToDataroomUrl = this.goToDataroomUrl.bind(this);
    this.loadDocument = this.loadDocument.bind(this);
    this.state = {
      unMounting: false,
    };
  }

  componentWillUnmount() {
    // dont reset document if we are on the same url (isLargeDataroom change cause component unmount)
    if (window.location.pathname.indexOf('documents') === -1) {
      this.props.resetDocumentPage();
    }

    document.querySelector('#nav_bar').style.display = 'block';
    document.querySelector('.page-head').style.display = 'block';
    document.querySelector('body').style.overflow = '';
    document.removeEventListener('keydown', this.onKeyPress);
  }

  onKeyPress(evt) {
    if (evt.key === 'Escape' && !this.props.isPdfEditorOpen) {
      evt.preventDefault();
      this.goToDataroom();
      return false;
    }
  }

  loadDocument() {
    if (
      (this.props.document &&
        this.props.document.id === this.props.routeParams.documentId) ||
      this.props.isFetchingDocument
    ) {
      return;
    }

    this.props.fetchDocument(
      this.props.id,
      this.props.routeParams.documentId,
      this.props.anonymous
    );
  }

  componentDidUpdate(prevProps) {
    this.loadDocument();

    if (this.props.isLargeDataroom === prevProps.isLargeDataroom) {
      return;
    }

    if (this.props.isLargeDataroom) {
      document.querySelector('#nav_bar').style.display = 'block';
      document.querySelector('.page-head').style.display = 'block';
      document.querySelector('body').style.overflow = '';
      return;
    }
    document.querySelector('#nav_bar').style.display = 'none';
    document.querySelector('.page-head').style.display = 'none';
    document.querySelector('body').style.overflow = 'hidden';
  }

  componentDidMount() {
    this.loadDocument();

    document.addEventListener('keydown', this.onKeyPress);

    if (!this.props.isLargeDataroom) {
      document.querySelector('#nav_bar').style.display = 'none';
      document.querySelector('.page-head').style.display = 'none';
      document.querySelector('body').style.overflow = 'hidden';
    }
  }

  render() {
    const {
      document,
      isFetchingDocument,
      anonymous,
      selectedTab,
      isLargeDataroom,
    } = this.props;

    let rootClassName = 'dataroom-doc-and-comment';
    let rootStyle = {};

    if (isLargeDataroom) {
      if (!this.state.unMounting) {
        rootClassName += ' document-detail-enter';
      } else {
        rootClassName += ' document-detail-exit';
      }
    } else {
      rootStyle.overflow = 'auto';
      rootStyle.height = '100vh';
    }

    let component = (
      <div style={rootStyle} className={rootClassName}>
        <Loading isLoading={isFetchingDocument} />
        {!isFetchingDocument && document && (
          <Title closeDocumentAction={this.goToDataroom} document={document} />
        )}
        {!isFetchingDocument && document && (
          <DocumentTabs
            hasFileTab={document.type === TYPE_DOCUMENT}
            fileTabActivated={!!document.file}
            selected={selectedTab}
            admin={this.props.admin}
            isLargeDataroom={isLargeDataroom}
            dataroomId={this.props.id}
            documentId={this.props.routeParams.documentId}
          />
        )}
      </div>
    );

    return component;
  }

  goToDataroomUrl() {
    let sufix = '';
    let prefix = '';

    if (this.props.anonymous) {
      sufix = `/fullscreen`;
    }

    browserHistory.push(`${prefix}/datarooms/${this.props.id}${sufix}`);
  }

  goToDataroom(evt) {
    const parsedQueryString = queryString.parse(
      window.location.search.substring(1)
    );
    if (typeof parsedQueryString.closeUrl != 'undefined') {
      window.location = `http://${window.location.host}${parsedQueryString.closeUrl}`;
      return;
    }

    if (evt) {
      evt.preventDefault();
    }

    if (this.props.isLargeDataroom) {
      this.setState(
        {
          unMounting: true,
        },
        () => {
          let urlUpdated = false;
          const listener = () => {
            urlUpdated = true;
          };

          window.addEventListener('popstate', listener);

          setTimeout(() => {
            window.removeEventListener('popstate', listener);
            if (urlUpdated) {
              return;
            }

            this.goToDataroomUrl();
          }, 100);
        }
      );

      return;
    }

    this.goToDataroomUrl();
  }
}

function mapStateToProps(state, props) {
  return {
    ...props,
    ...omit(state.document, 'anonymous'),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(documentActions, dispatch);
}

App.propTypes = {
  dataroomLoaded: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
