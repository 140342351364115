import { MINIMIZE, UNMINIMIZE } from '../actions/actions';

const initialState = {
  minimized: false,
};

export default function(state = initialState, action = {}) {
  switch (action.type) {
    case MINIMIZE:
    case UNMINIMIZE:
      return Object.assign({}, state, { minimized: action.type == MINIMIZE });
  }

  return state;
}
