import React, { Component } from 'react';
import './Notification.css';
import {
  TYPE_WARNING,
  TYPE_SUCCESS,
  STATUS_NOTIFICATION,
  STATUS_DELETING,
  STATUS_NOTIFICATION_REMINDER,
  STATUS_MOVING_TO_REMINDERS,
  TYPE_IN_PROGRESS,
  CATEGORY_REMINDER,
} from '../../reducers/notifications';

const getNotificationClass = notification => {
  let classNames = 'notification';

  if (
    notification.status === STATUS_DELETING ||
    notification.status === STATUS_NOTIFICATION_REMINDER ||
    notification.status === STATUS_MOVING_TO_REMINDERS
  ) {
    classNames = `${classNames} reminder`;
  }

  if (notification.type === TYPE_SUCCESS) {
    return `${classNames} notification--success`;
  }

  if (notification.type === TYPE_IN_PROGRESS) {
    return `${classNames} notification--in-progress`;
  }

  if (notification.type === TYPE_WARNING) {
    return `${classNames} notification--warning`;
  }

  return `${classNames} notification--error`;
};

class Notification extends Component {
  shouldComponentUpdate(nextProps) {
    return nextProps !== this.props;
  }

  componentDidMount() {
    if (this.props.notification.status === STATUS_NOTIFICATION) {
      this.timeout = setTimeout(this.props.moveNotificationAction, 4000);
    }

    if (
      this.props.notification.category === CATEGORY_REMINDER &&
      this.props.notification.status === STATUS_NOTIFICATION_REMINDER
    ) {
      this.timeout = setTimeout(this.props.deleteNotificationAction, 20000);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.notification.status === STATUS_NOTIFICATION &&
      nextProps.notification.status === STATUS_MOVING_TO_REMINDERS
    ) {
      clearTimeout(this.timeout);
    }
  }

  render() {
    const { notification } = this.props;

    const style = {};
    if (notification.status === STATUS_MOVING_TO_REMINDERS) {
      style.transform = 'scale(0.45, 1) translateY(44vh) translateX(-96vw)';
      style.transitionDuration = '0.5s';
      style.transitionTimingFunction = 'cubic-bezier(0.18, 0.89, 0.32, 1.28)';
      style.zIndex = '4000';
      style.backgroundColor = 'white';
    }

    if (notification.status === STATUS_DELETING) {
      style.transform = 'translateX(-30vw)';
      style.transitionDuration = '0.3s';
      style.transitionTimingFunction = 'linear';
      style.zIndex = '4000';
      style.backgroundColor = 'white';
    }

    return (
      <div style={style} className={getNotificationClass(notification)}>
        <p> {notification.message} </p>
      </div>
    );
  }
}

export default Notification;
