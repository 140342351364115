(function (t) {
// fr
t.add("asset.homepage", "Page d'accueil de l'actif", "asset\u002Dasset", "fr");
t.add("IN_PROGRESS", "En cours", "asset\u002Dasset", "fr");
t.add("PROMISE", "Promesse", "asset\u002Dasset", "fr");
t.add("SIGNED", "Sign\u00e9", "asset\u002Dasset", "fr");
t.add("STAND_BY", "Stand by", "asset\u002Dasset", "fr");
t.add("asset.list", "Liste des actifs", "asset\u002Dasset", "fr");
t.add("asset.new", "Cr\u00e9ation d'un actif", "asset\u002Dasset", "fr");
t.add("asset.show", "D\u00e9tail d'un actif", "asset\u002Dasset", "fr");
t.add("asset.details", "D\u00e9tail de l'actif", "asset\u002Dasset", "fr");
t.add("asset.show_detail_action", "Voir la fiche d\u00e9taill\u00e9e", "asset\u002Dasset", "fr");
t.add("assets", "Actifs", "asset\u002Dasset", "fr");
t.add("list", "Liste", "asset\u002Dasset", "fr");
t.add("new", "Cr\u00e9ation", "asset\u002Dasset", "fr");
t.add("show", "D\u00e9tail", "asset\u002Dasset", "fr");
t.add("asset", "Actif", "asset\u002Dasset", "fr");
t.add("no_associated_buyer_group", "Pas de groupes acqu\u00e9reurs associ\u00e9s", "asset\u002Dasset", "fr");
t.add("no_associated_client_group", "Pas de groupe client associ\u00e9", "asset\u002Dasset", "fr");
t.add("asset.associatedBuyerGroup", "Groupes Acqu\u00e9reurs associ\u00e9s", "asset\u002Dasset", "fr");
t.add("asset.associatedClientGroup", "Groupe Client associ\u00e9", "asset\u002Dasset", "fr");
t.add("asset.assetNumber", "Nombre d'actif", "asset\u002Dasset", "fr");
t.add("name", "Nom de l'actif", "asset\u002Dasset", "fr");
t.add("address", "Adresse", "asset\u002Dasset", "fr");
t.add("zipCode", "Code postal", "asset\u002Dasset", "fr");
t.add("town", "Ville", "asset\u002Dasset", "fr");
t.add("country", "Pays", "asset\u002Dasset", "fr");
t.add("referenceCadastralPlan", "R\u00e9f\u00e9rences cadastrales", "asset\u002Dasset", "fr");
t.add("referenceCondominiums", "Num\u00e9ros de lots de copropri\u00e9t\u00e9", "asset\u002Dasset", "fr");
t.add("referenceVolumeLots", "Num\u00e9ros de lots de volumes", "asset\u002Dasset", "fr");
t.add("referenceNote", "R\u00e9f\u00e9rence Inot", "asset\u002Dasset", "fr");
t.add("status", "Statut", "asset\u002Dasset", "fr");
t.add("signatureDate", "Date de signature", "asset\u002Dasset", "fr");
t.add("saleAgreementDate", "Date de promesse de vente", "asset\u002Dasset", "fr");
t.add("endDate", "Date de fin de dossier", "asset\u002Dasset", "fr");
t.add("asset.new.form_title", "Cr\u00e9ation d'un Actif", "asset\u002Dasset", "fr");
t.add("identity", "Identit\u00e9", "asset\u002Dasset", "fr");
t.add("adress", "Adresse", "asset\u002Dasset", "fr");
t.add("otherInformations", "Autres informations du dossier", "asset\u002Dasset", "fr");
t.add("asset.create.submit", "Cr\u00e9er l'actif", "asset\u002Dasset", "fr");
t.add("dataroomModel", "Mod\u00e8le de dataroom", "asset\u002Dasset", "fr");
t.add("asset.create", "Cr\u00e9er un actif", "asset\u002Dasset", "fr");
t.add("asset.export", "Exporter la recherche", "asset\u002Dasset", "fr");
t.add("asset.search.placeholder", "Nom, Identifiant, Principal client, Ville\u2026", "asset\u002Dasset", "fr");
t.add("SEARCH_DATA_ROOM_CLIENT", "Actifs ayant un %begin_html_tag% groupe client ouvert %end_html_tag%", "asset\u002Dasset", "fr");
t.add("SEARCH_DATA_ROOM_BUYER", "Actifs ayant un %begin_html_tag% groupe acqu\u00e9reur ouvert %end_html_tag%", "asset\u002Dasset", "fr");
t.add("SEARCH_ARCHIVED", "Actifs %begin_html_tag% archiv\u00e9s %end_html_tag%", "asset\u002Dasset", "fr");
t.add("SEARCH_DELETED", "Actifs %begin_html_tag% supprim\u00e9s %end_html_tag%", "asset\u002Dasset", "fr");
t.add("allAssets", "%begin_html_tag% Tous %end_html_tag% les actifs", "asset\u002Dasset", "fr");
t.add("asset.add_favorite_action", "Ajouter aux favoris", "asset\u002Dasset", "fr");
t.add("asset.remove_favorite_action", "Retirer aux favoris", "asset\u002Dasset", "fr");
t.add("asset.edit_action", "Modifier l'actif", "asset\u002Dasset", "fr");
t.add("asset.archive_action", "Archiver l'actif", "asset\u002Dasset", "fr");
t.add("asset.add_new_client_group", "Cr\u00e9er un nouveau <br \/>groupe client", "asset\u002Dasset", "fr");
t.add("asset.add_existing_client_group", "S\u00e9lectionner un <br \/>groupe client existant", "asset\u002Dasset", "fr");
t.add("asset.add_new_buyer_group", "Cr\u00e9er un nouveau <br \/>groupe acqu\u00e9reur", "asset\u002Dasset", "fr");
t.add("asset.add_existing_buyer_group", "S\u00e9lectionner un <br \/>groupe acqu\u00e9reur existant", "asset\u002Dasset", "fr");
t.add("asset.favorite", "Favori", "asset\u002Dasset", "fr");
t.add("asset.archive", "Archiv\u00e9", "asset\u002Dasset", "fr");
t.add("dataroom_client_name", "Nom de la Dataroom", "asset\u002Dasset", "fr");
t.add("dataroom_client_open", "Etat de la Dataroom", "asset\u002Dasset", "fr");
t.add("asset.buyer_group", "Groupe acqu\u00e9reur", "asset\u002Dasset", "fr");
t.add("createdAt", "Date de cr\u00e9ation", "asset\u002Dasset", "fr");
t.add("mainClientName", "Nom du client", "asset\u002Dasset", "fr");
t.add("wallet_name", "Nom du portefeuille", "asset\u002Dasset", "fr");
t.add("client_group", "Groupe client", "asset\u002Dasset", "fr");
t.add("buyer_groups", "Groupes acqu\u00e9reurs", "asset\u002Dasset", "fr");
t.add("client_group_name", "Nom du groupe client", "asset\u002Dasset", "fr");
t.add("buyer_group_name", "Nom du groupe acqu\u00e9reur", "asset\u002Dasset", "fr");
t.add("client_group_state", "Etat du groupe client", "asset\u002Dasset", "fr");
t.add("members", "Membres", "asset\u002Dasset", "fr");
t.add("buyer_group_state", "Etat du groupe acqu\u00e9reur", "asset\u002Dasset", "fr");
t.add("asset.open_dataroom_buyer", "Ouvrir le tableau documentaire", "asset\u002Dasset", "fr");
t.add("asset.close_dataroom_buyer", "Fermer le tableau documentaire", "asset\u002Dasset", "fr");
t.add("edit.form_title", "Edition d'un actif", "asset\u002Dasset", "fr");
t.add("edit.submit", "Enregistrer", "asset\u002Dasset", "fr");
t.add("edit", "Edition d'un actif", "asset\u002Dasset", "fr");
t.add("asset.unarchive_action", "D\u00e9sarchiver l'actif", "asset\u002Dasset", "fr");
t.add("asset.show_client_dataroom", "Voir la dataroom client", "asset\u002Dasset", "fr");
t.add("asset.client_dataroom", "Dataroom Client", "asset\u002Dasset", "fr");
t.add("asset.field_name", "Nom du champ", "asset\u002Dasset", "fr");
t.add("asset.old_value", "Ancienne %new_line% valeur", "asset\u002Dasset", "fr");
t.add("asset.new_value", "Nouvelle %new_line% valeur", "asset\u002Dasset", "fr");
t.add("state", "Etat", "asset\u002Dasset", "fr");
t.add("asset.connect_as_profil", "Se connecter avec ce profil", "asset\u002Dasset", "fr");
t.add("group", "Groupe", "asset\u002Dasset", "fr");
t.add("groups", "Groupes", "asset\u002Dasset", "fr");
t.add("client", "client", "asset\u002Dasset", "fr");
t.add("buyer", "acqu\u00e9reur", "asset\u002Dasset", "fr");
t.add("buyers", "acqu\u00e9reurs", "asset\u002Dasset", "fr");
t.add("dataroom", "Dataroom", "asset\u002Dasset", "fr");
t.add("collaborators", "collaborateurs", "asset\u002Dasset", "fr");
t.add("th_buyer_group", "Groupe(s)<span class=\"visible-xs-inline\"><br \/><\/span> acqu\u00e9reur(s)", "asset\u002Dasset", "fr");
t.add("th_connect_as", "Se connecter<span class=\"visible-xs-inline\"><br \/><\/span> avec ce profil ", "asset\u002Dasset", "fr");
t.add("th_last_opened", "Derni\u00e8re<span class=\"visible-xs-inline\"><br \/><\/span> ouverture ", "asset\u002Dasset", "fr");
t.add("th_last_closed", "Derni\u00e8re<span class=\"visible-xs-inline\"><br \/><\/span> fermeture", "asset\u002Dasset", "fr");
t.add("th_access_dataroom", "Acc\u00e8s \u00e0<span class=\"visible-xs-inline\"><br \/><\/span> la dataroom<span class=\"visible-xs-inline\"><br \/><\/span>", "asset\u002Dasset", "fr");
t.add("th_detach_asset", "D\u00e9tacher<span class=\"visible-xs-inline\"><br \/><\/span> ce groupe<span class=\"visible-xs-inline\"><br \/><\/span> de l'actif&nbsp;? ", "asset\u002Dasset", "fr");
t.add("add_client_group.form_title", "Ajouter un groupe client existant", "asset\u002Dasset", "fr");
t.add("add_buyer_group.form_title", "Ajouter un groupe acqu\u00e9reur existant", "asset\u002Dasset", "fr");
t.add("groupToAdd", "Groupe \u00e0 ajouter", "asset\u002Dasset", "fr");
t.add("STEP_WALLET_EXISTING", "Choix du portefeuille", "asset\u002Dasset", "fr");
t.add("STEP_WALLET_NEW", "Cr\u00e9ation du portefeuille", "asset\u002Dasset", "fr");
t.add("STEP_ASSET", "Cr\u00e9ation de l'actif", "asset\u002Dasset", "fr");
t.add("STEP_WALLET_TYPE", "Association au portefeuille", "asset\u002Dasset", "fr");
t.add("STEP_ASSETS_CHOICE", "Action sur les actifs", "asset\u002Dasset", "fr");
t.add("action_choices_assets", "Quels actions souhaitez vous faire sur les actifs s\u00e9l\u00e9ctionn\u00e9s", "asset\u002Dasset", "fr");
t.add("add_group", "Ajouter un groupe", "asset\u002Dasset", "fr");
t.add("SEARCH_FAVORITE", "Actifs %begin_html_tag% favoris  %end_html_tag%", "asset\u002Dasset", "fr");
t.add("toTaxDeedDate", "Acte pass\u00e9 \u00e0 la taxe (compta)", "asset\u002Dasset", "fr");
t.add("formalitiesDeedDate", "Acte pass\u00e9 aux formalit\u00e9s (forma)", "asset\u002Dasset", "fr");
t.add("asset.referent", "R\u00e9f\u00e9rent", "asset\u002Dasset", "fr");
t.add("accountNumber", "Num\u00e9ro de compte comptable", "asset\u002Dasset", "fr");
t.add("reference.notice", "Les r\u00e9f\u00e9rences doivent \u00eatre s\u00e9par\u00e9es par un \"\/\"", "asset\u002Dasset", "fr");
t.add("asset.buyer_dataroom", "Dataroom Acqu\u00e9reur", "asset\u002Dasset", "fr");
t.add("create_asset", "Creation d'un actif", "asset\u002Dasset", "fr");
t.add("addresses", "Adresses", "asset\u002Dasset", "fr");
t.add("asset.go_back_wallet", "Retour vers le portefeuille", "asset\u002Dasset", "fr");
t.add("edit_referent", "Modifier le r\u00e9f\u00e9rent", "asset\u002Dasset", "fr");
t.add("detach_group", "D\u00e9tacher le groupr", "asset\u002Dasset", "fr");
t.add("STEP_GROUP_CHOICE", "Choix du groupe", "asset\u002Dasset", "fr");
t.add("or_create_new_group", "Ou cr\u00e9er un nouveau groupe", "asset\u002Dasset", "fr");
t.add("asset_with_name_exist", "Attention: un actif ayant ce nom existe d\u00e9ja !", "asset\u002Dasset", "fr");
t.add("saleAgreementDateComment", "Commentaire", "asset\u002Dasset", "fr");
t.add("wallet_client", "Client du portefeuille", "asset\u002Dasset", "fr");
t.add("designation", "D\u00e9signation \/ r\u00e9f\u00e9rence actif", "asset\u002Dasset", "fr");
t.add("add_references_question", "Ajouter des r\u00e9f\u00e9rences de lots ?", "asset\u002Dasset", "fr");
t.add("add_references_condominiums", " \u2026 des r\u00e9f\u00e9rences de lots de copropri\u00e9t\u00e9", "asset\u002Dasset", "fr");
t.add("add_references_volume_lots", "\u2026 des r\u00e9f\u00e9rences de lots de volumes", "asset\u002Dasset", "fr");
t.add("add_references_both", "\u2026 les deux", "asset\u002Dasset", "fr");
t.add("CREATED", "En cours", "asset\u002Dasset", "fr");
t.add("ARCHIVED", "Archiv\u00e9", "asset\u002Dasset", "fr");
t.add("DELETED", "Supprim\u00e9", "asset\u002Dasset", "fr");
t.add("asset.delete_action", "Supprimer l'actif", "asset\u002Dasset", "fr");
t.add("asset.delete_note", "** Fonctionnalit\u00e9 disponible lorsque l'actif n'est pas supprim\u00e9, qu'il n'a plus de dataroom manipulable et que tous les liens avec des groupes sont d\u00e9sactiv\u00e9s", "asset\u002Dasset", "fr");
t.add("DATAROOM_MODEL_CORPORATE", "Share Deal", "asset\u002Dasset", "fr");
t.add("DATAROOM_MODEL_FUNDING_NOTARY_CREDITOR", "Financement (Notaire Cr\u00e9ancier)", "asset\u002Dasset", "fr");
t.add("DATAROOM_MODEL_FUNDING_NOTARY_BORROWER", "Financement (Notaire Emprunteur)", "asset\u002Dasset", "fr");
t.add("DATAROOM_MODEL_GENERAL", "G\u00e9n\u00e9ral", "asset\u002Dasset", "fr");
t.add("DATAROOM_MODEL_HOTEL_BUSINESS", "H\u00f4tel-Fond", "asset\u002Dasset", "fr");
t.add("DATAROOM_MODEL_HOTEL_WALL", "H\u00f4tel-Murs", "asset\u002Dasset", "fr");
t.add("DATAROOM_MODEL_PARIS_MIXED", "Paris-Mixte", "asset\u002Dasset", "fr");
t.add("DATAROOM_MODEL_SUCCESSION", "Succession", "asset\u002Dasset", "fr");
t.add("DATAROOM_MODEL_EMPTY", "Vide", "asset\u002Dasset", "fr");
t.add("th_access_datarooms", "Acc\u00e8s aux<span class=\"visible-xs-inline\"><br \/><\/span> dataroom<span class=\"visible-xs-inline\"><br \/><\/span>", "asset\u002Dasset", "fr");
t.add("asset.third_not_attached_wallet", "Les tiers ne sont pas ratach\u00e9s au portefeuille", "asset\u002Dasset", "fr");
t.add("asset.associatedBuyerDataroom", "Datarooms Acqu\u00e9reurs associ\u00e9s", "asset\u002Dasset", "fr");
t.add("no_associated_dataroom", "Aucune Dataroom", "asset\u002Dasset", "fr");
t.add("analyticsCode", "Code analytique", "asset\u002Dasset", "fr");
t.add("analytics_code.list", "Liste des codes analytiques", "asset\u002Dasset", "fr");
t.add("analytics_code.create", "Cr\u00e9er un code analytique", "asset\u002Dasset", "fr");
t.add("analytic_code.search.placeholder", "Raison social, code", "asset\u002Dasset", "fr");
t.add("analytic_code.show", "D\u00e9tail d'un code analytique", "asset\u002Dasset", "fr");
t.add("analytic_code.edit_action", "Modifier le code analytique", "asset\u002Dasset", "fr");
t.add("analytic_code.delete_action", "Supprimer le code analytique", "asset\u002Dasset", "fr");
t.add("analytic_code.social_reason", "Raison social", "asset\u002Dasset", "fr");
t.add("analytic_code.code", "Code", "asset\u002Dasset", "fr");
t.add("analytic_code.form_title", "Edition d'un code analytique", "asset\u002Dasset", "fr");
t.add("analytic_code.form_title.register", "Cr\u00e9ation d'un code analytique", "asset\u002Dasset", "fr");
t.add("analytics_code.added", "Le code analytique a \u00e9t\u00e9 ajout\u00e9", "asset\u002Dasset", "fr");
t.add("analyticsCode.no_result", "Pas de code analytique associ\u00e9", "asset\u002Dasset", "fr");
t.add("importAssets", "Importer des actifs en masse", "asset\u002Dasset", "fr");
t.add("asset.my_favorite", "Mes actifs Favoris", "asset\u002Dasset", "fr");
t.add("asset.comments_total", "commentaires au total", "asset\u002Dasset", "fr");
t.add("asset.search", "Rechercher des actifs", "asset\u002Dasset", "fr");
})(Translator);
