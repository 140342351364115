export const clipBoardKey = 'dataroomCopiedNodeId';

class ClipBoardRepository {
  subscribe(onNext) {
    window.addEventListener('storage', e => {
      if (e.key === clipBoardKey) {
        onNext(this.getClipBoard());
      }
    });
  }

  setClipBoard(type, nodeIds, dataroomId) {
    localStorage.setItem(
      clipBoardKey,
      JSON.stringify({ type, nodeIds, dataroomId })
    );
  }

  getClipBoard() {
    const item = localStorage.getItem(clipBoardKey);
    if (!item) {
      return {
        type: null,
        nodeIds: null,
        dataroomId: null,
      };
    }

    return JSON.parse(localStorage.getItem(clipBoardKey));
  }

  resetClipBoard() {
    localStorage.removeItem(clipBoardKey);
  }
}

export default new ClipBoardRepository();
