import React from 'react';
import styled from 'styled-components';
import { Asterisk } from './Label';

const CheckboxLabel = styled.label`
  display: ${props => (props.displayBlock ? 'flex' : 'inline-flex')};
  font-weight: ${props => (props.isLabelBold ? 'bold' : 'inherit')};
  break-inside: avoid;
  border-style: solid;
  border-width: 1px;
  border-color: transparent;
  padding: 0em 0.5em;
  font-family: ${props => props.theme.bodyFont};
  font-size: 1em;
  background-color: ${props => {
    if (props.isDisabled) {
      return props.theme.lightGreyColor;
    }
    if (props.hasError) {
      return props.theme.dangerLightColor;
    }
    return 'transparent';
  }};
  color: ${props => {
    if (props.hasError) {
      return props.theme.dangerColor;
    }
    if (props.labelColor) {
      return props.theme[props.labelColor];
    }
    return props.theme.darkColor;
  }};
  flex: 1; /* makes every [label + checkbox] the same width when isHorizontal is used */
  flex-shrink: 0;
  cursor: ${props => (props.isDisabled ? 'not-allowed' : 'pointer')};
  ${props => (props.flexWrap ? 'flex-wrap: ' + props.flexWrap + ';' : null)}
  ${props =>
    props.justifyContent
      ? 'justify-content: ' + props.justifyContent + ';'
      : null}
  ${props =>
    props.alignItems ? 'align-items: ' + props.alignItems + ';' : null}
`;
const CheckboxContainer = styled.span`
  display: inline-block;
  margin-right: ${props =>
    props.inputCheckboxMarginRight ? props.inputCheckboxMarginRight : '0.75em'};
`;
const Input = styled.input`
  margin: 0;
  padding: 0;
`;

class MultipleCheckbox extends React.Component {
  render() {
    return (
      <CheckboxLabel
        htmlFor={this.props.name}
        marginBottom0={this.props.marginBottom0}
        isDisabled={this.props.isDisabled}
        hasError={this.props.errors}
        readOnly={this.props.readOnly}
        displayBlock={this.props.displayBlock}
        isLabelBold={this.props.isLabelBold}
        labelColor={this.props.labelColor}
        flexWrap={this.props.flexWrap}
        justifyContent={this.props.justifyContent}
        alignItems={this.props.alignItems}
      >
        <CheckboxContainer
          inputCheckboxMarginRight={this.props.inputCheckboxMarginRight}
        >
          <Input
            type="checkbox"
            id={this.props.name}
            name={this.props.name}
            ref={this.props.forwardedRef}
            value={this.props.value}
            readOnly={true}
            disabled={this.props.disabled}
            checked={this.props.isChecked}
            onChange={this.props.onChange}
            onBlur={this.props.onBlur}
            defaultChecked={this.props.defaultChecked}
          />
        </CheckboxContainer>
        <span>
          {this.props.label} {this.props.isRequired && <Asterisk>*</Asterisk>}
        </span>
      </CheckboxLabel>
    );
  }
}

export default React.forwardRef((props, ref) => (
  <MultipleCheckbox forwardedRef={ref} {...props} />
));
