import React from 'react';
import Svg from './Svg';

export default function IconCalendar(props) {
  return (
    <Svg
      {...props}
      d="M45.479,21.86l0,-13.86l14.134,0l-0,13.86l35.673,-0l0,-13.86l14.134,0l0,13.86l35.674,-0l-0,-13.86l14.133,0l0,13.86l35.674,-0l0,-13.86l14.134,0l-0,13.86l23.04,-0l-0,226.14l-208.15,-0l0,-226.14l21.554,-0Zm163.556,14.133l-0,13.86l-14.134,-0l0,-13.86l-35.674,0l0,13.86l-14.133,-0l-0,-13.86l-35.674,0l0,13.86l-14.134,-0l0,-13.86l-35.673,0l-0,13.86l-14.134,-0l0,-13.86l-7.42,0l0,197.873l179.882,0l-0,-197.873l-8.906,0Zm-4.734,140.875l-42.402,-0l0,42.401l42.402,-0l-0,-42.401Zm-109.739,-0l-42.402,-0l0,42.401l42.402,-0l-0,-42.401Zm54.869,-0l-42.401,-0l-0,42.401l42.401,-0l0,-42.401Zm-14.134,14.133l-14.134,0l0,14.134l14.134,0l0,-14.134Zm54.87,0l-14.134,0l-0,14.134l14.134,0l-0,-14.134Zm-109.739,0l-14.134,0l-0,14.134l14.134,0l-0,-14.134Zm69.003,-69.506l-42.401,-0l-0,42.401l42.401,0l0,-42.401Zm54.87,-0l-42.402,-0l0,42.401l42.402,0l-0,-42.401Zm-109.739,-0l-42.402,-0l0,42.401l42.402,0l-0,-42.401Zm40.735,14.134l-14.134,-0l0,14.134l14.134,-0l0,-14.134Zm54.87,-0l-14.134,-0l-0,14.134l14.134,-0l-0,-14.134Zm-109.739,-0l-14.134,-0l-0,14.134l14.134,-0l-0,-14.134Zm14.134,-69.507l-42.402,0l0,42.402l42.402,-0l-0,-42.402Zm109.739,0l-42.402,0l0,42.402l42.402,-0l-0,-42.402Zm-54.87,0l-42.401,0l-0,42.402l42.401,-0l0,-42.402Zm-69.003,14.134l-14.134,0l-0,14.134l14.134,0l-0,-14.134Zm109.739,0l-14.134,0l-0,14.134l14.134,0l-0,-14.134Zm-54.87,0l-14.134,0l0,14.134l14.134,0l0,-14.134Z"
    />
  );
}
