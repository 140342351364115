import React from 'react';
import Svg from './Svg';

export default function IconLockLocked(props) {
  return (
    <Svg
      {...props}
      d="M74 128V86c0-29.803 24.197-54 54-54s54 24.197 54 54v42h22v96H52v-96h22zm96 0V86c0-23.18-18.82-42-42-42S86 62.82 86 86v42h84z"
    />
  );
}
