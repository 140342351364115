import React, { Fragment } from 'react';
import 'translations/messages/fr';
import 'translations/asset-asset/fr';
import P from '../../../presentational/components/P';
import { ConfirmationModal } from '../../../app/components/ConfirmationModal';
import Ul from '../../../presentational/components/Ul';
import Li from '../../../presentational/components/Li';
import useApiClient from '../../../app/hooks/apiClient';
import useNotification from '../../../app/hooks/notification';
import { MODE_DELETE_USER } from '../../pages/User';

export function DeleteUserModal(props) {
  const { onSendNotificationReminder } = useNotification();
  const apiClient = useApiClient();

  const { open, sending, mode, data } = props.modal;

  return (
    <Fragment>
      {mode === MODE_DELETE_USER && (
        <ConfirmationModal
          title="Suppression de la dataroom"
          onClose={() => props.onClose()}
          onValidate={async () => {
            const response = await apiClient(`/api/user/${data.id}`, {
              method: 'DELETE',
            });

            props.onClose();

            if (response.ok) {
              props.reload();
              onSendNotificationReminder("L'utilisateur a été supprimé");
            }
          }}
          sending={sending}
          open={open}
        >
          <P>
            Vous êtes sur le point de supprimer l'utilisateur suivant. L'action
            est irréversible.
          </P>
          <Ul>
            <Li key={1}>
              {data.firstName} {data.lastName.toUpperCase()}
            </Li>
          </Ul>
        </ConfirmationModal>
      )}
    </Fragment>
  );
}
