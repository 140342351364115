import { createSelector } from 'reselect';
import { TYPE_DOCUMENT, TYPE_FOLDER } from '../documents/type';
import pickedNodesSelector from './pickedNodesSelector';
import selectedNodesInContextMenu from './selectedNodesInContextMenu';

const canChangeType = nodes => {
  if (nodes.length === 0) {
    return false;
  }
  let previousNodeType = nodes[0].type;
  for (let $i = 0; $i < nodes.length; $i++) {
    if (nodes[$i].path === '0') {
      return false;
    }
    if (nodes[$i].type !== previousNodeType) {
      return false;
    }
    if (
      (nodes[$i].type === TYPE_FOLDER && nodes[$i].childIds.length !== 0) ||
      (nodes[$i].type === TYPE_DOCUMENT && nodes[$i].file)
    ) {
      return false;
    }
  }
  return true;
};

export const canChangeTypeForPickedNodes = createSelector(
  [pickedNodesSelector],
  canChangeType
);

export const canChangeTypeForSelectedNodesInContextMenu = createSelector(
  [selectedNodesInContextMenu],
  canChangeType
);
