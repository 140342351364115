import React, { Component } from 'react';

const allWordsAreUppercase = words => {
  for (let word of words) {
    if (word.length === 0) {
      return true;
    }
    if (word !== word.toUpperCase()) {
      return false;
    }
  }

  return true;
};

const allWordsHaveFirstLetterUppercase = words => {
  for (let word of words) {
    if (word.length === 0) {
      return true;
    }

    if (word[0] !== word[0].toUpperCase()) {
      return false;
    }
  }

  return true;
};

const transformWords = words => {
  if (allWordsAreUppercase(words)) {
    return words.map(word => word.toLowerCase());
  }

  if (allWordsHaveFirstLetterUppercase(words)) {
    return words.map(word => word.toUpperCase());
  }

  return words.map(word => {
    if (word.length === 0) {
      return '';
    }

    return `${word[0].toUpperCase()}${word.substring(1).toLowerCase()}`;
  });
};

const getAllSpaceIndexFromName = name => {
  const indexes = [];

  for (let i = 0; i < name.length; i++) {
    if (name[i] === ' ') {
      indexes.push(i);
    }
  }

  return indexes;
};

const findBeginAndEndForUpperCase = (
  indexes,
  selectionStart,
  selectionEnd,
  stringLength
) => {
  let beginString = 0;
  let endString = null;

  for (let index of indexes) {
    if (index <= selectionStart) {
      beginString = index + 1;
    }

    if (index >= selectionEnd) {
      endString = index;
      break;
    }
  }

  if (!endString) {
    endString = stringLength;
  }

  return {
    beginString,
    endString,
  };
};

const upperCaseLine = (line, selectionStart, selectionEnd) => {
  const spaceChars = [' ', '\n', '\t'];
  if (selectionStart === selectionEnd) {
    if (selectionStart === 0) {
      return line;
    }

    if (selectionStart === line.length) {
      return line;
    }

    if (
      spaceChars.indexOf(line[selectionStart - 1]) !== -1 ||
      spaceChars.indexOf(line[selectionEnd]) !== -1
    ) {
      return line;
    }
  }

  if (
    selectionStart !== selectionEnd &&
    line.substring(selectionStart, selectionEnd).trim().length === 0
  ) {
    return line;
  }

  const indexes = getAllSpaceIndexFromName(line);
  const { beginString, endString } = findBeginAndEndForUpperCase(
    indexes,
    selectionStart,
    selectionEnd,
    line.length
  );

  const stringsToUppercase = line.substring(beginString, endString);

  const words = stringsToUppercase.split(' ');

  const transformedWords = transformWords(words);

  return `${line.substring(0, beginString)}${transformedWords.join(
    ' '
  )}${line.substring(endString)}`;
};

export const upperCaseSelection = (name, inputRef) => {
  const { selectionEnd, selectionStart } = inputRef.current;

  const lines = name.split('\n');
  let lineOffset = 0;

  const transformedLines = lines.map(line => {
    let lineSelectionStart = selectionStart - lineOffset;
    let lineSelectionEnd = selectionEnd - lineOffset;

    if (lineSelectionEnd <= 0) {
      return line;
    }

    if (lineSelectionStart <= 0) {
      lineSelectionStart = 0;
    }

    if (lineSelectionEnd > line.length) {
      lineSelectionEnd = line.length;
    }

    lineOffset += line.length;

    return upperCaseLine(line, lineSelectionStart, lineSelectionEnd);
  });

  return {
    name: transformedLines.join('\n'),
    afterUpdate: () => {
      inputRef.current.setSelectionRange(selectionStart, selectionEnd);
    },
  };
};

class UpperCaseNodeNameButton extends Component {
  render() {
    return (
      <span className="input-group-btn">
        <button
          tabIndex="-1"
          type="button"
          className="btn btn-default"
          title="Passer le nom en majuscules"
          onMouseDown={this.props.upperCase}
          disabled={this.props.submitting}
        >
          {' '}
          <span className="glyphicon glyphicon-font" />{' '}
          <span className="sr-only">Passer en majuscules</span>{' '}
        </button>
      </span>
    );
  }
}

export default UpperCaseNodeNameButton;
