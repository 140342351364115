import updatePath from './updatePath';
import getPathLevel from './getPathLevel';

export const BEFORE = 'BEFORE';
export const INSIDE = 'INSIDE';
export const AFTER = 'AFTER';

export const getNextPathForPath = path =>
  updatePath(getPathLevel(path), path, position => position + 1);

/**
 * get path for nodeId if inserted at given position
 * @param path
 * @param position
 * @returns {string}
 */
export default (path, position) => {
  if (position === BEFORE) {
    return path;
  }

  if (position === AFTER) {
    return getNextPathForPath(path);
  }

  if (position === INSIDE) {
    return path === '0' ? '01' : `${path}.01`;
  }
};
