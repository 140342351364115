import createUndoableActionReducer from '../reducers/createUndoableActionReducer';
import { uniqueId } from 'underscore';
import doSaga from './dataroomActions/doSaga';
import undoSaga from './dataroomActions/undoSaga';
import { takeEvery, call, put, fork, cancelled } from 'redux-saga/effects';
import uploader from 'js/dataroom/core/uploader.js';
import addDocumentNumber, {
  addDocumentNumberWithoutFile,
} from './utils/addDocumentNumber';
import { previableExtensions } from '../../document/actions/document.js';
import { buffers, channel, delay } from 'redux-saga';
import { getTotalChunkForDocument } from '../../core/uploader';
import {
  FILE_UPLOAD_PROGRESS,
  handleFileUploadedProgess,
  uploadDocument,
} from './uploader';
import { MAX_RETRY } from './addFiles';

export const ATTACH_FILE_TO_DOCUMENT = 'ATTACH_FILE_TO_DOCUMENT';
export const FILE_NOT_ALLOWED = 'ATTACH_FILE_NOT_ALLOWEDT';
export const OPEN_MODAL = 'OPEN_ATTACH_FILE_MODAL';
export const HIDE_MODAL = 'HIDE_ATTACH_FILE_MODAL';
export const FILE_ALREADY_UPLOADING = 'FILE_ALREADY_UPLOADING';
export const CANT_ATTACH_MULTIPLE_FILES_TO_DOCUMENT =
  'CANT_ATTACH_MULTIPLE_FILES_TO_DOCUMENT';

export const OPEN_MODAL_CONFIRM_ATTACH_FILE_DOCUMENT_HAVING_FILE =
  'OPEN_MODAL_CONFIRM_ATTACH_FILE_DOCUMENT_HAVING_FILE';
export const CANCEL_ATTACH_FILE_DOCUMENT_HAVING_FILE =
  'CANCEL_ATTACH_FILE_DOCUMENT_HAVING_FILE';
export const CONFIRM_ATTACH_FILE_DOCUMENT_HAVING_FILE =
  'CONFIRM_ATTACH_FILE_DOCUMENT_HAVING_FILE';

export const cantAttachMultipleFilesToDocument = () => ({
  type: CANT_ATTACH_MULTIPLE_FILES_TO_DOCUMENT,
});

export const attachFileToDocumentAction = (nodeId, file) => ({
  type: ATTACH_FILE_TO_DOCUMENT,
  nodeId,
  file,
  frontActionId: uniqueId('dataroom_action'),
});

export const fileAlreadyUploadingAction = node => ({
  type: FILE_ALREADY_UPLOADING,
  name: node.name,
});

export const fileNotAllowedAction = fileName => ({
  type: FILE_NOT_ALLOWED,
  fileName,
});

export const openModalAction = nodeId => ({
  type: OPEN_MODAL,
  nodeId,
});

export const hideModalAction = () => ({
  type: HIDE_MODAL,
});

export const confirmAttachFileToDocumentHavingFileAction = (nodeId, file) => ({
  type: OPEN_MODAL_CONFIRM_ATTACH_FILE_DOCUMENT_HAVING_FILE,
  nodeId,
  file,
});

export const cancelAttachFileAction = () => ({
  type: CANCEL_ATTACH_FILE_DOCUMENT_HAVING_FILE,
});

export const confirmAttachFileAction = () => ({
  type: CONFIRM_ATTACH_FILE_DOCUMENT_HAVING_FILE,
});

function* callAttachFile(file, nodeId, fileUploadProgressChan) {
  let failedCalls = 0;
  let currentOffset = 0;

  while (true) {
    try {
      return yield call(
        uploadDocument,
        file,
        nodeId,
        currentOffset,
        `/api/nodes/${nodeId}/file`,
        fileUploadProgressChan
      );
    } catch (err) {
      if (!err.isUploadError || failedCalls === MAX_RETRY - 1) {
        throw err;
      }

      // set offset for retry last chunk
      currentOffset = err.offset;

      yield call(delay, 1000);
    } finally {
      if (yield cancelled()) {
        // TODO: cancel the request
        // https://github.com/github/fetch/issues/547
        // https://github.com/github/fetch/pull/592
      }
    }
    failedCalls += 1;
  }
}

export function* saga(action) {
  const fileUploadProgressChan = yield call(
    channel,
    buffers.fixed(uploader.getTotalChunkForDocument(action.file))
  );

  yield fork(
    handleFileUploadedProgess,
    fileUploadProgressChan,
    action.frontActionId,
    uploader.getTotalChunkForDocument(action.file)
  );

  return yield call(
    callAttachFile,
    action.file,
    action.nodeId,
    fileUploadProgressChan
  );
}

export function* undoActionSaga() {
  yield takeEvery('UNDO_ATTACH_FILE_TO_DOCUMENT', undoSaga);
}

const removeUpload = (uploads, frontActionId) => {
  const index = uploads.findIndex(
    upload => upload.frontActionId === frontActionId
  );

  if (index === -1) {
    return uploads;
  }

  return [...uploads.slice(0, index), ...uploads.slice(index + 1)];
};

function attachFileToDocument(state, action) {
  const node = state.working.nodes[action.nodeId];

  let extension = action.file.name.substring(
    action.file.name.lastIndexOf('.') + 1
  );

  if (extension) {
    extension = extension.toLowerCase();
  }

  return {
    ...state,
    working: {
      ...state.working,
      attachFileModal: {
        open: false,
        nodeId: null,
      },
      savedNodes: {
        ...state.working.savedNodes,
        [action.frontActionId]: {
          [node.id]: { ...node },
        },
      },
      uploads: [
        ...state.working.uploads,
        {
          frontActionId: action.frontActionId,
          uploadedDocuments: 0,
          uploadingDocuments: 1,
          totalSize: action.file.size,
          uploadedSize: 0,
          progress: [
            {
              nodeId: action.nodeId,
              uploadedSize: 0,
              totalSize: action.file.size,
            },
          ],
        },
      ],
      nodes: {
        ...state.working.nodes,
        [action.nodeId]: {
          ...node,
          uploading: true,
          uploadingFile: {
            size: action.file.size,
            editedAt: new Date(),
            mimetype: action.file.type,
            extension,
            previewStatus:
              previableExtensions.indexOf(extension) !== -1
                ? 'in_progress'
                : null,
          },
          uploadingProgress: {
            uploadedSize: 0,
            totalSize: action.file.size,
          },
        },
      },
    },
  };
}

function undoAttachFileToDocument(state, action) {
  const oldNode = state.working.savedNodes[action.frontActionId][action.nodeId];
  const newNode = state.working.nodes[action.nodeId];
  let newNodes = { ...state.working.nodes };

  if (!newNode.uploading && !oldNode.file) {
    newNodes = addDocumentNumber(newNode.parentId, newNodes, -1);
    newNodes = addDocumentNumberWithoutFile(newNode.parentId, newNodes);
  }

  return {
    ...state,
    working: {
      ...state.working,
      uploads: removeUpload(state.working.uploads, action.frontActionId),
      nodes: {
        ...newNodes,
        [action.nodeId]: {
          ...state.working.nodes[action.nodeId],
          uploading: false,
          uploadingFile: null,
          publicationState: oldNode.publicationState,
          file: oldNode.file,
        },
      },
    },
  };
}

const baseReducer = createUndoableActionReducer(
  ATTACH_FILE_TO_DOCUMENT,
  attachFileToDocument,
  undoAttachFileToDocument
);

export default function(state, action) {
  switch (action.type) {
    case CONFIRM_ATTACH_FILE_DOCUMENT_HAVING_FILE:
    case CANCEL_ATTACH_FILE_DOCUMENT_HAVING_FILE: {
      return {
        ...state,
        working: {
          ...state.working,
          confirmAttachFileModal: {
            open: false,
            nodeId: null,
            file: null,
          },
        },
      };
    }

    case OPEN_MODAL_CONFIRM_ATTACH_FILE_DOCUMENT_HAVING_FILE:
      return {
        ...state,
        working: {
          ...state.working,
          confirmAttachFileModal: {
            open: true,
            nodeId: action.nodeId,
            file: action.file,
          },
        },
      };

    case HIDE_MODAL:
      return {
        ...state,
        working: {
          ...state.working,
          attachFileModal: {
            open: false,
            nodeId: null,
          },
        },
      };
    case OPEN_MODAL:
      return {
        ...state,
        working: {
          ...state.working,
          attachFileModal: {
            open: true,
            nodeId: action.nodeId,
          },
        },
      };
    case 'ATTACH_FILE_TO_DOCUMENT_SUCCESS':
    case 'ATTACH_FILE_TO_DOCUMENT_ERROR': {
      let newState = baseReducer(state, action);
      let newNodes = { ...newState.working.nodes };
      let newNode = {
        ...newState.working.nodes[action.action.nodeId],
        uploading: false,
      };

      if (
        action.type === 'ATTACH_FILE_TO_DOCUMENT_SUCCESS' &&
        newNodes[action.action.nodeId].uploading
      ) {
        if (newState.working.nodes[action.action.nodeId].file === null) {
          newNodes = addDocumentNumber(
            newNodes[action.action.nodeId].parentId,
            newNodes,
            1
          );
          newNodes = addDocumentNumberWithoutFile(
            newNodes[action.action.nodeId].parentId,
            newNodes,
            -1
          );
        }

        newNode.file =
          newState.working.nodes[action.action.nodeId].uploadingFile;
        newNode.uploadingFile = null;
      }

      if (
        action.type === 'ATTACH_FILE_TO_DOCUMENT_SUCCESS' &&
        newNode.publicationState &&
        newNode.publicationState.published
      ) {
        newNode.publicationState = {
          ...newNode.publicationState,
          hasChange: true,
        };
      }

      return {
        ...newState,
        working: {
          ...newState.working,
          uploads: removeUpload(
            newState.working.uploads,
            action.action.frontActionId
          ),
          nodes: {
            ...newNodes,
            [action.action.nodeId]: newNode,
          },
        },
      };
    }
  }

  return baseReducer(state, action);
}
