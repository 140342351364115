import React, { Component } from 'react';
import { Canvas } from '../../../presentational/components/editor/PageViewer';

class PdfCanvas extends Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
    this.state = {
      rendering: false,
    };
  }

  calculateViewport(forceScale, page, rotation) {
    const viewport = page.getViewport(1);
    let scale = this.props.width / viewport.width;

    if (rotation === 90 || rotation === 270) {
      scale = this.props.width / viewport.height;
    }

    return page.getViewport(scale, rotation);
  }

  renderPage(viewPort) {
    const canvas = this.canvasRef.current;
    canvas.height = viewPort.height;
    canvas.width = viewPort.width;

    if (this.state.rendering) {
      return;
    }

    this.setState({
      rendering: true,
    });

    let renderTask = this.props.page.pagePdf.render({
      viewport: viewPort,
      canvasContext: canvas.getContext('2d'),
    });

    renderTask.promise.then(() => {
      this.setState({
        rendering: false,
      });
    });
  }

  componentDidMount() {
    const calculatedViewport = this.calculateViewport(
      false,
      this.props.page.pagePdf
    );

    this.renderPage(calculatedViewport);
  }

  componentWillReceiveProps(nextProps) {
    let page = this.props.page.pagePdf;
    if (this.props.page.rotation !== nextProps.rotate) {
      const currentViewport = this.calculateViewport(
        true,
        page,
        nextProps.rotate
      );

      this.renderPage(currentViewport);
    }
  }

  shouldComponentUpdate() {
    return false;
  }

  render() {
    return (
      <Canvas
        ref={this.canvasRef}
        className="pdf"
        title={this.props.file ? this.props.file.name : null}
        onClick={this.props.handleClick}
        onDoubleClick={this.props.handleDoubleClick}
      />
    );
  }
}

export default PdfCanvas;
