import React, {
  Fragment,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import Creatable from 'react-select';
import queryString from 'qs';
import Label from '../../../../presentational/components/form/Label';
import FieldAndHelper from '../../../../presentational/components/form/FieldAndHelper';
import FieldGroup from '../../../../presentational/components/form/FieldGroup';
import {
  CLIENT_UI_COLOR,
  DARK_GREY_COLOR,
  LIGHT_COLOR,
} from '../../../../presentational/components/theme_blue';
import GridCol from '../../../../presentational/components/GridCol';
import Button from '../../../../presentational/components/Button';
import GridRow from '../../../../presentational/components/GridRow';

export async function loadSelectItemsFromApi(
  abortController,
  inputValue,
  page = 1,
  url,
  apiClient,
  normalizer,
  baseUrlParameters
) {
  let baseOptions = {};
  if (abortController) {
    baseOptions.signal = abortController.signal;
  }

  if (abortController && abortController.signal.aborted) {
    return;
  }

  let qs = {
    page: page,
    items: 10,
    query: inputValue,
    ...baseUrlParameters,
  };

  let searchResult = null;

  try {
    searchResult = await apiClient(
      `${url}?${queryString.stringify(qs)}`,
      baseOptions
    ).then(response => response.json());
  } catch (error) {
    if (error?.name === 'AbortError') {
      // silently catch abort error error since aborted request are treated as error
      return;
    }

    throw error;
  }

  const loadedItems = searchResult.items.map(item => ({
    item,
    ...normalizer(item),
  }));

  return [loadedItems, searchResult.resultNumber];
}

const initialItemListState = {
  open: false,
  loading: false,
  loadingMore: false,
  loadedItems: [],
  currentPage: 1,
  pageNumber: 1,
};

const LOADING_ITEMS = 'LOADING_ITEMS';
const OPEN_LIST = 'OPEN_LIST';
const CLOSE_LIST = 'CLOSE_LIST';
const ITEMS_LOADED = 'ITEMS_LOADED';
const LOADING_MORE_ITEMS = 'LOADING_MORE_ITEMS';
const LOADED_MORE_ITEMS = 'LOADED_MORE_ITEMS';

function itemListReducer(state, action) {
  switch (action.type) {
    case LOADING_ITEMS:
      return {
        ...state,
        loading: true,
      };
    case OPEN_LIST:
      return {
        ...initialItemListState,
        open: true,
      };
    case CLOSE_LIST:
      return {
        ...initialItemListState,
        open: false,
      };
    case ITEMS_LOADED:
      return {
        ...state,
        loading: false,
        loadedItems: action.loadedItems,
        currentPage: 1,
        pageNumber: action.pageNumber,
      };
    case LOADING_MORE_ITEMS:
      return {
        ...state,
        loadingMore: state.currentPage < state.pageNumber,
        currentPage:
          state.currentPage < state.pageNumber
            ? state.currentPage + 1
            : state.pageNumber,
      };
    case LOADED_MORE_ITEMS:
      return {
        ...state,
        loadingMore: false,
        loadedItems: [...state.loadedItems, ...action.loadedItems],
      };
  }

  return state;
}

export default function Select(props) {
  const { loadSelectItems } = props;
  const [inputValue, setInputValue] = useState('');
  const [state, dispatch] = useReducer(itemListReducer, initialItemListState);

  useEffect(() => {
    if (state.currentPage === 1 || !state.open) {
      return;
    }

    let abortController = null;
    if (typeof AbortController !== 'undefined') {
      abortController = new AbortController();
    }

    async function loadMoreSelectItems() {
      const result = await loadSelectItems(
        abortController,
        inputValue,
        state.currentPage
      );
      if (!result) {
        return;
      }

      const [loadedItems] = result;
      dispatch({
        type: LOADED_MORE_ITEMS,
        loadedItems,
      });
    }

    loadMoreSelectItems();

    return () => {
      if (abortController) {
        abortController.abort();
      }
    };
  }, [state.currentPage, state.open]);

  useEffect(() => {
    if (!state.open) {
      return;
    }

    let abortController = null;
    if (typeof AbortController !== 'undefined') {
      abortController = new AbortController();
    }

    async function loadSelectItemsFirstPage() {
      const result = await loadSelectItems(abortController, inputValue, 1);

      if (!result) {
        return;
      }

      if (abortController?.signal?.aborted) {
        return;
      }

      const [loadedItems, resultNumber] = result;

      dispatch({
        type: ITEMS_LOADED,
        loadedItems,
        pageNumber: Math.ceil(resultNumber / 10),
      });
    }

    dispatch({ type: LOADING_ITEMS });
    loadSelectItemsFirstPage();

    return () => {
      if (abortController) {
        abortController.abort();
      }
    };
  }, [inputValue, state.open]);

  return (
    <Fragment>
      <FieldGroup isHorizontal={props.isHorizontal}>
        <Label
          label={props.label}
          isHorizontal={props.isHorizontal}
          labelColor={DARK_GREY_COLOR}
          isLabelBold
          isRequired={props.isRequired}
          labelTextRight={props.labelTextRight}
        />
        <FieldAndHelper isHorizontal={props.isHorizontal}>
          <Creatable
            isDisabled={props.readOnly}
            openMenuOnFocus={true}
            closeMenuOnSelect={!props.multiple}
            isClearable={true}
            isLoading={state.loading || state.loadingMore}
            placeholder={props.placeholder}
            isMulti={props.multiple}
            value={props.value}
            options={state.loadedItems}
            onMenuClose={() => dispatch({ type: CLOSE_LIST })}
            onMenuOpen={() => dispatch({ type: OPEN_LIST })}
            onChange={newValue => props.onChange(newValue)}
            onInputChange={inputValue => setInputValue(inputValue)}
            getOptionValue={option => option['id']}
            getOptionLabel={option => option['label']}
            noOptionsMessage={() =>
              Translator.transChoice('foundResult', 0, { count: 0 })
            }
            theme={theme => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: '#003a78',
                primary25: '#c5e8fd',
              },
            })}
            onMenuScrollToBottom={() => dispatch({ type: LOADING_MORE_ITEMS })}
          />
        </FieldAndHelper>
      </FieldGroup>
    </Fragment>
  );
}
