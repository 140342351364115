import {
  FETCH_DOCUMENT,
  LOAD_DOCUMENT,
  RESET_DOCUMENT,
  PREVIEW_FINISHED,
  PREVIEW_NOT_FINISHED,
  DISPLAY_CHECKING_PREVIEW,
} from '../actions/document.js';
import { FILE_TAB } from '../components/DocumentTabs.js';
import reduceReducers from 'reduce-reducers';
import { CHECK_PREVIEW_STATUS } from '../actions/document';

const initialState = {
  document: null,
  isFetchingDocument: false,
  selectedTab: FILE_TAB,
  buyerGroupId: null,
  anonymous: false,
  tabsStatus: {
    [FILE_TAB]: {
      hasBeenLoaded: true,
      isCheckingPreview: false,
      displayCheckingPreview: false,
    },
  },
};

const documentReducer = function(state = initialState, action = {}) {
  switch (action.type) {
    case CHECK_PREVIEW_STATUS:
      return {
        ...state,
        tabsStatus: {
          ...state.tabsStatus,
          [FILE_TAB]: {
            ...state.tabsStatus[FILE_TAB],
            isCheckingPreview: true,
          },
        },
      };
    case RESET_DOCUMENT:
      return {
        ...state,
        document: null,
        selectedTab: FILE_TAB,
        tabsStatus: {
          ...state.tabsStatus,
          [FILE_TAB]: {
            ...state.tabsStatus[FILE_TAB],
            isCheckingPreview: false,
            displayCheckingPreview: false,
          },
        },
      };
    case FETCH_DOCUMENT:
      return {
        ...state,
        isFetchingDocument: true,
        anonymous: action.anonymous,
      };
    case LOAD_DOCUMENT:
      if (state.document && state.document.id === action.document.id) {
        return state;
      }

      const document = { ...action.document };
      if (document.file) {
        document.file = {
          ...document.file,
          editedAt:
            typeof document.file.editedAt.getMonth === 'function'
              ? document.file.editedAt
              : new Date(document.file.editedAt * 1000),
        };
      }

      return {
        ...state,
        document,
        tabsStatus: {
          ...state.tabsStatus,
        },
        isFetchingDocument: false,
        anonymous: action.anonymous,
      };
    case DISPLAY_CHECKING_PREVIEW:
      return {
        ...state,
        tabsStatus: {
          ...state.tabsStatus,
          [FILE_TAB]: {
            ...state.tabsStatus[FILE_TAB],
            displayCheckingPreview: true,
          },
        },
      };
    case PREVIEW_NOT_FINISHED:
      return {
        ...state,
        tabsStatus: {
          ...state.tabsStatus,
          [FILE_TAB]: {
            ...state.tabsStatus[FILE_TAB],
            isCheckingPreview: false,
            displayCheckingPreview: true,
          },
        },
      };
    case PREVIEW_FINISHED: {
      if (
        !state.document ||
        !state.document.file ||
        state.document.id !== action.documentId
      ) {
        return state;
      }

      return {
        ...state,
        tabsStatus: {
          ...state.tabsStatus,
          [FILE_TAB]: {
            ...state.tabsStatus[FILE_TAB],
            isCheckingPreview: false,
            displayCheckingPreview: false,
          },
        },
        document: {
          ...state.document,
          file: {
            ...state.document.file,
            previewStatus: action.previewStatus,
          },
        },
      };
    }
  }

  return state;
};

export default reduceReducers(documentReducer);
