import apiClient from 'js/dataroom/core/apiClient';

const MAX_FILE_SIZE = 5242880;

const getTotalChunkForDocument = documentNode =>
  Math.ceil(documentNode.size / MAX_FILE_SIZE);

const doUpload = (url, item, headers, offset = 0) => {
  return apiClient
    .request(
      new Request(url, {
        method: 'POST',
        body: item,
        headers,
      })
    )
    .then(response => {
      if (!response.ok) {
        let error = new Error();
        error.isUploadError = true;
        error.offset = offset;

        throw error;
      }

      return response;
    });
};

const readSlice = item => {
  let fileReader = new FileReader();

  return new Promise((success, reject) => {
    fileReader.onload = () => {
      success(fileReader.result);
    };

    fileReader.onerror = () => {
      reject();
    };

    fileReader.readAsArrayBuffer(item);
  });
};

async function uploadFileWithChunk(url, file) {
  let offset = 0;
  let chunkIndex = 0;
  let totalSplit = Math.ceil(file.size / MAX_FILE_SIZE);
  let lastResponse = null;
  while (offset < file.size) {
    let bytesLeftToUpload = file.size - offset;
    let bytesToUpload =
      bytesLeftToUpload > MAX_FILE_SIZE ? MAX_FILE_SIZE : bytesLeftToUpload;
    let slice = file.slice(offset, offset + bytesToUpload);
    let sliceContent = await readSlice(slice);

    lastResponse = await doUpload(url, sliceContent, {
      'X-CHUNK-TOTAL': totalSplit,
      'X-CHUNK-INDEX': chunkIndex,
      'Content-Type': 'application/octet-stream',
      'X-FILENAME': encodeURI(file.name),
    });

    chunkIndex += 1;
    offset += bytesToUpload;
  }

  return lastResponse;
}

const upload = (url, file) => {
  if (file.size < MAX_FILE_SIZE) {
    return doUpload(url, file, { 'X-FILENAME': encodeURI(file.name) });
  }

  return uploadFileWithChunk(url, file);
};

const uploadChunk = async (url, file, offset) => {
  let isFinished = false;
  let numberOfChunk = Math.ceil(file.size / MAX_FILE_SIZE);
  let currentChunk = Math.ceil(offset / MAX_FILE_SIZE);
  let header = {
    'X-FILENAME': encodeURI(file.name),
  };

  let bytesLeftToUpload = file.size - offset;
  let bytesToUpload =
    bytesLeftToUpload > MAX_FILE_SIZE ? MAX_FILE_SIZE : bytesLeftToUpload;
  let content = file;

  if (file.size > MAX_FILE_SIZE) {
    let slice = file.slice(offset, offset + bytesToUpload);
    let sliceContent = await readSlice(slice);

    header['X-CHUNK-TOTAL'] = numberOfChunk;
    header['X-CHUNK-INDEX'] = currentChunk;
    header['Content-Type'] = 'application/octet-stream';
    content = sliceContent;
  }

  let lastResponse = await doUpload(url, content, header, offset);

  if (numberOfChunk === currentChunk + 1) {
    isFinished = true;
  }

  return {
    isFinished: isFinished,
    offset: bytesToUpload + offset,
    lastResponse: lastResponse,
  };
};

async function uploadDocumentByChunk(
  file,
  index,
  offset,
  url,
  onSuccess = null
) {
  let uploadedStatus = null;
  let isUploadFinished = false;
  let bytesUploaded = 0;

  return new Promise(async (success, reject) => {
    while (!isUploadFinished) {
      uploadedStatus = await uploadChunk(url, file, offset);

      if (uploadedStatus) {
        bytesUploaded = uploadedStatus.offset - offset;
        offset = uploadedStatus.offset;
        isUploadFinished = uploadedStatus.isFinished;

        if (onSuccess) {
          onSuccess(offset);
        }
      }
    }

    const data = await uploadedStatus.lastResponse.json();

    success({
      fileUploadId: data.fileName,
      id: index,
      fileSize: file.size,
      fileName: file.name,
    });
  });
}

export default {
  upload,
  uploadChunk,
  getTotalChunkForDocument,
  uploadDocumentByChunk,
};
