import { createSelector } from 'reselect';
import { once } from 'underscore';

function calculatePercent(uploadingProgress) {
  const percent = Math.ceil(
    (uploadingProgress.uploadedSize / uploadingProgress.totalSize) * 100
  );

  return {
    percent: percent,
    height: (percent * 0.28).toFixed(2),
  };
}

export const calculateUploadingPercentSelector = () => {
  return createSelector(
    [document => document.uploadingProgress],
    uploadingProgress => {
      return calculatePercent(uploadingProgress);
    }
  );
};

export const getUploadingPercentSelector = once(
  calculateUploadingPercentSelector
);
