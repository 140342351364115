import { useAuthDataContext } from './authDataProvider';
import { useCallback, useEffect, useRef, useState } from 'react';

export default function useApiClient() {
  const { onLogout } = useAuthDataContext();

  return (url, options = {}) => {
    return fetch(url, { credentials: 'same-origin', ...options }).then(
      response => {
        if (response.status === 401) {
          onLogout();
          return new Promise(() => {});
        }

        return response;
      }
    );
  };
}

async function fetchData(url, apiClient, setQueryStatus, abortController) {
  let apiClientOptions = {};
  if (abortController) {
    apiClientOptions.signal = abortController.signal;
  }

  setQueryStatus(state => ({
    ...state,
    loading: true,
    loadingId: Math.random()
      .toString(36)
      .substr(2, 9),
  }));

  let response;
  try {
    response = await apiClient(url, apiClientOptions);
  } catch (error) {
    if (error?.name === 'AbortError') {
      // silently catch abort error error since aborted request are treated as error
      return;
    }
  }

  if (response.ok) {
    const data = await response.json();
    setQueryStatus(state => ({
      ...state,
      loaded: true,
      error: false,
      loading: false,
      data,
    }));
    return;
  }

  setQueryStatus(state => ({ ...state, error: true }));
}

export function useQuery(url, loadingKey) {
  const apiClient = useApiClient();

  const [queryStatus, setQueryStatus] = useState({
    loaded: false,
    error: false,
    data: null,
    loading: false,
    loadingId: null,
  });

  const abortControllerRef = useRef();

  const loadData = useCallback(
    abortController => {
      fetchData(url, apiClient, setQueryStatus, abortController);
    },
    [url]
  );

  const resetData = useCallback(() => {
    setQueryStatus({
      loaded: false,
      error: false,
      data: null,
      loading: false,
      loadingId: null,
    });
  }, []);

  useEffect(() => {
    if (typeof AbortController !== 'undefined') {
      abortControllerRef.current = new AbortController();
    }
    loadData(abortControllerRef.current);

    return () => {
      abortControllerRef.current?.abort();
    };
  }, [url, loadingKey]);

  return [
    queryStatus.loaded,
    queryStatus.error,
    queryStatus.data,
    queryStatus.loading,
    loadData,
    queryStatus.loadingId,
    resetData,
  ];
}
