import React from 'react';
import styled from 'styled-components';
import { PICKED_COLOR, PICKED_BACKGROUND_COLOR } from '../theme_blue';

import IconChevronBottomHeavy from '../img/icons/IconChevronBottomHeavy';
import IconChevronTopHeavy from '../img/icons/IconChevronTopHeavy';

const DropAreaPlaceholder = styled.div`
  border: dashed 1px ${props => props.theme[PICKED_COLOR]};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 0 -0.5em 1em;
  padding: 0 0.5em;
  background-color: ${props => props.theme[PICKED_BACKGROUND_COLOR]};
  align-self: stretch;
  color: ${props => props.theme[PICKED_COLOR]};
  width: 0;
  overflow: hidden;
  position: relative;
  visibility: ${props => (props.isDropAreaVisible ? 'visible' : 'hidden')};
`;

export default function DropArea(props) {
  return (
    <DropAreaPlaceholder isDropAreaVisible={props.isDropAreaVisible}>
      <IconChevronBottomHeavy width=".5em" marginTop=".25em" />
      <IconChevronTopHeavy width=".5em" marginBottom=".25em" />
    </DropAreaPlaceholder>
  );
}
