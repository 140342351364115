import React, { Component, Fragment, Suspense } from 'react';
import { connect } from 'react-redux';
import { DragDropContext } from 'react-dnd';
import RootNode from './RootNode';
import Search from '../components/Search';
import {
  resetDataroom,
  startDataroom,
  toggleIsLargeDataroomAction,
  updateSearch,
} from '../actions/dataroom';
import ResizeObserverPolyfill from 'resize-observer-polyfill';
import ResizeObserver from 'resize-observer-polyfill';
import Sticky from '../../lib/react-stickynode/Sticky';
import 'translations/asset-asset/fr';
import Loading from '../../app/components/Loading';
import { connectMenu } from 'react-contextmenu';
import HTML5Backend from '../../lib/react-dnd-html5-backend/src/HTML5Backend';
import ContextMenu from './ContextMenu';
import PdfMerged from '../../pdfMerged/components/PdfMerged';
import ActionBar from './ActionBar';
import DataroomCorruptionInfo from '../components/DataroomCorruptionInfo';
import DataroomActions from './DataroomActions';
import ConfirmAddFilesModal from './ConfirmAddFilesModal';
import UploadingInfo from './UploadingInfo';
import PleaseWait from '../components/PleaseWait';
import { throttle } from 'underscore';
import AttachFileModal from './AttachFileModal';
import ConfirmAttachFileModal from './ConfirmAttachFileModal';
import PdfExplode from '../../pdfExplode/components/PdfExplode';
import ConfirmImportFilesModal from './ConfirmImportFilesModal';
import ConfirmExtractZipModal from './ConfirmExtractZipModal';
import DataroomPdfEditor from '../components/pdf/DataroomPdfEditor';
import editionModeSelectorFromDataRoom from '../selectors/editionModeSelectorFromDataRoom';
import { EDITION_MODE_FORBIDDEN } from '../constants';
import { unLockDataroom } from '../ducks/lock';

const ConnectedMenu = connectMenu('NODE_MENU')(ContextMenu);

const LARGE_DATAROOM_WITHOUT_DOCUMENT_THRESHOLD = 1600;
const LARGE_DATAROOM_WITH_DOCUMENT_THRESHOLD = 700;

function makeFolderAwareHTML5Backend(manager) {
  return new HTML5Backend(manager);
}

@DragDropContext(makeFolderAwareHTML5Backend)
class App extends Component {
  constructor(props) {
    super(props);
    this.beginTimerUserDisconnect = this.beginTimerUserDisconnect.bind(this);
    this.listenDataroomResize = this.listenDataroomResize.bind(this);
    this.rootNodeRef = React.createRef();
    this.throttledBeginTimerUserDisconnect = throttle(
      this.beginTimerUserDisconnect,
      30000 // 30 seconds throttle
    );
    this.unmounted = false;
    if (typeof ResizeObserver !== 'undefined') {
      this.resizeObserver = new ResizeObserver(this.listenDataroomResize);
    } else {
      this.resizeObserver = new ResizeObserverPolyfill(
        this.listenDataroomResize
      );
    }
  }

  listenDataroomResize(entries) {
    const entry = entries[entries.length - 1];

    this.lastCurrent = this.rootNodeRef.current;

    const isLargeDataroom =
      (this.props.isLargeDataroom &&
        this.props.children &&
        entry.contentRect.width > LARGE_DATAROOM_WITH_DOCUMENT_THRESHOLD) ||
      entry.contentRect.width > LARGE_DATAROOM_WITHOUT_DOCUMENT_THRESHOLD;

    if (this.props.isLargeDataroom !== isLargeDataroom) {
      this.props.toggleIsLargeDataroomAction(isLargeDataroom);
    }
  }

  beginTimerUserDisconnect() {
    if (this.unmounted) {
      return;
    }
    if (this.disconnectionTimer) {
      clearTimeout(this.disconnectionTimer);
      this.disconnectionTimer = null;
    }
    this.disconnectionTimer = setTimeout(
      () => {
        window.location.href = '/';
      },
      900000 // 15 minutes
    );
  }

  componentDidUpdate() {
    // dom node of rootNode has been removed and added, so we have to watch resize for this new dom node
    if (this.lastCurrent && this.lastCurrent !== this.rootNodeRef.current) {
      this.resizeObserver.disconnect();
      this.resizeObserver = new ResizeObserver(this.listenDataroomResize);
      this.resizeObserver.observe(this.rootNodeRef.current);
    }

    this.lastCurrent = this.rootNodeRef.current;
  }

  componentDidMount() {
    this.resizeObserver.observe(this.rootNodeRef.current);
  }

  componentWillMount() {
    document.body.classList.add('dataroom');
    document.querySelector('html').style.fontSize = '13px';

    this.props.startDataroom();

    this.beginTimerUserDisconnect();
    window.document.body.addEventListener(
      'mousemove',
      this.throttledBeginTimerUserDisconnect
    );
  }

  componentWillUnmount() {
    this.props.resetDataroom();

    this.resizeObserver.disconnect();
    document.body.classList.remove('dataroom');
    document.querySelector('html').style.fontSize = '16px';

    this.unmounted = true;

    window.document.body.removeEventListener(
      'mousemove',
      this.throttledBeginTimerUserDisconnect
    );

    if (this.disconnectionTimer) {
      clearTimeout(this.disconnectionTimer);
    }
  }

  render() {
    const {
      dataroomLoaded,
      isLargeDataroom,
      search,
      dataroomLoading,
      nodesLoading,
      corrupted,
    } = this.props;

    const { anonymous } = this.props.routeParams;
    let arboClass = 'main-content main-content-with-actions';
    let mainColClass = 'main-col';
    let dataroomColumn = 'col-md-12 col-lg-12';
    let mainContentStyle = {};

    let children = this.props.children;

    if (this.props.children) {
      children = React.cloneElement(this.props.children, {
        ...this.props.routeParams,
        dataroomLoaded,
        isLargeDataroom,
      });
    }

    if (isLargeDataroom && this.props.children) {
      mainContentStyle.paddingRight = '0';
      mainColClass += ' d-f-huge';
      dataroomColumn = 'col-md-6 col-lg-6';
    }

    if (isLargeDataroom && this.props.children) {
      children = (
        <div className="col-md-6 col-lg-6">
          <Sticky>{children}</Sticky>
        </div>
      );
    }

    let dataroomComponent = (
      <div className="row">
        <div className={dataroomColumn}>
          <Search search={search} searchUpdated={this.props.updateSearch} />
          <DataroomCorruptionInfo corrupted={corrupted} />
          <UploadingInfo />
          <RootNode
            id={this.props.routeParams.id}
            anonymous={this.props.routeParams.anonymous}
            hasToLoad={!this.props.children | isLargeDataroom}
            forwardedRef={this.rootNodeRef}
          />
        </div>
        {children}
      </div>
    );

    return (
      <Fragment>
        <div className="page-head">
          <h1> Dataroom </h1>
        </div>
        <div style={mainContentStyle} className={arboClass}>
          <PleaseWait />
          <Loading isLoading={dataroomLoading || nodesLoading} />
          {!anonymous && (
            <DataroomActions dataroomId={this.props.routeParams.id} />
          )}
          <div className={mainColClass}>{dataroomComponent}</div>
          {dataroomLoaded &&
            !this.props.routeParams.anonymous &&
            this.props.canWrite && <ConnectedMenu />}
          {(!isLargeDataroom || !this.props.children) && <ActionBar />}

          {this.props.pdfEditor.open && (
            <Suspense fallback={<Loading isLoading={true} />}>
              <DataroomPdfEditor
                dataroom={this.props.dataroomWorking}
                nodeId={this.props.pdfEditor.nodeId}
              />
            </Suspense>
          )}

          {this.props.pdfExplode.open && (
            <Suspense fallback={<Loading isLoading={true} />}>
              <PdfExplode nodeId={this.props.pdfExplode.nodeId} />
            </Suspense>
          )}

          {this.props.pdfMerged.open && (
            <Suspense fallback={<Loading isLoading={true} />}>
              <PdfMerged nodeId={this.props.pdfMerged.nodeId} />
            </Suspense>
          )}

          <ConfirmAddFilesModal />
          <AttachFileModal />
          <ConfirmAttachFileModal />
          <ConfirmImportFilesModal />
          <ConfirmExtractZipModal />
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state, props) {
  let newProps = {
    ...props,
    dataroomLoaded: state.dataroom.loaded,
    dataroomWorking: state.dataroom.working,
    dataroomLoading: state.dataroom.loading,
    nodesLoading: state.dataroom.working.loading,
    rootNodeId: state.dataroom.working.rootNodeId,
    search: state.dataroom.search,
    pdfEditor: state.dataroom.pdfEditor,
    pdfExplode: state.pdfExplode,
    corrupted: state.dataroom.working.corrupted,
    isLargeDataroom: state.dataroom.isLargeDataroom,
    pdfMerged: state.pdfMerged,
    editionMode: EDITION_MODE_FORBIDDEN,
    canWrite: false,
    lockedByMe: false,
  };

  if (state.dataroom.loaded) {
    newProps.editionMode = editionModeSelectorFromDataRoom(state);
    newProps.canWrite = state.dataroom.dataroom.rights.canWrite;
    newProps.lockedByMe = state.dataroom.working.lock.lockedByMe;
  }

  return newProps;
}

export default connect(mapStateToProps, {
  updateSearch,
  resetDataroom,
  startDataroom,
  toggleIsLargeDataroomAction,
  unLockDataroom,
})(App);
