import { takeEvery, call, select, put } from 'redux-saga/effects';
import { PASTE_NODE, TYPE_COPY } from './nodesClipBoard';
import childNode from './utils/childNode';
import { TYPE_FOLDER, TYPE_DOCUMENT } from '../documents/type';
import createUndoableActionReducer from '../reducers/createUndoableActionReducer';
import updatePathForDescendant from './utils/updatePathForDescendant';
import { indexBy, uniqueId } from 'underscore';
import getParentIdForInsertion from './utils/getParentIdForInsertion';
import getNextPath, { AFTER, INSIDE } from './utils/getNextPath';
import getIndexForInsert from './utils/getIndexForInsert';
import createNode from './utils/createNode';
import undoSaga from './dataroomActions/undoSaga';
import { normalizeNode } from '../reducers/utils/dataroomLoad';
import { previableExtensions } from '../../document/actions/document';
import getPosition from './utils/getPosition';
import getPathLevel from './utils/getPathLevel';
import getParentPath from './utils/getParentPath';
import {
  addDocumentNumberForNodes,
  addDocumentWithoutFileNumberForNodes,
} from '../reducers/utils/dataroomLoad';
import addDocumentNumber, {
  addDocumentNumberWithoutFile,
} from './utils/addDocumentNumber';

import uuidv4 from 'uuid/v4';
import apiClient from 'js/dataroom/core/apiClient';
import queryString from 'qs';

export const COPY_NODES = 'COPY_NODES';

const replaceIds = (node, correspondingIds) => {
  const newNode = {
    ...node,
    id: correspondingIds[node.id].toId,
    parentId:
      typeof correspondingIds[node.parentId] !== 'undefined'
        ? correspondingIds[node.parentId].toId
        : null,
  };

  if (newNode.type === TYPE_FOLDER) {
    newNode.childIds = newNode.childIds.map(
      childId => correspondingIds[childId].toId
    );
  }

  return newNode;
};

class NodeNotFoundError {}

const getNormalizedNodesForParent = parentId =>
  apiClient
    .request(
      new Request(
        `/api/nodes?${queryString.stringify({
          searchOn: {
            parentNode: parentId,
          },
          items: '',
        })}`
      )
    )
    .then(response => {
      if (!response.ok) {
        throw new NodeNotFoundError();
      }

      return response.json();
    })
    .then(searchResult => {
      if (!searchResult.nodes.find(node => node.id === parentId)) {
        throw new NodeNotFoundError();
      }

      return normalizeNodesSearchResult(searchResult, parentId);
    });

const normalizeNodesSearchResult = (searchResult, parentId) => {
  const nodesByPath = indexBy(searchResult.nodes, 'path');
  const rootPath = searchResult.nodes.find(node => node.id === parentId).path;

  const normalizedNodes = searchResult.nodes.map(node => {
    return normalizeNode(node, nodesByPath, undefined, rootPath);
  });

  let indexedNodes = indexBy(normalizedNodes, 'id');

  if (typeof indexedNodes[parentId] !== 'undefined') {
    addDocumentNumberForNodes(indexedNodes, parentId);
    addDocumentWithoutFileNumberForNodes(indexedNodes, parentId);
  }

  return indexedNodes;
};

const getNodes = clipboard => {
  const firstCopiedNodeId = clipboard.nodeIds[0];

  if (clipboard.nodeIds.length === 1) {
    return getNormalizedNodesForParent(firstCopiedNodeId);
  }

  return apiClient
    .request(
      new Request(
        `/api/nodes?${queryString.stringify({
          searchOn: {
            sameParent: firstCopiedNodeId,
          },
          items: '',
        })}`
      )
    )
    .then(response => {
      if (!response.ok) {
        throw new NodeNotFoundError();
      }

      return response.json();
    })
    .then(searchResult => {
      for (let nodeId of clipboard.nodeIds) {
        if (!searchResult.nodes.find(node => node.id === nodeId)) {
          throw new NodeNotFoundError();
        }
      }

      const nodePath = searchResult.nodes.find(
        node => node.id === firstCopiedNodeId
      ).path;
      const parentPath = getParentPath(nodePath);
      const parentId = searchResult.nodes.find(node => node.path === parentPath)
        .id;

      return normalizeNodesSearchResult(searchResult, parentId);
    });
};

function* copyCrossDataroomNode(
  action,
  dataroomId,
  nodes,
  dataroomOriginId,
  withFile
) {
  const frontActionId = uniqueId('dataroom_action');

  yield put({
    type: 'COPY_NODES_PREPARE',
    action: {
      frontActionId,
    },
  });

  let crossDataroomNodes = null;

  try {
    crossDataroomNodes = yield call(getNodes, action.clipBoard);
  } catch (error) {
    console.error(error);
    yield put({
      type: 'COPY_NODES_PREPARE_ERROR',
      action: {
        frontActionId,
      },
    });
    return;
  }

  yield put(
    generateCopyNodeAction(
      {
        type: COPY_NODES,
        destinationId: action.destinationId,
        destinationPath: getNextPath(
          nodes[action.destinationId].path,
          action.position
        ),
        position: action.position,
        dataroomId,
        frontActionId,
        dataroomOriginId,
        withFile,
      },
      crossDataroomNodes,
      action.clipBoard.nodeIds
    )
  );
}

const generateCopyid = node => ({
  fromId: node.id,
  toId: uuidv4(),
});

function* applyCopyNodeSaga(action) {
  const { nodes, dataroomId } = yield select(state => ({
    nodes: state.dataroom.working.nodes,
    dataroomId: state.dataroom.dataroom.id,
  }));

  if (action.clipBoard.dataroomId !== dataroomId) {
    yield call(
      copyCrossDataroomNode,
      action,
      dataroomId,
      nodes,
      action.clipBoard.dataroomId,
      action.withFile
    );
    return;
  }

  yield put(
    generateCopyNodeAction(
      {
        type: COPY_NODES,
        destinationId: action.destinationId,
        destinationPath: getNextPath(
          nodes[action.destinationId].path,
          action.position
        ),
        position: action.position,
        dataroomId,
        frontActionId: uniqueId('dataroom_action'),
        withFile: action.withFile,
      },
      nodes,
      action.clipBoard.nodeIds
    )
  );
}

const generateCopyNodeAction = (baseAction, nodes, nodeIdsToCopy) => {
  const pathLevel = getPathLevel(nodes[nodeIdsToCopy[0]].path);

  const newNodes = nodeIdsToCopy
    .sort(
      (nodeId1, nodeId2) =>
        getPosition(pathLevel, nodes[nodeId1].path) -
        getPosition(pathLevel, nodes[nodeId2].path)
    )
    .map(nodeId => nodes[nodeId]);
  const newChildNodes = {};
  const copyIds = [];

  for (let newNode of newNodes) {
    newChildNodes[newNode.id] = [];
    copyIds.push(generateCopyid(newNode));
    for (const newChildNode of childNode(nodes, newNode.id)) {
      copyIds.push(generateCopyid(newChildNode));
      newChildNodes[newNode.id].push(newChildNode);
    }
  }

  return {
    ...baseAction,
    newNodes,
    newChildNodes,
    copyIds,
  };
};

const copyNodeApi = action =>
  apiClient.request(
    new Request(`/api/datarooms/${action.dataroomId}/nodes/copy`, {
      method: 'POST',
      body: JSON.stringify({
        to: action.destinationPath,
        nodeIds: action.newNodes.map(node => node.id),
        copyIds: action.copyIds,
        keepFile: action.withFile,
        dataRoomOrigin: action.dataroomOriginId
          ? action.dataroomOriginId
          : action.dataroomId,
      }),
    })
  );

export function* pasteNodeSaga() {
  yield takeEvery(
    action => action.type === PASTE_NODE && action.clipBoard.type === TYPE_COPY,
    applyCopyNodeSaga
  );
}

export function* undoActionSaga() {
  yield takeEvery('UNDO_COPY_NODES', undoSaga);
}

export function* saga(action) {
  return yield call(copyNodeApi, action);
}

function copyNodes(state, action) {
  let newState = { ...state };

  let newNodes = action.newNodes;

  if (action.position === AFTER || action.position === INSIDE) {
    newNodes = [...action.newNodes].reverse();
  }

  newNodes.forEach(newNode => {
    newState = copyNode(newState, {
      copyIds: action.copyIds,
      newChildNodes: action.newChildNodes[newNode.id],
      newNode,
      position: action.position,
      destinationId: action.destinationId,
      withFile: action.withFile,
    });
  });

  return newState;
}

function copyNode(state, action) {
  const { copyIds, newChildNodes, newNode, position, destinationId } = action;
  const { nodes } = state.working;
  const correspondingIds = indexBy(copyIds, 'fromId');
  const parentId = getParentIdForInsertion(nodes, destinationId, position);
  const nextPath = getNextPath(nodes[destinationId].path, position);

  const normalizedChilds = newChildNodes.map(node => {
    const newNormalizedNode = {
      ...createNode(),
      ...replaceIds(node, correspondingIds),
      path:
        newNode.path === '0'
          ? `${nextPath}.${node.path}`
          : node.path.replace(newNode.path, nextPath),
      origin: node.origin,
    };

    if (node.type === TYPE_DOCUMENT && node.file && !action.withFile) {
      newNormalizedNode.file = null;
    }

    if (node.type === TYPE_FOLDER && !action.withFile) {
      newNormalizedNode.documentWithFileNumber = 0;
    }

    if (newNormalizedNode.file) {
      newNormalizedNode.file = {
        ...newNormalizedNode.file,
        previewStatus:
          previableExtensions.indexOf(newNormalizedNode.file.extension) !== -1
            ? 'in_progress'
            : null,
      };
    }

    return newNormalizedNode;
  });
  const normalizedNode = {
    ...createNode(),
    ...replaceIds(newNode, correspondingIds),
    parentId,
    path: nextPath,
    origin: newNode.origin,
  };

  if (normalizedNode.type === TYPE_FOLDER && !action.withFile) {
    normalizedNode.documentWithoutFileNumber +=
      normalizedNode.documentWithFileNumber;
    normalizedNode.documentWithFileNumber = 0;
  }

  if (
    normalizedNode.type === TYPE_DOCUMENT &&
    normalizedNode.file &&
    !action.withFile
  ) {
    normalizedNode.file = null;
  }

  if (normalizedNode.file) {
    normalizedNode.file = {
      ...normalizedNode.file,
      previewStatus:
        previableExtensions.indexOf(normalizedNode.file.extension) !== -1
          ? 'in_progress'
          : null,
    };
  }

  let renumberedNodes = updatePathForDescendant(
    state.working.nodes,
    destinationId,
    position,
    1
  );

  if (normalizedNode.type === TYPE_DOCUMENT && normalizedNode.file) {
    renumberedNodes = addDocumentNumber(
      normalizedNode.parentId,
      renumberedNodes,
      1
    );
  }

  if (normalizedNode.type === TYPE_DOCUMENT && !normalizedNode.file) {
    renumberedNodes = addDocumentNumberWithoutFile(
      normalizedNode.parentId,
      renumberedNodes,
      1
    );
  }

  if (normalizedNode.type === TYPE_FOLDER) {
    renumberedNodes = addDocumentNumber(
      normalizedNode.parentId,
      renumberedNodes,
      normalizedNode.documentWithFileNumber
    );

    renumberedNodes = addDocumentNumberWithoutFile(
      normalizedNode.parentId,
      renumberedNodes,
      normalizedNode.documentWithoutFileNumber
    );
  }

  const newChildIds = [...nodes[parentId].childIds];
  newChildIds.splice(
    getIndexForInsert(nodes[parentId].childIds, destinationId, position),
    0,
    normalizedNode.id
  );

  return {
    ...state,
    working: {
      ...state.working,
      nodes: {
        ...renumberedNodes,
        ...indexBy(normalizedChilds, 'id'),
        [normalizedNode.id]: normalizedNode,
        [parentId]: {
          ...renumberedNodes[parentId],
          childIds: newChildIds,
        },
      },
    },
  };
}

function undoCopyNodes(state, action) {
  let newState = { ...state };

  [...action.newNodes].reverse().forEach(newNode => {
    newState = undoCopyNode(newState, {
      copyIds: action.copyIds.filter(
        copyId =>
          copyId.fromId === newNode.id ||
          action.newChildNodes[newNode.id].findIndex(
            newChildNode => newChildNode.id === copyId.fromId
          ) !== -1
      ),
      newChildNodes: action.newChildNodes[newNode.id],
      newNode,
      position: action.position,
      destinationId: action.destinationId,
      withFile: action.withFile,
    });
  });

  return newState;
}

function undoCopyNode(state, action) {
  const { copyIds, newNode, destinationId, position } = action;
  const { nodes } = state.working;
  const parentId = getParentIdForInsertion(nodes, destinationId, position);

  const newNodeId = copyIds.find(copyId => copyId.fromId === newNode.id).toId;

  let newNodes = updatePathForDescendant(
    state.working.nodes,
    destinationId,
    position,
    -1
  );

  const newId = copyIds.find(copyId => copyId.fromId === newNode.id).toId;

  if (nodes[newId].type === TYPE_DOCUMENT && nodes[newId].file) {
    newNodes = addDocumentNumber(
      position === INSIDE ? newId : nodes[newId].parentId,
      newNodes,
      -1
    );
  }

  if (nodes[newId].type === TYPE_DOCUMENT && !nodes[newId].file) {
    newNodes = addDocumentNumberWithoutFile(
      position === INSIDE ? newId : nodes[newId].parentId,
      newNodes,
      -1
    );
  }

  if (action.withFile && nodes[newId].type === TYPE_FOLDER) {
    newNodes = addDocumentNumber(
      position === INSIDE ? nodes[newId].id : nodes[newId].parentId,
      newNodes,
      -nodes[newId].documentWithFileNumber
    );
  }

  if (nodes[newId].type === TYPE_FOLDER) {
    newNodes = addDocumentNumberWithoutFile(
      position === INSIDE ? nodes[newId].id : nodes[newId].parentId,
      newNodes,
      -nodes[newId].documentWithoutFileNumber
    );
  }

  copyIds.forEach(copyId => {
    delete newNodes[copyId.toId];
  });

  return {
    ...state,
    working: {
      ...state.working,
      nodes: {
        ...newNodes,
        [parentId]: {
          ...newNodes[parentId],
          childIds: nodes[parentId].childIds.filter(
            childId => childId !== newNodeId
          ),
        },
      },
    },
  };
}

export default createUndoableActionReducer(
  COPY_NODES,
  copyNodes,
  undoCopyNodes
);
