import createUndoableActionReducer from '../reducers/createUndoableActionReducer';
import { takeEvery, call } from 'redux-saga/effects';
import undoSaga from './dataroomActions/undoSaga';

import apiClient from 'js/dataroom/core/apiClient';
import { uniqueId } from 'underscore';
import { TYPE_DOCUMENT } from '../documents/type';
import addNodes from './utils/addNodes';
import addDocumentNumber, {
  addDocumentNumberWithoutFile,
} from './utils/addDocumentNumber';
import { AFTER, INSIDE } from './utils/getNextPath';
import getPathLevel from './utils/getPathLevel';
import updatePath from './utils/updatePath';
import removeNode from './utils/removeNode';

export const PDF_EXPLODE = 'PDF_EXPLODE';

export function explodePdfAction(nodeId, path, newNodes) {
  return {
    type: PDF_EXPLODE,
    nodeId: nodeId,
    to: path,
    newNodes: newNodes,
    frontActionId: uniqueId('dataroom_action'),
  };
}

const formatNewNode = newNodes => {
  return newNodes.map(newNode => {
    return {
      pageNumbers: newNode.pageNumbers,
      node: {
        id: newNode.nodeId,
        name: newNode.nodeName,
        type: newNode.nodeType,
      },
    };
  });
};

export const validatePdfExplodeApi = action =>
  apiClient.request(
    new Request(`/api/nodes/${action.nodeId}/explodeDocumentPdf`, {
      method: 'POST',
      body: JSON.stringify({
        to: action.to,
        nodeId: action.nodeId,
        pages: formatNewNode(action.newNodes),
      }),
    })
  );

export function* saga(action) {
  return yield call(validatePdfExplodeApi, action);
}

export function* undoActionSaga() {
  yield takeEvery('UNDO_PDF_EXPLODE', undoSaga);
}

export const explodePdf = (state, action) => {
  let nodes = action.newNodes.map(newNode => {
    return {
      id: newNode.nodeId,
      name: newNode.nodeName,
      type: TYPE_DOCUMENT,
      file: {
        size: null,
        editedAt: new Date(),
        mimetype: 'application/pdf',
        extension: 'pdf',
        previewStatus: null,
      },
    };
  });

  let pathLevel = getPathLevel(action.to);
  let lastPath = updatePath(pathLevel, action.to, pathNumber => pathNumber - 1);

  let parentId = state.working.nodes[action.nodeId].parentId;
  let nodeIdToInsert = state.working.nodes[parentId].childIds.find(
    id => state.working.nodes[id].path === lastPath
  );

  let newNodes = addNodes(nodeIdToInsert, AFTER, state.working.nodes, nodes);

  newNodes = addDocumentNumber(
    state.working.nodes[action.nodeId].parentId,
    newNodes,
    action.newNodes.length
  );

  return {
    ...state,
    working: {
      ...state.working,
      nodes: newNodes,
    },
  };
};

export const undoExplodePdf = (state, action) => {
  let stateNodes = { ...state.working.nodes };

  action.newNodes.forEach(newNode => {
    stateNodes = removeNode(stateNodes, newNode.nodeId);
  });

  if (action.nodeType === TYPE_DOCUMENT) {
    stateNodes = addDocumentNumberWithoutFile(
      state.working.nodes[action.nodeId].parentId,
      stateNodes,
      -action.newNodes.length
    );
  }

  return {
    ...state,
    working: {
      ...state.working,
      nodes: stateNodes,
    },
  };
};

export default createUndoableActionReducer(
  PDF_EXPLODE,
  explodePdf,
  undoExplodePdf
);
