import React, { Component } from 'react';
import styled from 'styled-components';

const P = styled.p`
  ${props => (props.fontSize ? 'font-size: ' + props.fontSize + ';' : null)};
  ${props => (props.display ? 'display: ' + props.display + ';' : null)};
  ${props =>
    props.lineHeight ? 'line-height: ' + props.lineHeight + ';' : null};
  ${props => (props.textAlign ? 'text-align: ' + props.textAlign + ';' : null)};
  ${props => (props.marginTop ? 'margin-top: ' + props.marginTop + ';' : null)};
  ${props =>
    props.marginBottom ? 'margin-bottom: ' + props.marginBottom + ';' : null};
  ${props => (props.color ? 'color: ' + props.color + ';' : null)};
  ${props =>
    props.fontWeight ? 'font-weight: ' + props.fontWeight + ';' : null};
`;

export default P;
