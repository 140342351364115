import createUndoableActionReducer from '../reducers/createUndoableActionReducer';
import undoSaga from './dataroomActions/undoSaga';
import { takeEvery, call } from 'redux-saga/effects';
import { UNLOCKED, UNLOCK_SUCCESS } from './lock';

import apiClient from 'js/dataroom/core/apiClient';

export const RENAME_NODE = 'RENAME_NODE';
export const NODE_NAME_EDITABLE = 'NODE_NAME_EDITABLE';
export const CANCEL_RENAME_NODE = 'CANCEL_RENAME_NODE';

export const renameNodeAction = (newName, nodeId) => ({
  type: RENAME_NODE,
  name: newName,
  nodeId: nodeId,
});

export const nodeNameEditableAction = nodeId => ({
  type: NODE_NAME_EDITABLE,
  nodeId,
});

export const cancelRenameAction = () => ({
  type: CANCEL_RENAME_NODE,
});

const renameNodeApi = action =>
  apiClient.request(
    new Request(`/api/nodes/${action.nodeId}/rename`, {
      method: 'PATCH',
      body: JSON.stringify({
        name: action.name,
      }),
    })
  );

export function* saga(action) {
  return yield call(renameNodeApi, action);
}

export function* undoActionSaga() {
  yield takeEvery('UNDO_RENAME_NODE', undoSaga);
}

function renameNode(state, action) {
  return {
    ...state,
    working: {
      ...state.working,
      savedNodes: {
        ...state.working.savedNodes,
        [action.frontActionId]: {
          [action.nodeId]: state.working.nodes[action.nodeId],
        },
      },
      nodes: {
        ...state.working.nodes,
        [action.nodeId]: {
          ...state.working.nodes[action.nodeId],
          name: action.name,
          rename: false,
        },
      },
      renameNode: {
        nodeNameEditable: false,
        nodeId: null,
      },
    },
  };
}

function undoRenameNode(state, action) {
  return {
    ...state,
    working: {
      ...state.working,
      nodes: {
        ...state.working.nodes,
        [action.nodeId]: {
          ...state.working.nodes[action.nodeId],
          name:
            state.working.savedNodes[action.frontActionId][action.nodeId].name,
        },
      },
    },
  };
}

const baseReducer = createUndoableActionReducer(
  RENAME_NODE,
  renameNode,
  undoRenameNode
);

export default function(state, action) {
  switch (action.type) {
    case CANCEL_RENAME_NODE:
    case UNLOCKED:
    case UNLOCK_SUCCESS:
      if (state.working.renameNode.nodeId) {
        return {
          ...state,
          working: {
            ...state.working,
            nodes: {
              ...state.working.nodes,
              [state.working.renameNode.nodeId]: {
                ...state.working.nodes[state.working.renameNode.nodeId],
                rename: false,
              },
            },
            renameNode: {
              nodeNameEditable: false,
              nodeId: null,
            },
          },
        };
      }

      return {
        ...state,
        working: {
          ...state.working,
          renameNode: {
            nodeNameEditable: false,
            nodeId: null,
          },
        },
      };
    case NODE_NAME_EDITABLE:
      return {
        ...state,
        working: {
          ...state.working,
          nodes: {
            ...state.working.nodes,
            [action.nodeId]: {
              ...state.working.nodes[action.nodeId],
              rename: true,
            },
          },
          renameNode: {
            nodeNameEditable: true,
            nodeId: action.nodeId,
          },
        },
      };
  }

  return baseReducer(state, action);
}
