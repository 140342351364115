export const EDITION_MODE_FORBIDDEN = 'forbidden';
export const EDITION_MODE_ALLOWED = 'allowed';

export const MAX_DROPED_NODES = 2000;
export const NODE_NUMBER_DISABLE_UNFOLD_ALL = 2000;
export const VISIBLE_NODE_NUMBER_WARNING = 2000;

export const IMPORT_TYPE_NOTARY_CHAMBER = 'IMPORT_TYPE_NOTARY_CHAMBER';
export const IMPORT_TYPE_CLASSIC = 'IMPORT_TYPE_CLASSIC';
export const IMPORT_TYPE_CDROM_BRUT = 'IMPORT_TYPE_CDROM_BRUT';

export const EXPORT_TYPE_NOTARY_CHAMBER = 'EXPORT_TYPE_NOTARY_CHAMBER';
export const EXPORT_TYPE_FOLDER = 'EXPORT_TYPE_FOLDER';
export const EXPORT_TYPE_INTRALINK = 'EXPORT_TYPE_INTRALINK';

export const adminRoles = ['ROLE_SUPER_ADMIN', 'ROLE_COMPANY_ADMIN'];
export const superAdminRoles = ['ROLE_SUPER_ADMIN'];
