import React, { Component } from 'react';

class PanelHeader extends Component {
  render() {
    const { icon, children } = this.props;

    return (
      <div className="panel-heading">
        <div className="panel-title">
          <span className={`fa fa-${icon}`} />
          {children}
        </div>
      </div>
    );
  }
}

export default PanelHeader;
