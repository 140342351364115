import { uniqueId } from 'underscore';
import {
  DELETE_NOTIFICATION,
  MOVE_NOTIFICATION,
  MOVE_NOTIFICATION_DONE,
  DELETE_NOTIFICATION_DONE,
  ADD_PERMANENT_NOTIFICATION,
  ADD_NOTIFICATION,
} from '../actions/notifications';

import allowedFileExtension from '../../dataroom/documents/allowedFileExtension';
import { MAX_DROPED_NODES } from '../../dataroom/constants';
import { IMPORT_ERROR } from '../../dataroom/ducks/importFiles';
import { TRANSFORM_ARCHIVE_NODE_TO_FOLDER } from '../../dataroom/ducks/transformArchiveNodeToFolder';
import { MOVE_NODES } from '../../dataroom/ducks/moveNodes';
import { REMOVE_EMPTY_DOCUMENT_NODES } from '../../dataroom/ducks/removeDocumentNodeWithoutFile';
import { REMOVE_NODES } from '../../dataroom/ducks/removeNodes';
import { REMOVE_EMPTY_FOLDER_NODES } from '../../dataroom/ducks/removeEmptyFolderNodes';
import reduceReducers from 'reduce-reducers';
import {
  ATTACH_FILE_TO_DOCUMENT,
  FILE_NOT_ALLOWED,
  FILE_ALREADY_UPLOADING,
  CANT_ATTACH_MULTIPLE_FILES_TO_DOCUMENT,
} from '../../dataroom/ducks/attachFileToDocument';
import {
  ADD_FILES,
  FILES_UPLOADED,
  FILE_UPLOAD_ERROR,
  CANT_CREATE_ALL_IGNORED_FILES,
  TOO_MANY_FILES_DROPPED,
  TOO_MANY_UPLOADS_FOR_DROP,
  DRAG_AND_DROP_ERROR,
} from '../../dataroom/ducks/addFiles';
import {
  LOCK_ERROR,
  UNLOCK_ERROR,
  CANT_LOCK_NOT_MANIPULABLE_DATAROOM,
} from '../../dataroom/ducks/lock';
import { ADD_NODES } from '../../dataroom/ducks/addNodes';
import { RENAME_NODE } from '../../dataroom/ducks/renameNode';
import { COPY_NODES } from '../../dataroom/ducks/copyNodes';
import { CHANGE_NODES_TYPE } from '../../dataroom/ducks/changeNodeType';
import { DETACH_FILE_TO_DOCUMENT } from '../../dataroom/ducks/detachFileToDocument';

export const TYPE_ERROR = 'ERROR';
export const TYPE_WARNING = 'WARNING';
export const TYPE_SUCCESS = 'TYPE_SUCCESS';
export const TYPE_IN_PROGRESS = 'TYPE_IN_PROGRESS';

export const CATEGORY_FULLSCREEN = 'CATEGORY_FULLSCREEN';
export const CATEGORY_PERMANENT = 'CATEGORY_PERMANENT';
export const CATEGORY_REMINDER = 'CATEGORY_REMINDER';

export const ADD_NOTIFICATION_REMINDER = 'ADD_NOTIFICATION_REMINDER';

export const STATUS_NOTIFICATION = 'STATUS_NOTIFICATION';
export const STATUS_MOVING_TO_REMINDERS = 'STATUS_MOVING_TO_REMINDERS';
export const STATUS_NOTIFICATION_REMINDER = 'STATUS_NOTIFICATION_REMINDER';
export const STATUS_DELETING = 'STATUS_DELETING';

const defaultState = {
  notifications: [],
};

function reducer(state = defaultState, action = {}) {
  switch (action.type) {
    case IMPORT_ERROR: {
      return addNotification(
        state,
        `Une erreur s'est produite lors de l'import`
      );
    }
    case ADD_NOTIFICATION: {
      return addNotification(state, action.message, action.notificationType);
    }
    case CANT_LOCK_NOT_MANIPULABLE_DATAROOM: {
      return addNotification(
        state,
        'Vous ne pouvez pas verrouiller une dataroom fermée à la manipulation'
      );
    }
    case LOCK_ERROR: {
      return addNotification(
        state,
        "le verrouillage de l'arborescence a échoué"
      );
    }
    case FILES_UPLOADED:
      let newState = { ...state };

      action.finishedUploadsFiles.forEach(finishedUploadsFile => {
        if (finishedUploadsFile.type === FILE_UPLOAD_ERROR)
          newState = addNotification(
            newState,
            `L'upload du fichier ${finishedUploadsFile.name} a échoué`
          );
      });

      return newState;
    // todo a remettre comme avant hack pour le user
    /*case UNLOCK_ERROR: {
      return addNotification(
        state,
        "le déverrouillage de l'arborescence a échoué"
      );
    }*/
    case CANT_CREATE_ALL_IGNORED_FILES: {
      return addNotification(
        state,
        `Tous les dossiers et fichiers ajoutés sont ignorés`,
        TYPE_WARNING
      );
    }
    case FILE_NOT_ALLOWED:
      return addNotification(
        state,
        `le fichier ${
          action.fileName
        } n'est pas autorisé. extensions autorisées: ${allowedFileExtension.join(
          ', '
        )}`
      );
    case FILE_ALREADY_UPLOADING:
      return addNotification(
        state,
        `L'upload d'un fichier est déja en cours pour le document ${action.name}`
      );
    case CANT_ATTACH_MULTIPLE_FILES_TO_DOCUMENT: {
      return addNotification(
        state,
        `Vous ne pouvez associer qu'un fichier à un document`
      );
    }
    case TOO_MANY_FILES_DROPPED:
      return addNotification(
        state,
        `le nombre maximum de lignes autorisés en glisser déposer est de ${MAX_DROPED_NODES}.
         Vous avez tenté de déposer ${action.droppedFilesNumber} lignes`
      );
    case ADD_NOTIFICATION_REMINDER: {
      return addNotificationReminder(
        state,
        action.message,
        action.notificationType
      );
    }
    case DRAG_AND_DROP_ERROR:
      return addNotification(
        state,
        "Une erreur s'est produite lors de l'ajout de fichiers"
      );
    case TOO_MANY_UPLOADS_FOR_DROP:
      return addNotification(
        state,
        `Il y a actuellement ${action.currentUploadNumber} documents en cours d'upload. 
        Vous en avez déposé ${action.droppedFilesNumber}.
         Veuillez réduire le nombre de fichiers ou attendre la fin des upload pour ne pas dépasser la limite d'upload (${MAX_DROPED_NODES} lignes)`
      );
    case MOVE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.map(notification => {
          if (notification.id !== action.notificationId) {
            return notification;
          }

          return {
            ...notification,
            status: STATUS_MOVING_TO_REMINDERS,
          };
        }),
      };
    case MOVE_NOTIFICATION_DONE:
      return {
        ...state,
        notifications: state.notifications.map(notification => {
          if (notification.id !== action.notificationId) {
            return notification;
          }

          return {
            ...notification,
            status: STATUS_NOTIFICATION_REMINDER,
          };
        }),
      };
    case DELETE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.map(notification => {
          if (notification.id !== action.notificationId) {
            return notification;
          }

          return {
            ...notification,
            status: STATUS_DELETING,
          };
        }),
      };
    case DELETE_NOTIFICATION_DONE:
      const itemIndex = state.notifications.findIndex(
        notification => notification.id === action.notificationId
      );
      if (itemIndex === -1) {
        return state;
      }

      return {
        ...state,
        notifications: [
          ...state.notifications.slice(0, itemIndex),
          ...state.notifications.slice(itemIndex + 1),
        ],
      };
    case ADD_PERMANENT_NOTIFICATION:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            type: action.notificationType,
            message: action.message,
            status: STATUS_NOTIFICATION_REMINDER,
            id: action.notificationId,
            category: CATEGORY_PERMANENT,
          },
        ],
      };
    default:
      return state;
  }
}

const createErrorReducer = (actionType, doErrorMessage, undoErrorMessage) => (
  state,
  action
) => {
  switch (action.type) {
    case `${actionType}_ERROR`:
      return addNotification(state, doErrorMessage);
    case `UNDO_${actionType}_ERROR`:
      return addNotification(state, undoErrorMessage);
    case `${actionType}_PREPARE_ERROR`:
      return addNotification(state, doErrorMessage);
  }

  return state;
};

const addNotification = (state, message, type = TYPE_ERROR) => ({
  ...state,
  notifications: [
    ...state.notifications,
    {
      type,
      message,
      status: STATUS_NOTIFICATION,
      id: uniqueId('notification'),
      category: CATEGORY_FULLSCREEN,
    },
  ],
});

const addNotificationReminder = (state, message, type = TYPE_ERROR) => ({
  ...state,
  notifications: [
    ...state.notifications,
    {
      type,
      message,
      status: STATUS_NOTIFICATION_REMINDER,
      id: uniqueId('notification'),
      category: CATEGORY_REMINDER,
    },
  ],
});

export default reduceReducers(
  reducer,
  createErrorReducer(
    ADD_NODES,
    `l'ajout de lignes a échoué`,
    `l'annulation d'ajout de lignes a échoué`
  ),
  createErrorReducer(
    TRANSFORM_ARCHIVE_NODE_TO_FOLDER,
    `L'extraction de l'archive a échoué`,
    `L'annulation de l'extraction de l'archive a échoué`
  ),
  createErrorReducer(
    RENAME_NODE,
    `le renommage de la ligne a échoué`,
    `l'annulation du renommage de la ligne a échoué`
  ),
  createErrorReducer(
    CHANGE_NODES_TYPE,
    `le changement de type de fichier a échoué`,
    `l'annulation du changement de type de fichier a échoué`
  ),
  createErrorReducer(
    DETACH_FILE_TO_DOCUMENT,
    `La suppression du fichier a échoué`,
    `L'annulation de la suppression  du fichier a échoué`
  ),
  createErrorReducer(
    MOVE_NODES,
    `le déplacement de la ligne a échoué`,
    `l'annulation du déplacement de la ligne a échoué`
  ),
  createErrorReducer(
    REMOVE_NODES,
    `la suppression de la ligne a échoué`,
    `l'annulation de la suppression de la ligne a échoué`
  ),
  createErrorReducer(
    REMOVE_EMPTY_FOLDER_NODES,
    `La suppression des dossiers vides a échoué`,
    `L'annulation de la suppression des dossiers vides a échoué`
  ),
  createErrorReducer(
    ATTACH_FILE_TO_DOCUMENT,
    `l'association du fichier a échoué`,
    `l'annulation de l'association du fichier a échoué`
  ),
  createErrorReducer(
    REMOVE_EMPTY_DOCUMENT_NODES,
    `La suppression des documents vides a échoué`,
    `L'annulation de la suppression des documents vides a échoué`
  ),
  createErrorReducer(
    ADD_NODES,
    `l'ajout de lignes a échoué`,
    `l'annulation d'ajout de lignes a échoué`
  ),
  createErrorReducer(
    COPY_NODES,
    `la copie de la ligne a échoué`,
    `l'annulation de la copie de la ligne a échoué`
  ),
  createErrorReducer(
    ADD_FILES,
    `l'ajout de fichiers a échoué`,
    `L'annulation de l'ajout de fichiers a échoué`
  )
);
