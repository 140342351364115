export default function() {
  return {
    selected: false,
    visible: true,
    matched: false,
    published: false,
    menuOpen: false,
    editedAt: new Date(),
    picked: false,
    file: null,
    publicationState: {
      published: false,
      hasChange: false,
    },
    dragging: false,
  };
}
