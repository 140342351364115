import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actionsActions from '../actions/actions.js';
import { bindActionCreators } from 'redux';
import Action from '../components/Action';
import { Link } from 'react-router';
import 'translations/messages/fr';
import 'translations/messages/en';
import { ButtonGroup } from 'react-bootstrap';

class ActionsPanel extends Component {
  constructor(props) {
    super(props);
    this.handleArrowClick = this.handleArrowClick.bind(this);
  }

  render() {
    const { actionsList, actions, links, footerActionsList } = this.props;
    if (actions.minimized) {
      return (
        <div className="actions actions-col-hidden">
          <button
            onClick={this.handleArrowClick}
            className="tools btn btn-dark btn-sm aside-toggler"
          >
            <span className="icon fa fa-angle-right" />
          </button>
        </div>
      );
    }

    return (
      <div className="actions actions-col">
        <div className="panel panel-full-dark">
          <div className="panel-heading">
            <div className="tools">
              {' '}
              <span
                onClick={this.handleArrowClick}
                className="icon fa fa-angle-down"
              />{' '}
            </div>
            {!actions.minimized && (
              <div className="panel-title"> {Translator.trans('actions')} </div>
            )}
          </div>

          <div className="panel-body">
            <ul className="list-unstyled">
              {this.props.headerElementsList.map((element, index) => (
                <li key={index}>{element}</li>
              ))}
              {actionsList.map((action, i) => (
                <Action key={i} action={action} />
              ))}
              {this.props.children}
            </ul>
            {links.length > 0 && <hr />}
            {links.map((link, i) =>
              this.renderLink(i, link, i === links.length - 1)
            )}
            {footerActionsList.length > 0 && <hr />}
            <ul className="list-unstyled">
              {footerActionsList.map((action, i) => (
                <Action danger key={i} action={action} />
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  }

  renderLink(index, link, isLast) {
    let icon = `fa fa-${link.icon}`;
    return (
      <span key={index}>
        {' '}
        <p>
          {' '}
          <i className={icon} /> {link.title} :
        </p>
        <ul className="last-docs">
          {link.urls.map((urlInfo, i) => (
            <li key={i}>
              <Link title={urlInfo.name} to={urlInfo.url}>
                {urlInfo.name}
              </Link>
            </li>
          ))}
        </ul>
        {!isLast && <hr />}
      </span>
    );
  }

  handleArrowClick() {
    if (this.props.actions.minimized) {
      return this.props.unMinimizeActions();
    }
    return this.props.minimizeActions();
  }
}

ActionsPanel.defaultProps = {
  headerElementsList: [],
  footerActionsList: [],
};

ActionsPanel.propTypes = {
  actionsList: PropTypes.array.isRequired,
  links: PropTypes.array.isRequired,
  headerElementsList: PropTypes.array,
  footerActionsList: PropTypes.array,
};

function mapStateToProps(state) {
  return {
    actions: state.actions,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionsActions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ActionsPanel);
