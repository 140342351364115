import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  fold,
  foldRecursive,
  selectNode,
  unFold,
  unFoldRecursive,
  unSelectNode,
} from '../actions/dataroom.js';
import { browserHistory } from 'react-router';
import {
  addNodesBetweenPickedAction,
  addNodeToPickedAction,
  pickNodeAction,
  removeNodeToPickedAction,
} from '../ducks/pickNode';
import {
  addFilesAction,
  dragAndDropErrorAction,
  preparingUploadAction,
  tooManyFilesDroppedAction,
  tooManyUploadForDropAction,
} from '../ducks/addFiles';
import { TYPE_DOCUMENT, TYPE_FOLDER } from '../documents/type.js';
import { confirmImportFilesAction, getImportType } from '../ducks/importFiles';
import {
  addNotificationAction,
  addNotificationReminderAction,
} from '../../app/actions/notifications';
import { DragSource, DropTarget } from 'react-dnd';
import { findDOMNode } from 'react-dom';
import {
  attachFileToDocumentAction,
  cantAttachMultipleFilesToDocument,
  confirmAttachFileToDocumentHavingFileAction,
  fileAlreadyUploadingAction,
  fileNotAllowedAction,
} from '../ducks/attachFileToDocument';
import {
  EDITION_MODE_ALLOWED,
  EDITION_MODE_FORBIDDEN,
  IMPORT_TYPE_CLASSIC,
  MAX_DROPED_NODES,
} from '../constants';
import { isFileAllowed } from '../documents/allowedFileExtension';
import { store } from '../../app/createStore';
import { confirmExtractZipAction } from '../ducks/extractZipFile';
import getNextPath, { AFTER, BEFORE, INSIDE } from '../ducks/utils/getNextPath';
import getPathLevel from '../ducks/utils/getPathLevel';
import getPosition from '../ducks/utils/getPosition';
import getParentPath from '../ducks/utils/getParentPath';
import updatePath from '../ducks/utils/updatePath';
import { beginDragAction, endDragAction } from '../ducks/dragDrop';
import { createSelector } from 'reselect';
import './TreeNode.css';

import uuidv4 from 'uuid/v4';
import getClassesForNode from './utils/getClassesForNode';
import FolderNode from '../components/FolderNode';
import DocumentNode from '../components/DocumentNode';
import TreeNodeCheckbox from '../components/TreeNodeCheckbox';
import { loadDocument } from '../../document/actions/document';
import { moveNodesAction } from '../ducks/moveNodes';
import getDraggingNodes from '../selectors/getDraggingNodes';
import { FILE } from '../../lib/react-dnd-html5-backend/src/NativeTypes';
import canEditDataroom from '../selectors/canEditDataroom';
import editionModeSelectorFromDataRoom from '../selectors/editionModeSelectorFromDataRoom';
import { TYPE_WARNING } from '../../app/reducers/notifications';

const getBasePath = () => {
  const documentRoutesTypes = ['documents'];

  let basePath = window.location.pathname;
  for (let documentRoutesType of documentRoutesTypes) {
    const baseTypeIndex = window.location.pathname.indexOf(documentRoutesType);
    if (baseTypeIndex !== -1) {
      return basePath.substr(0, baseTypeIndex - 1);
    }
  }

  return basePath;
};

function createNodeFromEntry(entry) {
  if (!entry) {
    throw new Error('got undefined entry');
  }

  if (entry.isFile) {
    return new Promise((success, reject) => {
      entry.file(
        file => {
          return success({
            file,
            type: TYPE_DOCUMENT,
            name: entry.name,
            id: uuidv4(),
          });
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  const reader = entry.createReader();

  return readFolderNodes(reader).then(nodes => {
    return {
      type: TYPE_FOLDER,
      name: entry.name,
      children: nodes,
      id: uuidv4(),
    };
  });
}

const readFolderNodes = reader => {
  return readFolderNodesBatch(reader).then(nodes => {
    if (nodes.length === 100) {
      return Promise.all([
        Promise.resolve(nodes),
        readFolderNodes(reader),
      ]).then(nodesData => {
        return nodesData[0].concat(nodesData[1]);
      });
    }

    return nodes;
  });
};

const readFolderNodesBatch = reader => {
  return new Promise((success, reject) => {
    reader.readEntries(entries => {
      let nodes = [];
      const promises = entries.map(createNodeFromEntry);

      return Promise.all(promises)
        .then(readNodes => {
          success(nodes.concat(readNodes));
        })
        .catch(error => reject(error));
    });
  });
};

const goToDocument = (pathType, documentId) => {
  browserHistory.push(getBasePath() + '/' + pathType + '/' + documentId);
};

const orderNodesByPath = (nodeIds, nodes) => {
  const orderedNodes = nodeIds.map(nodeId => nodes[nodeId]);

  const pathLevel = getPathLevel(orderedNodes[0].path);

  return orderedNodes.sort(
    (node1, node2) =>
      getPosition(pathLevel, node1.path) - getPosition(pathLevel, node2.path)
  );
};

const nodesAreConsecutive = nodes => {
  const pathLevel = getPathLevel(nodes[0].path);
  let expectedPosition = getPosition(pathLevel, nodes[0].path);

  for (let node of nodes) {
    if (getPosition(pathLevel, node.path) !== expectedPosition) {
      return false;
    }

    expectedPosition += 1;
  }

  return true;
};

export const actionDoesNothing = (sourceNode, positionType, state) => {
  const draggingNodeIds = state.dataroom.working.draggingNodeIds;
  if (draggingNodeIds.length === 0) {
    return true;
  }

  const orderedNodes = orderNodesByPath(
    draggingNodeIds,
    state.dataroom.working.nodes
  );
  const draggingNodesPathLevel = getPathLevel(orderedNodes[0].path);

  if (!nodesAreConsecutive(orderedNodes)) {
    return false;
  }

  if (positionType === BEFORE) {
    const nextPathForLastDraggingNode = updatePath(
      draggingNodesPathLevel,
      orderedNodes[orderedNodes.length - 1].path,
      position => position + 1
    );
    if (nextPathForLastDraggingNode === sourceNode.path) {
      return true;
    }
  }

  if (positionType === INSIDE) {
    // dropping consecutive nodes beginning by path 01 inside of parent folder will do nothing
    return (
      sourceNode.path === getParentPath(orderedNodes[0].path) &&
      getPosition(draggingNodesPathLevel, orderedNodes[0].path) === 1
    );
  }

  if (positionType === AFTER) {
    const previousPathForFirstDraggingNode = updatePath(
      draggingNodesPathLevel,
      orderedNodes[0].path,
      position => position - 1
    );

    if (previousPathForFirstDraggingNode === sourceNode.path) {
      return true;
    }
  }

  return false;
};

const itemIsNode = item => item.type !== 'undefined' && item.type === 'NODE';

export const getPositionType = (component, monitor) => {
  const domNode = findDOMNode(component);

  // TODO: use getSourceClientOffset of monitor ?
  const hoverBoundingRect = domNode.getBoundingClientRect();
  const clientOffset = monitor.getClientOffset();
  const hoverClientY = clientOffset.y - hoverBoundingRect.top;
  const componentHeight = 33;

  if (component.props.node.path === '0') {
    return INSIDE;
  }

  if (
    component.props.node.type === TYPE_DOCUMENT &&
    itemIsNode(monitor.getItem())
  ) {
    const hoverMiddleY = componentHeight / 2;
    return hoverClientY > hoverMiddleY ? AFTER : BEFORE;
  }

  const hoverTenthY = componentHeight / 10;

  if (hoverClientY < hoverTenthY * 4) {
    return BEFORE;
  }

  if (
    (hoverClientY > hoverTenthY * 8 &&
      (!itemIsNode(monitor.getItem()) ||
        component.props.node.childIds.length === 0 ||
        component.props.node.folded)) ||
    hoverClientY > componentHeight
  ) {
    return AFTER;
  }

  return INSIDE;
};

const getDroppedNodesNumber = nodes => {
  return nodes.reduce((acc, node) => {
    if (node.type === TYPE_DOCUMENT) {
      return acc + 1;
    }

    return acc + getDroppedNodesNumber(node.children) + 1;
  }, 0);
};

const canDropNode = props => {
  const state = store.getState();

  // TODO memoize ?

  if (state.dataroom.working.draggingNodeIds.includes(props.node.id)) {
    return false;
  }

  for (const draggingNodeId of state.dataroom.working.draggingNodeIds) {
    const draggingNodePath = state.dataroom.working.nodes[draggingNodeId].path;

    if (props.node.path.startsWith(`${draggingNodePath}.`)) {
      return false;
    }
  }

  return true;
};

const getUploadingFilesNumber = uploads => {
  if (!uploads) {
    return 0;
  }

  return uploads.reduce((acc, upload) => {
    return acc + upload.uploadingDocuments + upload.uploadedDocuments;
  }, 0);
};

export const nodeTarget = {
  canDrop(props, monitor) {
    return !props.anonymous && props.canEditDataroom;
  },

  drop(props, monitor, component) {
    const item = monitor.getItem();
    const sourceNode = monitor.getItem().sourceNode;

    if (monitor.didDrop()) {
      return;
    }

    const positionType = getPositionType(component, monitor);

    if (itemIsNode(item)) {
      if (!canDropNode(props)) {
        props.endDragAction(sourceNode.id);
        return;
      }
    }

    const state = store.getState();

    if (itemIsNode(item)) {
      if (actionDoesNothing(props.node, positionType, state)) {
        props.endDragAction(sourceNode.id);
        return;
      }

      if (sourceNode.id === item.id) {
        props.endDragAction(sourceNode.id);
        return;
      }

      const draggingNodes = state.dataroom.working.draggingNodeIds.map(
        nodeId => state.dataroom.working.nodes[nodeId]
      );

      props.endDragAction(sourceNode.id);

      return props.moveNodesAction(draggingNodes, props.node, positionType);
    }

    const entries = item.files.map(file => file.webkitGetAsEntry());

    if (
      entries.length === 1 &&
      entries[0] &&
      entries[0].isFile &&
      component.props.node.type === TYPE_DOCUMENT &&
      positionType === INSIDE
    ) {
      return entries[0].file(readFile => {
        if (component.props.node.uploading) {
          return props.fileAlreadyUploadingAction(component.props.node);
        }

        if (isFileAllowed(readFile.name)) {
          if (component.props.node.file && component.props.node.file.editedAt) {
            return props.confirmAttachFileToDocumentHavingFileAction(
              props.node.id,
              readFile
            );
          }
          return props.attachFileToDocumentAction(props.node.id, readFile);
        }

        return props.fileNotAllowedAction(readFile.name);
      });
    }

    if (
      positionType === INSIDE &&
      component.props.node.type === TYPE_DOCUMENT
    ) {
      props.cantAttachMultipleFilesToDocument();
      return;
    }

    let promises = null;
    try {
      promises = entries.map(createNodeFromEntry);
    } catch (error) {
      props.dragAndDropErrorAction();
      console.error(error);
      return;
    }

    props.preparingUploadAction();

    Promise.all(promises)
      .then(files => {
        const droppedNodesNumber = getDroppedNodesNumber(files);
        if (droppedNodesNumber > MAX_DROPED_NODES) {
          return props.tooManyFilesDroppedAction(droppedNodesNumber);
        }

        const uploadingFilesNumber = getUploadingFilesNumber(
          state.dataroom.working.uploads
        );

        if (uploadingFilesNumber + droppedNodesNumber > MAX_DROPED_NODES) {
          return props.tooManyUploadForDropAction(
            uploadingFilesNumber,
            droppedNodesNumber
          );
        }

        const importType = getImportType(files);

        if (importType !== IMPORT_TYPE_CLASSIC) {
          return props.confirmImportFilesAction(
            files,
            props.node,
            positionType,
            importType
          );
        }

        if (
          files.length === 1 &&
          files[0].type === TYPE_DOCUMENT &&
          files[0].file.type === 'application/zip'
        ) {
          // too heavy zip can crash browser when extracting before uploading so we block zip with size > 1.5 GB
          if (files[0].file.size < 1.5 * 1000 ** 3) {
            props.confirmExtractZipAction(files[0], props.node, positionType);
            return;
          } else {
            props.addNotificationReminderAction(
              "L'archive est trop volumineuse pour être extraite automatiquement. Faites un clic droit sur la ligne pour lancer l'extraction une fois que l'envoi du fichier est terminé.",
              TYPE_WARNING
            );
          }
        }

        props.addFilesAction(files, props.node, positionType);
      })
      .catch(error => {
        props.dragAndDropErrorAction();
        console.error(error);
      });
  },

  hover(props, monitor, component) {
    if (component.props.isOver && canDropNode(props)) {
      component
        .getDecoratedComponentInstance()
        .changeHoverPosition(getPositionType(component, monitor));
      return;
    }

    // reset hover position when draggin item is not hover anymore
    if (
      !component.props.isOver &&
      component.getDecoratedComponentInstance().state.hoverPosition
    ) {
      component
        .getDecoratedComponentInstance()
        .setState({ hoverPosition: null });
    }
  },
};

let image = null;

const generateMultipleDragAndDropImageCache = () => {
  if (image) {
    return;
  }

  image = new Image();
  image.src =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAhCAYAAAAh3nDkAAAABHNCSVQICAgIfAhkiAAAABl0RVh0U29mdHdhcmUAZ25vbWUtc2NyZWVuc2hvdO8Dvz4AAAKrSURBVFiF5ddZSFRRHMfx71yu3aFxVLAHHc0gMBCkBQKJisoWQttU0DIVgxZpIQyTlhEhXCJIrB5CC9tteSg0KYModQwfSkTRzDQl0dTRMZcZuejo7SGyhsl8mMXA3+P/nMOH83D+f45KURSFOYowV7ANPjU1xfsPdbS0tLkXt1qt5FzM55w+i3sPHrsXL7pdjKG6xm2oDb4vLpoz6SfdjosAWq0n69aG/XVDdm4eZrPFYWjrlo2Eb1pvjwPM9OIqKt85DAPodH52uABQ39BISelLAHp6jTx9Voaxf8Ap6L8iAFQZaii8eReAzs4urhfcoqvrGwAazUKnQFpPT7uaat52OBGgo+MrV64V0trWTmCAPyeOHSI0NMTluEpRFCUtPZMv7R2sWrkcQ3UNPt5ePHlUhEqlwjT4HVmWHYYW+foiSQvscVmWMRoHCAzUEZ94BJNpkOclxaglie2RsUxOTjqMx0TvJOVwsk1NAFCr1Xh5aTmfkY3JNMieXRGoJQnAKTDAxMSEXU0E6O7uITVNz8jIKAeS44nfG+MUcLaI8LOFDg+PcPnSBaYUhf1JKejPniIkZBlJCXGMms0OQ2vCVtvjsizT2tYOQGqafnphfGIcgMSEWIfhmSJKksTxowdRsO01ATp/l6G/MqcdTgTIv1pAX5/RZiE3OwOArJw8LBYXjtTGpmaGhoYJDl5KbW09Wu3vIVBZ5bqRKgJE7Y5Ao9FQV9eAIAhk6k87BZwtAkBkxDZEUeRF+Wu0Wk/MlrHpDW4ZqUZjP+Wv3lBaVo7FMsbD+4X4+Hg7BZ4pAkDTx08MmAaJjtrBkqBArFYrlj9u76qIAAU37tDc/Hm6uGJ5KDqdn8txlaIoitls4W2FgZ5eI0GLA9gcvgEPD3H2087AXa7MkP/jozjv8B/+qwKOzHZgowAAAABJRU5ErkJggg==\n';
};

const getMultipleDragAndDropImage = () => {
  if (!image) {
    generateMultipleDragAndDropImageCache();
  }

  return image;
};

const nodeSource = {
  canDrag(props) {
    return (
      !props.anonymous &&
      props.canEditDataroom &&
      props.node.path !== '0' &&
      props.editionMode === EDITION_MODE_ALLOWED
    );
  },
  beginDrag(props, monitor, component) {
    if (!props.node.dragging) {
      props.beginDragAction(props.node.id);

      if (component.props.node.multipleDrag) {
        component.props.connectDragPreview(getMultipleDragAndDropImage());
      } else {
        component.props.connectDragPreview();
      }
    }

    return {
      sourceNode: props.node,
      type: 'NODE',
    };
  },

  endDrag(props, monitor, component) {
    if (props.node.dragging) {
      props.endDragAction(props.node.id);
    }
  },
};

const getTextColorForDragItem = dragItem => {
  if (
    dragItem.length === 0 ||
    (dragItem[0].type === TYPE_DOCUMENT && dragItem[0].file)
  ) {
    return '#00f';
  }

  if (dragItem[0].type === TYPE_DOCUMENT) {
    return '#000';
  }

  return '#f00';
};

const FuturePositionHiglighter = props => {
  const style = {
    height: '20px',
    width: '100%',
    backgroundColor: 'rgba(46, 117, 191, 0.3)',
  };

  const futurePositionStyle = {
    fontSize: '19px',
    color: getTextColorForDragItem(props.dragItem),
    paddingLeft: '19px',
    fontWeight: '600',
  };

  style.marginBottom = props.positionType === BEFORE ? '3px' : '4px';

  return (
    <div style={style}>
      <span style={futurePositionStyle}>{props.path}</span>
    </div>
  );
};

const nodeStyleWhenDragging = { opacity: '0.5', padding: '0' };

const nodeStyleWhenNotDragging = { padding: '0' };

@DropTarget(['NODE', FILE], nodeTarget, (connect, monitor, component) => {
  const isOver = monitor.isOver({ shallow: true });

  const data = {
    connectDropTarget: connect.dropTarget(),
    isOver,
  };

  if (isOver) {
    const state = store.getState();

    data.offset = monitor.getSourceClientOffset();
    data.currentItemsDragged = getDraggingNodes(state);
  }

  return data;
})
@DragSource('NODE', nodeSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging(),
}))
class TreeNode extends Component {
  constructor(props) {
    super(props);
    this.changeHoverPosition = this.changeHoverPosition.bind(this);
    this.shouldComponentUpdate = this.shouldComponentUpdate.bind(this);
    this.goToDocument = this.goToDocument.bind(this);
    this.foldNode = this.foldNode.bind(this);
    this.unfoldNode = this.unfoldNode.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleDoubleClick = this.handleDoubleClick.bind(this);
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleChangeSelected = this.handleChangeSelected.bind(this);
    this.state = {
      hoverPosition: null,
    };
    generateMultipleDragAndDropImageCache();
  }

  handleMouseDown(evt) {
    if (evt.shiftKey) {
      evt.preventDefault();
    }
  }

  foldNode(evt) {
    if (evt.ctrlKey) {
      this.props.foldRecursive(this.props.node.id);
      return;
    }
    this.props.fold(this.props.node);
  }

  unfoldNode(evt) {
    if (evt.ctrlKey) {
      this.props.unFoldRecursive(this.props.node.id);
      return;
    }
    this.props.unFold(this.props.node);
  }

  handleChangeSelected(evt) {
    if (evt.currentTarget.checked) {
      return this.props.selectNode(this.props.node.id);
    }
    this.props.unSelectNode(this.props.node.id);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const propsAreDifferent = nextProps !== this.props;
    const stateIsDifferent = nextState !== this.state;

    if (stateIsDifferent) {
      return true;
    }

    if (propsAreDifferent) {
      return (
        nextProps.node !== this.props.node ||
        nextProps.isOver !== this.props.isOver ||
        nextProps.isDragging !== this.props.isDragging ||
        nextProps.anonymous !== this.props.anonymous ||
        nextProps.picked !== this.props.picked ||
        nextProps.dragging !== this.props.dragging ||
        nextProps.offset !== this.props.offset
      );
    }

    return false;
  }

  changeHoverPosition(hoverPosition) {
    if (this.state.hoverPosition !== hoverPosition) {
      this.setState({
        hoverPosition,
      });
    }
  }

  handleDoubleClick(evt) {
    if (this.props.node.type === TYPE_DOCUMENT && this.props.node.file) {
      evt.preventDefault();
      document.getSelection().removeAllRanges();
      this.goToDocument(this.props.node);
    }
  }

  handleClick(e) {
    // click on icon
    if (e.target.classList.contains('o')) {
      return;
    }

    // click on icon
    if (e.target.parentNode.classList.contains('o')) {
      return;
    }

    // click on checkbox
    if (e.target.tagName === 'INPUT' && e.target.type === 'checkbox') {
      return;
    }

    if (this.props.anonymous) {
      return;
    }

    if (this.props.editionMode === EDITION_MODE_FORBIDDEN) {
      return;
    }

    e.stopPropagation();

    if (this.props.node.rename) {
      return;
    }

    if (e.shiftKey) {
      if (this.props.node.picked) {
        return;
      }

      this.props.addNodesBetweenPickedAction(this.props.node.id);
      return;
    }

    if (e.ctrlKey) {
      if (!this.props.node.picked) {
        this.props.addNodeToPickedAction(this.props.node.id);
        return;
      }

      this.props.removeNodeToPickedAction(this.props.node.id);
      return;
    }

    this.props.pickNodeAction(this.props.node.id);
  }

  goToDocument(document) {
    if (document.file.size) {
      this.props.loadDocument(document, this.props.anonymous);
    }

    goToDocument('documents', document.id);
  }

  render() {
    let node = null;

    const nodeItem = this.props.node;
    const {
      isOver,
      isDragging,
      canEditDataroom,
      connectDragSource,
      connectDropTarget,
    } = this.props;

    const { hoverPosition } = this.state;

    let checkBox = (
      <TreeNodeCheckbox
        onChange={this.handleChangeSelected}
        isSelected={nodeItem.selected}
      />
    );

    const baseArguments = {
      checkBoxElement: checkBox,
    };

    switch (nodeItem.type) {
      case TYPE_FOLDER:
        node = (
          <FolderNode
            {...baseArguments}
            unFoldAction={this.unfoldNode}
            foldAction={this.foldNode}
            folder={nodeItem}
            hasDrop={canEditDataroom && isOver && hoverPosition === INSIDE}
            TreeNodeComponent={ConnectedTreeNode}
          />
        );
        break;
      case TYPE_DOCUMENT:
        node = (
          <DocumentNode
            {...baseArguments}
            goToDocumentAction={this.goToDocument}
            document={nodeItem}
            hasDrop={canEditDataroom && isOver && hoverPosition === INSIDE}
          />
        );
        break;
      default:
        throw new Error('unknow type ' + nodeItem.type);
    }

    let className = getClassesForNode(nodeItem);

    if (isDragging) {
      className = `${className} dragging`;
    }

    if (canEditDataroom && isOver && hoverPosition === INSIDE) {
      className = `${className} dropInside`;
    }

    if (nodeItem.picked) {
      className = `${className} nodePicked`;
    }

    return connectDragSource(
      connectDropTarget(
        <li
          onMouseDown={this.handleMouseDown}
          onDoubleClick={this.handleDoubleClick}
          onClick={this.handleClick}
          style={
            nodeItem.dragging ? nodeStyleWhenDragging : nodeStyleWhenNotDragging
          }
          className={className}
          id={nodeItem.id}
        >
          {canEditDataroom && isOver && hoverPosition === 'BEFORE' && (
            <FuturePositionHiglighter
              path={getNextPath(nodeItem.path, BEFORE)}
              dragItem={this.props.currentItemsDragged}
              positionType={BEFORE}
            />
          )}
          {node}
          {canEditDataroom && isOver && hoverPosition === 'AFTER' && (
            <FuturePositionHiglighter
              path={getNextPath(nodeItem.path, AFTER)}
              positionType={AFTER}
              dragItem={this.props.currentItemsDragged}
            />
          )}
        </li>
      ),
      {
        dropEffect: 'move',
      }
    );
  }
}

TreeNode.propTypes = {
  nodeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const getNode = (state, props) => state.dataroom.working.nodes[props.nodeId];

const makeSelector = () => {
  return createSelector(
    [
      getNode,
      state => state.dataroom.anonymous,
      canEditDataroom,
      editionModeSelectorFromDataRoom,
    ],
    (node, anonymous, canEditDataroom, editionMode) => ({
      node,
      anonymous,
      canEditDataroom,
      editionMode,
    })
  );
};

function mapStateToProps(state, ownProps) {
  return makeSelector(state, ownProps);
}

const ConnectedTreeNode = connect(
  mapStateToProps,
  {
    fold,
    unFold,
    selectNode,
    loadDocument,
    addFilesAction,
    dragAndDropErrorAction,
    preparingUploadAction,
    tooManyFilesDroppedAction,
    tooManyUploadForDropAction,
    unSelectNode,
    foldRecursive,
    unFoldRecursive,
    addNodesBetweenPickedAction,
    addNodeToPickedAction,
    pickNodeAction,
    confirmExtractZipAction,
    removeNodeToPickedAction,
    beginDragAction,
    endDragAction,
    addNotificationReminderAction,
    moveNodesAction,
    attachFileToDocumentAction,
    confirmImportFilesAction,
    cantAttachMultipleFilesToDocument,
    confirmAttachFileToDocumentHavingFileAction,
    fileAlreadyUploadingAction,
    fileNotAllowedAction,
  },
  undefined,
  {
    areStatePropsEqual: (oldProps, newProps) => oldProps === newProps,
  }
)(TreeNode);

export default ConnectedTreeNode;
