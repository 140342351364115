import styled from 'styled-components';
import { PRIMARY_COLOR } from '../theme_blue';

export default styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${props =>
    props.background
      ? props.theme[props.background]
      : props.theme[PRIMARY_COLOR]};
  ${props => (props.forOnePage ? null : 'padding-top: 2.5em;')}
  ${props => (props.forOnePage ? 'padding-left: .25em;' : null)}
  ${props => (props.forOnePage ? 'padding-right: .25em;' : null)}
  ${props => (props.forOnePage ? 'position: absolute;' : null)}
  ${props => (props.forOnePage ? 'bottom: -1px;' : null)}
  ${props => (props.forOnePage ? 'left: 0px;' : null)}
  ${props => (props.forOnePage ? 'z-index: 1;' : null)}
`;
