import ReactDOM from 'react-dom';
import React, { Fragment } from 'react';
import { Route, browserHistory } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';
import createStore from './dataroom/app/createStore';
import AppDataRoom from './dataroom/dataroom/containers/App';
import { Provider } from 'react-redux';
import { withProps } from 'recompose';
import Router from './dataroom/app/components/Router';
import { FILE_TAB } from './dataroom/document/components/DocumentTabs';
import AppDocument from './dataroom/document/containers/App';
import Notifications from './dataroom/app/containers/Notifications';
import Dataroom from './dataroom/pages/Dataroom';
import AuthDataProvider from './app/hooks/authDataProvider';
import MyAccount from './user/pages/MyAccount';
import MyProfile from './user/pages/MyProfile';
import User from './user/pages/User';
import DataRoomCreation from './home/pages/DataRoomCreation';
import DataRoomEdition from './home/pages/DataRoomEdition';
import UserCreation from './user/pages/UserCreation';
import DataRoom from './home/pages/DataRoom';
import UserPasswordUpdate from './user/pages/UserPasswordUpdate';
import UserEdition from './user/pages/UserEdition';
import NotFound from './app/pages/NotFound';
import RequestResetPassword from './user/pages/RequestResetPassword';
import ResetPassword from './user/pages/ResetPassword';

const EnhancedAppDataRoom = withProps(props => ({
  ...props,
  routeParams: {
    ...props.routeParams,
    admin: true,
    anonymous: props.location.pathname.indexOf('fullscreen') !== -1,
  },
}))(AppDataRoom);

const EnhancedAppDocument = withProps(props => ({
  ...props,
  routeParams: {
    ...props.routeParams,
    tab: FILE_TAB,
  },
}))(AppDocument);

export const store = createStore();
const history = syncHistoryWithStore(browserHistory, store);

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <Route components={AuthDataProvider}>
        <Route path="/" component={DataRoom} />
        <Route
          path="/request-reset-password"
          component={RequestResetPassword}
        />
        <Route path="/reset-password" component={ResetPassword} />
        <Route path="/datarooms/creation" component={DataRoomCreation} />
        <Route
          path="/datarooms/edition/:dataroomId"
          component={DataRoomEdition}
        />
        <Route path="/me" component={MyAccount} />
        <Route path="/profile" component={MyProfile} />
        <Route path="/users" component={User} />
        <Route path="/users/creation" component={UserCreation} />
        <Route path="/users/:userId/edition" component={UserEdition} />
        <Route
          path="/users/:id/edition/password"
          component={UserPasswordUpdate}
        />
        <Route path="/datarooms" component={Dataroom}>
          <Route
            useScroll
            path=":id(/fullscreen)"
            component={EnhancedAppDataRoom}
          >
            <Route
              path="documents/:documentId"
              component={EnhancedAppDocument}
            />
          </Route>
        </Route>
        <Route path="*" component={NotFound} />
      </Route>
    </Router>
  </Provider>,
  document.getElementById('content')
);
