/**
 * update the path with modifier
 * for those params
 * pathLevel: 2
 * path: 1.2.3
 * modifier: position => position + 1
 *
 * the result will be 1.3.3
 * @param pathLevel
 * @param path
 * @param modifier function take in param the position for the pathLevel and return the modified position
 * @returns {string}
 */
export default function(pathLevel, path, modifier) {
  let positions = path.split('.');

  let newPosition = modifier(parseInt(positions[pathLevel - 1], 10));
  if (newPosition < 10) {
    newPosition = `0${newPosition}`;
  }

  positions[pathLevel - 1] = newPosition;

  return positions.join('.');
}
