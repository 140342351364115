import Basil from '../../../../../node_modules/basil.js/build/basil';

class DataroomStateRepository {
  constructor(identitifier) {
    this.basil = new Basil({
      namespace: `${identitifier}-working`,
    });
  }

  getNodesState() {
    if (!this.nodesState) {
      this.nodesState = this.basil.get('nodesState') || {};
    }

    return this.nodesState ? this.nodesState : {};
  }

  setNodesFoldStatus(nodeIds, isFolded) {
    let nodesState = this.getNodesState();

    nodeIds.forEach(nodeId => {
      nodesState[nodeId] = Object.assign({ folded: isFolded });
    });

    this.basil.set('nodesState', nodesState);
  }

  setNodeFoldStatus(nodeId, isFolded) {
    let nodesState = this.basil.get('nodesState');
    const nodeState = {};
    nodeState[nodeId] = { folded: isFolded };
    this.basil.set('nodesState', Object.assign({}, nodesState, nodeState));
    this.nodesState = Object.assign({}, nodesState, nodeState);
  }
}

export default DataroomStateRepository;
