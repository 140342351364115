import { uniqueId } from 'underscore';
import { takeEvery, select, call } from 'redux-saga/effects';
import createUndoableActionReducer from '../reducers/createUndoableActionReducer';
import { removeAndSaveNodes, undoRemoveNode } from './utils/removeNodes';
import undoSaga from './dataroomActions/undoSaga';
import apiClient from '../../core/apiClient';
import { TYPE_FOLDER } from '../documents/type';
export const REMOVE_EMPTY_FOLDER_NODES = 'REMOVE_EMPTY_FOLDER_NODES';

export const removeEmptyFolderNodesAction = parentNodeId => {
  return {
    type: REMOVE_EMPTY_FOLDER_NODES,
    parentNodeId: parentNodeId,
    frontActionId: uniqueId('dataroom_action'),
  };
};

export const getEmptyFolderChildNodes = (parentId, childNodeIds, nodes) => {
  let emptyChildNodes = [];
  childNodeIds.forEach(nodeId => {
    const node = nodes[nodeId];
    if (node.type === TYPE_FOLDER) {
      if (node.childIds.length === 0) {
        emptyChildNodes.push(node.id);
        return;
      }
      emptyChildNodes = emptyChildNodes.concat(
        getEmptyFolderChildNodes(node.id, node.childIds, nodes)
      );
    }
  });
  if (folderContainOnlyEmptyFolder(childNodeIds, emptyChildNodes)) {
    emptyChildNodes.push(parentId);
  }
  return emptyChildNodes;
};

const folderContainOnlyEmptyFolder = (childNodeIds, emptyChildNodes) => {
  for (let i = 0; i < childNodeIds.length; i++) {
    if (emptyChildNodes.indexOf(childNodeIds[i]) !== -1) {
      continue;
    }
    return false;
  }
  return true;
};
const removeEmptyFolderNodeApi = (action, { dataroomId }) => {
  return apiClient.request(
    new Request(`/api/datarooms/${dataroomId}/nodes/empty_folder_child`, {
      method: 'DELETE',
      body: JSON.stringify({
        parentNodeId: action.parentNodeId,
      }),
    })
  );
};

export function* saga(action) {
  const selectedState = yield select(state => ({
    dataroomId: state.dataroom.dataroom.id,
  }));

  return yield call(removeEmptyFolderNodeApi, action, selectedState);
}

export function* undoActionSaga() {
  yield takeEvery('UNDO_REMOVE_EMPTY_FOLDER_NODES', undoSaga);
}

function removeEmptyFolderNodes(state, action) {
  let newState = {
    ...state,
    working: {
      ...state.working,
      savedNodes: {
        ...state.working.savedNodes,
        [action.frontActionId]: {},
      },
    },
  };

  const nodeIds = getEmptyFolderChildNodes(
    state.working.nodes[action.parentNodeId].id,
    state.working.nodes[action.parentNodeId].childIds,
    state.working.nodes
  );

  newState = removeAndSaveNodes(newState, nodeIds, action.frontActionId, false);
  newState = {
    ...newState,
    working: {
      ...newState.working,
      savedNodes: {
        ...newState.working.savedNodes,
        [action.frontActionId]: {
          ...newState.working.savedNodes[action.frontActionId],
          nodeIds: nodeIds,
        },
      },
    },
  };
  return newState;
}

function undoRemoveEmptyFolderNodes(state, action) {
  let newState = { ...state };
  [...state.working.savedNodes[action.frontActionId].nodeIds]
    .reverse()
    .forEach(nodeId => {
      newState = undoRemoveNode(newState, nodeId, action.frontActionId, false);
    });

  return newState;
}

export default createUndoableActionReducer(
  REMOVE_EMPTY_FOLDER_NODES,
  removeEmptyFolderNodes,
  undoRemoveEmptyFolderNodes
);
