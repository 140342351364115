import React, { Fragment, useState } from 'react';
import theme_blue, {
  BODY_FONT,
  CLIENT_UI_COLOR,
  DARK_GREY_COLOR,
  LIGHT_COLOR,
  LIGHT_GREY_COLOR,
  MID_GREY_COLOR,
} from '../../../presentational/components/theme_blue';
import Button from '../../../presentational/components/Button';
import P from '../../../presentational/components/P';
import Form from '../../../presentational/components/form/Form';
import Alert from '../../../presentational/components/Alert';
import IconSpam from '../../../presentational/components/img/icons/IconSpam';
import { useForm } from 'react-hook-form';
import Input from '../../../presentational/components/form/Input';
import GridCol from '../../../presentational/components/GridCol';
import A from '../../../presentational/components/A';
import { browserHistory, Link } from 'react-router';
import GridRow from '../../../presentational/components/GridRow';
import useNotification from '../../../app/hooks/notification';
import { TYPE_ERROR } from '../../../dataroom/app/reducers/notifications';

export default function RequestResetPasswordForm(props) {
  const { onSendNotificationReminder } = useNotification();
  const [hasError, setHasError] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
    setError,
  } = useForm({
    criteriaMode: 'all',
    defaultValues: {
      email: null,
    },
  });

  const onSubmit = async data => {
    const response = await fetch('/api/user/request-reset-password', {
      method: 'POST',
      body: JSON.stringify({
        email: data.email?.trim(),
      }),
    });

    if (response.ok) {
      onSendNotificationReminder('Demande de récupération envoyée');
      browserHistory.push('/');

      return;
    }

    if (
      response.status === 400 &&
      response.headers.get('Content-Type') === 'application/json'
    ) {
      const formErrors = await response.json();
      formErrors.forEach(error => {
        setError(error.propertyPath, {
          type: 'manual',
          types: {
            manual: error.message,
          },
        });
      });
      return;
    }

    if (
      response.status === 400 &&
      response.headers.get('Content-Type') === 'application/json+error'
    ) {
      const error = await response.json();
      setHasError(error.message);
      return;
    }

    onSendNotificationReminder('La demande a échoué', TYPE_ERROR);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Input
        {...register('email', { required: 'Le mail est obligatoire' })}
        type="text"
        label="Email"
        isRequired
        displayBlock
        isHorizontal
        isLabelBold
        formHelper="Saisissez votre email, nous vous enverrons la procédure de récupération"
        errors={errors.email?.types}
      />
      <GridRow>
        <GridCol md={40 / 100}>
          <A as={Link} to={'/'}>
            <Button
              type="cancel"
              displayBlock
              backgroundColor={DARK_GREY_COLOR}
              borderColor={DARK_GREY_COLOR}
              backgroundHoverColor={LIGHT_COLOR}
            >
              Annuler
            </Button>
          </A>
        </GridCol>
        <GridCol>
          <Button
            type="submit"
            displayBlock
            disabled={isSubmitting}
            backgroundColor={CLIENT_UI_COLOR}
            borderColor={CLIENT_UI_COLOR}
            backgroundHoverColor={LIGHT_COLOR}
          >
            Regénérer un mot de passe
          </Button>
        </GridCol>
      </GridRow>
      {hasError && (
        <Alert alertType="danger" role="alert">
          <Alert.ContentWithOrWithoutIcon>
            <P marginTop="1rem" marginBottom="0">
              <IconSpam marginRight="1em" width="1.5em" marginTop="-.125em" />
            </P>
            <Alert.ContentText>
              <P marginTop="1rem" marginBottom="1rem">
                {Translator.trans(hasError, {}, 'messages')}
              </P>
            </Alert.ContentText>
          </Alert.ContentWithOrWithoutIcon>
        </Alert>
      )}
    </Form>
  );
}
