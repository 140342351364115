import { createSelector } from 'reselect';

export default createSelector(
  state => state.dataroom.working.lock.lockedByMe,
  state => state.dataroom.working.renameNode.nodeNameEditable,
  state => state.dataroom.working.addNodes.show,
  state => state.dataroomActions.preparingActions,
  (lockedByMe, nodeNameEditable, showAddNodesForm, preparingActions) =>
    lockedByMe &&
    !nodeNameEditable &&
    !showAddNodesForm &&
    preparingActions.length === 0
);
