import React, { Component } from 'react';
import styled from 'styled-components';

const H2 = styled.h2`
  font-size: 1.25rem;
  display: flex;
  align-items: flex-start;
  line-height: 1.5rem;
  ${props => (props.textAlign ? 'text-align: ' + props.textAlign + ';' : null)}
  width: 100%;
  ${props => (props.marginTop ? 'margin-top: ' + props.marginTop + ';' : null)}
  ${props =>
    props.marginBottom ? 'margin-bottom: ' + props.marginBottom + ';' : null}
  @media (min-width: ${props => props.theme.tablet}) {
    margin-top: ${props => (props.marginTop ? props.marginTop : '1.5em')};
    margin-bottom: ${props =>
      props.marginBottom ? props.marginBottom : '1.5em'};
  }
`;

export default H2;
