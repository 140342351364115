import React from 'react';
import Svg from './Svg';

export default function IconChevronLeftHeavy(props) {
  return (
    <Svg
      {...props}
      d="M92.039 128l108.78 97.902-17.638 17.298-128-115.2 128-115.2 17.638 17.298L92.039 128z"
    />
  );
}
