import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
  padding: 2em 1rem 0;
  align-self: flex-start;
  position: relative;
  width: 100%;
`;
