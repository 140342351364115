import React, { Fragment, useMemo } from 'react';
import moment from 'moment';
import Span from '../../presentational/components/Span';

export function Date(props) {
  const { format, date } = props;

  const formatedDate = useMemo(() => {
    let momentDate;
    if (typeof date.getMonth === 'function') {
      momentDate = moment(date.getTime());
    } else {
      momentDate = moment.unix(date);
    }

    return momentDate.locale(document.documentElement.lang).format(format);
  }, [date]);

  return <Span>{formatedDate}</Span>;
}
