import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Item from '../../../presentational/components/editor/treeview/Item';
import IconDocument from '../../../presentational/components/editor/treeview/IconDocument';
import ItemLabel from '../../../presentational/components/editor/treeview/ItemLabel';
import ItemNumber from '../../../presentational/components/editor/treeview/ItemNumber';
import {
  PDF_COLOR,
  PRIMARY_COLOR,
} from '../../../presentational/components/theme_blue';
import updatePath from '../../dataroom/ducks/utils/updatePath';
import getPathLevel from '../../dataroom/ducks/utils/getPathLevel';
import {
  addNodeToPickedAction,
  removeNodeToPickedAction,
  pickNodeAction,
  resetPickNodeAction,
} from '../ducks/pickNode';

const getRangeFromArray = numbers => {
  let ranges = [];
  let start = numbers[0];
  let end = numbers[0];

  numbers.map(range => {
    if (range - end > 1) {
      ranges.push(start === end ? start : start + '-' + end);
      start = range;
    }

    end = range;
  });

  ranges.push(start === end ? start : start + '-' + end);

  return ranges;
};

class Document extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.listenClick = this.listenClick.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.listenClick);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.listenClick);
  }

  listenClick(e) {
    if (e.target.parentNode.classList.contains('new-node')) {
      return;
    }

    if (e.target.classList.contains('new-node')) {
      return;
    }

    this.props.resetPickNodeAction();
  }

  handleClick(e) {
    e.stopPropagation();

    if (e.ctrlKey) {
      if (!this.props.newNode.picked) {
        this.props.addNodeToPickedAction(this.props.newNode.nodeId);
        return;
      }

      this.props.removeNodeToPickedAction(this.props.newNode.nodeId);
      return;
    }

    this.props.pickNodeAction(this.props.newNode.nodeId);
  }

  render() {
    const { index, newNode, to } = this.props;

    return (
      <Item
        className="new-node"
        picked={newNode.picked}
        onClick={this.handleClick}
      >
        <IconDocument color={PDF_COLOR} />
        <ItemNumber color={PDF_COLOR}>
          {updatePath(getPathLevel(to), to, pathNumber => pathNumber + index)}
        </ItemNumber>
        <ItemLabel color={PDF_COLOR}>{newNode.nodeName}</ItemLabel>
        <ItemLabel color={PRIMARY_COLOR}>
          [{getRangeFromArray(newNode.pageNumbers).join(', ')}]
        </ItemLabel>
      </Item>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    loaded: state.pdfExplode.loaded,
    to: state.pdfExplode.to,
  };
}

export default connect(mapStateToProps, {
  pickNodeAction,
  removeNodeToPickedAction,
  addNodeToPickedAction,
  resetPickNodeAction,
})(Document);
