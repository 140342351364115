import React, { Component } from 'react';
import { Alert } from 'react-bootstrap';

class DataroomCorruptionInfo extends Component {
  render() {
    const { corrupted } = this.props;

    if (!corrupted) {
      return null;
    }

    return (
      <Alert bsStyle="danger">
        <strong>
          {' '}
          <i className="fa fa-exclamation-circle" aria-hidden="true" /> La
          dataroom connait un problème de cohérence, l'équipe technique est
          informée et revient vers vous au plus vite
        </strong>
      </Alert>
    );
  }
}

export default DataroomCorruptionInfo;
