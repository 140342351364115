import reduceReducers from 'reduce-reducers';
import pdfjs from 'pdfjs-dist';
import apiClient from '../../core/apiClient';
import pickPage from '../ducks/pickPage';
import pickNode from '../ducks/pickNode';
import manageNode from '../ducks/manageNode';
import updatePath from '../../dataroom/ducks/utils/updatePath';
import getPathLevel from '../../dataroom/ducks/utils/getPathLevel';
import getPosition from '../../dataroom/ducks/utils/getPosition';

pdfjs.GlobalWorkerOptions.workerSrc = '/pdfjs/build/pdf.worker.js';

const PDF_LOADED = 'EXPLODE_PDF_LOADED';
const PDF_LEAVE = 'EXPLODE_PDF_LEAVE';
const PDF_OPEN = 'EXPLODE_PDF_OPEN';
const PDF_LOAD_PAGES = 'EXPLODE_PDF_LOAD_PAGES';

const LOAD_PER_PAGES = 40;

export const leavePdfExplodeAction = () => ({
  type: PDF_LEAVE,
});

export const loadPagesAction = from => ({
  type: PDF_LOAD_PAGES,
  from: from,
});

export const openPdfExplodeAction = (node, parentNode) => ({
  type: PDF_OPEN,
  node: node,
  parentNode: parentNode,
});

function getPdfPageData(pdf, pageNumber) {
  return pdf.getPage(pageNumber).then(page => {
    return page;
  });
}

const loadDocument = buffer => {
  return pdfjs.getDocument({ data: buffer }).then(pdf => {
    let promises = [];
    for (let i = 1; i <= pdf.numPages; i++) {
      promises.push(getPdfPageData(pdf, i));
    }

    return Promise.all(promises).then(pages => {
      let formatedPages = pages.map(page => {
        return {
          pagePdf: page,
          pageNumber: page.pageNumber,
          picked: false,
        };
      });

      return {
        formatedPages: formatedPages,
        pdf: pdf,
      };
    });
  });
};

export const loadPdfExplodeAction = nodeId => {
  return dispatch => {
    apiClient
      .request(
        new Request(`/api/nodes/${nodeId}/file`, {
          method: 'GET',
        })
      )
      .then(response => response.arrayBuffer())
      .then(buffer => loadDocument(buffer))
      .then(document => {
        dispatch({
          type: PDF_LOADED,
          pages: document.formatedPages,
          pdf: document.pdf,
        });
      });
  };
};

const initialState = {
  to: null,
  pages: [],
  loadedPages: [],
  newNodes: [],
  pickedPageNumbers: [],
  pickedNodeIds: [],
  pdf: null,
  loaded: false,
  eligible: true,
  open: false,
  nodeId: null,
};

function pdfExplode(state = initialState, action = {}) {
  switch (action.type) {
    case PDF_LOAD_PAGES: {
      return {
        ...state,
        loadedPages: [
          ...state.loadedPages,
          ...state.pages.slice(action.from, action.from + LOAD_PER_PAGES),
        ],
      };
    }
    case PDF_LOADED: {
      return {
        ...state,
        loaded: true,
        pdf: action.pdf,
        loadedPages: action.pages.slice(0, LOAD_PER_PAGES),
        pages: action.pages,
      };
    }
    case PDF_OPEN: {
      let pathLevel = getPathLevel(action.node.path);
      let numberModifier =
        action.parentNode.childIds.length -
        getPosition(pathLevel, action.node.path) +
        1;

      return {
        ...state,
        to: updatePath(
          pathLevel,
          action.node.path,
          pathNumber => pathNumber + numberModifier
        ),
        nodeId: action.node.id,
        open: true,
      };
    }
    case 'PDF_EXPLODE_SUCCESS':
    case PDF_LEAVE: {
      return initialState;
    }
  }

  return state;
}

export default reduceReducers(pdfExplode, pickPage, manageNode, pickNode);
