import {
  addDraggingObject,
  draggedObjectIsOneOfPickedObject,
  resetDraggingObject,
} from '../../dataroom/utils/dragDropObject';

const BEGIN_DRAG = 'EDITOR_BEGIN_DRAG_NODE';
const END_DRAG = 'EDITOR_END_DRAG_NODE';

export const beginDragAction = pdfNumber => ({
  type: BEGIN_DRAG,
  number: pdfNumber,
});

export const endDragAction = () => ({
  type: END_DRAG,
});

export default function reducer(state, action) {
  switch (action.type) {
    case BEGIN_DRAG: {
      let pageNumbersToDrag = [action.number];

      if (
        draggedObjectIsOneOfPickedObject(action.number, state.pickedPageNumbers)
      ) {
        pageNumbersToDrag = [...state.pickedPageNumbers];
      }

      const multipleDrag = pageNumbersToDrag.length > 1;

      let pages = [...state.pages];

      for (let pageNumberToDrag of pageNumbersToDrag) {
        pages = addDraggingObject(
          pages,
          pageNumberToDrag,
          multipleDrag,
          'pageNumber'
        );
      }

      return {
        ...state,
        pages: pages,
        draggingPageNumbers: pageNumbersToDrag,
      };
    }
    case END_DRAG: {
      let newState = {
        ...state,
        pages: [...state.pages],
      };

      return resetDraggingObject(
        newState,
        'pages',
        'draggingPageNumbers',
        'pageNumber'
      );
    }
  }

  return state;
}
