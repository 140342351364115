import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { Consumer } from 'react-dnd/lib/DragDropContext';

const MAX_PIXEL_BEFORE_SCROLL = 150;

class ScrollingDrag extends Component {
  constructor(props) {
    super(props);
    this.handleMonitorChange = this.handleMonitorChange.bind(this);
    this.updateScrolling = this.updateScrolling.bind(this);
    this.startScrolling = this.startScrolling.bind(this);
    this.stopScrolling = this.stopScrolling.bind(this);
    this.frame = false;
    this.isDragging = false;
    this.speed = 1;
  }

  stopScrolling() {
    if (!this.frame) {
      return;
    }
    window.cancelAnimationFrame(this.frame);
    this.frame = null;
  }

  startScrolling(direction) {
    if (this.frame) {
      return;
    }

    const tick = () => {
      if (direction === 'TOP') {
        window.scrollBy(0, -1 * this.speed);
      } else {
        window.scrollBy(0, 1 * this.speed);
      }

      this.frame = window.requestAnimationFrame(tick);
    };

    tick();
  }

  updateScrolling(e) {
    if (!this.isDragging) {
      return;
    }

    if (e.clientY < MAX_PIXEL_BEFORE_SCROLL) {
      this.speed = MAX_PIXEL_BEFORE_SCROLL / e.clientY;
      this.startScrolling('TOP');
      return;
    }

    if (window.innerHeight - e.clientY < MAX_PIXEL_BEFORE_SCROLL) {
      this.speed = MAX_PIXEL_BEFORE_SCROLL / (window.innerHeight - e.clientY);
      this.startScrolling('BOT');
      return;
    }

    this.stopScrolling();
  }

  handleMonitorChange() {
    const isDragging = this.dragDropManager.getMonitor().isDragging();

    if (isDragging && !this.isDragging) {
      // trigger a scroll to avoid chrome bug https://trello.com/c/xkRBQzGR/1370-noeuds-qui-fr%C3%A9tillent when scroll bar is at bottom
      if (
        window.document.documentElement.scrollTop +
          window.document.documentElement.offsetHeight >=
        window.document.documentElement.scrollHeight
      ) {
        window.scrollBy(0, -1);
        window.scrollBy(0, 1);
      }
      window.document.body.addEventListener('dragover', this.updateScrolling);
    }

    if (this.isDragging && !isDragging) {
      this.stopScrolling();
      window.document.body.removeEventListener(
        'dragover',
        this.updateScrolling
      );
    }

    this.isDragging = isDragging;
  }

  componentDidMount() {
    this.dragDropManager.getMonitor().subscribeToStateChange(() => {
      this.handleMonitorChange();
    });
  }

  componentWillUnmount() {
    this.stopScrolling();
    window.document.body.removeEventListener('dragover', this.updateScrolling);
  }

  render() {
    return (
      <Fragment>
        <Consumer>
          {context => {
            this.dragDropManager = context.dragDropManager;
            return null;
          }}
        </Consumer>
        <section>{this.props.children}</section>
      </Fragment>
    );
  }
}

export default ScrollingDrag;
