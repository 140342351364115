import styled from 'styled-components';

const ErrorScanPanel = styled.div`
  font-size: 0.75rem;
  background-color: ${props => props.theme.primaryColor};
  color: ${props => props.theme.lightColor};
  @media (min-width: ${props => props.theme.tablet}) {
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0.3333rem 0.5rem 0;
    margin-bottom: 0;
    margin-top: auto;
    // the 2 following lines minimize the vertical flickering on chromium engines when the user scrolls the ScrollablePanel component
    transform: translate3d(0, 0, 0);
    box-shadow: 0 1px 0 ${props => props.theme.primaryColor};
  }
`;

ErrorScanPanel.Inner = styled.div`
  @media (min-width: ${props => props.theme.tablet}) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export default ErrorScanPanel;
