import createUndoableActionReducer from '../reducers/createUndoableActionReducer';
import { TYPE_DOCUMENT, TYPE_FOLDER } from '../documents/type';
import addNodes from './utils/addNodes';
import removeNode from './utils/removeNode';
import getNextPath, { INSIDE } from './utils/getNextPath';
import undoSaga from './dataroomActions/undoSaga';
import { uniqueId } from 'underscore';
import { buffers, channel, delay } from 'redux-saga';
import {
  all,
  call,
  cancel,
  cancelled,
  fork,
  put,
  race,
  select,
  take,
  takeEvery,
} from 'redux-saga/effects';
import addDocumentNumber, {
  addDocumentNumberWithoutFile,
} from './utils/addDocumentNumber';
import uploader from 'js/dataroom/core/uploader.js';
import { isFileAllowed } from '../documents/allowedFileExtension';
import { previableExtensions } from '../../document/actions/document.js';
import { REMOVE_NODES } from './removeNodes';
import { IMPORT_TYPE_CLASSIC } from '../constants';
import apiClient from 'js/dataroom/core/apiClient';
import queryString from 'qs';
import { CONFIRM_IMPORT_FILES } from './importFiles';
import { CONFIRM_EXTRACT_ZIP } from './extractZipFile';
import {
  FILE_UPLOAD_PROGRESS,
  handleFileUploadedProgess,
  uploadDocument,
} from './uploader';

export const ADD_FILES = 'ADD_FILES';
export const FILES_UPLOADED = 'FILES_UPLOADED';
export const FILE_UPLOAD_SUCCESS = 'FILE_UPLOAD_SUCCESS';
export const FILE_UPLOAD_ERROR = 'FILE_UPLOAD_ERROR';

export const CANT_CREATE_ALL_IGNORED_FILES = 'CANT_CREATE_ALL_IGNORED_FILES';

export const OPEN_CONFIRM_MODAL = 'OPEN_CONFIRM_ADD_FILES_MODAL';
export const HIDE_CONFIRM_MODAL = 'HIDE_CONFIRM_ADD_FILES_MODAL';

export const TOO_MANY_FILES_DROPPED = 'TOO_MANY_FILES_DROPPED';
export const TOO_MANY_UPLOADS_FOR_DROP = 'TOO_MANY_UPLOADS_FOR_DROP';

export const PREPARING_NODES_UPLOAD = 'PREPARING_NODES_UPLOAD';

export const DRAG_AND_DROP_ERROR = 'DRAG_AND_DROP_ERROR';

export const preparingUploadAction = () => ({
  type: PREPARING_NODES_UPLOAD,
});

export const tooManyFilesDroppedAction = droppedFilesNumber => ({
  type: TOO_MANY_FILES_DROPPED,
  droppedFilesNumber,
});

export const tooManyUploadForDropAction = (
  currentUploadNumber,
  droppedFilesNumber
) => ({
  type: TOO_MANY_UPLOADS_FOR_DROP,
  droppedFilesNumber,
  currentUploadNumber,
});

const ignoredFileRegex = [
  // ini files (Desktop.ini)
  /.*\.ini$/,
  // db files (Thumbs.db)
  /.*\.db$/,
  // files with ~ at begining (temp file of libreoffice)
  /^~.*/,
];

const hasOnlyOneDotAtBegining = fileName =>
  fileName[0] === '.' && fileName.lastIndexOf('.') === 0;

const hasNoExtension = fileName => fileName.indexOf('.') === -1;

export const isFileIgnored = fileName => {
  return (
    hasOnlyOneDotAtBegining(fileName) ||
    hasNoExtension(fileName) ||
    ignoredFileRegex.findIndex(regex => regex.test(fileName)) !== -1
  );
};

export function getDocumentsWithNotAllowedExtension(nodes, relativePath = '') {
  let notAllowedDocuments = [];

  nodes.forEach(node => {
    if (node.type === TYPE_DOCUMENT && isFileAllowed(node.name)) {
      return;
    }

    if (node.type === TYPE_DOCUMENT && node.file === null) {
      return;
    }

    if (node.type === TYPE_FOLDER) {
      notAllowedDocuments = notAllowedDocuments.concat(
        getDocumentsWithNotAllowedExtension(
          node.children,
          `${relativePath}${node.name}\\`
        )
      );
      return;
    }

    notAllowedDocuments.push({
      ...node,
      path: `${relativePath}${node.name}`,
    });
  });

  return notAllowedDocuments;
}

export function getDocuments(nodes) {
  let documents = [];

  nodes.forEach(node => {
    if (node.type === TYPE_DOCUMENT) {
      documents.push(node);
      return;
    }

    if (node.type === TYPE_FOLDER) {
      documents = documents.concat(getDocuments(node.children));
      return;
    }
  });

  return documents;
}

export function filterNotAllowedNodes(nodes) {
  const newNodes = [];

  nodes.forEach(node => {
    if (node.type === TYPE_DOCUMENT && !isFileIgnored(node.name)) {
      newNodes.push(node);
    }

    if (node.type === TYPE_FOLDER) {
      node.children = filterNotAllowedNodes(node.children);
      newNodes.push(node);
    }
  });

  return newNodes;
}

export const hideModalAction = () => ({ type: HIDE_CONFIRM_MODAL });

export const dragAndDropErrorAction = () => ({
  type: DRAG_AND_DROP_ERROR,
});

export const addFilesAction = (
  nodes,
  destinationNode,
  position,
  importType = IMPORT_TYPE_CLASSIC
) => {
  const filteredNodes = filterNotAllowedNodes(nodes);

  if (filteredNodes.length === 0) {
    return {
      type: CANT_CREATE_ALL_IGNORED_FILES,
    };
  }

  const notAllowedDocuments = getDocumentsWithNotAllowedExtension(
    filteredNodes
  );

  const action = {
    type: ADD_FILES,
    nodes: filteredNodes,
    destinationId: destinationNode.id,
    destinationPath: getNextPath(destinationNode.path, position),
    position,
    frontActionId: uniqueId('dataroom_action'),
    importType,
  };

  if (notAllowedDocuments.length === 0) {
    return action;
  }

  return {
    type: OPEN_CONFIRM_MODAL,
    addFilesAction: action,
    notAllowedDocuments,
  };
};

function removeFilesWithoutAllowedExtensions(nodes) {
  let newNodes = nodes.filter(
    node => node.type !== TYPE_DOCUMENT || isFileAllowed(node.name)
  );

  newNodes = newNodes.map(node => {
    if (node.type === TYPE_FOLDER) {
      return {
        ...node,
        children: removeFilesWithoutAllowedExtensions(node.children),
      };
    }

    return node;
  });

  return newNodes;
}

export const addFilesWithAllowedExtensions = action => action;

const normalizeNodeForApiCall = node => {
  let newNode = { ...node };

  if (node.type === TYPE_DOCUMENT) {
    delete newNode['file'];
    const lastIndex = newNode.name.lastIndexOf('.');

    if (lastIndex !== -1) {
      newNode.name = node.name.substr(0, newNode.name.lastIndexOf('.'));
    }

    return newNode;
  }

  newNode.children = newNode.children.map(normalizeNodeForApiCall);
  return newNode;
};

const addFilesApi = (action, { dataroomId }) =>
  apiClient.request(
    new Request(`/api/datarooms/${dataroomId}/nodes/drop`, {
      method: 'POST',
      body: JSON.stringify({
        to: action.destinationPath,
        nodes: action.nodes.map(normalizeNodeForApiCall),
        importType: action.importType,
      }),
    })
  );

export function* saga(action) {
  const selectedState = yield select(state => ({
    dataroomId: state.dataroom.dataroom.id,
  }));

  return yield call(addFilesApi, action, selectedState);
}

export function* undoActionSaga() {
  yield takeEvery('UNDO_ADD_FILES', undoSaga);
}

export const getDocumentNodes = nodes => {
  let documentNodeItems = [];

  nodes.forEach(node => {
    if (node.type === TYPE_DOCUMENT) {
      return documentNodeItems.push(node);
    }

    documentNodeItems = documentNodeItems.concat(
      getDocumentNodes(node.children)
    );
  });

  return documentNodeItems;
};

export const getTotalSize = documentNodes => {
  return documentNodes.reduce((prev, next) => prev + next.file.size, 0);
};

export const getTotalChunk = documentNodes => {
  return documentNodes.reduce(
    (prev, next) => prev + uploader.getTotalChunkForDocument(next.file),
    0
  );
};

export const MAX_RETRY = 3;

function* callUploadFile(
  nodeId,
  file,
  dropToken,
  deletedDocuments,
  fileUploadProgressChan
) {
  let failedCalls = 0;
  let currentOffset = 0;

  while (true) {
    if (deletedDocuments.indexOf(nodeId) !== -1) {
      return;
    }

    try {
      return yield call(
        uploadDocument,
        file,
        nodeId,
        currentOffset,
        `/api/nodes/${nodeId}/file?${queryString.stringify({
          dropToken,
        })}`,
        fileUploadProgressChan
      );
    } catch (err) {
      if (
        !err.isUploadError ||
        failedCalls === MAX_RETRY - 1 ||
        deletedDocuments.indexOf(nodeId) !== -1
      ) {
        throw err;
      }

      // set offset for retry last chunk
      currentOffset = err.offset;

      yield call(delay, 1000);
    } finally {
      if (yield cancelled()) {
        // TODO: cancel the request
        // https://github.com/github/fetch/issues/547
        // https://github.com/github/fetch/pull/592
      }
    }
    failedCalls += 1;
  }
}

function* uploadFile(
  chan,
  fileUploadChan,
  deletedDocuments,
  fileUploadProgressChan
) {
  while (true) {
    const payload = yield take(chan);

    // the document has been deleted we dont have to upload it
    if (deletedDocuments.indexOf(payload.nodeId) !== -1) {
      yield put(fileUploadChan, {
        type: FILE_UPLOAD_SUCCESS,
        nodeId: payload.nodeId,
        offset: payload.file.size,
      });
      continue;
    }

    try {
      yield call(
        callUploadFile,
        payload.nodeId,
        payload.file,
        payload.dropToken,
        deletedDocuments,
        fileUploadProgressChan
      );

      yield put(fileUploadChan, {
        type: FILE_UPLOAD_SUCCESS,
        nodeId: payload.nodeId,
        offset: payload.file.size,
      });
    } catch (e) {
      // if an error has been caught because the document has been deleted, we consider it has a success
      if (deletedDocuments.indexOf(payload.nodeId) !== -1) {
        yield put(fileUploadChan, {
          type: FILE_UPLOAD_SUCCESS,
          nodeId: payload.nodeId,
          offset: e.offset,
        });

        continue;
      }

      yield put(fileUploadChan, {
        type: FILE_UPLOAD_ERROR,
        nodeId: payload.nodeId,
        name: payload.name,
      });
    }
  }
}

export const MAX_BUFFER = 50;

function* filesUploadedSaga(finishedUploadsFiles, frontActionId) {
  yield call(delay, 50);

  yield put({ type: FILES_UPLOADED, finishedUploadsFiles, frontActionId });
}

function* handleFileUploaded(
  fileUploadChan,
  documentsToUploadNumber,
  frontActionId
) {
  let finishedUploadsFiles = [];
  let finishedUploadsFilesByTaskId = {};
  let uploadFileTask;
  let uploadedDocuments = 0;

  while (true) {
    const action = yield take(fileUploadChan);

    if (uploadFileTask && uploadFileTask.isRunning()) {
      yield cancel(uploadFileTask);
      if (uploadFileTask.isCancelled()) {
        finishedUploadsFiles = finishedUploadsFilesByTaskId[uploadFileTask.id];
      }
    }

    finishedUploadsFiles.push(action);

    uploadFileTask = yield fork(
      filesUploadedSaga,
      finishedUploadsFiles,
      frontActionId
    );

    finishedUploadsFilesByTaskId[uploadFileTask.id] = finishedUploadsFiles;
    if (finishedUploadsFiles.length >= MAX_BUFFER) {
      uploadFileTask = null;
    }

    finishedUploadsFiles = [];
    uploadedDocuments += 1;

    if (documentsToUploadNumber === uploadedDocuments) {
      break;
    }
  }
}

function* deletedDocumentsSaga(deletedDocuments) {
  while (true) {
    const action = yield take(REMOVE_NODES);

    const nodes = yield select(
      state => state.dataroom.working.savedNodes[action.frontActionId]
    );

    Object.entries(nodes).forEach(nodeEntry => {
      const node = nodeEntry[1];

      if (node.type === TYPE_DOCUMENT) {
        deletedDocuments.push(node.id);
      }
    });
  }
}

function removeDocumentWithoutFiles(nodes) {
  let newNodes = nodes.filter(node => node.type !== TYPE_DOCUMENT || node.file);

  newNodes = newNodes.map(node => {
    if (node.type === TYPE_FOLDER) {
      return {
        ...node,
        children: removeDocumentWithoutFiles(node.children),
      };
    }

    return node;
  });

  return newNodes;
}

// with http2, the max concurent request for the same host is 100, it is possible to allow more if the server send a specific header
// https://stackoverflow.com/questions/39759054/how-many-concurrent-requests-should-we-multiplex-in-http-2
const MAX_PARALELL_UPLOAD_FILE = 90;

function* uploadSaga(action) {
  const documentNodes = getDocumentNodes(
    removeFilesWithoutAllowedExtensions(
      removeDocumentWithoutFiles(action.action.nodes)
    )
  );

  const chan = yield call(channel, buffers.fixed(documentNodes.length));
  const deletedDocuments = [];
  const numberOfChunk = getTotalChunk(documentNodes);

  const fileUploadChan = yield call(channel, buffers.fixed(numberOfChunk));

  const fileUploadProgressChan = yield call(
    channel,
    buffers.fixed(numberOfChunk)
  );

  const uploadFileWorkers = [];

  // no need to spawn many workers if there is few documents
  for (
    let i = 0;
    i < MAX_PARALELL_UPLOAD_FILE && i < documentNodes.length;
    i++
  ) {
    const uploadFileWorker = yield fork(
      uploadFile,
      chan,
      fileUploadChan,
      deletedDocuments,
      fileUploadProgressChan
    );
    uploadFileWorkers.push(uploadFileWorker);
  }

  const uploadsTask = yield fork(
    handleFileUploaded,
    fileUploadChan,
    documentNodes.length,
    action.action.frontActionId
  );

  yield fork(
    handleFileUploadedProgess,
    fileUploadProgressChan,
    action.action.frontActionId,
    numberOfChunk
  );

  const deletedDocumentsWorker = yield fork(
    deletedDocumentsSaga,
    deletedDocuments
  );

  yield all(
    documentNodes.map(documentNode =>
      put(chan, {
        nodeId: documentNode.id,
        file: documentNode.file,
        name: documentNode.name,
        dropToken: action.dropToken,
      })
    )
  );

  uploadsTask.done
    .catch(() => {
      return;
    })
    .then(() => {
      uploadFileWorkers.forEach(uploadFileWorker => {
        uploadFileWorker.cancel();
        deletedDocumentsWorker.cancel();
      });
    });
}

function* cancelableUploadSaga(action) {
  const { upload, cancel } = yield race({
    upload: call(uploadSaga, action),
    cancel: take(
      action =>
        action.type === 'UNDO_ADD_FILES' &&
        action.action.frontActionId === action.action.frontActionId
    ),
  });
}

export function* uploadActionSaga() {
  yield takeEvery('ADD_FILES_SUCCESS', cancelableUploadSaga);
}

export function normalizeAddedNode(node) {
  let normalizedNode = { ...node };

  if (node.type === TYPE_FOLDER) {
    normalizedNode.documentWithoutFileNumber = getDocumentNodes(
      node.children
    ).length;
    delete normalizedNode['children'];
  }

  if (node.type === TYPE_DOCUMENT) {
    const extensionIndex = normalizedNode.name.lastIndexOf('.') + 1;

    let extension = normalizedNode.name.substring(extensionIndex);
    if (extension) {
      extension = extension.toLowerCase();
    }

    let normalizedNodeName = node.name;
    if (extensionIndex !== 0) {
      normalizedNodeName = node.name.substr(0, extensionIndex - 1);
    }

    if (!normalizedNode.file || !isFileAllowed(normalizedNode.name)) {
      normalizedNode.uploading = false;
      normalizedNode.file = null;
      normalizedNode.name = normalizedNodeName;

      return normalizedNode;
    }

    normalizedNode.name = normalizedNodeName;

    normalizedNode['uploading'] = true;

    normalizedNode.uploadingFile = {
      size: normalizedNode.file.size,
      editedAt: new Date(),
      mimetype: normalizedNode.file.type,
      extension,
      previewStatus:
        previableExtensions.indexOf(extension) !== -1 ? 'in_progress' : null,
    };

    normalizedNode.uploadingProgress = {
      uploadedSize: 0,
      totalSize: normalizedNode.file.size,
    };

    normalizedNode['file'] = null;
  }

  return normalizedNode;
}

export function addChildToFolder(nodeId, nodesToAdd, nodes) {
  const normalizedNodeToAdd = nodesToAdd.map(normalizeAddedNode);

  let newNodes = addNodes(nodeId, INSIDE, nodes, normalizedNodeToAdd);

  nodesToAdd.forEach(node => {
    if (node.type === TYPE_FOLDER) {
      newNodes = addChildToFolder(node.id, node.children, newNodes);
    }
  });

  return newNodes;
}

function addFilesReducer(state, action) {
  const normalizedNodes = action.nodes.map(normalizeAddedNode);

  let newNodes = addNodes(
    action.destinationId,
    action.position,
    state.working.nodes,
    normalizedNodes
  );

  action.nodes.forEach(node => {
    if (node.type === TYPE_FOLDER) {
      newNodes = addChildToFolder(node.id, node.children, newNodes);
    }
  });

  let uploads = state.working.uploads;
  const documentNodes = getDocumentNodes(
    removeFilesWithoutAllowedExtensions(action.nodes)
  );

  const documentNodesWithFile = removeDocumentWithoutFiles(documentNodes);

  if (documentNodesWithFile.length > 0) {
    const totalSize = getTotalSize(documentNodesWithFile);

    let progressDocumentUpload = [];
    documentNodesWithFile.map(document => {
      progressDocumentUpload.push({
        nodeId: document.id,
        uploadedSize: 0,
        totalSize: document.file.size,
      });
    });

    uploads = [
      ...uploads,
      {
        frontActionId: action.frontActionId,
        uploadedDocuments: 0,
        uploadingDocuments: documentNodesWithFile.length,
        totalSize: totalSize,
        uploadedSize: 0,
        progress: progressDocumentUpload,
      },
    ];

    newNodes = addDocumentNumberWithoutFile(
      action.position === INSIDE
        ? action.destinationId
        : newNodes[action.destinationId].parentId,
      newNodes,
      documentNodes.length
    );
  }

  return {
    ...state,
    working: {
      ...state.working,
      uploads: uploads,
      nodes: newNodes,
      preparingUpload: false,
    },
  };
}

function undoAddFilesReducer(state, action) {
  let newNodes = { ...state.working.nodes };

  for (let document of getDocuments(action.nodes)) {
    const node = newNodes[document.id];

    if (node.uploadingFile) {
      newNodes = addDocumentNumberWithoutFile(node.id, newNodes, -1);
    }

    if (node.file) {
      newNodes = addDocumentNumber(node.id, newNodes, -1);
    }
  }

  action.nodes.forEach(node => {
    newNodes = removeNode(newNodes, node.id);
  });

  let newState = {
    ...state,
    working: {
      ...state.working,
      nodes: newNodes,
    },
  };

  const currentUploadIndex = state.working.uploads.findIndex(
    upload => upload.frontActionId === action.frontActionId
  );

  if (currentUploadIndex !== -1) {
    newState.working.uploads = [
      ...newState.working.uploads.slice(0, currentUploadIndex),
      ...newState.working.uploads.slice(currentUploadIndex + 1),
    ];
  }

  return newState;
}

const baseReducer = createUndoableActionReducer(
  ADD_FILES,
  addFilesReducer,
  undoAddFilesReducer
);

function uploadsAreFinished(uploads) {
  for (const upload of uploads) {
    if (upload.uploadedDocuments !== upload.uploadingDocuments) {
      return false;
    }
  }

  return true;
}

function updateUploads(uploads, action) {
  const uploadIndex = uploads.findIndex(
    upload => upload.frontActionId === action.frontActionId
  );

  let newUpload = {
    ...uploads[uploadIndex],
    uploadedDocuments:
      uploads[uploadIndex].uploadedDocuments +
      action.finishedUploadsFiles.length,
  };

  const newUploads = uploads.map((upload, index) => {
    if (uploadIndex !== index) {
      return upload;
    }

    return newUpload;
  });

  if (uploadsAreFinished(newUploads)) {
    return [];
  }

  return newUploads;
}

function updateUploadsProgress(uploads, finishedUploadsFile, action) {
  const uploadIndex = uploads.findIndex(
    upload => upload.frontActionId === action.frontActionId
  );

  const uploadDocumentIndex = uploads[uploadIndex].progress.findIndex(
    progress => progress.nodeId === finishedUploadsFile.nodeId
  );

  uploads[uploadIndex].uploadedDocuments -= 1;
  uploads[uploadIndex].uploadingDocuments -= 1;
  uploads[uploadIndex].uploadedSize -= finishedUploadsFile.offset;
  uploads[uploadIndex].totalSize -=
    uploads[uploadIndex].progress[uploadDocumentIndex].totalSize;

  return uploads;
}

export default function(state, action) {
  switch (action.type) {
    case PREPARING_NODES_UPLOAD: {
      return {
        ...state,
        working: {
          ...state.working,
          preparingUpload: true,
        },
      };
    }
    case TOO_MANY_FILES_DROPPED:
    case TOO_MANY_UPLOADS_FOR_DROP:
    case CONFIRM_IMPORT_FILES:
    case CONFIRM_EXTRACT_ZIP:
    case DRAG_AND_DROP_ERROR:
    case CANT_CREATE_ALL_IGNORED_FILES: {
      return {
        ...state,
        working: {
          ...state.working,
          preparingUpload: false,
        },
      };
    }

    case HIDE_CONFIRM_MODAL: {
      return {
        ...state,
        working: {
          ...state.working,
          confirmAddFilesModal: {
            open: false,
            addFilesAction: null,
            notAllowedDocuments: [],
          },
        },
      };
    }
    case OPEN_CONFIRM_MODAL: {
      return {
        ...state,
        working: {
          ...state.working,
          preparingUpload: false,
          confirmAddFilesModal: {
            open: true,
            addFilesAction: action.addFilesAction,
            notAllowedDocuments: action.notAllowedDocuments,
          },
        },
      };
    }
    case FILES_UPLOADED: {
      let newNodes = {
        ...state.working.nodes,
      };

      let uploads = updateUploads(state.working.uploads, action);

      action.finishedUploadsFiles.forEach(finishedUploadsFile => {
        if (!newNodes[finishedUploadsFile.nodeId]) {
          if (uploads.length === 0) {
            return;
          }

          uploads = updateUploadsProgress(uploads, finishedUploadsFile, action);

          return;
        }

        if (finishedUploadsFile.type === FILE_UPLOAD_ERROR) {
          newNodes[finishedUploadsFile.nodeId] = {
            ...newNodes[finishedUploadsFile.nodeId],
            file: null,
          };
        }

        if (finishedUploadsFile.type === FILE_UPLOAD_SUCCESS) {
          newNodes = addDocumentNumber(
            newNodes[finishedUploadsFile.nodeId].parentId,
            newNodes
          );
          newNodes = addDocumentNumberWithoutFile(
            newNodes[finishedUploadsFile.nodeId].parentId,
            newNodes,
            -1
          );

          newNodes[finishedUploadsFile.nodeId] = {
            ...newNodes[finishedUploadsFile.nodeId],
            file: newNodes[finishedUploadsFile.nodeId].uploadingFile,
          };
        }

        newNodes[finishedUploadsFile.nodeId] = {
          ...newNodes[finishedUploadsFile.nodeId],
          uploading: false,
          uploadingFile: null,
          uploadingProgress: null,
        };
      });

      return {
        ...state,
        working: {
          ...state.working,
          uploads: uploads,
          nodes: newNodes,
        },
      };
    }
  }

  return baseReducer(state, action);
}
