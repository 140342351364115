import React, { Fragment, useState } from 'react';
import Span from '../../../presentational/components/Span';
import {
  BODY_FONT,
  CLIENT_UI_COLOR,
  DANGER_COLOR,
  DARK_GREY_COLOR,
  LIGHT_COLOR,
  LIGHT_GREY_COLOR,
  MID_GREY_COLOR,
} from '../../../presentational/components/theme_blue';
import Div from '../../../presentational/components/Div';
import GridCol from '../../../presentational/components/GridCol';
import Button from '../../../presentational/components/Button';
import 'translations/messages/fr';
import 'translations/messages/en';
import GridRow from '../../../presentational/components/GridRow';
import Hr from '../../../presentational/components/Hr';
import H3 from '../../../presentational/components/H3';
import IconCheckmark from '../../../presentational/components/img/icons/IconCheckmark';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import Form from '../../../presentational/components/form/Form';
import useNotification from '../../../app/hooks/notification';
import useApiClient from '../../../app/hooks/apiClient';
import { TYPE_ERROR } from '../../../dataroom/app/reducers/notifications';
import Input from '../../../presentational/components/form/Input';
import Select from '../../../presentational/components/form/Select';
import Option from '../../../presentational/components/form/Option';
import Alert from '../../../presentational/components/Alert';
import P from '../../../presentational/components/P';
import IconSpam from '../../../presentational/components/img/icons/IconSpam';
import { browserHistory, Link } from 'react-router';
import IconChevronLeft from '../../../presentational/components/img/icons/IconChevronLeft';
import IconCloseSimple from '../../../presentational/components/img/icons/IconCloseSimple';
import A from '../../../presentational/components/A';
import Ul from '../../../presentational/components/Ul';
import Li from '../../../presentational/components/Li';
import IconClose from '../../../presentational/components/img/icons/IconClose';
import IconDataroom from '../../../presentational/components/img/icons/IconDataroom';
import IconCalendar from '../../../presentational/components/img/icons/IconCalendar';
import { Date } from '../../../app/components/Date';
import { useAuthDataContext } from '../../../app/hooks/authDataProvider';
import IconComment from '../../../presentational/components/img/icons/IconComment';

export default function UserForm(props) {
  const [hasError, setHasError] = useState(null);
  const { user, isAdmin, isSuperAdmin } = useAuthDataContext();
  const apiClient = useApiClient();
  const { onSendNotificationReminder } = useNotification();

  const {
    register,
    handleSubmit,
    control,
    setError,
    formState: { isSubmitting, errors },
  } = useForm({
    criteriaMode: 'all',
    defaultValues: props.defaultValues,
  });

  const passwordValue = useWatch({
    control,
    name: 'password',
  });

  const onSubmit = async data => {
    let url = `/api/user`;
    let method = `POST`;
    let message = "L'utilisateur a été ajouté";

    if (props.user) {
      url = `/api/user/${props.user.id}/update`;
      method = `PATCH`;
      message = 'Le profil a été édité';
    }

    const response = await apiClient(url, {
      method,
      body: JSON.stringify(data),
    });

    if (response.ok) {
      onSendNotificationReminder(message);
      browserHistory.push('/users');

      return;
    }

    if (
      response.status === 400 &&
      response.headers.get('Content-Type') === 'application/json'
    ) {
      const formErrors = await response.json();
      formErrors.forEach(error => {
        setError(error.propertyPath, {
          type: 'manual',
          types: {
            manual: error.message,
          },
        });
      });
      return;
    }

    if (
      response.status === 400 &&
      response.headers.get('Content-Type') === 'application/json+error'
    ) {
      const error = await response.json();
      setHasError(error.message);
      return;
    }

    onSendNotificationReminder('La création a échoué', TYPE_ERROR);
  };

  return (
    <Div maxWidth="50rem" margin="0 auto" fontWeight="300">
      {hasError && (
        <Alert alertType="danger" role="alert">
          <Alert.ContentWithOrWithoutIcon>
            <P marginTop="1rem" marginBottom="0">
              <IconSpam marginRight="1em" width="1.5em" marginTop="-.125em" />
            </P>
            <Alert.ContentText>
              <P marginTop="1rem" marginBottom="1rem">
                {Translator.trans(hasError, {}, 'messages')}
              </P>
            </Alert.ContentText>
          </Alert.ContentWithOrWithoutIcon>
        </Alert>
      )}
      {isAdmin && (
        <Alert alertType="info" role="alert">
          <Alert.ContentWithOrWithoutIcon>
            <P marginTop="1rem" marginBottom="0">
              <IconComment
                marginRight="1em"
                width="1.5em"
                marginTop="-.125em"
              />
            </P>
            <Alert.ContentText>
              <P marginTop="1rem" marginBottom="1rem">
                Changer le rôle d'un utilisateur le déconnectera.
              </P>
            </Alert.ContentText>
          </Alert.ContentWithOrWithoutIcon>
        </Alert>
      )}
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Input
          {...register('lastName', {
            required: 'Le nom est obligatoire',
            pattern: {
              value: /\S+/,
              message: 'Le nom ne doit pas être vide',
            },
            maxLength: {
              value: 100,
              message: 'Le nom est trop long',
            },
          })}
          displayBlock
          isHorizontal
          label="Nom"
          isLabelBold
          type="text"
          isRequired
          labelTextRight
          errors={errors.lastName?.types}
          labelColor={DARK_GREY_COLOR}
        />
        <Input
          {...register('firstName', {
            required: 'Le prénom est obligatoire',
            pattern: {
              value: /\S+/,
              message: 'Le prénom ne doit pas être vide',
            },
            maxLength: {
              value: 100,
              message: 'Le prénom est trop long',
            },
          })}
          displayBlock
          isHorizontal
          label="Prénom"
          isLabelBold
          type="text"
          isRequired
          labelTextRight
          errors={errors.firstName?.types}
          labelColor={DARK_GREY_COLOR}
        />
        <Input
          {...register('email', {
            required: 'Le mail est obligatoire',
            maxLength: {
              value: 100,
              message: "L'email est trop long",
            },
          })}
          displayBlock
          isHorizontal
          label="Email"
          isLabelBold
          type="text"
          isRequired
          labelTextRight
          errors={errors.email?.types}
          labelColor={DARK_GREY_COLOR}
        />
        {isAdmin && (
          <Select
            {...register('role')}
            displayBlock
            isHorizontal
            label="Rôle"
            isLabelBold
            isRequired
            labelTextRight
            errors={errors.role?.types}
            labelColor={DARK_GREY_COLOR}
          >
            <Option value="ROLE_USER">Utilisateur</Option>
            <Option value="ROLE_COMPANY_ADMIN">Administrateur</Option>
            {isSuperAdmin && (
              <Option value="ROLE_SUPER_ADMIN">Super Administrateur</Option>
            )}
          </Select>
        )}
        <Hr />
        {!props.noPassword && (
          <Fragment>
            <H3>
              <Span color={CLIENT_UI_COLOR}>Mot de passe :</Span>
            </H3>
            <Input
              {...register('password', {
                required: 'Le mot de passe est obligatoire',
                pattern: {
                  value: /(?=.*\d)(?=.*[A-Za-z])(?=.*[!#$*]).{8,}/,
                  message: 'Le mot de passe est non valide',
                },
              })}
              displayBlock
              isHorizontal
              label="Mot de passe"
              isLabelBold
              type="password"
              isRequired
              labelTextRight
              errors={errors.password?.types}
              formHelper="Plus de 8 caractères dont 1 chiffre, et 1 caractère spécial parmi: !#$*"
              labelColor={DARK_GREY_COLOR}
            />
            <Input
              {...register('passwordConfirmation', {
                validate: value =>
                  passwordValue === value ||
                  "Le mot de passe n'est pas identique",
              })}
              displayBlock
              isHorizontal
              label="Confirmation"
              isLabelBold
              type="password"
              isRequired
              labelTextRight
              errors={errors.passwordConfirmation?.types}
              labelColor={DARK_GREY_COLOR}
            />
            <Hr />
          </Fragment>
        )}

        {props.user?.dataRooms && (
          <Fragment>
            <H3>
              <Span color={CLIENT_UI_COLOR}>Mes datarooms :</Span>
            </H3>
            {props.user.dataRooms.length === 0 && (
              <P>Pas de datarooms pour l'instant.</P>
            )}
            {props.user.dataRooms.length > 0 && (
              <Ul listStyleType="none" display="block" margin="0" padding="0">
                {props.user.dataRooms.map((dataroom, index) => (
                  <Li
                    key={index}
                    strippedColor={LIGHT_GREY_COLOR}
                    padding=".5rem"
                  >
                    <GridRow>
                      <GridCol xs={12 / 12}>
                        <Div display="flex">
                          <A
                            as={Link}
                            to={`/datarooms/${dataroom.id}`}
                            color={CLIENT_UI_COLOR}
                            display="inline-flex"
                          >
                            <IconDataroom
                              marginRight=".5em"
                              marginTop=".125em"
                            />
                            <strong>{dataroom.name}</strong>
                          </A>
                        </Div>
                      </GridCol>
                      <GridCol xs={12 / 12}>
                        <Span color={MID_GREY_COLOR} margin="0 0 0 1.6em">
                          <IconCalendar
                            marginRight=".5em"
                            marginBottom="-.125em"
                          />
                          Date de dernière modification :{' '}
                          <Date date={dataroom.editedAt} format="L" />
                        </Span>
                      </GridCol>
                    </GridRow>
                  </Li>
                ))}
              </Ul>
            )}
            <Hr />
          </Fragment>
        )}

        <GridRow>
          <GridCol textAlign="right">
            <A as={Link} to={'/users'}>
              <Button
                type="cancel"
                backgroundColor={DARK_GREY_COLOR}
                borderColor={DARK_GREY_COLOR}
                backgroundHoverColor={LIGHT_COLOR}
              >
                <IconCloseSimple marginRight=".5em" />
                Annuler
              </Button>
            </A>
          </GridCol>
          <GridCol textAlign="right">
            <Button
              type="submit"
              disabled={isSubmitting}
              backgroundColor={CLIENT_UI_COLOR}
              borderColor={CLIENT_UI_COLOR}
              backgroundHoverColor={LIGHT_COLOR}
            >
              <IconCheckmark marginRight=".5em" />
              Enregistrer l'utilisateur
            </Button>
          </GridCol>
        </GridRow>
      </Form>
    </Div>
  );
}
