import getPathLevel from './getPathLevel';
import { moveNodes, moveNodesAction } from '../moveNodes';
import { AFTER, INSIDE } from './getNextPath';
import mapDescendant from './mapDescendant';
import updatePath from './updatePath';
import childNode from './childNode';
import { TYPE_DOCUMENT, TYPE_FOLDER } from '../../documents/type';
import addDocumentNumber, {
  addDocumentNumberWithoutFile,
} from './addDocumentNumber';
import getPosition from './getPosition';

export function removeAndSaveNodes(
  newState,
  nodeIds,
  frontActionId,
  deleteOnlyHead
) {
  nodeIds.forEach(nodeId => {
    newState = removeNode(newState, nodeId, frontActionId, deleteOnlyHead);
  });
  return newState;
}

function removeNode(state, nodeId, frontActionId, deleteOnlyHead) {
  const nodeToDelete = state.working.nodes[nodeId];
  const savedNodes = {
    [frontActionId]: {
      ...state.working.savedNodes[frontActionId],
      [nodeId]: { ...state.working.nodes[nodeId] },
    },
  };
  const pathLevel = getPathLevel(nodeToDelete.path);
  let newState = { ...state };

  if (deleteOnlyHead) {
    [...nodeToDelete.childIds].reverse().forEach(childId => {
      newState = moveNodes(
        newState,
        moveNodesAction([newState.working.nodes[childId]], nodeToDelete, AFTER)
      );
    });
  }
  const parentNode = newState.working.nodes[nodeToDelete.parentId];
  let newNodes = { ...newState.working.nodes };

  newNodes = mapDescendant(newNodes, nodeId, node => {
    return {
      ...node,
      path: updatePath(pathLevel, node.path, position => position - 1),
    };
  });

  if (!deleteOnlyHead) {
    const idsToDelete = [];
    for (const childNodeItem of childNode(newNodes, nodeToDelete.id)) {
      idsToDelete.push(childNodeItem.id);
      savedNodes[frontActionId][childNodeItem.id] = { ...childNodeItem };
    }
    idsToDelete.forEach(idToDelete => delete newNodes[idToDelete]);
  }

  if (nodeToDelete.type === TYPE_DOCUMENT && nodeToDelete.file) {
    newNodes = addDocumentNumber(nodeToDelete.parentId, newNodes, -1);
  }
  if (nodeToDelete.type === TYPE_DOCUMENT && !nodeToDelete.file) {
    newNodes = addDocumentNumberWithoutFile(
      nodeToDelete.parentId,
      newNodes,
      -1
    );
  }

  if (nodeToDelete.type === TYPE_FOLDER && !deleteOnlyHead) {
    newNodes = addDocumentNumber(
      nodeToDelete.parentId,
      newNodes,
      -nodeToDelete.documentWithFileNumber
    );
    newNodes = addDocumentNumberWithoutFile(
      nodeToDelete.parentId,
      newNodes,
      -nodeToDelete.documentWithoutFileNumber
    );
  }

  delete newNodes[nodeId];

  return {
    ...newState,
    working: {
      ...state.working,
      savedNodes: {
        ...state.working.savedNodes,
        ...savedNodes,
      },
      nodes: {
        ...newNodes,
        [parentNode.id]: {
          ...newNodes[parentNode.id],
          childIds: parentNode.childIds.filter(
            nodeId => nodeId !== nodeToDelete.id
          ),
        },
      },
    },
  };
}

export function undoRemoveNode(state, nodeId, frontActionId, deleteOnlyHead) {
  const savedNodes = state.working.savedNodes[frontActionId];
  const deletedNode = savedNodes[nodeId];
  const deletedNodePathLevel = getPathLevel(deletedNode.path);
  const position = getPosition(deletedNodePathLevel, deletedNode.path);
  const parentNode = state.working.nodes[deletedNode.parentId];
  const indexToInsert = position - 1;

  let newNodes = { ...state.working.nodes };

  if (position - 1 !== parentNode.childIds.length) {
    newNodes = mapDescendant(
      state.working.nodes,
      parentNode.childIds[indexToInsert],
      node => {
        return {
          ...node,
          path: updatePath(
            deletedNodePathLevel,
            node.path,
            position => position + 1
          ),
        };
      },
      true
    );
  }

  newNodes = insertRemovedNodes(newNodes, savedNodes, nodeId, deleteOnlyHead);

  if (deletedNode.type === TYPE_DOCUMENT && deletedNode.file) {
    newNodes = addDocumentNumber(deletedNode.parentId, newNodes, 1);
  }

  if (deletedNode.type === TYPE_DOCUMENT && !deletedNode.file) {
    newNodes = addDocumentNumberWithoutFile(deletedNode.parentId, newNodes, 1);
  }

  if (deletedNode.type === TYPE_FOLDER && !deleteOnlyHead) {
    newNodes = addDocumentNumber(
      deletedNode.parentId,
      newNodes,
      deletedNode.documentWithFileNumber
    );

    newNodes = addDocumentNumberWithoutFile(
      deletedNode.parentId,
      newNodes,
      deletedNode.documentWithoutFileNumber
    );
  }

  const newChildIds = [
    ...newNodes[parentNode.id].childIds.slice(0, indexToInsert),
    deletedNode.id,
    ...newNodes[parentNode.id].childIds.slice(indexToInsert),
  ];

  return {
    ...state,
    working: {
      ...state.working,
      nodes: {
        ...newNodes,
        [parentNode.id]: {
          ...newNodes[parentNode.id],
          childIds: newChildIds,
        },
      },
    },
  };
}

function insertRemovedNodes(nodes, savedNodes, nodeId, deleteOnlyHead) {
  const deletedNode = savedNodes[nodeId];
  const updatedSavedNodes = {
    [nodeId]: {
      ...deletedNode,
      menuOpen: false,
      picked: false,
      uploading: false,
      uploadingFile: null,
      uploadingProgress: null,
    },
  };

  if (!deleteOnlyHead || deletedNode.type === TYPE_DOCUMENT) {
    const childNodeIds = Array.from(childNode(savedNodes, deletedNode.id)).map(
      node => node.id
    );
    childNodeIds.forEach(nodeId => {
      updatedSavedNodes[nodeId] = {
        ...savedNodes[nodeId],
        menuOpen: false,
        picked: false,
        uploading: false,
        uploadingFile: null,
        uploadingProgress: null,
      };
    });

    return {
      ...nodes,
      ...updatedSavedNodes,
    };
  }

  let state = {
    working: {
      nodes: {
        ...nodes,
        [nodeId]: {
          ...updatedSavedNodes[nodeId],
          childIds: [],
        },
      },
    },
  };

  [...deletedNode.childIds].reverse().forEach(childId => {
    state = moveNodes(
      state,
      moveNodesAction([nodes[childId]], deletedNode, INSIDE)
    );
  });

  return state.working.nodes;
}
