import React, { Fragment } from 'react';
import Notifications from '../app/containers/Notifications';
import Helmet from 'react-helmet';
import Layout from '../../app/layout/Layout';
import { Menu } from '../../app/components/menu/Menu';
import Content from '../../app/layout/Content';
import Div from '../../presentational/components/Div';

export default function Dataroom(props) {
  return (
    <Fragment>
      <Helmet>
        <title>Espace client - Dataroom</title>
      </Helmet>

      <Layout isOrangeSkin className="am-wrapper am-nosidebar-left">
        <Menu isOrangeSkin dataroom />

        <Content isOrangeSkin>{props.children}</Content>

        <Div id={'after-wrapper-end'} />
      </Layout>

      <Notifications />
    </Fragment>
  );
}
