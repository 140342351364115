import styled from 'styled-components';
const Li = styled.li`
  ${props => (props.display ? 'display: ' + props.display + ';' : null)}
  ${props => (props.width ? 'width: ' + props.width + ';' : null)}
  ${props => (props.padding ? 'padding: ' + props.padding + ';' : null)}
  ${props => (props.margin ? 'margin: ' + props.margin + ';' : null)}
  ${props => (props.fontSize ? 'font-size : ' + props.fontSize + ';' : null)}
  ${props =>
    props.borderBottomColor
      ? 'border-bottom: solid 1px ' + props.theme[props.borderBottomColor] + ';'
      : null}
  break-inside: avoid;
  ${props => (props.sticky ? 'top: 0; position: sticky; z-index: 2;' : null)}
  &:nth-child(odd) {
    ${props =>
      props.strippedColor
        ? 'background-color: ' + props.theme[props.strippedColor] + ';'
        : null}
  }
`;
export default Li;
