/**
 Permet de gérer le drag/drop d'un objet en ayant le format suivant:
 {
    ...,
    objects: [
        {
          ...,
          picked: boolean,
          dragging: boolean,
          multipleDrag: boolean
        }
    ],
    pickedObjects: [string, ...],
    draggingObjects: [string, ...]
 }
 */

/**
 * Permet de vérifier que l'object en cours de drag est présent parmis les picked object
 * @param identifier => identifiant unique
 * @param pickedObjects => la valeur de "pickedObjects"
 */
export const draggedObjectIsOneOfPickedObject = (identifier, pickedObjects) =>
  pickedObjects.findIndex(
    pickedObjectIdentifier => identifier === pickedObjectIdentifier
  ) !== -1;

/**
 * Permet de set un object en cours de drag
 * @param objects => la valeurs de "objects"
 * @param objectIdentifierToDrag => l'identifiant des objects a drag
 * @param key => la clef de l'identifiant unique (ex: id)
 */
export const addDraggingObject = (
  objects,
  objectIdentifierToDrag,
  multipleDrag,
  key
) => {
  const objectIndex = objects.findIndex(
    current => current[key] === objectIdentifierToDrag
  );

  let newPage = {
    ...objects[objectIndex],
    dragging: true,
    multipleDrag: multipleDrag,
  };

  return objects.map((page, index) => {
    if (objectIndex !== index) {
      return page;
    }

    return newPage;
  });
};

/**
 * Permet de reset le draggin de tous les objects
 * @param objectKey => valeur de la clef ('objects' dans l'exemple)
 * @param draggingObjectKey => valeur de la clef ('draggingObjects' dans l'exemple)
 * @param identifierKey => la clef de l'identifiant unique (ex: id)
 */
export const resetDraggingObject = (
  state,
  objectKey,
  draggingObjectKey,
  identifierKey
) => {
  state[draggingObjectKey].forEach(nodeId => {
    const nodeIndex = state[objectKey].findIndex(
      node => node[identifierKey] === nodeId
    );

    state[objectKey][nodeIndex] = {
      ...state[objectKey][nodeIndex],
      dragging: false,
      multipleDrag: false,
    };
  });

  state[draggingObjectKey] = [];

  return state;
};
