import React, { Fragment, useEffect, useMemo, useReducer } from 'react';
import theme_blue, {
  BODY_FONT,
  CLIENT_UI_COLOR,
  DARK_GREY_COLOR,
  LIGHT_BLUE_COLOR,
  LIGHT_COLOR,
  LIGHT_GREY_COLOR,
  MID_GREY_COLOR,
  SECONDARY_COLOR,
  TRANSPARENT_COLOR,
} from '../../../presentational/components/theme_blue';
import Div from '../../../presentational/components/Div';
import Span from '../../../presentational/components/Span';
import IconDataroom from '../../../presentational/components/img/icons/IconDataroom';
import GridRow from '../../../presentational/components/GridRow';
import GridCol from '../../../presentational/components/GridCol';
import Hr from '../../../presentational/components/Hr';
import Ul from '../../../presentational/components/Ul';
import Li from '../../../presentational/components/Li';
import A from '../../../presentational/components/A';
import IconEdit from '../../../presentational/components/img/icons/IconEdit';
import IconCalendar from '../../../presentational/components/img/icons/IconCalendar';
import IconUser from '../../../presentational/components/img/icons/IconUser';
import { Link } from 'react-router';
import { useAuthDataContext } from '../../../app/hooks/authDataProvider';
import { Date } from '../../../app/components/Date';
import { Paginator } from '../../../app/components/Paginator';
import IconTrash from '../../../presentational/components/img/icons/IconTrash';
import Button from '../../../presentational/components/Button';
import { formatedBytes } from '../../../app/utils/formatedBytes';

export default function DataRoomSearchResult(props) {
  const { user, isAdmin } = useAuthDataContext();
  const { currentPage, datarooms, total, items } = props;

  return (
    <Fragment>
      {total === 0 && (
        <Span>{Translator.transChoice('foundResult', total)}</Span>
      )}
      <Div fontWeight="300">
        <Ul listStyleType="none" display="block" margin="0" padding="0">
          {datarooms.map(dataroom => (
            <Li
              key={dataroom.id}
              strippedColor={LIGHT_GREY_COLOR}
              padding=".5rem"
            >
              <Div display="flex" alignItems="start">
                <GridRow>
                  <GridCol xs={12 / 12} md={4 / 12}>
                    <A
                      as={Link}
                      to={`/datarooms/${dataroom.id}`}
                      color={CLIENT_UI_COLOR}
                      display="inline-flex"
                    >
                      <IconDataroom marginRight=".5em" marginTop=".125em" />
                      <strong>{dataroom.name}</strong>
                    </A>
                  </GridCol>
                  <GridCol xs={12 / 12} md={2 / 12}>
                    <Span
                      color={MID_GREY_COLOR}
                      display="inline-block"
                      margin="0 0 0 1.6em"
                    >
                      <IconCalendar marginRight=".5em" marginBottom="-.125em" />
                      MàJ le <Date date={dataroom.editedAt} format="L" />
                    </Span>
                  </GridCol>
                  <GridCol xs={12 / 12} md={2 / 12}>
                    <Span
                      color={MID_GREY_COLOR}
                      display="flex"
                      margin="0 0 0 1.6em"
                      flexWrap
                    >
                      <Span display="block">
                        <IconDataroom marginRight=".5em" marginTop=".125em" />
                        <Span display="inline-block" margin="0 .5em 0 0">
                          Poids :
                        </Span>
                      </Span>
                      <A display="inline-block" color={MID_GREY_COLOR}>
                        {formatedBytes(dataroom.size)}
                      </A>
                    </Span>
                  </GridCol>
                  <GridCol xs={12 / 12} md={3 / 12}>
                    <Span
                      color={MID_GREY_COLOR}
                      display="flex"
                      margin="0 0 0 1.6em"
                      flexWrap
                    >
                      <Span display="block">
                        <IconUser marginRight=".5em" marginTop=".125em" />
                        <Span display="inline-block" margin="0 .5em 0 0">
                          Propriétaire :
                        </Span>
                      </Span>
                      <A href="#" display="inline-block" color={MID_GREY_COLOR}>
                        {dataroom.owner.firstName}{' '}
                        {dataroom.owner.lastName.toUpperCase()}
                      </A>
                    </Span>
                  </GridCol>
                  <GridCol xs={12 / 12} md={1 / 12}>
                    {(isAdmin || dataroom.owner.id === user.id) && (
                      <A
                        as={Link}
                        to={`/datarooms/edition/${dataroom.id}`}
                        title="Éditer cette dataroom"
                        $hoverColor="primaryColor"
                      >
                        <Span margin="0 1em 0 0">
                          <IconEdit width="1.25em" />
                        </Span>
                      </A>
                    )}
                    {(isAdmin || dataroom.owner.id === user.id) && (
                      <Button
                        backgroundColor={TRANSPARENT_COLOR}
                        borderColor={TRANSPARENT_COLOR}
                        color={SECONDARY_COLOR}
                        hoverColor={CLIENT_UI_COLOR}
                        noBorder
                        padding="0"
                      >
                        <Span
                          margin="0 1em 0 0"
                          onClick={() => props.onDelete(dataroom)}
                        >
                          <IconTrash width="1.25em" />
                        </Span>
                      </Button>
                    )}
                  </GridCol>
                </GridRow>
              </Div>
            </Li>
          ))}
        </Ul>
      </Div>
      <Hr />
      <Div fontSize=".75rem" margin="2rem 0">
        {total > 0 && (
          <Paginator
            totalItems={total}
            itemsPerPage={items}
            currentPage={currentPage}
            changePage={props.changePage}
          />
        )}
      </Div>
    </Fragment>
  );
}
