import React, { Component, Fragment } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import 'translations/edm-dataroom/fr';
import 'translations/edm-dataroom/en';
import { connect } from 'react-redux';
import {
  getNodePathForNodeIds,
  makeGetNodePathForNodeIds,
} from '../selectors/getNodePathForNodeIds';

class DuplicatedFileWarning extends Component {
  render() {
    const { warning, validationWarningsLength, index, nodePaths } = this.props;

    return (
      <Fragment>
        -{' '}
        <b>
          {Translator.trans(warning.name, {}, 'edm-dataroom')} (
          {nodePaths.join(' / ')})
        </b>{' '}
        {index !== validationWarningsLength - 1 ? <br /> : null}
      </Fragment>
    );
  }
}

function mapStateToProps(state, props) {
  const getNodePathForNodeIds = makeGetNodePathForNodeIds(
    state,
    props.warning.relatedIds
  );

  return {
    nodePaths: getNodePathForNodeIds(state, props.warning.relatedIds),
  };
}

export default connect(mapStateToProps)(DuplicatedFileWarning);
