import React from 'react';
import Svg from './Svg';

export default function IconChevronRightHeavy(props) {
  return (
    <Svg
      {...props}
      d="M163.961 128L55.181 30.098 72.819 12.8l128 115.2-128 115.2-17.638-17.298L163.961 128z"
    />
  );
}
