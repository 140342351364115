import React from 'react';
import styled from 'styled-components';
import { PRIMARY_COLOR } from '../theme_blue';
import IconCloseSimple from '../img/icons/IconCloseSimple';

const Button = styled.button`
  display: inline-block;
  width: 1.125em;
  height: 1.125em;
  overflow: hidden;
  vertical-align: middle;
  text-align: center;
  background-color: ${props =>
    props.background
      ? props.theme[props.background]
      : props.theme[PRIMARY_COLOR]};
  color: white;
  font-size: 2rem;
  border-radius: 50%;
  border: solid 1px transparent;
  padding: 0;
  position: absolute;
  left: calc(100vw - 4rem);
  z-index: 1;
  top: 0.5rem;
  cursor: pointer;
  &:hover {
    border-color: white;
  }
`;

const Icon = styled.span`
  display: flex;
  vertical-align: middle;
`;

export default function CloseButton(props) {
  return (
    <Button onClick={props.onClick} background={props.background}>
      <Icon>
        <IconCloseSimple />
      </Icon>
    </Button>
  );
}
