import {
  addPickedObject,
  removePickedObject,
  resetPickedObject,
} from '../../dataroom/utils/pickObject';

const PICK_DOCUMENT = 'EDITOR_MERGED_PICK_DOCUMENT';
const ADD_DOCUMENT_TO_PICKED = 'EDITOR_MERGED_ADD_DOCUMENT_TO_PICKED';
const REMOVE_DOCUMENT_TO_PICKED = 'EDITOR_MERGED_REMOVE_DOCUMENT_TO_PICKED';
const RESET_PICK_DOCUMENT = 'EDITOR_MERGED_RESET_PICK_DOCUMENT';

export const pickDocumentAction = nodeId => ({
  type: PICK_DOCUMENT,
  id: nodeId,
});

export const addDocumentToPickedAction = nodeId => ({
  type: ADD_DOCUMENT_TO_PICKED,
  id: nodeId,
});

export const removeDocumentToPickedAction = nodeId => ({
  type: REMOVE_DOCUMENT_TO_PICKED,
  id: nodeId,
});

export const resetPickDocumentAction = () => ({
  type: RESET_PICK_DOCUMENT,
});

export default function reducer(state, action) {
  switch (action.type) {
    case RESET_PICK_DOCUMENT:
    case PICK_DOCUMENT: {
      let newState = {
        ...state,
        nodes: [...state.nodes],
      };

      newState = resetPickedObject(newState, 'nodes', 'pickedNodes', 'id');

      if (action.type === RESET_PICK_DOCUMENT) {
        return newState;
      }

      return addPickedObject(newState, action, 'nodes', 'id', 'pickedNodes');
    }
    case ADD_DOCUMENT_TO_PICKED: {
      return addPickedObject(state, action, 'nodes', 'id', 'pickedNodes');
    }
    case REMOVE_DOCUMENT_TO_PICKED: {
      return {
        ...state,
        pickedNodes: state.pickedNodes.filter(id => id !== action.id),
        nodes: removePickedObject(state, action, 'nodes', 'id'),
      };
    }
  }

  return state;
}
