import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  checkDocumentPreviewStatus,
  previableExtensions,
} from '../actions/document.js';
import { FILE_TAB } from '../components/DocumentTabs.js';
import './FileTab.css';
import pdfObject from 'pdfobject';
import ResizeObserverPolyfill from 'resize-observer-polyfill';
import PDF from '../components/PDF';
import { makeShortenedNodeForDocumentDetailSelector } from '../../dataroom/selectors/shortenedNodeNameSelector';

const viewableExtensions = ['pdf', 'png', 'jpg', 'jpeg'];

const View = props => {
  if (props.extension === 'mp4') {
    return (
      <video controls style={{ width: '60%', margin: 'auto', display: 'flex' }}>
        <source src={props.fileUrl} type="video/mp4" />
      </video>
    );
  }

  if (props.extension === 'pdf') {
    return <PDF fileUrl={props.fileUrl} height={props.height} />;
  }

  if (
    props.extension === 'png' ||
    props.extension === 'jpg' ||
    props.extension === 'jpeg'
  ) {
    return (
      <img
        style={{ maxHeight: props.height }}
        src={props.fileUrl}
        className="dataroomImagePreview"
      />
    );
  }

  return null;
};

const getFileViewUrl = (admin, document) => {
  let url = null;
  if (viewableExtensions.indexOf(document.file.extension) !== -1) {
    url = `/api/nodes/${document.id}/file`;

    if (document.file.extension === 'pdf' && !pdfObject.supportsPDFs) {
      return `/pdfjs/web/viewer.html?file=${encodeURIComponent(url)}`;
    }
  }

  if (previableExtensions.indexOf(document.file.extension) !== -1) {
    url = `/api/nodes/${document.id}/filePreview/`;
  }

  if (url) {
    return encodeURI(url);
  }

  return null;
};

class FileTab extends Component {
  constructor(props) {
    super(props);
    this.renderContent = this.renderContent.bind(this);
    this.renderPreview = this.renderPreview.bind(this);
    this.updateViewSize = this.updateViewSize.bind(this);
    this.openEditor = this.openEditor.bind(this);
    this.shortenedNodeName = makeShortenedNodeForDocumentDetailSelector();
    this.viewContainerRef = React.createRef();
    this.state = {
      fileViewHeight: '600px',
    };

    if (typeof ResizeObserver !== 'undefined') {
      this.resizeObserver = new ResizeObserver(this.updateViewSize);
    } else {
      this.resizeObserver = new ResizeObserverPolyfill(this.updateViewSize);
    }
  }

  openEditor() {
    // todo
  }

  render() {
    let divClass = 'tab-pane cont';
    if (this.props.selectedTab === FILE_TAB) {
      divClass += ' active';
    }

    return (
      <div id="document" className={divClass}>
        {this.renderContent()}
      </div>
    );
  }

  updateViewSize() {
    if (!this.viewContainerRef.current) {
      return;
    }

    this.setState({
      fileViewHeight: `${window.innerHeight -
        this.viewContainerRef.current.getBoundingClientRect().y -
        40}px`,
    });
  }

  componentDidMount() {
    this.resizeObserver.observe(this.viewContainerRef.current);
    window.addEventListener('scroll', this.updateViewSize);
    const { document, dataroomType } = this.props;

    if (document.file.previewStatus !== 'in_progress') {
      return;
    }

    this.props.checkDocumentPreviewStatus(document.id);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.updateViewSize);
    this.resizeObserver.disconnect();
  }

  renderPreview() {
    const { document, admin } = this.props;

    if (previableExtensions.indexOf(document.file.extension) === -1) {
      return null;
    }

    if (
      this.props.tabsStatus[FILE_TAB].isCheckingPreview &&
      this.props.tabsStatus[FILE_TAB].displayCheckingPreview
    ) {
      return (
        <p className="text-center">
          Génération de la prévisualisation en cours
          <br />
          <i className="fa fa-cog fa-spin fa-3x fa-fw"> </i>
        </p>
      );
    }

    if (
      document.file.previewStatus === 'in_progress' &&
      this.props.tabsStatus[FILE_TAB].displayCheckingPreview
    ) {
      return (
        <p className="text-center">
          La génération de la prévisualisation prend du temps, revenez plus tard
          <br />
          <i className="fa fa-cog fa-spin fa-3x fa-fw"> </i>
        </p>
      );
    }

    if (document.file.previewStatus === 'in_progress') {
      return;
    }

    if (document.file.previewStatus === 'error') {
      return (
        <p className="text-center">
          La prévisualisation n'est pas disponible pour ce document
        </p>
      );
    }

    const fileUrl = getFileViewUrl(admin, document);

    return (
      <View
        height={this.state.fileViewHeight}
        extension="pdf"
        fileUrl={fileUrl}
      />
    );
  }

  renderContent() {
    const hasBeenLoaded = this.props.tabsStatus[FILE_TAB].hasBeenLoaded;
    const isSelected = this.props.selectedTab === FILE_TAB;
    const { isFetchingDocument, document } = this.props;

    if (!hasBeenLoaded && !isSelected && isFetchingDocument) {
      return '';
    }

    const fileUrl = encodeURI(`/api/nodes/${document.id}/file`);

    const fileViewUrl = getFileViewUrl(this.props.admin, document);

    const fileUrlDownload = encodeURI(
      `/api/nodes/${document.id}/file/download`
    );

    let documentName = this.shortenedNodeName(document);

    return (
      <Fragment>
        <p className="text-center">
          {fileViewUrl && (
            <a href={fileViewUrl} target="_blank" className="btn btn-primary">
              <i className="fa fa-desktop" aria-hidden="true" />
              <strong>
                {' '}
                {Translator.trans('see-full-screen', {}, 'edm-dataroom')}{' '}
              </strong>
            </a>
          )}{' '}
          <a
            href={fileUrlDownload}
            className="btn btn-primary"
            download={`${document.name}.${document.file.extension}`}
          >
            <i className="fa fa-download" aria-hidden="true" />
            {Translator.trans('download')}: <strong> {documentName} </strong>
          </a>
        </p>
        <div ref={this.viewContainerRef}>
          {this.renderPreview()}
          {!this.props.isPdfEditorOpen && (
            <View
              height={this.state.fileViewHeight}
              fileUrl={fileUrl}
              extension={document.file.extension}
            />
          )}
        </div>
      </Fragment>
    );
  }
}

FileTab.propTypes = {
  document: PropTypes.object.isRequired,
  admin: PropTypes.bool.isRequired,
};

function mapStateToProps(state, props) {
  return {
    ...state.document,
    viewType: props.viewType,
    isPdfEditorOpen: state.dataroom.pdfEditor.open,
  };
}

export default connect(mapStateToProps, {
  checkDocumentPreviewStatus,
})(FileTab);
