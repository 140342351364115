import React from 'react';
import Svg from './Svg';

export default function IconChevronBottom(props) {
  return (
    <Svg
      {...props}
      d="M128,176.67l106.002,-117.78l9.198,10.22l-115.2,128l-115.2,-128l9.198,-10.22l106.002,117.78Z"
    />
  );
}
