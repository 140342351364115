import getPathLevel from './getPathLevel';

export default path => {
  if (path === '0') {
    return '0';
  }

  const pathLevel = getPathLevel(path);

  if (pathLevel === 1) {
    return '0';
  }

  const splittedPath = path.split('.');
  splittedPath.splice(-1, 1);
  return splittedPath.join('.');
};
