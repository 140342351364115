import styled from 'styled-components';

const Form = styled.form`
  padding: ${props => (props.padding ? props.padding : 0)};
  margin: ${props => (props.margin ? props.margin : 0)};
  display: ${props => (props.display ? props.display : 'block')};
  ${props => (props.width ? 'width: ' + props.width + ';' : null)};
  ${props => (props.noDisplay ? 'display: none;' : null)};
  @media (min-width: ${props => props.theme.tablet}) {
    ${props => (props.widthMd ? 'width: ' + props.widthMd + ';' : null)};
  }
`;

export default Form;
