import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Sticky from 'js/dataroom/lib/react-stickynode/Sticky';

const style = {
  backgroundColor: '#f3a174',
};

class StickyNode extends Component {
  render() {
    const { isLargeDataroom, fetchingDocument, document } = this.props;

    return (
      <Sticky
        bottomMargin={
          isLargeDataroom && (fetchingDocument || document) ? 0 : 88
        }
        innerZ="3"
      >
        <div style={style}>{this.props.children} </div>
      </Sticky>
    );
  }
}

export default connect(state => ({
  isLargeDataroom: state.dataroom.isLargeDataroom,
  document: state.document.document,
  fetchingDocument: state.document.isFetchingDocument,
}))(StickyNode);
