export const MINIMIZE = 'MINIMIZE_ACTIONS';
export const UNMINIMIZE = 'UNMINIMIZE_ACTIONS';

export function minimizeActions() {
  return {
    type: MINIMIZE,
  };
}

export function unMinimizeActions() {
  return {
    type: UNMINIMIZE,
  };
}
