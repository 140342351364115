import React from 'react';
import styled from 'styled-components';

const InputContainer = styled.span`
  width: ${props => {
    if (props.fieldWidth) {
      return props.fieldWidth;
    }
    if (props.displayBlock) {
      return '100%';
    }
    return 'auto';
  }};
  display: ${props => (props.displayBlock ? 'flex' : 'inline-flex')};
  ${props => (props.height ? 'height: ' + props.height : null)};
  border-style: solid;
  border-width: ${props => (props.noBorder ? '0' : '1px')};
  border-color: ${props => {
    if (props.hasError) {
      return props.theme.dangerColor;
    }
    return props.theme.formFieldsBorderColor;
  }};
  padding-bottom: 1px;
  background-color: ${props =>
    props.hasError ? props.theme.dangerLightColor : props.theme.lightColor};
  color: ${props => props.theme.darkColor};
  ${props =>
    props.disabled
      ? 'background-color: ' +
        props.theme.lightGreyColor +
        '; cursor: not-allowed;'
      : null}
  align-items: center;
`;

const Input = styled.input`
  appearance: none;
  width: ${props => {
    if (props.fieldWidth || props.displayBlock) {
      return '100%';
    }
    return 'auto';
  }};
  display: ${props => (props.displayBlock ? 'block' : 'inline-block')};
  text-align: ${props => (props.textAlign ? props.textAlign : 'left')};
  border: none;
  border-width: 0;
  padding: 0.45em 0.5em;
  padding-right: 0.5em;
  font-family: ${props => props.theme.bodyFont};
  font-size: 1em;
  font-size: clamp(16px, 1em, 2rem);
  background-color: ${props =>
    props.hasError ? props.theme.dangerLightColor : props.theme.lightColor};
  color: ${props =>
    props.hasError ? props.theme.dangerColor : props.theme.darkColor};
  ${props =>
    props.disabled ? 'background-color: ' + props.theme.lightGreyColor : null};
  margin: 0; // required to avoid non zero margins by default on safari
  ${props =>
    props.disabled
      ? 'cursor: not-allowed !important;'
      : null} // !important is required here to overwrite 'orange' skin css rules.
  @media (min-width: ${props => props.theme.tablet}) {
    font-size: 1em;
  }
  &::-ms-clear {
    display: none; // remove the cross appearing in ie11 when one character hae been entered in the field.
  }
`;

class InputField extends React.Component {
  render() {
    return (
      <InputContainer
        required={this.props.isRequired}
        fieldWidth={this.props.fieldWidth}
        displayBlock={this.props.displayBlock}
        hasError={this.props.errors}
        noBorder={this.props.noBorder}
        disabled={this.props.isDisabled}
        height={this.props.height}
      >
        <Input
          ref={this.props.forwardedRef}
          readOnly={this.props.readOnly}
          autoComplete={this.props.autoComplete}
          onBlur={this.props.onBlur}
          type={this.props.type}
          textAlign={this.props.textAlign}
          fieldWidth={this.props.fieldWidth}
          displayBlock={this.props.displayBlock}
          id={this.props.field_id}
          value={this.props.value}
          placeholder={this.props.placeholder}
          onChange={this.props.onChange}
          onClick={this.props.onClick}
          onFocus={this.props.onFocus}
          onKeyDown={this.props.onKeyDown}
          disabled={this.props.isDisabled}
          hasError={this.props.hasError}
          maxLength={this.props.maxLength}
          pattern={this.props.pattern}
          name={this.props.name}
          {...this.props.inputProps}
        />
      </InputContainer>
    );
  }
}

export default React.forwardRef((props, ref) => (
  <InputField forwardedRef={ref} {...props} />
));
