let urlLeaveBlocked = false;
let documentReady = false;

$(document).ready(() => {
  documentReady = true;
});

const executeWhenReady = callback => {
  if (documentReady) {
    callback();
    return;
  }

  $(document).ready(callback);
};

export const blockLeave = () => {
  executeWhenReady(() => {
    $(window).on('beforeunload', () => 'block');
    urlLeaveBlocked = true;
  });
};

export const allowLeave = () => {
  executeWhenReady(() => {
    $(window).off('beforeunload');
    urlLeaveBlocked = false;
  });
};
