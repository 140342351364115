import { put, call, select } from 'redux-saga/effects';
import apiClient from 'js/dataroom/core/apiClient';
import Raven from 'raven-js';

const callUndoApi = (dataroomId, action) => {
  return apiClient
    .request(
      new Request(`/api/datarooms/${dataroomId}/undo`, {
        method: 'PUT',
      })
    )
    .then(response => {
      if (!response.ok) {
        throw new Error();
      }
    });
};

export default function* undoAction(action) {
  try {
    const dataroomId = yield select(state => state.dataroom.dataroom.id);
    yield call(callUndoApi, dataroomId, action);

    yield put({
      type: `${action.type}_SUCCESS`,
      action: action.action,
    });
  } catch (e) {
    Raven.captureException(e);

    yield put({
      type: `${action.type}_ERROR`,
      action: action.action,
    });
  }
}
