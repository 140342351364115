import React from 'react';
import Svg from './Svg';

export default function IconSpam(props) {
  return (
    <Svg
      {...props}
      d="M128 11l82.731 34.269L245 128l-34.269 82.731L128 245l-82.731-34.269L11 128l34.269-82.731L128 11zm-10.308 190v-20.416h20.616V201h-20.616zm4.681-36.251l-5.477-77.382V55h22.208v32.367l-5.178 77.382h-11.553z"
    />
  );
}
