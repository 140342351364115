import { useDispatch } from 'react-redux';
import {
  ADD_NOTIFICATION_REMINDER,
  TYPE_SUCCESS,
} from '../../dataroom/app/reducers/notifications';

export default function useNotification() {
  const dispatch = useDispatch();

  const onSendNotificationReminder = (message, type = TYPE_SUCCESS) => {
    dispatch({
      type: ADD_NOTIFICATION_REMINDER,
      message: message,
      notificationType: type,
    });
  };

  return {
    onSendNotificationReminder,
  };
}
